import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ItemGridModule } from '@app/modules/item-grid/item-grid.module';
import { AppColorsPreviewComponent } from './app-colors-preview/app-colors-preview.component';
import { DarkModeSettingsComponent } from './dark-mode-settings/dark-mode-settings.component';
import { LogoPreviewComponent } from './logo-preview/logo-preview.component';
import { LogoSettingsComponent } from './logo-settings/logo-settings.component';
import { CustomBrandingComponent } from './custom-branding/custom-branding.component';
import { LogoUploadButtonDirective } from './custom-branding/logo-upload-button.upgrade.directive';
import { LogoSettingsServiceClass } from './logo-settings/logo-settings.service';
import { ColorPalettesComponent } from './color-palettes/color-palettes.component';
import {
	AppearanceSettingsComponent
} from '@app/modules/account-administration/appearance/appearance-settings/appearance-settings.component';
import { CalendarModule } from '@app/modules/calendar/calendar-format/calendar.module';
import {
	ConversationSettingsModule
} from '@app/modules/account-administration/conversation-settings/conversation-settings.module';
import {
	AutomatedNarrativeModule
} from '@app/modules/account-administration/automated-narrative/automated-narrative.module';
import { IconModule } from '@app/modules/icon/icon.module';
import {
	ColorPaletteReplaceComponent
} from '@app/modules/account-administration/appearance/color-palette-replace/color-palette-replace.component';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import
ColorPaletteGridDefinition
	from '@app/modules/account-administration/appearance/color-palettes/services/color-palette-grid-definition.service';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ItemGridModule,
		CalendarModule,
		ConversationSettingsModule,
		AutomatedNarrativeModule,
		IconModule,
		DialogModule,
	],
	declarations: [
		AppColorsPreviewComponent,
		DarkModeSettingsComponent,
		LogoPreviewComponent,
		LogoSettingsComponent,
		CustomBrandingComponent,
		LogoUploadButtonDirective,
		ColorPalettesComponent,
		AppearanceSettingsComponent,
		ColorPaletteReplaceComponent,
	],
	providers: [
		LogoSettingsServiceClass,
		ColorPaletteGridDefinition
	]
})
export class AppearanceModule { }
