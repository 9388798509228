import { MetricComparisonUtils } from '@app/modules/widget-settings/cb-metric/metric-comparison-utils';
import { MetricWidgetVisualProperties } from '@app/modules/widget-settings/entities/properties/metric-widget-visual-properties.class';
import { IVisualization } from '@app/modules/widget-settings/report-visualization-selector.component';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { WidgetIcons } from '@app/modules/dashboard/widget-templates.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { IColorSelectorPalette } from '@cxstudio/reports/coloring/color-selector.component';
import { DEFAULT_DATE_FILTER_MODE } from '@cxstudio/reports/entities/date-filter-mode';
import { DatePeriodField, DatePeriodName } from '@cxstudio/reports/entities/date-period';
import { GaugeColorMode } from '@cxstudio/reports/entities/gauge-color-mode.enum';
import { MetricWidgetComparison, MetricWidgetProperties } from '@cxstudio/reports/entities/metric-widget-properties';
import { IDataObject } from '@cxstudio/reports/entities/report-interfaces';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { WidgetMetricConfigurationOptions } from '@cxstudio/reports/providers/cb/constants/widget-metric-configuration-options.constant';
import { KeyMetricListTypes } from '@cxstudio/reports/providers/cb/definitions/key-metric-list-types.constant';
import { PeriodOverPeriodMetricType } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric-type';
import { PeriodOverPeriodMetricService } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric.service';
import { IWidgetSettingsOptions, IWidgetSettingsScope, IWidgetSettingsUI } from '@cxstudio/reports/providers/cb/services/cb-settings-service.service';
import { MetricWidgetPOPService } from '@cxstudio/reports/providers/cb/services/metric-widget-pop.service';
import { IWidgetSettings } from '@cxstudio/reports/providers/cb/services/widget-settings.service';
import { VisualizationOptionsService } from '@cxstudio/reports/settings/visualization-options.service';
import { ColorUtils, PaletteType } from '@cxstudio/reports/utils/color-utils.service';
import { CalculationColorUtils } from '@cxstudio/reports/utils/color/calculation-color-utils';
import { HighchartsUtilsService } from '@cxstudio/reports/utils/highchart/highcharts-utils.service';
import { RealDataPreviewWidget } from '../definitions/real-data-preview-widget.class';
import { MetricConstants } from '../constants/metric-constants.service';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { MetricCustomFormatUtilsService } from '@app/modules/widget-visualizations/formatters/metric-custom-format-utils.service';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { PreviewChartRefreshType } from '@app/modules/reports/real-data-preview/preview-chart-refresh-type.enum';
import { Subscription } from 'rxjs';

export class MetricWidgetSettingsComponent extends RealDataPreviewWidget {
	readonly MAX_CALCULATIONS: number = 10;

	widgetType: WidgetType;

	ui: IWidgetSettingsUI;
	visualProps: MetricWidgetVisualProperties;
	props: MetricWidgetProperties;
	options: IWidgetSettingsOptions;
	widget: Widget;
	staticData: {
		data: IDataObject<any>;
		ready: boolean;
	};
	utils: WidgetUtils;

	reloadSettings: (event, callback) => void;
	broadcastSettings: (broadcastEvent: string) => void;
	updateChartSettings: (refreshType?: PreviewChartRefreshType) => void;
	updateAutoTitle: () => void;

	//methods defined in child controller. will refactor in the future.
	initLists: () => void;
	setPrimaryTimeGrouping: (timeGrouping) => void;
	initializePeriods: () => void;
	changePop: (pop: boolean) => void;
	onMetricUpdate: (metric) => void;
	removeMetric: (metric) => void;
	addMetric: (metric) => ng.IPromise<void>;
	applyVisualChanges: () => void;
	addLoadingPromise: (promise: ng.IPromise<any>) => ng.IPromise<any>;
	populateCurrentHierarchyCalculations: (metrics: any, grouping: any, calculations: any, models?: any) => void;
	reloadCommonSettings: (config: any) => ng.IPromise<IWidgetSettings>;
	configure: (item, listType, configurationOptions?, templateUrl?: string) => ng.IPromise<void>;
	metricColorFilter: (...args) => boolean;

	showRealDataPreview: () => boolean;
	updateDataLoading: (dataLoading: Promise<any>) => void;

	// have to do this because not everything using embedded controllers are component-ized yet :(
	$watch = this.$scope.$watch;
	$watchCollection = this.$scope.$watchCollection;
	$emit = this.$scope.$emit;

	calculationsError = this.locale.getString('widget.calculationsLimitError', {
		limit: this.MAX_CALCULATIONS,
		details: ''
	});

	visualizations: IVisualization[];
	newMetric?: ReportCalculation;
	showingNewMetric: boolean;
	colorModes: Array<UIOption<GaugeColorMode>>;
	allowedColorTypes = [PaletteType.PALETTE, PaletteType.PROVIDER, PaletteType.SOLID, PaletteType.CALCULATION];

	visualizationUpdateRequired: boolean;
	previewHasChangesSubscription: Subscription;


	constructor(
		protected $scope: ISimpleScope & IWidgetSettingsScope & ng.IScope,
		protected metricConstants: MetricConstants,
		protected realDataPreviewService: RealDataPreviewService,
		protected reportSettingsService: ReportSettingsService,
		protected defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		protected metricCustomFormatUtils: MetricCustomFormatUtilsService,
		protected colorUtils: ColorUtils,
		private locale: ILocale,
		private periodOverPeriodMetricService: PeriodOverPeriodMetricService,
		private $controller: ng.IControllerService,
		private $timeout: ng.ITimeoutService,
		private highchartsUtils: HighchartsUtilsService,
		private metricWidgetPOPService: MetricWidgetPOPService,
		private visualizationOptionsService: VisualizationOptionsService,
		private betaFeaturesService: BetaFeaturesService
	) {
		super(
			$scope,
			realDataPreviewService,
			metricConstants,
			reportSettingsService,
			defaultDataFormatterBuilder,
			metricCustomFormatUtils,
			colorUtils
		);
	}

	$onInit(): void {
		this.initRealDataPreview();

		this.metricColorFilter = () => true;
		this.visualizations = [
			this.visualizationOptionsService.buildMetricVisualization(WidgetVisualization.CB_AN_METRIC,
				WidgetIcons.cb_an_metric, this.locale.getString('widget.cb_an_metric')),
			this.visualizationOptionsService.buildMetricVisualization(WidgetVisualization.GAUGE,
				WidgetIcons.gauge, this.locale.getString('widget.gauge'))
		];

		this.colorModes = Object.keys(GaugeColorMode).map(key => {
			return {
				value: GaugeColorMode[key],
				displayName: this.locale.getString(`widget.gaugeMode_${key}`),
			};
		});

		if (this.isGauge()) {
			this.ensureSetGaugeColorMode();
			this.updateColorFilter(this.props.selectedMetrics[0]);
		}

		this.$controller('AnalyticDefinitionMultiSelectionController', {
			$scope: this,
			multiSelectionConfiguration: {
				withAttributeSelection: false,
				withMetricSelection: true,
				calculationFilter: item => item.name === StandardMetricName.VOLUME,
				max: {
					calculations: this.MAX_CALCULATIONS
				},
				calculationCountingFilter: item => !item?.isPopMetric
			}
		});

		this.$scope.$on('clearSettings', () => this.initProps());
		this.$scope.$on('reloadSettings', (event, callback) => {
			this.initProps();
			this.reloadSettings(event, callback);
		});

		this.$scope.$watchCollection(() => this.props.selectedMetrics, (newValue, oldValue) => {
			if (newValue === oldValue) return;
			if (!this.props.isCustomTitle)
				this.updateAutoTitle();
			this.redrawReport();
		});

		this.initProps();
		this.upgradeProperties();

		this.staticData.data = {
			data: [{}, {}],
			metadata: {
				totalCount: 157
			}
		} as IDataObject<any>;
		this.redrawReport();
	}

	private initRealDataPreview(): void {
		this.widgetType = this.props.widgetType;

		this.visualizationUpdateRequired = false;

		this.initScopeProps();
		super.$onInit();

		if (this.showRealDataPreview()) {
			this.previewHasChangesSubscription = this.realDataPreviewService.getPreviewChangeObserver().subscribe((hasChanges) => {
				if (!hasChanges) {
					this.visualizationUpdateRequired = false;
				}
			});
		}
	}

	private initScopeProps(): void {
		this.$scope.widget = this.widget;
		this.$scope.widgetType = this.props.widgetType;
		this.$scope.props = this.props;
		this.$scope.visualProps = this.visualProps;
		this.$scope.options = this.options;
		this.$scope.staticData = this.staticData;
		this.$scope.updateChartSettings = this.updateChartSettings;
	}

	$onDestroy = () => {
		if (this.previewHasChangesSubscription) {
			this.previewHasChangesSubscription.unsubscribe();
		}
	};

	$on = (event: string, handler: () => void): () => void => {
		return this.$scope.$on(event, handler);
	};

	private initProps(): void {
		if (!this.props.selectedMetrics) {
			this.props.selectedMetrics = [];
		}

		this.ui.periods = [{
			field: DatePeriodField.PERIOD1,
			name: DatePeriodName.PERIOD1,
			defaultMode: DEFAULT_DATE_FILTER_MODE,
		}];

		this.ui.periodOptions = {
			historic: {}
		};

		this.initializePeriods();

		this.visualProps.periodLabel = this.visualProps.periodLabel || {};

		if (!this.visualProps.visualization) {
			this.visualProps.visualization = WidgetVisualization.CB_AN_METRIC;
		}
		if (isEmpty(this.visualProps.showSampleSize)) {
			this.visualProps.showSampleSize = true;
		}
		if (_.isUndefined(this.visualProps.yAxisAutoMin))
			this.visualProps.yAxisAutoMin = true;
		if (_.isUndefined(this.visualProps.yAxisAutoMax))
			this.visualProps.yAxisAutoMax = true;

	}

	/**
	 * @deprecated delete in 183+
	 */
	private upgradeProperties(): void {
		if (_.isEmpty(this.props.comparisons)) {
			this.props.comparisons = [];
		}
		if (this.props.useHistoricPeriod && _.isEmpty(this.props.comparisons)) {
			this.props.comparisons = MetricComparisonUtils.getComparisonsFromProperties(this.props, this.visualProps);
		}
	}

	onWidgetMetricConfigured(metric): void {
		let popField = this.metricWidgetPOPService.getMetricPoPField(metric);
		let isStatistical = popField === PeriodOverPeriodMetricType.P_VALUE
			|| popField === PeriodOverPeriodMetricType.SIGNIFICANCE;
		if (isStatistical && !this.periodOverPeriodMetricService.isStatisticalMetricsSupported(metric)) {
			metric.popField = PeriodOverPeriodMetricType.DELTA;
		}
		this.redrawReport();
	}

	redrawReport = (): void => {
		this.staticData.data.data = this.generateDemoData();

		let refreshType: PreviewChartRefreshType = this.shouldSkipCheckChangesForRealDataPreview()
			? PreviewChartRefreshType.UI_ONLY
			: PreviewChartRefreshType.DATA_REQUEST;

		this.updateChartSettings(refreshType);
	};

	private shouldSkipCheckChangesForRealDataPreview(): boolean {
		return this.showRealDataPreview() && _.isEmpty(this.props.selectedMetrics);
	}

	private generateDemoData(): any {
		let data = {};
		this.populateDemoMetricValues(data, StandardMetricName.VOLUME, 10000);
		if (this.props.selectedMetrics) {
			this.props.selectedMetrics.forEach((selectedMetric) => {
				if (selectedMetric.name !== StandardMetricName.VOLUME) {
					this.populateDemoMetricValues(data, selectedMetric.name);
				}
			});
		}
		return [data];
	}

	private populateDemoMetricValues(data: any, metricName: string, koeff = 1): any {
		let currentPeriod = Math.random() * koeff;
		data[metricName] = currentPeriod;

		let comparisons: MetricWidgetComparison[] = this.props.comparisons || [];
		let previousPeriod = this.props.useHistoricPeriod;
		if (previousPeriod && _.isEmpty(comparisons)) {
			let historicPeriod = Math.random() * koeff;
			data[`period_2_${metricName}`] = historicPeriod;
			let delta = currentPeriod - historicPeriod;
			data[`delta_${metricName}`] = delta;
			if (historicPeriod !== 0) {
				data[`percentChange_${metricName}`] = delta / historicPeriod;
			}
			data[`pValue_${metricName}`] = Math.random();
			let valueLessThan = 5;
			data[`significance_${metricName}`] = Math.floor(Math.random() * Math.floor(valueLessThan));
		}

		_.each(comparisons, comparison => {
			let comparisonValue = Math.random() * koeff;
			data[`${metricName}_${comparison.identifier}_value`] = comparisonValue;
			let delta = currentPeriod - comparisonValue;
			let percent = comparisonValue === 0 ? undefined : delta / comparisonValue;
			data[`${metricName}_${comparison.identifier}_diff`] = comparison.calculation === PeriodOverPeriodMetricType.PERCENT_CHANGE
				? percent
				: delta;
		});

		return data;
	}


	isPoPFieldSelected = (metric: ReportCalculation, popField: PeriodOverPeriodMetricType): boolean => {
		let metricPoPField = this.metricWidgetPOPService.getMetricPoPField(metric);
		return metricPoPField === popField;
	};

	selectPoPField = (metric: ReportCalculation, popField: PeriodOverPeriodMetricType) => {
		metric.popField = popField;
	};

	showTotalCount = () => {
		return !isEmpty(this.staticData.data?.metadata.totalCount) && this.visualProps.showSampleSize;
	};

	configureMetric = (metric: ReportCalculation): void => {
		// TODO move to a component rather than mixing with existing metric-configuration controller
		this.configure(metric, KeyMetricListTypes.CALCULATION, this.getConfigurerOptions(),
			'partials/widgets/settings/cb/definitions/metric-widget-configuration-modal.html');
	};

	private getConfigurerOptions(): WidgetMetricConfigurationOptions[] {
		let result = [];
		if (this.visualProps.trendArrow) {
			result.push(WidgetMetricConfigurationOptions.TREND_ARROW);
		}
		if (this.visualProps.previousPeriod) {
			result.push(WidgetMetricConfigurationOptions.PREVIOUS_PERIOD);
		}
		return result;
	}

	showPlus = (index): boolean => {
		let items = this.props.selectedMetrics;
		return !!((items.length < this.MAX_CALCULATIONS)
			&& (index === items.length - 1)
			&& (items[index])
			&& this.visualProps.visualization !== WidgetVisualization.GAUGE);
	};

	metricChanged = (oldItem, newItem): void => {
		this.updateColorFilter(newItem);
		this.removeMetric(oldItem);
		this.addMetric(newItem); //.then(() => this.updatePopFields());
		this.validatePoPCalculationField(newItem);
	};

	private validatePoPCalculationField(metric): void {
		_.forEach(this.props.comparisons, (comparison) => {
			let calculation = comparison.calculation;
			let popMetric = this.periodOverPeriodMetricService.getPeriodOverPeriodMetricByType(calculation);
			if (!popMetric.isSupported(metric, [])) {
				comparison.calculation = this.metricWidgetPOPService.getMetricPoPField(metric);
			}
		});
	}

	private updateColorFilter(selectedMetric: ReportCalculation): void {
		if (CalculationColorUtils.isCalculationColor(this.visualProps.color)
				&& !CalculationColorUtils.isSameCalculationColor(this.visualProps.color, selectedMetric)) {
			delete this.visualProps.color;
		}
		if (selectedMetric?.definition?.calculation?.colorPalette) {
			this.metricColorFilter = (palette: IColorSelectorPalette) => {
				return palette.type === PaletteType.SOLID ||
					palette.type === PaletteType.PALETTE ||
					palette.rawName === selectedMetric.name;
			};
		} else {
			this.metricColorFilter = (palette: IColorSelectorPalette) => {
				return palette.type === PaletteType.SOLID ||
					palette.type === PaletteType.PALETTE;
			};
		}
	}

	processMetricSelection = (metric) => {
		if (this.showRealDataPreview()) {
			if (this.props.selectedMetrics.length > 0) {
				this.$timeout(() => {
					this.visualizationUpdateRequired = true;
					this.selectMetric(metric);
				});
			} else {
				this.selectMetric(metric);
			}
		} else {
			this.selectMetric(metric);
		}
	};

	selectMetric = (metric) => {
		this.addMetric(metric).then(() => {
			if (this.props.selectedMetrics.length > 1) {
				// TODO need to separate metric from multi-metric visualization after beta,
				// i.e. set visualization right away in settings rather than figure it out by metric count
				this.visualProps.visualization = WidgetVisualization.CB_AN_METRIC;
			}
			this.realDataPreviewService.setPreviewHasChanges(true);
			this.redrawReport();
		});
		this.hideNewMetricSelector();
	};

	processMetricRemove = (metric) => {
		if (this.showRealDataPreview() && this.props.selectedMetrics.length > 1) {
			this.realDataPreviewService.setPreviewHasChanges(true);
			setTimeout(() => {
				this.visualizationUpdateRequired = true;
				this.removeMetric(metric);
			});
		} else {
			this.removeMetric(metric);
		}
	};

	showNewMetricSelector = (): void => {
		delete this.newMetric;
		this.showingNewMetric = true;
	};

	hideNewMetricSelector = (): void => {
		delete this.newMetric;
		this.showingNewMetric = false;
	};

	onComparisonsChanged = () => {
		if (_.isEmpty(this.props.comparisons)) {
			this.visualProps.previousPeriod = false;
			this.visualProps.trendArrow = false;
		} else {
			// if this is the first comparison being added, turn on previousPeriod automatically
			this.visualProps.previousPeriod = this.props.comparisons.length === 1 || this.visualProps.previousPeriod;
		}

		this.redrawReport();
	};

	openAdvancedOptions = () => {
		this.highchartsUtils.showAdvancedChartOptionsDialog(
			this.widget,
			false,
			'',
			this.locale.getString('widgetSettings.gaugeRange'),
			'',
			false)
			.then(this.redrawReport);
	};

	onVisualizationChange = () => {
		if (this.visualProps.visualization === WidgetVisualization.CB_AN_METRIC
				&& _.size(this.props.comparisons) > 1) {
			this.props.comparisons = _.first(this.props.comparisons, 1);
		}
		if (this.isGauge()) {
			this.ensureSetGaugeColorMode();
			this.updateColorFilter(this.props.selectedMetrics[0]);
		}
		this.redrawReport();
	};

	showPopSettings = () => {
		return !_.isEmpty(this.props.comparisons) && this.visualProps.visualization === WidgetVisualization.CB_AN_METRIC;
	};

	isGauge(): boolean {
		return this.visualProps.visualization === WidgetVisualization.GAUGE;
	}

	/**
	 * Ensure that a color mode is set for backward compatibility
	 */
	private ensureSetGaugeColorMode(): void {
		this.visualProps.colorMode = this.visualProps.colorMode || GaugeColorMode.SEGMENTED;
	}

}

app.component('metricWidgetSettings', {
	bindings: {
		ui: '<',
		widget: '<',
		visualProps: '<',
		props: '<',
		options: '<',
		utils: '<',
		staticData: '<',
		redrawTrigger: '<',
		reloadSettings: '<',
		broadcastSettings: '<',
		addLoadingPromise: '<',
		loadingPromise: '<',
		clearErrors: '<',
		updateAutoTitle: '<',
		updateChartSettings: '<',
		showRealDataPreview: '<',
		updateDataLoading: '<',
		realDataLoading: '<',
		dashboardFilters: '<',
		isInheritingDashboardDateFilter: '<',
		intersectionObserver: '<'
	},
	controller: MetricWidgetSettingsComponent,
	templateUrl: 'partials/widgets/settings/cb/components/metric-widget-settings.component.html'
});
