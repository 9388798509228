import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
	selector: 'about-dialog',
	template: `
<div class="br-about">
    <div class="br-about-image p-16 bg-white">
        <img class="w-max-100-percent" src="img/xm-small.png" height="30">
    </div>
    <div class="br-about-text p-16">
		<p class="mb-0 font-bold" [i18n]="'header.appName'"></p>
		<p class="mb-0" [i18n]="'header.poweredBy'" [i18nParams]="{version: version, revision: revision}"></p>
		<p class="mb-0" [i18n]="'header.copyright'" [i18nParams]="{year: currentYear}"></p>
    </div>
</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutDialogComponent implements OnInit {
	version: string;
	revision: string;
	currentYear: number;
	isDarkMode: boolean;

	constructor(
		private modal: NgbActiveModal,
		private applicationThemeService: ApplicationThemeService
	) { }

	ngOnInit(): void {
		this.version = CONFIG.version;
		this.revision = CONFIG.revision.split('_')[0];
		this.currentYear = moment().tz('US/Eastern').year();

		this.isDarkMode = this.applicationThemeService.isShowingDarkTheme();
	}

	cancel = (): void => {
		this.modal.dismiss();
	};
}

app.directive('aboutDialog',
	downgradeComponent({component: AboutDialogComponent}));
