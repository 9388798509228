import { Component, OnInit, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { MetricComparisonType, MetricWidgetComparison } from '@cxstudio/reports/entities/metric-widget-properties';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { PeriodOverPeriodMetricType } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric-type';
import { PeriodOverPeriodMetricService } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { KeyboardUtils } from '@app/shared/util/keyboard-utils.class';

export interface IComparisonConfigurationInput {
	comparison: MetricWidgetComparison;
	displayName: string;
	metric?: ReportCalculation;
	metrics: Metric[];
}

interface ComparisonCalculation {
	field: PeriodOverPeriodMetricType;
	symbol: string;
	cssClass: string;
}

@Component({
	selector: 'comparison-configuration-modal',
	templateUrl: './comparison-configuration-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComparisonConfigurationModalComponent implements OnInit {

	@Input() input: IComparisonConfigurationInput;

	comparison: MetricWidgetComparison;

	popMetricOptions: ComparisonCalculation[];

	constructor(
		private modal: NgbActiveModal,
		private locale: CxLocaleService,
		@Inject('periodOverPeriodMetricService') private periodOverPeriodMetricService: PeriodOverPeriodMetricService,
	) {}

	ngOnInit(): void {
		this.comparison = this.input.comparison;
		this.initPoPMetricOptions();
	}

	save(): void {
		this.modal.close(this.comparison);
	}

	cancel(): void {
		this.modal.dismiss();
	}

	private initPoPMetricOptions(): void {
		let metric = this.input.metric;
		if (!metric) {
			this.popMetricOptions = [];
			return;
		}

		let options = [{
			field: PeriodOverPeriodMetricType.DELTA,
			symbol: 'Δ',
			cssClass: '',
		}];
		if (this.periodOverPeriodMetricService.getPeriodOverPeriodMetricByType(PeriodOverPeriodMetricType.PERCENT_CHANGE)
			?.isSupported(metric, this.input.metrics)) {
			options.push({
				field: PeriodOverPeriodMetricType.PERCENT_CHANGE,
				symbol: '%',
				cssClass: '',
			});
		}
		if (this.comparison.type === MetricComparisonType.TIME
				&& this.periodOverPeriodMetricService.isStatisticalMetricsSupported(metric)) {
			options.push({
				field: PeriodOverPeriodMetricType.P_VALUE,
				symbol: '𝑝',
				cssClass: 'p-value'
			});
			options.push({
				field: PeriodOverPeriodMetricType.SIGNIFICANCE,
				symbol: '*',
				cssClass: 'significance'
			});
		}
		this.popMetricOptions = options;
	}

	getCalcOptionTitle(option: any): string {
		if (option.field === PeriodOverPeriodMetricType.PERCENT_CHANGE && this.comparison.type === MetricComparisonType.GOAL) {
			return this.locale.getString('widget.goalPercentChangeTooltip');
		}
		return '';
	}

	isCalcOptionSelected(calculation: ComparisonCalculation): boolean {
		return calculation.field === this.comparison.calculation;
	}

	selectCalcOption(calculation: ComparisonCalculation): void {
		this.comparison.calculation = calculation.field;
	}

	showNumberFormatPanel(): boolean {
		let comparisonType: MetricComparisonType = this.comparison.type;
		return comparisonType === MetricComparisonType.GOAL || comparisonType === MetricComparisonType.HIERARCHY_ENRICHMENT;
	}

	// this function is required until we have ng9 outer modal dialog
	closeComparisonConfigurationModal(event: KeyboardEvent): void {
		KeyboardUtils.intercept(event);
		this.modal.dismiss();
	}
}
