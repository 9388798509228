import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import ContentProviderManagementApiService from '@app/modules/system-administration/platform/content-provider/content-provider-management-api.service';
import { AdminWorkspace } from '@app/modules/system-administration/platform/workspace/admin-workspace';
import { WorkspaceEditDialogComponent } from '@app/modules/system-administration/platform/workspace/workspace-edit-dialog/workspace-edit-dialog.component';
import WorkspaceManagementApiService from '@app/modules/system-administration/platform/workspace/workspace-management/workspace-management-api.service';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { Subject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class WorkspacesListActions {
	private readonly listChangeSubject = new Subject<void>();

	constructor(
		private readonly workspaceManagementApi: WorkspaceManagementApiService,
		private readonly contentProviderManagementApiService: ContentProviderManagementApiService,
		private readonly dialogService: CxDialogService,
		private readonly locale: CxLocaleService,
		private readonly toastService: ToastService,
		@Inject('globalNotificationService') private globalNotificationService: GlobalNotificationService
	) {}

	getListChangeObservable(): Observable<void> {
		return this.listChangeSubject.asObservable();
	}

	edit(workspace: AdminWorkspace, workspaces: AdminWorkspace[]): Promise<void> {
		const input = { workspace, workspaces };
		return this.dialogService.openDialog(WorkspaceEditDialogComponent, input).result
			.then((updatedWorkspace) => this.workspaceManagementApi.updateWorkspace(updatedWorkspace))
			.then(() => {
				const message = this.locale.getString('common.itemUpdated', {
					itemName: workspace.name
				});
				this.toastService.addToast(message, AlertLevel.CONFIRM);
				this.listChangeSubject.next();
			})
			.catch(_.noop);
	}

	requestSync(workspace: AdminWorkspace): Promise<void> {
		return this.contentProviderManagementApiService.syncAccountNames(workspace.contentProviderId)
			.then(() => this.listChangeSubject.next());
	}

	create(workspaces: AdminWorkspace[]): Promise<void> {
		return this.dialogService.openDialog(WorkspaceEditDialogComponent, { workspaces }).result
			.then((savedWorkspace) => this.workspaceManagementApi.createWorkspace(savedWorkspace))
			.then((workspace) => {
				const message = this.locale.getString('common.itemAdded', {
					itemName: workspace.name
				});
				this.toastService.addToast(message, AlertLevel.CONFIRM);
				this.listChangeSubject.next();
			})
			.catch(_.noop);
	}

	delete(workspace: AdminWorkspace): Promise<void> {
		return this.dialogService.warningWithConfirm(
			this.locale.getString('common.pleaseConfirm'),
			this.locale.getString('common.confirmDeleteItem', { itemName: workspace.name }),
			this.locale.getString('common.delete')).result
			.then(() => {
				if (workspace.masterAccounts?.isEmpty()) {
					this.workspaceManagementApi.deleteWorkspace(workspace.id).then(() => {
						const toastMessage = this.locale.getString('common.itemDeleted', {
							itemName: workspace.name
						});
						this.toastService.addToast(toastMessage, AlertLevel.CONFIRM);
						this.listChangeSubject.next();
					});
				} else {
					const warningMessage = this.getWorkspaceDeletionWarning(workspace);
					this.globalNotificationService.showWarning(warningMessage, 5);
				}
			})
			.catch(_.noop);
	}

	private getWorkspaceDeletionWarning(workspace: AdminWorkspace): string {
		if (workspace.masterAccounts?.length === 1) {
			return this.locale.getString('administration.workspaceDeleteSingleWarning', {
				name: workspace.masterAccounts[0].name
			});
		}

		const masterAccountName = _.chain(workspace.masterAccounts)
			.map((workspaceMasterAccount) => workspaceMasterAccount.name)
			.sort()
			.first()
			.value();

		return this.locale.getString('administration.workspaceDeleteMultipleWarning', {
			name: masterAccountName,
			amount: workspace.masterAccounts?.length
		});
	}
}
