import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { AllowedDomains } from '@app/modules/system-administration/master-account/email-domains/allowed-domains';


@Injectable({
	providedIn: 'root'
})
export class DomainsProcessingService {
	validateLowerCaseEmailDomain(allowedDomains: AllowedDomains, domain: string): boolean {
		if (!allowedDomains || !domain?.length) {
			return false;
		}
		if (!allowedDomains.enabled) {
			return true;
		}
		let exists = false;
		if (allowedDomains.defaultAllowedDomains) {
			_.each(allowedDomains.defaultAllowedDomains.concat(allowedDomains.userAllowedDomains),
				(allowedDomain) => {
					if (domain.toLowerCase() === allowedDomain.toLowerCase()) {
						exists = true;
					}
				});
		}
		return exists;
	}

	isDomainAllowed(defaultAllowedDomains: string[], membershipSelectedDomains: string[], domain: string): boolean {
		let exists = false;
		if (defaultAllowedDomains && membershipSelectedDomains) {
			_.each(defaultAllowedDomains.concat(membershipSelectedDomains),
				(allowedDomain) => {
					if (domain.toLowerCase() === allowedDomain.toLowerCase()) {
						exists = true;
					}
				});
		}
		return exists;
	}
}

app.service('domainsProcessingService', downgradeInjectable(DomainsProcessingService));
