import { PointSelectionUtils } from '@cxstudio/reports/utils/analytic/point-selection-utils.service';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { HighchartsClosureUtils } from '@app/modules/widget-visualizations/highcharts/highcharts-closure-utils.class';
import { CloudProcessorService } from '@app/modules/widget-visualizations/utilities/cloud-processor.service';
import * as cloneDeep from 'lodash.clonedeep';

export class CloudRendererService {
	constructor(
		private readonly reportUtils: ReportUtils,
		private readonly pointSelectionUtils: PointSelectionUtils,
		private readonly cloudD3Utils,
		private readonly highchartsUtils,
		private readonly cloudProcessor: CloudProcessorService
	) { }

	render = (reportDefinition, words: any[], width: number, height: number, textSize: number, selectedPoint, isDemo: boolean) => {

		let formatName = this.getNameFormatter(reportDefinition.utils);
		this.cloudD3Utils.populateDefaultOptions(reportDefinition.options);

		let wordsElemArray = [];

		// trick to use highcharts tooltip functionality

		let maxSize = 0;
		let isAsc = true;
		let i;
		let totalSize = 0;
		let totalLength = 0;

		for (i = 0; i < words.length; i++) {
			//size = whatever to base word's size off (volume, attribute, constant size, etc)
			let size = reportDefinition.utils.sizeFunction(words[i]);
			let rawSize = reportDefinition.utils.getRawSize(words[i]);
			if (size < 0 && !isDemo) {
				return false;
			}

			words[i].cnt = size;
			words[i].rawValue = rawSize;
			totalSize += size;
			totalLength += words[i].name.length;
			$.extend(words[i], { // for using highcharts tooltip
				series: {
					tooltipOptions: {
						followPointer: true,
						pointFormatter: this.cloudD3Utils.getPointFormatter(reportDefinition.utils, textSize),
						headerFormat: '',
						pointFormat: '<span style="color:{point.color}">\u25CF</span> {point.key}: <b>{point.y}</b><br/>',
						footerFormat: ''
					},
					chart: {
						styledMode: false
					},
					options: {},
					shouldShowTooltip: () => true
				},
				getLabelConfig: HighchartsClosureUtils.closureWrapper((scope: any) => {
					return {
						key: scope.displayName,
						x: scope.name,
						y: scope.cnt,
						color: scope.color,
						point: scope,
						series: scope.series,
						rawValue: scope.rawSize
					};
				}, ['cnt', 'rawSize']),
				displayName: formatName(words[i].name)
			});
			if (size > maxSize) {
				maxSize = size;
				isAsc = (i === 0);
			}
		}

		let avgSize = totalSize / words.length ;
		let avgLength = totalLength / words.length ;
		let wordSpaceCoefficient = this.cloudD3Utils.generateWordSpaceCoefficient(avgSize,
			avgLength, width, height, words.length, reportDefinition.options.cloudSizing);
		let maxNormWordScale = 0;
		let maxWordLength = 25;
		let firstElements = Math.min(words.length, 10);
		if (isAsc) {
			for (i = 0; i < firstElements; i++) {
				if ((words[i].cnt / maxSize) * words[i].displayName.length > maxNormWordScale) {
					maxNormWordScale = (words[i].cnt / maxSize) * words[i].displayName.length;
				}
			}
		} else {
			for (i = words.length - firstElements; i < words.length; i++) {
				if ((words[i].cnt / maxSize) * words[i].displayName.length > maxNormWordScale) {
					maxNormWordScale = (words[i].cnt / maxSize) * words[i].displayName.length;
				}
			}
		}

		let minFontSize = reportDefinition.options.minimumFontSize;
		let maxFontSize = Math.min(height / 4, width / (maxNormWordScale * 1.5));

		for (i = 0; i < words.length; i++) {
			let word = words[i].displayName;
			if (word.length > maxWordLength) {
				word = word.substr(0, maxWordLength - 3) + '...';
			}
			word = this.highchartsUtils.cleanStringForDisplay(word);
			words[i].displayName = this.highchartsUtils.cleanStringForDisplay(words[i].displayName);
			let calculatedSize = minFontSize
				+ ((wordSpaceCoefficient * words[i].cnt * (maxFontSize - minFontSize)) / maxSize);

			wordsElemArray[i] = {
				text: word,
				size: Math.min(calculatedSize, maxFontSize),
				code: i
			};
		}

		let angles = this.cloudD3Utils.getAnglesArray(reportDefinition.options.orientations);
		let spiral = this.cloudD3Utils.getSpiralFunctionName(reportDefinition.options.orientations);
		let draw = this.getDrawFunction(reportDefinition, words, width, height, textSize, selectedPoint, i);

		if (reportDefinition.lastWords && reportDefinition.lastWords.width === width &&
			reportDefinition.lastWords.height === height &&	reportDefinition.lastWords.textSize === textSize) {
			draw(reportDefinition.lastWords.cloudWords);
		} else {
			this.cloudProcessor.process({
				width,
				height,
				words: wordsElemArray,
				angles,
				spiral,
				maxWordLength
			}, draw);
		}

		// successfully rendered
		return true;
	};

	private getDrawFunction(reportDefinition, words: any[], width: number, height: number,
		textSize: number, selectedPoint, i: number): (cloudWords: any) => void {
		let renderer = reportDefinition.chart.renderer;

		return (cloudWords): void => {
			if (!renderer.box) {
				// chart is already detached, e.g. replaced with another
				return;
			}

			reportDefinition.lastWords = {
				cloudWords,
				width,
				height,
				textSize
			};

			let group = renderer.g('series').add();
			let hasSelections = false;
			let mousemove = this.getMousemove(reportDefinition, words);
			let mouseout = this.getMouseout(reportDefinition);

			for (let d of cloudWords) {
				let fillColor = reportDefinition.utils.colorFunction(words[d.code], i);
				if (_.isObject(fillColor) && fillColor.pattern) {
					fillColor = fillColor.pattern.color;
				}

				let word = renderer.text(d.text)
					.css({
						'font-size': d.size + 'px',
						'font-family': 'sans-serif',
						fill: (words[d.code].color = fillColor)
					}).attr({
						index: d.code,
						tabindex: -1,
						'text-anchor': 'middle',
						transform: 'translate(' + [ d.x + width / 2, d.y + height / 2 ]
														+ ')rotate(' + d.rotate + ')'
					}).addClass('cloud-word')
					.on('mousemove', mousemove(d, false))
					.on('mouseout', mouseout(d))
					.on('focus', mousemove(d, true))
					.on('blur', mouseout(d))
					.add(group);
				if (selectedPoint === words[d.code].name) {
					this.pointSelectionUtils.highlightElement(word.element);
					hasSelections = true;
				}
			}

			if (!reportDefinition.demo &&
				this.pointSelectionUtils.isPointSelectionEnabled(reportDefinition.utils.containerId,
					reportDefinition.utils.widgetId)) {
				this.pointSelectionUtils.enableWrongSelectionWarning(reportDefinition.utils.containerId,
					reportDefinition.utils.widgetId, !hasSelections);
			}

			reportDefinition.ready = true;
			this.reportUtils.handleWidgetRenderedEvent(reportDefinition.utils.widgetId,
				reportDefinition.utils.widgetType, reportDefinition.utils.containerId);
		};
	}


	private getMousemove(reportDefinition, words): (...args) => any {
		return (word, isFocus) => {
			return (event: MouseEvent) => {
				let w = words[word.code];
				reportDefinition.selectedPoint = cloneDeep(w);
				let textElement = $(event.target);
				if (textElement.is('tspan')) {
					textElement = textElement.parent();
				}
				if (isFocus) {
					let offset = textElement.offset();
					(event as any).pageX = offset.left + (textElement.width() / 2);
					(event as any).pageY = offset.top;
				}
				reportDefinition.selectedPoint._element = textElement;
				reportDefinition.chart.tooltip.refresh(w, event);
			};
		};
	}

	private getMouseout(reportDefinition): (...args) => () => void {
		return () => {
			return () => {
				reportDefinition.selectedPoint = null;
				reportDefinition.chart.tooltip.hide();
			};
		};
	}

	private getNameFormatter(utils): (name: string) => string {
		return (name: string) => {
			let formatter = utils.getGroupingFormatter();
			return formatter ? formatter(name) : name;
		};
	}
}

app.service('cloudRenderer', CloudRendererService);
