import * as _ from 'underscore';
import { MetricManagementApiService } from '@cxstudio/metrics/api/metric-management-api.service';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { IReportModel } from '@app/modules/project/model/report-model';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { AccountOrWorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { HierarchyEnrichmentProperty, OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { ReportAssetType}  from '@cxstudio/reports/entities/report-asset-type';
import { HierarchyMode } from '@cxstudio/organizations/hierarchy-mode';


export interface IHierarchySettings {
	hierarchyModels: IReportModel[];
	hierarchyGrouping: ReportGrouping;
	organizationCalculations: IOrganizationCalculations;
}

export interface IOrganizationCalculations {
	enrichment: HierarchyEnrichmentProperty[];
	custom: any[];
}

export default class HierarchySettingsService {

	constructor(
		private $q: ng.IQService,
		private httpHandlers: HttpHandlers,
		private metricApiService: MetricManagementApiService,
		private hierarchyService,
		private organizationApiService: OrganizationApiService,
	) {}

	getWidgetSettings = (props: WidgetProperties): ng.IPromise<IHierarchySettings> => {
		let proj = {
			contentProviderId: props.contentProviderId,
			accountId: props.accountId,
			projectId: props.project,
		} as AccountOrWorkspaceProjectData;
		let hierarchyModelsPromise = this.organizationApiService.getOrgHierarchyModels(proj);
		let hierarchyGrouping = this.hierarchyService.findSingleHierarchyGrouping(props);
		let organizationCalculationsPromise = hierarchyGrouping
			? this.getOrganizationCalculations(hierarchyGrouping)
			: this.$q.when({} as IOrganizationCalculations);

		let resultPromise = this.$q.all([
			PromiseUtils.old(hierarchyModelsPromise),
			organizationCalculationsPromise
		]).then((resultArray: any) => {
			return {
				hierarchyModels: resultArray[0],
				hierarchyGrouping,
				organizationCalculations: resultArray[1]
			};
		});
		return resultPromise;
	};

	getOrganizationCalculations = (hierarchyGrouping: ReportGrouping): ng.IPromise<IOrganizationCalculations> => {
		let hierarchyId = parseInt(hierarchyGrouping.name, 10);
		let deferred: ng.IDeferred<IOrganizationCalculations> = this.$q.defer();
		let result: IOrganizationCalculations = {} as IOrganizationCalculations;

		this.$q.all([
			PromiseUtils.old(this.organizationApiService.getOrganizationCalculations(hierarchyId, { reportableOnly: true, numericOnly: false })),
			this.metricApiService.getHierarchyCustomMetrics(hierarchyId)
		]).then((responses) => {
			result = {
				enrichment: responses[0],
				custom: responses[1].data
			};
		}).finally(() => {
			deferred.resolve(result);
		});

		return deferred.promise;
	};

	filterSelectedAttributesForGrouping = (items: any[], isPersonalizationEnabled = false, appliedHierarchyId = null): any[] => {
		const hierarchyModel = items?.find(item => item.name === ReportAssetType.HIERARCHY_MODEL);
		if (hierarchyModel && hierarchyModel.children && !isPersonalizationEnabled) {
			hierarchyModel.children = hierarchyModel.children.filter(child => child.hierarchyMode !== HierarchyMode.DYNAMIC_FILTERING);
		} else if (hierarchyModel && hierarchyModel.children && isPersonalizationEnabled) {
			// name is id for object, id is a number so need to use parseInt
			hierarchyModel.children = hierarchyModel.children.filter(child =>
				parseInt(child.name,  10) === appliedHierarchyId || (child.hierarchyMode !== HierarchyMode.DYNAMIC_FILTERING));
		}
		if (hierarchyModel?.children.length === 0) {
			items.remove(hierarchyModel);
		}
		return items;
	};
}

app.service('hierarchySettingsService', HierarchySettingsService);
