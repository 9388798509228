import { Label } from '@cxstudio/dashboards/entity/label';
import { Input, forwardRef, Inject, Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { User } from '@cxstudio/user-administration/users/entities/user';
import { SystemAdminApiService } from '@cxstudio/services/data-services/system-admin-api.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
	selector: 'account-owners-input',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AccountOwnersInputComponent), multi: true},
	],
	templateUrl: './account-owners-input.component.html'
})
export class AccountOwnersInputComponent implements ControlValueAccessor {
	@Input() masterAccountId: number | string;

	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	private onTouchedCallback: () => void = _.noop;
	private onChangeCallback: (val: User[]) => void = _.noop;

	owners: User[];
	values: string[];
	users: User[];

	constructor(
		@Inject('systemAdminApiService') private systemAdminApiService: SystemAdminApiService,
		private ref: ChangeDetectorRef,
	) {
	}

	ownerAdded = (value: string): void => {
		this.owners.push(this.findUser(value));
		this.onChange();
	};

	ownerRemoved = (value: string): void => {
		this.owners = _.without(this.owners, this.findUser(value));
		this.onChange();
	};

	private findUser = (value: string): User => {
		let owner = _.findWhere(this.owners, { userEmail: value});
		return owner
			? owner
			: _.findWhere(this.users, { userEmail: value});
	};

	tagsSource = (query: string): Promise<any[]> => {
		return this.masterAccountId
			? PromiseUtils.wrap(this.systemAdminApiService
				.getMasterAccountAdminOrgUsersSuggestion(this.masterAccountId, query)
				.then(response => {
					this.users = response.data;

					return _.map(this.users, (user: User) => user.userEmail);
				}))
			: Promise.resolve([]);
	};

	private onChange() {
		this.onChangeCallback(this.owners);
		this.onTouchedCallback();
	}

	//ControlValueAccessor
	writeValue(value: any): void {
		if (value && value !== this.owners) {
			this.owners = value;
			this.values = this.owners.map(label => label.userEmail);
			this.ref.markForCheck();
		}
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}
}
