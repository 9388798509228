import * as _ from 'underscore';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { VisualizationType } from '@cxstudio/reports/visualization-types.constant';
import { SelectorWidgetNavigationType } from '@app/modules/widget-settings/selector-widget/selector-widget-navigation-type.enum';
import { HeatmapVisualizationsService, HeatmapSubtype } from '@cxstudio/reports/providers/cb/constants/heatmap-visualizations.service';
import { SampleDataAPIServiceClass } from '@cxstudio/services/data-services/sample-data-api.service';
import { MetricConstants } from '../constants/metric-constants.service';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { IWidgetSettingsConfig, IWidgetSettings } from '../services/widget-settings.service';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import GeographyReportService from '@cxstudio/attribute-geography/geography-report.service';
import ProjectGeographies from '@cxstudio/attribute-geography/project-geographies';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { GeographyOptionsService } from '@cxstudio/attribute-geography/geography-options.service';
import { BoundaryField } from '@cxstudio/attribute-geography/boundary-field';
import { BoundaryTypeDefaults } from '@cxstudio/attribute-geography/boundary-type-defaults';
import { CBSettingsService, IWidgetSettingsScope } from '@cxstudio/reports/providers/cb/services/cb-settings-service.service';
import ChartType from '@cxstudio/reports/entities/chart-type';
import { IReportModel } from '@app/modules/project/model/report-model';
import { DatePeriodField, DatePeriodName } from '@cxstudio/reports/entities/date-period';
import { VisualizationOptionsService } from '@cxstudio/reports/settings/visualization-options.service';
import { GeographyApiService } from '@cxstudio/attribute-geography/geography-api.service';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import HierarchySettingsService from '@cxstudio/reports/providers/cb/services/hierarchy-settings.service';

export interface IMapAlikeCommonSettings {
	initialize: () => void;
	initProps: () => void;
	reloadSettings: (event, callback) => void;
	resetSelections: () => void ;
	checkDisableLabels: (node) => void;
	processAttributesAndMetrics: (metricFields: string[]) => void;
	populateSampleData: () => ng.IPromise<void>;
}

interface IMapAlikeSettingsScope extends IWidgetSettingsScope {
	dashboardFilters: boolean | any;
	personalization: any;
	metricsForColoring: (metricsForColoring: any) => [];
	disableLabels: boolean;
	hierarchyModels: IReportModel[];
	selectMetric: (metric: any) => void;

}

// eslint-disable-next-line prefer-arrow-callback
app.factory('MapAlikeCommonSettings', function(cbSettingsService: CBSettingsService,
	sampleDataApiService: SampleDataAPIServiceClass,
	ReportPeriods,
	visualizationOptionsService: VisualizationOptionsService,
	HeatmapVisualizations: HeatmapVisualizationsService,
	metricConstants: MetricConstants,
	betaFeaturesService: BetaFeaturesService,
	optionsBuilderProvider: OptionsBuilderProvider,
	geographyReportService: GeographyReportService,
	geographyOptionsService: GeographyOptionsService,
	hierarchySettingsService: HierarchySettingsService,
	geographyApiService: GeographyApiService,
) {

	return class MapAlikeCommonSettingsFactory implements IMapAlikeCommonSettings {
		readonly constants = metricConstants.get();
		private loaded: boolean = false;

		constructor(private widgetScope: IMapAlikeSettingsScope, private visualization: VisualizationType | SelectorWidgetNavigationType) { }

		initialize = (): void => {
			this.widgetScope.addLoadingPromise(this.populateSampleData().then(() => {
				this.widgetScope.visualProps.visualization = this.visualization;

				if (_.isUndefined(this.widgetScope.visualProps.showLabels) || this.widgetScope.visualProps.showLabels === null) {
					this.widgetScope.visualProps.showLabels = true;
				}

				if (isEmpty(this.widgetScope.visualProps.showSampleSize)) {
					this.widgetScope.visualProps.showSampleSize = true;
				}

				if (!this.widgetScope.visualProps.sortBy) this.widgetScope.visualProps.sortBy = StandardMetricName.VOLUME;
				if (!this.widgetScope.visualProps.direction) this.widgetScope.visualProps.direction = SortDirection.DESC;


				this.widgetScope.applyVisualChanges();
			}));
			this.widgetScope.ui.heatmapVisualizations = visualizationOptionsService.buildHeatmapVisualizations(
				[HeatmapSubtype.DEFAULT, HeatmapSubtype.ROW, HeatmapSubtype.COLUMN]);
		};

		populateSampleData = (): ng.IPromise<void> => {
			return this.generateSampleData().then((resp) => {
				let data = resp.data;

				this.widgetScope.staticData.data = data;
				this.widgetScope.staticData.totalCount = 157;
				this.widgetScope.staticData.data.data = _.filter(this.widgetScope.staticData.data.data,
					(dataPoint: any) => dataPoint._pop === ReportPeriods.CURRENT);
			});
		};

		private generateSampleData = (): ng.IPromise<any> => {
			let props = this.widgetScope.props;

			if (props.widgetType === WidgetType.MAP && !!props.selectedAttributes?.[0]) {
				let grouping = props.selectedAttributes[0];
				let geographiesPromise =  geographyApiService.getProjectGeographies(props.contentProviderId, props.accountId,
					props.project, props.runAs);
				return geographiesPromise.then(geographies => {
					let boundaryField = geographyReportService.getGroupingBoundaryField(grouping, geographies);
					if (!boundaryField) {
						return this.generateRegularMapAlikeData();
					}

					let level = props.selectedAttributes.length - 1;
					return geographyReportService.generateGeographyReport(boundaryField, level).then(result => {
						return { data: result };
					});
				});
			} else {
				return this.generateRegularMapAlikeData();
			}
		};

		private generateRegularMapAlikeData = (): ng.IPromise<any> => {
			let dataTarget = (this.visualization === VisualizationType.HEATMAP) ? 'tree' : 'selector';
			return sampleDataApiService.getHierarchyData(dataTarget);
		};

		initProps = (): void => {

			if (_.isEmpty(this.widgetScope.props.selectedAttributes)) {
				this.widgetScope.props.selectedAttributes = [undefined];
			}

			if (!this.widgetScope.visualProps.attributeSelections) {
				this.widgetScope.visualProps.attributeSelections = {};
			}
			if (!this.widgetScope.props.selectedMetrics) {
				this.widgetScope.props.selectedMetrics = [];

				if (this.visualization === VisualizationType.HEATMAP)
					this.widgetScope.selectMetric(this.constants.VOLUME);
			}

			if (this.visualization === VisualizationType.HEATMAP) {
				if (!this.widgetScope.visualProps.periodLabel) {
					this.widgetScope.visualProps.periodLabel = {} as any;
				}

				this.widgetScope.visualProps.subChartType = this.widgetScope.visualProps.subChartType || ChartType.DEFAULT;
				this.widgetScope.ui.showSortOptions = HeatmapVisualizations.isSortable(this.widgetScope.visualProps.subChartType);
			}

			this.widgetScope.ui.periods = [{
				field: DatePeriodField.PERIOD1,
				name: DatePeriodName.PERIOD1
			}];

			this.widgetScope.initializePeriods();
		};

		reloadSettings = (event, callback: (result: boolean) => void): void => {
			this.initProps();

			let config: IWidgetSettingsConfig = {withScorecardMetrics: betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)};

			this.reloadCommonSettings(config).then(result => {
				this.widgetScope.clearErrors();
				cbSettingsService.initializeCommonSettings(this.widgetScope, result);

				if (this.loaded) {
					this.resetSelections();
				}
				this.loaded = true;

				let availableAttributesBuilder = optionsBuilderProvider.getBuilder(OptionsConstant.GROUP_BY)
					.withModels(result.models)
					.withAttributes(this.widgetScope.options.attributes, MetricFilters.GROUP_FILTER)
					.withWordAttributes(result.wordAttributes)
					.withMetrics(result.metrics, this.widgetScope.props.project)
					.withOrgHierarchyModels(result.models, result.hierarchyModels)
					.withPredefinedGroups(angular.copy(result.predefinedMetrics));

				this.widgetScope.options.availableAttributes = availableAttributesBuilder.build();

				this.widgetScope.options.additionalMetrics = optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
					.withStandardMetrics(_.union(metricConstants.getStandardCalculations(this.widgetScope.props.project),
						[this.constants.CONSTANT_SIZE]))
					.withPredefinedMetrics(result.predefinedMetrics)
					.withAttributes(this.widgetScope.options.attributes, MetricFilters.CALCULATION)
					.withMetrics(result.metrics, this.widgetScope.props.project)
					.withScorecardMetrics(result.scorecardMetrics)
					.filterAvailableOptions(this.widgetScope.sizeMetricsFilter)
					.build();

				this.widgetScope.populateCurrentHierarchyCalculations(this.widgetScope.options.additionalMetrics, result.hierarchyGrouping,
					result.organizationCalculations, result.hierarchyModels);

				let availableMetrics = optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
					.withStandardMetrics(metricConstants.getStandardCalculations(this.widgetScope.props.project))
					.withPredefinedMetrics(result.predefinedMetrics)
					.withAttributes(this.widgetScope.options.attributes, MetricFilters.CALCULATION)
					.withMetrics(result.metrics, this.widgetScope.props.project)
					.build();

				this.widgetScope.populateCurrentHierarchyCalculations(availableMetrics, result.hierarchyGrouping,
					result.organizationCalculations, result.hierarchyModels);

				this.widgetScope.processSelections();

				this.widgetScope.hierarchyModels = angular.copy(result.hierarchyModels);
				this.widgetScope.options.projectGeographies = result.projectGeographies;

				this.widgetScope.options.predefinedMetrics = angular.copy(result.predefinedMetrics);
				this.widgetScope.options.studioMetrics = _.filter(result.metrics, (metric: any) => metric.projectId === this.widgetScope.props.project);

				this.widgetScope.options.sortMetrics = _.clone(availableMetrics);
				this.widgetScope.options.sortMetrics.push(this.constants.ALPHANUMERIC);
				this.widgetScope.options.sortMetrics.push(this.constants.MODEL_ORDER);
				this.widgetScope.options.cogSortByMetrics = angular.copy(availableMetrics);

				cbSettingsService.initDefaultProperties(this.widgetScope.options.availableAttributes);

				this.widgetScope.options.groupingOptions = this.getGroupingOptions(result, this.widgetScope.options.availableAttributes);
				// filter the options based on hierarchy settings
				// for selector, there is widgetScope.personalization but for heatmap there is widgetScope.dashboardFilters.personalization
				this.widgetScope.options.groupingOptions?.forEach(optionsArray => {
					optionsArray = hierarchySettingsService.filterSelectedAttributesForGrouping(
						optionsArray,
						this.widgetScope.dashboardFilters?.personalization?.isHierarchyEnabled() || this.widgetScope?.personalization?.isHierarchyEnabled(),
						this.widgetScope.dashboardFilters?.personalization?.getHierarchyId() || this.widgetScope?.personalization?.getHierarchyId()
					);
				});

				this.widgetScope.updateCustomDateFilters(result.dateFilters);
				this.widgetScope.initAttributesSelectionPanel();
				this.checkDisableLabels(this.widgetScope.visualProps.attributeSelections.size);
				this.widgetScope.applyVisualChanges();
				callback(true);
			});
		};

		resetSelections = (): void =>  {
			this.widgetScope.props.selectedMetrics = [];
			this.widgetScope.props.selectedAttributes = [undefined];
			this.widgetScope.visualProps.color = this.visualization !== SelectorWidgetNavigationType.SELECTOR_BUTTON ? 'custom' : null;
			this.widgetScope.selectMetric(this.constants.VOLUME);

			this.widgetScope.applyVisualChanges();
		};

		checkDisableLabels = (node): void => {
			if (_.isMatch(node, this.constants.CONSTANT_SIZE)) {
				// if they have selected constant size, the labels are worthless, and we should disable them
				this.widgetScope.disableLabels = true;
				this.widgetScope.visualProps.showLabels = false;
			} else {
				this.widgetScope.disableLabels = false;
			}
		};

		processAttributesAndMetrics = (metricFields: string[]): void => {
			this.widgetScope.visualProps.attributeSelections = this.widgetScope.visualProps.attributeSelections || {};
			this.widgetScope.props.selectedMetrics = _.chain(metricFields)
				.map(field => this.widgetScope.visualProps.attributeSelections[field])
				.union(_.values(this.widgetScope.metricsForColoring))
				.filter(metric => metric && !_.isUndefined(metric.name))
				.uniq(metric => metric.name)
				.value();

			this.widgetScope.processSelections();
		};

		private reloadCommonSettings = (config): ng.IPromise<IWidgetSettings> => {
			return this.widgetScope.reloadCommonSettings(config);
		};

		private getGroupingOptions = (resource, availableAttributes): any[] => {
			let primaryGroupingOptionsBuilder = optionsBuilderProvider.getBuilder(OptionsConstant.GROUP_BY)
				.withModels(resource.models)
				.withAttributes(this.widgetScope.options.attributes, MetricFilters.GROUP_FILTER)
				.withWordAttributes(resource.wordAttributes)
				.withMetrics(resource.metrics, this.widgetScope.props.project)
				.withOrgHierarchyModels(resource.models, resource.hierarchyModels)
				.withPredefinedGroups(angular.copy(resource.predefinedMetrics));
			this.filterAvailableAttributes(primaryGroupingOptionsBuilder, resource.projectGeographies);
			let primaryGroupingOptions = primaryGroupingOptionsBuilder.build();
			cbSettingsService.initDefaultProperties(primaryGroupingOptions);
			if (this.visualization === VisualizationType.MAP) {
				this.populateGeographyDefaults(primaryGroupingOptions, resource.projectGeographies);
			}
			return [primaryGroupingOptions, angular.copy(availableAttributes), angular.copy(availableAttributes)];
		};

		private filterAvailableAttributes = (optionsBuilder, projectGeographies: ProjectGeographies): any => {
			if (this.visualization === VisualizationType.MAP) {
				optionsBuilder
					.applyNlpFilter(option => this.filterAndFillGeographyOption(option, projectGeographies))
					.filterAvailableOptions(option => this.filterAndFillGeographyOption(option, projectGeographies));
			}

			return optionsBuilder;
		};

		private filterAndFillGeographyOption = (grouping: AttributeGrouping, projectGeographies: ProjectGeographies): boolean => {
			if (grouping.metricType === AnalyticMetricType.CLARABRIDGE) {
				return projectGeographies.modelGeographies
					.filter(modelGeography => modelGeography.modelId === Number.parseInt(grouping.name, 10)).length > 0;
			} else if (grouping.metricType === AnalyticMetricType.ATTRIBUTE) {
				return projectGeographies.attributeGeographies
					.filter(attributeGeography => attributeGeography.attributeName.toLowerCase() === grouping.name.toLowerCase()).length > 0;
			} else {
				return false;
			}
		};

		private populateGeographyDefaults = (options, projectGeographies: ProjectGeographies): void => {
			options.forEach(option => {
				if (option.children) {
					this.populateGeographyDefaults(option.children, projectGeographies);
				} else {
					let grouping = option as AttributeGrouping;
					let boundaryField = geographyReportService.getGroupingBoundaryField(grouping, projectGeographies);
					this.populateGeographyDefaultSize(grouping, boundaryField);
				}
			});
		};

		private populateGeographyDefaultSize = (grouping: AttributeGrouping, boundaryField: BoundaryField): void => {
			let boundaryType = geographyOptionsService.getBoundaryFieldBoundaryType(boundaryField);
			let defaultSize = BoundaryTypeDefaults.SIZE[boundaryType];
			grouping.size = defaultSize;
		};
	};
});
