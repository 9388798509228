import { MetricType } from '@app/modules/metric/entities/metric-type';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import Widget from '@cxstudio/dashboards/widgets/widget';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import * as _ from 'underscore';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { ReportNumberFormatUtils } from '@cxstudio/reports/utils/report-number-format-utils.service';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ReportCalculation, CalculationWithFormat } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { WidgetColorPalette } from '@cxstudio/reports/coloring/entities/widget-color-palette';
import { ColorFunction, ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { ColorFunctionBuilder } from '@cxstudio/reports/coloring/color-function-builder';
import { ColorFunctionService } from '@cxstudio/reports/coloring/color-function.service';
import { TableColumnService } from '@cxstudio/reports/providers/cb/services/table-column-service.service';
import { WidgetUtilsBuilder } from '@cxstudio/reports/widget-utils/widget-utils-builder';
import { WidgetUtilsResourcesService } from '@app/modules/reports/utils/widget-utils-resources.service';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { CustomDateFilter } from '@cxstudio/reports/entities/date-filter';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { DateRangeUtils } from '@app/modules/utils/dates/date-range-utils.class';
import { DualDefinitionHelper } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/dual-definition-helper.class';
import { IFormatBuilder } from '@app/modules/widget-visualizations/formatters/generic-formatter.service';
import { AnalyticsDataFormattingService } from '@app/modules/widget-visualizations/utilities/analytics-data-formatting.service';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';

type IFormatter = (...args: any[]) => string;

export class WidgetUtilsService {

	constructor(
		private readonly dashboardFiltersService: DashboardFiltersService,
		private readonly reportNumberFormatUtils: ReportNumberFormatUtils,
		private readonly analyticsDataFormatting: AnalyticsDataFormattingService,
		private readonly widgetUtilsResourcesService: WidgetUtilsResourcesService,
		private readonly $q: ng.IQService,
		private readonly locale: ILocale,
		private readonly colorUtils: ColorUtils,
		private readonly colorFunctionService: ColorFunctionService,
		private readonly tableColumnService: TableColumnService,
		private readonly DateRange,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
	) {}

	getBuilder(widget: Widget): WidgetUtilsBuilder {
		return new WidgetUtilsBuilder(widget,
			this.widgetUtilsResourcesService.getLazyResources(widget),
			this, this.tableColumnService, this.reportAssetUtilsService, this.$q);
	}

	getDataFormatter(properties: WidgetProperties, visualProperties: VisualProperties,
		metrics: Metric[]): (value: any) => string {
		let attribute: ReportCalculation;
		if (properties.widgetType === WidgetType.BAR || properties.widgetType === WidgetType.LINE) {
			attribute = visualProperties.attributeSelections?.yAxis;
		}
		if ([WidgetType.HEATMAP,
			WidgetType.CLOUD,
			WidgetType.NETWORK,
			WidgetType.PIE,
			WidgetType.METRIC].contains(properties.widgetType)) {
			attribute = properties.selectedMetrics?.[0];
		}
		let ignoreMultiplier = DualDefinitionHelper.isCalculationSeries(visualProperties, properties.widgetType);
		return this.getCalculationFormatter(attribute, metrics, ignoreMultiplier);
	}

	getSecondaryDataFormatter(properties: WidgetProperties, visualProperties: VisualProperties,
		metrics: Metric[]): (value: any) => string {
		let attribute: ReportCalculation;
		if (properties.widgetType === WidgetType.BAR || properties.widgetType === WidgetType.LINE) {
			attribute = visualProperties.attributeSelections?.secondaryYAxis;
		}
		return this.getCalculationFormatter(attribute, metrics);
	}

	getScatterDataFormatters(properties: WidgetProperties, visualProperties: VisualProperties,
		metrics: Metric[]): Array<(value: any) => string> {
		if (properties.widgetType === WidgetType.SCATTER) {
			return _.chain(properties.selectedMetrics)
				.first(3) // only Y and X axis
				.filter(metric => !!metric)
				.map(metric => this.getCalculationFormatter(metric, metrics))
				.value() || [];
		}
		return undefined;
	}

	private getCalculationFormatter(attribute: ReportCalculation, metrics: Metric[], ignoreMultiplier = false): (value) => string {
		if (!attribute) return undefined;

		let formatter: IFormatBuilder = this.reportNumberFormatUtils.getFormatterBuilder(attribute as CalculationWithFormat, metrics);

		if (attribute.definition) {
			let selectedMetric = _.find(metrics, {id: attribute.id});
			return (value) => formatter.format(value, attribute as CalculationWithFormat,
				selectedMetric?.format, {ignoreConversion: ignoreMultiplier});
		} else {
			return (value) => {
				return formatter.format(value, attribute as CalculationWithFormat, null, {ignoreConversion: ignoreMultiplier});
			};
		}
	}

	getTitleFormatter(attributes: IReportAttribute[], metrics: Metric[]): (field: string) => string {
		return (field: string) => {
			switch (field) {
				case StandardMetricName.VOLUME: return this.locale.getString('widget.volume');
				case StandardMetricName.PERCENT_OF_TOTAL: return this.locale.getString('widget.volumePercTotal');
				case StandardMetricName.PARENT_PERCENT: return this.locale.getString('widget.parentPerc');
				case StandardMetricName.SENTIMENT: return this.locale.getString('widget.sentiment');
				case PredefinedMetricConstants.EASE_SCORE: return this.locale.getString('metrics.easeScore');
				case PredefinedMetricConstants.EMOTION: return this.locale.getString('metrics.emotion');
				case StandardMetricName.STRENGTH: return this.locale.getString('widget.strength');
				default:
					let attr = _.findWhere(attributes, {name: field});
					if (attr)
						return attr.displayName.bracketsToEntities();
					let metric = _.findWhere(metrics, {name: field});
					if (metric)
						return metric.displayName.bracketsToEntities();
					return field?.bracketsToEntities();
			}
		};
	}

	getPeriodFormatter(dateFilters: IFilter[]): (field: DateFilterMode | CustomDateFilter) => string {
		return (field: DateFilterMode | CustomDateFilter) => {
			if (DateRangeUtils.isCustomDateFilterMode(field)) {
				let id = DateRangeUtils.getCustomDateFilterId(field);
				return _.findWhere(dateFilters, {id})?.name;
			} else {
				return this.DateRange.valueOf(field).displayName;
			}
		};
	}

	private isAbsoluteValueMetric(metrics: Metric[], field: string): boolean {
		let matchedMetric = _.findWhere(metrics, {name: field});

		if (matchedMetric) {
			return (matchedMetric.definition.type === MetricType.SATISFACTION);
		}

		return false;
	}

	private reflect = (value) => value;

	// some metric types may require modification to be used in sizing
	private getSizeModifier(metrics: Metric[], field): (value: number) => number {
		if (field === PredefinedMetricConstants.EASE_SCORE
			|| field === PredefinedMetricConstants.SENTIMENT) {
			return Math.abs;
		}

		if (this.isAbsoluteValueMetric(metrics, field)) {
			return Math.abs;
		}

		return this.reflect;
	}

	getSizeFunction(visualProperties: VisualProperties, metrics: Metric[],
		sizeFieldName: 'size' | 'secondarySize', returnRawValue?: boolean): (item: any) => number {

		let sizeMetric;

		if (visualProperties.attributeSelections?.[sizeFieldName]) {
			sizeMetric = visualProperties.attributeSelections[sizeFieldName].name;
		} else if (visualProperties[sizeFieldName]) {
			sizeMetric = visualProperties[sizeFieldName];
		}

		if (sizeMetric) {
			let modifierFn = returnRawValue ? this.reflect : this.getSizeModifier(metrics, sizeMetric);
			return (item: any) => modifierFn(item[sizeMetric]);
		}
		return (item: any) => 1;
	}

	getMetricNamesFunc(properties: WidgetProperties): () => string[] {
		return () => _.map(properties.selectedMetrics, item => item && item.name);
	}

	getGroupingsFunc(properties: WidgetProperties): () => ReportGrouping[] {
		return () => GroupIdentifierHelper.getGroupings(properties.selectedAttributes);
	}

	getGroupingFormatterFunc(properties: WidgetProperties, metrics: Metric[]): (index: number) => IFormatter {
		return (groupingIndex: number) => {
			groupingIndex = groupingIndex ? groupingIndex : 0;
			if (properties.selectedAttributes) {
				let grouping = properties.selectedAttributes[groupingIndex];
				let formatterKey = GroupIdentifierHelper.getIdentifier(grouping);
				return this.getGroupingFormatters(properties.selectedAttributes, metrics)[formatterKey];
			}
		};
	}

	getGroupingFormatters(groupings: AttributeGrouping[], metrics: Metric[]): any {
		let formatters = {};
		if (!isEmpty(groupings)) {
			_.chain(groupings)
				.filter(attr => !!attr)
				.forEach(group => {
					if (formatters) {
						let formatter = this.analyticsDataFormatting.getFormatterFromGroup(group, metrics);
						//Break forEach by setting formatters to null, so null gets returned and error is triggered
						//TODO fix the logic from comment above
						if (formatter) {
							let identifier = GroupIdentifierHelper.getIdentifier(group);
							formatters[identifier] = formatter;
						} else {
							formatters = null;
						}
					}

				})
				.value();
		}

		return formatters;
	}

	getGroupingFormattersFunc(properties: WidgetProperties, metrics: Metric[]): () => any {
		return () => this.getGroupingFormatters(properties.selectedAttributes, metrics);
	}

	getColorFunctionBuilder(selectedAttributes: ReportGrouping[], metrics: Metric[],
		studioPalettes: WidgetColorPalette[], providerColors: string[]): ColorFunctionBuilder {
		return this.colorFunctionService.getBuilder()
			.withGroups(selectedAttributes)
			.withMetrics(metrics)
			.withPalettes(studioPalettes, providerColors);
	}

	getColorFunctionWrapper(visualProperties: VisualProperties, userAlignmentColor: string,
		widget: Widget): (fn: ColorFunction) => ColorFunction {
		return (originalFunc: ColorFunction) =>
			this.colorUtils.getWrappedColorFunction(visualProperties, originalFunc, userAlignmentColor, widget);
	}

	getPeriods(properties: WidgetProperties, containerId: string): {period_1_: string; period_2_: string} {
		return {
			period_1_: this.dashboardFiltersService.getActiveDateFilterMode(
				properties, 1, containerId),
			period_2_: properties.dateRangeP2.dateFilterMode
		};
	}

}

app.service('widgetUtilsService', WidgetUtilsService);
