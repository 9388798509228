import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ICreateEngagorCaseModalResult } from '@cxstudio/reports/document-explorer/components/create-engagor-case-modal.component';
import EngagorCreateCaseRequestDocument from './engagor-create-case-request-document';


export default class EngagorCreateCaseRequest {
	contentProviderId: number;
	accountId: number;
	projectId: number;
	contentProviderName: string;
	accountName: string;
	projectName: string;
	documentId: number;
	templateId: number;
	notes: string;
	caseTitle: string;
	documents: EngagorCreateCaseRequestDocument[];
	dashboardId?: number;
	xflowSubscriptionId?: string;

	constructor(
		projectIdentifier: IProjectSelection,
		createCaseModalResult: ICreateEngagorCaseModalResult) {
		this.contentProviderId = projectIdentifier.contentProviderId;
		this.accountId = projectIdentifier.accountId;
		this.projectId = projectIdentifier.projectId;
		this.contentProviderName = projectIdentifier.contentProviderName;
		this.accountName = projectIdentifier.accountName;
		this.projectName = projectIdentifier.projectName;
		this.templateId = createCaseModalResult.templateId;
		this.documents = createCaseModalResult.documents;
		this.xflowSubscriptionId = createCaseModalResult.xflowSubscriptionId;
	}
}