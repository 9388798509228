import { Inject, Injectable } from '@angular/core';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { AttributesService } from '@app/modules/project/attribute/attributes.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { ReportAttributesService } from '@app/modules/project/attribute/report-attributes.service';
import { ModelsService } from '@app/modules/project/model/models.service';
import { ReportModelsService } from '@app/modules/project/model/report-models.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { PromiseUtils } from '@app/util/promise-utils';
import { Security } from '@cxstudio/auth/security-service';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { DashboardPersonalizationProvider } from '@cxstudio/dashboards/dashboard-filters/dashboard-personalization-provider.service';
import { DashboardProperties } from '@cxstudio/dashboards/entity/dashboard-properties';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { IDrillMenuOption } from '@cxstudio/reports/utils/contextMenu/drill-menu-option';
import { MenuOptionsUtils } from '@cxstudio/reports/utils/contextMenu/drill/drill-options/menu-options-utils.service';
import { HierarchyService } from '@cxstudio/services/hierarchy-service.service';
import { AdditionalDrill } from './drill-options/additional-drill.service';
import { RecolorDrillService } from './drill-options/recolor-drill.service';
import { IContextMenuActions } from './widget-drill-actions.service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';

@Injectable({
	providedIn: 'root'
})
export class WidgetDrillMenuService {

	constructor(
		@Inject('dashboardPersonalizationProvider') private readonly dashboardPersonalizationProvider: DashboardPersonalizationProvider,
		@Inject('hierarchyService') private readonly hierarchyService: HierarchyService,
		@Inject('security') private readonly security: Security,
		@Inject('contextMenuTree') private readonly contextMenuTree: ContextMenuTree,
		@Inject('menuOptionsUtils') private readonly menuOptionsUtils: MenuOptionsUtils,
		private readonly organizationApiService: OrganizationApiService,
		private readonly cxDialogService: CxDialogService,
		private readonly locale: CxLocaleService,
		private readonly additionalDrill: AdditionalDrill,
		private readonly recolorDrill: RecolorDrillService,
		private readonly attributesService: AttributesService,
		private readonly reportAttributesService: ReportAttributesService,
		private readonly modelsService: ModelsService,
		private readonly reportModelsService: ReportModelsService,
		private readonly betaFeaturesService: BetaFeaturesService,
	) { }

	showDrillMenu = (
		point: DrillPoint,
		widget: Widget,
		dashboardProperties: DashboardProperties,
		menuActions: IContextMenuActions,
		editMode: boolean,
		clickEvent: ng.IAngularEvent,
		showWidgetMenu?: (event: any) => void): Promise<void> => {

		if (!this.isContextMenuSupported(widget.properties)) {
			if ((clickEvent as any).type === 'contextmenu' && showWidgetMenu) {
				showWidgetMenu(clickEvent);
			}
			return Promise.resolve();
		}

		return this.checkUserDrillPermissions(point, widget, dashboardProperties).then(() => {
			if (this.isPreviewWidget(widget)) {
				this.openDocExplorer(point, widget, menuActions);
				return;
			}
			if (ReportConstants.isSelectorWidget(widget.properties.widgetType)) {
				let menuOptions = [this.recolorDrill.getRecolorOption({ widget, menuActions }, point)];
				this.contextMenuTree.showDrillMenu(clickEvent, point, menuOptions);
				return;
			}

			if (editMode || this.security.has('drill_in_place')) {
				return this.getAttributesAndModels(widget, editMode, widget.hasDataAccess)
					.then(({ attributes, models, wordAttributes }) => {
						let optionsPromise = PromiseUtils.wrap(this.menuOptionsUtils.getOptions(point, widget, attributes, wordAttributes, models,
							menuActions, editMode, dashboardProperties));
						return optionsPromise.then(options => {
							_.each(options, opt => opt && delete opt.selected);
							this.contextMenuTree.showDrillMenu(clickEvent, point, options);
						});
					});
			}
			if (!editMode) {
				let menuOptions = this.additionalDrill.getNonReportingOptions(widget, point);
				if (this.menuOptionsUtils.isDrillable(point)
					&& this.menuOptionsUtils.canDrillToFeedbackInView(dashboardProperties, widget)) {

					menuOptions = menuOptions.concat(this.getFullPageDocumentExplorerOption(point, widget, menuActions));

					let documentExplorerOptions = this.getDocumentExplorerOption(point, widget, menuActions);
					menuOptions = menuOptions.concat(documentExplorerOptions);
				}
				if (menuOptions.length > 1)
					this.contextMenuTree.showDrillMenu(clickEvent, point, menuOptions);
				else if (menuOptions.length === 1) {
					menuOptions[0].func();
				} else {
					if ((clickEvent as any).type === 'contextmenu' && showWidgetMenu) {
						showWidgetMenu(clickEvent);
					}
				}
			}
			return;

		}, () => {});
	};

	private getAttributesAndModels(widget: Widget, editMode: boolean, hasDataAccess: boolean):
		Promise<{ attributes: any[]; models: any[]; wordAttributes?: any[] }> {
		return Promise.all([
			this.getAttributes(widget, editMode, hasDataAccess),
			this.getModels(widget, editMode, hasDataAccess),
			this.getWordAttributes(widget, editMode, hasDataAccess)]).then(result => {
			let attributes = result[0];
			let models = result[1];
			let wordAttributes = result[2];

			if (attributes && models && wordAttributes) {
				return { attributes, models, wordAttributes };
			} else if (attributes && models) {
				return { attributes, models };
			} else
				Promise.reject({});
		});
	}

	private isContextMenuSupported(props: WidgetProperties): boolean {
		return !this.isMetricReport(props.widgetType) && !this.isAdminReport(props.project);
	}

	private isAdminReport(projectId: number): boolean {
		return InternalProjectTypes.isAdminProject(projectId);
	}

	private isMetricReport(type: string): boolean {
		return type === WidgetType.METRIC;
	}

	private checkUserDrillPermissions(point: DrillPoint, widget: Widget,
		dashboardProperties: DashboardProperties): Promise<void> {
		let dashboard = { properties: dashboardProperties };
		let personalization = this.dashboardPersonalizationProvider.getInstance(dashboard, null);
		if (!this.hierarchyService.isPeerReport(personalization, widget.properties)) {
			return Promise.resolve();
		} else {
			let indexedGrouping = this.findIndexedDrillingHierarchyGrouping(widget);
			if (!indexedGrouping) {
				return Promise.resolve();
			} else {
				let hierarchyId = personalization.getHierarchyId();
				let groupingIdentifier = GroupIdentifierHelper.generateIdentifier(indexedGrouping.grouping, indexedGrouping.index);
				let hierarchyNodeId = point[groupingIdentifier + '_hierarchyNodeId'];

				return this.organizationApiService.getDrillingInfo(hierarchyId, hierarchyNodeId).then(drillingInfo => {
					if (!drillingInfo.nodeAccessible) {
						this.showForbiddenHierarchyPeerReportMessage(drillingInfo);
						return Promise.reject({});
					}
				});
			}
		}
	}

	private findIndexedDrillingHierarchyGrouping(widget: Widget): { grouping: AttributeGrouping; index: number } {
		let groupings = widget.properties.selectedAttributes;
		for (let i = 0; i < groupings.length; i++) {
			let grouping = groupings[i];
			if (AnalyticMetricType.HIERARCHY_MODEL === grouping.metricType) {
				return {
					grouping,
					index: i
				};
			}
		}
	}

	private showForbiddenHierarchyPeerReportMessage(drillingInfo): void {
		this.cxDialogService.notify(
			this.locale.getString('common.error'),
			this.locale.getString('organization.hierarchyNodeDrillingForbidden', { nodeType: drillingInfo.nodeType }));
	}

	private openDocExplorer(point: DrillPoint, widget: Widget, menuActions: IContextMenuActions): void {
		this.menuOptionsUtils.openAnDocumentExplorer(point, widget, menuActions);
	}

	private getFullPageDocumentExplorerOption(point: DrillPoint, widget: Widget, menuActions): IDrillMenuOption {
		return {
			text: this.locale.getString('widget.openFullPage'),
			func: () => this.menuOptionsUtils.openAnDocumentExplorer(point, widget, menuActions, true),
			name: 'full_page_doc_explorer'
		};
	}

	private getDocumentExplorerOption(point: DrillPoint, widget: Widget, menuActions): IDrillMenuOption {
		return {
			text: this.locale.getString('widget.docExplorer'),
			func: () => this.menuOptionsUtils.openAnDocumentExplorer(point, widget, menuActions),
			name: 'an_doc_explorer'
		};
	}

	private isPreviewWidget(widget: Widget): boolean {
		return widget.name === WidgetType.PREVIEW;
	}

	private getAttributes(widget: Widget, editMode: boolean, hasDataAccess: boolean): ng.IPromise<IReportAttribute[]> {
		/*
		CXS-4808. As discussed with Milan and Keegan:
		If user doesn't have access to project data in edit mode,
		show all drill options but clicking on any of them will show a popup with message
		*/
		if (editMode && hasDataAccess) {
			let project = this.getProject(widget);
			return PromiseUtils.old(this.attributesService.getAttributes(project));
		} else {
			return PromiseUtils.old(this.reportAttributesService.getWidgetAttributes(widget));
		}
	}

	private getWordAttributes(widget: Widget, editMode: boolean, hasDataAccess: boolean): ng.IPromise<any> {
		if (editMode && hasDataAccess) {
			let project = this.getProject(widget);
			return PromiseUtils.old(this.attributesService.getWordAttributes(project));
		} else {
			return PromiseUtils.old(this.reportAttributesService.getWidgetWordAttributes(widget));
		}
	}

	private getProject(widget: Widget): AccountOrWorkspaceProject {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE)
			? widget.properties.workspaceProject
			: ProjectIdentifier.fromWidgetProperties(widget.properties);
	}

	private getModels(widget: Widget, editMode: boolean, hasDataAccess: boolean): ng.IPromise<any> {
		if (editMode && hasDataAccess) {
			let project = this.getProject(widget);
			return PromiseUtils.old(this.modelsService.getModels(project));
		} else {
			return PromiseUtils.old(this.reportModelsService.getWidgetModelsWithoutHierarchies(widget));
		}
	}
}

app.service('widgetDrillMenu', downgradeInjectable(WidgetDrillMenuService));
