import { ModelGroupingSettings, TextAttributeGroupingSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { ModelsService } from '@app/modules/project/model/models.service';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { INode, SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { SelectorWidgetNavigationType } from '@app/modules/widget-settings/selector-widget/selector-widget-navigation-type.enum';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { FormatsService } from '@app/modules/widget-visualizations/formatters/formats.service';
import { NumberFormatHelperService } from '@app/modules/widget-visualizations/formatters/number-format-helper.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { GeographyApiService } from '@cxstudio/attribute-geography/geography-api.service';
import ModelGeography from '@cxstudio/attribute-geography/model-geography';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { AnalyticMetricType, AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { FilterUtils } from '@cxstudio/report-filters/filter-utils.service';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { MetricMultiplierType } from '@cxstudio/reports/formatting/metric-multiplier-type.enum';
import { PreviewColumn } from '@cxstudio/reports/preview/preview-predefined-columns';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { IndeterminateTopicSelectionImpl } from '@cxstudio/reports/providers/cb/definitions/indeterminate-topic-selection.class';
import IndeterminateTopicSelection from '@cxstudio/reports/providers/cb/definitions/indeterminate-topic-selection.interface';
import { MetricConfigurationRules } from '@cxstudio/reports/providers/cb/definitions/metric-configuration-rules.factory';
import { TopicDrillUtils } from '@cxstudio/reports/utils/contextMenu/drill/topic-drill-utils.class';
import { CapitalizationService } from '@cxstudio/services/capitalization.service';
import * as uib from 'angular-ui-bootstrap';
import * as _ from 'underscore';
import { AttributeUrlType } from '../constants/attribute-url-type.constant';
import { StackType } from '../constants/stack-types';
import { WidgetMetricConfigurationOptions } from '../constants/widget-metric-configuration-options.constant';
import { DualAxisTypes } from './dual-axis-types';
import { EmptyPeriodType } from './empty-period-type';
import { KeyMetricListTypes } from './key-metric-list-types.constant';
import { HierarchyMode } from '@cxstudio/organizations/hierarchy-mode';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { ObjectUtils } from '@app/util/object-utils';

// NOTE: this is deprecated and rewritten in sidebar-editor, do not refactor this
export interface WidgetConfigModalSettings {
	configItem: AttributeGrouping | ReportCalculation;
	isWidgetAssetConfig: boolean;

	project: AccountOrWorkspaceProject;
	metric?: Metric;
	listType?: any;
	configurationOptions?: WidgetMetricConfigurationOptions[];

	studioMetrics?: any[];
	selectedMetrics?: any;
	props?: WidgetProperties;
	visualProps?: VisualProperties;
	mobile?: boolean;
	showUpdateInclusionFilterCheckbox?: boolean;
	sortOptions?: any;

	isFeedbackColumn?: boolean;
	isVerbatimView?: boolean;
	hideCapitalizationOption?: boolean;
	isTable?: boolean;
}

interface IMetricConfigurationScope extends ng.IScope {
	uiRules: MetricConfigurationRules;
}

export class WidgetConfigController implements ng.IController {

	indeterminateTopicSelection: IndeterminateTopicSelection;

	constructor(
		private $q: ng.IQService,
		private modalSettings: WidgetConfigModalSettings,
		private $scope: IMetricConfigurationScope,
		private $rootScope: ng.IRootScopeService,
		private locale: ILocale,
		private $uibModalInstance: uib.IModalInstanceService,
		private defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		private hierarchyService,
		private modelsService: ModelsService,
		private capitalization: CapitalizationService,
		private numberFormatHelper: NumberFormatHelperService,
		private reportSettingsService: ReportSettingsService,
		private organizationApiService: OrganizationApiService,
		private filterUtils: FilterUtils,
		private geographyApiService: GeographyApiService,
	) {
		this.$scope.submit = this.submit;
		this.$scope.shouldRemoveFilters = this.shouldRemoveFilters;
		this.$scope.isInheritedDisabled = this.isInheritedDisabled;
		this.$scope.isSubmitDisabled = this.isSubmitDisabled;
		this.$scope.dismiss = this.dismiss;
		this.$scope.changeAttrSort = this.changeAttrSort;
		this.$scope.levelChanged = this.levelChanged;
		this.$scope.getVolumeLabel = this.getVolumeLabel;
		this.$scope.isCapitalizationOptionsAvailable = this.isCapitalizationOptionsAvailable;
		this.$scope.isSentimentHighlightingAvailable = this.isSentimentHighlightingAvailable;
		this.$scope.showNullIncludeSelector = this.showNullIncludeSelector;
		this.$scope.isNodeCheckboxDisabled = this.isNodeCheckboxDisabled;
		this.$scope.isInclusionNodeChecked = this.isInclusionNodeChecked;
		this.$scope.handleInclusionNodeCheck = this.handleInclusionNodeCheck;
		this.$scope.applyModelDefaults = this.applyModelDefaults;
		this.$scope.applyAttributeDefaults = this.applyAttributeDefaults;
		this.$scope.studioMetrics = this.modalSettings.studioMetrics;
		this.$scope.selectedMetrics = this.modalSettings.selectedMetrics;
		this.$scope.getUpdateInclusionFilterLabel = this.getUpdateInclusionFilterLabel;
		this.$scope.getMaxGroupingSize = this.getMaxGroupingSize;
		this.$scope.onLevelNodeClick = this.onLevelNodeClick;
		this.$scope.selectSortOrder = this.selectSortOrder;
		this.$scope.isLevelDisabled = this.isLevelDisabled;
		this.$scope.isCustomMultiselectDropList = this.isCustomMultiselectDropList;
		this.$scope.isPareto = this.isPareto;
		this.$scope.showUrlType = this.showUrlType;
		this.$scope.showStyleSettings = this.showStyleSettings;

		this.$scope.item = angular.copy(this.modalSettings.configItem);
		this.$scope.project = this.modalSettings.props ? ProjectIdentifier.fromWidgetProperties(this.modalSettings.props) : null;

		this.$scope.uiRules = new MetricConfigurationRules(this.modalSettings.configItem, this.modalSettings.props,
			this.modalSettings.visualProps, this.modalSettings.configurationOptions,
			this.$scope.item, this.modalSettings.listType);
		this.$scope.props = angular.copy(this.modalSettings.props);
		this.$scope.visualProps = angular.copy(this.modalSettings.visualProps) || {};
		this.$scope.modalTitle = this.locale.getString('widget.settingsModalTitle', {itemName: this.$scope.item.displayName});

		this.$scope.options = {};
		this.$scope.ui = this.$scope.ui || {};
		this.$scope.ui.validationChecks = [];

		if (this.$scope.uiRules.isTopicOrLeaf() && this.modalSettings.mobile && this.$scope.item.updateOnParentFilter === undefined) {
			this.$scope.item.updateOnParentFilter = true;
		}

		this.$scope.showUpdateInclusionFilterCheckbox = this.modalSettings.showUpdateInclusionFilterCheckbox
			|| this.modalSettings.showUpdateInclusionFilterCheckbox === undefined;

		if (this.modalSettings.isFeedbackColumn) {
			this.initCapitalizationOptions();
		}

		if (this.modalSettings.listType === KeyMetricListTypes.GROUPING) {
			this.initGrouping();
		}

		if ((this.modalSettings.listType === KeyMetricListTypes.CALCULATION)
				|| (this.modalSettings.listType === KeyMetricListTypes.CALCULATION_SERIES)) {
			this.initCalculation();
		}

		if (this.$scope.uiRules.isTopicOrLeaf()) {
			if (this.isTopicLeafEnabled()) {
				this.$scope.item.selectedLevel = TopicReportGrouping.LEAF_LEVEL;
			}

			this.$scope.loadingTree = this.getModelTree().then(tree => {
				this.$scope.modelTree = tree.root;
				let maxLevels = this.hierarchyService.getDepth(tree.root);
				this.$scope.levels = [];
				for (let i = 1; i <= maxLevels; i++) {
					if (!this.isMapWidgetGrouping() || this.isMapWidgetLevelAllowed(tree, i - 1)) {
						this.$scope.levels.push({value: i, displayName: i});
					}
				}
				if (!this.isMapWidgetGrouping())
					this.$scope.levels.push({
						value: TopicReportGrouping.LEAF_LEVEL,
						displayName: this.locale.getString('widget.leaf')
					});
				this.$scope.applyModelDefaults();

				if (!!this.indeterminateTopicSelection) {
					this.indeterminateTopicSelection.updateSelectedLevel();
				}
			});
		}

		if (this.$scope.uiRules.isHierarchyModel() && this.$scope.item.levels) {
			let hierarchyLevelsPromise = this.organizationApiService.getHierarchyLevels(this.$scope.item.name);
			let hierarchyPromise = this.organizationApiService.getOrgHierarchy(this.$scope.item.name);

			this.$scope.loadingHierarchy = this.$q.all([
				hierarchyLevelsPromise,
				hierarchyPromise
			]).then((resultArray: any) => {
				let levels = resultArray[0];
				let selectedLevel = this.$scope.item.selectedLevel;
				selectedLevel = selectedLevel && selectedLevel <= levels.length ? selectedLevel : 1;
				this.$scope.item.selectedLevel = selectedLevel;

				this.$scope.hierarchyModelTree = this.hierarchyService.getOrgHierarchyLevelTree(levels);
				this.$scope.hierarchyModelInclusionTree = angular.copy(this.$scope.hierarchyModelTree);
				this.$scope.selectedLevelNode = TopicDrillUtils.findInHierarchy(this.$scope.hierarchyModelTree,
					'level', this.$scope.item.selectedLevel);
				if (this.$scope.item.checkedInclusionNodes) {
					this.$scope.item.checkedInclusionNodes = this.$scope.item.checkedInclusionNodes.filter(level => level <= levels.length);
				} else {
					this.$scope.item.checkedInclusionNodes = _.map(this.$scope.item.levels, (item: any) => item.level);
				}

				let hierarchy = resultArray[1].data;
				this.$scope.item.hierarchy = hierarchy;
			});

		}

		this.$scope.config = {
			hidePreview: this.$scope.uiRules.isCalculationSeries(),
			isInherited: this.$scope.uiRules.isCalculationSeries(),
			showColorDirection: this.$scope.uiRules.showColorDirection(),
			colorDirectionRadioOptions: this.$scope.uiRules.getColorDirectionRadioOptions().map(option => {
				option.displayName = this.locale.getString(`singleMetric.${option.displayName}`);
				return option;
			}),
			showArrowToggle: this.$scope.uiRules.showArrowToggle(),
			showCalculationType: this.$scope.uiRules.showCalculationType(),
			showStyleSettings: !this.isMetricWidgetEditor()
		};

		this.indeterminateTopicSelection = new IndeterminateTopicSelectionImpl({
			getSelectedNodes: () => this.$scope.item.selectedNodes,
			setSelectedNodes: (selectedNodes) => {
				this.$scope.item.selectedNodes = selectedNodes;
			},
			setCurrentSelection: (selection) => {
				this.$scope.checkedNodes = selection;
			},
			getCurrentSelection: () => this.$scope.checkedNodes,
			getModelTree: () => this.$scope.modelTree,
			getSelectedLevels: () => this.$scope.uiRules.isMultiLevelTopic()
				? this.$scope.item.selectedLevels
				: [this.$scope.item.selectedLevel],
			isTopicLeafEnabled: this.isTopicLeafEnabled,
			isIndeterminateDisabled: this.$scope.isCustomMultiselectDropList
		});

		this.$scope.itemHasDefaultFormat = this.numberFormatHelper.itemHasDefaultFormat;
		this.$scope.$watch('item.selectedLevel', (newValue, oldValue) => {
			if (!oldValue || !newValue) return;
			if (this.$scope.uiRules.isHierarchyModel()) return;
			if (this.$scope.item.inheritTopics) return;

			this.indeterminateTopicSelection.updateSelectedLevel();
		});

		this.$scope.handleNodeClick = this.indeterminateTopicSelection.handleNodeClick;
		this.$scope.isNodeChecked = this.indeterminateTopicSelection.isNodeChecked;
		this.$scope.isNodeHighlighted = this.indeterminateTopicSelection.isNodeHighlighted;
		this.$scope.isIndeterminateNode = this.indeterminateTopicSelection.isIndeterminateNode;
		this.$scope.isNodeClickable = this.indeterminateTopicSelection.isNodeClickable;
		this.$scope.updateSelectedLevel = this.indeterminateTopicSelection.updateSelectedLevel;

		this.$scope.processInclusionList = this.indeterminateTopicSelection.processInclusionList;
		this.$scope.processSelectedNodes = this.indeterminateTopicSelection.processSelectedNodes;

		this.$scope.selectAll = this.indeterminateTopicSelection.selectAll;
		this.$scope.deselectAll = this.indeterminateTopicSelection.deselectAll;
	}

	$onInit = () => {
		// required for typescript validation
	};

	isValid = (): boolean => {
		let allValid: boolean = true;
		this.$scope.ui.validationChecks.map((validationFn) => {
			allValid = allValid && validationFn();
		});
		return allValid;
	};

	submit = () => {
		if (!this.isValid()) return;

		if (this.shouldRemoveFilters()) {
			this.$rootScope.$broadcast('clearFilters');
		}

		if (this.$scope.uiRules.isTopicOrLeaf()) {
			this.indeterminateTopicSelection.processSelectedNodes();
		}
		this.$uibModalInstance.close(this.$scope.item);
	};

	isInheritedDisabled = (): boolean => {
		return this.$scope.item.hierarchy?.hierarchyMode === HierarchyMode.DYNAMIC_FILTERING;
	};

	shouldRemoveFilters = (): boolean => {
		return this.$scope.uiRules.isCustomSortOrder()
			&& this.filterUtils.isComplexFiltersApplied(this.$scope.props);
	};

	isSubmitDisabled = (): boolean => {
		if (this.$scope.uiRules.isCustomSortOrder()) {
			if (this.$scope.uiRules.isTopicOrLeaf()) {
				return _.isEmpty(this.$scope.checkedNodes);
			} else if (this.$scope.uiRules.wordsSelectionAllowed()) {
				return _.isEmpty(this.$scope.item.wordsList);
			} else if (this.$scope.uiRules.isNumeric()) {
				return _.isEmpty(this.$scope.item.numericInclusionList);
			}
		}
		return false;
	};

	dismiss = () => {
		this.$uibModalInstance.dismiss('cancel');
	};

	changeAttrSort = (node) => {
		this.$scope.item.sortBy = node.name;
	};

	levelChanged = () => {
		if (this.$scope.item.selectedLevel === TopicReportGrouping.LEAF_LEVEL) {
			this.$scope.item.type = ReportAssetType.TOPIC_LEAF;
		} else {
			this.$scope.item.type = ReportAssetType.TOPICS;
		}
	};

	private initGrouping = () => {
		if (this.$scope.uiRules.isUsingTimeGrouping()) {
			// this is likely not used
			this.$scope.options.sortBy = [{
				name: 'time',
				displayName: this.locale.getString('widget.groupTimeOptions')
			}];
		} else {
			this.$scope.options.sortBy = this.withoutScorecardMetrics(this.modalSettings.sortOptions);
		}

		// limit can just copy sort options -- all metrics or numeric attributes should be available
		this.$scope.options.limitOptions = this.withoutScorecardMetrics(this.modalSettings.sortOptions);

		if (this.$scope.uiRules.isUsingTimeGrouping()) {
			this.$scope.options.emptyPeriodTypes = [
				{
					displayName: this.locale.getString('widget.showAllEmptyPeriods'),
					value: EmptyPeriodType.SHOW_ALL, htmlId: 'emptyPeriod_0'
				},
				{
					displayName: this.locale.getString('widget.showInterspersedEmptyPeriods'),
					value: EmptyPeriodType.SHOW_INTERSPERSED, htmlId: 'emptyPeriod_1'
				},
				{
					displayName: this.locale.getString('widget.doNotShowEmptyPeriods'),
					value: EmptyPeriodType.DO_NOT_SHOW, htmlId: 'emptyPeriod_2'
				}
			];

			// this is likely not used
			this.$scope.options.sortOrder = [
				{displayName: this.locale.getString('widget.reversePeriodOrder'), name: 'desc'},
				{displayName: this.locale.getString('widget.directPeriodOrder'), name: 'asc'}
			];
		} else {
			this.$scope.options.nullInclude = [
				{displayName: this.locale.getString('metrics.include'), value: true},
				{displayName: this.locale.getString('metrics.exclude'), value: false}
			];

			this.$scope.options.sortOrder = [
				{displayName: this.locale.getString('widget.top'), name: 'desc'},
				{displayName: this.locale.getString('widget.bottom'), name: 'asc'}
			];
			if (this.modalSettings.props.widgetType === WidgetType.SELECTOR && this.$scope.uiRules.isCustomSortOrderAllowed()) {
				this.$scope.options.sortOrder.push({displayName: this.locale.getString('widget.custom'), name: 'custom'});
			}
		}

		this.$scope.options.barStackType = [
			{ displayName: this.locale.getString('widget.optionStacked'), value: StackType.NORMAL },
			{ displayName: this.locale.getString('widget.optionStackedHundredPercent'), value: StackType.HUNDRED_PERCENT }
		];

		this.$scope.options.lineStackType = [
			{ displayName: this.locale.getString('widget.optionNone'), value: StackType.NONE },
			{ displayName: this.locale.getString('widget.optionStacked'), value: StackType.NORMAL },
			{ displayName: this.locale.getString('widget.optionStackedHundredPercent'), value: StackType.HUNDRED_PERCENT }
		];

		if (this.$scope.item.sortBy)
			this.$scope.tempSort = SearchableHierarchyUtils.findMetricInHierarchy(this.$scope.options.sortBy, {name: this.$scope.item.sortBy});

		if (!this.$scope.item.sortBy || !this.$scope.tempSort) {
			let node;
			if (this.$scope.uiRules.isUsingTimeGrouping()) {
				node = this.$scope.options.sortBy[0];
			} else {
				node = this.$scope.options.sortBy[0].children[0];
			}

			this.$scope.item.sortBy = node.name;
			this.$scope.tempSort = node;
		}

		if (!this.$scope.item.sortOrder || this.isPareto()) {
			this.$scope.item.sortOrder = this.$scope.options.sortOrder[0].name;
		}


		if (this.$scope.item.metricType === AnalyticMetricType.TIME) {
			let isLegacyWidget = isEmpty(this.$scope.item.emptyPeriodType) && !isEmpty(this.$scope.item.nullInclude);
			let isNewWidget = isEmpty(this.$scope.item.emptyPeriodType) && isEmpty(this.$scope.item.nullInclude);

			if (isLegacyWidget) {
				this.$scope.item.emptyPeriodType = this.$scope.item.nullInclude
					? EmptyPeriodType.SHOW_INTERSPERSED
					: EmptyPeriodType.DO_NOT_SHOW;
			}

			if (isNewWidget) {
				this.$scope.item.emptyPeriodType = EmptyPeriodType.SHOW_ALL;
			}
		}

		if (isEmpty(this.$scope.item.nullInclude) && this.$scope.item.metricType !== AnalyticMetricType.TIME) {
			this.$scope.item.nullInclude = false;
		}

		if (this.$scope.uiRules.isStackedGroupingItem() && this.$scope.item.stackType === undefined) {
			this.$scope.item.stackType = StackType.NORMAL;
		}

		if (this.$scope.uiRules.isSeriesGroupingItem() && !this.$scope.item.stackType) {
			this.$scope.item.stackType = StackType.NONE;
		}

		this.initCapitalizationOptions();
		this.applyAttributeDefaults();
	};

	private initCapitalizationOptions = () => {
		this.$scope.capitalizationOptions = this.capitalization.getOptions();
		if (_.isUndefined(this.$scope.item.capitalization)) {
			this.$scope.item.capitalization = this.$scope.capitalizationOptions[0].value;
		}
	};



	private initCalculation = () => {
		this.$scope.ui.metricFormat = (this.modalSettings.metric) ? this.modalSettings.metric : {};
		this.$scope.metric = this.modalSettings.metric || {};

		// use the default format if metric type allows it, and it is already selected or no formatting options are set
		this.$scope.item.useDefaultFormat = this.numberFormatHelper.itemHasDefaultFormat(this.modalSettings.metric) &&
			(this.$scope.item.useDefaultFormat || !this.numberFormatHelper.hasFormattingOptions(this.$scope.item));

		if (!this.$scope.item.dataType && !this.$scope.item.customFormatting) {
			let defaultSetting = this.defaultDataFormatterBuilder.getDefaultFormatterSettings(this.$scope.item, this.modalSettings.studioMetrics);
			if (this.$scope.uiRules.isCalculationSeries()) {
				defaultSetting.conversion = MetricMultiplierType.NONE;
			}
			this.$scope.item = _.extend(defaultSetting, this.$scope.item.format || {}, this.$scope.item);
		}

		let dataType = FormatsService.find(this.$scope.item.dataType);
		if (dataType && !this.$scope.item.customFormatting)
			dataType.upgrade(this.$scope.item);

		if (this.$scope.uiRules.showColorDirection()) {
			let selected = this.$scope.uiRules.getColorDirectionRadioOptions().find(
				option => option.displayName === this.$scope.item.colorDirection);
			this.$scope.item.colorDirection = selected ? selected.displayName : 'direct';
		}
	};

	private withoutScorecardMetrics(metrics: INode[]): INode[] {
		return ObjectUtils.copy(metrics)?.filter(folder => folder.name !== OptionsConstant.SCORECARDS);
	}

	onLevelNodeClick = (node, $event) => {
		$event.stopPropagation();
		if (node && node.level) {
			this.$scope.item.selectedLevel = node.level;
			this.$scope.selectedLevelNode = node;
		}
	};

	getVolumeLabel = () => {
		let isSentenceItem = AnalyticMetricTypes.isSentenceLevel(this.modalSettings.configItem,
			this.modalSettings.props.selectedAttributes);
		return isSentenceItem ? this.locale.getString('widget.minimumSentenceCount')
			: this.locale.getString('widget.minimumVolume');
	};

	isCapitalizationOptionsAvailable = () => {
		if (this.modalSettings.hideCapitalizationOption)
			return false;

		return this.showFeedbackTableCapitalization() || this.$scope.uiRules.showCapitalization();
	};

	showUrlType = () => {
		return this.$scope.uiRules.showUrlType() || this.isFeedbackTable();
	};

	private isFeedbackTable = (): boolean => {
		return this.modalSettings.isFeedbackColumn && this.modalSettings.isTable;
	};

	private showFeedbackTableCapitalization = (): boolean => {
		let urlType = this.$scope.item.urlType;
		let isUrl = urlType !== undefined && urlType !== AttributeUrlType.NONE;
		return this.isFeedbackTable() && !isUrl;
	};

	isSentimentHighlightingAvailable = () => {
		let settings = this.modalSettings;
		return settings.isFeedbackColumn
			&& !settings.isVerbatimView
			&& settings.configItem.name === PreviewColumn.SENTENCE;
	};

	applyModelDefaults = () => {
		if (this.$scope.item.inheritTopics) {
			const settingsPromise = PromiseUtils.old(this.reportSettingsService.getGroupingSettings(this.$scope.props, this.$scope.item));
			settingsPromise.then((settings) => {
				const modelSettings = settings as ModelGroupingSettings;
				this.$scope.item.selectedLevel = settings ? modelSettings.selectedLevel : undefined;
				this.$scope.item.selectedNodes = settings ? modelSettings.selectedNodes : undefined;

				this.$scope.levelChanged();
				this.checkDefaultSelectedLevel();
				this.indeterminateTopicSelection.processInclusionList();
			});
			this.$scope.loadingTree = settingsPromise;
		} else {
			if (!this.$scope.uiRules.isMultiLevelTopic())
				this.checkDefaultSelectedLevel();
			this.indeterminateTopicSelection.processInclusionList();
		}
	};

	private checkDefaultSelectedLevel = () => {
		this.$scope.item.selectedLevel = (this.$scope.item.selectedLevel && (this.$scope.item.selectedLevel !== 'all'))
			? this.$scope.item.selectedLevel
			: this.$scope.levels[0].value;
	};

	applyAttributeDefaults = () => {
		if (this.$scope.item.inheritAttributeValues) {
			this.$scope.loadingDefaults = PromiseUtils.old(this.reportSettingsService.getGroupingSettings(this.$scope.props, this.$scope.item))
				.then((settings) => {
					if (settings) {
						const textSettings = settings as TextAttributeGroupingSettings;
						this.$scope.item.wordsFilteringMode = textSettings.wordsFilteringMode;
						this.$scope.item.wordsList = textSettings.wordsList;
					}
				});
		}
	};

	showNullIncludeSelector = () => {
		return !this.$scope.uiRules.isTopicOrLeaf()
			&& !this.$scope.uiRules.isHierarchyModel()
			&& !this.$scope.uiRules.isWordsOrAssociatedWordsGrouping()
			&& !this.$scope.uiRules.isNumericBreakdownGrouping();
	};

	isNodeCheckboxDisabled = () => {
		return !!this.$scope.item.inheritTopics;
	};

	isInclusionNodeChecked = (node) => {
		return _.indexOf(this.$scope.item.checkedInclusionNodes, node.level) >= 0;
	};

	handleInclusionNodeCheck = (node) => {
		let itemIndex = _.indexOf(this.$scope.item.checkedInclusionNodes, node.level);

		if (itemIndex >= 0) {
			this.$scope.item.checkedInclusionNodes.removeAt(itemIndex);
		} else {
			this.$scope.item.checkedInclusionNodes.push(node.level);
		}
	};

	private isTopicLeafEnabled = () => {
		return this.$scope.item.type === ReportAssetType.TOPIC_LEAF;
	};

	getUpdateInclusionFilterLabel = (): string => {
		return this.modalSettings.mobile
			? this.locale.getString('mobile.updateInclusionOnFilter')
			: this.locale.getString('widget.updateInclusionOnFilter');
	};

	getMaxGroupingSize = (): number => {
		switch (this.modalSettings.props.widgetType) {
			case WidgetType.TABLE:
			case WidgetType.MAP:
				return 3000;
			case WidgetType.NETWORK:
				return 300;
			default:
				return 1000;
		}
	};

	selectSortOrder = (option): void => {
		this.$scope.item.sortOrder = option.name;
		if (option.name === 'custom') {
			if (this.$scope.uiRules.wordsSelectionAllowed()) {
				this.$scope.item.wordsFilteringMode = 'INCLUDE';
			}
			if (this.$scope.uiRules.isTopicOrLeaf() && this.isCustomMultiselectDropList()) {
				this.$scope.item.selectedLevel = TopicReportGrouping.LEAF_LEVEL;
				this.levelChanged();
			}
		}
	};

	private isLevelDisabled = (): boolean => this.$scope.item.inheritTopics || this.isCustomMultiselectDropList();

	private isCustomMultiselectDropList = (): boolean =>
		this.modalSettings.isWidgetAssetConfig
			&& this.$scope.visualProps
			&& this.$scope.visualProps.visualization === SelectorWidgetNavigationType.SELECTOR_DROPLIST
			&& this.$scope.visualProps.multiselect
			&& this.$scope.uiRules.isCustomSortOrder();

	private getModelTree = (): ng.IPromise<any> => {
		let modelTreePromise = PromiseUtils.old(this.modelsService.getModelTree(
			ProjectIdentifier.fromWidgetProperties(this.modalSettings.props),
			parseInt(this.modalSettings.configItem.name, 10)));

		if (this.isMapWidgetGrouping()) {
			let geographyPromise = PromiseUtils.old(this.geographyApiService.getModelGeography(
				ProjectIdentifier.fromWidgetProperties(this.modalSettings.props),
				(this.modalSettings.configItem.name as unknown) as number));

			return this.$q.all([modelTreePromise, geographyPromise ]).then(results => {
				let modelTree = results[0];
				let geography = results[1];
				(modelTree as any).geography = geography;
				return modelTree;
			});
		} else {
			return modelTreePromise;
		}
	};

	private isMapWidgetGrouping = (): boolean => {
		return this.modalSettings.configurationOptions.contains(WidgetMetricConfigurationOptions.MAP_WIDGET_GROUPING);
	};

	private isMapWidgetLevelAllowed = (tree: {geography: ModelGeography}, level: number): boolean => {
		return !!tree.geography.boundaryFields[level];
	};

	private isPareto = () => DualAxisTypes.isParetoVisualization(this.$scope.visualProps);

	private isMetricWidgetEditor = (): boolean => {
		return this.modalSettings.props?.widgetType === WidgetType.METRIC;
	};

	showStyleSettings = (): boolean => {
		return (this.$scope.config.showColorDirection || this.$scope.config.showArrowToggle) && this.$scope.config.showStyleSettings;
	};
}

app.controller('widgetMetricConfigController', WidgetConfigController);
