import { BookTabData } from '@app/modules/book/book-tab-data';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { BookDashboardTab, BookEmbedTab, BookTabType, IBookTab } from '@cxstudio/dashboards/entity/book';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { QualtricsEmbedData } from '@app/modules/widget-visualizations/qualtrics/qualtrics-embed-data';

export class BookViewTab<MetadataType = DashboardTabMetadata | EmbedTabMetadata> implements IBookTab {

	static readonly URL_TYPE_XM = 'xm';

	name: string;
	type: BookTabType;
	properties: BookDashboardTab | BookEmbedTab;
	active?: boolean;
	rendered?: boolean;
	metadata: MetadataType;

	constructor(tab: IBookTab) {
		this.name = tab.name;
		this.type = tab.type;
		this.properties = tab.properties;
	}

	static dashboardTab(tab: IBookTab, data: BookTabData,
		dashboardHistory: IDashboardHistoryInstance): BookViewTab<DashboardTabMetadata> {
		let viewTab = new BookViewTab<DashboardTabMetadata>(tab);
		viewTab.metadata = {
			dashboardId: (tab.properties as BookDashboardTab).dashboardId,
			dashboard: data.dashboard,
			widgets: data.widgets || [],
			viewAllowed: !!data.viewAllowed,
			frontline: data.frontline,
			snapshotDate: data.dashboardSnapshot?.createdDate,
			removed: false,
			dashboardHistory,
		};
		return viewTab;
	}

	static deletedDashboardTab(tab: IBookTab): BookViewTab<DashboardTabMetadata> {
		let viewTab = new BookViewTab<DashboardTabMetadata>(tab);
		viewTab.metadata = {
			removed: true,
		} as DashboardTabMetadata;
		return viewTab;
	}

	static embedTab(tab: IBookTab): BookViewTab<EmbedTabMetadata> {
		let viewTab = new BookViewTab<EmbedTabMetadata>(tab);
		viewTab.metadata = {
			embedDashboardId: (tab.properties as BookEmbedTab).embedDashboardId
		};
		return viewTab;
	}

	getTabId(): string {
		if (this.isDashboardTab())
			return '' + this.metadata.dashboardId;
		if (this.isEmbedTab())
			return this.metadata.embedDashboardId;
		return undefined;
	}

	getTabType(): string | undefined {
		return this.isEmbedTab() ? BookViewTab.URL_TYPE_XM : undefined;
	}

	isDashboardTab(): this is BookViewTab<DashboardTabMetadata> {
		return this.type === BookTabType.DASHBOARD;
	}

	isEmbedTab(): this is BookViewTab<EmbedTabMetadata> {
		return this.type === BookTabType.EMBED;
	}
}

export interface DashboardTabMetadata {
	dashboardId: number;
	dashboard: Dashboard;
	widgets: Widget[];
	viewAllowed: boolean;
	removed: boolean;
	frontline: boolean;
	snapshotDate: string;
	dashboardHistory: IDashboardHistoryInstance;
}

export interface EmbedTabMetadata {
	embedDashboardId: string;
	embedData?: QualtricsEmbedData;
}

