import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { GridFormatter, IRowFormatter } from '@cxstudio/grids/grid-formatter-service';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { InteractionExplorer } from '@cxstudio/interaction-explorer/interaction-explorer.component';
import { CxLocaleService } from '@app/core';
import { Inject } from '@angular/core';
import { CommonGridColumns } from '@cxstudio/grids/common-grid-columns.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { AttributeObjectType } from '@app/modules/project/attribute/attribute-object-type';
import { IReportModel } from '@app/modules/project/model/report-model';
import { CbDocument } from '@cxstudio/reports/entities/cb-document.class';
import { TopicChickletsService } from '@app/modules/document-explorer/topic-chicklet.service';
import { WorldAwarenessService } from '@cxstudio/reports/document-explorer/world-awareness-attributes.service';
import { IProjectSettings } from '@cxstudio/services/data-services/project-settings.service';
import { EnrichmentAttributesService } from '@cxstudio/reports/document-explorer/enrichment-attributes.service';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { AnalyticPreviewFormatting } from '@cxstudio/reports/providers/cb/services/analytic-preview-formatting.service';
import { GridContextColumnItemOption } from '@cxstudio/grids/grid-context-menu.component';
import { PreviewChunkService } from '@app/modules/document-explorer/preview-chunk.service';
import { ProjectSettingsMap } from '@app/modules/project/settings/report-settings.service';
import { ClarabridgeAttributeName } from '@cxstudio/reports/providers/cb/constants/clarabridge-attribute-name';
import { ProfanityDisguiseService } from '../profanity/profanity-disguise.service';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { HtmlUtils } from '@app/shared/util/html-utils.class';

export enum ColumnPrefix {
	ATTRIBUTE = '__attribute_',
	TOPIC = '__topic_',
	ENRICHMENT = '__enrichment_'
}

interface InteractionExplorerGridColumn extends IGridColumn {
	type?: ObjectType;
	platformName?: string;
}

export interface InteractionExplorerColumnItemOption extends GridContextColumnItemOption {
	object: InteractionExplorerGridColumn;
}

@Injectable()
export class InteractionExplorerGridDefinition implements IGridDefinition<InteractionExplorer> {

	static getModelIdFromColumn = (columnName: string): number => {
		if (columnName.startsWith(ColumnPrefix.TOPIC)) return parseInt(columnName.slice(ColumnPrefix.TOPIC.length), 10);
		return -1;
	};

	static getAttributeNameFromColumn = (columnName: string): string => {
		if (columnName.startsWith(ColumnPrefix.ATTRIBUTE)) return columnName.slice(ColumnPrefix.ATTRIBUTE.length);
		if (columnName.startsWith(ColumnPrefix.ENRICHMENT)) return columnName.slice(ColumnPrefix.ENRICHMENT.length);
		if (columnName === 'interactionType') return 'cb_interaction_type';
		if (columnName === 'sourceId') return '_id_source';
		if (columnName === 'processedLanguage') return '_languagedetected';
		return '';
	};

	constructor(
		private locale: CxLocaleService,
		@Inject('gridFormatterService') private gridFormatterService: GridFormatter,
		@Inject('commonGridColumns') private commonGridColumns: CommonGridColumns,
		private topicChickletsService: TopicChickletsService,
		@Inject('worldAwarenessService') private worldAwarenessService: WorldAwarenessService,
		@Inject('enrichmentAttributesService') private enrichmentAttributesService: EnrichmentAttributesService,
		@Inject('analyticPreviewFormatting') private analyticPreviewFormatting: AnalyticPreviewFormatting,
		private readonly previewChunkService: PreviewChunkService,
		private readonly profanityDisguiseService: ProfanityDisguiseService,
		private readonly metricsService: MetricsService,
	) {
	}

	init = (gridMode: GridMode, controller: InteractionExplorer): Promise<InteractionExplorerGridColumn[]> => {
		let promise = Promise.all([ controller.getProjectAssets(), controller.getAttributeSettings(),
			this.metricsService.getPredefinedMetrics(controller.projectSelection) ]).then(result => {
			let assets: IProjectSettings = result[0];
			let attributeSettings: ProjectSettingsMap = result[1];
			let predefinedMetrics: Metric[] = result[2];

			let columns: InteractionExplorerGridColumn[] = this.getDefaultColumns(controller, predefinedMetrics);
			let attributes = _.filter(assets.attributes, attr => !attr.hide);
			let models = _.filter(assets.models, model => !model.hide && !model.hidden);
			let customColumns = [];
			customColumns.pushAll(this.getAttributesColumns(attributes, predefinedMetrics));
			customColumns.pushAll(this.getEnrichmentColumns(attributes, attributeSettings, predefinedMetrics));
			customColumns.pushAll(this.getTopicsColumns(models));
			_.each(customColumns, column => column.name = HtmlUtils.escapeHtml(column.name));
			return columns.concat(customColumns);
		});

		controller.loading.columnsPromise = promise;
		return promise;
	};

	private getDefaultColumns = (controller: InteractionExplorer, predefinedMetrics: Metric[]): InteractionExplorerGridColumn[] => {
		let hamburgerLabel = this.locale.getString('common.objectTypeMenu', {objectType: this.locale.getString('preview.document')});

		let sentiment = _.findWhere(predefinedMetrics, {name: PredefinedMetricConstants.SENTIMENT});
		let sentenceFormatter = this.analyticPreviewFormatting.sentenceFormatter(true, sentiment);
		let sentimentFormatter = this.analyticPreviewFormatting.sentimentFormatter(sentiment);
		let effortFormatter = this.analyticPreviewFormatting.easeScore(
			_.findWhere(predefinedMetrics, {name: PredefinedMetricConstants.EASE_SCORE}));
		let emotionalIntencityFormatter = this.analyticPreviewFormatting.emotion(
			_.findWhere(predefinedMetrics, {name: PredefinedMetricConstants.EMOTION}));

		return [
			this.commonGridColumns.getRowSelectorColumn(),
			{
				id: 'hamburger',
				sortable: false,
				minWidth: 32,
				width: 32,
				headerCssClass: 'header-hamburger text-center',
				name: `<i class="q-icon q-icon-layer" aria-label="${hamburgerLabel}" title="${hamburgerLabel}"></i>`,
				cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
				formatter: this.gridFormatterService.getPaginatedHamburgerFormatter(controller),
				resizable: false
			},
			this.commonGridColumns.getSpacerColumn(),
			{
				id: 'documentId',
				name: this.locale.getString('interactionExplorer.documentId'),
				field: 'id',
				sortable: false,
				minWidth: 50,
				width: 300,
				cssClass: 'cell-title br-document-id',
				optional: true,
				default: true
			}, {
				id: 'preview',
				name: 'Preview',
				sortable: false,
				minWidth: 400,
				width: 560, // default to approx 40ems @ 14px font size
				resizable: false,
				field: 'sentences',
				cssClass: 'preview-text text-wrap lh-default cursor-pointer',
				formatter: (row, cell, value, columnDef, document): string => {
					let sentence = document.sentences[0];
					if (this.previewChunkService.isNoVerbatim(sentence)) {
						return `<i>${this.locale.getString('preview.emptyVerb')}</i>`;
					}
					let sentenceText = controller.settings.showSentimentFormatting ? sentenceFormatter(sentence) : sentence.text;
					let tooltip = this.locale.getString('widget.openInExplorer');
					let sentenceBlock = `<div class="multiline-truncate" title="${tooltip}">${sentenceText}</div>`;

					let badgesBlock = '';
					if (controller.settings.showEnrichmentBadges) {
						let badges = [];
						badges.push(sentimentFormatter(sentence));
						badges.push(effortFormatter(sentence));
						badges.push(emotionalIntencityFormatter(sentence));
						let allBadges = badges.join('');
						if (allBadges) badgesBlock = `<div>${allBadges}</div>`;
					}
					return `<div>${sentenceBlock}${badgesBlock}</div>`;
				},
				optional: true,
				default: true
			}, {
				id: 'spacer',
				sortable: false,
				minWidth: 16,
				width: 16,
				resizable: false,
				cssClass: 'no-border-if-folder',
				attributes: {
					'aria-hidden': true
				}
			}, {
				id: 'interactionType',
				name: this.locale.getString('interactionExplorer.interactionType'),
				field: 'interactionType',
				sortable: false,
				minWidth: 50,
				width: 300,
				cssClass: 'cell-title br-interaction-type',
				formatter: this.getDocumentAttributeValueFormatter('cb_interaction_type'),
				optional: true,
				default: true,
				type: ObjectType.ATTRIBUTE,
				platformName: 'cb_interaction_type',
			}, {
				id: 'interactionDate',
				name: this.locale.getString('interactionExplorer.interactionDate'),
				field: 'documentDate',
				sortable: false,
				minWidth: 50,
				width: 300,
				cssClass: 'cell-title br-interaction-date',
				formatter: this.gridFormatterService.DateFormatter,
				optional: true,
				default: true
			}, {
				id: 'sourceId',
				name: this.locale.getString('interactionExplorer.sourceId'),
				field: 'sourceId',
				sortable: false,
				minWidth: 50,
				width: 300,
				cssClass: 'cell-title br-source-id',
				formatter: this.getDocumentAttributeValueFormatter('_id_source'),
				optional: true,
				default: true,
			}, {
				id: 'processedLanguage',
				name: this.locale.getString('interactionExplorer.processedLanguage'),
				field: 'processedLanguage',
				sortable: false,
				minWidth: 50,
				width: 300,
				cssClass: 'cell-title br-processed-language',
				formatter: this.getSentenceAttributeValueFormatter('_languagedetected', predefinedMetrics),
				optional: true,
				default: true,
			}];
	};

	private getAttributesColumns(attributes: IReportAttribute[], predefinedMetrics: Metric[]): InteractionExplorerGridColumn[] {
		let enrichmentAttributeNames = this.worldAwarenessService.getWorldAwarenessAttributes().map(attribute => attribute.name);
		return attributes
			.filter(attribute => !enrichmentAttributeNames.contains(attribute.name))
			.map(attribute => ({
				id: ColumnPrefix.ATTRIBUTE + attribute.name,
				name: attribute.displayName,
				persistedName: ColumnPrefix.ATTRIBUTE + attribute.name,
				field: attribute.name,
				sortable: false,
				minWidth: 50,
				width: 300,
				cssClass: 'cell-title',
				formatter: this.getAttributeFormatter(attribute, predefinedMetrics),
				optional: true,
				selected: false,
				groupKey: 'common.attributes',
				type: ObjectType.ATTRIBUTE,
				platformName: attribute.name,
			}));
	}

	private getEnrichmentColumns(enrichments: IReportAttribute[], attributeSettings: ProjectSettingsMap,
		predefinedMetrics: Metric[]): InteractionExplorerGridColumn[] {
		let enrichmentAttributeNames = this.worldAwarenessService.getWorldAwarenessAttributes().map(attribute => attribute.name);
		return enrichments
			.filter(enrichment => enrichmentAttributeNames.contains(enrichment.name))
			.map(enrichment => ({
				id: ColumnPrefix.ENRICHMENT + enrichment.name,
				name: enrichment.displayName,
				persistedName: ColumnPrefix.ENRICHMENT + enrichment.name,
				field: enrichment.name,
				sortable: false,
				minWidth: 50,
				width: 300,
				cssClass: 'cell-title text-wrap',
				formatter: this.getEnrichmentFormatter(enrichment, attributeSettings, predefinedMetrics),
				optional: true,
				selected: false,
				groupKey: 'preview.worldAwareness',
				type: ObjectType.ATTRIBUTE,
				platformName: enrichment.name,
			}));
	}

	private getTopicsColumns(models: IReportModel[]): InteractionExplorerGridColumn[] {
		return models.map(model => ({
			id: ColumnPrefix.TOPIC + model.id,
			name: model.name,
			persistedName: ColumnPrefix.TOPIC + model.id,
			field: 'classification',
			sortable: false,
			minWidth: 50,
			width: 300,
			cssClass: 'cell-title text-wrap',
			formatter: this.getTopicsFormatter(model),
			optional: true,
			selected: false,
			groupKey: 'widget.topics',
			type: ObjectType.MODEL,
			platformName: model.id + '',
		}));
	}

	private getAttributeFormatter = (attribute: IReportAttribute, predefinedMetrics: Metric[]): IRowFormatter => {
		return attribute.objectType === AttributeObjectType.DOCUMENT
			? this.getDocumentAttributeValueFormatter(attribute.name)
			: this.getSentenceAttributeValueFormatter(attribute.name, predefinedMetrics);
	};

	private getDocumentAttributeValueFormatter = (attributeName: string): IRowFormatter => {
		return (row, cell, value, columnDef, document: CbDocument): string => {
			return this.getDocumentLevelValue(document, attributeName);
		};
	};

	private getSentenceAttributeValueFormatter = (attributeName: string, predefinedMetrics: Metric[]): IRowFormatter => {
		return (row, cell, value, columnDef, document: CbDocument): string => {
			return this.getSentenceLevelValues(document, attributeName, predefinedMetrics).join(', ');
		};
	};

	private getEnrichmentFormatter = (enrichment: IReportAttribute,
		attributeSettings: ProjectSettingsMap, predefinedMetrics: Metric[]): IRowFormatter => {
		return (row, cell, value, columnDef, document: CbDocument): string => {
			let allValues = enrichment.objectType === AttributeObjectType.DOCUMENT
				? [ this.getDocumentLevelValue(document, enrichment.name) ]
				: this.getSentenceLevelValues(document, enrichment.name, predefinedMetrics);

			let values = allValues.filter(val => !!val);

			let settings = this.enrichmentAttributesService.getAttributeSettings(enrichment, attributeSettings);
			return values.reduce((memo, val) => {
				let formattedValue = this.enrichmentAttributesService.formatAttributeValue(val, enrichment.type, settings, false);
				// eslint-disable-next-line max-len
				let valueHtml = `<div class="label-bubble world-awareness-bubble h-32 border-radius-16 ph-8 pv-4 m-4 border-solid border-1">${formattedValue}</div>`;
				return memo + valueHtml;
			}, '');
		};
	};

	private getDocumentLevelValue = (document: CbDocument, attributeName: string): string => {
		return (_.findWhere(document.attributes, { name: attributeName.toLowerCase() }) as any)?.value;
	};

	private getSentenceLevelValues = (document: CbDocument, attributeName: string, predefinedMetrics: Metric[]): string[] => {
		if (attributeName === ClarabridgeAttributeName.CB_PROFANITY) {
			let profanitySentiments =
				this.profanityDisguiseService.getProfanityInstancesBySentiment(predefinedMetrics, document);

			return profanitySentiments.map(sentimentVal => `${sentimentVal.value}`);
		}

		return _.chain(document.sentences || [])
			.filter(sentence => !!sentence.attributes)
			.map(sentence => sentence.attributes[attributeName])
			.flatten()
			.unique()
			.value();
	};

	private getTopicsFormatter = (model: IReportModel): IRowFormatter => {
		return (row, cell, value, columnDef, document: CbDocument): string => {
			const formattedModel = _.findWhere(document.classification, { modelId: model.id });
			if (formattedModel) {
				return formattedModel.nodes.reduce((memo, node) => {
					let chickletTitle = this.topicChickletsService.getPath(node, formattedModel);
					let chickletDisplayName = this.topicChickletsService.getTopicChickletDisplayName(node);
					let nodeHtml = `<div class="label-bubble ellipsis h-32 border-radius-16 ph-8 pv-4 m-4 border-solid border-1" `
						+ `title="${chickletTitle}">${chickletDisplayName}</div>`;
					return memo + nodeHtml;
				}, '');
			} else {
				return '';
			}
		};
	};

}

app.service('interactionExplorerGridDefinition', downgradeInjectable(InteractionExplorerGridDefinition));
