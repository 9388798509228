import * as _ from 'underscore';
import { IListOption } from './list-option.interface';
import { Security } from '@cxstudio/auth/security-service';
import { IFilterRule, AdhocFilter } from '@cxstudio/reports/entities/adhoc-filter.class';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { DriversItem } from '../entities/drivers-item';
import { BaseStatsItem, AttributesStats } from '../entities/attributes-stats';
import { AttributeWarning } from './attribute-warning.enum';
import { WorldAwarenessService } from '@cxstudio/reports/document-explorer/world-awareness-attributes.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { IReportModel } from '@app/modules/project/model/report-model';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { IProjectContext } from '@app/modules/project/context/project-context';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { FilterValidationService } from '@cxstudio/report-filters/filter-validation-service.service';
import { DriversApi } from '@app/modules/drivers/services/drivers-api.service';
import { PromiseUtils } from '@app/util/promise-utils';

export class DriversHelperService {

	constructor(
		private security: Security,
		private driversApi: DriversApi,
		private worldAwarenessService: WorldAwarenessService,
		private filterValidationService: FilterValidationService,
		private optionsBuilderProvider: OptionsBuilderProvider,
		private optionsTemplatesService: OptionsTemplatesService,
	) {}

	isModel = (option: IListOption): boolean => option.optionType === ObjectType.MODEL;

	isCustomPredictionProvider = (): boolean => {
		let currentMA = this.security.getCurrentMasterAccount();
		return !currentMA.predictionProviderDefault;
	};

	getNonEmptyFilters = (filterRules: IFilterRule[]): IFilterRule[] => {
		return _.filter(filterRules, rule => {
			return rule.type !== FilterRuleType.empty;
		});
	};

	getAvailableAttributes = (options: IListOption[]): IListOption[] => {
		return _.filter(options, option => {
			return option.optionType === ObjectType.ATTRIBUTE && !option.disabled;
		});
	};

	getAvailableModels = (options: IListOption[]): IListOption[] => {
		return _.filter(options, option => {
			return this.isModel(option) && !option.disabled;
		});
	};

	getFilterItems = (attributes: IReportAttribute[], wordAttributes: IReportAttribute[], models: IReportModel[],
		context: IProjectContext): any[] => {
		return this.optionsBuilderProvider.getBuilder()
			.withTemplate(this.optionsTemplatesService.filterItemsDefault())
			.withModels(models)
			.withAttributes(attributes, MetricFilters.NOT_DOCUMENT_DATE)
			.withWordAttributes(wordAttributes)
			.build();
	};

	getAttributesWarnings = (driversItem: DriversItem, allItems: IListOption[]):
		ng.IPromise<{[key: string]: AttributeWarning}> => {

		let statsProperties = angular.copy(driversItem);
		let definition = statsProperties.definition;
		definition.attributes = _.chain(allItems)
			.filter({optionType: ObjectType.ATTRIBUTE} as any)
			.map(attr => {
				return {name: attr.name};
			}).value();

		definition.models = _.chain(allItems)
			.filter({optionType: ObjectType.MODEL} as any)
			.map(model => {
				return {name: `${model.id}`};
			}).value();
		delete statsProperties.target.filters;
		return PromiseUtils.old(this.driversApi.getAttributesStats(statsProperties)).then(this.processWarnings);
	};

	private processWarnings = (stats: AttributesStats): {[key: string]: AttributeWarning} => {
		let result = {} as {[key: string]: AttributeWarning};
		_.each(stats.attributes, statsItem => {
			let warning = this.getAttributeWarning(statsItem, stats.total,
				this.worldAwarenessService.isNLPAttribute(statsItem.name));
			if (warning) {
				result[statsItem.name.toLowerCase()] = warning;
			}
		});
		_.each(stats.models, statsItem => {
			let warning = this.getAttributeWarning(statsItem, stats.total, true);
			if (warning) {
				result[statsItem.id] = warning;
			}
		});
		return result;
	};

	private getAttributeWarning(statsItem: BaseStatsItem, total: number, sentenceLevel: boolean): AttributeWarning {
		if (statsItem.populatedPercent === 0)
			return AttributeWarning.NO_VALUES;
		if (statsItem.populatedPercent <= 0.03)
			return AttributeWarning.SPARSE;
		if (!sentenceLevel && statsItem.distinctValuesCount / total >= 0.98)
			return AttributeWarning.UNIQUE;
		return undefined;
	}

	areFiltersValid = (additionalFilters: AdhocFilter): boolean =>
		!!(additionalFilters.filterRules.isEmpty() || this.filterValidationService.validateFilterRules(additionalFilters));
}

app.service('driversHelperService', DriversHelperService);
