import { Component, ChangeDetectionStrategy, Input, OnInit, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { CxFormUtils } from '@app/modules/cx-form/utils/form-utils';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { AdminContentProvider } from './admin-content-provider';

@Component({
	selector: 'discover-designer-api-integration',
	template: `
	<form [formGroup]="ddaSettings">
			<div class="rounded-grouping">
				<div class="rounded-grouping-label">
					<div class="labeled-checkbox">
						<checkbox-button class="mr-0" id="ddaEnabled" name="ddaEnabled" label="{{'common.enabled'|i18n}}"
							formControlName="ddaEnabled">
						</checkbox-button>
					</div>
				</div>

				<div class="labeled-checkbox rounded-grouping-first-element">
					<checkbox-button class="mr-0" id="resultVerificationEnabled" name="resultVerificationEnabled"
						label="{{'administration.ddaResultVerificationEnabled'|i18n}}"
						formControlName="resultVerificationEnabled">
					</checkbox-button>
				</div>
		
				<div class="form-group">
					<label for="designerUrl" class="control-label col-md-3"
						[i18n]="'administration.ddaDesignerBaseUrl'"></label>
					<div class=col-md-9>
						<input id="designerUrl" name="designerUrl" class=form-control formControlName="designerUrl">
					</div>
				</div>
				<div class="form-group">
					<label for="analyticsUrl" class="control-label col-md-3"
						[i18n]="'administration.ddaAnalyticsBaseUrl'"></label>
					<div class=col-md-9>
						<input id="analyticsUrl" name="analyticsUrl" class=form-control formControlName="analyticsUrl">
					</div>
				</div>
				<div class="form-group">
					<label for="designerAuthEnvKey" class="control-label col-md-3"
						[i18n]="'administration.ddaDesignerAuthEnvKey'"></label>
					<div class=col-md-9>
						<input id="designerAuthEnvKey" name="designerAuthEnvKey" class=form-control
							formControlName="designerAuthEnvKey">
					</div>
				</div>
				<div class="form-group">
					<label for="analyticsAuthEnvKey" class="control-label col-md-3"
						[i18n]="'administration.ddaAnalyticsAuthEnvKey'"></label>
					<div class=col-md-9>
						<input id="analyticsAuthEnvKey" name="analyticsAuthEnvKey" class=form-control
							formControlName="analyticsAuthEnvKey">
					</div>
				</div>
				<div class="form-group">
					<label for="esQueryNodes" class="control-label col-md-3" [i18n]="'administration.ddaEsQueryNodes'"></label>
					<div class=col-md-9>
						<input id="esQueryNodes" name="esQueryNodes" class=form-control formControlName="esQueryNodes">
					</div>
				</div>
				<div class="form-group">
					<div class="row help-block">
						<p *ngIf="ddaSettings?.invalid" class="col-md-12 text-danger col-md-offset-3">
							{{'administration.ddaConfigIncomplete'|i18n}}
						</p>
					</div>
				</div>
			</div>
		</form>
	
	`,
	styles: [`
	.rounded-grouping-label { top: -20px; }
	.rounded-grouping-first-element { margin-top: 20px; }
`],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class DiscoverDesignerApiIntegrationComponent extends SelfCleaningComponent implements OnInit, AfterViewInit {

	ddaSettings: UntypedFormGroup;
	@Input() cp: AdminContentProvider;
	@Output() validityChange = new EventEmitter<boolean>();

	constructor(readonly formUtils: CxFormUtils, private fb: UntypedFormBuilder) {
		super();
		this.ddaSettings = this.fb.group({

			ddaEnabled: [ false ],
			resultVerificationEnabled: [{ value: null, disabled: true }],
			designerUrl: [{ value: null, disabled: true }, [ Validators.required ]],
			analyticsUrl: [{ value: null, disabled: true }, [ Validators.required ]],
			designerAuthEnvKey: [{ value: null, disabled: true }, [ Validators.required ]],
			analyticsAuthEnvKey: [{ value: null, disabled: true }, [ Validators.required ]],
			esQueryNodes: [{ value: null, disabled: true }, [ Validators.required ]],
		});		
	}

	ngAfterViewInit(): void {
		this.addSubscription(this.ddaSettings.statusChanges.subscribe(() => {
			let valid: boolean = this.ddaSettings.valid;
			this.validityChange.emit(valid);
		}));
	}


	ngOnInit(): void {
		this.ddaEnabled.setValue(this.cp.ddaSettings.ddaEnabled);
		if(this.cp.ddaSettings.ddaEnabled) {
			this.formUtils.enableFields(this.resultVerificationEnabled, this.designerUrl, this.analyticsUrl,
				this.designerAuthEnvKey, this.analyticsAuthEnvKey, this.esQueryNodes);
		}
		this.resultVerificationEnabled.setValue(this.cp.ddaSettings.resultVerificationEnabled);
		this.designerUrl.setValue(this.cp.ddaSettings.designerUrl);
		this.analyticsUrl.setValue(this.cp.ddaSettings.analyticsUrl);
		this.designerAuthEnvKey.setValue(this.cp.ddaSettings.designerAuthEnvKey);
		this.analyticsAuthEnvKey.setValue(this.cp.ddaSettings.analyticsAuthEnvKey);
		this.esQueryNodes.setValue(this.cp.ddaSettings.esQueryNodes);
		this.ddaSettings.updateValueAndValidity();

		this.addSubscription(this.ddaSettings.get('ddaEnabled').valueChanges.subscribe((value) => {
			this.cp.ddaSettings.ddaEnabled = value;
			if (value) {
				this.formUtils.enableFields(this.resultVerificationEnabled, this.designerUrl, this.analyticsUrl,
					this.designerAuthEnvKey, this.analyticsAuthEnvKey, this.esQueryNodes);
			} else {
				this.formUtils.disableFields(this.resultVerificationEnabled, this.designerUrl, this.analyticsUrl,
					this.designerAuthEnvKey, this.analyticsAuthEnvKey, this.esQueryNodes);
			}
		}));

		this.addSubscription(this.ddaSettings.get('resultVerificationEnabled').valueChanges.subscribe((value) => {
			this.cp.ddaSettings.resultVerificationEnabled = value;
		}));

		this.addSubscription(this.ddaSettings.get('designerUrl').valueChanges.subscribe((value) => {
			this.cp.ddaSettings.designerUrl = value;
		}));

		this.addSubscription(this.ddaSettings.get('analyticsUrl').valueChanges.subscribe((value) => {
			this.cp.ddaSettings.analyticsUrl = value;
		}));

		this.addSubscription(this.ddaSettings.get('designerAuthEnvKey').valueChanges.subscribe((value) => {
			this.cp.ddaSettings.designerAuthEnvKey = value;
		}));

		this.addSubscription(this.ddaSettings.get('analyticsAuthEnvKey').valueChanges.subscribe((value) => {
			this.cp.ddaSettings.analyticsAuthEnvKey = value;
		}));

		this.addSubscription(this.ddaSettings.get('esQueryNodes').valueChanges.subscribe((value) => {
			this.cp.ddaSettings.esQueryNodes = value;
		}));

		this.validityChange.emit(this.ddaSettings.valid);
	}

	
	get ddaEnabled(): UntypedFormControl {
		return this.ddaSettings.controls.ddaEnabled as UntypedFormControl;
	}

	get resultVerificationEnabled(): UntypedFormControl {
		return this.ddaSettings.controls.resultVerificationEnabled as UntypedFormControl;
	}

	get designerUrl(): UntypedFormControl {
		return this.ddaSettings.controls.designerUrl as UntypedFormControl;
	}
	get analyticsUrl(): UntypedFormControl {
		return this.ddaSettings.controls.analyticsUrl as UntypedFormControl;
	}
	get designerAuthEnvKey(): UntypedFormControl {
		return this.ddaSettings.controls.designerAuthEnvKey as UntypedFormControl;
	}
	get analyticsAuthEnvKey(): UntypedFormControl {
		return this.ddaSettings.controls.analyticsAuthEnvKey as UntypedFormControl;
	}
	get esQueryNodes(): UntypedFormControl {
		return this.ddaSettings.controls.esQueryNodes as UntypedFormControl;
	}

}
