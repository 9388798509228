import {
	AfterContentInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import {
	ApplicationSuiteApiService,
	ApplicationSuiteConfig,
} from '../services/application-suite-api.service';
import { Observable, of } from 'rxjs';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';

export interface SuiteApplicationUI {
	labelKey: string;
	destination: string;
}

@Component({
	selector: 'suite-applications',
	templateUrl: './suite-applications.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuiteApplicationsComponent extends SelfCleaningComponent implements OnInit, AfterContentInit {

	constructor(
		private readonly applicationSuiteService: ApplicationSuiteApiService,
		private ref: ChangeDetectorRef
	) {
		super();
	}

	@ViewChild(NgbDropdown) private ngbDropdown: NgbDropdown;
	suiteApplicationsMenuOpen: boolean;
	isLoading: boolean;
	config: Observable<ApplicationSuiteConfig>;

	apps: SuiteApplicationUI[] = [];

	readonly configI18NMap = new Map<string, string>([
		['cxEngage', 'header.xmEngage'],
		['socialConnect', 'header.qualtricsSocialConnect']
	]);

	ngOnInit(): void {
		this.suiteApplicationsMenuOpen = false;
		this.isLoading = true;
		this.config = of();
	}

	ngAfterContentInit(): void {
		this.config =  this.applicationSuiteService.getSuiteConfig();

		this.addSubscription(this.config.subscribe((suiteConfig: ApplicationSuiteConfig) => {

			for (let itemConfig in suiteConfig) {
				if (suiteConfig[itemConfig].enabled) {
					this.apps.push({
						labelKey: this.configI18NMap.get(itemConfig),
						destination: suiteConfig[itemConfig].location
					});
				}
			}

			this.isLoading = false;
			this.ref.markForCheck();
		}));
	}

	shouldDisplayWaffleMenu = ():boolean => {
		return !this.apps.isEmpty() && !this.isLoading;
	};

	onSuiteApplicationsMenuToggleKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER) ) {
			this.ngbDropdown.toggle();
			this.toggleDropdownMenu();
		}
	};

	onSuteApplicationsMenuToggle = (event: MouseEvent): void => {
		this.toggleDropdownMenu();
	};

	toggleDropdownMenu = () => {
		this.suiteApplicationsMenuOpen = this.ngbDropdown.isOpen();
		setTimeout(() => {
			if (this.suiteApplicationsMenuOpen) {
				$('#suite-applications-menu :focusable').first().trigger('focus');
			}
		});
	};

	onSuiteApplicationsMenuKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			this.ngbDropdown.close();
			this.suiteApplicationsMenuOpen = false;
			$('suite-applications :focusable').first().trigger('focus');
		}
		if (KeyboardUtils.isEventKey(event, Key.TAB)) {
			this.ngbDropdown.close();
			this.suiteApplicationsMenuOpen = false;
		}
	};

}

app.directive('suiteApplications',
	downgradeComponent({component: SuiteApplicationsComponent}));
