import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, TemplateRef } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { AlertTriggerCriteria } from '@app/modules/alert/alert-trigger-criteria';
import { AlertTriggerCriteriaOptionsService } from '@app/modules/alert/services/alert-trigger-criteria-options.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import ScorecardUtils from '@app/modules/scorecards/utils/scorecard-utils';
import { INode, SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { ObjectUtils } from '@app/util/object-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { AlertThresholdTypes } from '@cxstudio/alerts/entities/alert-threshold-types';
import { StudioAlert } from '@cxstudio/alerts/entities/studio-alert';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import ScorecardsApiService from '@cxstudio/projects/scorecards/scorecards-api-service';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { AnyGrouping } from '@cxstudio/reports/entities/any-grouping';
import { DateFilterMode, DateFilterModes, HistoricDateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { IDateRange } from '@cxstudio/reports/entities/date-period';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { PeriodOverPeriodMetricType } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric-type';
import { PeriodOverPeriodMetricService } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric.service';
import { AlertSettingsService } from '@app/modules/alert/alert-settings.service';
import { DateFiltersOptions } from '@cxstudio/reports/settings/date-filters.component';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { DatePeriodUtils } from '@cxstudio/reports/utils/analytic/date-period-utils.service';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { MetricAlertCondition } from '@cxstudio/alerts/entities/alert-trigger';

@Component({
	selector: 'alert-criteria-settings',
	templateUrl: './alert-criteria-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertCriteriaSettingsComponent implements OnInit {

	readonly PERIOD_OPTIONS = [ 'last7d', 'last30d', 'last90d', 'last180d',
		'lastd', 'lastw', 'lastm', 'year_to_date', 'quarter_to_date',
		'month_to_date', 'week_to_date', 'custom'];

	readonly COMPARE_PERIOD_OPTIONS = ['previousPeriod', 'sameLastYear', 'sameLastQuarter',
		'sameLastMonth', 'sameLastWeek',  'last365d', 'previous12m', 'lastq', 'lasty', 'currentw',
		'currentm', 'currentq', 'currenty'].concat(this.PERIOD_OPTIONS);


	@Input() alert: StudioAlert;
	@Input() project: IProjectSelection;

	loading: Promise<unknown>;
	studioMetrics: Metric[];
	dateOptions: DateFiltersOptions;
	projectTimezone: string;

	calculationOptions: INode[];

	thresholdTypeOptions: UIOption<AlertThresholdTypes>[];
	calculationError: boolean;

	groupingEnabled: boolean;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private alertTriggerCriteriaOptionsService: AlertTriggerCriteriaOptionsService,
		private dialogService: CxDialogService,
		private alertSettingsService: AlertSettingsService,
		@Inject('periodOverPeriodMetricService') private periodOverPeriodMetricService: PeriodOverPeriodMetricService,
		@Inject('scorecardsApiService') private scorecardsApiService: ScorecardsApiService,
		@Inject('optionsBuilderProvider') private optionsBuilderProvider: OptionsBuilderProvider,
		@Inject('metricConstants') private metricConstants: MetricConstants,
		@Inject('datePeriodUtils') private datePeriodUtils: DatePeriodUtils,
	) { }

	ngOnInit(): void {
		this.thresholdTypeOptions = [
			{value: AlertThresholdTypes.PERCENT, displayName: '%', htmlId: 'percent-threshold'},
			{value: AlertThresholdTypes.DELTA, displayName: 'Δ', htmlId: 'delta-threshold'},
		];
		this.updateCriteriaOptions();
		this.dateOptions = {
			hideMissDate: true,
			dateFilters: [],
		};
		this.groupingEnabled = !!this.alert.trigger.grouping;
		this.loading = this.alertSettingsService.getAlertSettings(this.project).then((result) => {
			this.calculationOptions = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
				.withStandardMetrics(this.metricConstants.getStandardCalculations())
				.withPredefinedMetrics(result.predefinedMetrics)
				.withMetrics(result.metrics, this.project.projectId)
				.withAttributes(result.attributes, MetricFilters.CALCULATION)
				.withScorecardMetrics(result.scorecardMetrics)
				.build();
			this.studioMetrics = ObjectUtils.copy(result.metrics);

			if (this.alert.trigger.grouping && !this.alert.trigger.sortBy) {
				this.updateSortBy(this.alert.trigger.grouping.sortBy);
			}

			this.dateOptions.dateFilters = this.datePeriodUtils.processDateFilters(result.dateFilters);

			const firstCondition = this.alert.trigger.conditions[0];
			if (!firstCondition.calculation) {
				let sentimentMetric = SearchableHierarchyUtils.findMetricNameInHierarchy(this.calculationOptions, 'sentiment');
				firstCondition.calculation = sentimentMetric || this.metricConstants.get().VOLUME;
			}
			this.ref.markForCheck();
		});
	}

	getCriteriaOptions(condition: MetricAlertCondition): UIOption<AlertTriggerCriteria>[] {
		const criteriaOptions = this.alertTriggerCriteriaOptionsService.getBasicOptions();
		if (this.isAdditionalPoPMetricSupported(condition, PeriodOverPeriodMetricType.SIGNIFICANCE)) {
			let significantChangeOption = this.alertTriggerCriteriaOptionsService.getOption(AlertTriggerCriteria.SIGNIFICANT_CHANGE);
			criteriaOptions.push(significantChangeOption);
		}
		return criteriaOptions;
	}

	updateCriteriaOptions(): void {
		this.alert.trigger.conditions.forEach(condition => {
			if (this.isSignificanceSelected(condition)
					&& !this.isAdditionalPoPMetricSupported(condition, PeriodOverPeriodMetricType.SIGNIFICANCE)) {
				condition.criteria = AlertTriggerCriteria.LESS_THAN_OR_EQUAL;
			}
			this.updateThresholdType(condition);
		});
	}

	private isAdditionalPoPMetricSupported(condition: MetricAlertCondition, additionalPoPMetricType: PeriodOverPeriodMetricType): boolean {
		return condition.calculation
			&& this.periodOverPeriodMetricService.getPeriodOverPeriodMetricByType(additionalPoPMetricType)
				?.isSupported(condition.calculation, this.studioMetrics);
	}

	private isScorecardCalculation(condition: MetricAlertCondition): boolean {
		return ScorecardUtils.isScorecardAttribute(condition.calculation?.name);
	}

	isThresholdLocked(condition: MetricAlertCondition): boolean {
		return this.isScorecardCalculation(condition)
			&& [AlertTriggerCriteria.LESS_THAN_OR_EQUAL, AlertTriggerCriteria.GREATER_THAN_OR_EQUAL]
				.contains(condition.criteria);
	}

	getThresholdLockedMessage(condition: MetricAlertCondition): string {
		return this.isThresholdLocked(condition) ? this.locale.getString('alert.rubricThresholdTooltip') : '';
	}

	private updateScorecardThreshold(condition: MetricAlertCondition): Promise<void> {
		let scorecardId = ScorecardUtils.getScorecardId(condition.calculation.name);
		return PromiseUtils.wrap(this.scorecardsApiService.getScorecard(this.project.contentProviderId, this.project.accountId, scorecardId)
			.then(scorecardData => {
				condition.threshold = scorecardData.scorecard.threshold;
				this.ref.markForCheck();
			}));
	}

	setAlertPeriod(dateFilterMode: DateFilterMode, dateFilterRange: IDateRange) {
		this.alert.trigger.period = dateFilterMode;
		this.alert.trigger.periodRange = dateFilterRange;
	}

	setAlertComparisonPeriod(condition: MetricAlertCondition, dateFilterMode: DateFilterMode, dateFilterRange: IDateRange) {
		condition.comparisonPeriod = dateFilterMode;
		condition.comparisonPeriodRange = dateFilterRange;
	}

	requiresComparisonPeriod(condition: MetricAlertCondition): boolean {
		return [
			AlertTriggerCriteria.INCREASE_GREATER_OR_EQUAL,
			AlertTriggerCriteria.DECREASE_GREATER_OR_EQUAL,
			AlertTriggerCriteria.CHANGE_GREATER_OR_EQUAL,
			AlertTriggerCriteria.SIGNIFICANT_CHANGE
		].indexOf(condition.criteria) > -1;
	}

	isSignificanceSelected(condition: MetricAlertCondition): boolean {
		return condition.criteria === AlertTriggerCriteria.SIGNIFICANT_CHANGE;
	}

	updateThresholdType(condition: MetricAlertCondition): void {
		if (this.isSignificanceSelected(condition)) {
			condition.thresholdType = AlertThresholdTypes.SIGNIFICANCE;
		} else if (condition.thresholdType === AlertThresholdTypes.SIGNIFICANCE) {
			condition.thresholdType = AlertThresholdTypes.DELTA;
		} else if (condition.criteria === AlertTriggerCriteria.LESS_THAN_OR_EQUAL
				|| condition.criteria === AlertTriggerCriteria.GREATER_THAN_OR_EQUAL) {
			condition.thresholdType = AlertThresholdTypes.DELTA;
			if (this.isScorecardCalculation(condition)) {
				this.loading = this.updateScorecardThreshold(condition);
			}
		}
		this.calculationError = this.validateThresholdType();
	}

	private validateThresholdType(): boolean  {
		if (!this.alert?.trigger?.conditions) {
			return false;
		}
		const allValid = _.all(this.alert.trigger.conditions, condition => {
			let type = this.getAdditionalPoPMetricType(condition);
			return this.isAdditionalPoPMetricSupported(condition, type);
		});
		return !allValid;
	}

	private getAdditionalPoPMetricType(condition: MetricAlertCondition): PeriodOverPeriodMetricType {
		let thresholdType = condition.thresholdType;
		if (thresholdType === AlertThresholdTypes.PERCENT) {
			return PeriodOverPeriodMetricType.PERCENT_CHANGE;
		} else if (thresholdType === AlertThresholdTypes.SIGNIFICANCE) {
			return PeriodOverPeriodMetricType.SIGNIFICANCE;
		}
		return PeriodOverPeriodMetricType.DELTA;
	}

	periodOptionsFilter = (filter): boolean => {
		return this.PERIOD_OPTIONS.indexOf(filter.value) >= 0
			|| DateFilterModes.isCustomDateRange(filter.value);
	};

	compareOptionsFilter = (filter): boolean => {
		return (this.COMPARE_PERIOD_OPTIONS.indexOf(filter.value) >= 0)
			|| DateFilterModes.isCustomDateRange(filter.value);
	};

	onGroupingChange(grouping: ReportGrouping): void {
		if (this.alert.trigger.sortBy && grouping?.sortBy !== this.alert.trigger.sortBy?.name) {
			this.updateSortBy(grouping.sortBy);
		}
	}

	onGroupingClear(): void {
		this.alert.trigger.grouping = null;
		this.updateSortBy();
	}

	private updateSortBy(name?: string): void {
		if (name) {
			this.alert.trigger.sortBy = SearchableHierarchyUtils.findMetricInHierarchy(this.calculationOptions, {name});
		} else {
			delete this.alert.trigger.sortBy;
		}
	}

	setCalculation(condition: MetricAlertCondition, node: ReportCalculation): void {
		condition.calculation = node;
		this.updateCriteriaOptions();
	}

	openGroupingProperties(propertiesTemplate: TemplateRef<unknown>): void {
		const header = `${this.alert.trigger.grouping.displayName}: ${this.locale.getString('dashboard.settingsTitle')}`;

		this.dialogService.openCustomSaveDialog<ReportGrouping>(header, propertiesTemplate).then(grouping => {
			this.alert.trigger.grouping = grouping;
			this.ref.markForCheck();
		}, _.noop);
	}

	isSentenceLevelGrouping(): boolean {
		return AnalyticMetricTypes.isSentenceLevelGrouping(this.alert.trigger.grouping as AnyGrouping);
	}

	getSelectedCalculations(): ReportCalculation[] {
		return this.alert.trigger.conditions.map(condition => condition.calculation).filter(calculation => !!calculation);
	}

	removeCondition(index: number): void {
		this.alert.trigger.conditions.removeAt(index);
	}

	addCondition(): void {
		this.alert.trigger.conditions.push({
			calculation: this.metricConstants.get().VOLUME,
			criteria: AlertTriggerCriteria.GREATER_THAN_OR_EQUAL,
			threshold: 1,
			thresholdType: AlertThresholdTypes.DELTA,
			comparisonPeriod: HistoricDateFilterMode.PREVIOUS_PERIOD,
		});
	}

}
