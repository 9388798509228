import { DateUtilsService } from '@app/modules/utils/dates/date-utils.service';
import * as _ from 'underscore';
import { User } from './entities/user';
import { UserQueryType } from '@cxstudio/query/user-query-type.enum';
import { Security } from '@cxstudio/auth/security-service';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { LicenseApiService } from '@app/modules/user-administration/services/license-api.service';
import { FilterQueryField } from '@cxstudio/query/filter-query-field.enum';
import Group from '../groups/Group';
import { UserSelectionMethod } from '@cxstudio/user-administration/users/entities/user-selection-method.enum';
import { UserBulkActionMode } from '@cxstudio/user-administration/users/entities/user-bulk-action-mode';
import { LicenseType } from '@cxstudio/common/license-types';
import { UserManagementTabMode} from '@cxstudio/user-administration/users/entities/user-management-tab-mode';
import { UserTabLoadMode } from '@cxstudio/user-administration/users/entities/user-tab-load-mode';
import { IUserSearchModel } from '@app/modules/user-administration/search-panel/user-search-panel.component';
import { ExportService } from '@cxstudio/services/export-service.service';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { MasterAccountDefaultPermissions } from '@app/modules/account-administration/license-permissions/master-account-default-permissions.class';
import { UserManageApi } from '@app/modules/user-administration/editor/user-manage-api.service';
import { MasterAccountListItem, UserApplicationPermissions, MasterAccountUser, UserEditData } from '@app/modules/user-administration/editor/user-edit-data';
import { UserApiService } from '@cxstudio/services/data-services/user-api-service';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { UserCreateWizardInput } from '@app/modules/user-administration/user-create/user-create-wizard.component';
import { LicenseService } from '@app/modules/user-administration/license.service';
import { IEditUserModalInput } from '@app/modules/user-administration/edit-user-modal/edit-user-modal.component';
import { PasswordPolicy } from '@app/modules/account-administration/password-policy/entities/password-policy';
import { GeoLocationApiService } from '@app/modules/geolocation/geolocation.api.service';
import { PermissionsService } from '@app/modules/user-administration/permissions/permissions.service';
import { UserQueryParams, UserColumnFilter } from '@app/modules/user-bulk/user-query-params';
import { MasterAccountContractInfo } from '@cxstudio/master-accounts/contracts/master-account-contract-info';
import { PromiseUtils } from '@app/util/promise-utils';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { InternalUser } from './entities/internal-user';
import { SecurityApiService } from '@cxstudio/services/data-services/security-api.service';
import { UsersGroupsApiService } from '@cxstudio/services/data-services/users-groups-api.service';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';
import { MAPropertiesService } from '@cxstudio/master-accounts/ma-properties-service.service';

export interface IUserColumn {
	displayName?: string;
	userQueryType?: UserQueryType;
	filterText?: string;
	filterField?: FilterQueryField;
	filterDisplayText?: string;
	ignore?: boolean;
}

export class UsersComponent implements ng.IComponentController {

	promises = {
		loadingUsers: null,
		loadingUser: null,
		loadingLimitsData: null,
		loadingLicenseTypes: null,
		loadingPermissions: null,
	};

	userColumns: IUserColumn[];

	permissions: any[];
	masterAccountDefaultPermissions: MasterAccountDefaultPermissions;

	currentPage: number = 1;
	maxSize: number = 10;

	licensingEnabled: boolean;
	licenseInfo: MasterAccountContractInfo = {} as MasterAccountContractInfo;
	customerAdminsCount: number;
	availableLicenses = {};

	usersLoaded: boolean = false;
	refreshedUserUpload: boolean;

	searchModel: IUserSearchModel = {};
	processedSearchModel: IUserSearchModel = {};

	selection: {
		method: UserSelectionMethod;
		totalItems: number;
	} = {
			method: UserSelectionMethod.EXACT_SELECTION,
			totalItems: 0
		};
	selectedUsers: any[] = [];

	filteredUsers: User[];
	internalUserList: InternalUser[];
	hasSearchFilter: boolean;
	userFilterLabels: string;
	showCustomField: boolean;
	pagedUserDetails;
	licenseTypes: any[];
	showAccessExpiration: boolean;
	groups: Group[];
	adminOrgUsers: boolean;
	bulkActionMode: UserBulkActionMode;
	noBasicUsers: boolean = false;
	updateBulkLicenseAllowed: boolean = false;
	selectedLicenseMap = {};
	contextMenuUtils;
	totalUnfilteredUsersCount: number;
	showSelectedOnly: boolean;
	mode: UserManagementTabMode;

	ui: {
		showAddUser: () => boolean;
		showAddInternalUser: () => boolean;
		showBulkMenu: () => boolean;
		getBulkProcessOptions: () => UIOption<string>[];
		userSyncEnabled: boolean;
	};

	tableTrigger: boolean = false;

	private modalOpened = false;
	readonly ROOT_PATH = '/user-group-management';

	allowExportOfUsers: boolean = false;

	constructor(
		private $scope: ng.IScope,
		private $location: ng.ILocationService,
		private $uibModal,
		private $q: ng.IQService,
		private security: Security,
		private usersGroupsApiService: UsersGroupsApiService,
		private securityApiService: SecurityApiService,
		private userApiService: UserApiService,
		private userManageApi: UserManageApi,
		private masterAccountApiService: MasterAccountApiService,
		private maPropertiesService: MAPropertiesService,
		private exportService: ExportService,
		private cbDialogService: CBDialogService,
		private locale: ILocale,
		private $routeParams,
		private licenseApiService: LicenseApiService,
		private dateUtils: DateUtilsService,
		private UserContextMenuUtils,
		private contextMenuTree: ContextMenuTree,
		private licenseService: LicenseService,
		private geoLocationApiService: GeoLocationApiService,
		private permissionsService: PermissionsService,
		private downgradeDialogService: DowngradeDialogService,
		private betaFeaturesService: BetaFeaturesService,
		private redirectService: RedirectService,
	) {}

	$onInit = () => {
		this.mode = UserManagementTabMode.CUSTOMER_USERS_ONLY;
		this.$scope.$watch(() => this.$location.url(), this.handleQueryParams);
		this.$scope.$watchCollection(() => this.selectedUsers, this.verifySelectionMode);
		this.$scope.$on('reloadUserList', (event) => {
			this.reloadPagedUserList();
		});
		this.contextMenuUtils = new this.UserContextMenuUtils(this);
		this.refreshedUserUpload = this.betaFeaturesService.isFeatureEnabled(BetaFeature.REFRESHED_USER_UPLOAD);
		let options: UIOption<string>[] = [];
		this.allowExportOfUsers = this.maPropertiesService.isFeatureEnabled(
			MasterAccountProperty.EXPORT_USERS_AND_GROUPS
		);

		if (this.showBulkMenu()) {
			options.push({
				displayName: this.locale.getString('userAdministration.uploadUsers'),
				tooltip: this.security.isUserSyncingEnabled() ?
					this.locale.getString('userAdministration.addUsersDisabled') :
					this.locale.getString('userAdministration.uploadUsers'),
				value: 'bulk-upload',
				disabled: this.security.isUserSyncingEnabled()
			});

			if (!this.security.isUserSyncingEnabled()) {
				// if user sync is enabled, we just need a single option so we can show the disabled button, no point in adding multiple opts
				options.push({
					displayName: this.locale.getString('userAdministration.bulkUpdateUsers'),
					tooltip: this.security.isUserSyncingEnabled() ?
						this.locale.getString('userAdministration.addUsersDisabled') :
						this.locale.getString('userAdministration.bulkUpdateUsers'),
					value: 'bulk-update',
				});
			}
		}

		this.ui = {
			showAddUser: () => (
				!this.adminOrgUsers
				&& this.security.has('manage_users')
			),
			showAddInternalUser: () => (
				this.adminOrgUsers
				&& (this.security.isAccountOwner() || this.security.isSysAdmin())
			),
			userSyncEnabled: this.security.isUserSyncingEnabled(),
			showBulkMenu: () => this.showBulkMenu(),
			getBulkProcessOptions: (): UIOption<string>[] => options,
		};

		this.initialize(this.adminOrgUsers);
	};

	showBulkMenu(): boolean {
		return (
			!this.adminOrgUsers
			&& (this.security.isAccountOwner() || this.security.isCustomerAdmin())
			&& this.security.has('manage_groups')
		);
	}

	onBulkMenuClick(menu: UIOption<string>): void {
		if (menu.value === 'bulk-upload') {
			this.goToUserUpload();
		} else {
			this.goToUserBulkUpdate();
		}
	}

	handleQueryParams = () => {
		this.handleNotificationDialogs();
	};

	handleNotificationDialogs = () => {
		const maIdParam = this.$location.search().maId;
		if (!maIdParam) {
			return;
		}
		const targetMasterAccountId = parseInt(this.$location.search().maId, 10);
		if (this.security.getMasterAccountId() !== targetMasterAccountId) {
			//do nothing if not same master account, maId switch is handled in user-management
			return;
		}

		if (this.$location.search().email) {
			this.openUserEditFromNotification();
		} else if (this.$location.search().taskId) {
			let taskId = this.$location.search().taskId;
			if (this.refreshedUserUpload && this.$location.search().mode === 'USER_UPDATE') {
				if (this.$location.search().action === 'MODIFY') {
					this.goToUserBulkUpdate(taskId);
				} else {
					this.goToUserUpload(taskId);
				}
			} else {
				this.downgradeDialogService.showAsyncTaskStatusModal(taskId);
				this.$location.url(this.$location.path());
			}
		}
	};

	openUserEditFromNotification = () => {
		this.promises.loadingUser = this.userManageApi.getMasterAccountUser(this.$location.search().email).then(
			(fullUser) => {
				this.selectGroupsForUser(fullUser.user, _.filter(this.groups, (group: Group) => group.type === 'SIMPLE'));
				this.editUser(fullUser);
				this.$location.search({maId: null, email: null});
			});
	};

	createQueryParameters = (): UserQueryParams => {
		//get filters from this.columns
		let userColumnFilters = _.chain(this.userColumns)
			.filter(column => !isEmpty(column.filterText))
			.map(column => _.pick(column, ['filterText', 'filterField', 'filterDisplayText']) as UserColumnFilter)
			.value();

		let properties: UserQueryParams = {};
		this.populatePaginationProperties(properties);

		let filterText, filterDisplayText;
		let searchModel = this.processedSearchModel;
		switch (searchModel.userQueryType) {
			case UserQueryType.CUSTOM: {
				let field = searchModel.userQueryTypeSelection.property;
				filterText = searchModel[field];
				break;
			}
			case UserQueryType.DATE: {
				filterText = this.dateUtils.formatDateRangeQuery(searchModel.date);
				filterDisplayText = this.dateUtils.formatDateRange(searchModel.date);
				break;
			}
			default:
				filterText = searchModel.text;
				break;
		}
		let filterField = searchModel.userQueryBy;
		properties.queryFilters = isEmpty(filterText) ? [] : [{filterText, filterField, filterDisplayText}];
		if (!isEmpty(userColumnFilters)) {
			properties.queryFilters.pushAll(userColumnFilters);
		}
		properties.searchMode = this.mode;
		return properties;
	};

	private populatePaginationProperties = (properties: UserQueryParams): void => {
		properties.isPaginated = true;
		properties.pageSize = this.maxSize;
		properties.currentPage = this.currentPage;
		properties.withTotal = true;
	};

	onSearchChange = (userQuery: IUserSearchModel): void => {
		this.searchModel = userQuery;
		this.searchUsers();
	};

	searchUsers = (leaveSelectedUntouched: boolean = false) => {
		this.processUserFiltersText();
		this.reloadPagedUserList(UserTabLoadMode.USER_LIST_ONLY);

		if (!leaveSelectedUntouched)
			this.showSelectedOnly = false;
	};

	reloadPagedUserList = (loadMode = UserTabLoadMode.ALL_DATA) => {
		this.loadUsers();
		if (loadMode === UserTabLoadMode.ALL_DATA) {
			if (this.mode === UserManagementTabMode.ADMIN_ORG_USERS_ONLY) {
				this.loadAvailableInternalUsers();
			} else {
				this.loadLimitsData();
			}
		}
	};

	loadUsers = () => {
		this.selection.method = UserSelectionMethod.EXACT_SELECTION;

		let properties = this.createQueryParameters();
		this.promises.loadingUsers = this.usersGroupsApiService
			.getMasterAccountUsersPaged(properties)
			.then((result) => {
				this.selection.totalItems = result.totalCount;
				this.totalUnfilteredUsersCount = this.totalUnfilteredUsersCount || this.selection.totalItems;
				this.filteredUsers = result.data;
				if (isEmpty(properties.queryFilters) && this.mode === UserManagementTabMode.ADMIN_ORG_USERS_ONLY) {
					this.checkRouteForExtendAccess();
				}
				this.pagedUserDetails = result.data;
				this.tableTrigger = !this.tableTrigger;
				this.usersLoaded = true;
			});
	};

	getSelectedUserCount = (): number => {
		return this.isMatchAllMode() ? this.selection.totalItems : this.selectedUsers.length;
	};

	isMatchAllMode = (): boolean => {
		return UserSelectionMethod.ALL_MATCHING_FILTERS === this.selection.method;
	};

	loadAvailableInternalUsers = () => {
		this.promises.loadingUsers = this.usersGroupsApiService.getAvailableInternalUsersForMasterAccount().then((users) => {
			this.internalUserList = users;
		});
	};

	loadLimitsData = () => {
		let loadContracts = this.masterAccountApiService.getMasterAccountContractInfo().then((result) => {
			if (result && result.data) {
				let data = result.data;
				this.updateLicensingInfo(data);
			}
		});
		let loadCustomerAdmins = PromiseUtils.old(this.userManageApi.getCustomerAdminsCount()
			.then(count => this.customerAdminsCount = count));
		this.promises.loadingLimitsData = this.$q.all([loadContracts, loadCustomerAdmins]);
	};

	exportUserData = () => {
		let queryParams = this.createQueryParameters();
		this.exportService.exportUserDataReport(queryParams, true).then(() => {
			this.cbDialogService.notify(this.locale.getString('administration.exportUserData'),
				this.locale.getString('administration.exportUserDataMessage'));
		});
	};

	updateLicensingInfo = (licenseData: MasterAccountContractInfo) => {
		this.licensingEnabled = this.isLicensingEnabled(licenseData);
		this.licenseInfo = licenseData;
	};

	private isLicensingEnabled = (data: MasterAccountContractInfo) => Boolean(data.licensingEnabled);

	pageChanged = () => {
		this.loadUsers();
	};

	initialize = (adminOrgUsers?, loadMode?) => {
		this.mode = this.security.getCurrentMasterAccount().adminOrg
			? UserManagementTabMode.ALL_USERS
			: (adminOrgUsers
				? UserManagementTabMode.ADMIN_ORG_USERS_ONLY
				: UserManagementTabMode.CUSTOMER_USERS_ONLY);

		this.promises.loadingLicenseTypes = this.licenseApiService.getLicenseTypes().then((result) => {
			this.licenseTypes = result;
		});
		this.showAccessExpiration = adminOrgUsers;
		this.showCustomField = this.isShowCustomField();
		this.userColumns = this.getUserColumns(this.showAccessExpiration, this.showCustomField);
		this.reloadPagedUserList(loadMode);
	};

	toggleShowFiltered = () => {
		this.clearColumnFilters();
	};

	checkCurrentPage = () => {
		if (this.filteredUsers) {
			for (let filteredUser of this.filteredUsers) {
				if (!_.find(this.selectedUsers, filteredUser)) {
					this.selectedUsers.push(filteredUser);
				}
			}
		}

		this.selection.method = UserSelectionMethod.EXACT_SELECTION;
	};

	checkAll = (): void => {
		this.selection.method = UserSelectionMethod.ALL_MATCHING_FILTERS;
	};

	uncheckAllUsers = () => {
		this.showSelectedOnly = false;
		this.selectedUsers.removeAll();
		this.selection.method = UserSelectionMethod.EXACT_SELECTION;
	};

	verifySelectionMode = () => {
		if (!this.isCurrentPageChecked()) {
			this.selection.method = UserSelectionMethod.EXACT_SELECTION;
		}
		this.updateBulkActionMode();
		this.updateBasicUsersSelectedFlag();
		this.updateBulkLicenseAllowedFlag();
		if (this.showSelectedOnly && !this.selectedUsers.length) {
			this.showSelectedOnly = false;
		}
	};

	buildUserSelectionQueryParameters = (): UserQueryParams => {
		let queryParameters: UserQueryParams = {
			method: this.selection.method,
			async: this.bulkActionMode === UserBulkActionMode.ASYNC
				|| UserSelectionMethod.ALL_MATCHING_FILTERS === this.selection.method
		};

		queryParameters = _.extend(queryParameters, this.createQueryParameters());
		return queryParameters;
	};

	updateBulkActionMode = () => {
		this.bulkActionMode = this.selectedUsers.length > this.maxSize
			? UserBulkActionMode.ASYNC
			: UserBulkActionMode.SYNC;
	};

	updateBasicUsersSelectedFlag = () => {
		let noBasicUsers = true;
		this.selectedUsers.forEach((user) => {
			if (user && !_.isUndefined(user.licenseTypeId)) {
				noBasicUsers = noBasicUsers
					&& user.licenseTypeId !== LicenseType.CX_STUDIO_BASIC;
			}
		});
		this.tableTrigger = !this.tableTrigger;
		this.noBasicUsers = noBasicUsers;
	};

	updateBulkLicenseAllowedFlag = () => {

		if (!this.selectedUsers?.length || !this.licenseTypes?.length) {
			return;
		}

		if (!this.licenseInfo.licensingEnabled) {
			this.updateBulkLicenseAllowed = true;
			return;
		}

		// get available license map
		this.availableLicenses = this.licenseService.getAvailableBulkLicensesMap(this.licenseTypes, this.licenseInfo);
		this.licenseTypes.forEach((licenseType) => {
			this.selectedLicenseMap[licenseType.licenseTypeId] = 0;
		});
		//get map of currently selected license
		this.selectedUsers.forEach((user: User) => {
			this.selectedLicenseMap[user.licenseTypeId] = this.selectedLicenseMap[user.licenseTypeId] + 1;
		});
		this.updateBulkLicenseAllowed = this.licenseService.isBulkLicenseSeatsAvailable(this.licenseTypes,
			this.availableLicenses, this.selectedLicenseMap, this.selectedUsers.length);
	};

	clickHamburgerMenu = (event: any) => {
		this.contextMenuTree.showObjectListMenu(event.$event, event.$user, this.contextMenuUtils.getContextMenu(event.$user), 'dashboards', 360);
	};

	isCurrentPageChecked = (): boolean => {
		if (!this.filteredUsers) {
			return false;
		} else {
			for (let filteredUser of this.filteredUsers) {
				if (!_.find(this.selectedUsers, (user) => user.userEmail === filteredUser.userEmail)) {
					return false;
				}
			}

			return true;
		}
	};

	clearColumnFilters = () => {
		_.forEach(this.userColumns, (column: any) => {

			column.filterText = '';
		});
		const SKIP_SELECTED_TOGGLE = true;
		this.searchUsers(SKIP_SELECTED_TOGGLE);
	};

	hasColumnFilters = () => {
		return _.some(this.userColumns, (column: any) => !isEmpty(column.filterText));
	};

	editUserFunction = (user, indexTab?: number) => {
		let deferred = this.$q.defer();
		this.promises.loadingUser = deferred.promise;
		this.userManageApi.getMasterAccountUser(user.userEmail).then((fullUserResponse: UserEditData) => {
			this.selectGroupsForUser(fullUserResponse.user, _.filter(this.groups, (group: Group) => group.type === 'SIMPLE'));
			deferred.resolve();
			this.editUser(fullUserResponse, indexTab);
		});
	};

	private selectGroupsForUser = (user: MasterAccountUser, groups: Group[]) => {
		let userGroups: string[] = user.groupNames;
		user.groups = _.map(groups, (group: any) => {
			let userGroup = angular.copy(group);
			userGroup.selected = userGroups.contains(group.groupName);
			userGroup.displayName = group.groupName;
			return userGroup;
		});
	};

	addUser = (data?: UserEditData) => {
		if (this.modalOpened) {
			return;
		}

		let permissions = this.permissionsService.filterProfanityPermission(this.permissions);

		data = data || {
			user: {
				email: '',
				defaultMasterAccountId: this.security.getMasterAccountId(),
				permissions: {
					application: {},
					own: []
				},
				groups: [],
				groupNames: []
			} as MasterAccountUser,
			linkedMasterAccounts: [
				this.getCurrentMasterAccount()
			],
			linkedAccounts: {}
		};

		this.selectGroupsForUser(data.user, _.filter(this.groups, (group: Group) => group.type === 'SIMPLE'));

		let userCreatePromises: Array<ng.IPromise<any>> = [ this.userApiService.getAllowedDomains() ];

		let requestPasswordPolicy: boolean = !!data.user.defaultMasterAccountId;
		if (requestPasswordPolicy) {
			userCreatePromises.push(this.securityApiService.getPasswordPolicy(data.user.defaultMasterAccountId));
		}
		userCreatePromises.push(this.promises.loadingPermissions);

		this.$q.all(userCreatePromises).then(userCreateResponses => {
			let resolve: UserCreateWizardInput = {
				userData: data,
				permissionItems: permissions,
				masterAccountDefaultPermissions: this.masterAccountDefaultPermissions,
				passwordPolicy: requestPasswordPolicy ? userCreateResponses[1] : { data: {} },
				allowedDomains: userCreateResponses[0],
				licenseTypes: this.licenseTypes,
				licenseInfo: this.licenseInfo
			};

			let userCreateModal = this.downgradeDialogService.openUserCreateDialog(resolve);
			// do not use then(this.reloadPagedUserList) - in this case default value for params doens't work
			userCreateModal.result.then(() => this.reloadPagedUserList()).catch(() => {});
		});
	};

	duplicateUser = (user: User): void => {
		this.promises.loadingUser = this.userManageApi.getMasterAccountUser(user.userEmail).then(
			(fullUser) => {
				const sourceUser = fullUser.user;
				let data: UserEditData = {
					user: {
						defaultMasterAccountId: this.security.getMasterAccountId(),
						licenseTypeId: sourceUser.licenseTypeId,
						customField: sourceUser.customField,
						countryCode: sourceUser.countryCode,
						permissions: sourceUser.permissions,
						groupNames: sourceUser.groupNames
					} as MasterAccountUser,
					linkedMasterAccounts: [
						this.getCurrentMasterAccount()
					],
					linkedAccounts: fullUser.linkedAccounts,
					sourceUserId: sourceUser.id
				};
				this.addUser(data);
			});
	};

	private isAdmin = (applicationPermissions: UserApplicationPermissions): boolean => {
		return applicationPermissions.systemAdmin || applicationPermissions.liteAdmin;
	};

	private getCurrentMasterAccount = (): MasterAccountListItem => {
		let current = this.security.getCurrentMasterAccount();
		return {
			accountId: current.accountId,
			accountName: current.accountName,
			isAdminOrg: current.adminOrg,
			allowPasswords: false, //not used in user create wizard
			authByUniqueId: this.security.isAuthByUniqueId(),
			qualtricsIntegrationEnabled: this.security.isQualtricsIntegrationEnabled()
		};
	};

	editUser = (data: UserEditData, goToTab?: number) => {
		if (this.modalOpened) {
			return;
		}

		let permissions = this.permissionsService.filterProfanityPermission(this.permissions);
		if (this.isAdmin(data.user.permissions.application)) {
			data.linkedMasterAccounts = _.filter(data.linkedMasterAccounts, (ma) => ma.isAdminOrg);
		}

		if (!data.user.defaultMasterAccountId && !_.isEmpty(data.linkedMasterAccounts)) {
			data.user.defaultMasterAccountId = data.linkedMasterAccounts[0].accountId;
		}

		let locationsCall = this.geoLocationApiService.getGeoLocations();
		let editModalInputs = {
			userData: data,
			permissionItems: permissions,
			licenseTypes: this.licenseTypes,
			licenseInfo: this.licenseInfo,
			goToTab
		} as IEditUserModalInput;

		let passwordPolicyCall;
		if (data.user.defaultMasterAccountId) {
			passwordPolicyCall = this.securityApiService.getPasswordPolicy(data.user.defaultMasterAccountId);
		} else {
			passwordPolicyCall = this.$q.when({ data: {} as PasswordPolicy });
		}

		this.$q.all([passwordPolicyCall, locationsCall]).then((resp: any[]) => {
			editModalInputs.passwordPolicy = resp[0];
			editModalInputs.availableCountries = _.map((resp[1] || []), location => location.country);
			let userEditModal = this.downgradeDialogService.openUserEditDialog(editModalInputs);
			this.modalOpened = true;

			userEditModal.result.then((linkingInfo) => {
				this.modalOpened = false;

				if (_.isUndefined(linkingInfo)) {
					this.reloadPagedUserList();
					return;
				}

				let options = {
					showDialog: true
				};

				this.userApiService.syncUserInContentProviders(linkingInfo, options).then(() => {
					this.reloadPagedUserList();
				});
			}, () => {
				this.modalOpened = false;
			});
		});
	};

	private redirectToHome = () => {
		this.$location.path(this.ROOT_PATH);
		return;
	};

	addInternalUser = (userId, isExtend) => {
		let internalUserModal = this.$uibModal.open({
			backdrop: 'static',
			component: 'addInternalUserWizard',
			resolve: {
				userId: () => userId,
				internalUserList: () => this.internalUserList,
				isExtend: () => isExtend,
				currentMaName: () => this.security.getCurrentMasterAccount().accountName
			}
		});

		internalUserModal.result.then(() => {
			this.reloadPagedUserList();
			this.redirectToHome();
		}, this.redirectToHome);
	};

	addBulkUsers = () => {
		this.$uibModal.open({
			windowClass: 'modal-md',
			backdrop: 'static',
			component: 'uploadBulkUsersWizard',
			resolve: {}
		});
	};

	goToUserUpload = (taskId?: string): void => {
		this.redirectService.goToUserUpload(taskId);
	};

	goToUserBulkUpdate = (taskId?: string): void => {
		this.redirectService.goToUserBulkUpdate(taskId);
	};

	// before directing the user to the /users page, make sure they havent moved on :)
	private hasLeftPage = () => {
		let pageRegex = new RegExp('^' + this.ROOT_PATH);
		return !pageRegex.test(this.$location.path());
	};

	private checkRouteForExtendAccess = () => {
		if (this.hasLeftPage()) return;

		if (this.$routeParams.action === 'extendAccess' && this.$routeParams.maId && this.$routeParams.userId) {
			if (this.security.has('account_owner')) {
				this.addInternalUser(this.$routeParams.userId, true);
			}
		} else {
			this.redirectToHome();
		}
	};

	private isShowCustomField = (): boolean => {
		let customField = this.security.getCurrentMasterAccount().customField;
		return !!customField && customField.length > 0;
	};

	getCustomFieldName = () => this.security.getCurrentMasterAccount().customField;

	getUserColumns = (showAccessExpiration, showCustomField): IUserColumn[] => {
		let userColumns = [{
			displayName: this.locale.getString('common.actions')
		}, {
			displayName: this.locale.getString('administration.firstName'),
			userQueryType: UserQueryType.TEXT,
			filterText: '',
			filterField: FilterQueryField.FIRST_NAME
		}, {
			displayName: this.locale.getString('administration.lastName'),
			userQueryType: UserQueryType.TEXT,
			filterText: '',
			filterField: FilterQueryField.LAST_NAME
		}, {
			displayName: this.locale.getString('administration.userEmail'),
			userQueryType: UserQueryType.TEXT,
			filterText: '',
			filterField: FilterQueryField.USER_EMAIL
		}, {
			displayName: this.locale.getString('administration.lastLogin'),
			userQueryType: UserQueryType.DATE,
			filterText: '',
			filterField: FilterQueryField.LOGIN_DATE
		}, {
			displayName: this.locale.getString('administration.accessExpiration'),
			ignore: !showAccessExpiration
		}, {
			displayName: '',
			ignore: !showAccessExpiration
		}, {
			displayName: this.locale.getString('administration.licenseType'),
			userQueryType: UserQueryType.SELECT,
			filterText: '',
			filterField: FilterQueryField.LICENSE
		}, {
			displayName: this.getCustomFieldName(),
			userQueryType: UserQueryType.TEXT,
			filterText: '',
			filterField: FilterQueryField.CUSTOM,
			ignore: !showCustomField
		}];

		return _.filter(userColumns, (column: IUserColumn) => !column.ignore);
	};

	processUserFiltersText = () => {
		let userColumnFilters = _.chain(this.userColumns)
			.filter((column) => !isEmpty(column.filterText)
			).map((column) => {
				let filterDisplayText = column.filterText;
				if (column.filterField === FilterQueryField.LICENSE)
					filterDisplayText = this.getLicenseTypeName(column.filterText);
				if (column.filterField === FilterQueryField.LOGIN_DATE)
					filterDisplayText = column.filterDisplayText;
				return column.displayName + ': ' + filterDisplayText;
			}).value();

		let searchFilterText;
		switch (this.searchModel.userQueryType) {
			case 'custom':
				searchFilterText = this.getLicenseTypeName(this.searchModel.license);
				break;
			case 'date':
				searchFilterText = this.dateUtils.formatDateRange(this.searchModel.date);
				break;
			default:
				searchFilterText = this.searchModel.text;
				break;
		}
		this.hasSearchFilter = !!searchFilterText;
		searchFilterText = `${this.searchModel.userQueryByName}: ${searchFilterText}`;

		this.processedSearchModel = angular.copy(this.searchModel);
		this.userFilterLabels = this.hasSearchFilter ?
			[searchFilterText].concat(userColumnFilters).join(' / ') : userColumnFilters.join(' / ');
	};

	getLicenseTypeName = (licenseTypeId) => {
		if (licenseTypeId) {
			return _.findWhere(this.licenseTypes, {licenseTypeId}).licenseTypeName;
		}
		return '';
	};

	showFilterBar = () => this.hasSearchFilter || this.hasColumnFilters();

	searchOptionsFilter = (option) => {
		return !_.contains([
			FilterQueryField.STATUS,
			FilterQueryField.TYPE,
			FilterQueryField.TAGS,
			FilterQueryField.DEFAULT_MA,
			FilterQueryField.XM_ACCOUNT_ID
		], option.value);
	};

	toggleUserSelection(user): void {
		if (user.selected) {
			this.selectedUsers.push(user);
		} else {
			let idx = _.findIndex(this.selectedUsers, oneUser => oneUser.userId === user.userId);
			if (idx > -1) {
				this.selectedUsers.removeAt(idx);
			}
		}
	}
}

app.component('users', {
	bindings: {
		permissions: '<',
		masterAccountDefaultPermissions: '<?',
		groups: '<',
		reloadGroups: '<',
		promises: '<',
		adminOrgUsers: '<'
	},
	controller: UsersComponent,
	templateUrl: 'partials/user-administration/users/users.component.html'
});
