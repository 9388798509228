import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { LicenseLevel } from '@cxstudio/common/license-levels';
import { LicenseType } from '@cxstudio/common/license-types';
import { MasterAccountContractInfo } from '@cxstudio/master-accounts/contracts/master-account-contract-info';
import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';

interface LicenseMap {
	[key: string]: number;
}

@Injectable({
	providedIn: 'root'
})
export class LicenseService {
	readonly alwaysPresentedLicenseTypes = [
		LicenseType.ANALYZE, LicenseType.CX_STUDIO
	];

	constructor(
		private readonly locale: CxLocaleService
	) { }

	getAvailableLicenses(contractInfo: MasterAccountContractInfo, licenseId: LicenseType): number {
		let contract = this.getContractLicenses(contractInfo, licenseId);
		let used = this.getUsedLicenses(contractInfo, licenseId);
		let placesAvailable = contract - used;
		return placesAvailable < 0 ? 0 : placesAvailable;
	}

	getUsedLicenses(contractInfo: MasterAccountContractInfo, licenseId: LicenseType): number {
		return this.getLicenses(contractInfo, 'usedLicenses', licenseId);
	}

	getContractLicenses(contractInfo: MasterAccountContractInfo, licenseId: LicenseType): number {
		return this.getLicenses(contractInfo, 'contractLicenses', licenseId);
	}

	private getLicenses(contractInfo: MasterAccountContractInfo, field: string, licenseId: LicenseType): number {
		let count = contractInfo[field][licenseId];
		return count ? count : 0;
	}

	getAssignableLicenseTypes(licenseTypes: LicenseTypeItem[], contractInfo, mandatoryLicenseId?: number): LicenseTypeItem[] {
		if (!contractInfo.licensingEnabled) {
			return licenseTypes;
		}

		let mandatoryLicenses = [].concat(this.alwaysPresentedLicenseTypes);
		if (mandatoryLicenseId) {
			mandatoryLicenses.push(mandatoryLicenseId);
		}

		return _.filter(licenseTypes, (license) => {
			if (_.contains(mandatoryLicenses, license.licenseTypeId)) {
				return true;
			}
			let seats = this.getAvailableLicenses(contractInfo, license.licenseTypeId);
			return seats > 0;
		});
	}

	isCPLinkingAllowed(license: LicenseTypeItem): boolean {
		return license && license.licenseLevel !== LicenseLevel.VIEWER;
	}

	getCountLabel(licenseTypeId: LicenseType, licenseInfo): string {
		let placesAvailable = this.getAvailableLicenses(licenseInfo, licenseTypeId);

		return this.locale.getString('mAccount.availableLicenseSeats', {
			amount: placesAvailable
		});
	}

	isNeedToCountLicenses(licenseInfo: MasterAccountContractInfo, user, masterAccount): boolean {
		if (!licenseInfo || !licenseInfo.licensingEnabled) {
			return false;
		}

		if (!user) {
			return false;
		}

		if (user.defaultMasterAccountId !== masterAccount) {
			return false;
		}

		if (user.platformApiUser) {
			return false;
		}

		if (user.licenseTypeId === undefined) {
			return false;
		}

		return true;
	}

	licenseLimitExceeded(licenseInfo: MasterAccountContractInfo, licenseTypeId: LicenseType): boolean {
		let placesAvailable = this.getAvailableLicenses(
			licenseInfo, licenseTypeId);
		return placesAvailable <= 0;
	}

	getLicenseExceededMessage(licenseTypeId: LicenseType, licenseTypes): string {
		let license = _.findWhere(licenseTypes, { licenseTypeId });
		let licenseName = license ? license.licenseTypeName : '';
		return this.locale.getString('mAccount.licenseLimitExceeded', {
			license: licenseName
		});
	}

	getAvailableBulkLicensesMap(licenseTypes: LicenseTypeItem[], licenseInfo: MasterAccountContractInfo): LicenseMap {
		let availableLicenses = {};

		licenseTypes.forEach((licenseType) => {
			availableLicenses[licenseType.licenseTypeId]
				= this.getAvailableLicenses(licenseInfo, licenseType.licenseTypeId);
		});

		return availableLicenses;
	}

	isBulkLicenseSeatsAvailable(licenseTypes: LicenseTypeItem[], availableLicenseMap: LicenseMap,
		selectedLicenseMap: LicenseMap, numSelected: number): boolean {
		let bulkLicenseAllowed = false;
		licenseTypes.forEach((licenseType) => {
			bulkLicenseAllowed = bulkLicenseAllowed ||
				this.bulkAllowedAndSeatsAvailable(licenseType, availableLicenseMap, selectedLicenseMap, numSelected);
		});
		return bulkLicenseAllowed;
	}

	getAvailableBulkLicenseTypes(licenseTypes: LicenseTypeItem[], availableLicenseMap: LicenseMap,
		selectedLicenseMap: LicenseMap, numSelected: number): LicenseTypeItem[] {
		return _.filter(licenseTypes, (licenseType) => {
			//filter out license with no seats, and Report Consumer license type
			return this.bulkAllowedAndSeatsAvailable(licenseType, availableLicenseMap, selectedLicenseMap, numSelected);
		});
	}

	getAvailableBulkLicenseTypesNoContract(licenseTypes: LicenseTypeItem[]): LicenseTypeItem[] {
		return _.filter(licenseTypes,
			(licenseType) => licenseType.licenseTypeId !== LicenseType.CX_STUDIO_BASIC);
	}

	private bulkAllowedAndSeatsAvailable(licenseType: LicenseTypeItem, availableLicenseMap: LicenseMap,
		selectedLicenseMap: LicenseMap, numSelected: number): boolean {
		return licenseType.licenseTypeId !== LicenseType.CX_STUDIO_BASIC
			&& (availableLicenseMap[licenseType.licenseTypeId])
			>= (numSelected - selectedLicenseMap[licenseType.licenseTypeId]);
	}
}

app.service('licenseService', downgradeInjectable(LicenseService));
