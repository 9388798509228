import { SlickHeaderUtils } from '@app/modules/item-grid/slick-header-utils.class';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { DashboardFoldersState } from '@cxstudio/dashboards/dashboard-folders-state.service';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { GeneratedFolderType } from '@cxstudio/report-filters/generated-folder-type';
import { FieldComparer } from '@app/modules/object-list/utilities/field-comparer.class';
import { GridDefinitionFactory } from './grid-definition-factory.service';
import { GridTypes } from './grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';


/**
 * Directive for slick table
 */
app.directive('itemGrid', (
	dashboardFoldersState: DashboardFoldersState,		
	locale: ILocale,
	gridUtils: GridUtilsService,
	gridDefinitionFactory: GridDefinitionFactory
) => {
	return {
		restrict: 'E',
		template: '<div></div>',
		scope: {
			treeData: '<',
			gridType: '<',
			gridMode: '<',
			uiOptions: '<',
			gridOptions: '<',
			gridColumns: '<',
			nameField: '<',
			idField: '<',
			initWithGridColumns: '<',
			gridFilter: '<',
			gridFilterFunction: '<',
			gridFilterCriteria: '<',
			gridAutoTooltip: '<',
			gridAlwaysHeaderTooltip: '<',
			gridHeaderButton: '<',
			gridHeaderButtonFunction: '<',
			gridDataChange: '<',
			hideItems: '<',
			controller: '<',
			gridReinit: '<',
			gridChange: '<',
			scrollable: '<',
			pagingOptions: '<'
		},
		link: (scope, element, attrs) => {
			let NAME_FIELD = scope.nameField;
			let NAME_COLUMN_CLASS = 'IS_NAME_COLUMN';

			if (_.isUndefined(NAME_FIELD)) {
				NAME_FIELD = 'name';
			}

			let options = _.extend({
				editable: false,
				enableAddRow: false,
				enableCellNavigation: GridTypes.DASHBOARD === scope.gridType,
				forceFitColumns: true,
				enableColumnReorder: false,
				headerRowHeight: 50,
				rowHeight: 40,
				asyncEditorLoading: false,
				multiSelect: true,
				localization: {
					getSortByColumnPrompt: (columnName) => locale.getString('common.clickToSortByColumnName', { columnName }),
					getColumnSortedTitle: (columnName, isAscending) => {
						return isAscending ?
							locale.getString('common.tableSortedByColumnNameAscending', { columnName }) :
							locale.getString('common.tableSortedByColumnNameDescending', { columnName });
					}
				}
			}, scope.uiOptions);

			let gridType = scope.gridType;
			let gridMode = scope.gridMode;
			let gridOptions = scope.gridOptions;
			let gridFilterFunction = scope.gridFilterFunction;
			let gridFilterCriteria = scope.gridFilterCriteria;
			let gridAutoTooltip = scope.gridAutoTooltip || false;
			let gridAlwaysHeaderTooltip = scope.gridAlwaysHeaderTooltip || false;
			let gridHeaderButton = scope.gridHeaderButton || false;
			let initWithGridColumns = scope.initWithGridColumns || false;
			let gridHeaderButtonFunction = scope.gridHeaderButtonFunction;
			let dataView;
			let data = [];
			let grid;
			let getDataBasedRowClasses = [];
			let columns = [];
			let gridDefinition;
			let loading;

			setDefinition();

			// factory contains all of our tables
			function setDefinition(): void {
				gridDefinition = gridDefinitionFactory.getDefinition(gridType);
				initGrid();
			}

			function getFieldComparer(field: string, isAsc: boolean = false): (...args) => any {
				return FieldComparer.getComparer(field, isAsc, NAME_FIELD, gridType);
			}

			function initGrid(): void {
				loading = gridDefinition.init(gridMode, scope.controller || scope).then((cols) => {
					cols = cols.filter(column => !column.enabledIf || column.enabledIf());
					columns = cols;

					if (initWithGridColumns) {
						updateGridColumns(scope.$eval('gridColumns'));
					} else {
						let visibleColumns = columns.filter(column => !gridUtils.isColumnHidden(column, scope.gridColumns));
						grid.setColumns(visibleColumns);
					}
					grid.invalidate();

					// handle header clicks
					grid.onHeaderClick.subscribe((e, args) => {
						if (args.column.onClick)
							args.column.onClick(e, args);
					});

					// allow the data in a cell to manipulate the classes for the row
					_.each(columns, (col) => {
						if (col.rowClassFunction) {
							getDataBasedRowClasses.push(col.rowClassFunction);
						}
						if (col.isObjectNameColumn) {
							let allClasses = col.cssClass.split(' ').concat([NAME_COLUMN_CLASS, 'cursor-pointer', 'action-hover-text']);
							col.cssClass = _.uniq(allClasses).join(' ');
						}
					});

					// allow grid to set its own default sort column using defaultSortColumn
					let defaultSortColumns = columns.filter(col => !!col.defaultSortColumn);
					if (defaultSortColumns.length) {
						let sortColumn = defaultSortColumns[0];
						let ascDirection = sortColumn.defaultSortColumn === SortDirection.ASC;
						grid.setSortColumn(sortColumn.id, ascDirection);
						dataView.sort(getFieldComparer(sortColumn.field, ascDirection), ascDirection);
					} else if (!gridOptions || !gridOptions.disableInitialSort) {
						grid.setSortColumn(columns[0].id, true);
						dataView.sort(getFieldComparer(columns[0].field, true), true);
					} else {
						grid.setSortColumns([]);
					}

					grid.render();

					if (scope.scrollable) {
						$(element).addClass('scrollable');

						$(element).children('.slick-viewport').on('scroll', () => {
							let scrollTop = $(this).scrollTop(); // eslint-disable-line no-invalid-this
							if (scrollTop > 0) {
								$(element).addClass('scrolled');
							} else {
								$(element).removeClass('scrolled');
							}
						});
					}
				});
			}

			let searchString = '';
			let hideItems = true;
			function hasText(item): boolean {
				if (_.isFunction(gridFilterFunction)) {
					if (gridFilterFunction(item, searchString, gridFilterCriteria)) {
						return true;
					} else if (_.find(gridFilterCriteria, criteria => criteria.searchFilterValue === searchString)) {
						return false;
					}
				}
				if (item.labels && item.labels.length) {
					let labelsSearchKeyword = locale.getString('common.labelsSearchKeyword');
					if (searchString.toLowerCase() === labelsSearchKeyword.toLowerCase()) {
						return true;
					}
					if (_.find(item.labels, label => label.text.toLowerCase().indexOf(searchString.toLowerCase()) >= 0)) {
						return true;
					}
				}
				for (let colDefinition of columns) {
					if ((colDefinition.searchable) && item[colDefinition.field]?.toString().toLowerCase().indexOf(searchString.toLowerCase()) >= 0) {
						return true;
					}
				}

				return item[NAME_FIELD] && item[NAME_FIELD].toLowerCase().indexOf(searchString.toLowerCase()) >= 0;
			}

			function hasTextChildren(item): boolean {
				if (!item.children)
					return false;
				for (let child of item.children) {
					if (hasText(child))
						return true;
					if (hasTextChildren(child))
						return true;
				}
				return false;
			}

			// filter for both search and collapsing(don't show collapsed items)
			// we do not hide folders, which contain children with search text
			function searchFilter(item): boolean {

				if (searchString && !hasText(item) && !hasTextChildren(item)) {
					item.hiddenViaFilter = true;
					return false;
				}
				if (item.parent) {
					let parent = item.parent;
					while (parent) {
						if (parent._collapsed) {
							// item is hidden because of the collapsed folder, NOT because of filter
							item.hiddenViaFilter = false;
							return false;
						}
						parent = parent.parent;
					}
				}

				item.hiddenViaFilter = false;
				if (hideItems) {
					return !item.hide;
				}
				return true;
			}

			// initialize the model
			dataView = new Slick.Data.DataView();

			dataView.beginUpdate();
			scope.$watchCollection('treeData', (newData: any[]) => {

				if (!newData) return;

				newData.forEach((item) => {
					if (_.isUndefined(item[NAME_FIELD])) {
						item[NAME_FIELD] = item.name;
					}
				});

				data = newData;
				if (scope.gridDataChange) {
					scope.gridDataChange(grid);
				}

				if (scope.idField) {
					dataView.setItems(newData, scope.idField);
				} else {
					dataView.setItems(newData, 'id');
				}

				if (grid.getSortColumns().length > 0)
					dataView.reSort();
			});

			dataView.setFilter(searchFilter);
			dataView.endUpdate();

			dataView.getItemMetadata = function(row): {cssClasses: string} {
				let item = this.getItem(row); // eslint-disable-line no-invalid-this
				let type = item.type || '';
				let tags = (item.tags && item.tags.join(' ')) || '';
				let selected = item.selected ? 'selected invert' : '';
				let inFolder = (item.level > 0) ? 'in-folder' : '';
				let feedbackFolder = item.id === DashboardType.FEEDBACK_FOLDER ? 'feedback-folder' : '';
				let predefinedFolder = item.generatedFolderType === GeneratedFolderType.SYSTEM ? 'PREDEFINED' : '';
				let customPredefinedFolder = item.generatedFolderType === GeneratedFolderType.CUSTOM ? 'CUSTOM-PREDEFINED' : '';

				let classes = ['hover-parent', type, tags, selected, inFolder, feedbackFolder, predefinedFolder, customPredefinedFolder]
					.filter(cls => !!cls);
				classes = classes.concat(getDataBasedRowClasses.map(classFn => classFn(item)));

				return {
					cssClasses: classes.join(' ')
				};
			};

			// initialize the grid
			grid = new Slick.Grid(element, dataView, columns, options);
			scope.$on('$destroy', grid.destroy);
			if (gridAutoTooltip) {
				grid.registerPlugin(new Slick.AutoTooltips({
					enableForHeaderCells: false
				}));
			}

			if (gridAlwaysHeaderTooltip) {
				grid.onHeaderMouseEnter.subscribe((e, args) => {
					let column = args.column;
					let headerElement = $(e.target).closest('.slick-header-column');
					headerElement.attr('title', column.name);
				});
			}

			// handle click/press on tree open/close icon
			let handleFolderAction = (e, args) => {
				if (e.type === 'keydown' && e.keyCode !== 13) return;
				if ($(e.target).hasClass('br-folder') ||
					$(e.target).hasClass('br-folder-exact-name') ||
					($(e.target).hasClass(NAME_COLUMN_CLASS) && gridUtils.isTargetHasChild(e, 'br-folder'))) {
					let item = dataView.getItem(args.row);
					if (item) {
						if (!item._collapsed) {
							item._collapsed = true;
						} else {
							item._collapsed = false;
						}
						if (item.type === 'folder') {
							dashboardFoldersState.saveFolderState(item.id, item._collapsed);
						}
						dataView.updateItem(item.id, item);
					}
					e.stopImmediatePropagation();
					if (e.type === 'keydown') {
						setTimeout(() => {
							grid.getCellNode(args.row, args.cell).focus();
						});
					}
				}
			};

			grid.onClick.subscribe(handleFolderAction);
			grid.onKeyDown.subscribe(handleFolderAction);

			if (gridHeaderButton) {
				let headerButtonsPlugin = new Slick.Plugins.HeaderButtons();
				headerButtonsPlugin.onCommand.subscribe((e, args) => {
					let column = args.column;
					let button = args.button;
					let command = args.command;
					if (button.commandHandler) {
						button.commandHandler(command, button, column, grid);
					}
					if (_.isFunction(gridHeaderButtonFunction)) {
						gridHeaderButtonFunction(command, column);
					}
					grid.invalidate();
				});
				grid.registerPlugin(headerButtonsPlugin);
			}

			// handle mouse/keyboard events (click, over, out, keypress)
			let callback = (method) => {
				return (e, args) => {
					if (method === 'onKeyDown') {
						if (grid.getOptions().enableCellNavigation) {
							gridOptions[method](e, dataView.getItem(args.row), args);
						}
						return;
					}
					let cell = grid.getCellFromEvent(e);
					if (cell && !_.isUndefined(cell.row))
						gridOptions[method](e, dataView.getItem(cell.row), args);
				};
			};
			for (let prop in gridOptions) {
				// if property is function and grid supports it
				if ($.isFunction(gridOptions[prop]) && grid[prop]) {
					grid[prop].subscribe(callback(prop));
				}
			}

			// wire up model events to drive the grid
			// required to update grid on changes (add/remove/move/rename)
			dataView.onRowCountChanged.subscribe(() => {
				grid.updateRowCount();
				grid.render();
			});
			dataView.onRowsChanged.subscribe((e, args) => {
				grid.invalidateRows(args.rows);
				grid.resizeCanvas();
				grid.render();
			});

			scope.$on('focusGridCell', (event, row, cell) => {
				grid.getCellNode(row, cell).focus();
			});

			scope.$on('scrollToId', (event, id) => {
				let row = dataView.getRowById(id);
				if (row)
					grid.scrollRowIntoView(row, true);
			});

			scope.$watch('pagingOptions', (pageOpts) => {
				if (!!pageOpts) {
					dataView.setPagingOptions(pageOpts);
				}
			});

			// filtering
			scope.$watch('gridFilter', (filter: string) => {
				searchString = filter;
				dataView.refresh();
				updateVisibleList();
			});
			scope.$watch('hideItems', (newVal: boolean) => {
				hideItems = newVal;
				dataView.refresh();
				updateVisibleList();
			});

			function updateVisibleList(): void {
				scope.$emit('gridListUpdated', dataView);
			}

			scope.$watch(() => {
				return element[0].clientWidth;
			}, (value) => {
				if (value) {
					grid.resizeCanvas();
				}
			});
			// handle changes in tree
			// we explicitly update all affected items, because watchCollection doesn't cover all our cases
			scope.$watch('gridChange', (changedItems: any[]) => {
				if (!changedItems || !changedItems.length)
					return;
				dataView.beginUpdate();
				changedItems.forEach((changedItem) => {
					if (!changedItem)
						return;
					if (_.isUndefined(changedItem[NAME_FIELD])) {
						changedItem[NAME_FIELD] = changedItem.name;
					}
					let item = data[dataView.getIdxById(changedItem.id)];
					if (item)
						dataView.updateItem(changedItem.id, _.extend(item, changedItem));
				});

				if (grid.getSortColumns().length > 0)
					dataView.reSort();

				dataView.endUpdate();
			});

			scope.$watch('gridType', (newMode, oldMode) => {
				if (newMode !== oldMode) {
					gridType = newMode;
					setDefinition();
				}
			});

			scope.$watch('gridMode', (newMode, oldMode) => {
				if (newMode !== oldMode) {
					gridMode = newMode;
					initGrid();
				}
			});

			scope.$watch('gridReinit', (newVal, oldVal) => {
				if (newVal !== oldVal) {
					initGrid();
				}
			});

			grid.onSort.subscribe((e, args) => {
				// Folders are always on bottom for all fields, except "name"
				let field = args.sortCol.field;
				let sortAsc = args.sortAsc;
				if (gridOptions && _.isFunction(gridOptions.onSort)) {
					let sortdir = args.sortAsc ? SortDirection.ASC : SortDirection.DESC;
					let sortcol = args.sortCol.field;
					gridOptions.onSort(e, {sortField: sortcol, sortDir: sortdir });
				} else {
					let comparer;
					if (isBulkUpdate(gridType) && field !== NAME_FIELD) {
						comparer = FieldComparer.getUpdateValueFieldComparer(field, sortAsc);
					} else {
						comparer = FieldComparer.getComparer(field, sortAsc, NAME_FIELD, gridType);
					}

					// Delegate the sorting to DataView.
					// This will fire the change events and update the grid.
					dataView.sort(comparer, sortAsc);
					if (SlickHeaderUtils.pinSlickHeader()) {
						$('item-grid:visible')[0].scrollIntoView();
					}
				}

			});

			scope.$watch('gridColumns', (newState, oldState) => {
				if (newState === oldState) {
					return;
				}

				if (!_.isUndefined(loading)) {
					loading.then(() => {
						updateGridColumns(newState);
					});
				} else {
					updateGridColumns(newState);
				}
			}, true);

			function updateGridColumns(selectedColumns): void {
				let gridColumns = _.filter(columns, (item) => !gridUtils.isColumnHidden(item, selectedColumns));

				grid.setColumns(gridColumns);
			}

			function isColumnHidden(column, selectedColumns): boolean {
				if (!column.optional || !selectedColumns?.length) return false;

				const hiddenColumns = _.filter(selectedColumns, (item) => !item.selected);

				return _.findWhere(hiddenColumns, {name: column.persistedName || column.field});
			}

			function isBulkUpdate(updateGridType: GridTypes): boolean {
				return updateGridType === GridTypes.USERS_BULK_GROUPS
					|| updateGridType === GridTypes.USERS_BULK_PERMISSIONS
					|| updateGridType === GridTypes.USERS_BULK_DATA_ACCESS;
			}
		}
	};
});
