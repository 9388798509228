import { Input, Component, OnInit, Inject } from '@angular/core';
import { User } from '@cxstudio/user-administration/users/entities/user';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';
import { IFilterOption, IUserSearchModel } from '@app/modules/user-administration/search-panel/user-search-panel.component';
import { SystemAdminApiService } from '@cxstudio/services/data-services/system-admin-api.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { CxLocaleService } from '@app/core';
import { UserApiService } from '@cxstudio/services/data-services/user-api-service';
import { ExportService } from '@cxstudio/services/export-service.service';
import { UserRemoveModalService } from '@cxstudio/user-administration/users/removal/user-remove-modal.service';
import { UserRemoveModes } from '@cxstudio/user-administration/users/removal/user-remove-modes';
import { downgradeComponent } from '@angular/upgrade/static';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { PromiseUtils } from '@app/util/promise-utils';

export interface IExtendedUser extends User {
	id: number;
	linkedMasterAccounts;
	masterAccountData;
	systemAdmin: boolean;
	liteAdmin: boolean;
	customerAdminMasterAccounts: number[];
	ownedMasterAccounts: number[];
}

@Component({
	selector: 'admin-users',
	templateUrl: './admin-users.component.html'
})
export class AdminUsersComponent implements OnInit {

	@Input() masterAccounts: MasterAccount[];

	loadingUsers: Promise<any>;
	sortParams = {
		sortField: '',
		sortDirection: false
	};
	userQuery: IUserSearchModel = {};
	masterAccountsMap = {};
	pagedUserDetails: IExtendedUser[];
	userQueryBy: IFilterOption;
	processedUserQueryBy: IFilterOption;

	pagination: Pagination;

	fields = [{
		field: 'disabled',
		name: '#'
	}, {
		field: 'firstName',
		name: this.locale.getString('administration.firstName')
	}, {
		field: 'lastName',
		name: this.locale.getString('administration.lastName')
	}, {
		field: 'userEmail',
		name: this.locale.getString('administration.userEmail')
	}, {
		field: 'licenseTypeName',
		name: this.locale.getString('administration.licenseType')
	}, {
		field: 'systemAdmin',
		name: this.locale.getString('administration.administrator')
	}, {
		field: 'defaultMA',
		name: this.locale.getString('administration.defaultMA')
	}, {
		field: 'tags',
		name: this.locale.getString('administration.tags')
	}, {
		field: 'xmAccountId',
		name: this.locale.getString('administration.xmAccountId')
	}, {
		field: 'xmGlobalUserId',
		name: this.locale.getString('administration.xmGlobalUserId')
	}];

	constructor(
		@Inject('systemAdminApiService') private readonly systemAdminApiService: SystemAdminApiService,
		@Inject('$log') private readonly $log: ng.ILogService,
		private readonly cxDialogService: CxDialogService,
		private readonly locale: CxLocaleService,
		@Inject('userApiService') private readonly userApiService: UserApiService,
		@Inject('exportService') private readonly exportService: ExportService,
		@Inject('userRemoveModalService') private readonly userRemoveModalService: UserRemoveModalService,
	) { }

	ngOnInit(): void {
		this.pagination = new Pagination(10);
		for (let account of this.masterAccounts) {
			this.masterAccountsMap[account.accountId] = account;
		}
	}

	private createQueryParameters = (withoutTotal?: boolean) => {
		let properties: any = {};
		let userQueryBy = this.processedUserQueryBy;
		properties.isPaginated = this.pagination.isPaginated;
		properties.pageSize = this.pagination.pageSize;
		properties.currentPage = this.pagination.currentPage;
		properties.filterText = this.getFilterText(userQueryBy);
		properties.filterField = userQueryBy.value;
		properties.withTotal = !withoutTotal;

		if (this.sortParams.sortField !== '') {
			properties.sortField = this.sortParams.sortField;
			properties.sortDirection = 'desc';
			if (this.sortParams.sortDirection) {
				properties.sortDirection = 'asc';
			}
		}

		return {params: properties};
	};

	private getFilterText = (userQueryBy: IFilterOption) => {
		let property = userQueryBy?.property;
		if (!property) {
			property = 'text';
		}
		return this.userQuery[property];
	};

	onSearchChange = (userQuery: IUserSearchModel): void => {
		this.userQuery = userQuery;
		this.pagination.currentPage = 1;
		this.reloadPagedUserList();
	};

	reloadPagedUserList = (withoutTotal?: boolean) => {
		this.pagedUserDetails = [];
		let withTotal = !withoutTotal;

		this.processedUserQueryBy = { ...this.userQuery.userQueryTypeSelection };
		let queryParams = this.createQueryParameters(withoutTotal);

		this.loadingUsers = PromiseUtils.wrap(this.systemAdminApiService.getAllUsersPaged(queryParams).then(response => {
			if (withTotal) {
				this.pagination.totalItems = response.data.totalCount;
			}
			this.pagedUserDetails = response.data.data;

			for (let i = 0; i < this.pagedUserDetails.length; i++) {
				this.pagedUserDetails[i].id = i + 1;
			}

			let mapFunc = accid => {
				return this.masterAccountsMap[accid];
			};
			for (let user of this.pagedUserDetails) {
				user.linkedMasterAccounts = _.chain(Object.keys(user.masterAccountData))
					.map(mapFunc)
					.filter(ma => user.systemAdmin || user.liteAdmin ? ma?.adminOrg : true)
					.value();
			}
		}));
	};

	pageChanged = (pagination: Pagination): void => {
		this.pagination = pagination;
		this.reloadPagedUserList(true);
	};

	matchDefaultMasterAccount = (user) => {
		return user.linkedMasterAccounts.filter(
			masterAccountOption => user.defaultMasterAccountId === masterAccountOption.accountId
		);
	};

	initialize = (): void => {
		this.$log.debug('Initialize UsersManageTabCtrl controller');
		this.reloadPagedUserList();
	};

	sort = sortField => {
		if (this.sortParams.sortField !== sortField) {
			this.sortParams.sortDirection = false;
		}
		this.sortParams.sortField = sortField;
		this.sortParams.sortDirection = !this.sortParams.sortDirection;
		this.reloadPagedUserList();
	};

	disableUserFunction = user => {
		let modal = this.userRemoveModalService.showUserRemoveModal(user, UserRemoveModes.DISABLE, true);
		modal.result.then(this.reloadPagedUserList, () => {});
	};

	changeDefaultMA = user => {
		this.userApiService.changeDefaultMA(user.userId, user.defaultMasterAccountId).then(_.noop);
	};

	exportUserData = () => {
		let queryParams = this.createQueryParameters();
		this.exportService.exportUserDataReport(queryParams.params).then(() => {
			this.cxDialogService.notify(
				this.locale.getString('administration.exportUserData'),
				this.locale.getString('administration.exportUserDataMessage'));
		});
	};

	userIsDisabled = user => !user.masterAccountData || (Object.keys(user.masterAccountData).length === 0);

	searchOptionsFilter = (option: IFilterOption) => !_.contains(
		['loginDate', 'groupName', 'customField', 'ldapUid'], option.value);

	getAdministratorValue = (user: IExtendedUser): string => {
		let value: string[]  = [];
		if (user.systemAdmin) {
			value.push('System');
		}
		if (user.liteAdmin) {
			value.push('Lite');
		}
		if (user.ownedMasterAccounts.length > 0) {
			value.push('Owner');
		}
		if (user.customerAdminMasterAccounts.length > 0) {
			value.push('Customer');
		}
		return value.join(', ');
	};
}

app.directive('adminUsers',
	downgradeComponent({component: AdminUsersComponent}));
