
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { CacheOptions } from '@cxstudio/common/cache-options';
import CachedHttpFactory from '@cxstudio/common/cache/cached-http.factory';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { Caches } from '@cxstudio/common/caches';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import AttributeGeography from './attribute-geography';
import { BoundaryField } from './boundary-field';
import { GeographyReport } from './geography-report';
import ModelGeography from './model-geography';
import ProjectGeographies from './project-geographies';

export class GeographyApiService {

	constructor(
		private $http: ng.IHttpService,
		private httpHandlers: HttpHandlers,
		private cachedHttpService: CachedHttpService
	) { }

	getProjectGeographies = (contentProviderId: number, accountId: number, projectId: number,
		userEmail: string): ng.IPromise<ProjectGeographies> => {

		return this.getCache().post('rest/report-assets/geographies', {
			contentProviderId,
			accountId,
			projectId,
			userEmail
		}, {
			cache: CacheOptions.CACHED
		})
			.then(this.httpHandlers.success);
	};

	getModelGeography = (project: AccountOrWorkspaceProject, modelId: number): AssetPromisePlatformProject<ModelGeography> => {
		return this.getCache().get(`${this.getProjectPath(project)}/model/${modelId}`, {cache: CacheOptions.CACHED})
			.then(this.httpHandlers.success) as AssetPromisePlatformProject<ModelGeography>;
	};

	saveAttributeGeographies = (project: AccountOrWorkspaceProject, geographies: AttributeGeography[]): ng.IPromise<void> => {
		return this.$http.post(`${this.getProjectPath(project)}/attributes`, geographies)
			.then(this.httpHandlers.success)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.GEOGRAPHIES));
	};

	saveModelGeographies = (project: AccountOrWorkspaceProject, geographies: ModelGeography[]): ng.IPromise<void> => {
		return this.$http.post(`${this.getProjectPath(project)}/models`, geographies)
			.then(this.httpHandlers.success)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.GEOGRAPHIES));
	};

	generateGeographyReport = (boundaryField: BoundaryField): ng.IPromise<GeographyReport> => {
		return this.$http.get('rest/report-assets/geographies/report/generate', { params: {boundaryField}, cache: true })
			.then(this.httpHandlers.success);
	};

	private getCache = (): CachedHttpFactory => {
		return this.cachedHttpService.cache(Caches.GEOGRAPHIES);
	};

	private getProjectPath(project: AccountOrWorkspaceProject): string {
		if (WorkspaceTransitionUtils.isWorkspaceProject(project)) {
			return `rest/geography/ws/${project.workspaceId}/project/${project.projectId}`;
		} else {
			return `rest/geography/cp/${project.contentProviderId}/account/${project.accountId}/project/${project.projectId}`;
		}
	}
}

app.service('geographyApiService', GeographyApiService);
