import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { DirectionalOrientation } from '@cxstudio/common/orientation';
import { FontOptions } from '@cxstudio/common/font-options.class';
import { PointSelectionUtils } from '@cxstudio/reports/utils/analytic/point-selection-utils.service';
import { Security } from '@cxstudio/auth/security-service';
import { ReportScopeUtils } from '@cxstudio/reports/utils/report-scope-utils.service';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { SelectorDisplaySize } from '@app/modules/widget-settings/selector-display-size.class';

app.directive('cbSelector', (
	reportUtils: ReportUtils,
	tableService,
	capitalization,
	highchartsTreeReportDataBuilder,
	$timeout: ng.ITimeoutService,
	pointSelectionUtils: PointSelectionUtils,
	security: Security,
	reportScopeUtils: ReportScopeUtils,
	currentObjects: CurrentObjectsService
) => {
	return {
		restrict: 'A',
		replace: true,
		scope: {
			options: '<',
			dataObject: '<data',
			demo: '<',
			view: '<',
			trigger: '<',
			utils: '<',
			props: '<',
			widget: '<',
			handleClick: '<',
			handleRightClick: '<',
			linkedWidgets: '<'
		},
		templateUrl: 'partials/widgets/views/cb-selector-widget.html',
		link: ($scope, element) => {
			if (!tableService.processIfNoData(element, $scope.dataObject, undefined, WidgetType.SELECTOR, $scope.utils)) {
				return;
			}
			reportUtils.initDestroyListener($scope);
			$scope.toggleItem = toggleItem;
			$scope.clearSelections = clearSelections;
			$scope.getDirectionalClasses = getDirectionalClasses;
			$scope.getItemId = getItemId;

			$scope.dashboardBackgroundColor = security.getActiveMasterAccountColors().COLOR_10 || '';

			$scope.$watch('trigger', (newValue, oldValue) => {
				if (newValue === oldValue) return;
				if ($scope.demo) {
					reloadData();
				}
			});

			if (!$scope.demo) {
				$scope.onOverButton = (point) => $scope.selectedPoint = currentObjects.isEditMode() ? point : null;
				$scope.onLeaveButton = () => $scope.selectedPoint = null;
				reportScopeUtils.emitContextMenuHandling($scope, element, true);
			}

			$scope.$on('selector:clearSelections', clearSelections);

			reloadData();

			function reloadData(): void {

				if ($scope.options.displaySize === SelectorDisplaySize.EQUAL_SIZE) {
					$timeout(applyConstantButtonSize);
				}

				$scope.capitalizer = capitalization.getWrappedFormatter($scope.props.selectedAttributes?.[0]?.capitalization);

				$scope.selectorData = highchartsTreeReportDataBuilder.getAnalyticTreeData($scope);

				// process selected points for refresh and pdf export
				updateSelectedPoints();

				if ($scope.options.font) {
					let fontFamily = _.find(FontOptions.getOptions(), (fontDefinition) => fontDefinition.name === $scope.options.font);
					if (fontFamily) {
						$scope.fontStyle = { fontFamily: fontFamily.css };
					}
				}

				$scope.directionalClasses = getDirectionalClasses();

				$scope.$$postDigest(() => {
					if (!$scope.demo) {
						reportUtils.handleWidgetRenderedEvent($scope.utils.widgetId, $scope.utils.widgetType, $scope.utils.containerId);
					}
				});
			}

			function getItemId(uniqName): string {
				return uniqName ? `_select-${uniqName.replace(/[^A-Za-z0-9]/g, '-')}` : '';
			}

			function toggleItem(dataItem): void {
				if (!$scope.linkedWidgets?.length || $scope.demo) return;

				if (_.isArray(dataItem)) {
					_.each(dataItem, item => item.selected = !item.selected);
				} else {
					dataItem.selected = !dataItem.selected;
				}
				if ($scope.options.multiselect) {
					multiSelect(dataItem);
				} else {
					singleSelect(dataItem);
				}
				setPointSelections();
			}

			function singleSelect(dataItem): void {
				$scope.selectorData.forEach(data => {
					if (data.object._uniqName !== dataItem._uniqName)
						data.object.selected = false;
				});
				$scope.isAnyFilterApplied = !!dataItem.selected;
				$scope.$emit('selector:updateClearSelections', !!dataItem.selected);
				$scope.handleClick(dataItem);
			}

			function multiSelect(dataItem): void {
				let selectedItems = _.chain($scope.selectorData)
					.filter((data: any) => data.object.selected)
					.map((data: any) => data.object)
					.value();
				$scope.isAnyFilterApplied = selectedItems.length > 0;
				$scope.$emit('selector:updateClearSelections', selectedItems.length >= 2);
				$scope.handleClick(_.isArray(dataItem) ? selectedItems : dataItem);
			}

			function setPointSelections(): void {
				let selectedPointIds = _.chain($scope.selectorData)
					.filter((item: any) => !!item.object.selected)
					.map((item: any) => item.object._uniqName)
					.value();
				if (selectedPointIds.length > 0) {
					pointSelectionUtils.enablePointSelection($scope.utils.containerId, $scope.utils.widgetId, true);
					pointSelectionUtils.setPointsInSelector($scope.utils.containerId, $scope.utils.widgetId, selectedPointIds);
				} else {
					pointSelectionUtils.enablePointSelection($scope.utils.containerId, $scope.utils.widgetId, false);
				}
			}

			function clearSelections(): void {
				if ($scope.selectorData) {
					$scope.selectorData.forEach(data => data.object.selected = false);
				}
				clearCheckbox();
				pointSelectionUtils.enablePointSelection($scope.utils.containerId, $scope.utils.widgetId, false);
				$scope.isAnyFilterApplied = false;
				$scope.$emit('selector:updateClearSelections', false);
				$scope.handleClick(null);
			}

			function clearCheckbox(): void {
				let checkboxes = element.find('selector-widget-droplist .hierarchy-item input').toArray();
				checkboxes.forEach((checkbox: HTMLInputElement) => checkbox.checked = false);
			}

			function updateSelectedPoints(): void {
				let selectedPoints = pointSelectionUtils.getSelectedPoint($scope.utils.containerId, $scope.utils.widgetId) as string[];
				if (!_.isEmpty(selectedPoints) && !$scope.demo) {
					selectedPoints = _.uniq(selectedPoints);
					//clear selections when switching from multiselect to singleselect
					if (selectedPoints.length > 1 && !$scope.options.multiselect) {
						clearSelections();
						return;
					}
					if (selectedPoints.length === 1) {
						$scope.$emit('selector:updateClearSelections', !$scope.options.multiselect);
					}

					pointSelectionUtils.enablePointSelection($scope.utils.containerId, $scope.utils.widgetId, true);
					for (let uniqName of selectedPoints) {
						let point = _.find($scope.selectorData, (item: any) => item.object._uniqName === uniqName);
						if (point) {
							point.object.selected = true;
						} else {
							pointSelectionUtils.enableWrongSelectionWarning($scope.utils.containerId, $scope.utils.widgetId, true);
							return;
						}
					}
					$scope.isAnyFilterApplied = true;
					pointSelectionUtils.enableWrongSelectionWarning($scope.utils.containerId, $scope.utils.widgetId, false);
				}
			}

			function getDirectionalClasses(): string {
				let direction = ($scope.options.orientation === DirectionalOrientation.HORIZONTAL) ?
					'flex-multiline l-margin-offset-3' : 'flex-direction-column';
				let sizing = `${$scope.options.displaySize}-buttons`;

				return `${direction} ${sizing}`;
			}

			function applyConstantButtonSize(): void {
				let max = 0;
				let buttons = element.find('selector-widget-button div');
				for (let button of buttons as unknown as any[]) {
					if (button.offsetWidth > max) max = button.offsetWidth;
				}

				for (let button of buttons as unknown as any[]) {
					button.style.width = `${max}px`;
				}
			}
		}
	};
});
