import { DashboardCreationOriginDetails } from '@app/modules/dashboard/dashboard-creation-origin-details';
import DashboardCreationOrigin from '@app/modules/dashboard/dashboard-creation-origin.enum';
import { ProjectContextService } from '@app/modules/project/context/project-context.service';
import ScorecardUtils from '@app/modules/scorecards/utils/scorecard-utils';
import { SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { Security } from '@cxstudio/auth/security-service';
import Widget, { WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';
import GridsterConfigurer from '@cxstudio/home/gridster-configurer';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import ScorecardsApiService from '@cxstudio/projects/scorecards/scorecards-api-service';
import { FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ReportMetricService } from '@cxstudio/reports/metrics/report-metric-service';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { PeriodOverPeriodMetricType } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric-type';
import { PeriodOverPeriodMetricService } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { CustomFilterService } from '@cxstudio/services/custom-filter-service';
import * as _ from 'underscore';
import { IAlertPredefinedSettings } from '../entities/alert-predefined-settings';
import { AlertThresholdTypes } from '../entities/alert-threshold-types';
import { AlertTrigger } from '../entities/alert-trigger';
import { AlertTriggerCriteriaOptionsService } from '@app/modules/alert/services/alert-trigger-criteria-options.service';
import { StudioAlert } from '../entities/studio-alert';
import { AlertTriggerCriteria } from '@app/modules/alert/alert-trigger-criteria';
import { ScheduleUtilsService } from '@app/modules/dashboard/schedule/schedule-utils.service';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { AlertSettingsService } from '@app/modules/alert/alert-settings.service';

export class AlertTriggerController implements ng.IComponentController {
	alert: StudioAlert;
	predefinedSettings: IAlertPredefinedSettings;
	thresholdTypes = AlertThresholdTypes;
	isNewAlert: boolean;
	weekDaysOptions: any[];
	minDate: any;
	currentStep: number;
	ui: any;
	groupingValidator: {validate?: (grouping: any) => ng.IPromise<boolean>};

	private criteriaOptions: UIOption<AlertTriggerCriteria>[] = [];
	private calculationOptions;
	private studioMetrics;
	private filteringOptions;
	private props: IProjectSelection;
	private loading;
	private projectTimezone;
	private dateOptions: any = {};
	private selectedCalculation;
	private scheduleType;

	private optionsLoaded = false;

	private frequencyOptions;

	originDetails: DashboardCreationOriginDetails;

	readonly PERIOD_OPTIONS = [ 'last7d', 'last30d', 'last90d', 'last180d',
		'lastd', 'lastw', 'lastm', 'year_to_date', 'quarter_to_date',
		'month_to_date', 'week_to_date', 'custom'];

	readonly COMPARE_PERIOD_OPTIONS = ['previousPeriod', 'sameLastYear', 'sameLastQuarter',
		'sameLastMonth', 'sameLastWeek',  'last365d', 'previous12m', 'lastq', 'lasty', 'currentw',
		'currentm', 'currentq', 'currenty'].concat(this.PERIOD_OPTIONS);

	readonly OPTIONS_TO_ADJUST = {
		daily: {
			name: this.locale.getString('alert.daily'),
			period: this.DateRange.options.LAST_DAY.value
		},
		weekly: {
			name: this.locale.getString('alert.weekly'),
			period: this.DateRange.options.LAST_7_DAYS.value
		},
		monthly: {
			name: this.locale.getString('alert.monthly'),
			period: this.DateRange.options.LAST_MONTH.value
		}
	};

	constructor(
		private locale: ILocale,
		private metricConstants: MetricConstants,
		private optionsBuilderProvider: OptionsBuilderProvider,
		private optionsTemplatesService: OptionsTemplatesService,
		private projectContextService: ProjectContextService,
		private datePeriodUtils,
		private DateRange,
		private alertSettingsService: AlertSettingsService,
		private scheduleUtilsService: ScheduleUtilsService,
		private reportMetricService: ReportMetricService,
		private security: Security,
		private $scope: ng.IScope,
		private gridsterConfigurer: GridsterConfigurer,
		private periodOverPeriodMetricService: PeriodOverPeriodMetricService,
		private dashboardService,
		private alertTriggerCriteriaOptionsService: AlertTriggerCriteriaOptionsService,
		private filterManagementApiService: FilterManagementApiService,
		private scorecardsApiService: ScorecardsApiService,
		private customFilterService: CustomFilterService,
	) {}

	$onInit = () => {
		this.originDetails = {
			type:  DashboardCreationOrigin.ALERT,
			name: this.alert.name
		};

		this.populateSchedulingOptions();
		this.loadProjectOptions();
		this.getProjectTimezone();
		this.setDefaults();
		this.updateCriteriaOptions(true);

		// required for TS validation
		let showampm = this.security.loggedUser && this.security.loggedUser.dateFormat === 'us';
		let time = this.scheduleUtilsService.getDefaultTimeObject(this.alert.startDate, this.alert.timezoneOffset);
		this.scheduleUtilsService.setAmPmHour(time, showampm);

		this.selectFrequencyFromOptions();
	};

	private populateSchedulingOptions = (): void => {
		let availableOptions = this.scheduleUtilsService.getSchedulingOptions();
		_.each(availableOptions, (option: {name: string; value: string}) => {
			if (this.OPTIONS_TO_ADJUST[option.value]) {
				option.name = this.OPTIONS_TO_ADJUST[option.value].name;
			}
		});

		this.frequencyOptions = [ availableOptions.DAILY, availableOptions.WEEKLY, availableOptions.MONTHLY ];
	};

	// sets the default alert values, if necessary
	private setDefaults = () => {
		let triggerDefaults: AlertTrigger = {
			minVolume: 100,
			filterRules: [],
			period: this.DateRange.options.LAST_7_DAYS.value,
			conditions: [{
				criteria: AlertTriggerCriteria.LESS_THAN_OR_EQUAL,
				threshold: 1,
				thresholdType: AlertThresholdTypes.DELTA,
				comparisonPeriod: this.DateRange.historicOptions.PREVIOUS_PERIOD.value
			}],
		};

		this.alert.trigger = angular.extend(triggerDefaults, this.alert.trigger);
		this.alert.trigger.scheduleType = this.scheduleUtilsService.getScheduleType(
			this.alert.cronExpression, this.frequencyOptions);
		this.scheduleType = _.find(this.frequencyOptions, { value: this.alert.trigger.scheduleType});
	};

	requiresComparisonPeriod = (): boolean => {
		return [
			AlertTriggerCriteria.INCREASE_GREATER_OR_EQUAL,
			AlertTriggerCriteria.DECREASE_GREATER_OR_EQUAL,
			AlertTriggerCriteria.CHANGE_GREATER_OR_EQUAL,
			AlertTriggerCriteria.SIGNIFICANT_CHANGE
		].indexOf(this.alert.trigger.conditions[0].criteria) > -1;
	};

	private loadProjectOptions = () => {
		this.loading = this.alertSettingsService.getAlertSettings(this.props).then((result) => {
			const OMIT_TEXT_FILTER = true;
			this.calculationOptions = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
				.withStandardMetrics(this.metricConstants.getStandardCalculations())
				.withPredefinedMetrics(result.predefinedMetrics)
				.withMetrics(result.metrics, this.props.projectId)
				.withAttributes(result.attributes, MetricFilters.CALCULATION)
				.withScorecardMetrics(result.scorecardMetrics)
				.build();
			this.studioMetrics = angular.copy(result.metrics);

			if (this.alert.trigger.grouping && !this.alert.trigger.sortBy) {
				this.updateSortBy(this.alert.trigger.grouping.sortBy);
			}

			this.filteringOptions = this.optionsBuilderProvider.getBuilder()
				.withTemplate(this.optionsTemplatesService.filterItemsWithESQuery(OMIT_TEXT_FILTER))
				.withModels(result.models)
				.withAttributes(angular.copy(result.attributes), MetricFilters.NOT_DOCUMENT_DATE)
				.withWordAttributes(result.wordAttributes)
				.build();

			this.dateOptions.dateFilters = this.datePeriodUtils.processDateFilters(result.dateFilters);

			if (!this.alert.trigger.conditions[0].calculation) {
				let sentimentMetric = SearchableHierarchyUtils.findMetricNameInHierarchy(this.calculationOptions, 'sentiment');
				this.alert.trigger.conditions[0].calculation = sentimentMetric || this.metricConstants.get().VOLUME;
			}
			// suppress "missing dates" option
			this.dateOptions.hideMissDate = true;
			this.optionsLoaded = true;
		});
	};

	setValue = (property: string, value: any): void => {
		this.selectedCalculation = value;
		this.alert.trigger[property] = value;
	};

	private getProjectTimezone = (): void => {
		let accountProject = new ProjectIdentifier(this.alert.contentProviderId, this.alert.accountId, this.alert.projectId);
		this.projectContextService.getProjectTimezone(accountProject).then((timezone) => {
			this.projectTimezone = timezone;
		});
	};

	isSignificanceSelected = (): boolean => {
		return this.alert.trigger.conditions[0].criteria === AlertTriggerCriteria.SIGNIFICANT_CHANGE;
	};

	updateCriteriaOptions = (initial?: boolean): void => {
		this.criteriaOptions = this.alertTriggerCriteriaOptionsService.getBasicOptions();
		if (this.isAdditionalPoPMetricSupported(PeriodOverPeriodMetricType.SIGNIFICANCE)) {
			let significantChangeOption = this.alertTriggerCriteriaOptionsService.getOption(AlertTriggerCriteria.SIGNIFICANT_CHANGE);
			this.criteriaOptions.push(significantChangeOption);
		} else if (this.isSignificanceSelected()) {
			this.alert.trigger.conditions[0].criteria = AlertTriggerCriteria.LESS_THAN_OR_EQUAL;
		}
		if (this.isScorecardCalculation() && !initial) {
			this.alert.trigger.conditions[0].criteria = AlertTriggerCriteria.LESS_THAN_OR_EQUAL;
			this.loading = this.updateScorecardThreshold();
		}
		this.updateThresholdType();
	};

	updateThresholdType = (): void => {
		if (this.isSignificanceSelected()) {
			this.alert.trigger.conditions[0].thresholdType = AlertThresholdTypes.SIGNIFICANCE;
		} else if (this.alert.trigger.conditions[0].thresholdType === AlertThresholdTypes.SIGNIFICANCE) {
			this.alert.trigger.conditions[0].thresholdType = AlertThresholdTypes.DELTA;
		} else if (this.alert.trigger.conditions[0].criteria === AlertTriggerCriteria.LESS_THAN_OR_EQUAL
				|| this.alert.trigger.conditions[0].criteria === AlertTriggerCriteria.GREATER_THAN_OR_EQUAL) {
			this.alert.trigger.conditions[0].thresholdType = AlertThresholdTypes.DELTA;
			if (this.isScorecardCalculation()) {
				this.updateScorecardThreshold();
			}
		}
		this.ui.calculationError = this.validateThresholdType();
	};

	setThresholdType = (type: AlertThresholdTypes): void => {
		this.alert.trigger.conditions[0].thresholdType = type;
		this.ui.calculationError = this.validateThresholdType();
	};

	private validateThresholdType(): boolean  {
		if (!this.alert?.trigger?.conditions[0].calculation) {
			return false;
		}
		let type = this.getAdditionalPoPMetricType();
		return !this.isAdditionalPoPMetricSupported(type);
	}

	setAlertPeriod = (dateFilterMode, dateFilterRange, dateDisplayName) => {
		this.alert.trigger.period = dateFilterMode;
		this.alert.trigger.periodRange = dateFilterRange;
	};

	alertPeriodClicked = () => {
		this.alert.trigger.periodCustomized = true;
	};

	private selectFrequencyFromOptions = (): void => {
		let options = this.frequencyOptions || [];

		let values = options.map(option => option.value);
		if (values.indexOf(this.alert.trigger.scheduleType) === -1) {
			this.alert.trigger.scheduleType = options[0].value;
			this.updateFrequency(options[0]);
		}
	};

	requiresFrequencyDetails = () => {
		let availableOptions = this.scheduleUtilsService.getSchedulingOptions();

		return (this.alert.trigger.scheduleType === availableOptions.WEEKLY.value)
			|| (this.alert.trigger.scheduleType === availableOptions.MONTHLY.value);
	};

	setAlertComparisonPeriod = (dateFilterMode, dateFilterRange) => {
		this.alert.trigger.conditions[0].comparisonPeriod = dateFilterMode;
		this.alert.trigger.conditions[0].comparisonPeriodRange = dateFilterRange;
	};

	periodOptionsFilter = (filter): boolean => {
		return this.PERIOD_OPTIONS.indexOf(filter.value) >= 0
			|| this.DateRange.isCustomDateRange(filter.value);
	};

	compareOptionsFilter = (filter): boolean => {
		return (this.COMPARE_PERIOD_OPTIONS.indexOf(filter.value) >= 0) ||
			this.DateRange.isCustomDateRange(filter.value);
	};

	processCron = () => {
		this.$scope.$emit('processCron');
	};

	updateWeekSelection = (newDay) => {
		this.weekDaysOptions.forEach((day) => { day.value = false; });
		newDay.value = true;
		this.processCron();
	};

	setFrequency = (selectedOption) => {
		if (!this.alert.trigger.periodCustomized) {
			let dateFilterMode = this.OPTIONS_TO_ADJUST[selectedOption.value]?.period;
			this.$scope.$broadcast('date-filters:refresh', {dateFilterMode});
		}
		this.updateFrequency(selectedOption);
	};

	private updateFrequency = (selectedOption) => {
		if (selectedOption) {
			this.alert.trigger.scheduleType = selectedOption.value;
			this.scheduleType = selectedOption;
		}

		if (this.alert.trigger.scheduleType === 'once') {
			this.minDate = new Date();
		}

		this.processCron();
	};

	private updateSortBy = (name?: string) => {
		if (name) {
			this.alert.trigger.sortBy = SearchableHierarchyUtils.findMetricInHierarchy(this.calculationOptions, {name});
		} else {
			delete this.alert.trigger.sortBy;
		}
	};

	onGroupingChange = (grouping: ReportGrouping): void => {
		if (this.alert.trigger.sortBy && grouping?.sortBy !== this.alert.trigger.sortBy?.name) {
			this.updateSortBy(grouping.sortBy);
		}
		this.alert.trigger.grouping = grouping;
	};

	onGroupingClear = (): void => {
		this.alert.trigger.grouping = null;
		this.updateSortBy();
	};

	getNewDashboardName = (): string => {
		let baseName = this.alert.name + ' ' + this.locale.getString('alert.alert');
		return this.dashboardService.getUniqueDashboardName(baseName);
	};

	getAlertTriggerWidget = (): Widget[] => {
		let widget = {} as Widget;
		let isTable = !!this.alert.trigger.grouping;
		let hasHistoricPeriod = this.requiresComparisonPeriod();

		widget.name = isTable ? WidgetType.TABLE : WidgetType.METRIC;
		widget.type = WidgetDisplayType.CB;
		widget.displayName = this.alert.name;
		this.addProperties(widget, isTable, hasHistoricPeriod);
		this.addVisualProperties(widget, isTable, hasHistoricPeriod);
		this.customFilterService.preprocessWidgetProperties(widget);
		return [widget];
	};

	private addProperties = (widget: Widget, isTable: boolean, hasHistoricPeriod: boolean): void => {
		widget.properties = {} as WidgetProperties;
		widget.properties.isCustomTitle = true;
		widget.properties.runAs = this.security.getEmail();
		widget.properties.widgetType = isTable ? WidgetType.TABLE : WidgetType.METRIC;
		widget.properties.contentProviderId = this.alert.contentProviderId;
		widget.properties.accountId = this.alert.accountId;
		widget.properties.project = this.alert.projectId;
		widget.properties.altTextFromTitle = true;
		widget.properties.altText = widget.displayName;
		widget.properties.autoDescription = true;

		widget.properties.selectedAttributes = isTable ? [this.alert.trigger.grouping] : [];
		widget.properties.selectedMetrics = [this.alert.trigger.conditions[0].calculation];

		widget.properties.dateRangeP1 = {dateFilterMode : this.alert.trigger.period} as DateFilter;
		if (this.alert.trigger.periodRange) {
			widget.properties.dateRangeP1.from = this.alert.trigger.periodRange.from;
			widget.properties.dateRangeP1.to = this.alert.trigger.periodRange.to;
		}
		if (hasHistoricPeriod) {
			widget.properties.useHistoricPeriod = true;
			widget.properties.dateRangeP2 = {dateFilterMode: this.alert.trigger.conditions[0].comparisonPeriod} as DateFilter;
			if (this.alert.trigger.conditions[0].comparisonPeriodRange) {
				widget.properties.dateRangeP2.from = this.alert.trigger.conditions[0].comparisonPeriodRange.from;
				widget.properties.dateRangeP2.to = this.alert.trigger.conditions[0].comparisonPeriodRange.to;
			}
			if (isTable) {
				widget.properties.selectedMetrics.push(this.periodOverPeriodMetricService.createMetric(
					this.alert.trigger.conditions[0].calculation, PeriodOverPeriodMetricType.HISTORICAL));
			}
			let additionalPoPMetricType = this.getAdditionalPoPMetricType();
			if (this.isAdditionalPoPMetricSupported(additionalPoPMetricType)) {
				if (isTable) {
					widget.properties.selectedMetrics.push(this.periodOverPeriodMetricService.createMetric(
						this.alert.trigger.conditions[0].calculation, additionalPoPMetricType));
				} else {
					widget.properties.selectedMetrics[0].popField = additionalPoPMetricType;
				}
			}
		}

		this.filterManagementApiService.convertModelTreeSelectionRulesForBackend(this.alert.trigger.filterRules);
		widget.properties.adhocFilter = { type: FilterTypes.AND, filterRules: this.alert.trigger.filterRules };
		widget.properties.appliedFilters = { type: FilterTypes.AND, filters: [] };
	};

	private getAdditionalPoPMetricType = (): PeriodOverPeriodMetricType => {
		let thresholdType = this.alert.trigger.conditions[0].thresholdType;
		if (thresholdType === AlertThresholdTypes.PERCENT) {
			return PeriodOverPeriodMetricType.PERCENT_CHANGE;
		} else if (thresholdType === AlertThresholdTypes.SIGNIFICANCE) {
			return PeriodOverPeriodMetricType.SIGNIFICANCE;
		}
		return PeriodOverPeriodMetricType.DELTA;
	};

	private isAdditionalPoPMetricSupported = (additionalPoPMetricType: PeriodOverPeriodMetricType): boolean => {
		return this.alert.trigger.conditions[0].calculation
			&& this.periodOverPeriodMetricService.getPeriodOverPeriodMetricByType(additionalPoPMetricType)
				?.isSupported(this.alert.trigger.conditions[0].calculation, this.studioMetrics);
	};

	private addVisualProperties = (widget: Widget, isTable: boolean, hasHistoricPeriod: boolean): void => {
		widget.visualProperties = {} as VisualProperties;
		if (!isTable) {
			widget.visualProperties.visualization = WidgetVisualization.CB_AN_METRIC;
			if (hasHistoricPeriod) {
				widget.visualProperties.previousPeriod = true;
				widget.visualProperties.trendArrow = true;
			}
		} else {
			widget.visualProperties.visualization = WidgetVisualization.CB_AN_TABLE;
		}
		widget.width = this.gridsterConfigurer.getGridsterItemWidth();
		widget.height = this.gridsterConfigurer.getGridsterItemHeight();
	};

	private isScorecardCalculation(): boolean {
		return ScorecardUtils.isScorecardAttribute(this.alert.trigger.conditions[0].calculation?.name);
	}

	isThresholdLocked = (): boolean => {
		return this.isScorecardCalculation()
			&& [AlertTriggerCriteria.LESS_THAN_OR_EQUAL, AlertTriggerCriteria.GREATER_THAN_OR_EQUAL]
				.contains(this.alert.trigger.conditions[0].criteria);
	};

	getThresholdLockedMessage = (): string => {
		return this.isThresholdLocked() ? this.locale.getString('alert.rubricThresholdTooltip') : '';
	};

	private updateScorecardThreshold(): ng.IPromise<void> {
		let scorecardId = ScorecardUtils.getScorecardId(this.alert.trigger.conditions[0].calculation.name);
		return this.scorecardsApiService.getScorecard(this.props.contentProviderId, this.props.accountId, scorecardId).then(scorecardData => {
			this.alert.trigger.conditions[0].threshold = scorecardData.scorecard.threshold;
		});
	}

}

app.component('alertTrigger', {
	bindings: {
		alert: '<',
		props: '<',
		loading: '=',
		isNewAlert: '<',
		weekDaysOptions: '=',
		minDate: '=',
		currentStep: '<',
		recurrence: '=',
		ui: '<',
		groupingValidator: '<'
	},
	controller: AlertTriggerController,
	templateUrl: 'partials/alerts/metric-alert-editor-trigger.html'
});
