import { ChangeDetectionStrategy, Component, Input, OnInit, Inject, HostListener, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@app/modules/authentication/services/authentication.service';
import { UserSessionApiService } from '@app/modules/user/user-session-api.service';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { Security } from '@cxstudio/auth/security-service';
import { IModalComponent } from '@app/modules/dialog/modal-component.interface';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { SecurityApiService } from '@cxstudio/services/data-services/security-api.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { URLEvent } from '@app/core/cx-event.enum';
import { RedirectService } from '@cxstudio/services/redirect-service';

interface SessionDialogData {
	password: string;
	errorMessage?: string;
}

export interface SessionDialogInput {
	username: string;
}

interface SessionDialogUI {
	showQualtricsLogin: boolean;
	showDiscoverLogin: boolean;
}

@Component({
	templateUrl: './session-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionDialogComponent extends SelfCleaningComponent
	implements OnInit, IModalComponent<SessionDialogInput> {

	@Input() input: SessionDialogInput;

	data: SessionDialogData;
	ui: SessionDialogUI;
	enforceXmAuthenticationEnabled: boolean;
	loading: boolean;

	constructor(
		@Inject(Window) private window: Window,
		@Inject('security') private readonly security: Security,
		@Inject('environmentService') private readonly environmentService: EnvironmentService,
		@Inject('cbDialogService') private readonly cbDialogService: CBDialogService,
		@Inject('securityApiService') private readonly securityApiService: SecurityApiService,
		@Inject('redirectService') private redirectService: RedirectService,
		private readonly cxLocale: CxLocaleService,
		private readonly userSessionApiService: UserSessionApiService,
		private readonly authenticationService: AuthenticationService,
		private readonly betaFeaturesService: BetaFeaturesService,
		private modal: NgbActiveModal,
		private eventBus: GlobalEventBus,
		private ref: ChangeDetectorRef,
	) {
		super();
		this.ui = {} as SessionDialogUI;
		this.data = {} as SessionDialogData;
	}

	ngOnInit(): void {
		this.ui.showDiscoverLogin = true;
		this.enforceXmAuthenticationEnabled = false;

		if (this.security.isQualtricsIntegrationEnabled()
			&& this.betaFeaturesService.isFeatureEnabled(BetaFeature.QUALTRICS_SESSION_DIALOG)) {
			this.ui.showQualtricsLogin = true;
			if (this.security.isEnforceXmAuthenticationEnabled()) {
				this.enforceXmAuthenticationEnabled = true;
			}
		}

		this.addListener(this.eventBus.subscribe(URLEvent.SCOPE_LOCATION_CHANGE_START, (event, newUrl, oldUrl) => {
			if (newUrl === oldUrl) return;
			event.preventDefault();
			this.window.location.href = '';
		}));
	}

	login = (): void => {
		let password = this.data.password;
		this.data.password = '';
		this.loading = true;
		this.userSessionApiService.continueSession({login: this.security.getEmail(), password})
			.then((resp) => {
				let response = resp.data;

				if (response.token) {
					this.security.setToken(response.token);
				}

				if (response.accessToken) {
					this.security.setAccessToken(response.accessToken);

					if (response.expiresIn) {
						this.authenticationService.startRefreshAccessTokenWatcher(response.expiresIn);
					}
				}

				this.loading = false;
				this.modal.close();
				if (response.passwordExpired) {
					this.showPasswordResetDialog();
				}
			}, (resp) => {
				this.loading = false;
				let responseData = resp.data;
				let status = resp.status;

				if (status === 401) {
					this.data.errorMessage = this.cxLocale.getString('login.sessionIncorrectPassword');
				} else {
					this.data.errorMessage = responseData
						? this.cxLocale.getString(responseData.data)
						: this.cxLocale.getString('common.networkError');
				}
				this.ref.detectChanges();
			});
	};

	private showPasswordResetDialog(): void {
		this.cbDialogService.showPasswordResetDialog(
			this.security.getUser(),
			this.securityApiService.getPasswordPolicy(this.security.getDefaultMasterAccountId()));
	}

	changeUser = (): void => {
		this.security.setToken(undefined);
		if (this.environmentService.isIframe()) {
			this.window.location.reload(); // to keep current redirection url
		} else {
			this.window.location.href = ''; // to clean all variables
		}
		this.modal.dismiss();
	};

	loginViaQualtrics = (): void => {
		this.loginViaExternal(this.security.getQualtricsAuthLandingPage());
	};

	private loginViaExternal = (loginUrl: string): void => {
		this.loading = true;

		let callback = (): void => {
			this.security.setAccessToken(undefined);
			this.authenticationService.getAccessToken().then((response: any) => {
				this.security.setAccessToken(response.token);
				this.loading = false;
				// During external authentication user has changed, need to reload page
				if (this.security.getEmail().toLowerCase() !== response.userEmail?.toLowerCase()) {
					this.redirectService.goToLogin();
					this.environmentService.disregardChangesAndRefresh();
				} else {
					this.modal.close();
				}
			});
		};
		this.environmentService.openOAuthLoginPopup(loginUrl, callback);
	};

	cleanErrorMessage = (): void => {
		this.data.errorMessage = null;
	};

	showLoginCredentialsInputFields = (): boolean => {
		return this.ui.showDiscoverLogin && !this.enforceXmAuthenticationEnabled;
	};

	showContinueSessionMsg = (): boolean => {
		return !this.ui.showDiscoverLogin && !this.enforceXmAuthenticationEnabled;
	};

	showContinueSessionMsgForXmEnforced = (): boolean => {
		return this.enforceXmAuthenticationEnabled;
	};

	showContinueButton = (): boolean => {
		return this.ui.showDiscoverLogin && !this.enforceXmAuthenticationEnabled;
	};

	@HostListener('window:keyup.escape', ['$event'])
	onKeyEscape(event: KeyboardEvent) {
		event.stopPropagation();
		this.changeUser();
	}
}
