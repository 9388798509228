import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { AssetAccessApiService } from '@app/modules/access-management/api/asset-access-api.service';
import { IAsset } from '@app/modules/access-management/groups/asset';
import { AssetType } from '@app/modules/access-management/groups/asset-type';
import { HasDirty } from '@app/modules/object-list/object-list-utils';
import { AgGridNestable } from '@app/modules/object-list/types/ag-grid-nestable.interface';
import { IAdminAttribute } from '@app/modules/project/attribute/admin-attribute';
import { AttributeManagementService } from '@app/modules/project/attribute/attribute-management.service';
import { AttributeType } from '@app/modules/project/attribute/attribute-type';
import { IAdminModel } from '@app/modules/project/model/admin-model';
import { ModelManagementService } from '@app/modules/project/model/model-management.service';
import { AssetVisibilityFlags } from '@app/modules/project/project-assets-management/project-assets-list/asset-visibility-flags.interface';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { NLPAttributes } from '@cxstudio/reports/settings/options/nlp-attributes';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { IHiddenObject } from '@cxstudio/services/user-objects.service';

export type IAdminAttributeTreeItem = IExtendedAdminAttribute | IFolderItem;

export interface IExtendedAdminAttribute extends IAdminAttribute, AssetVisibilityFlags, AgGridNestable, HasDirty<IExtendedAdminAttribute> {
	uiType: string;
	uiParents: string;
}

export interface IExtendedAdminModel extends IAdminModel, AssetVisibilityFlags, HasDirty<IExtendedAdminModel> {}

@Injectable({
	providedIn: 'root'
})
export class AdminAssetsManagementService {

	constructor(
		private locale: CxLocaleService,
		private attributeManagementService: AttributeManagementService,
		private modelManagementService: ModelManagementService,
		private assetAccessApiService: AssetAccessApiService,
		@Inject('metricConstants') private metricConstants: MetricConstants,
	) { }

	getAttributes(project: WorkspaceProject): Promise<IAdminAttributeTreeItem[]> {
		const attributePromise = this.attributeManagementService.getAdminAttributes(project, []);
		const hideInDrillAttributesPromise = this.assetAccessApiService.getDrillMenuHiddenAssets(project);
		const hideInDocExpAttributesPromise = this.assetAccessApiService.getDocExplorerHiddenAssets(project);
		const hideInReportingAssetsPromise = this.assetAccessApiService.getReportingAssetAccess(project);

		return Promise.all([
			attributePromise,
			hideInDrillAttributesPromise,
			hideInDocExpAttributesPromise,
			hideInReportingAssetsPromise,
		]).then((response) => {
			//need to transform drill path and parent ids to display names
			const attributes = response[0] as IExtendedAdminAttribute[];
			const hideInDrillItems = response[1];
			const hideInDocExpItems = response[2];
			const hideInReportingItems = response[3];

			this.populateUiStrings(attributes);
			this.populateHide(attributes, 'showInDrill', hideInDrillItems, AssetType.ATTRIBUTE);
			this.populateHide(attributes, 'showInDocExp', hideInDocExpItems, AssetType.ATTRIBUTE);
			this.populateShowInReporting(attributes, hideInReportingItems, AssetType.ATTRIBUTE);

			return this.processRawAttributes(attributes);
		});
	}

	getModels(project: WorkspaceProject): Promise<IExtendedAdminModel[]> {
		let modelPromise = this.modelManagementService.getAdminModels(project, []);
		let hideInDrillAttributesPromise = this.assetAccessApiService.getDrillMenuHiddenAssets(project);
		let hideInDocExpAttributesPromise = this.assetAccessApiService.getDocExplorerHiddenAssets(project);
		let hideInReportingAssetsPromise = this.assetAccessApiService.getReportingAssetAccess(project);

		return Promise.all([
			modelPromise,
			hideInDrillAttributesPromise,
			hideInDocExpAttributesPromise,
			hideInReportingAssetsPromise
		]).then((response) => {
			const models = response[0] as IExtendedAdminModel[];
			const hideInDrillItems = response[1];
			const hideInDocExpItems = response[2];
			const hideInReportingItems = response[3];
			this.populateHide(models, 'showInDrill', hideInDrillItems, AssetType.MODEL);
			this.populateHide(models, 'showInDocExp', hideInDocExpItems, AssetType.MODEL);
			this.populateShowInReporting(models, hideInReportingItems, AssetType.MODEL);
			return models;
		});
	}

	private processRawAttributes(attributes: IExtendedAdminAttribute[]): IAdminAttributeTreeItem[] {
		let filteredAttributes = _.filter(attributes, attr=> attr.type !== AttributeType.SATSCORE);
		return this.processAttributesWithFolders(filteredAttributes);

	}

	private processAttributesWithFolders(attributes: IExtendedAdminAttribute[]): IAdminAttributeTreeItem[] {
		const attributesAndFolders: IAdminAttributeTreeItem[] = [];
		attributesAndFolders.pushAll(this.getAttributeDefaultFolders());

		const folderMapper = this.getAttributeFolderMapper();
		attributes.forEach(attribute => attribute.parentId = folderMapper(attribute));
		attributesAndFolders.pushAll(attributes);
		return attributesAndFolders;
	}

	private populateUiStrings(attributesList: IExtendedAdminAttribute[]): void {
		attributesList.forEach((attribute) => {
			attribute.uiType = this.getUiType(attribute);
			let uiParents = this.getUiParents(attribute);
			if (uiParents) {
				attribute.uiParents = uiParents;
			}
		});
	}

	private populateHide<T extends keyof AssetVisibilityFlags>(items: (IExtendedAdminAttribute | IExtendedAdminModel)[],
		property: T, hideItems: IHiddenObject[], assetType: AssetType): void {
		const hideMap = {};
		hideItems
			.filter(item => assetType === AssetType.MODEL ? item.model : !item.model)
			.forEach(item => hideMap[item.id] = true);
		items.forEach((item) => {
			item[property] = !hideMap[item.id];
		});
	}


	private populateShowInReporting(items: (IExtendedAdminAttribute | IExtendedAdminModel)[],
		hideItems: IAsset[], assetType: AssetType): void {
		const hideMap = {};
		hideItems
			.filter(i => i.assetType === assetType)
			.forEach(i => hideMap[i.assetId] = true);
		items.forEach((item) => {
			item.showInReporting = !hideMap[item.id];
		});
	}

	private getUiType(attribute: IAdminAttribute): string | undefined {
		let type = attribute.type;
		if (type === undefined) return;
		//CONST_NAME to camelCase
		let localizationKey = type.toString()
			.toLowerCase()
			.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
		return attribute.derivedFromCategory
			? this.locale.getString('administration.derivedFromCategory')
			: this.locale.getString('administration.' + localizationKey);
	}

	private getUiParents(attribute: IAdminAttribute): string | undefined {
		if (attribute.derivedFromCategory) {
			return attribute.derivedNodeName;
		} else {
			let parents = attribute.parentAttributesNames;
			if (parents === undefined) return;
			return _.isArray(parents) ? parents.join() : parents;
		}
	}

	getAttributeDefaultFoldersOld(): IFolderItem[] {
		const nlpAttributes = new NLPAttributes(this.metricConstants);
		const systemMetrics = this.metricConstants.getSystemIntNumberAttributes();
		return [
			{
				id: OptionsConstant.NLP,
				displayName: this.locale.getString('widget.nlp'),
				name: this.locale.getString('widget.nlp'),
				type: FolderTypes.ATTRIBUTE,
				_collapsed: true,
				positionRank: 500,
				children: [
					{
						id: OptionsConstant.WORDS,
						displayName: this.locale.getString('widget.words'),
						name: this.locale.getString('widget.words'),
						type: FolderTypes.ATTRIBUTE,
						_collapsed: true,
						positionRank: 400,
						children: [],
						filter: (item) => nlpAttributes.words.indexOf(item.name) > -1
					},
					{
						id: OptionsConstant.ENRICHMENT,
						displayName: this.locale.getString('widget.enrichment'),
						name: this.locale.getString('widget.enrichment'),
						type: FolderTypes.ATTRIBUTE,
						_collapsed: true,
						positionRank: 300,
						children: [],
						filter: (item) => nlpAttributes.enrichment.indexOf(item.name) > -1
					},
					{
						id: OptionsConstant.LANGUAGE,
						displayName: this.locale.getString('widget.language'),
						name: this.locale.getString('widget.language'),
						type: FolderTypes.ATTRIBUTE,
						_collapsed: true,
						positionRank: 200,
						children: [],
						filter: (item) => nlpAttributes.language.indexOf(item.name) > -1
					},
					{
						id: OptionsConstant.CONVERSATION,
						displayName: this.locale.getString('widget.conversation'),
						name: this.locale.getString('widget.conversation'),
						type: FolderTypes.ATTRIBUTE,
						_collapsed: true,
						positionRank: 100,
						children: [],
						filter: (item) => nlpAttributes.conversation.indexOf(item.name) > -1
					}
				] as IFolderItem[],
				filter: (item) => nlpAttributes.root.indexOf(item.name) > -1
			},
			{
				id: OptionsConstant.TIME,
				displayName: this.locale.getString('widget.groupTimeOptions'),
				name: this.locale.getString('widget.groupTimeOptions'),
				type: FolderTypes.ATTRIBUTE,
				_collapsed: true,
				positionRank: 400,
				children: [],
				filter: (item) => item.type === AttributeType.DATE
			},
			{
				id: OptionsConstant.DERIVED_ATTRIBUTES,
				displayName: this.locale.getString('widget.derivedAttributes'),
				name: this.locale.getString('widget.derivedAttributes'),
				type: FolderTypes.ATTRIBUTE,
				_collapsed: true,
				positionRank: 300,
				children: [],
				filter: (item) =>
					item.derivedFromCategory
					|| item.type === AttributeType.RANGE
					|| item.type === AttributeType.DIMENSIONAL
					|| item.type === AttributeType.SENTIMENT_TRENDING
			}, {
				id: OptionsConstant.METRICS,
				displayName: this.locale.getString('widget.groupMetrics'),
				name: this.locale.getString('widget.groupMetrics'),
				type: FolderTypes.ATTRIBUTE,
				_collapsed: true,
				positionRank: 200,
				children: [],
				filter: (item) => systemMetrics.indexOf(item.name) > -1
			}
		];
	}

	private getAttributeDefaultFolders(): IFolderItem[] {
		return [
			{
				id: OptionsConstant.NLP,
				displayName: this.locale.getString('widget.nlp'),
				type: FolderTypes.ATTRIBUTE,
			},
			{
				id: OptionsConstant.TIME,
				displayName: this.locale.getString('widget.groupTimeOptions'),
				type: FolderTypes.ATTRIBUTE,
			},
			{
				id: OptionsConstant.DERIVED_ATTRIBUTES,
				displayName: this.locale.getString('widget.derivedAttributes'),
				type: FolderTypes.ATTRIBUTE,
			}, {
				id: OptionsConstant.METRICS,
				displayName: this.locale.getString('widget.groupMetrics'),
				type: FolderTypes.ATTRIBUTE,
			}, {
				id: OptionsConstant.WORDS,
				parentId: OptionsConstant.NLP,
				displayName: this.locale.getString('widget.words'),
				type: FolderTypes.ATTRIBUTE,
			},
			{
				id: OptionsConstant.ENRICHMENT,
				parentId: OptionsConstant.NLP,
				displayName: this.locale.getString('widget.enrichment'),
				type: FolderTypes.ATTRIBUTE,
			},
			{
				id: OptionsConstant.LANGUAGE,
				parentId: OptionsConstant.NLP,
				displayName: this.locale.getString('widget.language'),
				type: FolderTypes.ATTRIBUTE,
			},
			{
				id: OptionsConstant.CONVERSATION,
				parentId: OptionsConstant.NLP,
				displayName: this.locale.getString('widget.conversation'),
				type: FolderTypes.ATTRIBUTE,
			}
		];
	}

	private getAttributeFolderMapper(): (attribute: IAdminAttribute) => string {
		const nlpAttributes = new NLPAttributes(this.metricConstants);
		const systemMetrics = this.metricConstants.getSystemIntNumberAttributes();
		return (attribute: IAdminAttribute) => {
			if (nlpAttributes.root.indexOf(attribute.name) > -1) {
				return OptionsConstant.NLP;
			}
			if (nlpAttributes.words.indexOf(attribute.name) > -1) {
				return OptionsConstant.WORDS;
			}
			if (nlpAttributes.enrichment.indexOf(attribute.name) > -1) {
				return OptionsConstant.ENRICHMENT;
			}
			if (nlpAttributes.language.indexOf(attribute.name) > -1) {
				return OptionsConstant.LANGUAGE;
			}
			if (nlpAttributes.conversation.indexOf(attribute.name) > -1) {
				return OptionsConstant.CONVERSATION;
			}
			if (attribute.type === AttributeType.DATE) {
				return OptionsConstant.TIME;
			}
			if (attribute.derivedFromCategory
						|| attribute.type === AttributeType.RANGE
						|| attribute.type === AttributeType.DIMENSIONAL
						|| attribute.type === AttributeType.SENTIMENT_TRENDING) {
				return OptionsConstant.DERIVED_ATTRIBUTES;
			}
			if (systemMetrics.indexOf(attribute.name) > -1) {
				return OptionsConstant.METRICS;
			}
			return undefined;
		};
	}
}

app.service('adminAssetsManagement', downgradeInjectable(AdminAssetsManagementService));
