import * as _ from 'underscore';
import { ITreeItem, IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { Folders } from '@cxstudio/folders/folders-constant';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { AgGridNestable } from '@app/modules/object-list/types/ag-grid-nestable.interface';
import { AgGridNested } from '@app/modules/object-list/types/ag-grid-nested.interface';


export interface IFolderActions {
	CREATE_SUBFOLDER: ContextMenuItem<IFolderItem>;
	RENAME_FOLDER: ContextMenuItem<IFolderItem>;
	MOVE: ContextMenuItem<IFolderItem>;
	REMOVE_SUBFOLDER: ContextMenuItem<IFolderItem>;
	REMOVE_FOLDER: ContextMenuItem<IFolderItem>;
}

export class FolderContextMenuUtils {
	static readonly MAX_DEPTH: number = 5;
	static readonly FOLDER_REG = /.*folder.*/i;

	constructor(private locale: ILocale) {}

	getFolderMenuOptions(folder: IFolderItem,
		treeItems: ITreeItem[],
		actions: IFolderActions,
		moveFunction: (item: ITreeItem, folder: IFolderItem) => void): Array<ContextMenuItem<IFolderItem>> {
		let options = [];
		if (folder.level < FolderContextMenuUtils.MAX_DEPTH - 1) {
			options.push(actions.CREATE_SUBFOLDER);
		}

		options.push(actions.RENAME_FOLDER);

		let moveToItems = this.getFoldersForMove(treeItems, folder, moveFunction);

		if (moveToItems && moveToItems.length > 0) {
			options.push(this.extend(actions.MOVE, {items: moveToItems}));
		}

		options.push(BaseContextMenuUtils.MENU_DIVIDER);

		if (folder.level > 0) {
			options.push(actions.REMOVE_SUBFOLDER);
		} else {
			options.push(actions.REMOVE_FOLDER);
		}

		return BaseContextMenuUtils.enforceDividerRules(options);
	}

	getFoldersForMove = (array: ITreeItem[], item: ITreeItem, func, includeParent?: boolean): any[] => {
		let folders = [];

		let rootOption = {
			text: this.locale.getString('common.moveToRoot'),
			nameWithPath: ' ',
			func,
			obj: Folders.ROOT_FOLDER,
			pinBottom: true,
			name: 'place-at-root'
		};

		let depth: number = this.getDepth(item, 0);

		for (let arrayItem of array) {
			if (this.isAppropriateForMoveFolder(item, arrayItem, depth, includeParent, array)) {
				let folderItem = arrayItem as IFolderItem;
				folderItem.nameWithPath = this.getPath(folderItem, array);
				folders.push(
					{
						text: folderItem.name,
						func,
						obj: folderItem
					}
				);
			}
		}

		if (item.parentId > 0 || includeParent) {
			folders.push(rootOption);
		}

		return folders;
	};

	getPlainFoldersForMove = (array: ITreeItem[], item: ITreeItem): any[] => {
		let folders = [];

		let depth: number = this.getDepth(item, 0);

		for (let arrayItem of array) {
			if (this.isAppropriateForMoveFolder(item, arrayItem, depth, false, array)) {
				let folderItem = arrayItem as IFolderItem;
				folderItem.nameWithPath = this.getPath(folderItem, array);
				folders.push(folderItem);
			}
		}

		if (item.parentId > 0) {
			folders.push(Folders.ROOT_FOLDER);
		}

		return folders;
	};

	private isAppropriateForMoveFolder = (item: ITreeItem, moveToItem: ITreeItem,
		depth: number, includeParent: boolean = false, allItems: ITreeItem[]): boolean => {
		// ag grid doesn't have level
		let level = _.isUndefined((moveToItem as AgGridNested).path)
			? moveToItem.level
			: (moveToItem as AgGridNested).path.length - 1;
		return FolderContextMenuUtils.FOLDER_REG.test(moveToItem.type) // show only folders
			&& moveToItem.id as any !== FilterTypes.CXDATE // don't show dateFolder
			&& moveToItem.id as any !== DashboardType.FEEDBACK_FOLDER // don't show feedback shared folder
			&& (moveToItem.id !== item.parentId || includeParent) // don't show his current folder unless includeParent is true
			&& moveToItem !== item //don't show itself
			&& level < FolderContextMenuUtils.MAX_DEPTH - depth
			&& !this.isParent(item as IFolderItem, moveToItem, allItems);
	};

	private isParent = (folder: IFolderItem, item: ITreeItem, allItems: ITreeItem[]): boolean => {
		let parent = _.find(allItems, {id: item.parentId});
		while (parent) {
			if (parent === folder)
				return true;
			parent = _.find(allItems, {id: parent.parentId});
		}
		return false;
	};

	private getPath = (folder: IFolderItem, allItems: ITreeItem[]): string => {
		let parent = _.findWhere(allItems, {id: folder.parentId});
		let path = [folder.name];
		while (parent) {
			path.push(parent.name);
			parent = _.findWhere(allItems, {id: parent.parentId});
		}
		return path.reverse().join(' > ');
	};

	private getDepth = (item: any, currentLevel: number): number => {
		if (currentLevel >= FolderContextMenuUtils.MAX_DEPTH) {
			return FolderContextMenuUtils.MAX_DEPTH;
		}

		if (isEmpty(item.children)) {
			return FolderContextMenuUtils.FOLDER_REG.test(item.type) ? currentLevel + 1 : currentLevel;
		}

		return Math.max.apply(Math, item.children.map((child) => this.getDepth(child, currentLevel + 1)));
	};

	private extend(menuItem: any, settings: any): any {
		return angular.extend(angular.copy(menuItem), settings);
	}

}

app.service('folderContextMenuUtils', FolderContextMenuUtils);
