import { Component, OnInit, ChangeDetectionStrategy, Input, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CxLocaleService } from '@app/core';
import { PasswordPolicy } from '@app/modules/account-administration/password-policy/entities/password-policy';
import { TagsService } from '@app/modules/account-administration/properties/tags.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { CurrentMasterAccount } from '@cxstudio/auth/entities/current-master-account';
import { Security } from '@cxstudio/auth/security-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { LicenseLevel } from '@cxstudio/common/license-levels';
import { LicenseType } from '@cxstudio/common/license-types';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { UserApiService } from '@cxstudio/services/data-services/user-api-service';
import { ImpersonateUserService } from '@cxstudio/services/impersonate-service.service';
import { UserPermissionsService } from '@cxstudio/services/user-permissions-service.service';
import { AccountAccess } from '@cxstudio/user-administration/users/project-access/account-access-class';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as cloneDeep from 'lodash.clonedeep';
import { UserEditFormData } from '../editor/user-edit-form-data';
import { MasterAccountListItem, UserEditData } from '../editor/user-edit-data';
import { WorkspaceAccess } from '../editor/workspaces-projects-access/workspace-access';
import { WorkspaceProjectsApi } from '../editor/workspaces-projects-access/workspace-projects.api.service';
import { LicenseService } from '../license.service';
import { UserAccountsModalComponent } from '../projects-access/user-accounts-modal/user-accounts-modal.component';
import { UsersGroupsLogicService } from '../services/users-groups-logic.service';
import { SystemPermissionsService } from '../system-permissions/system-permissions.service';
import { ITransferDialogParams } from '../transfer/transfer-dialog.component';
import { TransferDialogService } from '../transfer/transfer-dialog.service';
import { TransferMode } from '../transfer/transfer-mode';
import { UserManagementHelperService } from '../user-management-helper.service';
import { UserModificationApiService } from '@app/modules/user-bulk/user-modification/user-modification-api.service';
import { Country } from '@app/modules/geolocation/geolocation.class';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';
import { UserManageApi } from '../editor/user-manage-api.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';

export interface IEditUserModalInput {
	userData: UserEditData;
	availableCountries: Country[];
	permissionItems: any[];
	passwordPolicy: {data: PasswordPolicy};
	licenseTypes;
	licenseInfo;
	goToTab: number;
}

@Component({
	selector: 'edit-user-modal',
	templateUrl: './edit-user-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditUserModalComponent implements OnInit {
	@Input() input: IEditUserModalInput;
	@ViewChild('userDialog', {static: false}) userDialog: NgForm;

	private initialUser: UserEditFormData;
	private defaultMasterAccount: MasterAccountListItem;

	private workspacesAccess: WorkspaceAccess[];

	activeTabIndex: number;
	options: any;
	currentMasterAccount: CurrentMasterAccount;
	user: any;
	passwordPolicy;
	pwdPattern;
	savedLinkedAccounts;
	currentLinkedAccounts;
	initialGroups;
	isCurrentUser: boolean;
	currentUserTags: any[];
	existingTags: string[];
	showPasswordControls: boolean = false;

	loadingCurrentUserAccountsIntersection;
	assignableLicenseTypes;
	selectedLicense: LicenseTypeItem;
	contentProvidersAccountsAccess;
	contentProvidersWithAccounts;
	showProjectAccessComponent;
	usersemailexist;
	editCustomField;
	loading;
	tagCustomClasses: Map<string, string>;

	availableCountries: Country[];

	// from modal input
	userData;
	permissionItems;
	inheritedPasswordPolicy;
	linkedAccounts;
	licenseTypes;
	licenseInfo;
	goToTab;

	constructor(
		private locale: CxLocaleService,
		private licenseService: LicenseService,
		private cxDialogService: CxDialogService,
		private userManagementHelper: UserManagementHelperService,
		private usersGroupsService: UsersGroupsLogicService,
		private systemPermissionsService: SystemPermissionsService,
		private transferDialogService: TransferDialogService,
		private workspaceProjectsApi: WorkspaceProjectsApi,
		private modal: NgbActiveModal,
		private tagService: TagsService,
		private ref: ChangeDetectorRef,
		private toastService: ToastService,
		private userManageApi: UserManageApi,
		private userModificationService: UserModificationApiService,
		private betaFeaturesService: BetaFeaturesService,
		@Inject('security') private security: Security,
		@Inject('impersonateUserService') private impersonateUserService: ImpersonateUserService,
		@Inject('userApiService') private userApiService: UserApiService,
		@Inject('masterAccountApiService') private masterAccountApiService: MasterAccountApiService
	) {}

	ngOnInit(): void {
		this.userData = this.input.userData;
		this.permissionItems = this.input.permissionItems;
		this.passwordPolicy = this.input.passwordPolicy.data || {};
		this.linkedAccounts = this.input.userData.linkedAccounts;
		this.licenseTypes = this.input.licenseTypes;
		this.licenseInfo = this.input.licenseInfo;
		this.goToTab = this.input.goToTab;

		this.tagCustomClasses = new Map<string, string>();

		this.availableCountries = this.input.availableCountries || [];

		this.defaultMasterAccount = this.searchMasterAccount(
			this.userData.linkedMasterAccounts, this.userData.user.defaultMasterAccountId);
		this.initialUser = this.toFormData(this.userData);
		this.user = cloneDeep(this.initialUser);

		this.workspacesAccess = [];

		this.initScopeObjects();
		this.user.tags.forEach(this.setTagClass);
	}

	private toFormData(userData: UserEditData): UserEditFormData {
		let applicationPermissions = userData.user.permissions.application;
		return {
			userId: userData.user.id,
			firstName: this.getNameOrEmpty(userData.user.firstName),
			lastName: this.getNameOrEmpty(userData.user.lastName),
			email: userData.user.email,
			countryCode: userData.user.countryCode,
			country: this.availableCountries.find(country => country.code === userData.user.countryCode),
			authUniqueId: userData.user.authUniqueId,
			xmAccountId: userData.user.xmAccountId,
			tags: userData.user.tags,

			defaultMasterAccountId: userData.user.defaultMasterAccountId,
			defaultMasterAccountName: this.getDefaultMasterAccountName(),
			linkedMasterAccounts: userData.linkedMasterAccounts,

			licenseTypeId: userData.user.licenseTypeId,
			customField: userData.user.customField,

			groups: userData.user.groups,

			systemAdministrator: applicationPermissions.systemAdmin,
			liteAdmin: applicationPermissions.liteAdmin,
			customerAdmin: userData.user.permissions.system.customerAdmin,
			studioApiUser: applicationPermissions.studioApiUser,
			platformApiUser: applicationPermissions.platformApiUser,
			downloadTrainingData: applicationPermissions.downloadTrainingData,
			manageInternalTemplates: applicationPermissions.manageInternalTemplates,
			shareInternalTemplates: applicationPermissions.shareInternalTemplates,
			createInternalTemplates: applicationPermissions.createInternalTemplates,
			permissions: userData.user.permissions.own,
			groupPermissions: userData.user.permissions.group,
			implicitPermissions: userData.user.permissions.implicit
		};
	}


	private initScopeObjects(): void {
		this.activeTabIndex = 0;

		if (this.goToTab > 0) {
			this.activeTabIndex = this.goToTab;
		}
		this.options = {};
		for (let permissionItem of this.permissionItems) {
			delete permissionItem.$$hashKey;
		}

		this.currentMasterAccount = this.security.getCurrentMasterAccount();
		this.options.customFieldName = this.currentMasterAccount.customField;

		this.options.permissions = this.permissionItems;
		this.pwdPattern = new RegExp('.*');
		if (this.passwordPolicy.requireSpecialChars) {
			this.pwdPattern = this.security.getPwdPattern();
		}

		this.savedLinkedAccounts = cloneDeep(this.linkedAccounts);
		this.currentLinkedAccounts = cloneDeep(this.linkedAccounts);

		this.initialGroups = cloneDeep(this.userData.user.groups);

		this.isCurrentUser = this.security.isCurrentUser(this.initialUser.email);

		let currentUser = this.security.getUser();

		this.currentUserTags = currentUser
			? currentUser.tags
			: [];
		this.existingTags = [];


		if (this.security.isAnyAdmin() && !this.security.isImpersonateMode()) {
			this.tagService.getTags('').then((tags) => {
				this.existingTags = tags;
			});
		}

		this.user.password = undefined;
		this.user.confirmPassword = undefined;

		this.user.filteredGroupPermissions = UserPermissionsService
			.getFilteredGroupPermissions(this.user, this.licenseTypes);
		this.user.calculatedPermissions = [];
		UserPermissionsService.refreshDerivedPermissions(this.user);

		let initialLicenseId = this.initialUser.licenseTypeId;
		this.assignableLicenseTypes = this.licenseService.getAssignableLicenseTypes(
			this.licenseTypes, this.licenseInfo, initialLicenseId);
		this.selectedLicense = _.findWhere(this.licenseTypes, {licenseTypeId: initialLicenseId});

		this.contentProvidersAccountsAccess = [];
		this.contentProvidersWithAccounts = [];
		this.showProjectAccessComponent = false;

		if (this.hasLinkedCP()) {
			this.loadingCurrentUserAccountsIntersection = this.userManagementHelper
				.getLinkedToCurrentUserAccounts(this.savedLinkedAccounts)
				.then((result) => {
					this.contentProvidersWithAccounts = result;
					this.showProjectAccessComponent = true;
				});
		}
	}

	defaultMasterAccountChanged($event): void {
		this.defaultMasterAccount = this.searchMasterAccount(this.user.linkedMasterAccounts, this.user.defaultMasterAccountId);
		if (!this.isInternalUserCurrently()) {
			this.user.manageInternalTemplates = false;
			this.user.shareInternalTemplates = false;
			this.user.createInternalTemplates = false;
			this.user.downloadTrainingData = false;
		}
	}

	isCurrentUserAnyAdmin = () => {
		return this.security.isAnyAdmin();
	};

	isUserSyncControlDisabled = (): boolean => {
		return this.security.isUserSyncingEnabled() && !(this.isAccountOwnerOrAnyAdmin());
	};

	hasManageGroups = () => {
		return this.security.has('manage_groups');
	};

	isInternalUser = (): boolean => {
		return this.isInternalUserEntity(this.initialUser);
	};

	isAccountOwner = (): boolean => {
		return this.security.has('account_owner');
	};

	isAccountOwnerOrAnyAdmin = (): boolean => {
		return this.security.isAccountOwner() || this.security.isAnyAdmin();
	};

	isXmAccountIdEnabled = (): boolean => {
		return this.security.isAccountOwner() || this.security.isAnyAdmin() || this.security.isCustomerAdmin();
	};

	showCustomField = (): boolean => {
		return this.isAccountOwner() || !!this.options.customFieldName;
	};

	updateCustomFieldName = (customFieldName: string): void => {
		this.options.customFieldName = customFieldName;
	};

	updateCustomFieldValue = (customFieldValue: string): void => {
		this.user.customField = customFieldValue;
	};

	/**
	 * @returns true, if current selected default master account is internal account
	 */
	isInternalUserCurrently = (): boolean => {
		return this.isInternalUserEntity(this.user);
	};

	isCurrentUserInternal = (): boolean => {
		return this.security.isAdminOrgUser();
	};

	isNeedPasswordControls = (): boolean => {
		return this.defaultMasterAccount?.accountId === this.currentMasterAccount?.accountId
			&& this.defaultMasterAccount?.allowPasswords;
	};

	isNeedAuthUniqueId = (): boolean => {
		return this.defaultMasterAccount?.authByUniqueId;
	};

	isNeedXmAccountId = (): boolean => {
		return this.defaultMasterAccount?.qualtricsIntegrationEnabled || this.defaultMasterAccount?.isAdminOrg;
	};

	setTagClass = (tag: string): void => {
		let tagAsObject = this.tagService.stringToTagObject(tag);
		this.tagCustomClasses[tag] = this.tagService.getDisableTagClass(tagAsObject, (oTag) => {
			if (this.isCurrentUser) {
				return !this.isNewTag(oTag.text);
			}
			return true;
		});
	};

	verifyTagAllowed = (tagText: string): boolean => {
		let tagAllowed = true;
		if (this.isCurrentUser) {
			tagAllowed = this.isNewTag(tagText);
		} else {
			tagAllowed = this.existingTags.contains(tagText)
				&& this.currentUserTags.contains(tagText);
		}
		return tagAllowed;
	};

	disableTagsInput = (): boolean => {
		return this.security.isImpersonateMode();
	};

	linkToCP = (): void => {
		let dlg = this.cxDialogService.openDialog(UserAccountsModalComponent,
			{ linkedAccounts: this.currentLinkedAccounts },
			{ class: 'br-create-user-accounts-dialog' });

		dlg.result.then((linkedAccountsResult) => {
			this.currentLinkedAccounts = linkedAccountsResult;
		}, _.noop);
	};

	clearLastApplicationState = (): void => {
		this.userApiService.clearLastApplicationState(this.user.userId).then(() => {
			this.toastService.addToast(this.locale.getString('administration.lastApplicationStateCleared'), AlertLevel.SUCCESS);
		});
	};

	validateUniqueId = (): void => {
		let userUniqueId = this.user.authUniqueId;
		if (!userUniqueId) return;

		if (this.initialUser.authUniqueId === userUniqueId) {
			this.userDialog?.controls.authUniqueId.setErrors(null);
			return;
		}

		this.userApiService.checkUniqueIdExists(userUniqueId).then((response) => {
			this.userDialog?.controls.authUniqueId.setErrors(response.exist ? { uniqueIdExists: true } : null);
		});
	};

	isUniqueIdValid = (): boolean => {
		return !this.userDialog?.form?.controls?.authUniqueId?.errors ||
				(!this.userDialog?.form?.controls?.authUniqueId?.errors.pattern &&
				!this.userDialog?.form?.controls?.authUniqueId?.errors.required &&
				!this.userDialog?.form?.controls?.authUniqueId?.errors.minlength);
	};

	validateXmAccountId = (xmAccountId: string): void => {

		if (!xmAccountId) return;

		this.userDialog?.controls.xmAccountId.setErrors(null);

		if (this.initialUser.xmAccountId === xmAccountId) {
			return;
		}

		this.userApiService.checkXmAccountIdExists(xmAccountId).then((response) => {
			if (response.exist) {
				this.userDialog?.controls.xmAccountId.setErrors({ xmAccountIdExists: true });
			}

			this.ref.markForCheck();
		});
	};

	isXmAccountIdValid(): boolean {
		return this.userDialog?.controls.xmAccountId?.pristine
			|| !this.userDialog?.form?.controls?.xmAccountId?.errors?.xmAccountIdExists;
	}

	impersonateUser = (): void => {
		this.impersonateUserService.impersonateUser(this.user);
	};

	grantPlatformApiUser = (): void => {
		if (isFalse(this.user.platformApiUser)) {
			return;
		}

		let dialogHeader = this.locale.getString('administration.platformApiUser');
		let needToLinkToCP = this.locale.getString('administration.unableToGrantPlatformApiUser');
		let grantPlatformApiUser = this.locale.getString('administration.grantPlatformApiUser');

		let declineChanges = (): void => {
			this.user.platformApiUser = false;
		};

		let acceptChanges = (): void => {
			this.user.platformApiUser = true;
		};

		if (!this.hasLinkedCP()) {
			this.cxDialogService
				.notify(dialogHeader, needToLinkToCP)
				.result
				.then(declineChanges, declineChanges);
		} else {
			this.cxDialogService.regularWithConfirm(dialogHeader, grantPlatformApiUser,
				this.locale.getString('common.continue'), this.locale.getString('common.cancel')).
				result.then(acceptChanges, declineChanges);
		}
	};

	isShowImpersonateUserButton = (): boolean => {
		return this.impersonateUserService.canImpersonateUser(this.user);
	};

	save = (): void => {
		if (this.userDialog.valid) {
			this.loading = this.validateEmailExistence()
				.then(this.checkAdminChangesAndConfirm)
				.then(this.checkLicenseTypeChange)
				.then(this.saveChanges)
				.then(_.noop, () => {
					this.loading = null;
				});
		}
	};

	cancel = (): void => {
		if (!this.hasUserChanged()) {
			this.modal.dismiss('cancel');

			return;
		}

		this.cxDialogService.showUnsavedChangesDialogAndResolve(
			this.save,
			this.modal.dismiss,
			'administration.unsavedUserChangesHeader',
			'administration.unsavedUserChangesBody'
		).catch(() => {});
	};

	// permissions functions refactored into separate service
	// refine further when time allows
	handleLicenseChange = (): void => {
		this.selectedLicense = _.findWhere(this.licenseTypes, {licenseTypeId: this.user.licenseTypeId});
		UserPermissionsService.clearRestrictedPermissions(this.user, this.licenseTypes);
		UserPermissionsService.grantLicensePermissions(
			this.user, this.licenseTypes, this.options.permissions);
	};

	permissionsChangedCallback = (selectedPermissions): void => {
		this.user.permissions = selectedPermissions && selectedPermissions.selectedPermissions;
		UserPermissionsService.refreshDerivedPermissions(this.user);
	};

	customerAdminChangedCallback = (user: UserEditFormData): void => {
		if (!user.customerAdmin) {
			this.handleLicenseChange();
		}
	};

	isWorkspaceEnabled = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
	};

	hasLinkedCP = (): boolean => {
		return this.selectedLicense?.licenseLevel !== LicenseLevel.VIEWER
			&& this.savedLinkedAccounts
			&& Object.keys(this.savedLinkedAccounts).length > 0;
	};

	isCPLinkingAllowed = (): boolean => {
		// Do not let customer admins to edit their own data access
		if (this.userManagementHelper.isSelfCustomerAdmin(this.initialUser.userId)) {
			return false;
		}

		let license = _.findWhere(this.licenseTypes, {
			licenseTypeId: this.user.licenseTypeId
		});

		return this.licenseService.isCPLinkingAllowed(license);
	};

	changeProjectsAccess = (updatedAccessLevel): void => {
		this.contentProvidersAccountsAccess = updatedAccessLevel;
	};

	updateWorkspacesAccess = (updatedAccessLevel: WorkspaceAccess[]): void => {
		this.workspacesAccess = updatedAccessLevel;
	};

	getTagsSuggestion = (filterQuery: string): Promise<string[]> => {
		return this.tagService.getTags(filterQuery)
			.then((tags) => this.tagService.filterTagsSuggestionsByUserTags(tags));
	};

	getCountLabel = (licenseTypeId: number): string => {
		if (!this.isNeedToCountLicenses() || licenseTypeId === this.initialUser.licenseTypeId) {
			return '';
		}
		return this.licenseService.getCountLabel(licenseTypeId, this.licenseInfo);
	};

	licenseLimitExceeded = (): boolean => {
		if (!this.isNeedToCountLicenses() || this.user.licenseTypeId === this.initialUser.licenseTypeId) {
			return false;
		}
		return this.licenseService.licenseLimitExceeded(
			this.licenseInfo, this.user.licenseTypeId);
	};

	getLicenseExceededMessage = (): string => {
		return this.licenseService.getLicenseExceededMessage(
			this.user.licenseTypeId, this.licenseTypes);
	};

	invalidDefaultMA = (): boolean => {
		let anyAdmin = this.user.systemAdministrator || this.user.liteAdmin;
		return anyAdmin && !this.isInternalUserCurrently();
	};

	disableSave = (): boolean => {
		return this.userDialog?.invalid
			|| this.licenseLimitExceeded()
			|| this.invalidDefaultMA()
			|| this.missingUserDefaultMA()
			|| this.isFirstNameInvalid()
			|| this.isLastNameInvalid()
			|| !!this.loading;
	};

	missingUserDefaultMA = (): boolean => {
		return _.isUndefined(this.user.defaultMasterAccountId) ||
			!_.any(this.user.linkedMasterAccounts, (masterAccount) => {
				return masterAccount.accountId === this.user.defaultMasterAccountId;
			});
	};

	hasCustomerPermissions = (): boolean => {
		return this.systemPermissionsService.hasCustomerPermissions();
	};

	onCountryChange = (country: Country) => {
		this.user.countryCode = country.code;
		this.user.country = country;
	};

	private searchMasterAccount(masterAccounts: MasterAccountListItem[], id: number): MasterAccountListItem {
		return _.findWhere(masterAccounts, { accountId: id});
	}

	private getDefaultMasterAccountName = (): string => {
		return this.defaultMasterAccount?.accountName;
	};

	private getNameOrEmpty(name: string): string {
		return (name && name.trim().length !== 0) ? name : '';
	}

	private isInternalUserEntity = (entity: UserEditFormData): boolean => {
		let userDefaultMasterAccount = this.searchMasterAccount(entity.linkedMasterAccounts, entity.defaultMasterAccountId);
		return userDefaultMasterAccount?.isAdminOrg;
	};

	private isNewTag = (tagText: string): boolean => {
		return this.existingTags && this.existingTags.length
			? !this.existingTags.contains(tagText)
			: true;
	};

	private validateEmailExistence = (): Promise<void> => {
		let promise = (this.initialUser.email === this.user.email)
			? Promise.resolve({exist: false})
			: this.userApiService.checkEmailExists(this.user.email) as Promise<any>;

		return promise.then((data) => {
			if (!data.exist) {
				return;
			} else {
				this.usersemailexist = data.exist;
				return Promise.reject();
			}
		}, _.noop);
	};

	private checkAdminChangesAndConfirm = (): Promise<void> => {
		let confirmationPromise = Promise.resolve();
		let sysAdminChanged = this.initialUser.systemAdministrator !== this.user.systemAdministrator;
		let liteAdminChanged = this.initialUser.liteAdmin !== this.user.liteAdmin;

		let grantedSysAdmin = sysAdminChanged && this.user.systemAdministrator;
		let revokedSysAdmin = sysAdminChanged && !this.user.systemAdministrator;

		let grantedLiteAdmin = liteAdminChanged && this.user.liteAdmin;

		if (sysAdminChanged || liteAdminChanged) {
			let message;
			if (grantedSysAdmin) {
				message = this.locale.getString('administration.grantSysAdminConfirm');
			} else {
				if (revokedSysAdmin) {
					if (grantedLiteAdmin || this.user.liteAdmin) {
						//if given lite admin, or already had it, show message about being removed from external MAs
						message = this.locale.getString('administration.expireExternalMasterAccountsConfirm',
							{defaultMAName: this.user.defaultMasterAccountName});
					} else {
						message = this.locale.getString('administration.revokeSysAdminConfirm',
							{defaultMAName: this.user.defaultMasterAccountName});
					}
				} else {
					// sys admin wasnt changed, so check lite admin in conjunction with sys admin state
					if (!this.user.systemAdministrator) {
						//if they are already sys admin, changing lite admin affects nothing so don't show dialog
						message = this.user.liteAdmin
							? this.locale.getString('administration.grantLiteAdminConfirm')
							: this.locale.getString('administration.revokeSysAdminConfirm',
								{defaultMAName: this.user.defaultMasterAccountName});
					}
				}
			}
			if (message && message.length) {
				confirmationPromise = this.cxDialogService.regularWithConfirm(
					this.locale.getString('common.pleaseConfirm'), message).result;
			}
		}

		return confirmationPromise;
	};

	private checkLicenseTypeChange = (): Promise<void> => {
		let confirmationPromise = Promise.resolve();
		if (this.initialUser.licenseTypeId !== this.user.licenseTypeId) {
			if (this.selectedLicense.licenseLevel === LicenseLevel.VIEWER) {
				let input: ITransferDialogParams = {
					user: this.user,
					mode: TransferMode.SINGLE,
					note: this.locale.getString('administration.downgradeLicenseNote'),
					confirmButton: this.locale.getString('userAdministration.downgradeUserAndTransferObjects')
				};
				let dialog = this.transferDialogService.open(input);
				confirmationPromise = dialog.result as Promise<void>;
			}
		}
		return confirmationPromise;
	};

	private getChangedAccounts = (): any[] => {
		let changedCpAccounts = [];
		for (let cpAccess of this.contentProvidersAccountsAccess) {
			let changedAccounts = _.filter(cpAccess.accounts, (account: AccountAccess) => {
				return account.changed;
			});
			if (changedAccounts.length) {
				cpAccess.accounts = changedAccounts;
				changedCpAccounts.push(cpAccess);
			}
		}
		return changedCpAccounts;
	};

	private saveChanges = (): Promise<any> => {
		let userUpdateData = this.userManagementHelper.getUserUpdateRequestData(this.user);
		if (!this.userDialog.controls.password?.dirty) {
			delete userUpdateData.password;
		}

		delete this.editCustomField;

		let updateUserPromise = this.userManageApi.updateUser(this.initialUser.userId, userUpdateData).then(() => {
			if (this.user.forceRegistration) {
				return PromiseUtils.wrap(this.userApiService.resetPassword({ userEmail: this.initialUser.email }));
			}
		});

		let updatePromises: Array<PromiseLike<any>> = [updateUserPromise];

		if (this.customFieldNameChanged()) {
			let customFieldNamePromise = this.masterAccountApiService.updateCustomFieldName(this.options.customFieldName) as Promise<any>;

			customFieldNamePromise.then(() => {
				this.currentMasterAccount.customField = this.options.customFieldName;
			});

			updatePromises.push(customFieldNamePromise);
		}

		if (this.initialGroups && this.user.groups && this.initialGroups !== this.user.groups) {
			let addedGroups = this.usersGroupsService.findAddedGroups(this.initialGroups, this.user.groups);
			let removedGroups = this.usersGroupsService.findRemovedGroups(this.initialGroups, this.user.groups);

			if (addedGroups.length || removedGroups.length) {
				let promise = this.userModificationService.updateGroupsForUser(this.user.userId, {
					added: addedGroups,
					removed: removedGroups
				}) as Promise<any>;
				updatePromises.push(promise);
			}
		}

		return Promise.all(updatePromises).then(() => {
			let licenseTypeId = this.user.licenseTypeId;
			let isValidLicenseType =
				licenseTypeId === LicenseType.ANALYZE
					|| licenseTypeId === LicenseType.CX_STUDIO
					|| licenseTypeId === LicenseType.CX_STUDIO_AND_CM;

			if (!isValidLicenseType) {
				this.modal.close();
				return;
			}

			if (!this.isWorkspaceEnabled()) {
				this.modal.close(this.getLinkingInfo());
			}

			let updateChainPromise = this.workspacesAccess.reduce((p, wa) => {
				return p.then(() => this.workspaceProjectsApi.updateProjectsAccess(
					wa.workspaceId, this.user.userId, wa) as unknown as Promise<void>);
			}, Promise.resolve());
			return updateChainPromise.then(() => {
				this.modal.close();
			});
		});
	};

	private getLinkingInfo = (): any => {
		let changedAccounts = this.getChangedAccounts();
		let projectsAccess = {
			cpAccounts: changedAccounts
		};
		let licenseTypeId = this.user.licenseTypeId;

		let diff = this.userManagementHelper.getLinkedAccountsChange(
			this.currentLinkedAccounts, this.savedLinkedAccounts);

		return {
			userId: this.initialUser.userId,
			initialLicenseTypeId: this.initialUser.licenseTypeId,
			currentLicenseTypeId: licenseTypeId,
			addedAccounts: diff.added,
			remainedAccounts: diff.remained,
			removedAccounts: diff.removed,
			projectsAccess,
			initialMasterAccountId: this.initialUser.defaultMasterAccountId,
			currentMasterAccountId: this.user.defaultMasterAccountId
		};
	};

	private customFieldNameChanged = (): boolean => {
		return this.isAccountOwner() && this.currentMasterAccount.customField !== this.options.customFieldName;
	};

	private hasUserChanged = (): boolean => {
		let userForComparison = cloneDeep(this.user);

		//clean uncomparable fields
		delete userForComparison.password;
		delete userForComparison.confirmPassword;
		delete userForComparison.filteredGroupPermissions;
		delete userForComparison.calculatedPermissions;

		// forceRegistration/reset pw is irrelevant if it's not checked
		if (!userForComparison.forceRegistration) {
			delete userForComparison.forceRegistration;
		}


		let linkedAccountsDiff = this.userManagementHelper.getLinkedAccountsChange(
			this.currentLinkedAccounts, this.savedLinkedAccounts);
		let hasAccountsChanged: boolean = linkedAccountsDiff &&
			((linkedAccountsDiff.added && !Object.keys(linkedAccountsDiff.added).isEmpty()) ||
				(linkedAccountsDiff.removed && !Object.keys(linkedAccountsDiff.removed).isEmpty()));

		return (!_.isEqual(userForComparison, this.initialUser) || hasAccountsChanged);
	};

	private isNeedToCountLicenses = (): boolean => {
		return this.licenseService.isNeedToCountLicenses(
			this.licenseInfo, this.user, this.security.getMasterAccountId());
	};

	togglePasswordControls = (): void => {
		this.showPasswordControls = !this.showPasswordControls;
		this.user.password = undefined;
		this.user.confirmPassword = undefined;
	};

	getPasswordControlLabel = (): string => {
		return this.showPasswordControls ? this.locale.getString('administration.clearChangePassword')
			: this.locale.getString('administration.changePassword');
	};

	onPasswordResetChange = (resetPassword: boolean): void => {
		if (resetPassword) {
			this.showPasswordControls = false;
			this.user.password = undefined;
			this.user.confirmPassword = undefined;
		}
	};

	private isNameInvalid(field: 'firstName' | 'lastName'): boolean {
		const control = this.userDialog?.controls[field];
		if (!control) {
			return false;
		}
		return control.invalid || this.usersGroupsService.isUnsafeName(control.value);
	}

	isFirstNameInvalid = (): boolean => {
		return this.isNameInvalid('firstName');
	};

	isLastNameInvalid = (): boolean => {
		return this.isNameInvalid('lastName');
	};
}
