import { Component, OnInit, ChangeDetectionStrategy, Inject, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { GroupDialogMode } from '@app/modules/user-administration/groups/group-dialog-mode';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { UsersGroupsApiService } from '@cxstudio/services/data-services/users-groups-api.service';
import GroupUserSearchResult from '@cxstudio/user-administration/groups/group-user-search-result';
import { User } from '@cxstudio/user-administration/users/entities/user';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';


@Component({
	selector: 'group-members',
	templateUrl: './group-members.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupMembersComponent implements OnInit, OnChanges {
	readonly CURRENT_USERS_DISPLAY_LIMIT = 100;

	@Input() groupId: number;
	@Input() operation: GroupDialogMode;
	@Input() hierarchyGroup: boolean;
	@Input() ownerEmail: string;

	@Output() addedUsersChange = new EventEmitter<User[]>();
	@Output() removedUsersChange = new EventEmitter<User[]>();

	search: string;
	users: {
		available: User[];
		current: User[];
		added: User[];
		removed: User[];
		addedFiltered: User[];
		currentFiltered: User[];
	};
	membersCount: number = null;

	searchPromise: Promise<any>;

	constructor(
		private locale: CxLocaleService,
		@Inject('usersGroupsApiService') private usersGroupsApiService: UsersGroupsApiService,
	) { }

	ngOnInit(): void {
		this.search = '';
		this.users = {
			current: [],
			available: [],
			added: [],
			removed: [],
			addedFiltered: [],
			currentFiltered: [],
		};

		this.updateSearch();
		this.searchGroupMemberCandidates('');
	}

	ngOnChanges(changes: SimpleChanges<GroupMembersComponent> ): void {
		if (ChangeUtils.hasChange(changes.ownerEmail)) {
			let newOwnerEmail = changes.ownerEmail.currentValue;
			if (newOwnerEmail) {
				this.addUser(newOwnerEmail);
			}
			let previousOwnerEmail = changes.ownerEmail.previousValue;
			let previousOwnerInAddedList = _.findWhere(this.users.added, { userEmail: previousOwnerEmail });
			if (previousOwnerInAddedList) {
				this.removeUserFromAdded(previousOwnerInAddedList);
			}
		}
	}

	isEditable(): boolean {
		return !this.hierarchyGroup;
	}

	updateSearch = (): void => {
		this.users.addedFiltered = this.filterUsers(this.users.added, this.search);
		this.users.currentFiltered = this.filterUsers(this.users.current, this.search);
	};

	membersSuggestions = (text$: Observable<string>) => {
		return text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			switchMap(searchText => {
				return this.getAvailableUsers(searchText)
					.then((users) => {
						return users.length === 0 ?
							[this.locale.getString('common.noMatchedItems')] :
							users.map(user => user.displayUsername);
					});
			})
		);
	};

	removeUserFromGroup = (user): void => {
		if (!user)
			return;

		this.users.removed.push(user);
		this.users.current.remove(user);
		this.updateSearch();
		this.removedUsersChange.emit(this.users.removed);
	};

	removeUserFromAdded = (user): void => {
		if (!user)
			return;

		this.users.available.push(user);
		this.users.added.remove(user);
		this.updateSearch();
		this.addedUsersChange.emit(this.users.added);
	};

	canAddUser(userEmail: string): boolean {
		return this.isUserValidForAddition(userEmail) && this.isEditable();
	}

	addUser = (userEmail: string): void => {
		userEmail = this.extractMatchedUser(userEmail);
		if (!this.canAddUser(userEmail)) {
			return;
		}

		let removedUser = _.findWhere(this.users.removed, { userEmail });
		if (removedUser) {
			this.users.current.push(removedUser);
			this.users.removed.remove(removedUser);
			this.search = '';
			this.updateSearch();
			this.removedUsersChange.emit(this.users.removed);
		} else {
			let user = _.findWhere(this.users.available, { userEmail });
			if (user) {
				this.users.added.push(user);
				this.users.available.remove(user);
				this.search = '';
				this.updateSearch();
				this.addedUsersChange.emit(this.users.added);
			}
		}
	};

	private filterUsers = (users: User[], filterText: string): User[] => {
		if (_.isUndefined(filterText)) {
			return users;
		}

		let searchText = this.removeTrailingSpacesSearchText(filterText).toLowerCase();

		return _.filter(users, (user) => {
			return (user.fullName && user.fullName.toLowerCase().includes(searchText))
				|| (user.licenseTypeName && user.licenseTypeName.toLowerCase().includes(searchText))
				|| (user.userEmail && user.userEmail.toLowerCase().includes(searchText));
		});
	};

	getAvailableUsers = (search: string): Promise<User[]> => {
		search = this.removeTrailingSpacesSearchText(search);
		return this.searchGroupMemberCandidates(search).then(() => {
			if (this.isEditable()) {
				let availableUsers: User[] = [];
				availableUsers.pushAll(this.users.available);
				availableUsers.pushAll(this.users.removed);

				availableUsers.sort((left, right) => left.userEmail.localeCompare(right.userEmail));
				const searchLowercase = search?.toLowerCase();
				availableUsers = availableUsers.filter((user) => {
					return (user.fullName && user.fullName.toLowerCase().includes(searchLowercase))
						|| (user.userEmail && user.userEmail.toLowerCase().includes(searchLowercase));})
					.map((user) => {
						return {...user,
							displayUsername: `${user.fullName} (${user.userEmail})`};
					});

				return availableUsers.length > 20
					? availableUsers.slice(0, 20)
					: availableUsers;
			} else {
				return [];
			}
		});
	};

	private searchGroupMemberCandidates = (filter: string): Promise<void> => {
		let promise = this.operation === GroupDialogMode.ADD
			? PromiseUtils.wrap(this.usersGroupsApiService.searchNewGroupMemberCandidates(filter, 100))
				.then((candidates: User[]) => {
					candidates.forEach(this.addCandidate);
				})
			: PromiseUtils.wrap(this.usersGroupsApiService.searchGroupMemberCandidates(this.groupId, filter, 100))
				.then((searchResult: GroupUserSearchResult) => {
					searchResult.candidates.forEach(this.addCandidate);
					searchResult.members.forEach(this.addMember);
					this.setMembersCount(searchResult.membersCount);

					this.updateSearch();
				});

		this.searchPromise = promise;
		return promise;
	};

	private addCandidate = (candidate: User): void => {
		if (!_.findWhere(this.users.available, { userEmail: candidate.userEmail })
				&& !_.findWhere(this.users.added, { userEmail: candidate.userEmail })) {
			candidate.fullName = `${candidate.firstName} ${candidate.lastName}`;
			this.users.available.push(candidate);
		}
	};

	private addMember = (member: User): void => {
		if (!_.findWhere(this.users.removed, { userEmail: member.userEmail })
				&& !_.findWhere(this.users.current, { userEmail: member.userEmail })) {
			member.fullName = `${member.firstName} ${member.lastName}`;
			this.users.current.push(member);
		}
	};

	private setMembersCount = (count: number): void => {
		this.membersCount = count;
	};

	private removeTrailingSpacesSearchText = (search: string): string => {
		return search.trim();
	};

	/**
	 * Check if exact (case-insensitive) email address exists in a group of users
	 */
	private isUserInGroup(currentUsers: User[], email: string): boolean {
		return email && currentUsers.some(u => u.userEmail.toLowerCase() === email.toLowerCase());
	}

	private extractMatchedUser(userEmail: string): string {
		let matchedUserEmail = userEmail.match(/\(([^(]+?)\)$/);
		if (matchedUserEmail !== null) {
			return matchedUserEmail[1];
		}
		return userEmail;
	}
	isUserValidForAddition = (userEmail: string): boolean => {
		userEmail = this.extractMatchedUser(userEmail);
		return !this.isUserInGroup(this.users.current, userEmail)
			&& (!!_.findWhere(this.users.available, { userEmail }) || !!_.findWhere(this.users.removed, { userEmail }));
	};

	getMoreUsersText = (): string => {
		return this.locale.getString('common.nMore', { n: this.membersCount - this.users.currentFiltered.length });
	};

	onNoMatches = (event: NgbTypeaheadSelectItemEvent): void => {
		if (event.item === this.locale.getString('common.noMatchedItems')) {
			event.preventDefault();
		}
	};

}

app.directive('groupMembers', downgradeComponent({component: GroupMembersComponent}));
