import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { ColorPaletteNames } from '@cxstudio/reports/coloring/color-palette-constants.service';
import ChartType from '@cxstudio/reports/entities/chart-type';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import { DatePeriodName } from '@cxstudio/reports/entities/date-period';
import { IDataPoint, IDataPointObject } from '@cxstudio/reports/entities/report-definition';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { IRGBColor } from '@cxstudio/reports/utils/color-utils.service';
import { CalculationColorUtils } from '@cxstudio/reports/utils/color/calculation-color-utils';
import { GroupColorUtils } from '@cxstudio/reports/utils/color/group-color-utils';

export class ColorUtilsHelper {
	static readonly CONTRAST_TEXT_COLORS: string[] = ['#43444f', '#ebebee', '#babbc5', '#babbc5', '#43444f', '#292930', '#ffffff'];
	static readonly DEFAULT_CUSTOM_COLOR = '#3F51B5';
	static readonly POP_GROUP: string = '_pop';
	static readonly CSS_VAR_FORMAT: RegExp = /--([A-Za-z\-0-9]+)/;

	static checkForRecolor = (item: IDataPoint, options: VisualProperties, nameObject, groupFormatter?, focusSeries?): string => {
		let displayName = nameObject.displayName;
		let recolors = options.recolors as any[];
		if (recolors && recolors.length) {
			if (!item.object) {
				let recolorItem = _.findWhere(recolors, {uniqueName: item.name});
				return recolorItem ? ColorUtilsHelper.getRecoloredLegendLabel(recolorItem.uniqueName,
					nameObject.periodSuffix, options, recolorItem) : displayName;
			} else {
				let identifier = item.object._group.identifier;
				//check for metric
				let metricRecolorItem = _.find(recolors, ColorUtilsHelper.findMatchedRecolor(item.object));

				if (metricRecolorItem) {
					let label = AnalyticMetricTypes.isTime(item.object._group as any)
						? item.object._name
						: metricRecolorItem.uniqueName;
					let suffix = nameObject.periodSuffix;
					if (groupFormatter && groupFormatter[identifier]) {
						//there is some formatter
						label = groupFormatter[identifier](label);
						suffix = groupFormatter[identifier](suffix);
					}
					return ColorUtilsHelper.getRecoloredLegendLabel(label, suffix, options, metricRecolorItem);
				} else if (focusSeries && focusSeries.name) {
					return focusSeries.name;
				} else if (groupFormatter && item.object
					&& item.object[identifier]
					&& groupFormatter[identifier]) {

					return groupFormatter[identifier](displayName);
				}
			}
		} else if (groupFormatter && item.object &&
			item.object[item.object._group.identifier] &&
			groupFormatter[item.object._group.identifier]) {

			return groupFormatter[item.object._group.identifier](displayName);
		}

		return displayName;
	};

	static findMatchedRecolor = (point: Partial<IDataPointObject>) => {
		return (item) => {
			return point._group && _.isEqual(item.uniqueName, point[point._group.identifier])
				&& _.isEqual(item.colorType, (point.colorType || ColorTypes.PRIMARY))
				&& ColorUtilsHelper.checkPopLineRecolor(point, item)
				&& (item.groupIdentifier
					? _.isEqual(item.groupIdentifier, point._group.identifier)
					: _.isEqual(item.groupName, point._group.name));
		};
	};

	private static checkPopLineRecolor(point, recolor): boolean {
		//line recolor need to check _pop, (values: period_1_/undefined or period_2_)
		return !ColorUtilsHelper.isPointColor(recolor) || ColorUtilsHelper.isPopPoint(recolor) === ColorUtilsHelper.isPopPoint(point);
	}

	static getColorFields = (): string[] => {
		return [ColorTypes.PRIMARY, ColorTypes.SECONDARY];
	};

	static getPointColorFields = (): string[] => {
		return [ColorTypes.POINT, ColorTypes.SECONDARY_POINT];
	};

	private static getRecoloredLegendLabel(label: string, periodSuffix: string, options: VisualProperties, recolor): string {

		if (!periodSuffix || !options) {
			return label;
		}

		//Don't add period suffix if its lighten and not a PoP color
		if (options.popColor !== 'lighten' || ColorUtilsHelper.isPopColor(recolor)) {
			label += ' - ' + periodSuffix;
		}

		if (ColorUtilsHelper.isPointColor(recolor) && ColorUtilsHelper.isPopPoint(recolor)) {
			label += ' - ' + periodSuffix;
		}
		return label;
	}

	private static isPopPoint(point): boolean {
		return point._pop === DatePeriodName.PERIOD2;
	}

	private static isPointColor(point): boolean {
		return ColorUtilsHelper.getPointColorFields().indexOf(point.colorType) > -1;
	}

	private static isPopColor(point): boolean {
		return [ColorTypes.PERIOD_OVER_PERIOD, ColorTypes.SECONDARY_PERIOD_OVER_PERIOD].indexOf(point.colorType) > -1;
	}

	static pickContrastColor = (baseHex, contrastOptions = []): string => {
		if (!baseHex)
			return undefined;
		if (ColorUtilsHelper.isCssVar(baseHex)) {
			let cssVar = ColorUtilsHelper.CSS_VAR_FORMAT.exec(baseHex)[0];
			baseHex = getComputedStyle(document.body).getPropertyValue(cssVar).trim();
		}
		let baseRGB = ColorUtilsHelper.hexToRGB(baseHex);
		let contrastColors = contrastOptions.map(contrast => ColorUtilsHelper.hexToRGB(contrast));
		let contrastValues = contrastColors.map(option => ColorUtilsHelper.getContrast(baseRGB, option));

		let maxContrastIndex = _.indexOf(contrastValues, _.max(contrastValues));
		return contrastOptions[maxContrastIndex];
	};

	static isCssVar(baseHex: string) {
		return ColorUtilsHelper.CSS_VAR_FORMAT.test(baseHex);
	}

	static pickContrastTextColor = (baseHex): string => {
		return ColorUtilsHelper.pickContrastColor(baseHex, ColorUtilsHelper.CONTRAST_TEXT_COLORS);
	};

	private static hexToRGB = (hexColor: string | IRGBColor | number[]): IRGBColor => {
		if (typeof hexColor !== 'string') {
			if (!_.isUndefined((hexColor as IRGBColor).r) &&
				!_.isUndefined((hexColor as IRGBColor).g) &&
				!_.isUndefined((hexColor as IRGBColor).b)) {
				return hexColor as IRGBColor; // if it's already IRGBColor...
			} else {
				if (typeof hexColor === 'object') {
					return {
						r: hexColor[0],
						g: hexColor[1],
						b: hexColor[2]
					};
				}
			}
		}

		// 3 digits
		if (hexColor.length === 4) {
			hexColor = ColorUtilsHelper.hex3ToHex6(hexColor);
		}
		let r = parseInt(hexColor.substr(1, 2), 16);
		let g = parseInt(hexColor.substr(3, 2), 16);
		let b = parseInt(hexColor.substr(5, 2), 16);

		return { r, g, b };
	};

	private static hex3ToHex6 = (hex3Color: string): string => {
		if (hex3Color[0] === '#') {
			return `#${hex3Color[1]}${hex3Color[1]}${hex3Color[2]}${hex3Color[2]}${hex3Color[3]}${hex3Color[3]}`;
		}
		return `${hex3Color[0]}${hex3Color[0]}${hex3Color[1]}${hex3Color[1]}${hex3Color[2]}${hex3Color[2]}`;
	};

	private static getContrast = (baseRGB: IRGBColor, contrastRGB: IRGBColor): number => {
		let lum1 = ColorUtilsHelper.getLuminance(baseRGB.r, baseRGB.g, baseRGB.b);
		let lum2 = ColorUtilsHelper.getLuminance(contrastRGB.r, contrastRGB.g, contrastRGB.b);
		let brightest = Math.max(lum1, lum2);
		let darkest = Math.min(lum1, lum2);
		return (brightest + 0.05) / (darkest + 0.05);
	};

	private static getLuminance = (r, g, b): number => {
		let a = [r, g, b].map((v) => {
			v /= 255;
			return v <= 0.03928
				? v / 12.92
				: Math.pow( (v + 0.055) / 1.055, 2.4 );
		});
		return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
	};

	static rgb2hex = (rgb?: string) => {
		let match = rgb && rgb.match(/^rgba?\((\d+),\s?(\d+),\s?(\d+)/i);
		return (match && match.length === 4) ? '#' +
			('0' + parseInt(match[1], 10).toString(16)).slice(-2) +
			('0' + parseInt(match[2], 10).toString(16)).slice(-2) +
			('0' + parseInt(match[3], 10).toString(16)).slice(-2) : '';
	};

	static lighten = (hex: string): string => {
		return hex && ColorUtilsHelper.blendColors(hex, '#ffffff', 0.75);
	};

	static darken = (hex: string): string => {
		return hex && ColorUtilsHelper.blendColors(hex, '#000000', 0.4);
	};

	static average = (color1: string, color2: string): string => {
		return color1 && color2 && ColorUtilsHelper.blendColors(color1, color2, 0.5);
	};

	/* eslint-disable no-bitwise */
	private static blendColors(c0, c1, p): string {
		let f = parseInt(c0.slice(1), 16);
		let t = parseInt(c1.slice(1), 16);
		let R1 = f >> 16;
		let G1 = f >> 8 & 0x00FF;
		let B1 = f & 0x0000FF;
		let R2 = t >> 16;
		let G2 = t >> 8 & 0x00FF;
		let B2 = t & 0x0000FF;
		return '#' + (0x1000000
			+ (Math.round((R2 - R1) * p) + R1) * 0x10000
			+ (Math.round((G2 - G1) * p) + G1) * 0x100
			+ (Math.round((B2 - B1) * p) + B1))
			.toString(16).slice(1);
	}
	/* eslint-enable no-bitwise */


	static isObjectBasedColorPresent = (colorFields: ColorTypes[], visualProps: VisualProperties): boolean => {
		return !!_.find(colorFields, field => ColorUtilsHelper.isObjectBasedColor(visualProps, field));
	};

	static isObjectBasedColor = (visualProps: VisualProperties, colorName: ColorTypes): boolean => {
		return GroupColorUtils.isGroupColor(visualProps[colorName])
			|| CalculationColorUtils.isCalculationColor(visualProps[colorName]);
	};

	static isObjectBasedColorUsed = (colorFields: ColorTypes[], visualProps: VisualProperties): boolean => {
		return colorFields
			.filter(color => ColorUtilsHelper.isObjectBasedColor(visualProps, color))
			.filter(color => ColorUtilsHelper.isColorApplicable(visualProps, color)).length > 0;
	};

	static isColorApplicable(options: VisualProperties, field: ColorTypes): boolean {
		if (field.indexOf('secondary') === 0 && !options.secondaryChartType) return false;

		switch (field) {
			case ColorTypes.POINT:
				return options.points && options.subChartType === ChartType.SPLINE;
			case ColorTypes.SECONDARY_POINT:
				return options.secondaryPoints && options.secondaryChartType === ChartType.SPLINE;
			case ColorTypes.PERIOD_OVER_PERIOD:
				return options.secondaryGroup === ColorUtilsHelper.POP_GROUP;
			case ColorTypes.SECONDARY_PERIOD_OVER_PERIOD:
				return options.secondaryPop && options.secondaryGroup === ColorUtilsHelper.POP_GROUP;
		}
		return true;
	}


	static checkForStackedRecolors = (opts, primarySeries, secondarySeries): void => {
		if (opts.showLegend && opts.recolors && opts.recolors.length) {
			ColorUtilsHelper.updateSeriesWithRecolor(_.filter(opts.recolors as any[],
				recolorItem => recolorItem.colorType === ColorTypes.PRIMARY), primarySeries);
			ColorUtilsHelper.updateSeriesWithRecolor(_.filter(opts.recolors as any[],
				recolorItem => recolorItem.colorType === ColorTypes.SECONDARY), secondarySeries);
		}
		if (opts.subChartType === 'SPLINE' && opts.secondaryGroup
			&& opts.secondaryGroup.length && opts.showLegend && opts.recolors && opts.recolors.length) {
			ColorUtilsHelper.updateSeriesWithRecolor(_.filter(opts.recolors as any[],
				recolorItem => recolorItem.colorType === ColorTypes.POINT), primarySeries);
		}
		if (opts.subChartType === 'SPLINE'
			&& opts.secondaryGroup
			&& opts.secondaryGroup.length
			&& opts.secondaryYAxis
			&& opts.secondaryYAxis.length
			&& opts.showLegend
			&& opts.recolors
			&& opts.recolors.length) {
			ColorUtilsHelper.updateSeriesWithRecolor(_.filter(opts.recolors as any[],
				recolorItem => recolorItem.colorType === ColorTypes.SECONDARY_POINT), secondarySeries);
		}
	};
	private static updateSeriesWithRecolor(recolors: any[], series: any[]): void {
		_.each(recolors, recolor => {
			let seriesItem = _.findWhere(series, {_uniqName: recolor.uniqueName});
			if (seriesItem) {
				seriesItem.color = recolor.color;
			} else {
				seriesItem = _.findWhere(series, {name: recolor.uniqueName});
				if (seriesItem) {
					seriesItem.color = recolor.color;
				}
			}
		});
	}

	static isSentimentColorPresent = (colorFields: ColorTypes[], visualProps: VisualProperties): boolean => {
		return !!_.find(colorFields, field => ColorUtilsHelper.isSentimentColor(visualProps, field));
	};

	static isSentimentColor = (visualProps: VisualProperties, colorField: ColorTypes): boolean => {
		let types = [
			PredefinedMetricConstants.SENTIMENT_3,
			PredefinedMetricConstants.SENTIMENT_5
		];
		return ColorUtilsHelper.isColorOfType(visualProps[colorField], types);
	};

	static isEaseColor = (visualProps: VisualProperties, colorField: ColorTypes): boolean => {
		let types = [
			PredefinedMetricConstants.EASE_3,
			PredefinedMetricConstants.EASE_5
		];
		return ColorUtilsHelper.isColorOfType(visualProps[colorField], types);
	};

	private static isColorOfType(color: string, types: string[]): boolean {
		let colorName = CalculationColorUtils.getCalculationColorAttributeName(color);
		return types.contains(colorName);
	}

	static getCustomColor = (options: VisualProperties, colorField: ColorTypes): string => {
		let customColorValueField = ReportConstants.customColorFields[colorField];
		return options[customColorValueField];
	};

	static convertSolidToCustom = (visualProps: VisualProperties, field: ColorTypes): void => {
		visualProps[field] = ColorPaletteNames.CUSTOM;

		let customColorField = ReportConstants.customColorFields[field];
		visualProps[customColorField] = ColorUtilsHelper.DEFAULT_CUSTOM_COLOR;
	};

	static dataPointHasRecolor = (visualProps: VisualProperties, dataPoint: IDataPoint) => {
		if (!visualProps.recolors || !dataPoint || !dataPoint.object) return false;

		let item = _.find(visualProps.recolors, ColorUtilsHelper.findMatchedRecolor(dataPoint.object));
		return !!item;
	};

	/**
	 * Sanitizes a string intended for use as a CSS color.
	 * Supports 6 digit hex or named colors
	 */
	static sanitizeCSSColor(color: string): string {		
		if (!color?.length) return '';
		color = color.trim();
		
		if (color[0] === '#') {
			return color.replace(/[^#0-9a-f]/ig, '').substring(0,7);
		} else {
			return color.replace(/[^A-Za-z]/g, '');
		}
	}
}
