<form #userDialog="ngForm">
	<p>{{(isForcedExternalAuthentication ? 'administration.userCreationHintNoForce' : 'administration.userCreationHint') | i18n}}</p>

	<div class="add-user-dialog">
		<div *ngIf="!isForcedExternalAuthentication" class="form-group">
			<div class="row d-flex">
				<label
					for="force-registration"
					class="d-flex align-items-center mb-0 control-label justify-end col-md-3"
					[i18n]="'administration.forceRegistration'"></label>
				<div class="col-md-9">
					<input
						id="force-registration"
						name="forceRegistration"
						type="checkbox"
						class="checkbox"
						[(ngModel)]="user.forceRegistration"
						[disabled]="isExistingUser()">
				</div>
			</div>
		</div>

		<div class="form-group" [class.has-error]="showFirstNameError()">
			<div class="row d-flex">
				<label
					for="firstName"
					class="d-flex align-items-center mb-0 control-label justify-end col-md-3"
					[class.mandatory]="!user.forceRegistration"
					[i18n]="'administration.firstName'"></label>
				<div class="col-md-9">
					<input
						class="form-control"
						id="firstName"
						name="firstName"
						[(ngModel)]="user.firstName"
						trim
						[required]="!user.forceRegistration"
						[minlength]="1"
						[maxlength]="40"
						[disabled]="isExistingUser()">
				</div>
			</div>
			<div class="row">
				<div class="col-md-offset-3 col-md-9">
					<span
						*ngIf="isValidLengthFirstName()"
						class="help-block has-error"
						[i18n]="'administration.firstNameInvalid'">
					</span>
					<span
						*ngIf="invalidFirstName()"
						class="help-block has-error"
						[i18n]="'administration.firstNameInvalidValue'">
					</span>
				</div>
			</div>
		</div>

		<div class="form-group" [class.has-error]="showLastNameError()">
			<div class="row d-flex">
				<label
					for="lastName"
					class="d-flex align-items-center mb-0 control-label justify-end col-md-3"
					[class.mandatory]="!user.forceRegistration"
					[i18n]="'administration.lastName'"></label>
				<div class="col-md-9">
					<input
						class="form-control"
						id="lastName"
						name="lastName"
						[(ngModel)]="user.lastName"
						trim
						[required]="!user.forceRegistration"
						[minlength]="1"
						[maxlength]="40"
						[disabled]="isExistingUser()">
				</div>
			</div>
			<div class="row">
				<div class="col-md-offset-3 col-md-9">
					<span
						*ngIf="isValidLengthLastName()"
						class="help-block has-error"
						[i18n]="'administration.lastNameInvalid'">
					</span>
					<span
						*ngIf="invalidLastName()"
						class="help-block has-error"
						[i18n]="'administration.lastNameInvalidValue'">
					</span>
				</div>
			</div>
		</div>

		<div *ngIf="!user.forceRegistration && !isForcedExternalAuthentication" class="form-group" [class.has-error]="hasPasswordError()">
			<div class="row d-flex">
				<label
					for="password"
					class="d-flex align-items-center mb-0 control-label justify-end col-md-3 mandatory"
					[i18n]="'administration.password'"></label>
				<div class="col-md-9">
					<input
						class="form-control"
						id="password"
						type="password"
						name="password"
						[(ngModel)]="user.password"
						[disabled]="passwordDisabled()"
						[required]="!passwordDisabled()"
						[minlength]="pwdPolicy.minLength"
						[pattern]="pwdPattern">
				</div>
			</div>
			<div class="row">
				<div class="col-md-offset-3 col-md-9">
					<span
						*ngIf="showPasswordInvalidError()"
						class="help-block has-error"
						[i18n]="'administration.passwordInvalid'"
						[i18nParams]="{pwdLength: pwdPolicy.minLength}">
					</span>
					<span
						*ngIf="showPasswordPatternError()"
						class="help-block has-error"
						[class.has-error]="showPasswordPatternError()"
						[i18n]="'administration.specialCharsRequired'">
					</span>
					<span
						*ngIf="showPasswordEmailError()"
						class="help-block has-error"
						[class.has-error]="showPasswordEmailError()"
						[i18n]="'administration.passwordEmailMatch'">
					</span>
				</div>
			</div>
		</div>
		<div *ngIf="!user.forceRegistration && !isForcedExternalAuthentication" class="form-group" [class.has-error]="showConfirmPasswordNotMatchError()">
			<div class="row d-flex">
				<label
					for="confirmPassword"
					class="d-flex align-items-center mb-0 control-label justify-end col-md-3 mandatory text-right"
					[i18n]="'administration.confirmPassword'"></label>
				<div class="col-md-9">
					<input
						class="form-control"
						type="password"
						id="confirmPassword"
						name="confirmPassword"
						[required]="!passwordDisabled()"
						[minlength]="pwdPolicy.minLength"
						[(ngModel)]="user.confirmPassword"
						[pwCheck]="user.password"
						[disabled]="passwordDisabled()">
				</div>
			</div>
			<div class="row">
				<div class="col-md-offset-3 col-md-9">
					<span
						*ngIf="showConfirmPasswordNotMatchError()"
						class="help-block has-error"
						[i18n]="'administration.passwordNotMatch'">
					</span>
				</div>
			</div>
		</div>

		<div class="form-group" [class.has-error]="forciblyShowEmailError()">
			<div class="row d-flex">
				<label
					for="email"
					class="d-flex align-items-center mb-0 control-label justify-end col-md-3 mandatory">{{'administration.email'|i18n}}</label>
				<div class="col-md-9">
						<input
							class="form-control"
							type="email"
							id="email"
							name="email"
							required="true"
							[(ngModel)]="user.email"
							[minlength]="5"
							(ngModelChange)="validateEmail()">
				</div>
			</div>
			<div class="row">
				<div class="col-md-offset-3 col-md-9">
					<span
						class="help-block"
						*ngIf="!isEmailStringValid()"
						[class.has-error]="forciblyShowEmailError()"
						[i18n]="'administration.emailInvalid'">
					</span>
					<span
						id="emailExistError"
						class="help-block"
						*ngIf="hasError('email', 'emailExist')"
						[class.has-error]="forciblyShowEmailError()"
						[i18n]="'administration.usersemailexist'">
					</span>
					<span
						id="userExistWarning"
						class="help-block warning-block"
						*ngIf="isExistingUserInOtherMA() && !isAdminOrgUser"
						[i18n]="'administration.userExistWarning'">
					</span>
					<span
						id="userRestoreWarning"
						class="help-block warning-block"
						*ngIf="isDisabledUser() && !isAdminOrgUser">{{getRestoreUserWarning()}}
					</span>
					<span
						id="adminUserWarning"
						class="help-block warning-block"
						*ngIf="isAdminOrgUser"
						[i18n]="'administration.adminOrgUserWarning'">
					</span>
					<span
						id="domainRestrictedError"
						class="help-block"
						[class.has-error]="forciblyShowEmailError()"
						*ngIf="hasError('email', 'domainRestricted')">{{allowedDomainsErrorMessage}}
					</span>
				</div>
			</div>
		</div>

		<div *ngIf="isAuthByUniqueId && !isAdminOrgMasterAccount" class="form-group" [class.has-error]="showUniqueIdError()">
			<div class="row d-flex">
				<label
					for="authUniqueId"
					class="d-flex align-items-center mb-0 control-label justify-end col-md-3"
					[i18n]="'administration.idpUniqueId'"></label>
				<div class="col-md-9">
					<input
						id="authUniqueId"
						name="authUniqueId"
						class="form-control col-md-9"
						(blur)="validateUniqueId()"
						(change)="validateUniqueId()"
						[(ngModel)]="user.authUniqueId"
						[required]="isAuthByUniqueId && !isAdminOrgMasterAccount"
						[minlength]="1"
						>
				</div>
			</div>
			<div class="row">
				<div class="col-md-offset-3 col-md-9">
					<span
						class="help-block"
						*ngIf="!isUniqueIdValid()"
						[i18n]="'administration.uniqueIdInvalid'">
					</span>

					<span
						id="uniqueIdExistError"
						class="help-block"
						*ngIf="formValidationService.hasError('authUniqueId', 'uniqueIdExists')"
						[i18n]="'administration.uniqueIdExists'">
					</span>
				</div>
			</div>
		</div>

		<div *ngIf="isNeedXmAccountId" class="form-group stack-md" [class.has-error]="showXmAccountIdError()">
			<div class="row d-flex">
				<label
					for="xmAccountId"
					class="d-flex align-items-center mb-0 control-label justify-end col-md-3"
					[i18n]="'administration.xmAccountId'"></label>
				<div class="col-md-9">
					<input
						id="xmAccountId"
						name="xmAccountId"
						class="form-control col-md-9"
						(ngModelChange)="validateXmAccountId($event)"
						[disabled]="!isXmAccountIdEnabled() || isExistingUser()"
						[(ngModel)]="user.xmAccountId"
						autocomplete="off"
						minlength="1">
				</div>
			</div>
			<div class="col-md-offset-3 col-md-9">
				<span
					id="xmAccountIdExistError"
					class="help-block"
					*ngIf="formValidationService.hasError('xmAccountId', 'xmAccountIdExists')"
					[i18n]="'administration.xmAccountIdExists'">
				</span>
			</div>
		</div>

		<div class="form-group" [class.has-error]="licenseLimitExceeded() || licenseIsMissing()">
			<div class="row d-flex">
				<label class="d-flex align-items-center mb-0 control-label justify-end col-md-3 mandatory">{{'administration.licenseType'|i18n}}</label>
				<div class="col-md-9">
					<simple-dropdown
						class="br-license-type-selector form-control p-0 border-0"
						valueField="licenseTypeId"
						[value]="user.licenseTypeId"
						[options]="assignableLicenseTypes"
						displayField="displayName"
						(onChange)="onLicenseChange($event)"
					></simple-dropdown>
				</div>
			</div>
			<div class="row">
				<div class="col-md-offset-3 col-md-9">
					<span
						class="help-block warning-block"
						*ngIf="licenseIsMissing()"
						[i18n]="'administration.licenseTypeInvalid'">
					</span>
				</div>
			</div>
		</div>

		<user-custom-field
			*ngIf="showCustomField()"
			[name]="options.customFieldName"
			[value]="user.customField"
			(updateName)="updateCustomFieldName($event)"
			(updateValue)="updateCustomFieldValue($event)"
		></user-custom-field>

		<div *ngIf="isCurrentUserAnyAdmin() && isInternalMasterAccount()"
			class="mt-32 mb-24 br-system-permissions">
			<admin-org-permissions
				[user]="user"
				(platformApiCallback)="grantPlatformApiUser()"
			></admin-org-permissions>
		</div>

		<div *ngIf="!isInternalMasterAccount() && hasCustomerPermissions()"
			class="mt-32 mb-24 br-system-permissions">
			<customer-permissions
				[user]="user"
				[licenseType]="user.licenseTypeId"
			></customer-permissions>
		</div>
	</div>

	<alert type="danger" *ngIf="user.forceRegistration && !userDialog.valid && !isEmailValidationPending()" dismissable="false">
		{{'administration.requiredFields'|i18n}}
	</alert>

	<p class="text-danger" *ngIf="licenseLimitExceeded()">{{getLicenseExceededMessage()}}</p>
</form>
