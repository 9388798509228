import { PaginatedSelectionController } from '@app/modules/item-grid/selection/paginated-selection-controller';
import { IconsMap } from '@app/modules/object-list/utilities/icons-map.class';
import { ScheduledJobTypes } from '@app/modules/schedules/jobs/scheduled-job-types.service';
import { TypeGuards } from '@app/util/typeguards.class';
import { Security } from '@cxstudio/auth/security-service';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DriversType } from '@cxstudio/drivers/entities/drivers-type';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { DateService } from '@cxstudio/services/date-service.service';
import * as moment from 'moment';
import * as _ from 'underscore';
import { GridContext } from './grid-context-constant';
import { GridMode } from './grid-mode';
import { GridTypes } from './grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { HtmlUtils } from '@app/shared/util/html-utils.class';


export type IRowFormatter = (row, cell, value, columnDef?, dataContext?) => string;

export class GridFormatter {

	private folderRegexp = new RegExp('.*folder.*', 'i');
	private dashboardIconsMap = IconsMap.getDashboardIconsMap();
	private filterIconsMap = IconsMap.getFilterIconsMap();
	private gridContextIconsMap = IconsMap.getGridContextIconsMap();
	private driversContentIconsMap = IconsMap.getDriversContentIconsMap();
	private dash = ' - ';

	constructor(
		private locale: ILocale,
		private dateService: DateService,
		private security: Security,
		private scheduleUtilsService,
		private scheduledJobTypes: ScheduledJobTypes,
		private gridUtils: GridUtilsService
	) {  }

	private countChildren = (folder): number => {
		let count = 0;
		if (folder.children) {
			folder.children.forEach((item) => {
				if (!this.folderRegexp.test(item.type)) {
					if (!folder.folderProjectId ||
						folder.folderProjectId === item.projectId ||
						item.type === FilterTypes.PREDEFINED ||
						AnalyticMetricTypes.isPredefinedMetric(item)) {
						count++;
					}
				} else {
					count += this.countChildren(item);
				}
			});
		}
		return count;
	};

	scheduleNameFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext): string => {
		let definitions = this.scheduledJobTypes.getTypes();
		let types = [ definitions.REFRESH, definitions.DISTRIBUTE_PDF ];
		let jobType = _.find(types, {value: dataContext.jobType});
		let job = jobType.titleString;

		let description = this.scheduleUtilsService.getScheduleDescription(dataContext.startDate, dataContext.endDate,
			dataContext.cronExpression, dataContext.activeFor, dataContext.timezoneOffset, jobType === definitions.DISTRIBUTE_PDF);
		let burger = this.getHamburger(dataContext);
		return `<span class="br-item-name">${job}${description}</span>${burger}`;
	};


	private getSpacing = (dataContext): string => {
		let displayStyle = dataContext.level ? 'inline-block' : 'inline';
		return `<span
			aria-hidden="true"
			class='item-tree-level-${dataContext.level}'
			style='display:${displayStyle};height:1px;width:${(24 * dataContext.level)}px'></span>`;
	};

	private getStar = (dataContext: Dashboard, accessibilityField: string): string => {
		let objectName = dataContext[accessibilityField];

		let promptText = dataContext.favorite
			? this.locale.getString('common.selectedAsFavorite', { objectName })
			: this.locale.getString('common.notSelectedAsFavorite', { objectName });

		let ariaLabelText = dataContext.favorite
			? this.locale.getString('common.unfavoriteObject', { objectName })
			: this.locale.getString('common.favoriteObject', { objectName });

		return `<button role="checkbox" class="action-hover-text show-on-parent-hover show-on-parent-selected br-favorite-star
			${(dataContext.favorite ? 'q-icon q-icon-star action-color visible' : 'q-icon q-icon-star-outline hide-icon')}"
			aria-checked="${!!dataContext.favorite}"
			aria-label="${this.gridUtils.replaceHTML(ariaLabelText)}"
			title="${this.gridUtils.replaceHTML(promptText)}"></button>`;
	};

	private getCheckbox = (dataContext, localizedObjectType?: string, accessibilityField?: string): string => {
		let checkedClasses, titleText;

		if (dataContext.selected) {
			checkedClasses = 'q-icon-check checked-item';
			titleText = localizedObjectType ?
				this.locale.getString('common.objectTypeSelected', {objectType: localizedObjectType}) :
				this.locale.getString('common.genericObjectSelected');
		} else {
			checkedClasses = 'q-icon-circle';
			titleText = localizedObjectType ?
				this.locale.getString('common.objectTypeDeselected', {objectType: localizedObjectType}) :
				this.locale.getString('common.genericObjectDeselected');
		}

		return `<button
			role="checkbox"
			class="action-hover-text br-bulk-actions-check show-on-parent-hover show-on-parent-selected ${checkedClasses}"
			aria-checked="${!!dataContext.selected}"
			${accessibilityField ? 'aria-label="' + this.gridUtils.replaceHTML(dataContext[accessibilityField]) + '"' : '' }
			title="${titleText}"></button>`;
	};

	private getLeftPadding = (dataContext, gridMode, gridType): string => {
		if (dataContext.type === 'folder' && gridMode !== GridMode.VIEW && (gridType !== GridTypes.DASHBOARD)
			&& (gridType !== GridTypes.FILTER)) {
			return '<span style="padding-left:44px" aria-hidden="true"></span>';
		}

		return '';
	};

	omitCheckbox = (dataContext): boolean => {
		return ((dataContext.type === FilterTypes.CXDATE) ||
			this.isGeneratedAsset(dataContext) ||
			(this.folderRegexp.test(dataContext.type)));
	};

	SelectedRowFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext): string | undefined => {
		if (this.omitCheckbox(dataContext)) return;

		return this.getCheckbox(dataContext);
	};

	getSelectedRowFormatterForObjectType = (localizedObjectType: string, accessibilityField: string): IRowFormatter => {
		return (row, cell, value, columnDef, dataContext): string | undefined => {
			if (this.omitCheckbox(dataContext)) return;

			return this.getCheckbox(dataContext, localizedObjectType, accessibilityField);
		};
	};

	getFavoriteFormatter = (accessibilityField: string): IRowFormatter => {
		return (row, cell, value, columnDef, dataContext): string | undefined => {
			if (this.folderRegexp.test(dataContext.type))
				return;
			return this.getStar(dataContext, accessibilityField);
		};
	};

	LabelsFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext): string | undefined => {
		if (this.folderRegexp.test(dataContext.type)) return;

		if (!dataContext.labels || !dataContext.labels.length) return;

		let labelsTitle = dataContext.labels.map((label): string => {
			return this.gridUtils.replaceHTML(label.text);
		}).join('\n');

		let labelsAria = dataContext.labels.map((label): string => {
			return this.gridUtils.replaceHTML(label.text);
		}).join(', ');

		return `<i class="q-icon-tag" title="${labelsTitle}" aria-label="${this.locale.getString('common.labels')}: ${labelsAria}"></i>`;
	};

	// formatter with folder icons(open/close) and spacer for looking like tree
	getNameFormatter = (gridType?: GridTypes, gridMode?): (...args: any) => string => {
		// closure to make function more reusable

		return (row, cell, value, columnDef, dataContext) => {
			value = this.gridUtils.replaceHTML(value);
			if (dataContext.type === GridContext.METRIC_PREDEFINED) {
				value = this.locale.getString(value);
			}

			let hasChildren = dataContext.children && dataContext.children.length;
			let descr = dataContext.description ? this.gridUtils.replaceHTML(dataContext.description) : value;
			let spacer = this.getSpacing(dataContext);

			if (this.folderRegexp.test(dataContext.type)) {
				let count = `(${this.countChildren(dataContext)})`;
				let icon = this.getFolderIcon(dataContext);
				let ariaTag = 
					`role="link" aria-expanded="${dataContext._collapsed ? 'false' : 'true'}" aria-disabled="${!this.countChildren(dataContext)}"`;
				let paddingLeft = this.getLeftPadding(dataContext, gridMode, gridType);

				return `<a ${ariaTag} href="javascript:void(0)">${paddingLeft}${spacer}<span
					class='toggle br-folder ${icon}'
					></span>
					<span class='br-folder folder-name' title='${descr}'>
					<span class='br-folder-exact-name br-item-name'>${value}</span> ${count}</span></a>`;
			} else {
				return this.getName(value, dataContext, gridType);
			}
		};
	};

	private getFolderIcon = (dataContext): string => {
		return `q-icon-folder${(!dataContext._collapsed ? '-open' : '-closed')}`;
	};

	getName = (value, dataContext, gridType?: GridTypes): string => {
		let brClass = gridType ? `br-${gridType}` : '';
		let contextClass = dataContext.type ? `br-${dataContext.type.toLowerCase()}` : '';

		let spacer = this.getSpacing(dataContext);
		let itemIcon = this.getItemIcon(dataContext, gridType);

		return `${spacer}${itemIcon}<a href="javascript:void(0)" class='${brClass} br-item-name
			${contextClass}' role="link">${value}</a>`;
	};

	private getItemIcon = (dataContext, gridType?: GridTypes): string => {
		let icon = '';

		if (gridType === GridTypes.ATTRIBUTES) {
			if (dataContext.objectGroup === 'System') {
				icon = IconsMap.QUALTRICS_XM_ICON;
			} else {
				return '';
			}
		} else {
			if (this.dashboardIconsMap[dataContext.type]) {
				icon = this.dashboardIconsMap[dataContext.type];
			} else if (this.filterIconsMap[dataContext.type]) {
				icon = this.filterIconsMap[dataContext.type];
			} else if (this.gridContextIconsMap[dataContext.type]) {
				icon = this.gridContextIconsMap[dataContext.type];
			} else if (this.driversContentIconsMap[dataContext.type]) {
				icon = this.driversContentIconsMap[dataContext.type];
			} else {
				return '';
			}
		}

		return `<span class="br-left-dashboard-items"><span class="pr-8 ${icon}" aria-hidden="true"></span></span>`;
	};

	getHamburger = (dataContext, accessibilityField?: string): string => {
		let isOwner = this.security.isCurrentUser(dataContext.ownerName);
		let ownerClass = isOwner ? ' item-owner' : ' non-owner';
		let positioning = '';
		let type = dataContext.type || '';
		let ariaLabel = accessibilityField ? this.locale.getString('common.menuAriaLabel', { objectTitle: dataContext[accessibilityField] }) : '';

		/* eslint-disable max-len */
		return `<button href="javascript:void(0)" tabindex="-1" title="${this.locale.getString('common.openMenu')}"
				class="show-on-parent-hover show-on-parent-selected burger-wrap br-${type}-burger-wrap ${ownerClass} action-hover-border action-hover-text no-background"
				aria-haspopup="true" aria-expanded="false" ${positioning}
				${ariaLabel ? 'aria-label="' + this.gridUtils.replaceHTML(ariaLabel) + '"' : ''}>
			<i class="q-icon-layer br-${type}-burger br-menu-burger"></i></button>`;
		/* eslint-enable max-len */
	};

	getDisabledHamburger = (dataContext, accessibilityField?: string): string => {
		let isOwner =  this.security.isCurrentUser(dataContext.ownerName);
		let ownerClass = isOwner ? ' item-owner' : ' non-owner';
		let positioning = '';
		let type = dataContext.type || '';
		let ariaLabel = accessibilityField ? this.locale.getString('common.menuAriaLabel', { objectTitle: dataContext[accessibilityField] }) : '';

		const classes = [
			`show-on-parent-hover`,
			`disabled`,
			`hidden`,
			`show-on-parent-selected`,
			`burger-wrap`,
			`br-${type}-burger-wrap`,
			ownerClass,
			`action-hover-border`,
			`action-hover-text`,
			`no-background`,
			`text-gray-900`,
		];

		return `<button href="javascript:void(0)" tabindex="-1" title="${this.locale.getString('common.openMenu')}"
				class="${classes.join(' ')}"
				aria-haspopup="true" disabled="disabled" hidden="hidden" aria-expanded="false" ${positioning}
				${ariaLabel ? 'aria-label="' + this.gridUtils.replaceHTML(ariaLabel) + '"' : ''}>
			<i class="q-icon-layer br-${type}-burger br-menu-burger"></i></button>`;
	};

	HamburgerFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext): string => {
		return this.getHamburger(dataContext);
	};

	getPaginatedHamburgerFormatter = <T> (controller: PaginatedSelectionController<T>): IRowFormatter => {
		return (row, cell, value, columnDef, item: T) => {
			let selectionUtils = controller.getUtils();

			return !selectionUtils || !selectionUtils.areMultipleItemsSelected() || controller.isItemSelected(item)
				? this.HamburgerFormatter(row, cell, value, columnDef, item)
				: '';
		};
	};

	getProjectHamburgerFormatter = (gridMode): (...args: any) => string => {
		return (row, cell, value, columnDef, dataContext) => {
			if (gridMode === GridMode.VIEW && dataContext.type !== FolderTypes.ATTRIBUTE) {
				return this.getHamburger(dataContext);
			}
			return '';
		};
	};

	JobHamburgerFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext): string => {
		return dataContext.triggerGroup === 'application' ? this.getHamburger(dataContext) : '';
	};

	ScheduleFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext): string | undefined => {
		if (dataContext.type === 'dashboard' && dataContext.scheduleCount) {
			let scheduleTitle = dataContext.scheduleCount > 1 ?
				this.locale.getString('dashboard.scheduledActions', {count: dataContext.scheduleCount}) :
				this.locale.getString('dashboard.scheduledAction');
			return `<i class="q-icon-clock dashboard-schedule-icon" aria-describedby="schedule-tooltip"
				title="${scheduleTitle}" aria-label="${scheduleTitle}"></i>`;
		}
	};

	DateFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext): string | undefined => {
		if (!value)
			return;
		let date = new Date(value);

		return this.dateService.format(date);
	};

	TextFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext): string | undefined => {
		value = value ? value : '';
		return `<p title="${HtmlUtils.escapeHtml(value)}" class="text-ellipsis">${HtmlUtils.escapeHtml(value)}</p>`;
	};

	DateWithTZFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext): string => {
		if (!value)
			return this.dash;
		let date = new Date(value);

		return this.dateService.formatWithTimezone(date);
	};

	DateFormatterDash: IRowFormatter = (row, cell, value, columnDef, dataContext): string => {
		if (!value) return this.dash;
		let date = new Date(value);

		return this.dateService.format(date);
	};

	getAuthorFormatter = (gridMode?) => {
		return (row, cell, value, columnDef, dataContext) => {
			if (this.folderRegexp.test(dataContext.type)) return '';
			if (this.isGeneratedAsset(dataContext)) {
				return this.locale.getString('common.clarabridgeType');
			}

			if (!TypeGuards.isString(value)) return '';

			if (gridMode !== GridMode.VIEW && this.security.isCurrentUser(value))
				return this.locale.getString('dashboard.me');

			return value;
		};
	};

	getRatingFormatter = (gridMode): IRowFormatter => {
		return (row, cell, value, columnDef, dataContext) => {
			if (this.folderRegexp.test(dataContext.type)) return;

			let editable = gridMode !== GridMode.VIEW && !dataContext.permissions.OWN;
			let rating = Math.round(value * 5) / 10; // convert 1..10 to 1..5
			let emptyKey = editable ? 'dashboard.emptyRatingTooltip' : '';
			let titleKey = value ? 'dashboard.ratingTooltip' : emptyKey;

			let titleStr = this.locale.getString(titleKey, {
				rating: Number(rating).toFixed(1),
				count: dataContext.ratingCount
			});
			let barClass = 'br-rating-bar';
			if (editable) {
				barClass += ' active';
			}

			let intValue = value ?
				Math.round(value) : // after dividing by 2: 2.3 -> 2.5, 2.2 -> 2
				-1;

			let ratingString = intValue > -1 ?
				this.locale.getString('dashboard.dashboardRated', { rated: titleStr }) :
				this.locale.getString('dashboard.dashboardUnrated');
			let stars = `<div class="${barClass}" title="${titleStr}" role="figure" aria-label="${ratingString}">`;

			if (intValue === -1)
				return `${stars}</div>`;

			for (let i = 0; i < Math.floor(intValue / 2); i++) {
				stars += '<i class="br-rating-star full-star q-icon-star" aria-hidden="true"></i>';
			}
			if (intValue % 2 === 1) {
				stars += '<i class="br-rating-star half-star q-icon-star-half" aria-hidden="true"></i>';
			}
			stars += '</div>';
			stars += `<span class="br-rating-count" aria-label="${titleStr}">${dataContext.ratingCount}</span>`;
			return stars;
		};
	};

	StatusFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext): string | undefined => {
		if (this.folderRegexp.test(dataContext.type)) return;

		let sharedIcon = `<span class='br-shared q-icon-users' title='${this.locale.getString('dashboard.shared')}'></span>`;
		let privateIcon = `<span class='br-private q-icon-user' title='${this.locale.getString('dashboard.private')}'></span>`;
		let globalIcon = `<span class='br-public q-icon-globe' title='${this.locale.getString('dashboard.public')}'></span>`;

		let type = dataContext.type;

		if (this.isGeneratedAsset(dataContext)) {
			return globalIcon;
		} else if (type === GridContext.DASHBOARD_TEMPLATE) {
			return dataContext.publicStatus === SharingStatus.PUBLIC ? globalIcon : privateIcon;
		} else {
			switch (dataContext.sharingStatus) {
				case SharingStatus.PUBLIC: return globalIcon;
				case SharingStatus.SHARED: return sharedIcon;
				case SharingStatus.PRIVATE: return privateIcon;
				default: return privateIcon;
			}
		}
	};

	StatusScorecardDisabledFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext): string => {
		let getSwitch = this.getLabeledToggleSwitchGenerator(dataContext.name);
		let disabled = !this.security.has('manage_scorecards');
		return getSwitch(!dataContext.disabled, disabled);
	};

	SimpleStatusFormatter: IRowFormatter = (row, cell, value: SharingStatus, columnDef, dataContext): string | undefined => {
		if (this.folderRegexp.test(dataContext.type)) return;

		let sharedIcon = `<span class='br-shared q-icon-users' title='${this.locale.getString('dashboard.shared')}'></span>`;
		let privateIcon = `<span class='br-private q-icon-user' title='${this.locale.getString('dashboard.private')}'></span>`;
		let globalIcon = `<span class='br-public q-icon-globe' title='${this.locale.getString('dashboard.public')}'></span>`;

		switch (value) {
			case SharingStatus.PUBLIC: return globalIcon;
			case SharingStatus.SHARED: return sharedIcon;
			case SharingStatus.PRIVATE: return privateIcon;
			default: return privateIcon;
		}
	};


	FilterTypeFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext): string | undefined => {
		if (dataContext.type === FilterTypes.PREDEFINED) {
			return this.locale.getString('reportFilters.predefined');
		}
		if (dataContext.type === FilterTypes.CXSCORECARD ) {
			return this.locale.getString('reportFilters.scorecardFilter');
		}
		if (dataContext.type.toLowerCase().indexOf('filter') === -1 || this.folderRegexp.test(dataContext.type)) return;


		return this.locale.getString(`reportFilters.${dataContext.type}`);
	};

	getCheckBoxFormatter = (checked: boolean, disabled: boolean): string => {
		let disabledString: string = disabled ? 'disabled=disabled aria-label="Row selection disabled"' : '';
		let checkedString: string = checked ? 'checked=checked aria-label="Row selected"' : 'aria-label="Row deselected"';

		return `<input type=checkbox ${checkedString} ${disabledString}>`;
	};

	getRadioFormatter = (checked: boolean, disabled: boolean, name: string): string => {
		let disabledString: string = disabled ? 'disabled=disabled' : '';
		let checkedString: string = checked ? 'checked=checked' : '';

		return `<input type=radio name=${name} ${checkedString} ${disabledString}>`;
	};

	NoFormat: IRowFormatter = (row, cell, value, columnDef, dataContext): string => {
		return value;
	};

	SkipFolders: IRowFormatter = (row, cell, value, columnDef, dataContext): string | undefined => {
		if (this.folderRegexp.test(dataContext.type)) return;
		return value;
	};

	Milliseconds: IRowFormatter = (row, cell, value, columnDef, dataContext): string => {
		return `${value}  ${this.locale.getString('common.millisecondsPostfix')}`;
	};

	YesNoFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext): string => {
		if (_.isUndefined(value)) return '';
		return value ? this.locale.getString('common.yes') : this.locale.getString('common.no');
	};
	buildToggleSwitchFormatter(
		nameField = 'name',
		isDisabled: (dataContext?) => boolean = () => false,
		disabledMessage?: (dataContext?) => string): IRowFormatter {
		return (row, cell, value, columnDef, dataContext) => {
			const disabled = isDisabled(dataContext);
			let getSwitch = this.getLabeledToggleSwitchGenerator(dataContext[nameField]);
			return getSwitch(value, disabled, disabledMessage?.(value));
		};
	}

	buildCellWithTitleFormatter: () => IRowFormatter = () => {
		return (row, cell, value, columnDef, dataContext) => {
			return `<div class="overflow-hidden text-ellipsis"
						title="${HtmlUtils.escapeHtml(value)}">${HtmlUtils.escapeHtml(value)}
					</div>`;
		};
	};

	optOutToggleSwitchFormatter = (value, dataContext) => {
		let getSwitch = this.getLabeledToggleSwitchGenerator(dataContext.recipient);
		return getSwitch(value);
	};

	getLabeledToggleSwitchGenerator(objectName: string, customClasses: string = '', centered: boolean = true):
			(checked: boolean, disabled?: boolean, disabledMessage?: string) => string {
		let checkedStateLabel = this.locale.getString('common.disableObject', { objectName });
		let uncheckedStateLabel = this.locale.getString('common.enableObject', { objectName });

		return (checked: boolean, disabled?: boolean, disabledMessage?: string): string => {
			let label = checked ? checkedStateLabel : uncheckedStateLabel;

			let switchClass = checked ? 'switch-on' : 'switch-off';
			let disabledClass = disabled ? 'disabled' : '';
			let disabledAttr = disabled ? 'disabled="disabled"' : '';
			let disabledTitle = disabled && disabledMessage ? `title="${disabledMessage}"` : '';
			let centeredClass = centered ? 'ml-auto mr-auto' : '';

			return `
				<button class="cb-toggle-button-container toggle-switch ${centeredClass} ${disabledClass} ${switchClass} ${customClasses}"
					${disabledAttr} ${disabledTitle} role="switch" aria-label="${this.gridUtils.replaceHTML(label)}">
					<div class="toggle-switch-animate ${switchClass} ${disabledClass}">
							<div class="knob toggle opacity-100 ${switchClass}"></div>
					</div>
				</button>
			`;
		};
	}

	getDropdownValueFormatter = (gridMode, valueFormatter: (value: unknown) => string): IRowFormatter => {
		return (row, cell, value, columnDef, dataContext) => {
			if (dataContext.type === FolderTypes.ATTRIBUTE) {
				return '';
			}

			let formattedValue = valueFormatter(value);

			if (gridMode === GridMode.EDIT) {
				return `<button aria-haspopup="true" class="btn btn-secondary dropdown-cell-button">
					<div class="value" title="${formattedValue}">${formattedValue}</div>
					<div class="caret"></div>
				</button>`;
			} else {
				return formattedValue;
			}
		};
	};

	SimpleCheckboxFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext) => {
		return this.getCheckBoxFormatter(value, false);
	};

	getFixedTextFormatter = (text) => {
		return (row, cell, value, columnDef, dataContext) => {
			return text;
		};
	};

	isGeneratedAsset = (dataContext): boolean => {
		return (dataContext.type === FilterTypes.PREDEFINED
			|| dataContext.type === GridContext.METRIC_PREDEFINED
			|| dataContext.type === DriversType.drivers_template);
	};

	PercentFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext): string | undefined => {
		if (this.folderRegexp.test(dataContext.type)) return;
		if (_.isUndefined(value))
			return this.dash;
		return (value * 100).toFixed(2) + '%';
	};

	DocumentDateFormatterDash: IRowFormatter = (row, cell, value, columnDef, dataContext): string | undefined => {
		if (this.folderRegexp.test(dataContext.type)) return;
		if (!value) return this.dash;
		let date = moment(value, 'YYYYMMDDHHmmss');

		return this.dateService.format(date.toDate());
	};

	ArrayToStringFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext): string | undefined => {
		return value ? value.join(', ') : '';
	};
}

app.service('gridFormatterService', GridFormatter);
