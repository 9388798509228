import * as _ from 'underscore';
import IProjectsProvider from '@cxstudio/common/projects-provider-interface';
import { StudioAsset } from '@cxstudio/asset-management/studio-asset';
import { AccountProject } from '@cxstudio/content-provider-api/account-project';
import { ProjectAccessLevel } from '@cxstudio/user-administration/users/project-access/project-access-level-class';
import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';

import { PromiseUtils } from '@app/util/promise-utils';
import { DashboardComponentsApiService } from '@app/modules/dashboard/services/assets/dashboard-components-api.service';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';

export class DashboardWidgetsProjects implements IProjectsProvider {

	contentProviders: any[];
	currentContentProviderId: number;
	accounts: any[];
	currentAccountId: number;
	projects: any[];
	currentProjectId: number;
	currentProject: any;

	private projectsData: AccountProject[];

	constructor(
		private dashboard: StudioAsset,
		private dashboardComponentsApiService: DashboardComponentsApiService,
		private contentProviderOptionsService: ContentProviderOptionsService,
	) {}

	reloadContentProviders(): angular.IPromise<void> {
		let projectsPromise = PromiseUtils.old(this.dashboardComponentsApiService.getProjects(this.dashboard));
		return projectsPromise.then((response: AccountProject[]) => {
			this.projectsData = response;
			return this.contentProviderOptionsService.getContentProviders()
				.then((contentProviders) => {
					this.contentProviders = (contentProviders.data || []);
					this.contentProviders = this.filterContentProviders(this.contentProviders);

					if (this.contentProviders.length) {
						if (ValueUtils.isNotSelected(this.currentContentProviderId)
								|| !_.findWhere(this.contentProviders, {id: this.currentContentProviderId})) {
							this.currentContentProviderId = this.contentProviders[0].id;
						}
					} else {
						this.currentContentProviderId = -1;
					}
				});
		});
	}

	private filterContentProviders(contentProviders: any[]): any[] {
		let cpIds = _.pluck(this.projectsData, 'contentProviderId');
		return _.filter(contentProviders, (cp) => cpIds.contains(cp.id));
	}

	reloadAccounts(contentProviderId: number): angular.IPromise<void> {
		return this.contentProviderOptionsService.getUserAccounts(contentProviderId, {})
			.then((accounts) => {
				this.accounts = accounts.data;
				this.accounts = this.filterAccounts(this.accounts);

				if (this.accounts.length) {
					if (ValueUtils.isNotSelected(this.currentAccountId)
							|| !_.findWhere(this.accounts, {accountId: this.currentAccountId})) {
						this.currentAccountId = this.accounts[0].accountId;
					}
				} else {
					this.currentAccountId = -1;
				}
			});
	}

	private filterAccounts(accounts: any[]): any[] {
		let cpProjects = _.filter(this.projectsData,
			(project) => project.contentProviderId === this.currentContentProviderId);
		let accountIds = _.pluck(cpProjects, 'accountId');
		return _.filter(accounts, (account) => accountIds.contains(account.accountId));
	}

	reloadProjects(contentProviderId: number, accountId: number, projectAccessLevel?: ProjectAccessLevel): angular.IPromise<void> {
		//projectAccessLevel will be ignored in widgets
		return this.contentProviderOptionsService.getOptions(contentProviderId, 'projects',
			{contentProviderId, accountId}, true)
			.then((projects) => {
				this.projects = projects.data;
				this.projects = this.filterProjects(this.projects);

				if (this.projects.length) {
					if (ValueUtils.isNotSelected(this.currentProjectId)
							|| !_.findWhere(this.projects, {projectId: this.currentProjectId})) {
						this.currentProject = this.projects[0];
						this.currentProjectId = this.currentProject.projectId;
					}
				} else {
					this.currentProjectId = -1;
				}
			});
	}

	private filterProjects(projects: any[]): any[] {
		let cpProjects = _.filter(this.projectsData, (project) =>
			project.contentProviderId === this.currentContentProviderId
				&& project.accountId === this.currentAccountId);
		let projectIds = _.pluck(cpProjects, 'projectId');
		return _.filter(projects, (project) => projectIds.contains(project.projectId));
	}

}
