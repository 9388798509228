import { ChangeDetectorRef, Directive, Input } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ProfanityDisguiseService } from '@app/modules/profanity/profanity-disguise.service';
import { FeedbackTranslationUtils } from '@app/modules/translation/feedback/feedback-translation-utils';
import { GenericVisualizationBaseComponent } from '@app/modules/widget-visualizations/generic-visualization-base.class';
import { WidgetContentEventType } from '@app/modules/widget-visualizations/visualization-component.interface';
import { ObjectUtils } from '@app/util/object-utils';
import { SelfCleaningComponentMixin } from '@app/util/self-cleaning-component';
import { SuggestionMenu } from '@cxstudio/reports/document-explorer/conversations/suggestion-menu.service';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import { PreviewWidget } from '@cxstudio/reports/entities/preview-widget.class';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';

@Directive()
export abstract class PreviewBaseComponent extends SelfCleaningComponentMixin(GenericVisualizationBaseComponent) {

	@Input() widget: PreviewWidget;

	menuLoading: boolean;

	constructor(
		protected ref: ChangeDetectorRef,
		protected reportUtils: ReportUtils,
		protected locale: CxLocaleService,
		protected profanityDisguiseService: ProfanityDisguiseService,
		protected suggestionMenu: SuggestionMenu,
	) {
		super(ref, reportUtils);
	}

	handleSentenceClick(sentence: PreviewSentence | null, event: MouseEvent, elementClass: string): void {
		if (this.demo)
			return;
		if ($(event.target).hasClass('no-drill') || $(event.target).parents('.no-drill').length)
			return;

		if (this.utils.auditMode && sentence) {
			if (this.profanityDisguiseService.needToMaskSentence(sentence)) {
				return;
			}
			this.menuLoading = true;
			this.suggestionMenu.openSentenceSuggestionMenu(event, sentence, this.widget,
				this.widget.visualProperties.preferences?.settings?.leafOnly).finally(() => {
				this.menuLoading = false;
				this.ref.markForCheck();
			});
			return;
		}
		this.showSentenceDrillMenu(sentence, event, elementClass);
	}

	showMore(sentence: PreviewSentence, callback: (response: ReportDataObject) => void): void {
		this.contentEvent.emit({
			type: WidgetContentEventType.LOAD_FULL_VERBATIM,
			args: [sentence.verbatimId, callback]
		});
	}

	private showSentenceDrillMenu(sentence: PreviewSentence, event: MouseEvent, elementClass: string): void {
		if (sentence === null) {
			this.handleClick.emit({point: null, event});
			return;
		}
		let point = ObjectUtils.copy(sentence) as DrillPoint;

		point._custom_filter = {
			name: sentence.documentId + '',
			type: DrillType.CUSTOM_GROUP,
			values: [
				`${this.locale.getString('widget.exportDocument')}: ${sentence.documentId}`,
				`_id_document:${sentence.documentId}`
			]
		};

		point.displayName = sentence.documentId + '';
		point._element = $(event.target).parents(elementClass);
		point.isWeekDescription = this.dataObject.metadata.isWeekDescription;
		//add information about dateRangeP1
		point.P1StartDate = this.dataObject.metadata.P1StartDate;
		point.P1EndDate = this.dataObject.metadata.P1EndDate;
		point.dateRangeP2 = this.dataObject.metadata.dateRangeP2;
		point._selectionId = sentence.id; // for pdf export selection

		this.handleClick.emit({point, event});
	}

	isVerbatimView = (): boolean => {
		return this.widget.properties?.previewMode === PreviewMode.VERBATIM;
	};

	getUniqueId = (sentence: PreviewSentence): string => {
		return FeedbackTranslationUtils.getSentenceUniqueId(sentence, this.widget.properties);
	};

	getTranslationText = (sentence: PreviewSentence): string => {
		return FeedbackTranslationUtils.getSentenceTranslationText(sentence, this.widget.properties);
	};

}
