import { Inject, Injectable } from '@angular/core';
import PlotLineAxis from '@app/modules/plot-lines/plot-lines/plot-line-axis';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { IColorSelectorPalette } from '@cxstudio/reports/coloring/color-selector.component';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { CommonInherentProperties } from '@cxstudio/reports/utils/contextMenu/drill/common-inherent-properties.class';
import { CommonDrillService } from '../common-drill.service';
import { DefaultDrillProcessor } from './default-drill-processor.class';

@Injectable({
	providedIn: 'root'
})
export class DrillToScatterProcessorService extends DefaultDrillProcessor {

	readonly INHERITED_VIS_PROPERTIES = [
		'primaryGroup', 'size', 'xAxis', 'yAxis', 'scale', 'plotBandsEnabled', 'plotBands'
	].concat(CommonInherentProperties.highchartsVisual);
	constants = this.metricConstants.get();

	constructor(
		private readonly commonDrill: CommonDrillService,
		@Inject('metricConstants') private readonly metricConstants: MetricConstants,
		@Inject('colorUtils') private readonly colorUtils: ColorUtils
	) {
		super();

		this.addInheritedVisualProperties(this.INHERITED_VIS_PROPERTIES);
	}

	setHierarchyCalculation(widget: Widget): void {
		if (widget.visualProperties.yAxis && widget.visualProperties.yAxis.startsWith('hierarchy_calculation_')) {
			this.setYAxis(widget, this.constants.VOLUME);
		}
		if (widget.visualProperties.xAxis && widget.visualProperties.xAxis.startsWith('hierarchy_calculation_')) {
			this.setXAxis(widget, this.constants.VOLUME);
		}
	}

	intializeDefaultProperties(widget: Widget, defaultColor: IColorSelectorPalette): void {
		widget.properties.selectedMetrics = _.filter(widget.properties.selectedMetrics, (metric) => {
			return metric.name !== this.constants.STRENGTH.name
				&& metric.name !== this.constants.CONSTANT_SIZE.name;
		});
		if (!widget.properties.selectedMetrics || widget.properties.selectedMetrics.length === 0) {
			widget.properties.selectedMetrics = [];
			this.setXAxis(widget, this.constants.VOLUME);
			this.setYAxis(widget, this.constants.SENTIMENT);
		} else if (widget.properties.selectedMetrics.length < 2) {
			this.setXAxis(widget, widget.properties.selectedMetrics[0]);
			this.setYAxis(widget, this.constants.SENTIMENT);
		}

		// Primary axis calculation --> horizontal axis of scatter
		// Secondary axis calculation --> vertical axis of scatter
		if (widget.visualProperties.yAxis && widget.visualProperties.secondaryYAxis && widget.properties.selectedMetrics.length >= 2) {
			let xAxisMetric = this.commonDrill.getSelectedMetric(widget, widget.visualProperties.yAxis);
			let yAxisMetric = this.commonDrill.getSelectedMetric(widget, widget.visualProperties.secondaryYAxis);

			this.setXAxis(widget, xAxisMetric);
			this.setYAxis(widget, yAxisMetric);
		}
		if (!AnalyticMetricTypes.isHierarchyModel(widget.properties.selectedAttributes[0])) {
			this.setHierarchyCalculation(widget);
		}

		if (widget.visualProperties.color) {
			this.commonDrill.adjustColorProperty(widget.visualProperties, ColorTypes.PRIMARY, defaultColor);
		} else {
			widget.visualProperties.color = this.colorUtils.getDefaultColor(defaultColor);
			widget.visualProperties.customColor = this.colorUtils.getDefaultCustomColor(defaultColor);
		}

		widget.visualProperties.showLegend = true;
		widget.visualProperties.showLabels = false;

		if (!widget.visualProperties.size) {
			widget.visualProperties.size = this.constants.CONSTANT_SIZE.name;
		}
	}

	drillTo(widget: Widget, drillToType, point: DrillPoint, defaultColor?: IColorSelectorPalette): Widget {
		let initialWidgetType = widget.name;

		widget.name = WidgetType.SCATTER;
		widget.properties.widgetType = WidgetType.SCATTER;
		widget.visualProperties.visualization = WidgetVisualization.BUBBLE;
		widget.visualProperties.subChartType = null;
		widget.displayName += ' - ' + point.groupBy.displayName;

		_.extend(point.groupBy, this.commonDrill.getPrimaryGroupSettingsExtension(widget, point.groupBy));

		widget.properties.selectedAttributes = [point.groupBy];
		widget.visualProperties.primaryGroup = point.groupBy.name;

		this.commonDrill.processSelectedMetric(point, widget, this.setCalculation);
		// process PoP when drilling from historical data point in table widget
		this.commonDrill.setPopForWidgetDrilledFromTableHistoricPoint(widget, point);
		this.commonDrill.processHierarchyMetrics(widget);
		delete widget.properties.dateRangeP2;
		this.intializeDefaultProperties(widget, defaultColor);
		widget = this.inheritPropertiesAndVisProps(widget);
		this.processScatterReferenceLines(widget, initialWidgetType);
		return widget;
	}

	private setCalculation = (calculationMetric: ReportCalculation, widget: Widget): void => {
		if (!calculationMetric) {
			calculationMetric = this.constants.VOLUME;
		}
		this.setXAxis(widget, calculationMetric);
		this.setYAxis(widget, this.constants.SENTIMENT);
	};

	setYAxis(widget: Widget, metric: ReportCalculation): void {
		widget.properties.selectedMetrics[0] = metric;
		widget.visualProperties.yAxis = metric.name;
	}

	setXAxis(widget: Widget, metric: ReportCalculation): void {
		widget.properties.selectedMetrics[1] = metric;
		widget.visualProperties.xAxis = metric.name;
	}

	private processScatterReferenceLines(widget: Widget, initialWidgetType: WidgetType | string): void {
		if (initialWidgetType !== WidgetType.SCATTER) {
			this.switchReferenceLinesAxis(widget);
		}
	}

	private switchReferenceLinesAxis(widget: Widget): void {
		let referenceLines = widget.visualProperties.referenceLines;
		if (!referenceLines) {
			return;
		}

		referenceLines.forEach(this.switchReferenceLineAxis);
	}

	private switchReferenceLineAxis(referenceLine): void {
		if (referenceLine.axis === PlotLineAxis.primary) {
			referenceLine.axis = PlotLineAxis.secondary;
		} else {
			referenceLine.axis = PlotLineAxis.primary;
		}
	}

}
