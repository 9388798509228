import {
	AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, ViewChild
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CxLocaleService } from '@app/core';
import { GenericVisualizationBaseComponent } from '@app/modules/widget-visualizations/generic-visualization-base.class';
import { QualtricsApiService } from '@app/modules/widget-visualizations/qualtrics/qualtrics-api.service';
import { SelfCleaningComponentMixin } from '@app/util/self-cleaning-component';
import { ExternalWidget } from '@cxstudio/dashboards/widgets/widget';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { QualtricsSource } from '@app/modules/widget-visualizations/qualtrics/qualtrics-embed-data';
import { Security } from '@cxstudio/auth/security-service';
import { HTTPStatusCode } from '@app/core/http-status-code.enum';
import { WidgetError } from '../common/widget-error/widget-error.class';

@Component({
	selector: 'qualtrics-widget',
	template: `
<div class="d-flex flex-direction-column w-100-percent h-100-percent" *ngIf="!hasError() && !showAuthButton">
	<figure
		#iframeContainer
		class="flex-fill w-100-percent h-100-percent overflow-hidden"
		[ngBusy]="loading">
		<div class="iframe-focus-bumber" tabindex="0"></div>
		<iframe *ngIf="!!widgetUrl"
			width="100%"
			height="100%"
			[src]="widgetUrl"
			[title]="widget.description || ''"
			frameborder="0">
		</iframe>
	</figure>
	<footer class="p-8 flex-fixed d-flex justify-between align-center">
		<span *ngIf="!!source"
			class="d-flex flex-nowrap mr-8 align-center"
			[title]="getSourceName()">
			<img class="w-16 h-16 overflow-hidden mr-8" [src]="getSourceImage()">
			{{ getSourceName() }}
		</span>
		<a *ngIf="!!dashboardUrl"
			class="btn-link"
			[i18n]="'widget.embedGoToDashboard'"
			[href]="dashboardUrl"
			target="_blank">
		</a>
	</footer>
</div>
<widget-error *ngIf="hasError()" [widgetError]="widgetError"></widget-error>
<qualtrics-auth-button *ngIf="showAuthButton"
	class="d-flex align-center justify-center h-100-percent"
	(reload)="reloadAfterAuthentication()">
</qualtrics-auth-button>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class QualtricsWidgetComponent extends SelfCleaningComponentMixin(GenericVisualizationBaseComponent) implements AfterViewInit {
	@Input() widget: ExternalWidget;

	@ViewChild('iframeContainer') iframeContainer: ElementRef<HTMLElement>;

	loading: PromiseLike<any>;
	widgetUrl: SafeResourceUrl;

	showAuthButton: boolean;

	source: QualtricsSource;
	dashboardUrl: string;

	constructor(
		readonly ref: ChangeDetectorRef,
		private readonly domSanitizer: DomSanitizer,
		private readonly qualtricsApi: QualtricsApiService,
		private readonly locale: CxLocaleService,
		@Inject('security') private security: Security,
		@Inject('reportUtils') readonly reportUtils: ReportUtils,
		@Inject('dashboardService') private dashboardService,
	) {
		super(
			ref,
			reportUtils,
		);
	}

	initializeWidget(): void {
		delete this.widgetError;
		delete this.showAuthButton;
		this.ref.markForCheck();

		setTimeout(() => {
			if (this.checkForErrors()) {
				this.ref.detectChanges();
				return;
			}

			this.loading = this.qualtricsApi.getQualtricsEmbedWidgetData(this.utils.widgetId).then(response => {
				this.widgetUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(response.data.embedUrl);
				this.dashboardUrl = response.data.dashboardUrl;
				this.source = response.data.source;
				this.ref.markForCheck();
				this.markRendered();
			}, (reason) => {
				if (reason.status === HTTPStatusCode.NEED_AUTHENTICATION) {
					this.showAuthButton = true;
					this.ref.markForCheck();
					this.markRendered();
				} else if (reason.status === HTTPStatusCode.NO_IMPERSONATION) {
					this.checkError(WidgetError.EMBEDDING_NOT_AVAILABLE_IN_IMPERSONATE);
				} else {
					this.checkError(WidgetError.withMessage(reason.data));
				}
			});
			this.ref.markForCheck();
		});
	}

	private checkForErrors(): boolean {
		if (!this.security.isQualtricsWidgetEmbeddingEnabled()) {
			return this.checkError(WidgetError.QUALTRICS_EMBEDDING_DISABLED);
		}
		if (!this.widget.properties.embedCode) {
			return this.checkError(WidgetError.EMBED_NOT_LINKED);
		}
		return false;
	}

	reloadAfterAuthentication(): void {
		this.dashboardService.hardRefreshDashboard(this.widget.dashboardId);
	}

	ngAfterViewInit(): void {
		this.initResizer(this.iframeContainer, _.noop);
		this.initializeWidget();
	}

	getSourceName(): string {
		switch (this.source) {
			// these are product names, not localizable
			case QualtricsSource.CX: return 'Customer XM';
			case QualtricsSource.EX: return 'Employee XM';
			case QualtricsSource.BX: return 'Brand XM';
			case QualtricsSource.PX: return 'Product XM';
			case QualtricsSource.DX: return 'Design XM';
			case QualtricsSource.CoreXM: return 'Core XM';
			default: return '';
		}
	}

	getSourceImage(): string {
		return `/dashboard/img/xm-embed/xm-logo-${this.source.toLowerCase()}.svg`;
	}
}
