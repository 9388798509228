import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { TaggingHelper } from '@app/modules/item-grid/services/tagging-helper.service';
import { MetricEditorDialogOutput } from '@app/modules/metric/editor/metric-editor-dialog-output.interface';
import { MetricEditorUtilsService } from '@app/modules/metric/editor/metric-editor-utils.service';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { TransferApiService } from '@app/modules/user-administration/transfer/transfer-api.service';
import { TransferGroup } from '@app/modules/user-administration/transfer/transfer-group';
import { BulkService } from '@app/shared/services/bulk.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { Security } from '@cxstudio/auth/security-service';
import BulkUpdateLabelsEntity from '@cxstudio/bulk/bulk-update-labels-entity';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { Caches } from '@cxstudio/common/caches';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { HiddenItemType } from '@cxstudio/common/hidden-item-type';
import { IFolderService } from '@cxstudio/folders/folder-service.factory';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { GridUpdateService } from '@app/modules/object-list/utilities/grid-update.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { MetricManagementApiService } from '@cxstudio/metrics/api/metric-management-api.service';
import { MetricMode } from '@cxstudio/metrics/metric-modes-constant';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { FilterFolderApi } from '@cxstudio/report-filters/api/filter-folder-api.service';
import { FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { FilterUpdateService } from '@cxstudio/report-filters/filter-update-service.service';
import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';
import { RenameService } from '@cxstudio/services/rename-service';
import { TreeService } from '@cxstudio/services/tree-service.service';
import { SharingParams, SharingService } from '@cxstudio/sharing/sharing-service.service';
import * as _ from 'underscore';
import { ScorecardFiltersManagementService } from '@app/modules/scorecards/filters/scorecard-filters-management.service';
import { ReportFilterManagement } from './report-filters-management.component';
import { FilterDependencyTypesProvider } from '@app/modules/filter/services/filter-dependency-types-provider';
import { StudioAsset } from '@cxstudio/asset-management/studio-asset';
import { DependenciesDialogInput } from '@app/modules/asset-management/dependencies-modal/dependencies-modal-component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import IFilter from './entity/filter';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';
import { TemplateAssetType } from '@app/modules/unified-templates/common-templates/dto/template-asset-type';
import { AssetTemplateApiService } from '@app/modules/unified-templates/common-templates/asset-template-api.service';
import { FilterRuleTypes } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { SecurityApiService } from '@cxstudio/services/data-services/security-api.service';


export interface IFilterActionsService {
	renameFolder(folder: any): void;
	removeFolder(folder: any): void;
	createFolder(folder: any): void;
	moveToFolder(filter: any, folderTo: any): void;
	toggleHide(filter: any): void;
	editFilter(filter: any): void;
	createFilter(folder?: IFolderItem): void;
	renameFilter(filter: any): void;
	deleteFilter(filter: any): void;
	copyFilter(filter: any): void;
	shareFilters(filters: any[]): void;
	viewFilter(filter: any): void;
	saveFilterAsTemplate(filter: IFilter): void;
	bulkDeleteFilters(filters: any[]): void;
	showDependencies(filter: any): void;
	canEditPredefinedFilter(filter: any): boolean;
	editPredefinedFilter(filter: any): void;
	viewPredefinedFilter(filter: any): void;
	bulkTransferFilters(filters: any[], projectIdentifier: AccountOrWorkspaceProject): Promise<void>;
	bulkUpdateLabels(): NgbModalRef;
	updateLabelsRequest(updateLabelsEntity: BulkUpdateLabelsEntity): ng.IPromise<any>;
	toggleScorecardFilterState(filter: any, newState: boolean): void;
}

// eslint-disable-next-line prefer-arrow-callback
app.service('FilterActionsService', function(
	$log,
	$q: ng.IQService,
	filterManagementApiService: FilterManagementApiService,
	filterFolderApi: FilterFolderApi,
	filterDependencyTypesProvider: FilterDependencyTypesProvider,
	FolderService,
	security: Security,
	metricApiService: MetricManagementApiService,
	metricsService: MetricsService,
	assetTemplateApi: AssetTemplateApiService,
	downgradeDialogService: DowngradeDialogService,
	sharingService: SharingService,
	cachedHttpService: CachedHttpService,
	locale: ILocale,
	cbDialogService: CBDialogService,
	renameService: RenameService,
	transferApiService: TransferApiService,
	gridUpdateService: GridUpdateService,
	treeService: TreeService,
	globalNotificationService: GlobalNotificationService,
	securityApiService: SecurityApiService,
	filterUpdateService: FilterUpdateService,
	scorecardFiltersManagementService: ScorecardFiltersManagementService,
	bulkService: BulkService,
	metricEditorUtils: MetricEditorUtilsService,
) {
	return class FilterActionsService implements IFilterActionsService {
		private scope: ReportFilterManagement;
		private folderSvc: IFolderService;

		constructor(scope: ReportFilterManagement) {
			this.scope = scope;
			this.folderSvc = new FolderService(FolderTypes.FILTER);
		}

		renameFolder(folder: any): void {
			this.folderSvc.renameFolder(folder, this.scope.filterList).then(() => {
				folder.displayName = folder.name;
				this.scope.refreshGrid(folder);
			}).catch(_.noop);
		}

		removeFolder(folder: any): void {
			this.folderSvc.removeFolder(folder, this.scope.filterList)
				.then((children) => this.scope.refreshGrid(children))
				.then(() => globalNotificationService.deleteFolderNotification(folder.name));
		}

		createFolder(folder: any): void {
			this.folderSvc.createFolder(folder,
				this.scope.filterList,
				WorkspaceTransitionUtils.getWorkspace(this.scope.project)
			).then((created) => {
				this.scope.refreshGrid([folder, created]);
			});
		}


		moveToFolder(filter: any, folderTo: any): void {
			let parent = filter.parent; // update icon, if needed parent
			this.moveFilterInternal(filter, folderTo).then(() => {
				if (parent) {
					parent.hasAssets = parent.children && !parent.children.isEmpty();
				}
				if (!folderTo.id)
					folderTo = null;

				let refreshItems = [filter, folderTo, parent];
				refreshItems = refreshItems.concat(this.traverseFolderChain(parent));
				refreshItems = refreshItems.concat(this.traverseFolderChain(filter));

				this.scope.refreshGrid(refreshItems);
			})
				.then(() => globalNotificationService.addItemMovedNotification(filter.name, folderTo));
		}

		private traverseFolderChain = (item): any[] => {
			let levels = [];
			let currentLevel = item ? item.parent : undefined;
			while (currentLevel) {
				levels.push(currentLevel);
				currentLevel = currentLevel.parent;
			}
			return levels;
		};



		toggleHide(filter: any): void {
			filter.hide = !filter.hide;
			let key = filter.type === FilterTypes.CXANALYZE
				? this.getAnalyzeFilterKey(filter)
				: filter.id.toString();

			securityApiService.hideObjectForUser(HiddenItemType.FILTERS, filter.hide, key, filter.name)
				.then(() => globalNotificationService.addVisibilityChangedNotification(filter.name, filter.hide));
			if (filter.hide) {
				//if it is being hidden, add tag to grey it out
				TaggingHelper.tag(filter, TaggingHelper.tags.HIDDEN);
				security.loggedUser.hiddenFilters[key] = true;
			} else {
				TaggingHelper.untag(filter, TaggingHelper.tags.HIDDEN);
				security.loggedUser.hiddenFilters[key] = false;
			}
			cachedHttpService.cache(Caches.FILTERS).invalidate();

			this.scope.selectionUtils.clearSelections();
		}

		editFilter(filter: any): void {
			if (!filter || /.*folder.*/i.test(filter.type)) return;
			let folders = this.getFoldersForFilter(filter, this.scope.filterList);
			let loading = filterUpdateService.edit(filter, true,
				this.createFilterBuildingData(false), this.scope.filterList, folders).then(config => {
				this.scope.refreshGrid(config.items);
				if (config.shareAfterSave) {
					if ((config.items as any[]).length) {
						this.shareFilters(config.items[1]);
					} else {
						this.shareFilters(config.items);
					}
				}
				globalNotificationService.addItemSavedNotification(filter.name);
			}, () => {
				return $q.when();
			});
			this.scope.loading.promise = loading;
		}

		renameFilter(filter: any): void {
			let type = locale.getString('reportFilters.filter');
			let itemList = this.scope.filterList.filter((item) => !/.*folder.*/i.test(item.type) );
			let renameModal = renameService.modal(filter, itemList, type);

			renameModal.result.then((props) => {
				filter.name = props.name;
				filter.description = props.description;

				filterManagementApiService.renameFilter(this.scope.filterList, filter).then(() => {
					this.scope.refreshGrid(filter);
				}).then(() => globalNotificationService.addItemSavedNotification(filter.name));
			}).catch(_.noop);
		}

		deleteFilter(filter: any): void {
			let escapedFilterName: string = bulkService.getEscapedName(filter.name);
			let confirmationText = {
				title: locale.getString('common.deleteObjectTitle', {objectName: escapedFilterName}),
				warning: locale.getString('common.deleteObjectText', {objectName: escapedFilterName})
			};

			gridUpdateService.delete(
				[].concat(filter), this.scope.filterList,
				filterManagementApiService.deleteFilters, confirmationText
			).then(() => {
				if (filter.parent) {
					filter.parent.hasAssets = filter.parent.children && !filter.parent.children.isEmpty();
				}
				this.scope.refreshGrid(filter.parent);
				globalNotificationService.addDeletedNotification(escapedFilterName);
			}, () => {});
		}

		bulkDeleteFilters(filters: any[]): void {
			let confirmationText = {
				title: locale.getString('reportFilters.bulkDeleteTitle'),
				warning: locale.getString('reportFilters.bulkDeleteText',
					{
						count: filters.length,
						namesList: bulkService.getNamesList(filters, 'name')
					})
			};

			gridUpdateService.delete(filters, this.scope.filterList, filterManagementApiService.deleteFilters, confirmationText).then(() => {
				filters.forEach((filter) => {
					if (filter.parent) {
						filter.parent.selected = false;
					}
					globalNotificationService.addDeletedNotification(bulkService.getEscapedName(filter.name));
				});

				this.scope.refreshGrid(filters);
			});
		}

		bulkTransferFilters(transferredFilters: any[], projectIdentifier: AccountOrWorkspaceProject): Promise<void> {
			return downgradeDialogService.openBulkTransferDialog({
				selectedObjects: transferredFilters,
				mode: TransferGroup.FILTERS,
				projectIdentifier
			}).result.then(result => {
				this.scope.loading.promise = transferApiService.makeTransfer(result)  as unknown as ng.IPromise<void>;
				if (!result.retainEditPermission) {
					this.removeTransferredFilters(transferredFilters);
				} else {
					this.updateTransferredFilters(result, transferredFilters);
				}
				this.scope.refreshGrid(this.scope.filterList);
			});
		}

		private removeTransferredFilters = (transferredFilters) => {
			let transferredFiltersIds = _.pluck(transferredFilters, 'id');
			this.scope.filterList = _.reject(this.scope.filterList, filter => {
				return _.contains(transferredFiltersIds, filter.id);
			});
		};

		private updateTransferredFilters = (result, transferredFilters) => {
			let masterAccountsFiltersList = result.transferItems;
			transferredFilters.forEach(transferredFilter => {
				let masterAccountFilters: any[] = masterAccountsFiltersList[transferredFilter.masterAccountId][TransferGroup.FILTERS];
				let newOwnerId = _.findWhere(masterAccountFilters, {id: transferredFilter.id}).selectedCandidateId;
				transferredFilter.ownerId = newOwnerId;
				transferredFilter.sharingStatus = 'SHARED';
				transferredFilter.permissionLevel = 'EDIT';
			});
		};

		createFilterBuildingData = (viewMode?: boolean) => {
			let props = this.scope.context;
			return {
				customDateRanges: this.scope.customDateRanges,
				internalProject: InternalProjectTypes.isStudioAdminProject(this.scope.project.projectId),
				attributes: props.attributes,
				wordAttributes: props.wordAttributes,
				models: props.models,
				projectTimezone: props.projectTimezone,
				projectContext: props.projectContext,
				viewMode,
				canShare: true
			};
		};

		createFilter = (folder?: IFolderItem): void => {
			if (!ValueUtils.isSelected(this.scope.project.projectId)) {
				this.scope.errors.noProjectSelected = true;
				return;
			}
			if (_.isEmpty(this.scope.context.attributes)) {
				this.scope.errors.noProjectAttributes = true;
				return;
			}

			filterUpdateService.create(this.createFilterBuildingData(),
				this.scope.project, this.scope.filterList, folder).then((config) => {
				this.scope.refreshGrid(config.items);
				let item = (config.items as any).length ? config.items[0] : config.items;
				if (config.shareAfterSave) {
					this.shareFilters(item);
				}
				this.scope.notifyFilterWasAdded(item);
			});
		};

		copyFilter(filter: any): void {
			filterUpdateService.copy(filter, this.scope.filterList).then((update) => {
				this.scope.refreshGrid(update.items);
				globalNotificationService.addCreatedNotification(update.updated.name);
			});
		}

		viewFilter(filter: any): void {
			if (!filter || /.*folder.*/i.test(filter.type)) return;
			let folders = this.getFoldersForFilter(filter, this.scope.filterList);
			this.scope.loading.promise = filterUpdateService.edit(filter, false,
				this.createFilterBuildingData(true), this.scope.filterList, folders).then((items) => {
				this.scope.refreshGrid(items);
			});
		}

		bulkUpdateLabels = (): NgbModalRef => {
			return downgradeDialogService.openBulkUpdateLabelsModal({
				itemsName: locale.getString('object.filters').toLowerCase()
			});
		};

		updateLabelsRequest = (updateLabelsEntity: BulkUpdateLabelsEntity): ng.IPromise<any> => {
			return filterManagementApiService.updateLabels(updateLabelsEntity);
		};

		shareFilters(filterItems: any): ng.IPromise<void> {
			let filters = Array.isArray(filterItems) ? filterItems : [filterItems];
			const isBulk = filters.length > 1;
			let modalTitle = isBulk
				? locale.getString('reportFilters.shareBulkTitle')
				: locale.getString('reportFilters.shareTitle', {name: filters[0].name});

			let params: SharingParams = {
				modalTitle,
				updateReshare: this.updateReshareFlag,
				share: filterManagementApiService.shareFilters,
				getAsset: (id) => filterManagementApiService.getFilter(id),
				refreshGrid: this.scope.refreshGrid,
				component: 'filterSharing',
				objectTypePlural: 'filters',
			};

			return sharingService.shareWithDialog(filters, params);
		}

		showDependencies(filter: any): void {
			let input: DependenciesDialogInput<StudioAsset> = {
				asset: {
					assetId: filter.id,
					name: filter.name,
					type: filter.type,
					contentProviderId: filter.contentProviderId,
					projectId: filter.projectId
				} as any,
				dependencyTypesProvider: filterDependencyTypesProvider.withDependencyTypes(filter.type)
			};

			downgradeDialogService.openDependenciesModal(input);
		}

		private moveFilterInternal(filter: any, folderTo: any): PromiseLike<void> {
			$log.debug(`Moving ${filter.name} to ${folderTo.name}`);
			filter.parentId = folderTo.id;

			return filterFolderApi.moveToFolder(filter, folderTo.id).then(() => {
				if (!folderTo.id)
					folderTo = undefined;
				treeService.moveItem(this.scope.filterList, filter, folderTo);
			});
		}

		private getAnalyzeFilterKey(item): string {
			return item.masterAccountId + '-' + item.contentProviderId + '-' + item.accountId + '-' + item.filterId;
		}

		private getFoldersForFilter(filter: IFilter, filterList: any[]): any[] {
			return this.folderSvc.getFoldersForMove(filterList, filter || {}, () => undefined, true)
				.map(folder => folder.obj);
		}

		canEditPredefinedFilter(filter: any): boolean {
			let canEdit = filter.subtype !== PredefinedMetricConstants.SENTIMENT || !!filter.projectName;
			let hasPermission = security.has('manage_settings') && security.has(MasterAccountPermissionAction.CREATE_METRIC);
			return canEdit && hasPermission;
		}

		editPredefinedFilter(filter: any): void {
			if (!this.canEditPredefinedFilter(filter))
				return;

			this.scope.loading.promise = this.populateMetric(filter).then(predefinedMetric => {
				let metricModal = PromiseUtils.old(this.openMetricModal(predefinedMetric, MetricMode.EDIT_AND_SHARE));
				return metricModal.then(modalResponse => {
					let metricValues = $.extend({}, predefinedMetric, modalResponse.result);
					return metricApiService.updatePredefinedMetric(metricValues).then(() =>
						globalNotificationService.addItemSavedNotification(filter.name));
				}, _.noop);
			});
		}

		viewPredefinedFilter(filter: any): void {
			this.scope.loading.promise = this.populateMetric(filter).then(predefinedMetric => {
				this.openMetricModal(predefinedMetric, MetricMode.VIEW);
			});
		}

		toggleScorecardFilterState(scorecardFilter: any, newState: boolean): void {
			scorecardFiltersManagementService.updateDisableState(scorecardFilter, newState).then(() => {
				scorecardFilter.disabled = newState;
				this.scope.refreshGrid(scorecardFilter);
			});
		}

		private populateMetric(filter: any): ng.IPromise<any>  {
			const workspace = WorkspaceTransitionUtils.getWorkspace(this.scope.project);
			let projectId = this.scope.project.projectId;
			let metricsPromise = PromiseUtils.old(metricsService.getPredefinedMetricsForWorkspace(workspace, CacheOptions.NOT_CACHED));

			return metricsPromise.then(response => {
				let metrics: any[] = response;
				let predefinedMetric;
				if (filter.subtype === PredefinedMetricConstants.SENTIMENT) {
					predefinedMetric = _.findWhere(metrics, {name: PredefinedMetricConstants.SENTIMENT, projectId});
					if (!predefinedMetric) {
						// use default sentiment metric
						let defaultMetric = _.find(metrics, metric => {
							return metric.name === PredefinedMetricConstants.SENTIMENT
								&& !!_.isUndefined(metric.projectId);
						});
						predefinedMetric = angular.extend({}, defaultMetric, {
							id: undefined,
							projectId
						});
					}
				} else {
					predefinedMetric = _.findWhere(metrics, {name: filter.subtype});
				}
				return predefinedMetric;
			});
		}

		private openMetricModal(metric: any, mode: MetricMode): Promise<MetricEditorDialogOutput> {
			return metricEditorUtils.openMetricModal(this.scope.project, metric, mode, [], undefined, []);
		}

		private updateReshareFlag(filter: any, reshareAllowed: boolean): void {
			filter.reshareAllowed = reshareAllowed;
			filterManagementApiService.updateFilterReshareFlag(filter.id, reshareAllowed);
		}

		saveFilterAsTemplate(listItem: IFilter): void {
			this.scope.loading.promise = filterManagementApiService.getFilter(listItem.id).then(filter => {
				if (this.hasNLPQueryRule(filter)) {
					cbDialogService.warning(locale.getString('common.warning'),
						locale.getString('filter.cannotCreateTemplate'));
					return;
				}
				downgradeDialogService.openFilterTemplateCreationModal(filter).then(result => {
					this.scope.loading.promise = PromiseUtils.old(assetTemplateApi.createTemplate(TemplateAssetType.FILTER,
						filter.id, result));
				}, _.noop);
			});
		}

		private hasNLPQueryRule(filter: IFilter): boolean {
			if (filter.dateFilter)
				return false;
			return !!_.find(filter.rule.filterRules, rule => FilterRuleTypes.isEsQueryRule(rule));
		}
	};
});
