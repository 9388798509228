import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Security } from '@cxstudio/auth/security-service';
import { ImpersonateUserService } from '@cxstudio/services/impersonate-service.service';

@Component({
	selector: 'impersonate-bar',
	template: `
	<aside id="preview-bar" class="d-flex justify-between align-items-center pv-8 ph-16" *ngIf="impersonateActive">
		<button
			class="btn btn-text"
			type="button"
			id="back-to-users"
			(click)="impersonateUserOff()">
			<span class="q-icon q-icon-angle-left mr-8" aria-hidden="true"></span>
			<span [i18n]="'header.backToUsers'"></span>
		</button>

		<p class="mb-0 text-white">
			<span class="q-icon q-icon-eye mr-8" aria-hidden="true"></span>
			<span [i18n]="'header.impersonateBarTitle'" [i18nParams]="{username: impersonateUsername}"></span>
		</p>

		<button
			class="btn btn-text"
			id="exit-view-as"
			type="button"
			(click)="impersonateAndStay()">
			<span [i18n]="'header.exitViewAs'"></span>
			<span class="q-icon q-icon-delete ml-8" aria-hidden="true"></span>
		</button>
	</aside>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImpersonateBarComponent implements OnInit {

	impersonateUserOff: () => void;
	impersonateUsername: string;
	impersonateActive: boolean;

	constructor(
		@Inject('impersonateUserService') private readonly impersonateUserService: ImpersonateUserService,
		@Inject('security') private readonly security: Security,
		@Inject('$location') private readonly $location: ng.ILocationService
	) {}

	ngOnInit(): void {
		this.impersonateActive = this.impersonateUserService.isImpersonateActive();
		this.impersonateUserOff = this.impersonateUserService.impersonateUserOff;

		const user = this.security.getUser();
		this.impersonateUsername = `${user.firstName} ${user.lastName}`;
	}

	impersonateAndStay(): void {
		const currentPath = this.$location.path();
		this.impersonateUserService.impersonateUserOff(currentPath);
	}

}

app.directive('impersonateBar',
	downgradeComponent({component: ImpersonateBarComponent}));
