import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { AttributeGridFormatterService } from '@app/modules/project/attribute/attribute-grid-formatter.service';
import { ContentProviderFeature } from '@app/modules/system-administration/content-provider/content-provider-feature';
import { ContentProviderFeatureService } from '@app/modules/system-administration/content-provider/content-provider-feature.service';
import { BoundaryField } from '@cxstudio/attribute-geography/boundary-field';
import { GeographyOptionsService } from '@cxstudio/attribute-geography/geography-options.service';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { GridFormatter } from '@cxstudio/grids/grid-formatter-service';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { ProjectsManagementController } from '@cxstudio/projects/projects-management-component';

export interface IAttributeGridColumn extends IGridColumn {
	requireAttrStats?: boolean;
}


@Injectable()
export class AttributesGridDefinitionService implements IGridDefinition<ProjectsManagementController> {

	constructor(
		private locale: CxLocaleService,
		private attributeGridFormatterService: AttributeGridFormatterService,
		@Inject('gridFormatterService') private readonly gridFormatterService: GridFormatter,
		@Inject('contextMenuTree') private readonly contextMenuTree: ContextMenuTree,
		@Inject('geographyOptionsService') private readonly geographyOptionsService: GeographyOptionsService,
		private contentProviderFeatureService: ContentProviderFeatureService,
	) {}

	init = (gridMode: GridMode, controller: ProjectsManagementController): Promise<IAttributeGridColumn[]> => {
		const cpId = controller.oldProject.contentProviderId;
		const sourceCountSupportedPromise = cpId && cpId > 0
			? this.contentProviderFeatureService.isFeatureSupported(
				controller.oldProject.contentProviderId, ContentProviderFeature.ATTRIBUTE_SOURCES_COUNT)
			: Promise.resolve(false);

		return sourceCountSupportedPromise
			.then(sourceCountSupported => this.getColumns(gridMode, controller, sourceCountSupported));
	};

	private getColumns(gridMode: GridMode, controller: ProjectsManagementController, sourceCountSupported: boolean): IGridColumn[] {
		let columns: IAttributeGridColumn[] = [
			{
				id: 'hamburger',
				field: 'name',
				sortable: false,
				minWidth: 40,
				width: 40,
				cssClass: 'cell-hamburger',
				headerCssClass: 'header-hamburger text-center',
				name: '<i class="q-icon-layer"></i>',
				formatter: this.gridFormatterService.getProjectHamburgerFormatter(gridMode),
				onClick: (event, object) => {

					let showHide = {
						name: 'hideToggle',
						text: controller.ui.hideObjects
							? this.locale.getString('dashboard.showAllHidden')
							: this.locale.getString('dashboard.hideAllHidden'),
						func: () => { controller.ui.hideObjects = !controller.ui.hideObjects; },
						classes: [controller.ui.hideObjects ? 'items-hidden' : 'items-visible']
					};

					this.contextMenuTree.show(event, object, [showHide], 'header-menu', 360);
				}
			},
			{
				id: 'name',
				name: this.locale.getString('common.name'),
				field: 'displayName',
				sortable: false,
				searchable: true,
				minWidth: 100,
				width: 180,
				cssClass: 'cell-title action-hover-text cursor-pointer',
				formatter: this.gridFormatterService.getNameFormatter(GridTypes.ATTRIBUTES)
			}, {
				id: 'databaseName',
				name: this.locale.getString('attributes.databaseName'),
				field: 'name',
				sortable: false,
				searchable: true,
				optional: true,
				minWidth: 100,
				width: 180,
				cssClass: 'cell-database-name',
				formatter: this.gridFormatterService.SkipFolders
			}, {
				id: 'dataType',
				name: this.locale.getString('attributes.dataType'),
				field: 'uiType',
				sortable: false,
				optional: true,
				minWidth: 80,
				width: 120,
				cssClass: 'cell-data-type'
			}, {
				id: 'objectGroup',
				name: this.locale.getString('attributes.objectGroup'),
				field: 'objectGroup',
				sortable: false,
				optional: true,
				minWidth: 80,
				width: 120,
				selected: false,
				cssClass: 'cell-object-group'
			}, {
				id: 'reportable',
				name: this.locale.getString('attributes.reportable'),
				field: 'reportable',
				sortable: false,
				optional: true,
				minWidth: 60,
				width: 180,
				cssClass: 'cell-reportable',
				formatter: this.gridFormatterService.YesNoFormatter
			}, {
				id: 'boundaryField',
				name: this.locale.getString('attributes.geography'),
				field: 'boundaryField',
				sortable: false,
				optional: true,
				minWidth: 60,
				width: 180,
				cssClass: 'cell-geography cell-dropdown ' + gridMode,
				formatter: this.gridFormatterService.getDropdownValueFormatter(gridMode, this.formatGeographyValue)
			}, {
				id: 'caseSensitive',
				name: this.locale.getString('attributes.caseSensitive'),
				field: 'caseSensitive',
				sortable: false,
				optional: true,
				minWidth: 60,
				width: 120,
				cssClass: 'cell-case-sensitive',
				formatter: this.gridFormatterService.YesNoFormatter
			}, {
				id: 'drillPath',
				name: this.locale.getString('attributes.drillPath'),
				field: 'drillPathAttributeName',
				sortable: false,
				optional: true,
				minWidth: 100,
				width: 100,
				cssClass: 'cell-drill-path'
			}, {
				id: 'parents',
				name: this.locale.getString('attributes.parent'),
				field: 'uiParents',
				sortable: false,
				optional: true,
				minWidth: 100,
				width: 100,
				cssClass: 'cell-parents'
			}, {
				id: 'multivalue',
				name: this.locale.getString('attributes.delimitedMultivalue'),
				field: 'delimitedMultiValue',
				sortable: false,
				optional: true,
				minWidth: 60,
				width: 100,
				cssClass: 'cell-multivalue',
				formatter: this.gridFormatterService.YesNoFormatter
			}, {
				id: 'sourceCount',
				name: this.locale.getString('attributes.sourceCount'),
				field: 'sourceCount',
				sortable: false,
				optional: true,
				minWidth: 60,
				width: 100,
				selected: false,
				requireAttrStats: true,
				cssClass: 'cell-distinct-value',
				enabledIf: () => sourceCountSupported
			}, {
				id: 'distinctValue',
				name: this.locale.getString('attributes.distinctValue'),
				field: 'distinctValuesCount',
				sortable: false,
				optional: true,
				minWidth: 60,
				width: 100,
				selected: false,
				requireAttrStats: true,
				cssClass: 'cell-distinct-value'
			}, {
				id: 'populatedPercent',
				name: this.locale.getString('attributes.populatedPercent'),
				field: 'populatedPercent',
				sortable: false,
				optional: true,
				minWidth: 60,
				width: 100,
				selected: false,
				requireAttrStats: true,
				cssClass: 'cell-populated-percent',
				formatter: this.gridFormatterService.PercentFormatter
			}, {
				id: 'mostRecentDate',
				name: this.locale.getString('attributes.mostRecentDate'),
				field: 'dateLatest',
				sortable: false,
				optional: true,
				minWidth: 70,
				width: 100,
				selected: false,
				requireAttrStats: true,
				cssClass: 'cell-most-recent-date',
				formatter: this.gridFormatterService.DocumentDateFormatterDash
			}, {
				id: 'showInDrill',
				name: this.locale.getString('administration.showInDrill'),
				field: 'showInDrill',
				sortable: false,
				searchable: false,
				optional: true,
				minWidth: 60,
				width: 100,
				cssClass: 'cell-show-in-drill text-center',
				formatter: this.attributeGridFormatterService.getCheckboxFormatter(gridMode)
			}, {
				id: 'showInDocExp',
				name: this.locale.getString('administration.showInDocExp'),
				field: 'showInDocExp',
				sortable: false,
				searchable: false,
				optional: true,
				minWidth: 60,
				width: 100,
				cssClass: 'cell-show-in-doc-exp text-center',
				formatter: this.attributeGridFormatterService.getCheckboxFormatter(gridMode)
			}, {
				id: 'showInReporting',
				name: this.locale.getString('administration.showInReporting'),
				field: 'showInReporting',
				sortable: false,
				searchable: false,
				optional: true,
				minWidth: 60,
				width: 100,
				cssClass: 'cell-show-in-reporting text-center',
				formatter: this.attributeGridFormatterService.getCheckboxFormatter(gridMode)
			}, {
				id: 'useInClarabridgeSearch',
				name: this.locale.getString('administration.useInClarabridgeSearch'),
				field: 'useInClarabridgeSearch',
				sortable: false,
				searchable: false,
				optional: true,
				selected: false,
				minWidth: 60,
				width: 100,
				cssClass: 'cell-use-in-clarabridge-search text-center',
				formatter: this.attributeGridFormatterService.getCheckboxFormatter(gridMode, controller.settingsState)
			}];
		return columns;
	}

	private formatGeographyValue = (value: BoundaryField): string => {
		if (value) {
			return this.geographyOptionsService.getOptionDropdownName(value);
		} else {
			return this.locale.getString('common.none');
		}
	};

}

app.service('attributesGridDefinition', downgradeInjectable(AttributesGridDefinitionService));
