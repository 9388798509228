import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { PredictionProvider } from '@cxstudio/system-administration/master-accounts/integrations/prediction-settings';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { CxFormUtils } from '@app/modules/cx-form/utils/form-utils';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';


@Component({
	selector: 'predictive-settings-integration',
	templateUrl: './predictive-settings-integration.component.html',
	styles: [`.rounded-grouping-label { top: -20px; }`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredictiveSettingsIntegrationComponent extends SelfCleaningComponent {
	providers: Array<{id: PredictionProvider; name: string}>;

	predictionSettings: UntypedFormGroup;

	constructor(private locale: CxLocaleService,
		readonly formUtils: CxFormUtils,
		private fb: UntypedFormBuilder,
	) {
		super();
		this.predictionSettings = this.fb.group({
			enabled: [ false ],
			provider: [{ value: null, disabled: true }],
		});

		this.providers = [
			{id: null, name: this.locale.getString('mAccount.defaultProvider')},
			{id: PredictionProvider.compellon, name: this.locale.getString('mAccount.compellonProvider')},
			{id: PredictionProvider.datarobot, name: this.locale.getString('mAccount.datarobotProvider')}
		];

		this.addSubscription(this.predictionSettings.get('enabled').valueChanges.subscribe((value) => {
			if (value) {
				this.formUtils.enableFields(this.provider);
				this.predictionSettings.updateValueAndValidity();
			} else {
				this.formUtils.disableFields(this.provider);
				this.predictionSettings.updateValueAndValidity();
			}
		}));
	}

	getGroup(): UntypedFormGroup {
		return this.predictionSettings;
	}

	get provider(): UntypedFormControl {
		return this.predictionSettings.controls.provider as UntypedFormControl;
	}
}
