import { MetricType } from '@app/modules/metric/entities/metric-type';
import { AnalyticsDataFormattingService } from '@app/modules/widget-visualizations/utilities/analytics-data-formatting.service';
import { ObjectUtils } from '@app/util/object-utils';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { MetricValues } from '@cxstudio/reports/providers/cb/constants/metric-values';
import { WordsFilteringMode } from '@cxstudio/reports/providers/cb/constants/words-filtering-mode';
import { GroupIdentifierHelper } from '../analytic/group-identifier-helper';
import { PredefinedMetricIcons } from '@app/util/predefined-metric-icons';
import { MetricBandsService } from './metric-bands.service';
import { MetricThresholdRules } from './metric-threshold-rules.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';


const IS_THREE_BANDS = true;
const IS_FIVE_BANDS = false;

type PredefinedRawName = PredefinedMetricConstants.SENTIMENT | PredefinedMetricConstants.EASE_SCORE
	| PredefinedMetricConstants.EMOTION;

export interface BaseColorTypeDefinition {
	name: PredefinedMetricConstants | MetricType;
	rawName?: PredefinedRawName;
	getColorArray: (...args) => string[];
	getColorFunction: (...args) => any;
	getCategoryFunction: (...args) => any;
	getDataForLegendItems: (...args) => any;

	getIconFunction?: (...args) => (...returnFnArgs) => string;
	getNeutralFunction?: (...args) => any;
}

export interface PredefinedMetricColorTypeDefinition extends BaseColorTypeDefinition {
	name: PredefinedMetricConstants;
	rawName: PredefinedRawName;
	getIconFunction: (...args) => (...returnFnArgs) => string;
	getNeutralFunction?: (...args) => any;
}

export interface CustomMetricColorTypeDefinition {
	name: MetricType;

	rawName?: never;
	getIconFunction?: never;
	getNeutralFunction?: never;
}


// TODO: refactor below so that TB, BB, NPS are not needed
export type ColorTypeMetrics = (keyof typeof PredefinedMetricConstants | 'TB' | 'BB' | 'NPS' | 'FILTER' | 'CUSTOM_MATH');

type AllMetricColorTypes = {[key in ColorTypeMetrics]?: PredefinedMetricColorTypeDefinition | CustomMetricColorTypeDefinition};

export class GroupColorTypeService {
	NA_CATEGORY = {name: this.locale.getString('widget.na'), id: 'na', order: 5};
	EASE_ICONS = ObjectUtils.copy(PredefinedMetricIcons.EASE_SCORE);
	EASE_THRESHOLD_RULES = MetricThresholdRules.getEaseRules();
	NUMERIC_BREAKDOWN_THRESHOLD_RULES = MetricThresholdRules.getNumericBreakdownGroupRules();
	NUMERIC_BREAKDOWN_ICONS = PredefinedMetricIcons.NUMERIC_BREAKDOWN;
	SENTIMENT_ICONS;
	SENTIMENT_THRESHOLD_RULES = MetricThresholdRules.getSentimentGroupRules();

	BANDS_3 = true;

	constructor(
		private readonly locale: ILocale,
		private readonly metricBandsService: MetricBandsService,
		private readonly analyticsDataFormatting: AnalyticsDataFormattingService,
		private readonly betaFeaturesService: BetaFeaturesService
	) {}

	public getAllMetricColorTypes(): AllMetricColorTypes {
		const isMlSentimentEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.MACHINE_LEARNING_SENTIMENT);
		this.SENTIMENT_ICONS = ObjectUtils.copy(PredefinedMetricIcons.getSentimentIcons(isMlSentimentEnabled));
		return {
			SENTIMENT_3: {
				name: PredefinedMetricConstants.SENTIMENT_3,
				rawName: PredefinedMetricConstants.SENTIMENT,
				getColorArray: (attribute) => {
					//TODO refactor as it's the same as EASE
					let colors = ObjectUtils.copy(attribute.definition.colorPalette);
					if (colors) {
						this.metricBandsService.removeInnerBands(colors);
					}
					return colors;
				},
				getColorFunction: (attribute, byNumber: boolean = false) => {
					return byNumber
						? this.predefinedMetricColorByNumberFunction(attribute, this.SENTIMENT_THRESHOLD_RULES, this.BANDS_3)
						: this.predefinedMetricColorByValueFunction(attribute, this.BANDS_3);
				},
				getCategoryFunction: (attribute, byNumber: boolean = false) => {
					return byNumber
						? this.predefinedMetricCategoryByNumberFunction(attribute, this.SENTIMENT_THRESHOLD_RULES, this.BANDS_3)
						: this.predefinedMetricCategoryByValueFunction(attribute);
				},
				getDataForLegendItems: (attribute) => this.predefinedMetricLegendItems(attribute, this.BANDS_3),
				getIconFunction: (metric) => this.predefinedIconFunction(
					metric,
					this.SENTIMENT_THRESHOLD_RULES,
					this.SENTIMENT_ICONS,
					IS_THREE_BANDS
				),
				getNeutralFunction: (metric) => this.getNeutralSentimentFunction(metric)
			} as PredefinedMetricColorTypeDefinition,
			SENTIMENT_5: {
				name: PredefinedMetricConstants.SENTIMENT_5,
				rawName: PredefinedMetricConstants.SENTIMENT,
				getColorArray: (attribute) => attribute.definition.colorPalette,
				getColorFunction: (attribute, byNumber: boolean = false) => {
					return byNumber
						? this.predefinedMetricColorByNumberFunction(attribute, this.SENTIMENT_THRESHOLD_RULES)
						: this.predefinedMetricColorByValueFunction(attribute);
				},
				getCategoryFunction: (attribute, byNumber: boolean = false) => {
					return byNumber
						? this.predefinedMetricCategoryByNumberFunction(attribute, this.SENTIMENT_THRESHOLD_RULES)
						: this.predefinedMetricCategoryByValueFunction(attribute);
				},
				getDataForLegendItems: (attribute) => this.predefinedMetricLegendItems(attribute),
				getIconFunction: (metric) => this.predefinedIconFunction(
					metric,
					this.SENTIMENT_THRESHOLD_RULES,
					this.SENTIMENT_ICONS,
					IS_FIVE_BANDS
				),
				getNeutralFunction: (metric) => this.getNeutralSentimentFunction(metric)
			} as PredefinedMetricColorTypeDefinition,
			TB: {
				name: MetricType.TOP_BOX,
				getColorArray: (attribute) => [attribute.definition.topColor, attribute.definition.otherColor],
				getColorFunction: (attribute) => this.studioMetricColorFunction(attribute),
				getCategoryFunction: (attribute) => this.studioMetricCategoryFunction(attribute),
				getDataForLegendItems: (attribute) => {
					return {
						name: attribute.name,
						values: [
							{name: {name: attribute.definition.topDisplayName, id: 1}, color: attribute.definition.topColor},
							{name: {name: attribute.definition.otherDisplayName, id: 2}, color: attribute.definition.otherColor}
						]
					};
				}
			} as CustomMetricColorTypeDefinition,
			BB: {
				name: MetricType.BOTTOM_BOX,
				getColorArray: (attribute) => [attribute.definition.otherColor, attribute.definition.bottomColor],
				getColorFunction: (attribute) => this.studioMetricColorFunction(attribute),
				getCategoryFunction: (attribute) => this.studioMetricCategoryFunction(attribute),
				getDataForLegendItems: (attribute) => {
					return {
						name: attribute.name,
						values: [
							{name: {name: attribute.definition.otherDisplayName, id: 1}, color: attribute.definition.otherColor},
							{name: {name: attribute.definition.bottomDisplayName, id: 2}, color: attribute.definition.bottomColor}
						]
					};
				}
			} as CustomMetricColorTypeDefinition,
			NPS: {
				name: MetricType.SATISFACTION,
				getColorArray: (attribute) => [attribute.definition.topColor, attribute.definition.middleColor, attribute.definition.bottomColor],
				getColorFunction: (attribute) => this.studioMetricColorFunction(attribute),
				getCategoryFunction: (attribute) => this.studioMetricCategoryFunction(attribute),
				getDataForLegendItems: (attribute) => {
					return {
						name: attribute.name,
						values: [
							{name: {name: attribute.definition.topDisplayName, id: 1}, color: attribute.definition.topColor},
							{name: {name: attribute.definition.middleDisplayName, id: 2}, color: attribute.definition.middleColor},
							{name: {name: attribute.definition.bottomDisplayName, id: 3}, color: attribute.definition.bottomColor}
						]
					};
				}
			} as CustomMetricColorTypeDefinition,
			EASE_3: {
				name: PredefinedMetricConstants.EASE_3,
				rawName: PredefinedMetricConstants.EASE_SCORE,
				getColorArray: (attribute) => {
					let colors = ObjectUtils.copy(attribute.definition.colorPalette);
					if (colors) {
						this.metricBandsService.removeInnerBands(colors);
					}
					return colors;
				},
				getColorFunction: (attribute, byNumber: boolean = false) => {
					return byNumber
						? this.predefinedMetricColorByNumberFunction(attribute, this.EASE_THRESHOLD_RULES, this.BANDS_3)
						: this.predefinedMetricColorByValueFunction(attribute, this.BANDS_3);
				},
				getCategoryFunction: (attribute, byNumber: boolean = false) => {
					return byNumber
						? this.predefinedMetricCategoryByNumberFunction(attribute, this.EASE_THRESHOLD_RULES, this.BANDS_3)
						: this.predefinedMetricCategoryByValueFunction(attribute);
				},
				getDataForLegendItems: (attribute) => this.predefinedMetricLegendItems(attribute, this.BANDS_3),
				getIconFunction: (metric) => this.predefinedIconFunction(metric, this.EASE_THRESHOLD_RULES, this.EASE_ICONS, IS_THREE_BANDS)
			} as PredefinedMetricColorTypeDefinition,
			EASE_5: {
				name: PredefinedMetricConstants.EASE_5,
				rawName: PredefinedMetricConstants.EASE_SCORE,
				getColorArray: (attribute) => attribute.definition.colorPalette,
				getColorFunction: (attribute, byNumber: boolean = false) => {
					return byNumber
						? this.predefinedMetricColorByNumberFunction(attribute, this.EASE_THRESHOLD_RULES)
						: this.predefinedMetricColorByValueFunction(attribute);
				},
				getCategoryFunction: (attribute, byNumber: boolean = false) => {
					return byNumber
						? this.predefinedMetricCategoryByNumberFunction(attribute, this.EASE_THRESHOLD_RULES)
						: this.predefinedMetricCategoryByValueFunction(attribute);
				},
				getDataForLegendItems: (attribute) => this.predefinedMetricLegendItems(attribute),
				getIconFunction: (metric) => this.predefinedIconFunction(metric, this.EASE_THRESHOLD_RULES, this.EASE_ICONS, IS_FIVE_BANDS)
			} as PredefinedMetricColorTypeDefinition,
			EMOTION: this.getNumericBreakdownColorType(PredefinedMetricConstants.EMOTION_3, PredefinedMetricConstants.EMOTION)
		};
	}

	private predefinedMetricColorByValueFunction(attribute, threeBands: boolean = false): (item) => string {
		let field = this.getField(attribute);
		let definition = attribute.definition;
		let displayNames = ObjectUtils.copy(definition.displayNames);
		let colorPalette = ObjectUtils.copy(definition.colorPalette);
		if (threeBands) {
			this.metricBandsService.removeInnerBands(colorPalette);
			this.metricBandsService.removeOuterBands(displayNames);
		}

		return (item) => {
			if (item) {
				let value = _.isString(item) ? item : (item[field] || item._uniqName);
				let i = displayNames.indexOf(value);
				if (i > -1)
					return colorPalette[i];
			}
			return colorPalette[threeBands ? 1 : 2]; // shouldn't happen ever
		};
	}

	private predefinedIconFunction(attribute, rules, icons: string[], isThreeBands: boolean = false): (...args) => string {

		let className = ObjectUtils.copy(icons);

		let field = attribute.name;
		let definition = attribute.definition;
		let thresholds = ObjectUtils.copy(definition.thresholds);
		let thresholdRules = ObjectUtils.copy(rules);

		if (isThreeBands) {
			this.metricBandsService.removeInnerBands(className);
			this.metricBandsService.removeOutersFromThresholdsAndRules(thresholds, thresholdRules);
		}

		return (item) => {
			if (item && _.isFinite(item[field])) {
				let value = item[field];
				let index = MetricThresholdRules.calculateValueIndex(value, thresholds, thresholdRules);
				return className[index];
			}
			return className[thresholds / 2];
		};
	}

	private getNeutralSentimentFunction(attribute): (item) => boolean {
		let iconFunction = this.predefinedIconFunction(attribute, this.SENTIMENT_THRESHOLD_RULES, this.SENTIMENT_ICONS);
		return (item) => {
			if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.MACHINE_LEARNING_SENTIMENT)) {
				// ML Sentiment dScore is undefined and the middle value is mixed sentiment.
				return _.isUndefined(item.dScore);
			} else {
				// The middle item in the predefined metrics is neutral sentiment.
				return iconFunction(item) === PredefinedMetricIcons.getSentimentIcons()[2];
			}
		};
	}

	private predefinedMetricColorByNumberFunction(attribute, rules, threeBands: boolean = false): (...args) => string {
		let field = attribute.name;
		let definition = attribute.definition;
		let colorPalette = ObjectUtils.copy(definition.colorPalette);
		let thresholds = ObjectUtils.copy(definition.thresholds);
		let thresholdRules = ObjectUtils.copy(rules);

		if (threeBands) {
			this.metricBandsService.removeInnerBands(colorPalette);
			this.metricBandsService.removeOutersFromThresholdsAndRules(thresholds, thresholdRules);
		}

		return (item, getValueFunc) => {
			if (item) {
				let value = getValueFunc ? getValueFunc(item) : item[field];
				if (_.isFinite(value)) {
					let index = MetricThresholdRules.calculateValueIndex(value, thresholds, thresholdRules);
					return colorPalette[index];
				}
			}
			return colorPalette[thresholds / 2];
		};
	}

	private predefinedMetricCategoryByValueFunction(attribute): (item) => any {
		let field = this.getField(attribute);
		let definition = attribute.definition;
		let displayNames = definition.displayNames;

		return (item) => {
			if (!item) {
				return this.NA_CATEGORY;
			}
			let value = item[field] || item._uniqName;
			let name = this.analyticsDataFormatting.buildPredefinedMetricFormatter(attribute)(value);

			let i = displayNames.indexOf(value);
			if (i > -1) {
				return {name, id: value, order: i};
			}
			return this.NA_CATEGORY;
		};
	}

	private predefinedMetricCategoryByNumberFunction(attribute, rules, threeBands: boolean = false): (...args) => any {
		let field = attribute.name;
		let definition = attribute.definition;
		let displayNames = ObjectUtils.copy(definition.displayNames);
		let thresholds = ObjectUtils.copy(definition.thresholds);
		let thresholdRules = ObjectUtils.copy(rules);

		if (threeBands) {
			this.metricBandsService.removeOuterBands(displayNames);
			this.metricBandsService.removeOutersFromThresholdsAndRules(thresholds, thresholdRules);
		}

		return (item, getValueFunc) => {
			if (!item) {
				return this.NA_CATEGORY;
			}
			let value = getValueFunc ? getValueFunc(item) : item[field];
			if (_.isFinite(value)) {
				let index = MetricThresholdRules.calculateValueIndex(value, thresholds, thresholdRules);
				let name = this.analyticsDataFormatting.buildPredefinedMetricFormatter(attribute)(displayNames[index]);
				return {name, id: displayNames[index], order: index};
			}
			return this.NA_CATEGORY;
		};
	}

	private excludeLegend(values, attribute): any[] {
		if (!isEmpty(attribute.wordsList)) {
			let exclude = attribute.wordsFilteringMode === WordsFilteringMode.EXCLUDE;
			values = _.filter(values, (value) => {
				//exclude and -1, include and >-1
				return attribute.wordsList.indexOf(value.name.value) > -1 ? !exclude : exclude;
			});
		}
		return values;
	}

	private predefinedMetricLegendItems(attribute, threeBands: boolean = false): {name: string; values: any[]} {
		let definition = attribute.definition;
		let displayNames = ObjectUtils.copy(definition.displayNames);
		let colorPalette = ObjectUtils.copy(definition.colorPalette);
		if (threeBands) {
			this.metricBandsService.removeOuterBands(displayNames);
			this.metricBandsService.removeInnerBands(colorPalette);
		}

		let values = _.map(displayNames, (displayName, i) => {
			return {
				name: {
					name: displayName,
					id: i,
					value: displayName
				},
				color: colorPalette[i]
			};
		});

		values = this.excludeLegend(values, attribute);

		return { name: attribute.name, values };
	}

	private studioMetricColorFunction(attribute): (item) => string {

		let field = this.getField(attribute);
		let definition = attribute.definition;
		let prefixLength = definition.name.length + 1;

		return (item) => {
			if (item) {
				let value = item[field] || item._uniqName;
				let valueBox = _.isString(value) ? value.substr(prefixLength) : null;
				switch (valueBox) {
					case MetricValues.TOP_BOX:
						return definition.topColor;
					case MetricValues.MIDDLE_BOX:
						return definition.middleColor;
					case MetricValues.BOTTOM_BOX:
						return definition.bottomColor;
					case MetricValues.OTHER_BOX:
						return definition.otherColor;
				}
			}
			return definition.otherColor || definition.middleColor;
		};
	}

	private studioMetricCategoryFunction(attribute): (item) => {name: string; id: string; order: number} {

		let field = this.getField(attribute);
		let definition = attribute.definition;
		let prefixLength = definition.name.length + 1;

		return (item) => {
			if (item) {
				let valueBox = _.isString(item[field]) ? item[field].substr(prefixLength) : null;
				switch (valueBox) {
					case MetricValues.TOP_BOX:
						return {name: definition.topDisplayName, id: 'topBox', order: 3};
					case MetricValues.MIDDLE_BOX:
						return {name: definition.middleDisplayName, id: 'middleBox', order: 2};
					case MetricValues.BOTTOM_BOX:
						return {name: definition.bottomDisplayName, id: 'bottomBox', order: 0};
					case MetricValues.OTHER_BOX:
						return {name: definition.otherDisplayName, id: 'otherBox', order: 1};
				}
			}
			return this.NA_CATEGORY;
		};
	}

	private getField(attribute): string {
		return GroupIdentifierHelper.getIdentifier(attribute);
	}

	private getNumericBreakdownColorType(name: PredefinedMetricConstants,
		rawName: PredefinedRawName): PredefinedMetricColorTypeDefinition {
		return {
			name,
			rawName,
			getColorArray: (attribute) => {
				return ObjectUtils.copy(attribute.definition.colorPalette);
			},
			getColorFunction: (attribute, byNumber: boolean = false) => {
				if (!attribute)
					return;
				return byNumber
					? this.predefinedMetricColorByNumberFunction(attribute, this.NUMERIC_BREAKDOWN_THRESHOLD_RULES)
					: this.predefinedMetricColorByValueFunction(attribute);
			},
			getCategoryFunction: (attribute, byNumber: boolean = false) => {
				if (!attribute)
					return;
				return byNumber
					? this.predefinedMetricCategoryByNumberFunction(attribute, this.NUMERIC_BREAKDOWN_THRESHOLD_RULES)
					: this.predefinedMetricCategoryByValueFunction(attribute);
			},
			getDataForLegendItems: (attribute) => {
				if (!attribute)
					return;
				return this.predefinedMetricLegendItems(attribute);
			},
			getIconFunction: (metric) => {
				return (item) => {
					return this.NUMERIC_BREAKDOWN_ICONS[rawName];
				};
			}
		};
	}

	typeOf(name: PredefinedMetricConstants | MetricType): PredefinedMetricColorTypeDefinition | CustomMetricColorTypeDefinition {
		return _.findWhere(_.values(this.getAllMetricColorTypes()), { name });
	}

}

app.service('GroupColorType', GroupColorTypeService);
