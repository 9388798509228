import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { INode } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { ObjectUtils } from '@app/util/object-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { StudioAlert } from '@cxstudio/alerts/entities/studio-alert';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { FilterValidationMessages } from '@cxstudio/report-filters/constants/filter-validation-messages.constant';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterValidationService } from '@cxstudio/report-filters/filter-validation-service.service';
import { AlertSettingsService } from '@app/modules/alert/alert-settings.service';

@Component({
	selector: 'alert-scope-settings',
	templateUrl: './alert-scope-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertScopeSettingsComponent implements OnInit {

	@Input() alert: StudioAlert;
	@Input() project: IProjectSelection;
	@Output() validityChange = new EventEmitter<boolean>();

	loading: Promise<unknown>;
	filteringOptions: INode[];
	filterRulesError: string;

	constructor(
		private ref: ChangeDetectorRef,
		private optionsTemplatesService: OptionsTemplatesService,
		private alertSettingsService: AlertSettingsService,
		@Inject('optionsBuilderProvider') private optionsBuilderProvider: OptionsBuilderProvider,
		@Inject('filterValidationService') private filterValidationService: FilterValidationService,
	) { }

	ngOnInit(): void {
		this.loading = this.alertSettingsService.getAlertSettings(this.project).then((result) => {
			const OMIT_TEXT_FILTER = true;

			this.filteringOptions = this.optionsBuilderProvider.getBuilder()
				.withTemplate(this.optionsTemplatesService.filterItemsWithESQuery(OMIT_TEXT_FILTER))
				.withModels(result.models)
				.withAttributes(ObjectUtils.copy(result.attributes), MetricFilters.NOT_DOCUMENT_DATE)
				.withWordAttributes(result.wordAttributes)
				.build();
			this.ref.markForCheck();
		});
	}

	validate(): void {
		let validationResult = this.validateFilter();
		this.filterRulesError = validationResult === FilterValidationMessages.VALID
			? null
			: validationResult;
		this.validityChange.emit(!this.filterRulesError);
	}

	private validateFilter = (): any => {
		if (!this.alert || !this.alert.trigger) {
			return null;
		}
		let tempRules = this.alert.trigger.filterRules.filter((filterRule) => {
			return filterRule.type !== FilterRuleType.empty;
		});
		if (tempRules.length) {
			let validationResult = this.filterValidationService.getValidationResult({filterRules: tempRules});
			return FilterValidationMessages.VALID === validationResult
				? null
				: validationResult;
		} else {
			return null;
		}
	};

}
