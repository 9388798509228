import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { HTMLStringUtils } from '@app/util/html-string-utils.class';

@Injectable({
	providedIn: 'root'
})
export class SentencePreviewElementBuildersService {
	readonly SENTIMENT_DESCRIPTOR_MAP = {
		stNegativeSentiment: 'Neg_Strong_Sent',
		negativeSentiment: 'Neg_Sent',
		neutralSentiment: 'Neutral_Sent',
		positiveSentiment: 'Positive_Sent',
		stPositiveSentiment: 'Positive_Strong_Sent'
	};

	constructor(
			private locale: CxLocaleService
	) {}

	buildSentenceText = (sentence, sentimentHighlightingEnabled, sentimentColorFunction?: (...args) => string): Element[] => {
		return _.map(sentence.chunks, (chunk: any) => {
			let wrap = false;
			let outerSpan = document.createElement('span');
			let span = document.createElement('span');
			const NON_BREAKING_SPACE = '\xa0';

			if (!chunk.text) {
				span.textContent = NON_BREAKING_SPACE;
			} else {
				if (!_.isUndefined(chunk.descriptors) && chunk.descriptors[0] === 'link') {
					(span as any).replaceChildren();
					const nodes = HTMLStringUtils.stringToDOM(chunk.text);
					while (nodes.length) {
							// nodes disappear from HTMLCollection as they are moved to div
						span.appendChild(nodes[0]);
					}
					span.className = span.className ? `${span.className} no-drill` : 'no-drill';
				} else if (_.isUndefined(chunk.descriptors) || !chunk.descriptors.length) {
					span.textContent = chunk.text + ' ';
				} else {
					span.textContent = chunk.text;
				}
			}

			if (!_.isUndefined(chunk.descriptors) && sentimentHighlightingEnabled) {
				chunk.descriptors.forEach((descriptor) => {
					if (descriptor === 'ngramCenter') {
						outerSpan.className = descriptor;
						wrap = true;
					} else if (sentimentColorFunction && this.SENTIMENT_DESCRIPTOR_MAP[descriptor]) {
						span.style.color = sentimentColorFunction(this.SENTIMENT_DESCRIPTOR_MAP[descriptor]);
						span.setAttribute('aria-label',
							this.locale.getString(`metrics.sentiment_${this.SENTIMENT_DESCRIPTOR_MAP[descriptor]}`) + ' ' + chunk.text);
					} else {
						span.className = span.className ? `${span.className} ${descriptor}` : descriptor;
					}
				});
			}

			if (wrap) {
				outerSpan.appendChild(span);
				return outerSpan;
			} else {
				return span;
			}
		});
	};

	buildSentenceTextDiv(sentence, width, displayOptions): Partial<Element> {
		let cell = this.createDiv(width);
		_.each(this.buildSentenceText(sentence, displayOptions.sentimentHighlightingEnabled), (span) => { cell.appendChild(span); });
		return cell;
	}

	private createDiv(width: number, className?: string): Partial<Element> {
		let div = document.createElement('div');
		div.className = className || 'measurmentTableCell';
		div.style.width = `${width}px`;
		div.style.float = 'left';
		return div;
	}
}

app.service('sentencePreviewElementBuilders', downgradeInjectable(SentencePreviewElementBuildersService));
