import * as _ from 'underscore';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { SelectorWidgetVisualProperties } from '@cxstudio/reports/providers/cb/components/selector-widget-visualization-properties.class';
import { TopicDrillUtils } from '@cxstudio/reports/utils/contextMenu/drill/topic-drill-utils.class';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { ReportModelsService } from '@app/modules/project/model/report-models.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ModelUtils } from '@app/modules/project/model/model-utils';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';
import { Subscription } from 'rxjs';

export class SelectorWidgetDroplist implements ng.IComponentController {

	readonly MAX_ITEM_DISPLAY_LIMIT = 2;

	data;
	utils;
	props: WidgetProperties;
	widget: Widget;
	demo: boolean;
	visualProps: SelectorWidgetVisualProperties;
	capitalizer: (name: string) => string;
	toggleItem: (dataItem: any) => void;
	selectNone: () => void;

	modelTreeLoading;
	treeData;
	colors: {backgroundColor: string; color: string};

	customColorChangeSubscription: Subscription;

	constructor(
		private $scope: ISimpleScope,
		private locale: ILocale,
		private reportModelsService: ReportModelsService,
		private realDataPreviewService: RealDataPreviewService,
	) {}

	$onInit = () => {
		let grouping = this.props.selectedAttributes[0];
		if (this.isMultiselectCustomTopicGrouping(grouping)) {
			this.modelTreeLoading = this.reportModelsService.getWidgetModelTree(this.widget, parseInt(grouping.name, 10))
				.then(modelTree => ModelUtils.populateNodesPath(modelTree))
				.then((modelTree) => {
					let nodeMap = _.groupBy(this.data, (item: any) => {
						let nodeIdField = grouping.identifier + '_modelNodeId';
						return item.object[nodeIdField];
					});
					this.buildTreeData(modelTree.root, null, nodeMap, []);
					this.treeData = [modelTree.root];
				});
		} else {
			this.treeData = this.data;
		}

		this.$scope.$on('selector:clearSelections', () => {
			if (this.isMultiselectCustomTopicGrouping(this.props.selectedAttributes[0])) {
				this.onNodeClick(this.treeData[0], [], false);
			}
		});

		this.colors = this.getColors();

		if (this.demo && this.realDataPreviewService.showRealDataPreview(this.props?.widgetType)) {
			this.customColorChangeSubscription = this.realDataPreviewService.getPreviewUIChangeObserver().subscribe(() => {
				if (!this.realDataPreviewService.hasPreviewChanges()) {
					this.colors = this.getColors();
				}
			});
		}
	};

	$onDestroy(): void {
		if (this.customColorChangeSubscription) {
			this.customColorChangeSubscription.unsubscribe();
		}
	}

	private isMultiselectCustomTopicGrouping = (grouping: ReportGrouping): boolean =>
		!!AnalyticMetricTypes.isTopicLeaf(grouping) && grouping.sortOrder === 'custom' && this.visualProps.multiselect;

	private buildTreeData = (node, parent, nodeMap, children) => {
		if (!node) {
			return;
		}
		if (!node.children) {
			if (nodeMap[node.id]) {
				let selectedNode = nodeMap[node.id][0];
				selectedNode.parentId = parent.id;
				children.push(selectedNode);
			}
			return;
		}
		node.displayName = node.name;
		node.leaf = false;
		node.object = {};
		let selectedChildren = [];
		_.each(node.children, child => this.buildTreeData(child, node, nodeMap, selectedChildren));
		if (!_.isEmpty(selectedChildren)) {
			node.children = selectedChildren;
			node.object.selected = !_.any(selectedChildren, (child: any) => !child.object.selected);
			children.push(node);
		} else {
			node.children = null;
		}
	};

	handleNodeClick = (node): void => {
		let leafNodes = this.onNodeClick(node, []);
		this.toggleItem(leafNodes.length === 1 ? leafNodes[0] : leafNodes);
		this.checkParent(node);
	};

	onNodeClick = (node, leafNodes, selected?): any[] => {
		if (node.leaf) {
			leafNodes.push(node.object);
		} else {
			node.object.selected = !_.isUndefined(selected) ? selected : !node.object.selected;
			_.each(node.children, (child: any) => {
				if (child.object.selected !== node.object.selected) {
					this.onNodeClick(child, leafNodes);
				}
			});
		}
		return leafNodes;
	};

	checkParent = (node): void => {
		if (node.parentId) {
			let parentNode = TopicDrillUtils.findInHierarchy(this.treeData, 'id', node.parentId) as any;
			parentNode.object.selected = !_.any(parentNode.children, (child: any) => !child.object.selected);
			this.checkParent(parentNode);
		}
	};

	isItemChecked = (item): boolean => !!item.selected;

	showCheckbox = (): boolean => !!this.visualProps.multiselect;

	dropdownToggled = (open: boolean) => {
		let widgetElementId = `#widget-${this.utils.widgetId}`;
		$(`${widgetElementId} .header-hidden .br-widget-header`).css('animation-play-state', open ? 'paused' : '');
		if (open) {
			setTimeout(() => {
				$(widgetElementId).addClass('widget:active');
				$(`${widgetElementId} .dropdown-menu :focusable`).first().trigger('focus');
			});
		} else {
			$(widgetElementId).removeClass('widget:active');
		}
	};

	getLabel = (): {displayName: string } => {
		let items = this.getSelectedItemNames();
		let text = this.locale.getString('common.selectPrompt');
		if (!_.isEmpty(items)) {
			text = items.length <= this.MAX_ITEM_DISPLAY_LIMIT
				? items.join(', ')
				: this.locale.getString('filter.firstAndMoreCount', {
					first: items[0],
					moreCount: items.length - 1
				});
		}
		return { displayName: text };
	};

	getTooltip = (): string => {
		let items = this.getSelectedItemNames();
		return items.length > 0 ? items.join(', ') : this.locale.getString('common.selectPrompt');
	};

	private getSelectedItemNames = (): string[] => _.chain(this.data)
		.filter(item => !!item.object.selected)
		.map(item => this.capitalizer(item.displayName))
		.value();

	getColors = (): {backgroundColor: string; color: string} => {
		let backgroundColor: string = this.visualProps.customColor;
		if (!backgroundColor) {
			return undefined;
		}

		let color: string = ColorUtilsHelper.pickContrastTextColor(backgroundColor);
		return { backgroundColor, color };
	};
}

app.component('selectorWidgetDroplist', {
	bindings: {
		data: '<',
		utils: '<',
		props: '<',
		widget: '<',
		demo: '<',
		visualProps: '<',
		capitalizer: '<',
		selectNone: '&',
		toggleItem: '<'
	},
	controller: SelectorWidgetDroplist,
	template: `
		<div class="selector-pill"
			dropdown-position="auto"
			dropdown-position-top-bound-element="#dsh-widget-container"
			cg-busy="$ctrl.modelTreeLoading">
			<hierarchy-dropdown
				multiselect="$ctrl.visualProps.multiselect"
				select-none="$ctrl.selectNone()"
				select-none-text="{{::'common.clear'|i18n}}"
				show-clear-button="true"
				button-style="$ctrl.colors"
				show-node-checkbox="$ctrl.showCheckbox()"
				node-is-checked="$ctrl.isItemChecked(node.object)"
				node-is-selected="$ctrl.isItemChecked(node.object) && !node.children"
				on-node-click="$ctrl.handleNodeClick(node)"
				hierarchy-list="$ctrl.treeData"
				display-property="displayName"
				evaluate-current-value="$ctrl.getLabel()"
				evaluate-tooltip="$ctrl.getTooltip()"
				value-placeholder="{{::'common.selectPrompt'|i18n}}"
				search-placeholder="{{::'common.search'|i18n}}"
				on-dropdown-toggle="$ctrl.dropdownToggled(open)"
				container-title="$ctrl.widget.displayName">
			</hierarchy-dropdown>
		</div>`
});
