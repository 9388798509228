import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { AssetSpecificUtilsService } from '@app/modules/unified-templates/common-templates/asset-specific-utils.service';
import { AssetTemplateApiService } from '@app/modules/unified-templates/common-templates/asset-template-api.service';
import { CreateFromTemplateDialogComponent } from '@app/modules/unified-templates/common-templates/asset/create-from-template-dialog/create-from-template-dialog.component';
import { TemplateAssetType } from '@app/modules/unified-templates/common-templates/dto/template-asset-type';
import { UnifiedTemplate, UnifiedTemplateTreeItem } from '@app/modules/unified-templates/common-templates/dto/unified-template';
import { TemplateFolderApiFactory } from '@app/modules/unified-templates/common-templates/template-folder-api-factory.service';
import { TemplateCreateDialogComponent } from '@app/modules/unified-templates/common-templates/template/template-create-dialog/template-create-dialog.component';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { IFolderService } from '@cxstudio/folders/folder-service.factory';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { Subject } from 'rxjs';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';

const SKIP_TREE_UPDATE = true; // skip slick grid logic

@Injectable({
	providedIn: 'root'
})
export class AssetTemplateActionsFactoryService {
	constructor(
		private locale: CxLocaleService,
		private cxDialogService: CxDialogService,
		private assetSpecificUtils: AssetSpecificUtilsService,
		private templateFolderApiFactory: TemplateFolderApiFactory,
		private assetTemplateApi: AssetTemplateApiService,
		@Inject('globalNotificationService') private globalNotificationService: GlobalNotificationService,
		@Inject('FolderService') private readonly FolderService,
		@Inject('exportUtils') private exportUtils: ExportUtils,
	) {}

	get(assetType: TemplateAssetType): AssetTemplateActions {
		return new AssetTemplateActions(
			assetType,
			this.locale,
			this.cxDialogService,
			this.assetTemplateApi,
			this.assetSpecificUtils,
			new this.FolderService(FolderTypes.UNIFIED_TEMPLATES,
				this.templateFolderApiFactory.getAssetFolderApi(assetType)),
			this.globalNotificationService,
			this.exportUtils,
		);
	}
}

export class AssetTemplateActions {

	private onChange = new Subject<UnifiedTemplate[] | IFolderItem[]>();
	private onLoading = new Subject<Promise<any>>();

	constructor(
		private assetType: TemplateAssetType,
		private locale: CxLocaleService,
		private cxDialogService: CxDialogService,
		private assetTemplateApi: AssetTemplateApiService,
		private assetSpecificUtils: AssetSpecificUtilsService,
		public folderService: IFolderService,
		private globalNotificationService: GlobalNotificationService,
		private exportUtils: ExportUtils,
	) {}

	getChangeObserver() {
		return this.onChange.asObservable();
	}

	getLoadingObserver() {
		return this.onLoading.asObservable();
	}

	createFromTemplate(template: UnifiedTemplateTreeItem): void {
		this.onLoading.next(this.assetTemplateApi.getTemplateAssetPlaceholdersData(this.assetType, template.id)
			.then(placeholders => {
				this.cxDialogService.openDialog(CreateFromTemplateDialogComponent, {
					modalTitle: this.locale.getString('templates.fillPlaceholdersTitle'),
					template,
					placeholders,
					propertiesComponent: this.assetSpecificUtils.getTemplatePropertiesClass(this.assetType),
					replacementsLabel: this.assetSpecificUtils.getTemplateReplacementLabel(this.assetType),
				}, {size: ModalSize.MEDIUM}).result.then((asset: any) => {
					this.assetSpecificUtils.onCreationSuccess(this.assetType, asset);
				}, _.noop);
			}, _.noop));
	}

	createFolder(templates: (UnifiedTemplateTreeItem | IFolderItem)[]): void {
		this.folderService.createFolder(undefined, templates, undefined, SKIP_TREE_UPDATE).then((createdFolder: IFolderItem) => {
			this.globalNotificationService.addCreatedNotification(createdFolder.name);
			this.onChange.next();
		});
	}

	createSubFolder(parentFolder: IFolderItem, templates: (UnifiedTemplateTreeItem | IFolderItem)[]): void {
		this.folderService.createFolder(parentFolder, templates, undefined, SKIP_TREE_UPDATE).then((createdFolder: IFolderItem) => {
			this.globalNotificationService.addCreatedNotification(createdFolder.name);
			this.onChange.next();
		});
	}

	renameFolder(folder: IFolderItem, templates: (UnifiedTemplateTreeItem | IFolderItem)[]): void {
		this.folderService.renameFolder(folder, templates, SKIP_TREE_UPDATE).then(() => {
			this.onChange.next([ folder ]);
		});
	}

	removeFolder(folder: IFolderItem): void {
		this.folderService.removeFolder(folder, null, SKIP_TREE_UPDATE).then(() => {
			this.globalNotificationService.deleteFolderNotification(folder.name);
			this.onChange.next();
		});
	}

	moveToFolder(item: UnifiedTemplate | IFolderItem, folderTo: IFolderItem): void {
		if (!folderTo) {
			return;
		}

		this.folderService.moveToFolder(item, folderTo, null, SKIP_TREE_UPDATE).then(() => {
			if (!folderTo.id || folderTo.id === 0) {
				folderTo = null;
			}

			this.globalNotificationService.addItemMovedNotification(item.name, folderTo);
			this.onChange.next();
		});
	}

	changePublicStatus(item: UnifiedTemplate, isPublic: boolean): void {
		this.assetTemplateApi.changePublicStatus(item.assetType, item.id, isPublic).then(() => {
			item.sharingStatus = isPublic ? SharingStatus.PUBLIC : SharingStatus.PRIVATE;
			item.modifiedDate = new Date();
			this.onChange.next([item]);
		});
	}

	deleteTemplate(item: UnifiedTemplate): void {
		this.cxDialogService.warningWithConfirm(
			this.locale.getString('templates.deleteTemplateHeader', {templateName: item.name}),
			this.locale.getString('templates.deleteTemplateConfirmationMsg', {templateName: item.name})
		).result.then(() => this.assetTemplateApi.deleteTemplate(item.assetType, item.id), _.noop)
			.then(() => this.onChange.next());
	}

	editProperties(item: UnifiedTemplate): void {
		this.cxDialogService.openDialog(TemplateCreateDialogComponent, {
			titleLabel: this.locale.getString('templates.editProperties'),
			type: this.assetType,
			showProperties: true,
			showMappings: false,
			templateId: item.id,
		}).result.then((template: UnifiedTemplate) => {
			this.onLoading.next(this.assetTemplateApi.updateTemplateProperties(this.assetType, item.id, template)
				.then(() => this.onChange.next()));
		}, _.noop);
	}

	editMappings(item: UnifiedTemplate): void {
		if (_.isEmpty(item.placeholders)) {
			this.cxDialogService.notify(this.locale.getString('templates.editMappings'),
				this.locale.getString('templates.noMappings'));
			return;
		}
		this.cxDialogService.openDialog(TemplateCreateDialogComponent, {
			titleLabel: this.locale.getString('templates.editMappings'),
			type: this.assetType,
			showProperties: false,
			showMappings: true,
			templateId: item.id,
		}).result.then((template: UnifiedTemplate) => {
			this.onLoading.next(this.assetTemplateApi.updateTemplateMappings(this.assetType, item.id, template.placeholders)
				.then(() => {
					item.placeholders = template.placeholders;
					item.modifiedDate = new Date();
					this.onChange.next([item]);
				}));
		}, _.noop);
	}

	exportTemplate(item: UnifiedTemplate): void {
		this.assetTemplateApi.exportTemplate(item.assetType, item.id).then((response) => {
			let fileName = response.headers('file-name');
			this.exportUtils.exportText(fileName, response.data);
		}, _.noop);
	}


}
