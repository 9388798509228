import { ITableColumnFormatter, TableColumn } from '@cxstudio/reports/entities/table-column';
import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import TableFormattersService from '@cxstudio/components/table/table-formatters.service';
import OAuthScope from '@app/modules/system-administration/oauth/oauth-scope';
import { OAuthAuthorizedGrantType, OAuthAuthorizedGrantTypes } from '@app/modules/system-administration/oauth/oauth-authorized-grant-type.factory';
import { GridFormatter } from '@cxstudio/grids/grid-formatter-service';
import { DomSanitizer } from '@angular/platform-browser';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';

@Injectable({
	providedIn: 'root'
})
export class FormattedTableColumnsOauthClientsService {

	constructor(
		@Inject('tableFormattersService') private tableFormattersService: TableFormattersService,
		@Inject('gridFormatterService') private gridFormatterService: GridFormatter,
		private locale: CxLocaleService,
		protected oauthAuthorizedGrantTypes: OAuthAuthorizedGrantTypes,
		private domSanitizer: DomSanitizer
	) { }

	getAccessTokenValidityColumn(): TableColumn<any> {
		return {
			name: 'accessTokenValiditySeconds',
			displayName: this.locale.getString('administration.oauthAccessToken'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.05
		};
	}

	getRefreshTokenValidityColumn(): TableColumn<any> {
		return {
			name: 'refreshTokenValiditySeconds',
			displayName: this.locale.getString('administration.oauthRefreshToken'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.05
		};
	}

	getOauthLinksEnabledColumn(): TableColumn<any> {
		return {
			name: 'additionalInformation.enableUnifiedLinks',
			displayName: this.locale.getString('administration.oauthSuiteLinksEnabled'),
			formatter: this.formatCheckMarkOrEmpty,
			width: 0.05
		};
	}

	getOauthLinksNumberColumn(): TableColumn<any> {
		return {
			name: 'additionalInformation.unifiedLinks',
			displayName: this.locale.getString('administration.oauthSuiteLinks'),
			formatter: this.formatLinksNumber,
			width: 0.05
		};
	}

	getSaveClientStateColumn(): TableColumn<any> {
		return {
			name: 'additionalInformation.saveClientState',
			displayName: this.locale.getString('administration.oauthSaveClientState'),
			formatter: this.formatCheckMarkOrEmpty,
			width: 0.05
		};
	}

	getEnabledColumn(action: any): TableColumn<any> {
		return {
			name: 'disabled',
			displayName: this.locale.getString('common.enabled'),
			formatter: this.formatEnabled,
			width: 0.05,
			action
		};
	}

	getClientIdWithWarningColumn(): TableColumn<any> {
		return {
			name: 'clientId',
			displayName: this.locale.getString('administration.oauthClientId'),
			formatter: this.formatClientIdWarning,
			width: 0.1
		};
	}

	getHamburgerColumn(action: any): TableColumn<any> {
		return {
			name: 'hamburger',
			displayName: '',
			formatter: this.formatHamburgerButton,
			width: 0.05,
			action
		};
	}

	getRegisteredRedirectUriColumn(): TableColumn<any> {
		return {
			name: 'registeredRedirectUri',
			displayName: this.locale.getString('administration.oauthRegisteredRedirectUri'),
			formatter: this.formatRegisteredRedirectUri,
			width: 0.2
		};
	}

	getMasterAccountColumn(masterAccounts: Array<MasterAccount>): TableColumn<any> {
		return {
			name: 'additionalInformation.masterAccountId',
			displayName: this.locale.getString('administration.masterAccount'),
			formatter: this.getMasterAccountFormatter(masterAccounts),
			width: 0.2
		};
	}

	getScopeColumn(): TableColumn<any> {
		return {
			name: 'scope',
			displayName: this.locale.getString('administration.oauthScope'),
			formatter: this.formatScope,
			width: 0.2
		};
	}

	getAuthorizedGrantTypesColumn(): TableColumn<any> {
		return {
			name: 'authorizedGrantTypes',
			displayName: this.locale.getString('administration.oauthAuthorizedGrantTypes'),
			formatter: this.formatGrantTypes,
			width: 0.1
		};
	}

	private formatHamburgerButton = (object: any, field: string): string => {
		let openMenuTitle = this.locale.getString('common.openMenu');
		return this.domSanitizer
			.bypassSecurityTrustHtml(
				`<span class="btn q-icon-layer br-menu-burger" aria-hidden="true" title="${openMenuTitle}"></span>`
			) as string;
	};

	private formatCheckMarkOrEmpty = (object: any, field: string): string => {
		let enabled: boolean = this.tableFormattersService.getFieldByPath(object, field);
		if (enabled) {
			return '<span class="q-icon q-icon-check" aria-hidden="true"></span>';
		}
		return '';
	};

	private formatClientIdWarning = (object: any, field: string): string => {
		let clientId: string = this.tableFormattersService.getFieldByPath(object, field);
		let broken: boolean = object.broken;
		if (broken) {
			return '<span class="q-icon q-icon-warning text-danger" aria-hidden="true"></span> ' + clientId;
		}
		return clientId;
	};

	private formatRegisteredRedirectUri = (object: any, field: string): string => {
		let linksArray: string[] = this.tableFormattersService.getFieldByPath(object, field);
		return _.chain(linksArray)
			.filter((link) => !_.isNull(link))
			.map((link) => `<a href="${link}" target="_blank">${link}</a>`)
			.join('<br/>')
			.value();
	};

	private formatScope = (object: any, field: string): string => {
		let scopes: OAuthScope[] = this.tableFormattersService.getFieldByPath(object, field);
		return _.map(scopes, (scope) => scope.name).join(', ');
	};

	private formatGrantTypes = (object: any, field: string): string => {
		let grantTypeNames: string[] = this.tableFormattersService.getFieldByPath(object, field) || [];
		let grantTypes = _.map((grantTypeNames), (grantType: OAuthAuthorizedGrantType) => {
			return this.oauthAuthorizedGrantTypes.getGrantType(grantType)?.displayName;
		});
		return grantTypes.filter(type => !!type).join(', ');
	};

	private formatLinksNumber = (object: any, field: string): string => {
		let links: string[] = this.tableFormattersService.getFieldByPath(object, field);
		if (!_.isUndefined(links)) {
			return links.length as unknown as string;
		}
		return '';
	};

	private formatEnabled = (object: any, field: string): string => {
		let clientDisabled: boolean = this.tableFormattersService.getFieldByPath(object, field);
		let disableToggle: boolean = object.broken;
		let clientId: string = object.clientId;
		let getSwitch = this.gridFormatterService.getLabeledToggleSwitchGenerator(clientId, 'mr-0 ml-0 w-64', false);

		// Note: disabled clients are marked as "disabled=true". Reversing it to display as standard "enabled" value
		return this.domSanitizer.bypassSecurityTrustHtml(getSwitch(!clientDisabled, disableToggle)) as string;
	};

	getMasterAccountFormatter = (masterAccounts: Array<MasterAccount>): ITableColumnFormatter<any> => {
		return (object: any, field: string): string => {
			const masterAccountId: number = this.tableFormattersService.getFieldByPath(object, field);
			if (_.isUndefined(masterAccountId)) {
				return '';
			}

			const selectedMasterAccount: MasterAccount = _.find(masterAccounts,
				masterAccount => {
					return masterAccount.accountId === masterAccountId;
				});

			if (_.isUndefined(selectedMasterAccount)) {
				return '<span class="q-icon q-icon-warning text-danger" aria-hidden="true"></span> ' + masterAccountId;
			} else {
				return selectedMasterAccount.accountName;
			}

		};
	};



}
app.service('formattedTableColumnsOauthClientsService', downgradeInjectable(FormattedTableColumnsOauthClientsService));
