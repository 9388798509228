import * as _ from 'underscore';
import * as moment from 'moment';
import { Component, OnInit, ViewChild, Inject, Input, KeyValueDiffer, KeyValueDiffers, DoCheck, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Security } from '@cxstudio/auth/security-service';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { SharingService } from '@cxstudio/sharing/sharing-service.service';
import { ShareAction } from '@cxstudio/common/share-actions.constant';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { User } from '@cxstudio/user-administration/users/entities/user';
import { EmailUtils } from '@app/modules/user-administration/editor/email-utils.class';
import { UserApiService } from '@cxstudio/services/data-services/user-api-service';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { TypeGuards } from '@app/util/typeguards.class';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { ScheduleDistributionService } from '../schedule-distribution.service';
import { ScheduleUtilsService } from '../schedule-utils.service';
import { TimezoneService } from '@app/shared/services/timezone.service';
import { DomainsProcessingService } from '@app/modules/account-administration/api/domains-processing.service';
import { MentionConfig } from 'angular-mentions';
import { IScheduledJobType } from '@app/modules/schedules/jobs/scheduled-job-type';
import { ScheduledJobTypes } from '@app/modules/schedules/jobs/scheduled-job-types.service';
import { ScheduleSettings } from '@app/modules/schedules/schedule-settings';
import { DashboardScheduleService } from '@app/modules/dashboard/services/scheduling/dashboard-schedule.service';
import { AllowedDomains } from '@app/modules/system-administration/master-account/email-domains/allowed-domains';
import { SchedulePeriod } from '@app/modules/dashboard/schedule/schedule-period';
import { DateTimeFormat } from '@cxstudio/services/date-service.service';
import { ObjectSharingConstants } from '@app/modules/asset-management/object-sharing-constants';

export interface Subscriber {
	_name: string;
	type: string;
	displayName: string;
	entity: any;
}

@Component({
	selector: 'schedule-panel',
	templateUrl: './schedule-panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchedulePanelComponent implements OnInit, DoCheck {

	@Input() dashboard: Dashboard;
	@Input() settings: ScheduleSettings;
	@Input() index: number;
	@Input() numberOfUsers: number;
	@Input() hierarchies: any[];
	@Input() subscribers: Subscriber[];
	@Input() autoOpen: boolean;
	@Input() users: User[];
	@Input() dateFormat: string;
	@Input() removeScheduleFunction: (index) => void;
	@Input() updateSummaryFunction: (index, summaryDescription) => void;
	@Input() validateFunction: (value, index) => boolean;
	@Input() revert: (index) => void;
	@Input() saveSchedule: (index) => void;
	@Input() updateMasterSchedule: (index, fields) => void;
	@Input() isModified: (index) => boolean;
	@Input('getSummaryFunction') getSummary: (index) => any;
	@Input('testEmailFunction') sendTestEmail: (index: number) => void;

	@ViewChild('scheduleForm', {static: true}) public scheduleForm: UntypedFormGroup;

	distributionOptions: any[];
	defaultValues: any;
	jobTypeDefinitions: { [key: string]: IScheduledJobType };
	disableActiveFor: boolean;
	distributionDefinitions: any;
	schedulingOptions;
	weekDaysOptions;
	startTimeOptions;
	showampm;
	ampmOptions;
	recurrence;
	minDate;
	scheduleDescription;
	expirationMessage;
	minOptions;
	zoneOffsetOptions;
	time: {
		hour: string;
		min: string;
		ampm: string;
		timezoneOffset: string | number;
	};
	hourOptions: string[];
	inputOptions: Array<{label: string}>;
	inputOptionsWithHierarchy: any[];
	activeForOptions: any[];
	jobTypes: any[];
	selectedDistribution: any;
	entityMap: {[key: string]: any};

	searchText: string;
	disableAddButton: boolean;
	tableMaxHeight: number;
	allowedDomains: AllowedDomains;
	expired: boolean;
	showWeekRequired: boolean;
	emailCount: number;
	emailWarningClass: string;
	emailWarning: string | void;
	hierarchyWarning: string;
	inviteEntity: Subscriber;
	typeaheadSearchLoading: Promise<void>;
	emailVariableMentionConfig: MentionConfig;
	differ: KeyValueDiffer<string, any>;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly differsService: KeyValueDiffers,
		private readonly scheduleUtilsService: ScheduleUtilsService,
		private readonly scheduleDistributionService: ScheduleDistributionService,
		private readonly timezoneService: TimezoneService,
		@Inject('security') private readonly security: Security,
		@Inject('scheduledJobTypes') private readonly scheduledJobTypes: ScheduledJobTypes,
		@Inject('currentWidgets') private readonly currentWidgets: ICurrentWidgets,
		@Inject('dashboardExportService') private readonly dashboardExportService,
		private readonly domainsProcessingService: DomainsProcessingService,
		@Inject('userApiService') private readonly userApiService: UserApiService,
		@Inject('sharingService') private readonly sharingService: SharingService,
		private readonly dashboardScheduleService: DashboardScheduleService,
	) {}

	ngOnInit(): void {
		this.subscribers = this.subscribers || [];

		this.defaultValues = {};

		this.jobTypeDefinitions = this.scheduledJobTypes.getTypes();
		this.disableActiveFor = this.scheduleUtilsService.isActiveForDisabled();

		this.distributionDefinitions = this.scheduleDistributionService.getOptions();

		this.schedulingOptions = this.scheduleUtilsService.getDefaultSchedulingOptions();
		this.weekDaysOptions = this.scheduleUtilsService.getWeekDaysOptions();
		this.startTimeOptions = this.scheduleUtilsService.getStartTimeOptions();

		this.settings.startDate.setSeconds(0);
		this.showampm = this.security.loggedUser.dateFormat === 'us';
		this.ampmOptions = this.scheduleUtilsService.getAmpmOptions();

		this.recurrence = {day: 1, week: 1, month: 1, dayOfMonth: 1};

		const localTimezoneOffset = moment().utcOffset();


		this.minDate = new Date();
		if (!this.dateFormat) {
			this.dateFormat = DateTimeFormat.DEFAULT_DATE;
		}

		this.scheduleDescription = '';
		this.expirationMessage = '';
		this.minOptions = this.scheduleUtilsService.getMinOptions();

		// set timezone offset to local if it is not already set
		this.settings.timezoneOffset = this.settings.timezoneOffset || String(localTimezoneOffset) as any;
		this.zoneOffsetOptions = this.timezoneService.getUTCOffsets();

		this.time = this.scheduleUtilsService.getDefaultTimeObject(this.settings.startDate, this.settings.timezoneOffset);
		if (!this.isPDFJob()) {
			this.setAmPmHour();
		}
		if (this.showampm) {
			this.hourOptions = _.range(1, 13).map(i => '' + i); // 1..12
		} else {
			this.hourOptions = _.range(0, 24).map(i => '' + i); // 0..23
		}

		this.inputOptions = [{
			label: 'dashboardName'
		}, {
			label: 'dashboardOwner'
		}, {
			label: 'distributionDate'
		}];

		this.inputOptionsWithHierarchy = this.inputOptions.concat({
			label: 'hierarchyLevel',
			isHierarchy: true
		} as any);

		// when we support the Active For field, we'll need to initialize it
		if (!this.disableActiveFor) {
			this.activeForOptions = this.scheduleUtilsService.getActiveForOptions();
			this.settings.activeFor = this.settings.activeFor || this.activeForOptions[1].value;
		}

		this.defaultValues[this.distributionDefinitions.SPECIFIC_USERS.type] = {
			defaultPdfName: this.locale.getString('schedule.defaultPdfName'),
			defaultEmailTitle: this.locale.getString('schedule.defaultEmailTitle'),
			defaultEmailBody: this.locale.getString('schedule.defaultEmailBody')
		};

		this.defaultValues[this.distributionDefinitions.HIERARCHY.type] = {
			defaultPdfName: this.locale.getString('schedule.defaultPdfNameWithHierarchy'),
			defaultEmailTitle: this.locale.getString('schedule.defaultEmailTitleWithHierarchy'),
			defaultEmailBody: this.locale.getString('schedule.defaultEmailBodyWithHierarchy')
		};

		this.emailVariableMentionConfig = {
			triggerChar: '{',
			mentionSelect: (variable) => `{${variable.label}}`
		};

		this.setInitialState();
		this.settings.type = this.determineScheduleType();
		this.processCron();
		this.refreshJobOptions();

		this.scheduleForm.statusChanges.subscribe(status => {
			this.validateFunction(status === 'VALID', this.index);
		});

		this.differ = this.differsService.find(this.settings).create();
	}

	ngDoCheck(): void {
		if (this.differ) {
			const changes = this.differ.diff(this.settings);
			if (changes) {
				changes.forEachChangedItem((r) => {
					if (r.key === 'startDate') {
						if (TypeGuards.isString(this.settings.startDate)) {
							this.settings.startDate = r.previousValue.valueOf();
						}
						this.processCron();
					} else if (r.key === 'type') {
						if (r.currentValue !== 'once') {
							this.settings.endDate = this.scheduleUtilsService
								.getScheduleExpirationDate(this.settings.startDate);
						}
						this.processCron();
					} else if (r.key === 'notify' || r.key === 'notifyApp') {
						this.processCron();
					}
				});
			}
		}
	}

	// set up available scheduled job types -- currently not allowing PDF distribution if hierarchy is not selected
	private refreshJobOptions = () => {
		this.jobTypes = [ this.jobTypeDefinitions.REFRESH, this.jobTypeDefinitions.DISTRIBUTE_PDF ];
		this.distributionOptions = [ this.distributionDefinitions.SPECIFIC_USERS ];

		if (this.hierarchies && this.hierarchies.length > 0) {
			this.distributionOptions.push(this.distributionDefinitions.HIERARCHY);
			const options = _.map(this.hierarchies, (hierarchy) => {
				hierarchy.type = this.distributionDefinitions.HIERARCHY.type;
				hierarchy.uniqueValue = hierarchy.type + hierarchy.id;
				hierarchy.displayName = hierarchy.name;
				return hierarchy;
			});
			this.distributionOptions.pushAll(options);
			if (this.settings.distribution
				&& this.settings.distribution.type === this.distributionDefinitions.HIERARCHY.type) {
				this.selectedDistribution = this.distributionDefinitions.HIERARCHY.type + this.settings.distribution.hierarchyId;

			}
		}
		if (this.settings.distribution && this.settings.distribution.type === this.distributionDefinitions.SPECIFIC_USERS.type) {
			this.selectedDistribution = this.distributionDefinitions.SPECIFIC_USERS.uniqueValue;
			this.refreshSubscribers();
		}

		this.settings.jobType = this.settings.jobType || this.jobTypeDefinitions.REFRESH.value;
	};

	private refreshSubscribers = () => {
		this.entityMap = {};
		this.subscribers.forEach(this.addEntity);

		const sharedEntities = {
			shareEntities: {
				USER: this.settings.distribution.users || [],
				GROUP: this.settings.distribution.groups || [],
				META_GROUP: []
			}
		};

		const sharingTableContent = this.sharingService.produceSharingTableContent(sharedEntities, this.entityMap);
		this.settings.distribution.subscribers = sharingTableContent?.users;
		this.settings.distribution.subscribers?.pushAll(sharingTableContent?.groups);
		this.sharingService.removeSharedEntities(this.subscribers, this.settings.distribution.subscribers);

		this.recalculateUsersCounter();
	};

	private addEntity = (entity) => {
		this.entityMap = this.entityMap || {};

		const entityKey = entity.type + entity._name;
		if (!this.entityMap[entityKey]) {
			this.entityMap[entityKey] = entity;
		}
	};

	private addAvailableEntity = (entity) => {
		const entityKey = entity.type + entity._name;
		if (!this.entityMap[entityKey]) {
			this.subscribers.push(entity);
			this.entityMap[entityKey] = entity;
		}
	};


	private getNormalHour = () => {
		return this.scheduleUtilsService.getNormalHour(this.showampm, this.time);
	};

	private setAmPmHour = () => {
		this.scheduleUtilsService.setAmPmHour(this.time, this.showampm);
	};

	private determineScheduleType = () => {
		return this.scheduleUtilsService.getScheduleType(this.settings.cronExpression);
	};

	updateHour = (adjust) => {
		const hours = adjust ? this.getNormalHour() : this.time.hour;
		this.settings.startDate.setHours(Number.parseInt(hours, 10));
		this.processCron();
	};

	updateMinute = () => {
		this.settings.startDate.setMinutes(Number.parseInt(this.time.min, 10));
		this.processCron();
	};

	updateAmPm =  () => {
		this.settings.startDate.setHours(Number.parseInt(this.getNormalHour(), 10));
		this.processCron();
	};

	updateTimezone =  () => {
		this.time.timezoneOffset = this.settings.timezoneOffset;
		this.processCron();
	};

	private setInitialState = () => {

		if (this.settings.cronExpression) {
			const result = this.scheduleUtilsService.getScheduleInitialState(this.settings.cronExpression);
			this.recurrence = result.recurrence;
			this.weekDaysOptions = result.weekDaysOptions;
		}

		this.initDistribution();
		this.initPdfExportParams();
		this.updateSummaryFunction(this.index, this.scheduleDescription);
	};

	private initDistribution = () => {
		this.searchText = '';
		this.disableAddButton = true;
		this.tableMaxHeight = 157;

		if (!this.settings.distribution) {
			this.settings.distribution = {
				type: this.distributionDefinitions.SPECIFIC_USERS.type
			};
			this.settings.distribution.subscribers = [];
			this.setEmailContentToDefault();
		}

		this.userApiService.getAllowedDomains().then((response) => {
			this.allowedDomains = response;
		});
	};

	private initPdfExportParams = () => {
		const pdfParams = this.dashboardExportService.getExportParams(this.dashboard,
			this.currentWidgets.getAllWidgets(this.dashboard.id + ''));
		this.settings.height = this.settings.height || pdfParams.height;
		this.settings.width = this.settings.width || pdfParams.width;
		this.settings.useAutosizing = !isEmpty(this.settings.useAutosizing) ? this.settings.useAutosizing : true;
	};

	processCron = () => {
		const cronProcessingResult = this.scheduleUtilsService.processCron(this.settings.startDate, this.settings.endDate,
			this.settings.type, this.settings.activeFor, this.time, this.recurrence, DateTimeFormat.BASIC_DATE,
			this.weekDaysOptions, this.isPDFJob());

		this.scheduleDescription = cronProcessingResult.scheduleDescription;
		this.expired = this.scheduleUtilsService.isExpired(this.settings.endDate);
		this.expirationMessage = cronProcessingResult.expirationMessage;
		this.settings.cronExpression = cronProcessingResult.cronExpression;
		this.showWeekRequired = cronProcessingResult.showWeekRequired;

		this.updateSummaryFunction(this.index, this.scheduleDescription);
	};

	removeSchedule = (index) => {
		this.removeScheduleFunction(index);
	};

	getSummaryWithAction = (index) => {
		const titleAction = _.find(this.jobTypes, {value: this.settings.jobType}).titleString;
		return `${titleAction} ${this.getSummary(index)}`;
	};

	updateFrequency = () => {
		if (this.settings.type === SchedulePeriod.ONLY_ONCE) {
			this.minDate = new Date();
		}
		this.processCron();
	};

	renewSchedule = () => {
		const newExpirationDate = this.scheduleUtilsService.getScheduleExpirationDate(new Date());

		this.dashboardScheduleService.renewSchedule(this.settings, newExpirationDate).then(() => {
			this.settings.active = true;
			this.settings.endDate = newExpirationDate;
			this.updateMasterSchedule(this.index, ['active', 'endDate']);
			this.processCron();
		});
	};

	isScheduleRenewable = () => {
		const currentDate = this.scheduleUtilsService.getScheduleExpirationDate(new Date());
		const endDate = this.settings.endDate;

		return moment(currentDate).diff(endDate, 'days') > 1;
	};

	private updateCounterMessage = (userCount: number) => {
		this.emailCount = userCount;
		this.emailWarningClass = this.scheduleUtilsService.getWarningClass(this.emailCount);

		const type = this.scheduleDistributionService.findType(this.settings.distribution.type);
		this.emailWarning = type ? type.getEmailWarning(this.emailCount) : '';
	};


	recalculateUsersCounter = () => {
		if (isEmpty(this.settings) || isEmpty(this.settings.distribution) || isEmpty(this.settings.distribution.type)) {
			this.updateCounterMessage(0);
			return;
		}

		const distribution = this.scheduleDistributionService.findType(this.settings.distribution.type);
		distribution.getUsersCount(this.settings.distribution).then((response) => {
			this.updateCounterMessage(response.data);
		});
	};

	checkHierarchyLevel = () => {
		const distribution: any = this.scheduleDistributionService.findType(this.settings.distribution.type);
		distribution.hierarchyNode(this.settings.distribution).then((response) => {
			if (response.data.level !== 1) {
				this.hierarchyWarning = this.locale.getString('dashboard.notAtRootHierarchy');
				this.scheduleForm.setErrors({ valid: false });
			} else {
				this.hierarchyWarning = '';
				this.scheduleForm.setErrors({ valid: false });
			}
		});
	};

	selectDistribution = (option) => {
		if (option.uniqueValue === this.distributionDefinitions.HIERARCHY.type) {
			return;
		}
		this.selectedDistribution = option.uniqueValue;
		const distribution = this.scheduleDistributionService.findType(option.type);
		this.setEmailContentToDefault(option);
		distribution.copyToSettings(option, this.settings);
		this.recalculateUsersCounter();
		if (option.type === this.distributionDefinitions.HIERARCHY.type) {
			// default to true when selected
			this.settings.consolidatedEmail = true;
			this.checkHierarchyLevel();
		}
	};

	shareWith = () => {
		const entity = this.inviteEntity;
		if (!entity || this.isDuplicateShare(entity) || this.disableAddButton) { // ignore actions for empty string or dupes
			return;
		}

		let shareEntity: any = entity;

		if (typeof(entity) === 'string') {
			const existingEntity = this.findExistingEntity(entity);
			shareEntity = existingEntity || entity;
		}

		this.inviteEntity = null;
		if (!shareEntity._name) {
			shareEntity = {
				entity: shareEntity,
				type: 'user',
				action: ShareAction.CREATE,
				_name: shareEntity
			};
		}

		this.subscribers.remove(shareEntity);
		this.settings.distribution.subscribers.push(shareEntity);

		this.disableAddButton = true;
	};

	removeUser = (event) => {
		const item = event.$item;
		this.subscribers.push(item);
		this.settings.distribution.subscribers.remove(item);
	};

	typeaheadOnSelect = (event) => {
		this.validateInvite(event.item._name);
	};

	formatter = (entity: {displayName: string}) => entity.displayName;

	immediateShare = () => {
		if (this.disableAddButton) {
			return;
		}
		if (this.validateInvite(this.inviteEntity._name)) {
			this.shareWith();
		}
	};

	onInviteEntityChange = (input) => {
		if (input === '') {
			return;
		}

		this.typeaheadSearchLoading = this.sharingService.searchUsersAndGroups(input)
			.then((usersAndGroups) => {
				this.populateLoadedAvailableEntities(usersAndGroups);
				this.disableAddButton = true;
				this.validateInvite(input);
			}) as unknown as Promise<any>;
	};

	private populateLoadedAvailableEntities = (usersAndGroups) => {
		this.entityMap = {};
		this.subscribers = [];

		// users are split by priority and each set is sorted in alphanumeric order on API
		usersAndGroups.users.forEach(this.addAvailableEntity);

		usersAndGroups.groups
			.sort((left, right) => left.displayName.localeCompare(right.displayName))
			.forEach(this.addAvailableEntity);
	};

	getSearchedEntities = (query): Promise<Subscriber[]> => {
		const entitiesPromise = this.typeaheadSearchLoading
			? this.typeaheadSearchLoading.then(() => this.subscribers)
			: Promise.resolve(this.subscribers);

		return entitiesPromise.then((entities) => {
			return entities
				.filter((entity) => entity.displayName.toLowerCase().contains(query.toLowerCase()))
				.slice(0, this.sharingService.getSharingSearchLimit());
		});
	};

	inviteSuggestions = (text$: Observable<string>) => {
		return text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap((query: string) => {
				this.onInviteEntityChange(query);
				return this.getSearchedEntities(query);
			})
		);
	};

	validateInvite = (entity) => {
		let inviteValid = entity !== null
			&& !_.isUndefined(entity)
			&& (TypeGuards.isString(entity))
			&& !this.isDuplicateShare(entity);

		if (inviteValid) {
			const isEmailEntered = !_.isUndefined(entity.split('@')[1]);

			inviteValid = isEmailEntered
				? this.validateEmail(entity)
				: this.validateGroupName(entity);
			if (inviteValid && isEmailEntered) {
				const existingEntity = this.findExistingEntity(entity);
				if (_.isUndefined(existingEntity)) {
					inviteValid = false;
				}
			}
		}

		this.disableAddButton = !inviteValid;

		return inviteValid;
	};

	private validateEmail = (entity) => {
		return EmailUtils.validate(entity) && this.validateEmailDomain(entity);
	};

	private validateGroupName = (entry) => {
		const filteredGroups = this.subscribers.filter((item) => {
			return item.type === 'group' && item.entity.groupName.toLowerCase() === entry.toLowerCase();
		});

		return filteredGroups.length > 0;
	};

	private validateEmailDomain = (email) => {
		const domain = email.split('@')[1];
		return this.domainsProcessingService.validateLowerCaseEmailDomain(this.allowedDomains, domain);
	};

	private isDuplicateShare = (entity) => {
		if (TypeGuards.isString(entity)) {
			// check if user is already allowed access to dashboard
			return _.find(this.settings.distribution.subscribers as any[],  (item) => {
				return item._name.toLowerCase() === entity.toLowerCase();
			});
		}

		return false;
	};

	private findExistingEntity = (entity): Subscriber | undefined => {
		if (entity) {
			const entityName = entity.entity ? entity._name : entity;

			return _.find(this.subscribers, (item) => {
				return item._name.toLowerCase() === entityName.toLowerCase();
			});
		}
	};

	getPDFInputOptions = (): any[] => {
		return this.checkInputOptions();
	};

	getSubjectBodyInputOptions = (): any[] => {
		if (this.settings.consolidatedEmail) {
			return this.inputOptions;
		} else {
			return this.checkInputOptions();
		}
	};

	checkInputOptions = (): any[] => {
		if (this.distributionDefinitions
			&& this.settings?.distribution?.type === this.distributionDefinitions.HIERARCHY.type) {
			return this.inputOptionsWithHierarchy;
		}

		return this.inputOptions;
	};

	isRefreshJob = () => {
		return this.settings.jobType === this.jobTypeDefinitions.REFRESH.value;
	};

	isPDFJob = () => {
		return this.settings.jobType === this.jobTypeDefinitions.DISTRIBUTE_PDF.value;
	};

	private setDefaultDistribution = () => {
		this.settings.distribution.type = this.distributionDefinitions.SPECIFIC_USERS.type;
		this.selectDistribution(this.distributionDefinitions.SPECIFIC_USERS);
	};

	jobTypeChanged = () => {
		if (this.isPDFJob()) { // reset start time
			const hour = Number.parseInt(moment().format('H'), 10);
			this.time.hour = `${(hour - hour % 6)}`;
			this.settings.startDate.setHours(Number.parseInt(this.time.hour, 10));
			this.time.min = '0';
			this.settings.startDate.setMinutes(Number.parseInt(this.time.min, 10));
			if (!this.selectedDistribution) {
				this.setDefaultDistribution();
			}
		} else {
			this.settings.startDate = moment().add(10, 'minutes').toDate();
			this.time = this.scheduleUtilsService.getDefaultTimeObject(this.settings.startDate, this.settings.timezoneOffset);
			this.setAmPmHour();
		}
		this.processCron();
	};

	private setEmailContentToDefault = (selectedDistribution?) => {

		const distribution = selectedDistribution || this.settings.distribution;
		const type = this.settings.distribution.type;
		if (distribution && type && this.isEmailSettingsUnchanged(type)) {
			this.settings.pdfName = this.defaultValues[distribution.type].defaultPdfName;
			this.settings.emailTitle = this.defaultValues[distribution.type].defaultEmailTitle;
			this.settings.emailBody = this.defaultValues[distribution.type].defaultEmailBody;
		}
	};

	private isEmailSettingsUnchanged = (type) => {
		if (this.settings && type) {
			return this.isDefaultOrEmpty(this.settings.pdfName, this.defaultValues[type].defaultPdfName) &&
				this.isDefaultOrEmpty(this.settings.emailTitle, this.defaultValues[type].defaultEmailTitle) &&
				this.isDefaultOrEmpty(this.settings.emailBody, this.defaultValues[type].defaultEmailBody);
		}

		return true;
	};

	private isDefaultOrEmpty = (value, defaultValue) => {
		return !(value && value.length > 0
				&& value !== defaultValue);
	};

	getWarningMessage = () => {
		if (this.recurrence.dayOfMonth === 29) {
			return this.locale.getString('schedule.willNotFire29Days', {leapYearClause: this.locale.getString('schedule.exceptLeapYear')});
		} else if (this.recurrence.dayOfMonth === 30) {
			return this.locale.getString('schedule.willNotFire29Days', {leapYearClause: ''});
		}
		return this.locale.getString('schedule.willNotFire31Days');
	};

	private isNoSubscribersPDF = () => {
		return this.isPDFJob()
			&& (this.settings.distribution.type === this.distributionDefinitions.SPECIFIC_USERS.type)
			&& (this.settings.distribution.subscribers.length < 1);
	};

	disableSave = () => {
		return !this.isModified(this.index) || this.isNoSubscribersPDF() || !this.isSchedulerFormValid();
	};

	isSchedulerFormValid = () => {
		return this.scheduleForm.valid && !this.showWeekRequired;
	};

	deleteSchedule = (event: Event) => {
		event.stopPropagation();
		event.preventDefault();
		this.removeSchedule(this.index);
	};

	shouldShowEmailWarning = (): boolean => {
		if (this.settings.distribution.type === this.distributionDefinitions.SPECIFIC_USERS.type && this.isPDFJob()) {
			let totalUsers = 0;
			this.settings.distribution.subscribers.forEach(subscriber => {
				if (subscriber.type === 'group') {
					totalUsers += subscriber.usersCount ?? subscriber.entity?.usersCount ?? 0;
				} else {
					totalUsers++;
				}

			});
			return totalUsers > ObjectSharingConstants.EMAIL_USER_LIMIT;
		}
		return false;
	};

}
