import ExternalApplication from '@cxstudio/system-administration/external-applications/external-application';
import { ObjectListSort } from '@app/shared/components/sort/object-list-sort';
import { ObjectSortDirection } from '@app/shared/components/sort/object-sort-direction';
import { ObjectListFilter } from '@app/shared/components/filter/object-list-filter';
import { FilterCondition } from '@app/shared/components/filter/filter-condition';
import { ExternalApplicationTypeUtils } from './external-applications/external-application-type-utils';
import { ExternalApplicationsApiService } from '@app/modules/system-administration/oauth/service/external-applications-api.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import ILocale from '@cxstudio/interfaces/locale-interface';


export class ExternalApplicationsComponent implements ng.IComponentController {

	loadingPromise;
	applications: ExternalApplication[];
	ui: {filter: ObjectListFilter; sort: ObjectListSort};
	search: ExternalApplication;

	readonly DEFAULT_SORT: ObjectListSort = { field: 'name', direction: ObjectSortDirection.ASC };

	constructor(
		private $uibModal: ng.ui.bootstrap.IModalService,
		private cxDialogService: CxDialogService,
		private locale: ILocale,
		private externalApplicationsApiService: ExternalApplicationsApiService
	) {}

	$onInit = () => {
		this.search = {} as ExternalApplication;
		this.ui = {filter: {} as ObjectListFilter, sort: Object.assign(this.DEFAULT_SORT)};
		this.reloadApplications();
	};

	reloadApplications = (): void => {
		this.loadingPromise = this.externalApplicationsApiService.getApplications()
			.then(applications => {
				this.applications = applications;
			});
	};

	addApplication = (): void => {
		let application: ExternalApplication = {} as ExternalApplication;

		this.loadingPromise = this.openApplicationEditDialog(application)
			.then((applicationData) => { this.externalApplicationsApiService.createApplication(applicationData); })
			.then(() => this.reloadApplications());
	};

	editApplication = (application: ExternalApplication): void => {
		this.loadingPromise = this.openApplicationEditDialog(application)
			.then((applicationData) => { this.externalApplicationsApiService.updateApplication(applicationData.id, applicationData); })
			.then(() => this.reloadApplications());
	};

	removeApplication = (application: ExternalApplication): void => {
		this.cxDialogService.warningWithConfirm(
			this.locale.getString('common.pleaseConfirm'),
			this.locale.getString('common.confirmDeleteItem', { itemName: application.name }),
			this.locale.getString('common.delete')).result
			.then(() => {
				this.loadingPromise = this.externalApplicationsApiService.removeApplication(application.id)
					.then(() => this.reloadApplications());
			});
	};

	getSuiteClientId = (application: ExternalApplication): void => {

	};

	applyFilters = (filters: ObjectListFilter[]): void => {
		let filter = _.isEmpty(filters) ? {} as ObjectListFilter : filters[0];
		this.ui.filter = filter;
	};

	applySorts = (sorts: ObjectListSort[]): void => {
		let sort = _.isEmpty(sorts) ? this.DEFAULT_SORT : sorts[0];
		this.ui.sort.field = sort.field || this.DEFAULT_SORT.field;
		this.ui.sort.direction = sort.direction || this.DEFAULT_SORT.direction;
	};

	applicationFilter = (application: ExternalApplication): boolean => {
		if (this.ui.filter?.field === 'type' && this.ui.filter.value) {
			return this.ui.filter.condition === FilterCondition.IS
				? application.type === this.ui.filter.value
				: application.type !== this.ui.filter.value;
		}
		return true;
	};

	private openApplicationEditDialog = (application: ExternalApplication): ng.IPromise<any> => {
		return this.$uibModal.open({
			templateUrl: 'partials/system-administration/external-applications/external-application-edit-dialog-controller.html',
			controller: 'ExternalApplicationEditDialogController',
			resolve: {
				application: () => {
					return application;
				}
			}
		}).result;
	};

	getTypeDisplayName = (type: string) => {
		return ExternalApplicationTypeUtils.getDisplayName(type);
	};

}


app.component('externalApplications', {
	bindings: {},
	controller: ExternalApplicationsComponent,
	template: `
<div class="d-flex justify-between">
	<div class="d-flex align-items-end">
		<label class="font-bold mb-0">{{::'common.findApplications'|i18n}}
			<input
				ng-escape="$ctrl.search.name=''"
				type="text"
				class="grid-search-bar d-block"
				ng-model="$ctrl.search.name">
		</label>
		<external-application-filter
			class="grid-search-filter d-inline-block"
			(apply)="$ctrl.applyFilters($event)"
		></external-application-filter>
		<external-application-sort
			class="grid-search-filter d-inline-block"
			(apply)="$ctrl.applySorts($event)"
		></external-application-sort>
	</div>
	<div class="form-group mb-0 d-flex align-items-end">
		<button type="button"
			class="btn btn-primary"
			ng-click="$ctrl.addApplication()"
			i18n="administration.addExternalApplication">
		</button>
	</div>
</div>
<table class="table" cg-busy="$ctrl.loadingPromise">
	<thead>
		<tr>
			<th style="width:15%;">#</th>
			<th style="width:30%;" i18n="administration.externalApplicationNameTitle"></th>
			<th style="width:25%;" i18n="administration.externalApplicationTypeTitle"></th>
			<th style="width:30%;" i18n="administration.externalApplicationSuiteClientTitle"></th>
		</tr>
	</thead>
	<tbody>
		<tr class="br-external-application"
			ng-repeat="application in $ctrl.applications
				| filter: $ctrl.search 
				| filter: $ctrl.applicationFilter 
				| orderBy:$ctrl.ui.sort.field:$ctrl.ui.sort.direction == 'DESC'">
			<th scope="row">
				<a class="br-edit-application" ng-click="$ctrl.editApplication(application)" i18n="administration.edit"></a> |
				<a class="br-remove-application" ng-click="$ctrl.removeApplication(application)" i18n="administration.remove"></a>
			</th>
			<td class="br-application-name">{{ application.name }}</td>
			<td class="br-application-type">{{ $ctrl.getTypeDisplayName(application.type) }}</td>
			<td class="br-application-type">{{ application.suiteClientId || '' }}</td>
		</tr>
	</tbody>
</table>`
});
