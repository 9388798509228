<div>
	<div class="cursor-pointer kb-focusable d-flex justify-between lh-1_75 mb-4"
		(click)="collapse()"
		(keydown.enter)="collapse()"
		tabindex="0"
		[attr.aria-label]="getScorecardTitleAriaLabel()">
		<div>
			<button class="text-gray-900 mr-8 no-border kb-focusable bg-none"
				[attr.aria-label]="getExpandCollapseBtnAriaLabel()"
				[ngClass]="{'q-icon q-icon-angle-bottom' : expanded, 'q-icon q-icon-angle-right' : !expanded}"
				(keydown.enter)="collapse()"
			></button>
			<b>{{ scorecard.name }}</b>
			<button class="ml-8 cursor-pointer text-gray-900 no-border kb-focusable bg-none"
				*ngIf="isDocExplorer"
				[attr.aria-label]="getFavoriteBtnAriaLabel()"
				[ngClass]="{'q-icon-star': favorite, 'q-icon-star-outline': !favorite}"
				(click)="toggleFavorite(); $event.stopPropagation()"
			></button>
		</div>

		<div>
			<scorecard-label
				[label]="scorecard.labelName"
				[bgColorHex]="scorecard.labelColor"
				[labelDisplayOption]="scorecard.reportingOption"
				[score]="getScorecardScore()">
			</scorecard-label>
		</div>

	</div>

	<div [hidden]=!expanded class="w-100-percent">
		<div class="text-center mb-24" *ngIf="rebuttalMode && scorecard.rebuttalInProgress">
			<span class="q-icon q-icon-clock text-lg-6" aria-hidden="true"></span>
			<p class="text-base font-bold">{{ 'scorecards.rebuttalInProgressMessage' | i18n }}</p>
			<p class="text-sm-2 font-normal">{{ 'scorecards.rebuttalInProgressNote' | i18n }}</p>
		</div>

		<table class="w-100-percent" *ngIf="showTable()">
			<tr>
				<th class="scorecard-warning-column" *ngIf="!rebuttalMode"><span class="ph-8" [ngClass]="rebuttedIcon.css"></span></th>
				<th>
					<div class="d-flex align-items-center">{{ 'scorecards.criteria' | i18n }}
						<explorer-scorecard-sort column='criteria' [selectedValue]="displayPreference"
							(onSelectedChange)="onSelectedValueChange($event)">
						</explorer-scorecard-sort>
					</div>
				</th>
				<th [ngClass]="{'scorecard-result-column': !rebuttalMode, 'scorecard-result-column-rebut': rebuttalMode}">
					<div class="d-flex align-items-center">{{ 'scorecards.result' | i18n }}
						<explorer-scorecard-sort column='result' [selectedValue]=displayPreference
							(onSelectedChange)="onSelectedValueChange($event)">
						</explorer-scorecard-sort>
					</div>
				</th>
			</tr>
			<tr *ngFor="let topic of sortAndFilter(scorecard.topics)"
				[ngClass]="{'cursor-pointer': isClickable(topic), 'cxs-table-row-hover': isClickable(topic)}"
				(click)="clickTopic(topic)">
				<td *ngIf="!rebuttalMode">
					<i *ngIf="topic.rebutted"
						class="mh-4 ph-4 scorecard-result-icon"
						[ngClass]="rebuttedIcon.css"
						[style.color]="rebuttedIcon.color"></i>
				</td>
				<td [title]="topic.path || topic.name"
					class="scorecard-rule-column">{{ topic.name }}</td>
				<ng-container *ngIf="!rebuttalMode">
					<td *ngIf="!topic.rebutted" class="scorecard-result-column">
						<i [ngClass]="getResult(topic.result)"
							[title]="getResultTitle(topic.result)"
							[style.color]="getResultColor(topic.result)"
							class="scorecard-result-icon ph-4"></i>
					</td>
					<td *ngIf="topic.rebutted" class="scorecard-result-column">
						<i [ngClass]="getResult(topic.originalResult)"
							[title]="getResultTitle(topic.originalResult)"
							[style.color]="getResultColor(topic.originalResult)"
							class="scorecard-result-icon ph-4"></i>
						<i class="q-icon-arrow-right ph-4"></i>
						<i [ngClass]="getResult(topic.result)"
							[title]="getResultTitle(topic.result)"
							[style.color]="getResultColor(topic.result)"
							class="scorecard-result-icon ph-4"></i>
					</td>
				</ng-container>
				<search-list *ngIf="rebuttalMode"
					class="d-flex pt-4"
					[dropdown]="true"
					[data]="getPresenceObject(topic)"
					[disableSearch]="true"
					[listOptions]="presenceOptions"
					(onNodeSelection)="selectPresence(topic, $event.node)"
					[appendToBody]="true">
				</search-list>
			</tr>
		</table>
		<div class="d-flex justify-end pt-8" *ngIf="showButtons()">
			<button
				type="button"
				class="btn btn-secondary"
				[disabled]="!hasChanges()"
				(click)="cancelChanges()">{{'common.resetChanges'|i18n}}</button>
			<button
				type="submit"
				data-testid="btn-confirm-rebuttal"
				class="btn btn-primary"
				(click)=confirmRebuttal()
				[disabled]="!hasChanges()">{{'common.save'|i18n}}
			</button>
		</div>

		<div class="text-center mb-24" *ngIf="rebuttalMode && !scorecardRebuttalAllowed">
			<span class="q-icon q-icon-accessdenied text-lg-6" aria-hidden="true"></span>
			<p class="text-base">{{ 'common.disabled' | i18n }}</p>
		</div>
		<ng-container *ngIf="noResults">
			<span class="ml-8">{{'scorecards.noResults'|i18n}}</span>
		</ng-container>
	</div>
</div>
