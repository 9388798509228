import {
	Component, Input, OnInit, ChangeDetectionStrategy, EventEmitter, Output,
	ViewChild, AfterViewInit, ChangeDetectorRef, OnDestroy
} from '@angular/core';
import { UntypedFormControl, FormGroup, NgForm } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';
import OAuthClientDetails from '@app/modules/system-administration/oauth/oauth-client-details';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { OauthClientDetailsApplicationKind } from '../oauth-client-details-application-kind';

enum AdditionalInfoType {
	BOOLEAN = 'boolean',
	NUMBER = 'number',
	STRING = 'string'
}

export interface OAuthClientDetailsAdditionalInfoChangedEvent {
	additionalInformation: any;
	additionalInformationValid: boolean;
}

export interface OAuthClientDetailsAdditionalInfoView {
	name: string;
	type: AdditionalInfoType;
	value: any;
	id: number;
}

interface LogoutEndpoint {
	url: string;
	user: string;
	password: string;
}

const MASTER_ACCOUNT_ID_PROPERTY: string = 'masterAccountId';
const LOGOUT_ENDPOINT_PROPERTY: string = 'logoutEndpoint';
const SAVE_CLIENT_STATE_PROPERTY: string = 'saveClientState';

// list of additional properties that requires separate processing.
export const PRE_DEFINED_PROPERTY_NAMES: string[] = [SAVE_CLIENT_STATE_PROPERTY, 'jwtSecretKey', 'unifiedLinks',
	'parentFolderEnabled', 'parentFolderName', 'enableUnifiedLinks', LOGOUT_ENDPOINT_PROPERTY, MASTER_ACCOUNT_ID_PROPERTY,
	'unifiedLinksVisibility'];

// list of additional properties not managed by this component
export const EXCLUDED_PROPERTY_NAMES: string[] = ['jwtSecretKey', 'unifiedLinks',
	'parentFolderEnabled', 'parentFolderName', 'enableUnifiedLinks', 'unifiedLinksVisibility'];

@Component({
	selector: 'oauth-client-details-additional-information',
	templateUrl: './oauth-client-details-additional-information.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OauthClientDetailsAdditionalInformationComponent implements OnInit, AfterViewInit, OnDestroy {
	@Output() detailsUpdated = new EventEmitter<OAuthClientDetailsAdditionalInfoChangedEvent>();
	@Input() clientDetails: OAuthClientDetails;
	@Input() masterAccounts: Array<MasterAccount>;
	@ViewChild('additionalInformationForm') public additionalInformationForm: NgForm;

	readonly ADDITIONAL_INFO_TYPES: string[] = [AdditionalInfoType.BOOLEAN, AdditionalInfoType.NUMBER,
		AdditionalInfoType.STRING];

	additionalInformation: OAuthClientDetailsAdditionalInfoView[];
	saveClientState: boolean;
	logoutEndpoint: LogoutEndpoint = {} as LogoutEndpoint;

	masterAccount: MasterAccount;
	masterAccountIdRequired: boolean;

	private addedInputs: string[] = [];
	private processPropertyRemoved: boolean = false;

	propertyAddedSubscription: Subscription;
	propertyRemovedSubscription: Subscription;

	constructor(
		private ref: ChangeDetectorRef
	) { }

	ngOnDestroy(): void {
		this.propertyAddedSubscription.unsubscribe();
		this.propertyRemovedSubscription.unsubscribe();
	}

	// after form is rendered, register additional handlers:
	ngAfterViewInit(): void {

		// registering handler for added controls
		this.propertyAddedSubscription = this.additionalInformationForm.valueChanges.subscribe(
			value => {
				let processedInputs: string[] = [];
				this.addedInputs.forEach(inputName => {
					//Check if expected control is there. Render might be incomplete.
					let input: UntypedFormControl = this.additionalInformationForm.form.get(inputName) as UntypedFormControl;
					if (!_.isNull(input)) {
						input.markAsDirty();
						input.markAsTouched();
						processedInputs.push(inputName);
					}
				});

				processedInputs.forEach(processedInputName => {
					this.addedInputs.remove(processedInputName);
				});

				if (processedInputs.length > 0) {
					this.ref.markForCheck();
					this.updateAdditionalInformation();
				}
			}
		);

		// registering handler for removed controls
		this.propertyRemovedSubscription = this.additionalInformationForm.valueChanges.subscribe(
			value => {

				if (!this.processPropertyRemoved) {
					return;
				}

				//Check form and model for consistency. Render might be incomplete.
				const expectedInputFieldsNumer = this.additionalInformation.length * 2; // value and dname pair for each property

				const customPropertiesFieldsNumber = _.filter(Object.keys(this.additionalInformationForm.controls), (controlName: string) => {
					return controlName.startsWith('propertyName_') || controlName.startsWith('propertyValue_');
				}).length;

				if (customPropertiesFieldsNumber !== expectedInputFieldsNumer) {
					return;
				}

				for (let i: number = 0; i < this.additionalInformation.length; i++) {
					let item: OAuthClientDetailsAdditionalInfoView = this.additionalInformation[i];
					let nameControl: UntypedFormControl = this.additionalInformationForm.form.get('propertyName_' + i) as UntypedFormControl;
					let valueControl: UntypedFormControl = this.additionalInformationForm.form.get('propertyValue_' + i) as UntypedFormControl;

					if (_.isNull(nameControl) || _.isNull(valueControl)) {
						return;
					}

					if (nameControl.value !== item.name || valueControl.value !== item.value) {
						return;
					}
				}

				this.processPropertyRemoved = false;
				this.ref.markForCheck();
				this.updateAdditionalInformation();
			}
		);

	}

	ngOnInit(): void {
		this.additionalInformation = [];
		let id = 0;

		if (_.isUndefined(this.clientDetails.additionalInformation)) {
			return;
		}

		Object.keys(this.clientDetails.additionalInformation).forEach((name: string) => {
			if (_.contains(this.ADDITIONAL_INFO_TYPES, typeof this.clientDetails.additionalInformation[name])
				&& !_.contains(PRE_DEFINED_PROPERTY_NAMES, name)) {
				let info: any = {};
				info.name = name;
				info.type = typeof this.clientDetails.additionalInformation[name];
				info.value = this.clientDetails.additionalInformation[name];
				info.id = id++;
				this.additionalInformation.push(info);
			}
		});

		if (!_.isUndefined(this.clientDetails.additionalInformation[LOGOUT_ENDPOINT_PROPERTY])) {
			this.logoutEndpoint = this.clientDetails.additionalInformation[LOGOUT_ENDPOINT_PROPERTY];
		}

		if (!_.isUndefined(this.clientDetails.additionalInformation[SAVE_CLIENT_STATE_PROPERTY])) {
			this.saveClientState = this.clientDetails.additionalInformation?.saveClientState;
		}

		if (!_.isUndefined(this.clientDetails.additionalInformation[MASTER_ACCOUNT_ID_PROPERTY])) {
			const masterAccountId = this.clientDetails.additionalInformation[MASTER_ACCOUNT_ID_PROPERTY];
			this.masterAccount = _.find(this.masterAccounts,
				masterAccount => {
					return masterAccount.accountId === masterAccountId;
				});
		}

		this.masterAccountIdRequired = this.clientDetails.applicationKind === OauthClientDetailsApplicationKind.CB_LINK;

		// updating validation status
		this.updateAdditionalInformation();

	}

	updateAdditionalInformation = (): void => {
		let info: any = {};
		this.additionalInformation.forEach((element: any) => {
			info[element.name] = element.value;
		});

		if (this.saveClientState) {
			info[SAVE_CLIENT_STATE_PROPERTY] = true;
		}

		if (!_.isUndefined(this.masterAccount)) {
			info[MASTER_ACCOUNT_ID_PROPERTY] = this.masterAccount.accountId;
		}

		if (!_.isUndefined(this.logoutEndpoint)) {
			if (this.logoutEndpoint.url) {
				const transformedLogoutEndpoint: any = {};
				transformedLogoutEndpoint.url = this.logoutEndpoint.url;
				if (this.logoutEndpoint.user && this.logoutEndpoint.password) {
					transformedLogoutEndpoint.user = this.logoutEndpoint.user;
					transformedLogoutEndpoint.password = this.logoutEndpoint.password;
				}
				info[LOGOUT_ENDPOINT_PROPERTY] = transformedLogoutEndpoint;
			}
		}

		let valid: boolean = true;
		if (_.isUndefined(this.additionalInformationForm)) {
			valid = this.masterAccountIdValid();
		} else {
			valid = this.additionalInformationForm.valid && this.masterAccountIdValid();
		}

		this.detailsUpdated.emit({
			additionalInformation: info,
			additionalInformationValid: valid
		});
	};

	masterAccountIdValid = (): boolean => {
		if (this.masterAccountIdRequired) {
			return !_.isUndefined(this.masterAccount);
		}
		return true;
	};

	onMasterAccountIdChange = (newValue: MasterAccount): void => {
		this.masterAccount = newValue;
		this.updateAdditionalInformation();
	};

	clearMasterAccountId = (): void => {
		delete this.masterAccount;
		this.updateAdditionalInformation();
	};

	addInfoBoolean = (): void => {
		let info: any = {};
		info.type = AdditionalInfoType.BOOLEAN;
		info.value = false;
		this.additionalInformation.push(info);
		this.assignAdditionalInfromationIds();
		this.markNewPropertyInputDirty();
	};

	addInfoString = (): void => {
		let info: any = {};
		info.type = AdditionalInfoType.STRING;
		this.additionalInformation.push(info);
		this.assignAdditionalInfromationIds();
		this.markNewPropertyInputDirty();
	};

	addInfoNumber = (): void => {
		let info: any = {};
		info.type = AdditionalInfoType.NUMBER;
		this.additionalInformation.push(info);
		this.assignAdditionalInfromationIds();
		this.markNewPropertyInputDirty();
	};

	markNewPropertyInputDirty = (): void => {
		// the newly added input is always the last one.
		let index = this.additionalInformation.length - 1;
		if (index >= 0) {
			this.addedInputs.push('propertyName_' + index);
			this.addedInputs.push('propertyValue_' + index);
		}
	};

	removeInfo = (index: number): void => {
		this.additionalInformation.splice(index, 1);
		this.assignAdditionalInfromationIds();
		this.processPropertyRemoved = true;
	};

	assignAdditionalInfromationIds = (): void => {
		this.additionalInformation.forEach((details: OAuthClientDetailsAdditionalInfoView, index: number) => {
			details.id = index;
		});
	};

	trackAdditionalInformationProperties = (index: number, prop: OAuthClientDetailsAdditionalInfoView): string => {
		// needed to ensure the form is working correctly when input is added or removed.
		return prop.id as unknown as string;
	};

}

app.directive('oauthClientDetailsAdditionalInformation',
	downgradeComponent({ component: OauthClientDetailsAdditionalInformationComponent }));
