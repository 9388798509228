import * as _ from 'underscore';
import * as orderBy from 'lodash.orderby';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { HierarchyService } from '@cxstudio/services/hierarchy-service.service';
import { DrillToDashboardService } from '@cxstudio/services/drill-to-dashboard.service';
import { FilterUtils } from '@cxstudio/report-filters/filter-utils.service';
import { DashboardFiltersDragDropService } from '@app/modules/dashboard-edit/services/dashboard-filters-drag-drop.service';
import { DashboardUtils } from '@app/modules/dashboard/services/utils/dashboard-utils.class';
import { DashboardFilterSelection } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-selection';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { DashboardFilter } from './dashboard-filter';
import { AttributeValueOption } from '@app/modules/filter-builder/attribute/multiselect/multiselect.component';
import { AttributeValueSearcher, FilterValuesSearchType, SearchTermsResult, SearchedAttribute }
	from '@cxstudio/dashboards/attribute-value-searcher.service';
import { ReportScorecardFiltersService } from '@app/modules/scorecards/filters/report-scorecard-filters.service';
import { FilterCreatorService } from '@cxstudio/report-filters/filter-creator.service';
import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { FilterValidationService } from '@cxstudio/report-filters/filter-validation-service.service';
import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import { DropdownPosition } from './dropdown-position';
import { TreeSelectionStrategy } from '@app/shared/components/tree-selection/tree-selection';
import { EnrichmentAttributesService } from '@cxstudio/reports/document-explorer/enrichment-attributes.service';
import { ReportFiltersService } from '@app/modules/filter/services/report-filters.service';
import { PromiseUtils } from '@app/util/promise-utils';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { IScorecardFilter } from '@app/modules/scorecards/entities/scorecard-filter';
import { AttributeType } from '@app/modules/project/attribute/attribute-type';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { UrlService } from '@cxstudio/common/url-service.service';
import { DashboardFilterLabelsService } from './dashboard-filter-labels.service';

type FilterTreeItem = Omit<IFilter, 'id' | 'parentId'> & {
	id: number | string;
	parentId?: number | string;
};

export class DashboardFiltersEditController implements ng.IController {

	readonly MAX_FILTERS_ALLOWED = 15;

	project: AccountOrWorkspaceProject;
	dashboard: Dashboard;
	containerId: string;
	dashboardHistory: IDashboardHistoryInstance;
	isVisible: () => boolean;
	editMode: boolean;
	embedded: boolean;
	personalization: any;
	projectAttributes: any[];
	projectDateFilters: {
		dateFilters: Array<{value: string; displayName: string}>;
	};
	projectTimezone: string;
	applyFilters: () => void;
	onFilterChange: (params: {$action: string; $filter: any}) => void;
	closePopup: () => void;

	minPopoverWidth = 0;
	drillToDashboardFilters: DashboardFilter[];

	matchModeOptions;
	getAppliedMatchMode;

	constructor(
		private $scope: ISimpleScope,
		private $rootScope: ng.IRootScopeService,
		private dashboardFiltersService: DashboardFiltersService,
		private locale: ILocale,
		private cbDialogService: CBDialogService,
		private hierarchyService: HierarchyService,
		private $filter: ng.IFilterService,
		private filterUtils: FilterUtils,
		private DateRange,
		private $timeout: ng.ITimeoutService,
		private dashboardFilterLabels: DashboardFilterLabelsService,
		private readonly reportFiltersService: ReportFiltersService,
		private readonly reportScorecardFiltersService: ReportScorecardFiltersService,
		private drillToDashboardService: DrillToDashboardService,
		private dashboardFiltersDragDrop: DashboardFiltersDragDropService,
		private attributeValueSearcher: AttributeValueSearcher,
		private $q: ng.IQService,
		private filterCreatorService: FilterCreatorService,
		private metricConstants: MetricConstants,
		private filterValidationService: FilterValidationService,
		private enrichmentAttributesService: EnrichmentAttributesService,
		private urlService: UrlService,
		private $sanitize,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
	) {}

	$onInit(): void {

		let TIME_OUT_TO_GET_ACTUAL_HEIGHT = 100;
		this.$timeout(() => {
			this.setAvailableHeight();
		}, TIME_OUT_TO_GET_ACTUAL_HEIGHT);

		this.project = this.reportAssetUtilsService.getDashboardProject(this.dashboard);
		this.dashboardHistory.applyHierarchyNodeIfEmpty(this.personalization.currentHierarchyNode);

		this.matchModeOptions = this.filterCreatorService.getMatchModeOptions;
		this.getAppliedMatchMode = this.filterCreatorService.getAppliedMatchMode;

		this.applyGenericFilterFormat();
	}

	dragStart = (filter) => this.dashboardFiltersDragDrop.dragStart(filter);
	dragStop = (filter) => this.dashboardFiltersDragDrop.dragStop(filter);
	dragMove = (filter) => {
		this.dashboardFiltersDragDrop.dragMove(filter, this.dashboard.appliedFiltersArray);
		this.dashboard.properties.isAttributeFiltersApplied = false;
	};

	isText = (item) => DashboardFilterSelection.isText(item);
	isTopic = (item) => DashboardFilterSelection.isTopic(item);
	isAttribute = (item) => DashboardFilterSelection.isAttribute(item);
	isMetric = (item) => DashboardFilterSelection.isMetricFilter(item);
	isDateRange = (item) => DashboardFilterSelection.isDateRange(item);

	lockFilterDropdown = (filter: DashboardFilter) => {
		return filter.locked && !this.editMode;
	};

	/**
	 * Convert dashboard filter format to generic filter format for UI
	 * @param dashboardFilter
	 */
	private applyGenericFilterFormat = (): void => {
		if (!this.dashboard?.appliedFiltersArray?.length) return;

		this.dashboard.appliedFiltersArray
			.filter((dashboardFilter: DashboardFilter) => dashboardFilter.selectedAttribute)
			.forEach((dashboardFilter: DashboardFilter) => {
				dashboardFilter.genericFilterFormat = this.dashboardFiltersService.buildDashboardFilterRule(dashboardFilter);
				dashboardFilter.selectedAttribute.matchMode = dashboardFilter.genericFilterFormat.matchMode;
				if (dashboardFilter.genericFilterFormat.existMatch !== undefined) {
					dashboardFilter.selectedAttribute.existMatch = dashboardFilter.genericFilterFormat.existMatch;
				}
				if (dashboardFilter.genericFilterFormat.modelMatch !== undefined) {
					dashboardFilter.selectedAttribute.modelMatch = dashboardFilter.genericFilterFormat.modelMatch;
				}
				if (dashboardFilter.genericFilterFormat.existMatch)
					dashboardFilter.multiValues = [];
			});
	};

	addFilter = () => {
		let dashboardProps = this.dashboard;
		if (!dashboardProps.appliedFiltersArray) {
			dashboardProps.appliedFiltersArray = [];
		}
		let newFilter = {};

		dashboardProps.appliedFiltersArray.push(newFilter);

		// make sure the container element is visible then set a max height
		this.$timeout(this.setAvailableHeight, 1);
		this.scrollToFilterBottom();
	};

	scrollToFilterBottom = () => {
		// make sure the container element is visible
		this.$timeout(() => {
			let filtersContainer = $('#dashboard-filters-container');
			filtersContainer.animate({scrollTop: filtersContainer[0].scrollHeight}, 500);
		}, 1);
	};

	isTextFilterSelected = () => {
		return _.any(this.dashboard.appliedFiltersArray, (filter) => {
			return this.isText(filter.selectedAttribute);
		});
	};

	clearSearch = () => {
		this.applyTextFilter('');
	};

	removeTextFilter = () => {
		let textIndex = _.findIndex(this.dashboard.appliedFiltersArray, (filter) => {
			return this.isText(filter.selectedAttribute);
		});

		if (!_.isUndefined(textIndex)) {
			this.removeFilter(textIndex);

			if (this.dashboardHistory.getTextFilter()) {
				this.clearSearch();
			}
		}
	};

	isVisibleNonTextFilters = () => {
		return this.isHierarchyNodeSelectable() ||
			(_.filter(this.dashboard.appliedFiltersArray, (filter) => {
				return !this.isText(filter.selectedAttribute) && !filter.isOrgHierarchy;
			}).length > 0);
	};

	selectFilterType = (item, index) => {
		if (!item.children) {
			let currFilter = this.dashboard.appliedFiltersArray[index];
			currFilter.selectedAttribute = item;
			currFilter.genericFilterFormat = this.dashboardFiltersService.buildDashboardFilterRule(currFilter);
			currFilter.selectedAttribute.matchMode  = currFilter.genericFilterFormat.matchMode;
			if (this.isText(item)) {
				this.valueChanged();
				this.$rootScope.$broadcast('dashboardFiltersChangedEvent');
				return;
			}

			let defaultValue = this.dashboardFiltersService.getDefaultFilterValue(item);
			if (defaultValue) {
				currFilter.selectedAttributeValue = defaultValue;
			}

			this.dashboard.properties.isAttributeFiltersApplied = false;
			this.onFilterChange({$action: 'add', $filter: currFilter});
			this.$rootScope.$broadcast('dashboardFiltersChangedEvent');

			// scroll to bottom after filter type is chosen
			this.scrollToFilterBottom();
		}

		this.adjustMinPopoverWidth();
	};

	adjustMinPopoverWidth = () => {
		this.$timeout(() => {
			let currentWidth = $('.popover.dashboard-filters-pop').width();
			if (currentWidth > this.minPopoverWidth) {
				this.minPopoverWidth = currentWidth;
				$('.popover.dashboard-filters-pop').css({minWidth: this.minPopoverWidth});
			}
		}, 1);
	};

	selectAttributeValue = (item, index) => {
		if (!item) return;

		let currFilter = this.getFilterByIndex(index);
		currFilter.selectedAttributeValue =
			item.isAllValues || !_.isUndefined(currFilter.multiValues) ? undefined : item;

		//Topic filter specific logic
		if ( item.strategy && item.strategy === TreeSelectionStrategy.NONE) {
			delete currFilter.selectedAttributeValue;
		}

		this.onFilterChange({$action: 'add', $filter: currFilter});
		this.markNotApplied();
		this.adjustMinPopoverWidth();
	};

	updateAttributeValues = (select, index) => {
		let currFilter = this.getFilterByIndex(index);
		this.onFilterChange({$action: 'update', $filter: currFilter});
	};

	enableFilterApply = () => {
		return this.filtersHaveChanged() && this.allFiltersValid();
	};

	filtersHaveChanged = () => {
		return this.dashboardHistory.isAppliedFiltersChanged(this.dashboard.appliedFiltersArray)
			&& !this.dashboard.properties.isAttributeFiltersApplied;
	};

	applyDashboardFilters = () => {
		this.applyGenericFilterFormat();

		let rules = _.chain(this.dashboard.appliedFiltersArray)
			.filter((filterItem) => !_.isUndefined(filterItem.genericFilterFormat))
			.map((filterItem) => filterItem.genericFilterFormat)
			.value();

		let valid = this.filterValidationService.validateDashboardFilterRules({ filterRules: rules }) || rules.isEmpty();
		if (!valid) {
			this.cbDialogService.notify(this.locale.getString('common.warning'), this.locale.getString('reportFilters.incompleteRules'));
			return;
		}

		this.persistAppliedHierarchyNode();
		this.applyFilters();
		this.closePopup();
	};

	clearChanges = () => {
		let previousAppliedFilters = angular.copy(this.dashboardHistory.getPersistentFilters());

		this.dashboard.appliedFiltersArray = previousAppliedFilters;
		this.applyGenericFilterFormat();

		this.restorePreviouslyAppliedHierarchyNode();
		this.$rootScope.$broadcast('dashboardRollbackFinished');
	};

	restorePreviouslyAppliedHierarchyNode = () => {
		if (isTrue(this.dashboard.properties.allowPersonalization)) {
			this.setHierarchyNode(this.dashboardHistory.getAppliedHierarchyNode());
		}
	};

	selectDateFilter = (dateFilterMode, dateFilterRange, dateDisplayName, index) => {
		let currFilter = this.getFilterByIndex(index);
		currFilter.selectedAttributeValue = {
			dateFilterMode,
			dateFilterRange,
			dateDisplayName
		} as any;

		this.onFilterChange({$action: 'add', $filter: currFilter});
		if (dateFilterMode === this.DateRange.options.MISS_DATE.value) {
			this.cbDialogService.notify(this.locale.getString('common.warning'),
				this.locale.getString('filter.appliedMissingDatesFilter'), undefined, 'popover-content');
		}

		this.scrollToFilterBottom();
		this.markNotApplied();
	};

	getFilterByIndex = (index) => {
		return this.dashboard.appliedFiltersArray[index];
	};

	markNotApplied = () => {
		this.dashboard.properties.isAttributeFiltersApplied = false;
	};

	getDisplayName = (selectedAttribute) => {
		return this.dashboardFiltersService.getFilterDisplayName(selectedAttribute);
	};

	getAriaLabel = (selectedAttribute): string => {
		const filterTypeName = this.getAriaFilterName(selectedAttribute);
		const filterDisplayName = this.dashboardFiltersService.getFilterDisplayName(selectedAttribute);
		return `${filterTypeName} ${filterDisplayName}`;
	};

	private getAriaFilterName = (selectedAttribute): string => {
		if (this.isTopic(selectedAttribute)) {
			return this.locale.getString('dashboard.dashboardTopicFilterAria');
		}

		if (this.isDateRange(selectedAttribute)) {
			return this.locale.getString('dashboard.dashboardDateRangeFilterAria');
		}

		if (this.isAttribute(selectedAttribute)) {
			return this.locale.getString('dashboard.dashboardAttributeFilterAria');
		}

		if (this.isMetric(selectedAttribute)) {
			return this.locale.getString('dashboard.dashboardMetricFilterAria');
		}

		if (this.isText(selectedAttribute)) {
			return this.locale.getString('dashboard.dashboardTextFilterAria');
		}
	};

	getFilterValue = (filter) => {
		if (filter.isOrgHierarchy) {
			return this.personalization.currentHierarchyNode.name;
		}

		return this.dashboardFilterLabels.getFilterLabel(filter);
	};

	isMaximumFilters = () => {
		return this.dashboard && this.dashboard.appliedFiltersArray
			&& (this.dashboardFiltersService.getAttributeFilters(this.dashboard.appliedFiltersArray)
				.length > (this.MAX_FILTERS_ALLOWED - 1));
	};

	isDisabledAdding = () => {
		if (this.dashboard && this.dashboard.appliedFiltersArray) {
			let dashboardProps = this.dashboard;

			// don't count org hierarchy filter toward total
			let attributeFilters = this.dashboardFiltersService.getAttributeFilters(dashboardProps.appliedFiltersArray);

			return (this.isMaximumFilters()
				|| attributeFilters.filter((filter) => isEmpty(filter.selectedAttribute)).length > 0);
		}
		return false;
	};

	searchSavedFilters = (filter: DashboardFilter, query: string): void => {
		query = query || '';

		this.searchSavedFiltersQuery(filter.selectedAttribute, query).then((result) => {
			let options: AttributeValueOption[] = [];

			let resultHasValues: boolean = result.data && !!result.data.length;
			if (resultHasValues) {
				options.pushAll(result.data);
			}

			let nestedList = options;
			// if multiselect is in use we need to keep the selected items in the list
			if (filter.multiValues) {
				options = this.attributeValueSearcher.updateSelectedList(options, filter.multiValues, true);
			}

			if (result.folders && result.folders.length) {
				nestedList = this.addNestedFolders(options, result.folders, query);
			} else {
				nestedList = options;
			}

			filter.attrValues = options;
			filter.nestedList = orderBy(nestedList, 'displayName', 'asc');
		});
	};

	filterFolderChildrenByQuery = (folder, query) => {
		folder.children = _.filter(folder.children as any[], (child) => {
			return child.selected || this.dashboardFiltersService.nameContainsIgnoreCase(child.object.name, query);
		});
	};

	populateChildrenWithExistingSelections = (currentChildren: any[], potentialFolderChildren: any[]) => {
		let currentSelectedChildrenIds = _.pluck(_.pluck(_.filter(currentChildren, {selected: true} as any), 'name'), 'id');
		potentialFolderChildren.forEach( (child) => {
			//Need to add selection to child
			if (_.contains(currentSelectedChildrenIds, child.object.id)) {
				child.selected = true;
			}

		});
		if (currentChildren) {
			currentChildren.forEach((currentChild) => {
				//If child does not match search query, but is selected, need to add it
				if (!_.some(potentialFolderChildren, (child) => {
					return child.object.id === currentChild.object.id;
				})) {
					potentialFolderChildren.push(currentChild);
				}

			});
		}
		return potentialFolderChildren;
	};

	processExpansionForFolder = (folder, query) => {
		let children: any[] = folder.children;
		if (_.some(children, child => child.selected === true)) {
			//some child is selected, so we should expand parent
			folder.expandedSelected = true;
		} else {
			if (query && query.length) {
				if (this.dashboardFiltersService.nameContainsIgnoreCase(folder.name, query)) {
					//Folder name matches query
					folder.expandedSelected = true;
					folder.expandedUnselected = true;
				} else {
					if ( _.some(children, this.dashboardFiltersService.displayNameCompareFunc(query))) {
						//Some child of folder (not selected) matches query
						folder.expandedUnselected = true;
					} else {
						folder.expandedSelected = false;
						folder.expandedUnselected = false;
					}
				}
			} else {
				folder.expandedSelected = true;
				folder.expandedUnselected = false;
			}
		}
	};

	addNestedFolders = (options: any[], folders: any[], query) => {
		let nestedList = [];
		let itemsToAdd = angular.copy(options);
		while (itemsToAdd.length > 0) {
			let attrValue = itemsToAdd[0];
			if (attrValue && attrValue.object && attrValue.object.parentId) {
				//some parent folder for an attribute
				//Check if folder is in items to add, in which case it has some values selected
				let folder = _.find(itemsToAdd, {type: FolderTypes.FILTER, id: attrValue.object.parentId});
				if (!folder) {
					//Cant find folder, need to get from folders list and populate children
					folder = _.find(folders, {id: attrValue.object.parentId});
				}
				//Need to check folder children for selected nodes before replacing them

				let currentFolderChildren = folder.children;
				let potentialFolderChildren = _.filter(itemsToAdd, item => item.object.parentId === folder.id);
				folder.children = this.populateChildrenWithExistingSelections(currentFolderChildren, potentialFolderChildren);


				if (folder) {
					this.processExpansionForFolder(folder, query);

					folder.displayName = folder.name;
					nestedList.push(folder);
					itemsToAdd = _.reject(itemsToAdd, item => item.object.parentId === folder.id);
					itemsToAdd = _.reject(itemsToAdd, {type: FolderTypes.FILTER, id: folder.id} as any);
				} else {
				//couldnt find folder for item
					nestedList.push(attrValue);
					itemsToAdd.splice(0, 1);

				}
			} else {
				nestedList.push(attrValue);
				itemsToAdd.splice(0, 1);
				if (attrValue.type === FolderTypes.FILTER && query && query.length) {
					this.filterFolderChildrenByQuery(attrValue, query);
				}
			}

		}
		return nestedList;

	};

	searchSavedFiltersQuery = (attribute: SearchedAttribute, query?: string) => {
		let dashProps = this.dashboard.properties;

		let filtersAndFoldersPromise = PromiseUtils.old(
			this.reportFiltersService.getDashboardFiltersWithFolders(this.dashboard));

		let scorecardFiltersPromise: ng.IPromise<any> = DashboardUtils.isStudioAdminDashboard(this.dashboard)
			? this.$q.when([])
			: PromiseUtils.old(this.reportScorecardFiltersService.getDashboardScorecardFilters(this.dashboard));

		return this.$q.all([filtersAndFoldersPromise, scorecardFiltersPromise]).then(result => {
			let filterAndFolersResultData = result[0];

			let cmpFilters = _.filter(filterAndFolersResultData, {type: FilterTypes.CXANALYZE} as any);
			let studioFilters = _.filter(filterAndFolersResultData, {type: FilterTypes.CXSTUDIO} as any);
			let predefinedFilters = _.filter(filterAndFolersResultData, {type: FilterTypes.PREDEFINED} as any);
			studioFilters = _.filter(studioFilters, (filter: any) => !filter.dateFilter);
			let filterFolders = _.filter(filterAndFolersResultData, {type: FolderTypes.FILTER} as any);

			let unifiedFilters: FilterTreeItem[] = _.union(cmpFilters, studioFilters);
			unifiedFilters = _.filter(unifiedFilters, this.visibleFiltersFunc(this.dashboardHistory.getAppliedFilters()));
			unifiedFilters = this.addContentProviderInformationToFilters(unifiedFilters, dashProps);
			unifiedFilters = _.union(
				unifiedFilters, predefinedFilters, this.getAppliedSavedFilters(unifiedFilters));

			let scorecardFilters: IScorecardFilter[] = this.enrichScorecardFilters(result[1]);
			unifiedFilters = _.union(unifiedFilters, scorecardFilters);

			return this.filterFoldersAndChildrenByQuery(attribute, unifiedFilters, filterFolders, query);
		});
	};

	private enrichScorecardFilters(scorecardFilters: IScorecardFilter[]): IScorecardFilter[] {
		return _.chain(scorecardFilters)
			.filter(scorecardFilter => !scorecardFilter.disabled && !scorecardFilter.hide)
			.map(scorecardFilter => {
				scorecardFilter.displayName = scorecardFilter.name;
				return scorecardFilter;
			})
			.value();
	}

	private filterFoldersAndChildrenByQuery
		= (attribute: SearchedAttribute, filteredFilters: any[], filterFolders: any[], query?): SearchTermsResult => {
			if (query && query.length) {
			//Find folders whose name matches query
				let matchingFolderIds = _.pluck(_.filter(filterFolders, (folder) => {
					return this.dashboardFiltersService.nameContainsIgnoreCase(folder.name, query);
				}), 'id');
			//Filter out filters who don't match query
				filteredFilters = _.filter(filteredFilters, (filter) => {
					return this.dashboardFiltersService.nameContainsIgnoreCase(filter.name, query) ||
				(filter.parentId && _.find(matchingFolderIds, id => id === filter.parentId));
				});

				filterFolders.forEach((folder) => {
				//Filter out children who do not match query or have parent who matches query
					this.filterFolderChildrenByQuery(folder, query);
				});
			}
			return {
				type: FilterValuesSearchType.SAVED_FILTER,
				data: this.attributeValueSearcher.getSearchedFilters(filteredFilters),
				folders: filterFolders
			};
		};

	visibleFiltersFunc = (appliedFiltersArray: any[]) => {
		let savedFilterAttribute = _.find(appliedFiltersArray, (attr) => {
			return this.isSavedFilter(attr.selectedAttribute);
		});
		let filterMatcher;
		if (savedFilterAttribute) {
			let selectedFilters = _.map(savedFilterAttribute.multiValues, 'name');
			filterMatcher = this.filterUtils.filterMatcher(selectedFilters);
		}
		return (filter) => {
			return !filter.hide || filterMatcher && filterMatcher(filter);
		};
	};

	addContentProviderInformationToFilters = (filters, props) => {
		filters.forEach((filter) => {
			if (props && props.cbContentProvider) {
				filter.contentProviderId = props.cbContentProvider + '';
				filter.accountId = props.cbAccount + '';
				filter.projectId = props.project + '';
			}
		});
		return filters;
	};

	getAppliedSavedFilters = (filters): any[] => {
		let savedFilterAttribute = _.find(this.dashboard.appliedFiltersArray, (attr) => {
			return this.isSavedFilter(attr.selectedAttribute);
		}) as any;

		if (!savedFilterAttribute) {
			return [];
		}

		// at the beginning values are in multiValues
		let values = savedFilterAttribute.nestedList || savedFilterAttribute.multiValues;
		let selectedFilters = _.map(values, 'object');

		let unavailable = (filter) => {
			if (filter && filter.type === FilterTypes.CXANALYZE) {
				return !_.findWhere(filters, {filterId: filter.filterId});
			}

			if (filter && filter.type === FilterTypes.CXSTUDIO) {
				return !_.findWhere(filters, {id: filter.id});
			}

			return false;
		};
		return _.chain(selectedFilters)
			.filter(unavailable)
			.each((filter: any) => filter.css = 'not-shared')
			.value();


	};

	removeFilter = (index) => {
		this.onFilterChange({$action: 'remove', $filter: this.dashboard.appliedFiltersArray[index]});

		// if the filter being removed has been defined, reenable the apply button
		if (this.dashboardFiltersService.isValidFilter(this.dashboard.appliedFiltersArray[index])) {
			this.dashboard.properties.isAttributeFiltersApplied = false;
		}

		this.dashboard.appliedFiltersArray.splice(index, 1);
		this.$rootScope.$broadcast('dashboardFiltersChangedEvent');
	};


	isProjectSelected = () => {
		return this.dashboard
			&& this.dashboard.properties
			&& (this.dashboard.properties.project > 0 || DashboardUtils.isStudioAdminDashboard(this.dashboard));
	};

	dateOptionsFilter = (filter) => {
		if (!DashboardUtils.isStudioAdminDashboard(this.dashboard)) {
			return true;
		}
		return !this.DateRange.isCustomDateRange(filter.value)
			&& !!_.findWhere(this.DateRange.adminProjectOptions, {value: filter.value});
	};

	showAddFilterButton = () => {
		return this.isProjectSelected() && !this.isMaximumFilters();
	};

	canChangeAnyFilters = () => {
		if (!this.dashboard) return false;

		return this.dashboardFiltersService.canChangeFilters(this.dashboard.appliedFiltersArray,
			this.isHierarchyNodeSelectable());
	};

	allFiltersValid = () => {
		return this.dashboard && this.dashboard.appliedFiltersArray
			&& this.dashboard.appliedFiltersArray.filter(this.dashboardFiltersService.isInvalidFilter).length === 0;
	};

	showApplyButton = () => {
		if (this.editMode) {
			return this.canChangeAnyFilters() || this.isProjectSelected();
		} else {
			return (this.definedFilterCount() >= 1);
		}
	};

	definedFilterCount = () => {
		if (!this.dashboard) return 0;

		let visibleFilters = this.dashboardFiltersService.getVisibleFilters(this.dashboard.appliedFiltersArray,
			this.isHierarchyNodeSelectable());
		return visibleFilters.length - (visibleFilters.filter(this.dashboardFiltersService.isInvalidFilter).length);
	};

	// show warning when no default project is selected AND no hierarchy is enabled
	showFullFiltersWarning = () => {
		return this.editMode && !this.isHierarchyNodeSelectable() && !this.isProjectSelected();
	};

	// show warning when no default project is selected, but hierarchy is enabled
	showPartialFiltersWarning = () => {
		return this.editMode && this.isHierarchyNodeSelectable() && !this.isProjectSelected();
	};

	isAttributeFilter = (dashboardFilter) => {
		return !(dashboardFilter.isOrgHierarchy
			|| dashboardFilter.selectedAttribute && !this.dashboardFiltersService.isAttribute(dashboardFilter.selectedAttribute));
	};

	isHierarchyNodeSelectable = () => {
		return this.personalization && this.personalization.isHierarchySelectable();
	};

	toggleHierarchyDropdown = () => {
		if (!this.personalization || this.personalization.currentHierarchyNode.id === ReportConstants.NOT_VALID_NODE_ID) {
			return;
		}
		this.personalization.showHierarchyList = !this.personalization.showHierarchyList;
		this.personalization.hierarchySearchText = '';

		if (this.personalization.showHierarchyList) {
		//Opening list, expand to selected node
			this.collapseTreeToRootLevel();
			this.initHierarchyCloseHandler(); // hide on click outside
		} else {
		//Closing list, collapse all nodes but root
			this.collapseDescendants(this.personalization.hierarchyNodes[0]);
		}

		if (!this.personalization.hierarchyLoaded) {
			this.personalization.loadEntireHierarchy(this.personalization.hierarchyNodes[0].hierarchyId);
		}
	};

	collapseDescendants = (rootNode) => {
		if (!rootNode.children) return;
		rootNode.expanded = false;
		for (let child of rootNode.children) {
			this.collapseDescendants(child);
		}
	};

	collapseTreeToRootLevel = () => {
		if (this.personalization.rawHierarchy && this.personalization.hierarchyNodes) {
			this.personalization.hierarchyNodes = this.personalization.getHierarchyNodes();
			(this.$filter('addNestedChildren') as any)(this.personalization.rawHierarchy,
				this.hierarchyService.getAncestorIdsFromNode(this.personalization.currentHierarchyNode), this.personalization.hierarchyNodes);
			this.personalization.hierarchyNodes[0].expanded = true;
		}
	};

	initHierarchyCloseHandler = () => {
		let ignoreInitial = true;
		$(document).on('click.orgHierarchy', (event) => {
			if (ignoreInitial) {
				ignoreInitial = false;
				return;
			}
			let target = $(event.target);
			if (!target.parents().last().is(document.documentElement) // if not attached to dom, ignore it
					|| target.parents('.br-organization-panel').length > 0) // ignore any elements inside panel
				return;
			$(document).off('click.orgHierarchy');
			this.personalization.showHierarchyList = false;
			this.$scope.$apply();
		});
	};

	onHierarchyNodeChange = (node) => {
		this.personalization.showHierarchyList = false;
		this.personalization.setHierarchyNode(node);
		this.changeHierarchyNode();
	};

	setHierarchyNode = (node) => {
		this.personalization.showHierarchyList = false;
		this.personalization.setHierarchyNode(node);
	};

	changeHierarchyNode = () => {
		if (DashboardUtils.getDashboardFiltersCount(this.dashboard) === 0) { // only org hierarchy or empty
			this.$rootScope.$broadcast('dashboardRefreshEvent', this.dashboard.id);
			this.persistAppliedHierarchyNode();
		} else {
			let orgFilter = this.dashboardFiltersService.getHierarchyFilter(this.dashboard.appliedFiltersArray)[0];
			(orgFilter as any).node = this.personalization.currentHierarchyNode.fullPath;
			this.onFilterChange({$action: 'update', $filter: 'orgHierarchy'});
			this.dashboard.properties.isAttributeFiltersApplied = false;
		}
	};

	persistAppliedHierarchyNode = () => {
		this.dashboardHistory.applyHierarchyNode(this.personalization && this.personalization.currentHierarchyNode);
	};

	showMultiselect = (filter) => {
		return this.isAttribute(filter.selectedAttribute) || this.isSavedFilter(filter.selectedAttribute);
	};

	showNumericInput = (filterRule: IFilterRule): boolean => {
		return filterRule.type === FilterRuleType.numericOpenRange && !filterRule.existMatch;
	};

	showNumericRange = (filterRule: IFilterRule): boolean => {
		return filterRule.type === FilterRuleType.numericRange && !filterRule.existMatch;
	};

	getPattern = (filterRule: IFilterRule) => {
		return this.metricConstants.isIntNumberAttribute(filterRule.attributeName) ? '^-?\\d*' : '';
	};

	isSavedFilter = (attribute) => {
		return attribute && attribute.name === 'savedFilter';
	};

	formatFilterDisplayName = (filter) => {
		let attributeFilter: any = _.find(this.dashboard.appliedFiltersArray, (appliedFilter) => {
			return appliedFilter.selectedAttribute
				? appliedFilter.selectedAttribute.name === filter.attributeName
				: false;
		});

		if (!attributeFilter || !attributeFilter.selectedAttribute.settings) {
			return filter.displayName;
		}

		let selectedAttribute = attributeFilter.selectedAttribute;
		if (selectedAttribute.type === AttributeType.TEXT && this.urlService.isUrl(filter.name)) {
			return this.$sanitize(filter.name);
		}

		return this.enrichmentAttributesService.formatAttributeValue(filter.name, selectedAttribute.type, selectedAttribute.settings);
	};

	disableAdditionalFilterValues = (item, allItems) => {
		let SELECTION_LIMIT = 20;
		allItems = allItems || [];
		return !item.selected
			&& (this.existMatchSelected(allItems)
				|| (allItems.filter((oneItem) => oneItem.selected).length >= SELECTION_LIMIT));
	};

	existMatchSelected = (allItems) => {
		return _.some(allItems, (item: any) => item.existMatch && item.selected);
	};

	valueChanged = () => {
		this.dashboard.properties.isAttributeFiltersApplied = false;
	};

	filtersEqual = (filter1, filter2) => {
		return this.filterUtils.filtersEqual(filter1, filter2);
	};

	toggleFilterLocked = (filter: DashboardFilter) => {
		if (this.editMode) {
			filter.locked = !filter.locked;
		}
	};

	setAvailableHeight = () => {
		//add new filter, topics could be small initially and then increase their height up to this number
		let SPACE_FOR_LONG_MENUS = 330;
		let popoverTop = $('.dashboard-filters-pop').position().top;
		let windowHeight = $(window).height();
		let availableHeight = windowHeight - popoverTop - SPACE_FOR_LONG_MENUS;
		let style = {
			'max-height': availableHeight + 'px'
		};
		$('.dashboard-filters-wrap').css(style);
	};

	hasDrillToDashboardFilters = () => {
		return this.drillToDashboardService.hasFilters(this.dashboard.id);
	};

	getDrillToDashboardFilters = () => {
		if (!this.drillToDashboardFilters) {
			this.drillToDashboardFilters = this.drillToDashboardService.getFilters(this.dashboard.id);
		}

		return this.drillToDashboardFilters;
	};

	applyTextFilter = (text): void => {
		if (!this.dashboardHistory.hasTextFilter()) {
			// apply text filter like we click "Apply" button, but without applying other unsaved filters
			let textFilter = _.find(this.dashboard.appliedFiltersArray, (filter) => {
				return this.isText(filter.selectedAttribute);
			});
			let newAppliedFilters = _.union(this.dashboardHistory.getAppliedFilters(), [textFilter]);
			this.dashboardHistory.setAppliedFilters(newAppliedFilters);
			this.dashboard.properties.isAttributeFiltersApplied = true;
		}
		this.dashboardHistory.setTextFilter(text);
		this.$rootScope.$broadcast('dashboardRefreshEvent', this.dashboard.id);
	};

	getCurrentTextFilter = () => {
		return this.dashboardHistory.getTextFilter() || '';
	};

	selectMatchMode = (dashboardFilter: DashboardFilter): void => {
		let rule = dashboardFilter.genericFilterFormat;
		dashboardFilter.selectedAttribute.matchMode = rule.matchMode;
		dashboardFilter.selectedAttribute.existMatch = rule.existMatch;
		dashboardFilter.selectedAttribute.modelMatch = rule.modelMatch;
		if (this.filterCreatorService.showModelSelect(rule)) {
			dashboardFilter.selectedAttributeValue = {
				strategy: (rule.values.length > 0) ? TreeSelectionStrategy.SUBSET : TreeSelectionStrategy.NONE,
				nodes: rule.values
			};
		} else if (rule.modelMatch) {
			dashboardFilter.selectedAttributeValue = {
				strategy: TreeSelectionStrategy.EVERYTHING,
				nodes: rule.values
			};
		}
		this.valueChanged();
	};

	getDropdownDefaultPosition = (): string => {
		return DropdownPosition.RIGHT;
	};

	getOperatorsDropdownPosition = (): string => {
		return DropdownPosition.BOTTOM;
	};

	onFiltersWindowKeydown = (event: any): void => {
		let target: any = event.target;
		let focusableElements: JQuery = $('#dashboard-filters-edit :focusable');
		if (focusableElements.length > 0) {
			if (KeyboardUtils.isEventKey(event, Key.TAB) && target === focusableElements.last()[0]) {
				event.preventDefault();
				event.stopPropagation();
				focusableElements.first().trigger('focus');
			} else if (KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT) && target === focusableElements.first()[0]) {
				event.preventDefault();
				event.stopPropagation();
				focusableElements.last().trigger('focus');
			}
		}

		if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			event.preventDefault();
			event.stopPropagation();
		}
	};

	onFiltersWindowKeyup = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			event.preventDefault();
			event.stopPropagation();
			this.$timeout(() => {
				let popoverToggle = $('#filters-popover-toggle');
				popoverToggle.trigger('click');
				popoverToggle.trigger('focus');
				this.$scope.$emit('popover:close');
			});
		}
	};

	onApplyChangesKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			event.preventDefault();
			event.stopPropagation();
			this.applyDashboardFilters();
			$('#filters-popover-toggle').trigger('focus');
		}
	};

	onClearChangesKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			event.preventDefault();
			event.stopPropagation();
			this.clearChanges();
			$('#dashboard-filters-edit :focusable').first().trigger('focus');
		}
	};

	onAddFilterKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			event.preventDefault();
			event.stopPropagation();
			this.addFilter();
			this.$timeout(() => {
				$('#select-from-hierarchy-button').trigger('focus');
			});
		}
	};

	onHierarchyToggleKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			event.preventDefault();
			event.stopPropagation();
			this.$timeout(() => {
				$('#hierarchy-dropdown-button').trigger('click');
				$('#hierarchy-selector-menu :focusable').first().trigger('focus');
			});
		}
	};

	onHierarchySelectorMenuKeyup = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			event.preventDefault();
			event.stopPropagation();
			this.$timeout(() => {
				this.closeAndFocusOnHierarchyDropdown();
			});
		}
	};

	private closeAndFocusOnHierarchyDropdown = (): void => {
		let hierarchyDropdownButton = $('#hierarchy-dropdown-button');
		hierarchyDropdownButton.trigger('click');
		hierarchyDropdownButton.trigger('focus');
	};
}

app.component('dashboardFiltersEdit', {
	controller: DashboardFiltersEditController,
	bindings: {
		dashboard: '<',
		containerId: '<',
		dashboardHistory: '<',
		isVisible: '<',
		editMode: '<',
		embedded: '<?',
		personalization: '<',
		projectAttributes: '<',
		projectDateFilters: '<',
		projectTimezone: '<',
		applyFilters: '&',
		onFilterChange: '&',
		closePopup: '&'
	},
	templateUrl: 'partials/dashboard/dashboard-filters/dashboard-filters-edit.html',
});
