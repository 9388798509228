import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetReportDisplayState } from './widget-report-display-state';
import { ReportResponsiveness } from './report-responsiveness';
import { AnalyticCacheOptions } from '../entities/analytic-cache-options';
import { ResponsiveReportResult } from '../entities/responsive-report-result.interface';
import { FreshReportResult, ResponsiveState } from '../entities/responsive-state.interface';
import { ReportResultCache } from '../entities/report-result-cache.interface';
import { ResponsiveReportContext } from '../entities/responsive-report-context.interface';
import { ResponsiveDashboardService } from './responsive-dashboard-service';
import { WidgetTypeFilters } from '@cxstudio/home/widget-type-filters.class';
import ReportRunService from '@cxstudio/reports/report-run.service';
import { Security } from '@cxstudio/auth/security-service';
import { PreviewHelper } from '@cxstudio/reports/preview/preview-helper-service';
import { DashboardReportType } from '@app/modules/dashboard/entity/dashboard-report-type.enum';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import * as moment from 'moment';
import { FrontlineDashboardUtils } from '@app/modules/dashboard/services/utils/frontline-dashboard-utils';

export class ResponsiveReportService {

	constructor(
		private $q: ng.IQService,
		private $timeout: ng.ITimeoutService,
		private $rootScope: ng.IRootScopeService,
		private responsiveDashboardService: ResponsiveDashboardService,
		private reportRunService: ReportRunService,
		private previewHelper: PreviewHelper,
		private security: Security
	) {
	}

	createResponsiveState(widget: Widget): ResponsiveState {
		if (!this.isResponsiveApplicable(widget)) {
			return;
		}
		return {
			responsiveness: null,
			displayState: null,
			freshReportResult: null
		};
	}

	/**
	 * Selects flow depending on report responsiveness.
	 * If new widget behaviour beta is enabled, runs responsive flow, otherwise runs regular reports.
	 */
	getAndDisplayData(context: ResponsiveReportContext): void {
		if (!this.isResponsiveApplicable(context.widget)) {
			context.displayData(context.getData(context.widget), context.reportRun);
			return;
		}
		this.cancelDataRefresh(context.widget.responsiveState);

		if (this.isEnterpriseWidgetOpenedByViewer() || this.isFrontlineWidgetView(context)) {
			this.runCachedWidgetReport(context);
			return;
		}

		let responsiveness = this.getReportResponsiveness(context);

		if (responsiveness === ReportResponsiveness.RESPONSIVE) {
			this.runResponsiveFlow(context);
		} else {
			this.refreshResponsiveReport(context);
		}
	}

	private isEnterpriseWidgetOpenedByViewer(): boolean {
		return this.security.loggedUser.isEnterpriseUser;
	}

	// returns true if this is a regular view of frontline widget (not manual refresh)
	private isFrontlineWidgetView(context: ResponsiveReportContext): boolean {
		return FrontlineDashboardUtils.isFrontlineDashboard(context.dashboard)
			&& context.reportRun?.responsiveness !== ReportResponsiveness.NO_CACHE;
	}

	private isResponsiveApplicable(widget: Widget): boolean {
		return WidgetTypeFilters.isReportWidget(widget) && !this.previewHelper.isDocumentPreview(widget);
	}

	/**
	 * Runs responsive flow. Responsive report consists of 2 calls:
	 * 1. First call tries to get cached data.
	 * 2. If the cache is outdated, runs the second call which always returns fresh data.
	 */
	private runResponsiveFlow(context: ResponsiveReportContext): void {
		let cachedWidget = this.prepareCachedResponsiveWidget(context.widget);

		context.widget.responsiveState.displayState = WidgetReportDisplayState.LOADING_CACHED_DATA;
		context
			.displayData(context.getData(cachedWidget), context.reportRun)
			.then(widgetData => this.checkReportCache(context, widgetData),
				(error) => this.setDisplayingFreshData(cachedWidget));
	}

	/**
	 * Uncached report for responsive flow, refreshes all caches.
	 */
	private refreshResponsiveReport(context: ResponsiveReportContext): void {
		let noCacheWidget = this.prepareResponsiveRefreshingWidget(context.widget);
		this.runSingleReportFlow(noCacheWidget, context);
	}

	private runCachedWidgetReport(context: ResponsiveReportContext): void {
		let cacheWidgetReport = this.prepareCacheWidget(context.widget);
		this.runSingleReportFlow(cacheWidgetReport, context);
	}

	private runSingleReportFlow(widget: Widget, context: ResponsiveReportContext): void {
		context.widget.responsiveState.displayState = WidgetReportDisplayState.LOADING_FRESH_DATA;

		context
			.displayData(context.getData(widget), context.reportRun)
			.finally(() => this.setDisplayingFreshData(context.widget));
	}

	private checkReportCache(context: ResponsiveReportContext, widgetData: ResponsiveReportResult): void {
		//widget error. handled in report-controller errorHanled
		if (!widgetData) return;

		let reportCache = this.getReportCache(widgetData);

		if (reportCache.isUpToDate) {
			this.setDisplayingFreshData(context.widget);
			this.responsiveDashboardService.ensureFreshDataRuns();
		} else {
			this.loadFreshData(context, reportCache);
		}
	}

	private loadFreshData(context: ResponsiveReportContext, reportCache: ReportResultCache): void {
		//save hash for compare
		context.widget.responsiveState.hash = reportCache.hash;
		let noCacheWidget = this.prepareResponsiveRefreshingWidget(context.widget);
		context.widget.responsiveState.displayState = WidgetReportDisplayState.LOADING_FRESH_DATA;

		let dataPromise = this.responsiveDashboardService.scheduleFreshDataReport(() =>
			context.getData(noCacheWidget), reportCache.estElapsedTimeMs);
		this.prepareFreshReportResult(context, dataPromise, Date.now());
	}

	private getReportCache(widgetData: ResponsiveReportResult): ReportResultCache {
		return widgetData.metadata?.reportCache || {
			isUpToDate: true,
			estElapsedTimeMs: 0,
			hash: ''
		};
	}

	isReportInViewport(widget: Widget): boolean {
		let widgetBox: JQuery = $('#widget-' + widget.id);
		if (widgetBox) {
			return (widgetBox as any).isInViewport();
		} else {
			// by default, if could not define widget container, assume the report is displayed
			return true;
		}
	}

	private hasDifference(previousHash: string, currentHash: string): boolean {
		if (_.isEmpty(previousHash) || _.isEmpty(currentHash)) {
			return true;
		}
		return previousHash !== currentHash;
	}

	private prepareFreshReportResult(
		context: ResponsiveReportContext, dataPromise: ng.IPromise<ResponsiveReportResult>, startTime: number): void {
		let data;
		dataPromise.then((report) => {
			context.widget.responsiveState.displayState = WidgetReportDisplayState.DISPLAYING_CACHED_DATA;
			data = report;
			if (!this.hasDifference(context.widget.responsiveState.hash, this.getReportCache(report).hash)) {
				this.setDisplayingFreshData(context.widget, WidgetReportDisplayState.DISPLAYING_FRESH_DATA_NO_CHANGE);
			} else if (!this.isReportInViewport(context.widget)) {
				this.displayFreshData(context.widget);
			}
		}, (error) => {
			this.setDisplayingFreshData(context.widget);
		});

		let freshReportResult: FreshReportResult = {
			dataPromise,
			startTime,
			display: () => {
				context.displayData(this.$q.when(data), context.reportRun);
				this.setDisplayingFreshData(context.widget);
			}
		};

		context.widget.responsiveState.freshReportResult = freshReportResult;
	}

	private cancelDataRefresh(state: ResponsiveState): void {
		if (state) {
			let freshReportResult = state.freshReportResult;
			if (freshReportResult?.dataPromise
				&& (state.displayState === WidgetReportDisplayState.LOADING_FRESH_DATA
					|| state.displayState === WidgetReportDisplayState.LOADING_CACHED_DATA)) {
				// if 2nd request is waiting to be executed, this will cancel the process
				this.$timeout.cancel(freshReportResult.dataPromise);
				state.freshReportResult = null;
			}
		}
	}

	private getReportResponsiveness(context: ResponsiveReportContext): ReportResponsiveness {
		let responsiveness = !this.$rootScope.pdf && context.reportRun?.responsiveness === ReportResponsiveness.RESPONSIVE
			? ReportResponsiveness.RESPONSIVE
			: ReportResponsiveness.NO_CACHE;

		let widget = context.widget;

		if (!widget.responsiveState) {
			widget.responsiveState = this.createResponsiveState(widget);
		}

		context.widget.responsiveState.responsiveness = responsiveness;

		return responsiveness;
	}

	isResponsivenessEnabled(): boolean {
		return !this.$rootScope.pdf;
	}

	private prepareCachedResponsiveWidget(widget: Widget): Widget {
		widget.properties.reportCacheOption = AnalyticCacheOptions.Try;
		widget.properties.cacheOption = AnalyticCacheOptions.Normal;
		widget.properties.backgroundReport = true;

		return widget;
	}

	private prepareResponsiveRefreshingWidget(widget: Widget): Widget {
		widget.properties.reportCacheOption = AnalyticCacheOptions.Refresh;
		widget.properties.cacheOption = AnalyticCacheOptions.Refresh;
		widget.properties.backgroundReport = false;

		return widget;
	}

	private prepareCacheWidget(widget: Widget): Widget {
		widget.properties.reportCacheOption = AnalyticCacheOptions.Try;
		widget.properties.cacheOption = AnalyticCacheOptions.Normal;
		widget.properties.backgroundReport = false;

		return widget;
	}

	setDisplayingFreshData(widget: Widget, freshState?: WidgetReportDisplayState): void {
		let state = widget.responsiveState || {};
		state.displayState = freshState || WidgetReportDisplayState.DISPLAYING_FRESH_DATA;
		state.responsiveness = state.responsiveness || ReportResponsiveness.RESPONSIVE;
		widget.responsiveState = state;
	}

	setLoadingFreshData(widget: Widget): void {
		let state = widget.responsiveState || {};
		state.displayState = WidgetReportDisplayState.LOADING_FRESH_DATA;
		state.responsiveness = state.responsiveness || ReportResponsiveness.RESPONSIVE;
		widget.responsiveState = state;
	}

	resetResponsiveState(widget: Widget): void {
		widget.responsiveState = widget.responsiveState || {};
		widget.responsiveState.responsiveness = null;
		widget.responsiveState.displayState = null;
	}

	updateCacheTimestamp(widget: Widget, report: ReportDataObject): void {
		let state = widget.responsiveState || {};
		state.upToDate = report.metadata?.reportCache?.isUpToDate;
		let isoDate = report.metadata?.reportCache?.timeStamp;
		state.reportTimestamp = isoDate ? moment(isoDate).toDate() : isoDate;
		widget.responsiveState = state;
	}

	isLoadingResponsiveReport(widget: Widget): boolean {
		let state = widget.responsiveState || {};
		let displayState = state.displayState;
		let responsiveness = state.responsiveness;
		let loadingWidgetAssets = !responsiveness;
		if (!this.isResponsiveApplicable(widget)) {
			return false;
		} else {
			let loadingNoCacheWidget = responsiveness === ReportResponsiveness.NO_CACHE
				&& displayState === WidgetReportDisplayState.LOADING_FRESH_DATA;
			let loadingResponsiveWidget = responsiveness === ReportResponsiveness.RESPONSIVE
				&& displayState === WidgetReportDisplayState.LOADING_CACHED_DATA;

			return loadingWidgetAssets || loadingNoCacheWidget || loadingResponsiveWidget;
		}
	}

	isLoadingReport(widget: Widget): boolean {
		if (!this.isResponsiveApplicable(widget)) {
			return false;
		}
		return !widget.responsiveState?.responsiveness
			|| this.reportRunService.isLoading(widget.id, widget.name, widget.containerId);
	}

	isShowingStaleData(widget: Widget): boolean {
		let state = widget.responsiveState || {};

		return state.responsiveness === ReportResponsiveness.RESPONSIVE
			&& (state.displayState === WidgetReportDisplayState.LOADING_FRESH_DATA
				|| state.displayState === WidgetReportDisplayState.DISPLAYING_CACHED_DATA);
	}

	isReadyToDisplayFreshData(widget: Widget): boolean {
		let state = widget.responsiveState || {};

		return state.responsiveness === ReportResponsiveness.RESPONSIVE
			&& state.displayState === WidgetReportDisplayState.DISPLAYING_CACHED_DATA;
	}

	displayFreshData(widget: Widget): void {
		let state = widget.responsiveState || {};

		if (this.isReadyToDisplayFreshData(widget)) {
			state.freshReportResult?.display();
		}
	}

}

app.service('responsiveReportService', ResponsiveReportService);
