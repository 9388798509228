export enum TransferGroup {
	DASHBOARDS = 'dashboards',
	FILTERS = 'filters',
	DATE_FILTERS = 'dateFilters',
	METRICS = 'metrics',
	METRIC_ALERTS = 'metricAlerts',
	DRIVERS = 'drivers',
	HOME_PAGES = 'homePages',
	QUICK_INSIGHTS = 'quickInsights'
}
