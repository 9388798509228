import { downgradeInjectable } from '@angular/upgrade/static';
import { PersonalizationState, HierarchyPersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { HierarchyService } from '@cxstudio/services/hierarchy-service.service';
import { Inject, Injectable } from '@angular/core';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';

@Injectable()
export class PersonalizationStateProvider {
	constructor(
		@Inject('hierarchyService') private hierarchyService: HierarchyService,
		private organizationApiService: OrganizationApiService,
		@Inject('currentWidgets') private currentWidgets: ICurrentWidgets,
	) {}

	getInstance(hierarchyId: number, showingContext: boolean, containerId: string, preselectedNodeId?: number): PersonalizationState {
		let personalization = this.currentWidgets.getPersonalization(containerId);
		if (!personalization || personalization.getHierarchyId() !== hierarchyId ) {
			personalization = new HierarchyPersonalizationState(hierarchyId, showingContext, preselectedNodeId,
				this.hierarchyService, this.organizationApiService);
			this.currentWidgets.setPersonalization(containerId, personalization);
		}

		return personalization;
	}

	getViewAsInstance(hierarchyId: number, showingContext: boolean, viewAs: string, preselectedNodeId?: number): PersonalizationState {
		return new HierarchyPersonalizationState(hierarchyId, showingContext, preselectedNodeId,
			this.hierarchyService, this.organizationApiService, viewAs);
	}
}

app.service('personalizationStateProvider', downgradeInjectable(PersonalizationStateProvider));
