import { Injectable } from '@angular/core';
import { CustomMathExpressionAdapter} from '@app/modules/metric/definition/custom-math/adapter/custom-math-expression-adapter';
import { FormulaSegment, TextToken, TextTokenType  } from '@app/modules/metric/definition/custom-math/adapter/formula-segment';
import { CustomMathAdapterUtils } from '@app/modules/metric/definition/custom-math/adapter/custom-math-adapter-utils.class';
import { CustomMathAssets } from '@app/modules/metric/definition/custom-math/adapter/custom-math-assets';
import { CustomMathErrorType, CustomMathWarningType, WarningAssetType } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-error';
import { CustomMathToken } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-token';
import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';
import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';
import { ScorecardMetricExpressionItem } from '@cxstudio/metrics/custom-math/scorecard-metric-expression-item.class';
import { FilterMetricDefinition } from '@cxstudio/metrics/entities/filter-metric-definition.class';
import { MetricDefinition } from '@cxstudio/metrics/entities/metric-definition';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { ScorecardMetric } from '@cxstudio/projects/scorecards/entities/scorecard-metric';
import { CustomMathMetrics } from './custom-math-metrics';

@Injectable({providedIn: 'root'})
export class ScorecardMetricAdapter implements CustomMathExpressionAdapter {

	parseToken(token: CustomMathToken, assets: CustomMathAssets, appliedMetrics: CustomMathMetrics): FormulaSegment {
		let { metric, showWarning } = this.findScorecardMetricByDisplayName(token.value, assets, appliedMetrics.scorecardMetrics);

		let textToken: TextToken = CustomMathAdapterUtils.getItemNameToken(token, TextTokenType.SCORECARD_METRIC, !metric);
		if (!metric && textToken) {
			textToken.errors = [CustomMathErrorType.MISSED_REFERENCE];
		}
		if (metric && textToken && showWarning) {
			textToken.warnings = [{
				type: CustomMathWarningType.DUPLICATE_NAME_WITH_PATH,
				params: {
					name: metric.displayName,
					type: WarningAssetType.SCORECARD_METRIC,
					path: metric.nodeFullPath
				}
			}];
		}

		let segment: FormulaSegment = {
			text: token.text,
			startOffset: token.offset,
			textTokens: CustomMathAdapterUtils.getSegmentTextTokens('scorecard', TextTokenType.PREFIX, textToken)
		};

		if (metric) {
			let name = metric.name;
			let display = `metric[${token.value}]`;
			let expression = new ScorecardMetricExpressionItem(name, display);
			expression.scorecardId = metric.scorecardId;
			expression.nodeId = metric.nodeId;
			expression.passing = metric.passing;
			expression.criteriaLevel = !!expression.nodeId;
			segment.expression = expression;
		} else {
			segment.expression = new ExpressionItem(ExpressionPieces.SCORECARD_STUDIO_METRIC, token.value);
		}
		return segment;
	}

	toString(expression: ExpressionItem, assets: CustomMathAssets): string {
		let metricExpression = expression as ScorecardMetricExpressionItem;
		let scorecardMetric = this.findScorecardMetricByName(metricExpression.name, assets);
		let displayName = CustomMathAdapterUtils.getCalculationName(expression.displayName);
		if (scorecardMetric) {
			displayName = scorecardMetric.displayName;
		}
		displayName = CustomMathAdapterUtils.escapeSpecialChars(displayName);
		return `scorecard[${displayName}]`;
	}

	private isFilterMetricDefinition(definition: MetricDefinition): definition is FilterMetricDefinition {
		return definition.type === MetricType.FILTER;
	}

	private findScorecardMetricByName = (name: string, assets: CustomMathAssets): ScorecardMetric => {
		let scorecardMetric: any = _.find(assets.scorecardMetrics, (metric) => metric.name === name);
		return scorecardMetric as ScorecardMetric;
	};

	private findScorecardMetricByDisplayName = (displayName: string, assets: CustomMathAssets,
		appliedScorecardMetrics: ScorecardMetric[]): {metric?: ScorecardMetric; showWarning?: boolean} => {
		if (!displayName) {
			return {};
		}
		let metricsWithSameDisplayName = _.filter(assets.scorecardMetrics, scorecardMetric => scorecardMetric.displayName === displayName);
		let appliedMetric = _.findWhere(appliedScorecardMetrics, {displayName});
		let metric = appliedMetric ? appliedMetric : metricsWithSameDisplayName[0];
		return { metric, showWarning: metricsWithSameDisplayName.length > 1 };
	};
}
