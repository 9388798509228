
import { NarrativeSettingEntry } from '@app/modules/account-administration/automated-narrative/narrative-settings-list.component';
import { AssetParameters } from '@app/modules/asset-management/access/asset-parameters/asset-parameters';
import { AssetParametersFactory } from '@app/modules/asset-management/access/asset-parameters/asset-parameters-factory';
import { DocumentExplorerFilters } from '@app/modules/document-explorer/document-explorer-metric-filters/document-explorer-filters';
import { DocumentExplorerEvents } from '@app/modules/document-explorer/events/document-explorer-events';
import { DocViewPreferences } from '@app/modules/document-explorer/preferences/doc-view-preferences.class';
import { SentenceFiltersHelper } from '@app/modules/document-explorer/sentence-filters-helper';
import { InfiniteListSearcher } from '@app/modules/document-explorer/sentence-pane/infinite-list-searcher.class';
import { ProfanityDisguiseService } from '@app/modules/profanity/profanity-disguise.service';
import { ReportProcessingService } from '@app/modules/reporting/report-processing.service';
import { Security } from '@cxstudio/auth/security-service';
import { NavigationDirection } from '@cxstudio/common/entities/navigation-direction.enum';
import { LicenseType } from '@cxstudio/common/license-types';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetsCache } from '@cxstudio/dashboards/widgets/widgets-cache.factory';
import EngagorCase from '@cxstudio/engagor/engagor-case';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { DocExplorerHelperService } from '@cxstudio/reports/document-explorer/doc-explorer-helper.service';
import { ExplorerPagination, IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import { DocumentPreviewerState } from '@cxstudio/reports/document-explorer/document-previewer-state.interface';
import { PreviewDocument } from '@cxstudio/reports/document-explorer/preview-document';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import { PreviewWidget } from '@cxstudio/reports/entities/preview-widget.class';
import { IDataObject, ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import IAnalyticFeedbackSelection from '@cxstudio/reports/preview/analytic-feedback-selection.interface';
import { PreviewExportService } from '@app/modules/widget-container/widget-menu/export/preview-export.service';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { ResponsiveReportService } from '@cxstudio/reports/responsiveness/responsive-report-service';
import { DrillFilter } from '@cxstudio/reports/utils/contextMenu/drill/drill-filter';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';
import { CustomFilterService } from '@cxstudio/services/custom-filter-service';
import { EngagorApiService } from '@cxstudio/services/data-services/engagor-api.service';
import { ExportApiService } from '@cxstudio/services/data-services/export-api-service.service';
import { WidgetDataService } from '@cxstudio/services/data-services/widget-data-service.class';
import * as moment from 'moment';
import * as _ from 'underscore';
import { SearchMode, SentenceSearch } from '../entities/widget-properties';
import { IExplorerHighlighter } from './explorer-highlighter-factory';
import { PreviewVerbatim } from './preview-verbatim';
import { DocExplorerQidsService } from '@cxstudio/reports/document-explorer/doc-explorer-qids.service';
import { AnalyticCacheOptions } from '@cxstudio/reports/entities/analytic-cache-options';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { EngagorService } from '@app/modules/system-administration/master-account/integration/engagor.service';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { NarrativeSettingsApi } from '@app/modules/account-administration/automated-narrative/narrative-settings-api.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { ReportDataApiService } from '@cxstudio/services/data-services/report-data-api.service';
import { DocumentCacheService } from './document-cache.service';
import { ReportPaginationService } from '@app/modules/widget-visualizations/report-pagination.service';
import { PreviewSortAttributes } from '../entities/preview-sort-attributes';
import { EsQueryService } from '@app/modules/filter-builder/es-query/es-query.service';
import { PreviewChunkService } from '@app/modules/document-explorer/preview-chunk.service';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import { DocumentTypeUtils } from '@app/modules/document-explorer/document-type-utils.class';
import { AmplitudeDocumentSource } from '@app/modules/document-explorer/amplitude-document-source.enum';
import { AmplitudeEventUtils } from '@app/modules/analytics/amplitude/amplitude-event-utils';

const ROW_LIMIT = 10000;

// basically packages up a bunch of functionality that is required across panels
// so that we can pass this single object around instead of passing a dozen methods and properties
// eslint-disable-next-line prefer-arrow-callback
app.factory('ExplorerDataControls', function(locale: ILocale, $timeout, $q: ng.IQService, $log,
	reportDataApiService: ReportDataApiService,
	previewChunkService: PreviewChunkService,
	exportUtils: ExportUtils, exportApiService: ExportApiService,
	documentCacheService: DocumentCacheService,
	$window, docExplorerQids: DocExplorerQidsService, previewExportService: PreviewExportService,
	customFilterService: CustomFilterService,
	currentWidgets: ICurrentWidgets, $rootScope, widgetDataServiceFactory,
	esQueryService: EsQueryService, security: Security,
	engagorApiService: EngagorApiService, responsiveReportService: ResponsiveReportService,
	docExplorerHelperService: DocExplorerHelperService,
	engagorService: EngagorService, betaFeaturesService: BetaFeaturesService,
	reportAssetUtilsService: ReportAssetUtilsService,
	narrativeSettingsApi: NarrativeSettingsApi,
	profanityDisguiseService: ProfanityDisguiseService, reportProcessingService: ReportProcessingService,
) {
	return class ExplorerDataLoadControls implements IDocumentPreviewerControls {

		sentenceList: PreviewSentence[];
		documents: {[id: number]: PreviewDocument} = {};
		selectedAttributes;
		selectedWorldAwareness;
		availableAttributes: any[];
		availableModels: any[];

		selectedSentence: number;
		selectedDocument: number;
		selectedVerbatim: number;
		engagorCases: EngagorCase[];
		narrativeEntries: NarrativeSettingEntry[];
		caseExistStatusLoading: boolean;
		caseExistStatus: boolean;
		changingDoc: boolean = false;
		tabularData: Pick<ReportDataObject, 'data' | 'metadata'>;

		private pageSentences: number[];
		private pageVerbatims: number[];
		private pageDocuments: number[];
		private allLoadedVerbatims: number[];
		private allLoadedDocuments: number[];

		private pages: ExplorerPagination = { current: 1, total: 0, itemsPerPage: 20};
		totalItems: number;

		private openedDocuments: any = {};
		state: DocumentPreviewerState = {};
		widget: PreviewWidget;
		private filters: DocumentExplorerFilters;
		private startBound: number = 0;
		private endBound: number = 0;
		private globalDataObject: any = null;
		private storedStart: number = 0;
		private storedEnd: number = 0;
		private pagesPerCall: number = 5;
		private isMobile: boolean;
		private highlighter: IExplorerHighlighter;
		events: DocumentExplorerEvents;
		activeFeedbackSelection: IAnalyticFeedbackSelection;
		private loadData;
		data: ReportDataObject;
		private accountLevelHiddenObjects;
		private DOCUMENT_ID = 'documentId';
		private VERBATIM_ID = 'verbatimId';
		private SENTENCE_ID = 'id';
		private preferences: DocViewPreferences;
		private widgetsCache: WidgetsCache;
		private sentenceForExport: number;
		isDocExplorer: boolean;

		private documentHiddenAttributes = ['_language', '_languagedetected', '_doc_date', '_doc_time', '_verbatimtype'];
		private widgetDataService: WidgetDataService;

		switchVerbatimHelper: any = {
			previous: () => {
				let direction = NavigationDirection.PREVIOUS;
				if (!this.isSortByTime()) {
					return this.switchSentenceHelper.previous();
				}
				let performPageChange = this.selectedVerbatim === this.pageVerbatims[0];
				let performLoading = performPageChange
					&& this.selectedVerbatim === this.allLoadedVerbatims[0]
					&& this.canLoadMorePages(direction);

				let performLoadingPromise = $q.when();
				if (performLoading) {
					performLoadingPromise = PromiseUtils.old(this.loadNext(direction, 'verbatimId', this.selectedVerbatim));
				}
				performLoadingPromise.then(() => {
					return performPageChange ? this.pageChanged(this.getPreviousVerbatimPage()) : $q.when();
				}).then(() => {
					this.switchVerbatim(direction);
				});
			},
			next: () => {
				let direction = NavigationDirection.NEXT;
				if (!this.isSortByTime()) {
					return this.switchSentenceHelper.next();
				}
				let performPageChange = this.selectedVerbatim === this.pageVerbatims.last();
				let performLoading = performPageChange
					&& this.selectedVerbatim === this.allLoadedVerbatims.last()
					&& this.canLoadMorePages(direction);

				let performLoadingPromise = $q.when();
				if (performLoading) {
					performLoadingPromise = PromiseUtils.old(this.loadNext(direction, 'verbatimId', this.selectedVerbatim));
				}
				performLoadingPromise.then(() => {
					return performPageChange ? this.pageChanged(this.getNextVerbatimPage()) : $q.when();
				}).then(() => {
					this.switchVerbatim(direction);
				});
			},
			getSelectedIndex: () => {
				if (_.isEmpty(this.sentenceList)) {
					return -1;
				}
				return this.pageVerbatims.indexOf(this.selectedVerbatim);
			},
			isPreviousEnabled: () => {
				if (_.isEmpty(this.sentenceList))
					return false;
				if (!this.isSortByTime()) {
					return this.switchSentenceHelper.isPreviousEnabled();
				}
				let index = this.switchVerbatimHelper.getSelectedIndex();
				return (index > 0) || (this.pages.current > 1);
			},
			isNextEnabled: () => {
				if (_.isEmpty(this.sentenceList))
					return false;
				if (!this.isSortByTime()) {
					return this.switchSentenceHelper.isNextEnabled();
				}
				let index = this.switchVerbatimHelper.getSelectedIndex();
				return (!_.isEmpty(this.pageVerbatims) && index < (this.pageVerbatims.length - 1))
					|| (this.pages.current < this.pages.total);
			}
		};

		switchDocumentHelper: any = {
			previous: () => {
				let direction = NavigationDirection.PREVIOUS;
				if (!this.isSortByTime()) {
					return this.switchSentenceHelper.previous();
				}
				let performPageChange = this.selectedDocument === this.pageDocuments[0];
				let performLoading = performPageChange
					&& this.selectedDocument === this.allLoadedDocuments[0]
					&& this.canLoadMorePages(direction);
				let performLoadingPromise = $q.when();
				if (performLoading) {
					performLoadingPromise = PromiseUtils.old(this.loadNext(direction, 'documentId', this.selectedDocument));
				}

				performLoadingPromise.then(() => {
					return performPageChange ? this.pageChanged(this.getPreviousDocPage()) : $q.when();
				}).then(() => {
					this.switchDocument(direction);
				});
			},
			next: () => {
				let direction = NavigationDirection.NEXT;
				if (!this.isSortByTime()) {
					return this.switchSentenceHelper.next();
				}
				let performPageChange = this.selectedDocument === this.pageDocuments.last();
				let performLoading = performPageChange
					&& this.selectedDocument === this.allLoadedDocuments.last()
					&& this.canLoadMorePages(direction);

				let performLoadingPromise = $q.when();
				if (performLoading) {
					performLoadingPromise = PromiseUtils.old(this.loadNext(direction, 'documentId', this.selectedDocument));
				}
				performLoadingPromise.then(() => {
					return performPageChange ? this.pageChanged(this.getNextDocPage()) : $q.when();
				}).then(() => {
					this.switchDocument(direction);
				});
			},
			getSelectedIndex: () => {
				if (_.isEmpty(this.sentenceList)) {
					return -1;
				}
				return this.pageDocuments.indexOf(this.selectedDocument);
			},
			isPreviousEnabled: () => {
				if (_.isEmpty(this.sentenceList))
					return false;
				if (!this.isSortByTime()) {
					return this.switchSentenceHelper.isPreviousEnabled();
				}
				let index = this.switchDocumentHelper.getSelectedIndex();
				return (index > 0) || (this.pages.current > 1);
			},
			isNextEnabled: () => {
				if (_.isEmpty(this.sentenceList))
					return false;
				if (!this.isSortByTime()) {
					return this.switchSentenceHelper.isNextEnabled();
				}
				let index = this.switchDocumentHelper.getSelectedIndex();
				return (!_.isEmpty(this.pageDocuments) && index < (this.pageDocuments.length - 1))
					|| ((this.pages.current < this.pages.total) && this.hasNextDocument());
			}
		};

		switchSentenceHelper: any = {
			previous: () => {
				let direction = -1;
				let performPageChange = this.selectedSentence === this.pageSentences[0];
				let performPageChangePromise = performPageChange ? this.pageChanged(this.pages.current + direction) : $q.when();
				performPageChangePromise.then(() => {
					this.switchSentence(direction);
				});
			},
			next: () => {
				let direction = 1;
				let performPageChange = this.selectedSentence === this.pageSentences.last();
				let performPageChangePromise = performPageChange ? this.pageChanged(this.pages.current + direction) : $q.when();
				performPageChangePromise.then(() => {
					this.switchSentence(direction);
				});
			},
			getSelectedIndex: () => {
				if (_.isEmpty(this.sentenceList)) {
					return -1;
				}
				return this.pageSentences.indexOf(this.selectedSentence);
			},
			isPreviousEnabled: () => {
				if (_.isEmpty(this.sentenceList))
					return false;
				let index = this.switchSentenceHelper.getSelectedIndex();
				return (index > 0) || (this.pages.current > 1);
			},
			isNextEnabled: () => {
				if (_.isEmpty(this.sentenceList))
					return false;
				let index = this.switchSentenceHelper.getSelectedIndex();
				return (!_.isEmpty(this.sentenceList) && index < (this.sentenceList.length - 1))
					|| (this.pages.current < this.pages.total);
			}
		};

		// probably can refactor some of this out in a future interation......
		// Good luck, Jeremy
		constructor(
			widget: Widget, filters, previewFn, accountLevelHiddenObjects, selectedAttributes,
			selectedWorldAwareness, highlighter, events,
			sharingFeedbackSelection: IAnalyticFeedbackSelection, private filteringFeedbackSelection: IAnalyticFeedbackSelection,
			preferences, isDocExplorer: boolean
		) {
			this.widget = widget;
			this.filters = filters;
			this.loadData = previewFn;
			this.accountLevelHiddenObjects = accountLevelHiddenObjects;
			this.selectedAttributes = selectedAttributes;
			this.selectedWorldAwareness = selectedWorldAwareness;
			this.highlighter = highlighter;
			this.events = events;
			this.activeFeedbackSelection = sharingFeedbackSelection;
			this.preferences = preferences;
			this.isDocExplorer = isDocExplorer;
			this.widgetDataService = widgetDataServiceFactory.create(this.widget);

			this.isMobile = $window.matchMedia('(max-width: 767px)').matches;

			this.widgetsCache = currentWidgets.getWidgetsCache(widget.containerId);

			let widgetCurrentPage = currentWidgets.getPagination(widget.containerId, widget.id);
			if (!_.isUndefined(widgetCurrentPage) && !this.isDocExplorer)
				widget.properties.initialPageNumber = widgetCurrentPage;
			if ($rootScope.pdf) {
				let sentenceForExport = currentWidgets.getSelectedSentence(widget.containerId, widget.id);
				if (!_.isUndefined(sentenceForExport)) {
					this.sentenceForExport = sentenceForExport;
				}
			}

			if (betaFeaturesService.isFeatureEnabled(BetaFeature.AUTOMATED_NARRATIVES)) {
				const project = reportAssetUtilsService.getWidgetProject(this.widget);
				narrativeSettingsApi.getNarrativeSettings(project).then(response => {
					this.narrativeEntries = _.filter(response, entry => !!entry.enabled);
				});
			}
		}

		loadEngagorCases(documentId: number): void {
			if (security.getCurrentMasterAccount().engagorEnabled) {
				const project = reportAssetUtilsService.getWidgetProject(this.widget);
				engagorApiService.getCases(project, documentId).then((cases: any) => {
					this.engagorCases = cases;
					this.caseExistStatus = !_.isEmpty(cases);
					this.caseExistStatusLoading = false;
				}, (reason: any) => {
						//error callback, if case isn't found
					this.engagorCases = null;
					this.caseExistStatus = false;
					this.caseExistStatusLoading = false;
				});
			} else {
				this.caseExistStatusLoading = false;
			}
		}

		allowCaseCreation = (): boolean => {
			if (!engagorService.isIntegrationEnabled() && !security.isTicketingEnabled())  return false;
			if (!security.has('manual_case_creation')) return false;
			return this.isDocExplorer
				|| (this.widget.visualProperties.visualization === WidgetVisualization.DOCUMENT_PREVIEW
					&& this.widget.visualProperties.caseCreationEnabled);
		};

		allowTuningSuggestions = (): boolean => {
			if (!engagorService.isIntegrationEnabled())  return false;
			if (!security.has('interaction_audit')) return false;
			return this.isDocExplorer || this.widget.visualProperties.tuningSuggestionsEnabled;
		};


		private getPageItems = (field): number[] => {
			return _.chain(this.sentenceList)
				.uniq((listItem) => listItem[field])
				.map((listItem) => listItem[field])
				.value();
		};

		private getAllLoadedItems = (field): number[] => {
			return _.chain(this.globalDataObject.data)
				.uniq((listItem) => listItem[field])
				.map((listItem) => listItem[field])
				.value();
		};

		private resetPageContent = (): void => {
			if (this.isMobile) {
				this.openedDocuments = {};
				this.documents = {};
			}
		};

		private getNextDocPage = (): number => {
			if (this.selectedDocument === this.allLoadedDocuments.last()) {
				return Math.ceil(this.storedEnd / this.pages.itemsPerPage); //last page after load
			}
			let currentIndex = this.allLoadedDocuments.indexOf(this.selectedDocument);
			let nextId = currentIndex === -1 ? this.allLoadedDocuments[0] : this.allLoadedDocuments[currentIndex + 1];
			let index = _.findIndex(this.globalDataObject.data, { documentId: nextId });
			return Math.floor((index + this.storedStart) / this.pages.itemsPerPage) + 1;
		};

		private getPreviousDocPage = (): number => {
			if (this.selectedDocument === this.allLoadedDocuments[0]) {
				return Math.floor(this.storedStart / this.pages.itemsPerPage) + 1; //first page after load
			}
			let currentIndex = this.allLoadedDocuments.indexOf(this.selectedDocument);
			let prevId = currentIndex === -1 ? this.allLoadedDocuments.last() : this.allLoadedDocuments[currentIndex - 1];
			let	index = _.findLastIndex(this.globalDataObject.data, { documentId: prevId });
			return Math.floor((index + this.storedStart) / this.pages.itemsPerPage) + 1;
		};

		private getNextVerbatimPage = (): number => {
			if (this.selectedVerbatim === this.allLoadedVerbatims.last()) {
				return Math.ceil(this.storedEnd / this.pages.itemsPerPage); //last page after load
			}
			let currentIndex = this.allLoadedVerbatims.indexOf(this.selectedVerbatim);
			let nextId = currentIndex === -1 ? this.allLoadedVerbatims[0] : this.allLoadedVerbatims[currentIndex + 1];
			let index = _.findIndex(this.globalDataObject.data, { verbatimId: nextId });
			return Math.floor((index + this.storedStart) / this.pages.itemsPerPage) + 1;
		};

		private getPreviousVerbatimPage = (): number => {
			if (this.selectedVerbatim === this.allLoadedVerbatims[0]) {
				return Math.floor(this.storedStart / this.pages.itemsPerPage) + 1; //first page after load
			}
			let currentIndex = this.allLoadedVerbatims.indexOf(this.selectedVerbatim);
			let prevId = currentIndex === -1 ? this.allLoadedVerbatims.last() : this.allLoadedVerbatims[currentIndex - 1];
			let	index = _.findLastIndex(this.globalDataObject.data, { verbatimId: prevId });
			return Math.floor((index + this.storedStart) / this.pages.itemsPerPage) + 1;
		};

		private isSortByTime = (): boolean => {
			return !this.filters.sortBy || this.filters.sortBy === PreviewSortAttributes.DOC_DATE;
		};

		private previewSentenceAndHighlight(sentence: PreviewSentence): ng.IPromise<any> {
			return this.getDocumentPreview(sentence).then(() => {
				// wait for changes to propagate, then scroll to the selected item
				$timeout(this.highlighter.highlightSelected, 1);
			});
		}

		private switchSentence = (direction: number): ng.IPromise<any> => {
			let selectedIndex = this.switchSentenceHelper.getSelectedIndex();
			let newIndex = selectedIndex + direction;
			let sentence;

			if (selectedIndex === -1) {
				if (direction > 0) {
					sentence = this.sentenceList[0];
				} else {
					sentence = this.sentenceList[this.sentenceList.length - 1];
				}
			} else if (newIndex > -1 && newIndex < this.pageSentences.length) {
				sentence = this.sentenceList[newIndex];
			}
			return this.previewSentenceAndHighlight(sentence);
		};

		private switchDocument = (direction: number): ng.IPromise<any> => {
			let selectedIndex = this.switchDocumentHelper.getSelectedIndex();
			let newIndex = selectedIndex + direction;
			let sentence;

			if (selectedIndex === -1) {
				if (direction > 0) {
					newIndex = 0;
				} else {
					newIndex = this.pageDocuments.length - 1;
				}
			}

			if (newIndex > -1 && newIndex < this.pageDocuments.length) {
				sentence = _.find(this.sentenceList, (listItem: PreviewSentence) => {
					return listItem.documentId === this.pageDocuments[newIndex];
				});
			} else if (this.canLoadMorePages(direction)) {
				if (direction > 0) {
					this.switchDocumentHelper.next();
				} else {
					this.switchDocumentHelper.previous();
				}
				return $q.when().then(this.endDataLoadProcess);
			} else {
				sentence = this.getLastSentence(direction);
			}

			return this.previewSentenceAndHighlight(sentence);

		};

		private switchVerbatim = (direction: number): ng.IPromise<any> => {
			let selectedIndex = this.switchVerbatimHelper.getSelectedIndex();
			let newIndex = selectedIndex + direction;
			let sentence;

			if (selectedIndex === -1) {
				if (direction > 0) {
					newIndex = 0;
				} else {
					newIndex = this.pageVerbatims.length - 1;
				}
			}

			if (newIndex > -1 && newIndex < this.pageVerbatims.length) {
				sentence = _.find(this.sentenceList, (listItem: PreviewSentence) => {
					return listItem.verbatimId === this.pageVerbatims[newIndex];
				});
			}  else if (this.canLoadMorePages(direction)) {
				if (direction > 0) {
					this.switchVerbatimHelper.next();
				} else {
					this.switchVerbatimHelper.previous();
				}
				return $q.when().then(this.endDataLoadProcess);
			} else {
				sentence = this.getLastSentence(direction);
			}
			this.previewSentenceAndHighlight(sentence);
		};

		private canLoadMorePages = (direction): boolean => {
			if (direction > 0) {
				return Math.ceil(this.pages.current / this.pagesPerCall) < Math.ceil(this.pages.total / this.pagesPerCall);
			} else {
				return this.pages.current > this.pagesPerCall;
			}
		};

		private hasNextDocument = (): boolean => {
			let lastLoadedDocumentSelected = _.last(this.allLoadedDocuments) === this.selectedDocument;
			return !lastLoadedDocumentSelected || this.canLoadMorePages(NavigationDirection.NEXT);
		};

		private getLastSentence = (direction): PreviewSentence => {
			return direction > 0 ? this.sentenceList.last() : this.sentenceList[0];
		};

		selectSentence = (sentence, scrollInView?: boolean): void => {
			if (!sentence) {
				this.resetSelected();
			} else {
				this.selectedSentence = sentence.id;
				this.selectedDocument = sentence.documentId;
				this.selectedVerbatim = sentence.verbatimId;

				this.highlighter.update(this.documents[sentence.documentId]);
				if (scrollInView)
					$timeout(this.highlighter.highlightSelected, 1);
			}
			currentWidgets.setSelectedSentence(this.widget.containerId, this.widget.id, sentence && sentence.id);
		};

		startDataLoadProcess = () => {
			this.changingDoc = true;
		};

		endDataLoadProcess = () => {
			// timeout forces digest cycle, which lets popcorn reload
			$timeout(() => {
				this.changingDoc = false;
			});
		};

		getDocumentPreview = (sentence): ng.IPromise<any> => {
			let sentenceId = sentence.id;
			let documentId = sentence.documentId;

			if (this.isMobile) {
				this.startDataLoadProcess();
				this.openedDocuments[documentId] = 'doc';

				return this.loadData(sentence).then(() => {
					this.selectSentence(sentence);
				});

			} else {
				if (this.selectedSentence === sentenceId || this.state.documentLoadInProgress) {
					return $q.when().then(this.endDataLoadProcess);
				}
				delete this.openedDocuments[documentId];

				return this.loadData(sentence, this.startDataLoadProcess).then(() => {
					this.selectSentence(sentence);
					this.endDataLoadProcess();
				});
			}
		};

		getPages = (): ExplorerPagination => {
			return this.pages;
		};

		private isOverReportSizeLimit = (pg: number): boolean => {
			return (pg - 1) * this.pages.itemsPerPage >= 10000;
		};

		/*
		* bootstrap pagination cannot disable last page or next page only,
		* so need to set page number back if click disabled last/next page button
		*/
		private resetPageIfOverLimit = (): void => {{
			this.pages.current = currentWidgets.getPagination(this.widget.containerId, this.widget.id) || 1;
		}};

		pageChanged = (pg: number, scrollToTop?: boolean): ng.IPromise<any> => {
			if (this.globalDataObject === null || this.isOverReportSizeLimit(pg)) {
				this.resetPageIfOverLimit();
				return $q.when();
			}

			this.setCurrentPage(pg);
			if ((this.endBound + this.pages.itemsPerPage) >= 10000) {
				$('li.pagination-next').addClass('disabled');
				$('li.pagination-next>a').attr('disabled', 'disabled');
			}

			if (this.endBound > this.storedEnd || this.startBound < this.storedStart) {
				return this.runReportPaginated(this.getWidgetWithFilter(this.widget))
					.then(() => {
						if (scrollToTop) angular.element('.dex-list-group').scrollTop(0);
						if (this.switchSentenceHelper.getSelectedIndex() === -1)
							this.autoSelect(this.widget);
					});
			} else {
				let pageData: any = {data: null, countInfo: {}};
				pageData.countInfo.total = this.globalDataObject.total;
				pageData.countInfo.count = this.globalDataObject.total.entitiesVolume || this.globalDataObject.total.volume;
				pageData.data = this.globalDataObject.data.slice(
					this.startBound - this.storedStart, this.endBound - this.storedStart);
				this.resetPageContent();
				this.setPageContext(pageData);
				return $q.when().then(() => {
					if (scrollToTop) angular.element('.dex-list-group').scrollTop(0);
				});
			}
		};

		// if we're loading for the first time, and not starting at page 1
		private isInitialLoadOnSpecificPage = (widgetSettings: Widget): boolean => {
			return !!(!this.globalDataObject && widgetSettings.properties.initialPageNumber);
		};

		private searchSentence(sentenceSearch: SentenceSearch): (sentence: PreviewSentence) => boolean {
			if (sentenceSearch.searchMode === SearchMode.VERBATIM) {
				return sentence => sentence.verbatimId === sentenceSearch.searchId;
			} else {
				return sentence => sentence.id === sentenceSearch.searchId;
			}
		}

		private loadNext(direction: number, fieldName: 'verbatimId' | 'documentId', currentId: number): Promise<void> {
			// persisting responses while search to avoid calling same offset twice
			let responses: {[index: number]: ng.IPromise<any>} = {};
			let limit = this.pagesPerCall * this.pages.itemsPerPage;
			let widgetSettings = this.getWidgetWithFilter(this.widget);
			return InfiniteListSearcher.findNextPage(chunkIndex => {
				let start = chunkIndex * limit;
				responses[chunkIndex] = this.getSentencesChunk(widgetSettings, start, limit);
				return Promise.resolve(responses[chunkIndex]).then(dataObject => dataObject?.data);
			}, fieldName, currentId, Math.floor((this.pages.current - 1) / this.pagesPerCall), direction)
				.then(chunkIndex => {
					this.pages.current = chunkIndex * this.pagesPerCall;
					let start = chunkIndex * limit;
					return Promise.resolve(responses[chunkIndex])
						.then(dataObject => this.processSentenceResponse(dataObject, widgetSettings, start));
				});
		}

		private getSentencesChunk(widgetSettings: Widget, start: number, lookAheadLimit: number): ng.IPromise<ReportDataObject> {
			widgetSettings.properties.page = widgetSettings.properties.page || {};
			widgetSettings.properties.page.start = start;
			widgetSettings.properties.page.lookAheadLimit = lookAheadLimit;
			let data = this.getData(widgetSettings);
			this.state.requestInProgress = true;
			this.state.loadingSentencePanel = data;
			return data.then(dataObject => {
				delete this.state.requestInProgress;
				this.state.loadingSentencePanel = false; // set to false so we know that a call has completed
				return dataObject;
			});
		}

		runReportPaginated = (widgetSettings: Widget, fullReload?: boolean): ng.IPromise<any> => {
			this.pages.itemsPerPage = widgetSettings.properties.itemsPerPage
				|| widgetSettings.properties.page.lookAheadLimit
				|| widgetSettings.properties.itemsPerPage;

			if (fullReload) {
				this.resetSelected();
				this.resetPagination();
			} else {
				if (this.isInitialLoadOnSpecificPage(widgetSettings)) {
					this.setCurrentPage(widgetSettings.properties.initialPageNumber);
				}
			}

			this.resetPageContent();

			let limit = this.pagesPerCall * this.pages.itemsPerPage;
			let start = 0;

			if (this.globalDataObject !== null || this.isInitialLoadOnSpecificPage(widgetSettings)) {
				start = Math.floor((this.pages.current - 1) / this.pagesPerCall) * limit;
			} else {
				this.updateBounds(1);
			}

			this.widgetsCache.addLoading(widgetSettings.id, true);
			responsiveReportService.setLoadingFreshData(this.widget);
			return this.getSentencesChunk(widgetSettings, start, limit).then((dataObject) => {
				return this.processSentenceResponse(dataObject, widgetSettings, start);
			}, reason => this.processError(reason));
		};

		private processSentenceResponse(dataObject: IDataObject<PreviewSentence[]>,
			widgetSettings: Widget, start: number): void {
			this.globalDataObject = dataObject;
			responsiveReportService.setDisplayingFreshData(this.widget);
			profanityDisguiseService.maskProfanityInSentences(this.globalDataObject.data);

			let sentenceSearch = this.getSentenceSearch(widgetSettings);
			if (sentenceSearch) {
				start = dataObject.metadata.page ? dataObject.metadata.page.start : 0;
				let offset = _.findIndex(dataObject.data,
					this.searchSentence(sentenceSearch));
				if (offset !== -1) {
					this.widget.properties.initialSentence = dataObject.data[offset].id;
				} else {
					offset = 0;
				}
				delete this.widget.properties.sentenceSearch;

				this.setCurrentPage(Math.ceil((start + offset + 1) / this.pages.itemsPerPage));
			}
			this.widgetsCache.addData(widgetSettings.id, dataObject);
			this.loadTweets(dataObject);
			docExplorerQids.sentenceQid = dataObject.metadata && dataObject.metadata.qid;
			let pageData: {data: any; metadata: any; countInfo: {total?: number; count?: number}} = {
				data: null, metadata: null, countInfo: {}
			};

			if (!_.isUndefined(dataObject.data) && !_.isUndefined(dataObject.total) && dataObject.total.volume > 0) {
				this.storedStart = start;
				this.storedEnd =  start + dataObject.data.length;

				previewChunkService.processSentenceChunks(dataObject.data);

				pageData.countInfo.total = dataObject.total;
				pageData.countInfo.count = dataObject.total.entitiesVolume || dataObject.total.volume;
				pageData.data = this.globalDataObject.data.slice(
					this.startBound - this.storedStart, this.endBound - this.storedStart);
				pageData.metadata = this.globalDataObject.metadata;
				this.totalItems = pageData.countInfo.count;
				this.pages.total = this.getTotalPages();

				if (docExplorerHelperService.isRefreshPaginationOptions(this))  {
					docExplorerHelperService.initPaginationOptions();
				}
			} else {
				this.resetSelected();
			}

			this.setPageContext(pageData, true);
			delete this.state.message;
			delete this.state.previewDocumentMessage;
		}

		private processError(reason): ng.IPromise<any> {
			// need to clear existing sentences
			let emptyData = {data: [], countInfo: {count: 0}, total: {volume: 0}};
			this.globalDataObject = emptyData;
			this.selectedSentence = -1;
			this.resetPagination();
			this.pages.total = 0;
			this.setPageContext(emptyData, false);
			this.state.message = reason;
			this.state.previewDocumentMessage = reason;
			return $q.reject(reason);
		}

		autoSelect = (widgetSettings: Widget): ng.IPromise<any> => {
			let resultPromise = $q.when();
			if (!this.isMobile && this.globalDataObject.data.length) {
				let autoselectItem, targetedItem;

				if (widgetSettings.properties.initialSentence && this.selectedSentence < 0) {
					targetedItem = _.findWhere(this.globalDataObject.data, {id: widgetSettings.properties.initialSentence });
				}
				if (!targetedItem && this.sentenceForExport) {
					targetedItem = _.findWhere(this.globalDataObject.data, {id: this.sentenceForExport});
				}

				autoselectItem = targetedItem ? targetedItem : this.globalDataObject.data[0];

				resultPromise = this.loadData(autoselectItem).then(() => {
					this.selectSentence(autoselectItem, !!targetedItem);
				});
			}

			return resultPromise;
		};

		private getSentenceSearch(widgetSettings: Widget): SentenceSearch {
			if (!_.isUndefined(this.sentenceForExport)) {
				return {
					searchMode: SearchMode.SENTENCE,
					searchId: this.sentenceForExport
				};
			} else return widgetSettings.properties.sentenceSearch;
		}

		setPageContext = (pageData, updateLoaded?: boolean) => {
			this.data = pageData;
			this.sentenceList = this.data.data;
			this.tabularData = {
				data: this.sentenceList,
				metadata: this.data.metadata
			};
			this.pageDocuments = this.getPageItems(this.DOCUMENT_ID);
			this.pageVerbatims = this.getPageItems(this.VERBATIM_ID);
			this.pageSentences = this.getPageItems(this.SENTENCE_ID);
			if (updateLoaded) {
				this.allLoadedDocuments = this.getAllLoadedItems(this.DOCUMENT_ID);
				this.allLoadedVerbatims = this.getAllLoadedItems(this.VERBATIM_ID);
			}
		};

		resetSelected = (): void => {
			this.filters = this.filters || {};
			this.filters.additionalFilter = this.filters.additionalFilter || {};
			this.selectedSentence = -1;
			this.selectedDocument = -1;
			this.selectedVerbatim = -1;
			this.selectedAttributes = [];
			this.selectedWorldAwareness = [];
		};

		getData = (widgetSettings: Widget): ng.IPromise<any> => {
			let runWidgetSettings = angular.copy(widgetSettings);
			runWidgetSettings.properties.documentExplorer = true;
			runWidgetSettings.properties.timezoneOffset = new Date().getTimezoneOffset();
			runWidgetSettings.properties.timezoneName = moment.tz.guess();

			return this.postProcessProperties(runWidgetSettings).then((processedSettings) => {
				return this.widgetDataService.getAnSentenceData(processedSettings);
			});
		};

		postProcessProperties = (widgetSettings: Widget) => {
			let filtersProvider = currentWidgets.getDashboardHistoryIfAvailable(widgetSettings.containerId);
			return customFilterService.postprocessWidgetProperties(widgetSettings, filtersProvider,
				this.filteringFeedbackSelection?.isEnabled());
		};

		exportData = () => {
			let runWidgetSettings = this.getWidgetWithFilter(this.widget);
			runWidgetSettings.properties.documentExplorer = true;
			runWidgetSettings.properties.timezoneOffset = new Date().getTimezoneOffset();
			runWidgetSettings.properties.timezoneName = moment.tz.guess();

			runWidgetSettings.properties.page = _.extend(runWidgetSettings.properties.page,
				{start: 0, lookAheadLimit: ROW_LIMIT});
			runWidgetSettings.properties.reportCacheOption = AnalyticCacheOptions.Off;

			runWidgetSettings.properties.previewMode = PreviewMode.DOCUMENT;
			this.populateExportVisualProperties(runWidgetSettings);
			runWidgetSettings.properties.exportAttributes = this.preferences.getSavedFavorites(runWidgetSettings.properties.contentProviderId,
				runWidgetSettings.properties.accountId, runWidgetSettings.properties.project);

			this.postProcessProperties(runWidgetSettings).then((processedSettings) => {
				let data = previewExportService.getPreviewExport(processedSettings, true, this.preferences.settings.leafOnly);
				this.state.loadingDocPanel = data;

				data.then((response) => {
					let filtersProvider = currentWidgets.getDashboardHistoryIfAvailable(processedSettings.containerId);
					reportProcessingService.getWidgetFiltersMetadata(this.widget, filtersProvider).then(widgetMetadata => {
						// using unprocessed widget to skip additional doc explorer drill filters, which are added manually below
						reportProcessingService.setDocExplorerFilters(widgetMetadata, this.filters.additionalFilter);
						exportUtils.getCBExportOptions(processedSettings, response).then((allOptions: any) => {
							allOptions.hiddenModelsAndAttributes = this.accountLevelHiddenObjects;
							let csvPromise = exportUtils.getWidgetDataForExport(processedSettings,
								response, allOptions, undefined, widgetMetadata);
							this.state.loadingDocPanel = csvPromise;
							csvPromise.then((csvData) => {
								let exportData: any = {};
								exportData.data = csvData;
								exportData.name = processedSettings.documentExplorerName;
								exportData.filename = exportUtils.explorerExportFilename(processedSettings, 'xlsx');
								exportData.widgetId = processedSettings.id;
								exportData.dashboardId = processedSettings.dashboardId;
								exportData.documentExplorer = true;
								this.state.loadingDocPanel = exportApiService.convertToExcel(exportData)
									.then((resp) => {
										exportUtils.exportXLSX(exportData.filename, resp.data);

										const document = this.documents[this.selectedDocument];
										const {source, documentId, documentType } = AmplitudeEventUtils.getBaseDocumentViewEvent(document, this.isDocExplorer);

										AmplitudeAnalyticsService.trackEvent(
											AmplitudeEvent.DOCEXPLORER_EXPORT,
											{ documentId: this.documents[this.selectedDocument].id },
											{ source, documentType, type: 'Data'}
										);
									});
							});
						});
					});
				});
			});
		};

		private populateExportVisualProperties(widgetSettings: PreviewWidget): void {
			widgetSettings.visualProperties.sentencePaneEnabled = this.preferences ?
				this.preferences.settings.visiblePanes.sentence : true;
			widgetSettings.visualProperties.contextPaneEnabled = this.preferences ?
				this.preferences.settings.visiblePanes.context : true;
			widgetSettings.visualProperties.sentimentHighlightingEnabled = this.preferences ?
				this.preferences.settings.showSentiment : true;
		}

		loadTweets = (dataObject: {data: PreviewSentence[]}) => {
			if (!dataObject.data) {
				return;
			}
			let sentences = dataObject.data;
			let tweetIds = [];
			let mapping = {};
			sentences.filter(sentence => sentence.smService && sentence.smService.toLowerCase() === 'twitter')
				.filter(sentence => documentCacheService.isDocumentRequired(sentence.documentId))
				.forEach(sentence => {
					const naturalId = sentence.attributes['natural_id'];
					tweetIds.push(naturalId);
					mapping['' + naturalId] = sentence.documentId;
				});

			if (!tweetIds.isEmpty()) {
				reportDataApiService.loadTweetsFromTwitter(tweetIds)
					.then((tweets) => {
						documentCacheService.updateTwitterDocuments(tweets.data, mapping);
					}, (tweets) => {
						$log.error('Cannot load tweets', tweets.data);
					});
			}
		};

		getWidgetWithFilter = (runWidget: Widget): PreviewWidget => {
			let copiedWidget = angular.copy(this.widget);

			if (this.filters.additionalFilter) {
				copiedWidget.properties.drillFilters = angular.copy(runWidget.properties.drillFilters) || [];

				let sentimentFilter: DrillFilter, easeFilter: DrillFilter, emotionFilter: DrillFilter;
				let sentimentValue = this.filters.additionalFilter.sentiment;
				let easeFilterValue = this.filters.additionalFilter.easeScore;
				let emotionFilterValue = this.filters.additionalFilter.emotion;
				if (sentimentValue) {
					sentimentFilter = SentenceFiltersHelper.getPredefinedMetricFilter(PredefinedMetricConstants.SENTIMENT, sentimentValue);
					copiedWidget.properties.drillFilters.push(sentimentFilter);
				}

				if (easeFilterValue) {
					easeFilter = SentenceFiltersHelper.getPredefinedMetricFilter(PredefinedMetricConstants.EASE_SCORE, easeFilterValue);
					copiedWidget.properties.drillFilters.push(easeFilter);
				}

				if (emotionFilterValue) {
					emotionFilter = SentenceFiltersHelper.getPredefinedMetricFilter(PredefinedMetricConstants.EMOTION, emotionFilterValue);
					copiedWidget.properties.drillFilters.push(emotionFilter);
				}

			}

			if (this.filters.additionalFilter.words) {
				// use adhoc filter to support doc_count call
				copiedWidget.properties.adhocFilter = copiedWidget.properties.adhocFilter
					|| { type: FilterTypes.AND, filterRules: [] };

				copiedWidget.properties.adhocFilter.filterRules.push({
					type: FilterRuleType.esQueryRule,
					displayName: locale.getString('preview.esQuery'),
					esQueryObject: {
						keyword: esQueryService.processQuery(this.filters.additionalFilter.words)
					}
				});
			}

			// don't deduplicate docID when drilling from sentences
			let parentPreview = this.widget.parentWidget?.properties?.widgetType === WidgetType.PREVIEW;
			let parentTableOrBubble = _.contains([WidgetVisualization.PREVIEW_TABLE, WidgetVisualization.PREVIEW_BUBBLES],
				this.widget.parentWidget?.visualProperties?.visualization);
			let showAllSentences = parentPreview && parentTableOrBubble;
			if (!showAllSentences || !this.isDocExplorer) {
				copiedWidget.properties.deduplicateDocsOk = true;
			}

			return copiedWidget;
		};

		getTotalPages = (): number => {
			let exactTotal = this.totalItems / this.pages.itemsPerPage;
			return (Math.floor(exactTotal) === exactTotal) ? exactTotal : Math.ceil(exactTotal);
		};

		getSelectedVerbatim = () => {
			if (this.documents[this.selectedDocument]) {
				return _.chain(this.documents[this.selectedDocument].verbatims).filter((verbatim) => {
					return _.findWhere(verbatim.sentences, {id: this.selectedSentence});
				}).value()[0];
			}
			return {} as PreviewVerbatim;
		};


		resetPagination = (): void => {
			this.setCurrentPage(1);
			this.pages.itemsPerPage = this.pages.itemsPerPage || 20;
			this.globalDataObject = null;
		};

		private setCurrentPage = (pg: number): void => {
			this.pages.current = pg;
			currentWidgets.setPagination(this.widget.containerId, this.widget.id, pg);
			this.updateBounds();
		};

		private updateBounds = (page?: number) => {
			page = page || this.pages.current;
			this.startBound = (this.pages.current - 1) * this.pages.itemsPerPage;
			this.endBound = this.startBound + this.pages.itemsPerPage;
		};

		switchFeedbackSelection = (feedbackSelection: IAnalyticFeedbackSelection): void => {
			this.activeFeedbackSelection = feedbackSelection;
		};

		isFeedbackSelectionEnabled = (): boolean => {
			return !!(this.activeFeedbackSelection && this.activeFeedbackSelection.isEnabled());
		};

		isFilteredByConfidentiality = (): boolean => {
			return this.data?.metadata?.filteredByConfidentiality;
		};

		curateItem = (item: PreviewDocument | PreviewVerbatim): void => {
			this.activeFeedbackSelection.curateItem(item);
		};

		isItemCurated = (item: PreviewDocument | PreviewVerbatim): boolean => {
			return this.activeFeedbackSelection.isItemCurated(item);
		};

		isSentenceItemCurated = (sentence: PreviewSentence): boolean => {
			return this.activeFeedbackSelection.isSentenceItemCurated(sentence);
		};

		getRelevantCuratedItemsCount = (): number => {
			return this.activeFeedbackSelection.getRelevantCuratedItemsCount();
		};

		getRelevantCuratedItemsCountLabel = (): string => {
			return this.activeFeedbackSelection.getLabel();
		};

		getAllCuratedItemsCount = (): number => {
			return this.activeFeedbackSelection.getAllCuratedItemsCount();
		};

		getAllCuratedDocuments = (): PreviewDocument[] => {
			return _.chain(this.activeFeedbackSelection.getRelevantCuratedItems())
				.map((item) => item.documentId)
				.uniq()
				.map((id) => this.documents[id])
				.value() || [];
		};

		getAllCuratedDocumentsCount = (): number => {
			//only used in document view mode;
			return this.getRelevantCuratedItemsCount();
		};

		hasAnyCuratedItems = (): boolean => {
			return this.activeFeedbackSelection.hasAnyCuratedItems();
		};

		isShowingCuratingControls = (): boolean => {
			return !!(this.activeFeedbackSelection && this.activeFeedbackSelection.isShowingCuratingControls());
		};

		isFeedbackSharingAvailable = (): boolean => {
			return this.activeFeedbackSelection.isFeedbackSharingAvailable();
		};

		isDemo = (): boolean => false;

		setAvailableAttributes = (availableAttributes: any[]) => {
			this.availableAttributes = availableAttributes;
			this.joinSelectedAttributes();
		};

		setAvailableModels = (availableModels: any[]) => {
			this.availableModels = availableModels;
		};

		joinSelectedAttributes = (): void => {
			let attributesToJoinToSelected = this.isDocExplorer
				? this.availableAttributes
				: this.widget.properties.selectedAttributes;
			attributesToJoinToSelected = attributesToJoinToSelected || [];
			if (!this.isDocExplorer) {
				attributesToJoinToSelected = _.filter(attributesToJoinToSelected, (attribute: any): boolean => {
					return attribute.metricType === 'ATTRIBUTE';
				});
				attributesToJoinToSelected.forEach((attribute) => attribute.isReportable = true);
			}


			let availableAttributesWithoutValue = _.filter(attributesToJoinToSelected, (attribute: any): boolean => {
				let attrName = attribute.name.toLowerCase();
				return (attrName.indexOf('cb_') < 0
					&& !_.some(this.selectedAttributes, (selectedAttribute: any): boolean => {
						return selectedAttribute.name.toLowerCase() === attrName;
					}));
			});

			this.selectedAttributes = _.chain(this.selectedAttributes)
				.union(availableAttributesWithoutValue)
				.filter((attribute: any): boolean => {
					return !_.find(this.accountLevelHiddenObjects, (hiddenAttribute: any): boolean => {
						return attribute.name.toLowerCase() === hiddenAttribute.name.toLowerCase();
					});
				})

				.filter((attribute: any): boolean =>
					!_.contains(this.documentHiddenAttributes, attribute.name.toLowerCase()))
				.value();
		};

		isPageLimitReached = (): boolean => {
			return !this.isDocExplorer && ReportPaginationService.isLimitReached(this.totalItems);
		};

		canCopyLink = (): boolean => {
			const currentLicense = security.getLicenseTypeId();

			const currentUserIsBasic = currentLicense === LicenseType.CX_STUDIO_BASIC;
			const enabledForBasicUsers = security.getCurrentMasterAccount().linkSharingSettings?.enabledForBasicUsers;

			return !currentUserIsBasic || enabledForBasicUsers;

		};

		getNoResultMessage = (): string => {
			if (!_.isEmpty(this.filters?.additionalFilter)) {
				return locale.getString('docExplorer.noResultMsg');
			} else {
				return locale.getString('widget.noData');
			}
		};

		getProjectIdentifier = (): ProjectIdentifier => {
			let props = this.widget.properties;
			return new ProjectIdentifier(props.contentProviderId, props.accountId, props.project,
				props.contentProviderName, props.accountName, props.projectName);
		};

		getWorkspaceProject = (): WorkspaceProject => {
			let props = this.widget.properties;
			return props.workspaceProject;
		};


		getAssetParameters = (): AssetParameters => {
			return AssetParametersFactory.fromWidget(this.widget);
		};

		toggleShowTopics = (): void => {
			let settings = this.preferences.settings;
			if (!settings.showTopics) {
				if (!settings.showSentences) {
					settings.showSentences = true;
				}
				settings.expandTopics = false;
				settings.leafOnly = false;
			}
			this.events.redrawTopics();
			this.persistPreferences();
		};

		toggleExpandTopics = (): void => {
			let settings = this.preferences.settings;
			if (settings.showTopics) {
				this.persistPreferences();
			}
		};

		private persistPreferences = (): void => {
			if (this.preferences.storeUpdates) this.preferences.storeUpdates();
			this.events.changeSettings();
		};
	};
});
