import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';

import * as _ from 'underscore';
import * as moment from 'moment';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { IDateRange } from '@cxstudio/reports/entities/date-period';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { IDateRuleDefinition } from '@cxstudio/reports/entities/adhoc-filter.class';
import { DatePointMode } from '@cxstudio/reports/settings/date-point-constants';
import { DateFilterUtils } from '@cxstudio/report-filters/date-filter-utils.service';
import { GeneratedFolderType } from '@cxstudio/report-filters/generated-folder-type';
import { DateRangeOption } from '@cxstudio/report-filters/filter-builder/date-range-option';
import { DateTimeFormat, DateService } from '@cxstudio/services/date-service.service';


interface IZonedDateRange {
	from: moment.Moment;
	to: moment.Moment;
	fromText: string;
	toText: string;
}

export class DateFilterService {
	private ZONE_REGEX = /(Z|[+-]\d{2}:?\d{2})(\[.*\])?$/; // Z or +01:00 or -0100, optional [Europe/Paris]
	private TZ_NAME_REGEX = /\[.*\]$/;
	DATE_FOLDER; // used outside
	private dateFilterOptions: DateRangeOption[] = [];

	private customRangeLabelCache: {[key: string]: string} = {};

	constructor(
		private $filter: ng.IFilterService,
		private dateService: DateService,
		private DateRange,
		private locale: ILocale,
		private $rootScope: ng.IRootScopeService,
		private dateFilterUtils: DateFilterUtils,
	) {
		this.DATE_FOLDER = {
			name: this.locale.getString('filter.dateFilters'),
			id: FilterTypes.CXDATE,
			description: '',
			type: FolderTypes.FILTER,
			generatedFolderType: GeneratedFolderType.SYSTEM
		};

		Object.keys(DateRange.options).forEach(key => {
			this.dateFilterOptions.push(DateRange.options[key]);
		});
	}

	getDateFilterOptions = (): DateRangeOption[] => {
		return angular.copy(this.dateFilterOptions);
	};

	getDateFilterHistoricOptions = (): any[] => {
		return angular.copy(this.DateRange.historicOptionsArray);
	};

	matchDate = (date, options?): any => {
		if (!options) {
			options = this.dateFilterOptions;
		}
		if (date) {
			let d = _.findWhere(options, {value: date});
			return d ? d : date;
		}
		return date;
	};

	getCustomRangeLabel = (dateRange: IDateRange, timezone: string, withTime = true): string => {
		return this.zonedRangeFormatter(dateRange, timezone, false, withTime);
	};

	getDateFilterLabel = (dateFilter: DateFilter, timezone: string): string => {
		if (dateFilter.dateFilterMode === DateFilterMode.CUSTOM) {
			return this.getCachedCustomRangeLabel(dateFilter.dateFilterRange, timezone, true);
		} else if (dateFilter.dateDisplayName) {
			return dateFilter.dateDisplayName;
		} else {
			let mode = this.matchDate(dateFilter.dateFilterMode);
			return mode.displayName ? mode.displayName : mode;
		}
	};

	getDateFilterRuleLabel = (rule: IDateRuleDefinition, timezone: string): string => {
		if (rule.dateFilterMode === this.DateRange.options.CUSTOM.value) {
			if (rule.fromMode === DatePointMode.CUSTOM) {
				let dateLabel = this.getCachedCustomRangeLabel({from: rule.fromDate, to: rule.toDate}, timezone, false);
				return `${rule.displayName}: ${dateLabel}`;
			} else {
				let estimatedDays = Math.round(this.dateFilterUtils.getDateRulePeriodLengthInYears(rule) * 365);
				// temporarily use estimated amount of days
				return `${rule.displayName}: ~${estimatedDays} ${this.locale.getString('common.days')}`;
			}
		} else {
			return `${rule.displayName}: ${this.DateRange.valueOf(rule.dateFilterMode).displayName}`;
		}
	};

	private getCachedCustomRangeLabel(dateRange: IDateRange, timezone: string, withTime: boolean): string {
		let cacheKey = `${dateRange?.from}-${dateRange?.to}-${timezone}-${withTime}`;
		if (!this.customRangeLabelCache.hasOwnProperty(cacheKey))
			this.customRangeLabelCache[cacheKey] = this.getCustomRangeLabel(dateRange, timezone, withTime);
		return this.customRangeLabelCache[cacheKey];
	}

	formatDateFilter = (dateRange: IDateRange, timezone: string): string => {
		return this.zonedRangeFormatter(dateRange, timezone, true, true);
	};

	private zonedRangeFormatter(dateRange: IDateRange, timezone: string, withTimezone?: boolean, withTime?: boolean): string {
		if (!dateRange || !dateRange.from || !dateRange.to)
			return;
		dateRange = this.processLegacyDates(dateRange);
		let dateObject = {} as IZonedDateRange;
		if (!isEmpty(timezone)) {
			dateObject = this.convertToProjectTimeZone(dateRange, timezone);
		} else if (this.$rootScope.clientTimezoneName) {
			dateObject = this.convertToClientTimeZone(dateRange, this.$rootScope.clientTimezoneName);
		} else { // use local timezone
			if (this.$rootScope.pdf) { // show original date range for export
				dateObject.fromText = this.formatAsLocalDate(dateRange.from);
				dateObject.toText = this.formatAsLocalDate(dateRange.to);
				dateObject.from = moment.parseZone(dateRange.from);
				dateObject.to = moment.parseZone(dateRange.to);
			} else {
				let dateFormat = this.dateService.getUserDateFormat(
					withTime ? DateTimeFormat.BASIC_DATE_TIME : DateTimeFormat.BASIC_DATE);
				dateObject.fromText = this.$filter('date')(dateRange.from, dateFormat);
				dateObject.toText = this.$filter('date')(dateRange.to, dateFormat);
				dateObject.from = moment(dateRange.from);
				dateObject.to = moment(dateRange.to);
			}
		}
		return this.dateRangeToString(dateObject, withTimezone && withTime);
	}

	private utcSuffix(date: moment.Moment, withTimezone?: boolean, ignorePdf?: boolean): string {
		return (withTimezone || (!ignorePdf && this.$rootScope.pdf)) ? this.getUTCSuffix(date) : '';
	}

	private dateRangeToString(dateObject: IZonedDateRange, withTimezone: boolean): string {
		if (dateObject.from.format('Z') === dateObject.to.format('Z')) { // same offset
			return `${dateObject.fromText} - ${dateObject.toText}${this.utcSuffix(dateObject.to, withTimezone)}`;
		} else {
			return `${dateObject.fromText}${this.utcSuffix(dateObject.from, withTimezone)} - `
				+ `${dateObject.toText}${this.utcSuffix(dateObject.to, withTimezone)}`;
		}
	}

	private convertToProjectTimeZone(dateRange: IDateRange, timezone: string): IZonedDateRange {
		let dateObject = {} as IZonedDateRange;
		dateObject.fromText = this.formatAsLocalDate(dateRange.from);
		dateObject.toText = this.formatAsLocalDate(dateRange.to);
		if (_.isNumber(timezone)) { // fixed offset
			let offsetSuffix = moment().utcOffset(-timezone).format('Z');
			dateObject.from = moment.parseZone(this.toLocalDate(dateRange.from) + offsetSuffix);
			dateObject.to = moment.parseZone(this.toLocalDate(dateRange.to) + offsetSuffix);
		} else { // e.g. America/Chicago
			dateObject.from = moment.tz(this.toLocalDate(dateRange.from), timezone);
			dateObject.to = moment.tz(this.toLocalDate(dateRange.to), timezone);
		}
		return dateObject;
	}

	private convertToClientTimeZone(dateRange: IDateRange, timezone: string): IZonedDateRange {
		let dateObject = {} as IZonedDateRange;

		dateObject.from = moment(dateRange.from).tz(timezone);
		dateObject.to = moment(dateRange.to).tz(timezone);

		dateObject.fromText = this.formatAsLocalDate(dateObject.from.format());
		dateObject.toText = this.formatAsLocalDate(dateObject.to.format());

		return dateObject;
	}

	private formatAsLocalDate(isoDate: string, format?: DateTimeFormat): string {
		let localDate = this.toLocalDate(isoDate);
		let dateFormat = this.dateService.getUserDateFormat(format || DateTimeFormat.BASIC_DATE_TIME);
		return this.$filter('date')(localDate, dateFormat);
	}

	private toLocalDate(isoDate: string): string { // only date/time, no TZ
		return isoDate.replace(this.ZONE_REGEX, '');
	}

	private getUTCSuffix(momentDate: moment.Moment): string {
		return ' (' + this.locale.getString('common.UTC') + momentDate.format('Z') + ')';
	}

	private processLegacyDates(dateRange: IDateRange): IDateRange {
		if (this.isLegacyDate(dateRange.from)) {
			let newRange = {} as IDateRange;
			newRange.from = moment(new Date(dateRange.from)).format();
			newRange.to = moment(new Date(dateRange.to)).format();
			return newRange;
		} else {
			return dateRange;
		}
	}

	private isLegacyDate(date: string): boolean { // if date has format 00:00Z, but user is not in UTC
		return moment.parseZone(date).format('Z') === '+00:00' // date is in utc
			&& moment(new Date(date)).format('Z') !== '+00:00' // user is not in UTC
			&& moment.parseZone(date).format('H') !== '0'; // time is not at midnight
	}

	formatZonedDateRange = (dateRange: IDateRange): string => {
		if (!dateRange)
			return;

		let fromText = this.formatAsLocalDate(dateRange.from);
		let toText = this.formatAsLocalDate(dateRange.to);
		let from = moment.parseZone(dateRange.from.replace(this.TZ_NAME_REGEX, ''));
		let to = moment.parseZone(dateRange.to.replace(this.TZ_NAME_REGEX, ''));
		if (from.format('Z') === to.format('Z')) { // same offset
			return fromText + ' - ' + toText + this.getUTCSuffix(to);
		} else {
			return fromText + this.getUTCSuffix(from) + ' - ' + toText + this.getUTCSuffix(to);
		}
	};

	formatDatePointWithTZ = (date: string, timezone: string|number, withTimezone: boolean,
		toLocal: boolean, format?: DateTimeFormat, hideUtcTimeSuffix?: boolean, docDate?: boolean): string => {

		if (!date) {
			return;
		}

		let dateText, dateObject;
		let dateFormat = this.dateService.getUserMomentDateFormat(format || DateTimeFormat.BASIC_DATE_TIME);

		if (!isEmpty(timezone)) {
			if (docDate) {
				//CSI-9943, by PLAT, doc time will always based on server timezone, so no conversion to local timezone
				if (_.isNumber(timezone)) { // fixed offset
					dateObject = moment.utc(date).utcOffset(timezone);
					dateText = dateObject.format(dateFormat);
				} else { // e.g. America/Chicago
					dateObject = moment.tz(date, timezone as string);
					dateText = dateObject.format(dateFormat);
				}
			} else {
				if (_.isNumber(timezone)) { // fixed offset
					let offsetSuffix = moment().utcOffset(-timezone).format('Z');
					dateObject = moment.parseZone(this.toLocalDate(date) + offsetSuffix);
					dateText = toLocal
						? this.formatAsLocalDate(date, format)
						: moment(date).utcOffset(-timezone).format(dateFormat as string);
				} else { // e.g. America/Chicago
					dateObject = moment.tz(this.toLocalDate(date), timezone as string);
					dateText = toLocal ? this.formatAsLocalDate(date, format) : dateObject.format(dateFormat);
				}
			}
		} else if (this.$rootScope.clientTimezoneName) {
			dateObject = moment(date).tz(this.$rootScope.clientTimezoneName);
			dateText = dateObject.format(dateFormat);
		} else { // use local timezone
			dateFormat = this.dateService.getUserDateFormat(format || DateTimeFormat.BASIC_DATE_TIME);
			dateText = this.$filter('date')(date, dateFormat);
			if (hideUtcTimeSuffix) {
				return dateText;
			}
			dateObject = moment(date);
		}

		return dateText + this.utcSuffix(dateObject, withTimezone, true);
	};

	formatEsDate = (date: string): string => {
		if (!date) return '';
		const dateFormat = this.dateService.getUserMomentDateFormat(DateTimeFormat.BASIC_DATE);
		const dateObject = moment(date, 'YYYYMMDD');
		return dateObject.format(dateFormat as string);
	};


	formatDatePoint = (date: string, timezone: string|number, withTimezone: boolean, format?: DateTimeFormat,
		hideUtcTimeSuffix?: boolean): string => {
		return this.formatDatePointWithTZ(date, timezone, withTimezone, true, format, hideUtcTimeSuffix);
	};

	formatDocDatePoint = (date: string, timezone: string|number, withTimezone: boolean, format?: DateTimeFormat): string => {
		return this.formatDatePointWithTZ(date, timezone, withTimezone, false, format, false, true);
	};

}

app.service('dateFilterService', DateFilterService);
