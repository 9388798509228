import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { IComparisonListItem } from '@app/modules/widget-settings/cb-metric/comparison-item/comparison-item.component';
import { MetricComparisonUtils } from '@app/modules/widget-settings/cb-metric/metric-comparison-utils';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { HierarchyEnrichmentValue, MetricComparisonType, MetricWidgetComparison } from '@cxstudio/reports/entities/metric-widget-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';


@Component({
	selector: 'comparisons-list',
	templateUrl: './comparisons-list.component.html',
	styles: [`
		.cdk-drop-list-dragging hr {
			visibility: hidden;
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComparisonsListComponent implements OnInit {

	@Input() comparisons: MetricWidgetComparison[];
	@Output() update = new EventEmitter<void>();

	@Input() dashboardId: number;
	@Input() projectTimezone: string;
	@Input() dateFilters: any[];
	@Input() props: WidgetProperties;
	@Input() metrics: Metric[];
	@Input() multi: boolean;

	private showingNewComparison: boolean;

	private readonly GOAL_COMPARISON: IComparisonListItem =
		{ type: MetricComparisonType.GOAL, displayName: this.locale.getString('widget.goal'), disabled: false };

	private readonly HIERARCHY_ENRICHMENT_COMPARISON: IComparisonListItem =
		{ type: MetricComparisonType.HIERARCHY_ENRICHMENT, displayName: this.locale.getString('widget.orgHierarchyEnrichment'), disabled: false };

	private readonly TIME_COMPARISON: IComparisonListItem =
		{ type: MetricComparisonType.TIME, displayName: this.locale.getString('widget.period_over_period'), disabled: false };

	allOptions: IComparisonListItem[];

	hierarchyEnrichments: HierarchyEnrichmentValue[];
	hierarchyEnrichmentError: string;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly betaFeatureService: BetaFeaturesService,
		@Inject('currentWidgets') private readonly currentWidgets: ICurrentWidgets,
	) {}

	ngOnInit(): void {
		if (this.betaFeatureService.isFeatureEnabled(BetaFeature.OH_ENRICHMENTS)) {
			let personalization: PersonalizationState = this.currentWidgets.getPersonalization(this.dashboardId + '');
			let hierarchyId: number = personalization.getHierarchyId();
			this.hierarchyEnrichments = personalization.getComparisonEnrichments();
			if (hierarchyId > 0 && this.hierarchyEnrichments.length > 0) {
				this.allOptions = [this.GOAL_COMPARISON, this.HIERARCHY_ENRICHMENT_COMPARISON, this.TIME_COMPARISON];
			} else {
				this.allOptions = [this.GOAL_COMPARISON, this.TIME_COMPARISON];
			}
		} else {
			this.allOptions = [this.GOAL_COMPARISON, this.TIME_COMPARISON];
		}

		this.checkHierarchyEnrichmentError();
		this.checkNewComparison();
	}

	private checkHierarchyEnrichmentError(): void {
		let hierarchyEnrichmentComparison: MetricWidgetComparison = MetricComparisonUtils.getHierarchyEnrichmentComparison(this.comparisons);
		let hierarchyEnrichment: HierarchyEnrichmentValue = hierarchyEnrichmentComparison?.value as HierarchyEnrichmentValue;
		if (hierarchyEnrichment) {
			if (!MetricComparisonUtils.isHierarchyEnrichmentPropertyAvailable(this.hierarchyEnrichments, hierarchyEnrichment)) {
				this.removeComparison(hierarchyEnrichmentComparison);
				this.hierarchyEnrichmentError = this.locale.getString('widget.hierarchyEnrichmentError');
			}
		}
	}

	private onChange(): void {
		this.updateComparisonIdentifiers();
		this.checkNewComparison();
		this.update.emit();
	}

	removeComparison = (comparison: MetricWidgetComparison): void => {
		this.comparisons.remove(comparison);
		this.onChange();
	};

	addComparison(): void {
		this.showingNewComparison = true;
	}

	canAddComparisons(): boolean {
		return this.multi && this.comparisons.length < 2 && !this.showingNewComparison;
	}

	showNewComparison(): boolean {
		return this.showingNewComparison && (this.multi || this.comparisons.length === 0);
	}

	onComparisonSelected = (item: IComparisonListItem) => {
		if (!item.type)
			return;
		let comparison = MetricComparisonUtils.getComparisonDefaults(item.type, this.hierarchyEnrichments);
		this.comparisons.push(comparison);

		this.showingNewComparison = false;
		this.onChange();
	};

	onComparisonChanged(comparison: MetricWidgetComparison, index: number): void {
		this.comparisons[index] = comparison;
		this.onChange();
	}

	private checkNewComparison(): void {
		if (_.isEmpty(this.comparisons)) {
			this.showingNewComparison = true;
		}
	}

	getComparisonOptions(comparison?: MetricWidgetComparison): IComparisonListItem[] {
		return _.chain(this.allOptions)
			.filter(option => this.filterComparisonsOptions(MetricComparisonType.TIME, option, comparison))
			.filter(option => this.filterComparisonsOptions(MetricComparisonType.HIERARCHY_ENRICHMENT, option, comparison))
			.value();
	}

	private filterComparisonsOptions(type: MetricComparisonType, option: IComparisonListItem, comparison?: MetricWidgetComparison): boolean {
		let comparisonSelected: boolean = !!_.findWhere(this.comparisons, { type });

		return !comparisonSelected || comparison?.type === type || option.type !== type;
	}

	private updateComparisonIdentifiers(): void {
		_.each(this.comparisons, (comparison, index) => {
			comparison.identifier = `comparison_c${index}_${comparison.type.toLowerCase()}`;
		});
	}

	onDrop(event: CdkDragDrop<MetricWidgetComparison[]>) {
		event.container.data.move(event.previousIndex, event.currentIndex);
		this.onChange();
	}
}

app.directive('comparisonsList', downgradeComponent({component: ComparisonsListComponent}));
