import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { FiltersService } from '@app/modules/filter/services/filters.service';
import { GenericSelectUtils } from '@app/modules/item-grid/selection/generic-selection-utils.factory';
import { SelectionControllerBase } from '@app/modules/item-grid/selection/selection-controller-base';
import { SelectionUtils } from '@app/modules/item-grid/selection/selection-utils.class';
import { TaggingHelper } from '@app/modules/item-grid/services/tagging-helper.service';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { ScorecardMetricsManagementService } from '@app/modules/scorecards/metrics/scorecard-metrics-management.service';
import { ProjectAssetsErrors, ProjectAssetsLoading } from '@app/modules/units/project-selection-error/project-selection-error.component';
import { ProjectsReadyEvent } from '@app/modules/units/workspace-project-selector/workspace-project-selector.component';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AccountOrWorkspaceProjectData, WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';
import { OptionsAmount } from '@app/shared/components/project-selector/options-amount';
import { PromiseUtils } from '@app/util/promise-utils';
import { ProjectFilterData } from '@cxstudio/angular-filters/project-filter-data';
import { AssetPermission } from '@cxstudio/asset-management/asset-permission';
import Authorization from '@cxstudio/auth/authorization-service';
import { FavoriteProperties } from '@cxstudio/auth/entities/favorite-properties';
import { Security } from '@cxstudio/auth/security-service';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { IMetricContextMenuUtils } from '@cxstudio/common/context-menu-utils/metric-context-menu-utils';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { CHANGE_MA_STATUS } from '@cxstudio/common/url-service.service';
import { AccountProject } from '@cxstudio/content-provider-api/account-project';
import { IFolderService } from '@cxstudio/folders/folder-service.factory';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { MetricManagementApiService } from '@cxstudio/metrics/api/metric-management-api.service';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { AnalyticMetricType, AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { GeneratedFolderType } from '@cxstudio/report-filters/generated-folder-type';
import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { Project } from '@cxstudio/user-administration/users/project-access/project-class';
import * as _ from 'underscore';
import { GridContext } from '../grids/grid-context-constant';
import { FilterMetricDefinition } from './entities/filter-metric-definition.class';
import { MetricDefinition } from './entities/metric-definition';
import { IMetricFolder } from './entities/metric-folder';
import { Metric } from './entities/metric.class';
import { IMetricActionsService } from './metric-actions-service';
import { MetricConverter } from './metric-converter.service';
import { MetricTreeItem } from './metric-tree-item';
import { PredefinedMetricConstants } from './predefined/predefined-metric-constants';

interface MetricLoading extends ProjectAssetsLoading {
	metricLoadingPromise?: ng.IPromise<any>;
}

interface MetricTab {
	type: MetricTabType;
	name: string;
}

enum MetricTabType {
	CUSTOM = 'CUSTOM',
	SYSTEM = 'SYSTEM'
}

export interface IMetricManagementScope extends ISimpleScope {
	props?: IProjectSelection;
	loading?: MetricLoading;
	errors?: ProjectAssetsErrors;
	metricList?: MetricTreeItem[];
	visibleMetricList?: MetricTreeItem[];
	selectionUtils?: GenericSelectUtils<MetricTreeItem>;
	contextMenuUtils?: IMetricContextMenuUtils;
	actionsService?: IMetricActionsService;
}

export class MetricManagementController implements ng.IController, SelectionControllerBase<MetricTreeItem> {

	private folderSvc: IFolderService;
	private metricLoading;
	private predefinedMetricLoading;
	private scorecardMetricsLoading;

	gridType: GridTypes;
	gridNameField: string;
	gridOptions: SlickgridOptions;
	project: AccountOrWorkspaceProjectData;
	projectsAmount: OptionsAmount;
	projects: Project[];
	metricList: Array<Metric | IMetricFolder>;
	defaultSentimentMetric: Metric;
	visibleMetricList: any[];
	tabMetricList?: MetricTreeItem[];
	ui;
	selectionUtils;
	contextMenuUtils: IMetricContextMenuUtils;
	actionsService: IMetricActionsService;
	preselectedProject: Partial<FavoriteProperties>;
	metricEditors;
	loading: MetricLoading = {};
	errors: ProjectAssetsErrors = {};
	lastChecked;
	lastChange;
	isWorkspaceEnabled: boolean;


	constructor(
		private $scope: IMetricManagementScope,
		private $q: ng.IQService,
		private $filter: ng.IFilterService,
		private locale: ILocale,
		private security: Security,
		private authorization: Authorization,
		private metricConverter: MetricConverter,
		private gridUtils: GridUtilsService,
		private treeService,
		private MetricContextMenuUtils,
		private MetricActionsService,
		private filtersService: FiltersService,
		private metricApiService: MetricManagementApiService,
		private FolderService,
		private contextMenuTree,
		private cbDialogService: CBDialogService,
		private $routeParams,
		private $location: ng.ILocationService,
		private $route, //: ng.route.IRouteService,
		private globalNotificationService,
		private redirectService,
		private readonly scorecardMetricsManagementService: ScorecardMetricsManagementService,
		private readonly metricsService: MetricsService,
		private readonly betaFeaturesService: BetaFeaturesService,
	) {}

	$onInit = () => {
		// restrict this page to non-mobile
		this.security.preventMobileAccess();
		this.security.restrictPage(this.authorization.hasMetricManagementAccess);

		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);

		this.folderSvc = new this.FolderService(FolderTypes.METRIC);

		this.selectionUtils = SelectionUtils.createMetricSelectionUtils(this);

		this.contextMenuUtils = new this.MetricContextMenuUtils(this);
		this.actionsService = new this.MetricActionsService(this);

		this.$scope.$on('$routeUpdate', (event) => {
			if (this.$routeParams.metricId) {
				this.$route.reload();
			}
		});

		this.loadPreSelectedMetric().then((response) => {
			const result = response.data || {};
			this.preselectedProject = {
				favoriteCP: result.contentProviderId,
				favoriteAccount: result.accountId,
				favoriteProject: result.projectId
			};
		}, (response) => {
			const status = response.status;
			if (status === CHANGE_MA_STATUS) { // dashboard from another MA, need to reload
				this.redirectService.saveCurrentMA({accountId: response.data});
				return;
			}
			this.$location.url('/dashboards');
			this.$route.reload();
		});

		this.$scope.$watchCollection(() => this.metricList, this.updateVisibleMetricList);

		this.gridType = GridTypes.METRIC;
		this.gridNameField = 'displayName';
		this.gridOptions = {
			onClick: this.onClick
		};
		this.initEvents();

		this.project = {} as AccountOrWorkspaceProjectData;
		this.projects = [];
		this.metricList = [];
		this.visibleMetricList = [];

		this.ui = this.ui || {};
		this.ui.hideMetrics = true;
		this.ui.metricTabs = [
			{name: this.locale.getString('common.custom'), type: MetricTabType.CUSTOM},
			{name: this.locale.getString('common.system'), type: MetricTabType.SYSTEM}
		] as MetricTab[];
		this.ui.selectedTab = MetricTabType.CUSTOM;
	};

	getProjectId(): number {
		return this.project.projectId;
	}

	getSearchFilter(): string {
		return this.ui.searchFilter;
	}

	getVisibleItems(): MetricTreeItem[] {
		return this.tabMetricList;
	}

	isShowHidden(): boolean {
		return !this.ui.hideMetrics;
	}
	isSelectionSupported(item: MetricTreeItem): boolean {
		return item.type === GridContext.METRIC || item.type === GridContext.METRIC_SCORECARD;
	}

	verifyNoMixedMetricTypes(item: MetricTreeItem): boolean {
		let selectedMetrics = _.where(this.metricList, {selected: true});
		//remove folders, as their type doesnt matter
		selectedMetrics = _.reject(selectedMetrics, this.isFolder);
		if (selectedMetrics.length) {
			//if some items are checked, we need to make sure currently checked item's type matches new item check
			return _.every(selectedMetrics, metric => metric.type === item.type);
		}
		return true;
	}
	selectAllFilter = (item: MetricTreeItem): boolean => {
		return item.type === GridContext.METRIC;
	};

	showSelectAll = (): boolean => {
		let selectedMetrics = _.where(this.metricList, {selected: true});
		selectedMetrics = _.reject(selectedMetrics, this.isFolder);
		return _.every(selectedMetrics, metric => metric.type === GridContext.METRIC);
	};

	private loadPreSelectedMetric = (): ng.IPromise<any> => {
		if (this.$routeParams.metricId) {
			const metricId = parseInt(this.$routeParams.metricId, 10);
			return this.metricApiService.getMetric(metricId);
		} else {
			return this.$q.when({});
		}
	};

	private initEvents(): void {
		this.errors = {
			noAccounts: false,
			noProjects: false
		};

		this.$scope.$on('projects:loaded', (event, projects: AccountProject[]) => {
			this.projectsAmount = projects.length === 1 ? OptionsAmount.SINGLE_OPTION : OptionsAmount.SEVERAL_OPTIONS;
			this.projects = projects.map(project => {
				return {
					id: project.projectId,
					name: project.name
				};
			});
		});
	}

	canCreateMetric = (): boolean => {
		return this.security.has(MasterAccountPermissionAction.CREATE_METRIC);
	};

	canEditPredefinedMetric = (): boolean => {
		return this.security.has('manage_settings') && this.canCreateMetric();
	};

	createMetric = (): void => {
		this.actionsService.createMetric();
	};

	addDuplicatedMetric = (metric: Metric): void => {
		this.addMetricToList(metric);
	};

	addNewMetric = (metric: Metric): void => {
		this.addMetricToList(metric);
	};

	addMetricToList = (newMetric: Metric): void => {
		this.metricTransformer(newMetric);

		const targetFolder = this.metricList.find(f => f.id === newMetric.parentId);
		this.treeService.addItem(this.metricList, newMetric, targetFolder);

		this.refreshGrid(newMetric);

		this.notifyMetricWasAdded(newMetric);
	};

	private notifyMetricWasAdded = (metric: Metric): void => {
		this.globalNotificationService.addCreatedNotification(metric.displayName);
	};

	metricTransformer = (metric: Metric): void => {
		if (metric.definition) {
			if (metric.type === MetricType.SCORECARD) {
				metric.typeDisplayName = this.locale.getString('metrics.scorecardType');
			} else {
				metric.typeDisplayName = this.metricConverter.codeToString(metric.definition.type);
			}
		}
		this.updateProjectName(metric);
	};

	private onClick = (event, object): void => {
		// single click only on title
		if (event.ctrlKey && this.selectionUtils.isSupportedType(object) && this.verifyNoMixedMetricTypes(object)) {

			this.lastChecked = object;
			this.selectionUtils.handleCtrlClick(object);
			this.$scope.$apply();
			return;
		}
		if (this.gridUtils.isNameClick(event)) {
			if (AnalyticMetricTypes.isPredefinedMetric(object)) {
				if (this.canEditPredefinedMetric()) {
					this.actionsService.editPredefinedMetric(object);
				} else {
					this.actionsService.viewMetric(object);
				}
			} else {
				this.showMetricDialog(object);
			}
		} else if (this.gridUtils.isToggleClick(event) && !this.gridUtils.isToggleDisabled(event)) {
			this.handleClickOnToggle(object);
		} else if (this.gridUtils.isMenuClick(event)) {
			this.contextMenuTree.showObjectListMenu(event, object, this.contextMenuUtils.getContextMenu(object), 'dashboards', 360);
		} else if (this.gridUtils.isBulkCheckbox(event) && this.verifyNoMixedMetricTypes(object)) {
			this.selectionUtils.handleCheckboxClick(object, this.lastChecked, event.shiftKey);
			this.lastChecked = object;
			this.$scope.$apply();
		}
	};

	private handleClickOnToggle = (object) => {
		this.changeScorecardMetricState(object);
	};

	changeScorecardMetricState(scorecardMetric): void {
		if (this.security.has('manage_scorecards')) {
			this.scorecardMetricsManagementService.updateDisableState(scorecardMetric, !scorecardMetric.disabled).then(() => {
				scorecardMetric.disabled = !scorecardMetric.disabled;
				this.refreshGrid(scorecardMetric);
			});
		}
	}


	private showMetricDialog(metric): void {
		if (this.isOwner(metric) || (this.canEditMetric(metric)) && this.canCreateMetric()) {
			this.actionsService.editMetric(metric);
		} else {
			this.actionsService.viewMetric(metric);
		}
	}

	private canEditMetric = (metric: Metric): boolean => {
		return AssetPermission.EDIT === metric.permissionLevel;
	};

	private isOwner = (object: Metric): boolean => {
		return !object.ownerName || this.security.isCurrentUser(object.ownerName);
	};

	refreshGrid = (items?: MetricTreeItem[] | MetricTreeItem): void => {
		this.lastChange = [].concat(items);
	};

	createFolder = (folder: IMetricFolder): void => {
		const space = WorkspaceTransitionUtils.getWorkspace(this.project);
		if (!WorkspaceTransitionUtils.isWorkspaceSelected(space)) {
			this.errors.noProjectSelected = true;
			return;
		} else this.errors.noProjectSelected = false;

		this.folderSvc.createFolder(folder, this.metricList, space)
			.then((created) => {
				created.displayName = created.name;
				this.refreshGrid(created);
				this.globalNotificationService.addFolderNotification(created.name);
			});
	};

	validProviderSelection = (providerId: number): boolean => {
		return !_.isUndefined(providerId)
			&& providerId !== null
			&& providerId >= 0;
	};

	validMetricSelection = (contentProviderId: number, accountId: number, projectId: number): boolean => {
		return this.validProviderSelection(contentProviderId)
			&& this.validProviderSelection(accountId)
			&& this.validProviderSelection(projectId);
	};

	private isFolder = (item: MetricTreeItem): item is IMetricFolder => {
		return /.*folder.*/i.test(item.type);
	};

	onAccountChange = (newProps: IProjectSelection): void => {
		this.updateProject(newProps);
		this.metricList = [];
	};

	onProjectChange = (newProps: IProjectSelection): void => {
		this.updateProject(newProps);

		if (ValueUtils.isNotSelected(newProps.accountId) || this.errors.tooManyProjects) {
			this.metricList = [];
			return;
		}

		if (!this.loading.metricLoadingPromise) {
			this.reloadMetrics();
		}
	};

	private updateProject = (newProps: IProjectSelection): void => {
		_.extend(this.project, _.pick(newProps, ['contentProviderId', 'accountId', 'projectId', 'projectName']));
	};

	workspaceChanged = () => {
		this.projectsAmount = undefined;
	};

	workspaceProjectChanged = (newProject?: WorkspaceProjectData): void => {
		this.cleanProjectErrors();
		let projectSelectedCheck = WorkspaceProjectUtils.isProjectSelected(this.project as WorkspaceProject);
		if (newProject) {
			this.project = newProject;
		}

		if (!this.projectsAmount) {
			//projects not yet loaded
			return;
		}

		this.clearAndRemoveAll();

		if (!_.isEmpty(this.project) && projectSelectedCheck) {
			this.reloadMetrics();
		} else if (this.checkManyProjectError(newProject)) {
			this.errors.tooManyProjects = true;
			return;
		} else {
			this.reloadMetrics();
		}
	};

	private clearAndRemoveAll = (): void => {
		this.selectionUtils.clearSelections();
		this.metricList.removeAll();
	};

	private checkManyProjectError = (newProject: WorkspaceProjectData): boolean => {
		if ( this.projectsAmount === OptionsAmount.MANY_OPTIONS ) {
			return true;
		}
		return false;
	};

	private cleanProjectErrors = (): void => {
		this.errors.noProjectSelected = false;
		this.errors.noProjectAttributes = false;
		this.errors.tooManyProjects = false;
	};

	onProjectsLoading = (loadingPromise: Promise<any>) => {
		this.loading.promise = PromiseUtils.old(loadingPromise);
	};

	projectsLoaded = (event: ProjectsReadyEvent): void => {
		this.projectsAmount = event.optionsAmount;
		this.projects = event.projects;
		this.workspaceProjectChanged();
	};

	errorsChanged = (errors: string[]): void => {
		this.errors.messages = errors;
	};


	private reloadMetrics = (): ng.IPromise<void> => {
		let workspace = WorkspaceTransitionUtils.getWorkspace(this.project);
		if (!WorkspaceTransitionUtils.isWorkspaceSelected(workspace)) return;

		this.metricLoading = WorkspaceTransitionUtils.isProjectSelected(this.project)
			? this.metricsService.getMetrics(this.project, CacheOptions.NOT_CACHED)
			: this.metricsService.getMetricsForWorkspace(workspace, CacheOptions.NOT_CACHED);

		this.predefinedMetricLoading = WorkspaceTransitionUtils.isProjectSelected(this.project)
			? this.metricsService.getPredefinedMetrics(this.project)
			: this.metricsService.getPredefinedMetricsForWorkspace(workspace);
		this.scorecardMetricsLoading = WorkspaceTransitionUtils.isProjectSelected(this.project)
			? this.scorecardMetricsManagementService.getScorecardMetrics(this.project)
			: this.scorecardMetricsManagementService.getWorkspaceScorecardMetrics(workspace);

		this.loading.metricLoadingPromise = this.$q.all([
			this.metricLoading,
			this.predefinedMetricLoading,
			this.scorecardMetricsLoading
		]).then((response) => {
			let predefinedMetrics = this.processPredefinedMetrics(response[1]);
			let scorecardMetrics = response[2];
			let scorecardFolders = this.scorecardMetricsManagementService.getScorecardFolders(
				scorecardMetrics, this.project, this.security.getMasterAccountId());

			let metricList = predefinedMetrics
				.concat(response[0])
				.concat(scorecardMetrics)
				.concat(scorecardFolders);

			metricList.push(this.getPredefinedFolder());

			this.transformMetrics(metricList);
			if (this.$routeParams.metricId) {
				const metricId  = parseInt(this.$routeParams.metricId, 10);
				const selectedMetric = _.findWhere(metricList, { id: metricId });
				if (selectedMetric) {
					this.showMetricDialog(selectedMetric);
				}
				this.$location.url('metrics');
			}
		}).then(() => {
			if (!WorkspaceTransitionUtils.isProjectSelected(this.project)) return;


			this.metricList
				.filter(this.isFolder)
				.forEach((folder: IMetricFolder) => folder.folderProjectId = this.project.projectId);

			this.refreshGrid();
		});
		return this.loading.metricLoadingPromise;
	};

	private getPredefinedFolder = (): any => {
		return {
			name: this.locale.getString('metrics.predefined'),
			id: AnalyticMetricType.METRIC_PREDEFINED,
			type: FolderTypes.METRIC,
			generatedFolderType: GeneratedFolderType.SYSTEM,
			description: ''
		};
	};

	private processPredefinedMetrics = (predefinedMetrics: Metric[]): any[] => {
		this.defaultSentimentMetric = _.find(predefinedMetrics, (metric) =>
			metric.name === PredefinedMetricConstants.SENTIMENT && !!_.isUndefined(metric.projectId)
		);
		if (this.defaultSentimentMetric) {
			this.metricTransformer(this.defaultSentimentMetric);
		}
		return _.without(predefinedMetrics, this.defaultSentimentMetric);
	};

	private transformMetrics = (allMetrics): void => {
		let availableProjectIds = this.projects.map(project => project.id);
		allMetrics = _.filter(allMetrics, (metric: Metric): boolean => {
			return !metric.projectId || _.contains(availableProjectIds, metric.projectId);
		});
		let flatTree = this.gridUtils.processItemsTree(allMetrics);
		// need to replicate definition.* to top level for sorting purposes
		for (let item of flatTree) {
			this.metricTransformer(item);
		}

		this.metricList = [];
		this.metricList.pushAll(flatTree);

		flatTree.forEach((treeItem) => {
			if (this.isFolder(treeItem)) {
				treeItem._collapsed = true;
			}

			if (treeItem.hide) {
				TaggingHelper.tag(treeItem, TaggingHelper.tags.HIDDEN);
			}
		});
		this.refreshGrid();
		this.loading.metricLoadingPromise = null;
	};

	//CSI-7128
	private updateProjectName = (metric: Metric): void => {
		if (this.projects && this.projects.length > 0) {
			let projectName = _.findWhere(this.projects, {id: metric.projectId})?.name;
			if (projectName) {
				metric.projectName = projectName;
			}
		}
	};

	removeUnownedFilters = (metric: Metric): ng.IPromise<boolean> => {
		if (!this.isFilterMetric(metric.definition)) {
			return this.$q.when(false);
		}
		return PromiseUtils.old(this.filtersService.getStudioFilters(metric)).then((filters) => {
			let rulesRemoved = this.removeUnownedFiltersInMetric(metric, this.security.getEmail(), filters);
			if (rulesRemoved) {
				let warning = this.cbDialogService.confirm(this.locale.getString('metrics.duplicateRuleRemovalHeader'),
					this.locale.getString('metrics.duplicateRuleRemovalWarning'), this.locale.getString('common.ok'),
					this.locale.getString('common.cancel'));
				return warning.result.then(
					() => this.$q.when(true),
					() => this.$q.reject()
				);
			} else {
				return this.$q.when(false);
			}
		});
	};

	private isFilterMetric = (definition: MetricDefinition): definition is FilterMetricDefinition => {
		return definition && definition.type === MetricType.FILTER;
	};

	private removeUnownedFiltersInMetric = (metric: Metric, currentUser, studioFilters: IFilter[]): boolean => {
		if (this.isFilterMetric(metric.definition) && metric.definition.rule && metric.definition.rule.filterRules) {
			let filterRules = metric.definition.rule.filterRules;
			let otherRules = _.filter(filterRules, (filter: any) => !Boolean(filter.filterId));
			let ownedStudioFilterRules = _.filter(filterRules, (filter: any) => {
				let studioFilter: any = _.find(studioFilters, {id: filter.filterId});
				return studioFilter && studioFilter.ownerName === currentUser;
			});
			let newFilterRules = _.union(otherRules, ownedStudioFilterRules);
			metric.definition.rule.filterRules = newFilterRules;
			metric.ownerName = currentUser;
			//If any filters were removed, return false
			return filterRules.length !== newFilterRules.length;
		}
		return false;
	};

	private updateVisibleMetricList = (): void => {
		let projectFilter: ng.IFilterFilter = this.$filter('projectFilter');
		let projectFilterData: ProjectFilterData = {
			singleProject: this.projectsAmount === OptionsAmount.SINGLE_OPTION,
			projectId: WorkspaceTransitionUtils.isProjectSelected(this.project) ? this.project.projectId : undefined
		};
		this.visibleMetricList = projectFilter(this.metricList, projectFilterData);

		if (!WorkspaceTransitionUtils.isProjectSelected(this.project)) {
			this.filterCustomSentimentMetrics();
		}

		let predefinedFolder: any = this.removePredefinedFolder();
		if (predefinedFolder) {
			predefinedFolder.children = _.filter(predefinedFolder.children, (child: any) => child.name !== 'sentiment');
			if (WorkspaceTransitionUtils.isProjectSelected(this.project)) {
				this.populatePredefinedFolderWithSentiment(predefinedFolder, this.visibleMetricList, this.project.projectId);
			}
			this.visibleMetricList.push(predefinedFolder);
		}

		this.populateTabMetricList();
	};

	private populateTabMetricList = (): void => {
		if (this.ui.selectedTab === MetricTabType.CUSTOM) {
			this.tabMetricList = _.filter(this.visibleMetricList, item => {
				return item.ownerId || item.generatedFolder === GeneratedFolderType.CUSTOM ;
			});
		} else {
			this.tabMetricList = _.filter(this.visibleMetricList, item => {
				return !item.ownerId && item.generatedFolderType !== GeneratedFolderType.CUSTOM;
			});
		}
	};

	private filterCustomSentimentMetrics = (): void => {
		this.visibleMetricList = _.filter(this.visibleMetricList, (metric) => {
			return metric.name !== 'sentiment';
		});
	};

	private removePredefinedFolder = (): any => {
		let predefinedFolder: any = _.findWhere(this.visibleMetricList, {id: AnalyticMetricType.METRIC_PREDEFINED});
		this.visibleMetricList = _.without(this.visibleMetricList, predefinedFolder);
		return predefinedFolder;
	};

	private populatePredefinedFolderWithSentiment = (predefinedFolder: any, visibleMetrics: MetricTreeItem[], projectId: number): void => {
		let customSentiment: any = _.findWhere(visibleMetrics, {name: 'sentiment', projectId});

		if (customSentiment) {
			this.addSentimentToFolder(customSentiment, predefinedFolder);
		}

		if (!customSentiment && !_.isUndefined(this.defaultSentimentMetric)) {
			let defaultSentiment = this.createSentimentFromDefault(projectId);
			this.addSentimentToFolder(defaultSentiment, predefinedFolder);
			this.visibleMetricList.push(defaultSentiment);
		}
	};

	private createSentimentFromDefault = (projectId: number): any => {
		let defaultSentiment = angular.copy(this.defaultSentimentMetric);
		defaultSentiment.projectId = projectId;
		defaultSentiment.id = -(projectId * 1000 + 999); // some unique id, not tied to backend
		return defaultSentiment;
	};

	private addSentimentToFolder = (sentimentMetric: any, predefinedFolder: any) => {
		sentimentMetric.level = 1;
		sentimentMetric.parent = predefinedFolder;
		predefinedFolder.children.push(sentimentMetric);
	};

	isTabSelected = (type: MetricTabType) => {
		return this.ui.selectedTab === type;
	};

	setTabSelected = (type: MetricTabType) => {
		this.ui.selectedTab = type;
	};

	selectTab = (type: MetricTabType) => {
		this.setTabSelected(type);
		this.populateTabMetricList();
		this.refreshGrid();
	};

	createNewButtonsDisabled = (): boolean => {
		return this.ui.selectedTab === MetricTabType.SYSTEM;
	};
}

app.component('metricManagement', {
	controller: MetricManagementController,
	templateUrl: 'partials/metrics/metric-management.component.html'
});
