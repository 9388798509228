import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { AssetAccessApiService } from '@app/modules/access-management/api/asset-access-api.service';
import { IAsset } from '@app/modules/access-management/groups/asset';
import { AssetType } from '@app/modules/access-management/groups/asset-type';
import { ModificationDelta } from '@app/modules/access-management/groups/modification-delta';
import { DependenciesDialogInput, DependenciesModalComponent } from '@app/modules/asset-management/dependencies-modal/dependencies-modal-component';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { AttributeDependencyTypesProvider } from '@app/modules/attribute/services/assets/attribute-dependency-types-provider';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { ObjectListColumnsService } from '@app/modules/object-list/object-list-columns.service';
import { ObjectListMenuService } from '@app/modules/object-list/object-list-menu.service';
import { ObjectListUtils } from '@app/modules/object-list/object-list-utils';
import { TableFilterManager } from '@app/modules/object-list/types/table-filter-manager.class';
import { IconsMap } from '@app/modules/object-list/utilities/icons-map.class';
import { IAdminAttribute } from '@app/modules/project/attribute/admin-attribute';
import { AttributeManagementService } from '@app/modules/project/attribute/attribute-management.service';
import { AttributeType } from '@app/modules/project/attribute/attribute-type';
import { AttributeStats } from '@app/modules/project/attribute/attributes-stats-response';
import {
	AdminAssetsManagementService, IAdminAttributeTreeItem,
	IExtendedAdminAttribute
} from '@app/modules/project/project-assets-management/project-assets-list/admin-assets-management.service';
import { AssetDefaultsService } from '@app/modules/project/project-assets-management/project-assets-list/asset-defaults.service';
import { EditableAssetsTable } from '@app/modules/project/project-assets-management/project-assets-list/editable-assets-table.interface';
import { PropertyValue } from '@app/modules/project/state-changes/property-changes';
import { SettingsState } from '@app/modules/project/state-changes/settings-state.class';
import { WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { ProjectAccessLevelValue } from '@app/modules/user-administration/editor/workspaces-projects-access/project-access-level-value.enum';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';
import { AgeService } from '@app/modules/utils/dates/age.service';
import { GlobalUnloadService } from '@app/shared/services/global-unload.service';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { ProjectAsset } from '@cxstudio/asset-management/project-asset';
import AttributeGeography from '@cxstudio/attribute-geography/attribute-geography';
import { BoundaryField } from '@cxstudio/attribute-geography/boundary-field';
import { GeographyApiService } from '@cxstudio/attribute-geography/geography-api.service';
import { GeographyOptionsService } from '@cxstudio/attribute-geography/geography-options.service';
import { Security } from '@cxstudio/auth/security-service';
import { Caches } from '@cxstudio/common/caches';
import { FolderUtils } from '@cxstudio/common/folders/folder-utils';
import { HiddenItemType } from '@cxstudio/common/hidden-item-type';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ProjectTabType } from '@cxstudio/projects/project-tab-type';
import { ClarabridgeMetricName } from '@cxstudio/reports/providers/cb/constants/clarabridge-metrics-names';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { SecurityApiService } from '@cxstudio/services/data-services/security-api.service';
import { ColDef, ColumnApi, GetContextMenuItemsParams, GridApi, GridReadyEvent, MenuItemDef } from 'ag-grid-enterprise';
import * as moment from 'moment';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
@Component({
	selector: 'attributes-table',
	templateUrl: './attributes-table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttributesTableComponent implements OnInit, OnChanges, OnDestroy, EditableAssetsTable {

	@Input() project: WorkspaceProjectData;
	@Input() accountProject: IProjectSelection;
	@Input() visibility: ProjectTabType;
	@Input() searchText: string;
	@Input() editMode: boolean;

	loading: Promise<unknown>;

	attributes: IAdminAttributeTreeItem[];

	columnDefs: ColDef[];
	filterManager: TableFilterManager;
	gridApi: GridApi;
	gridColumnApi: ColumnApi;

	lastCachedTimestamp: string;
	loadingStats: boolean;

	ProjectTabType = ProjectTabType;
	AssetType = AssetType;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private ageService: AgeService,
		private attributeManagementService: AttributeManagementService,
		private assetAccessApiService: AssetAccessApiService,
		private objectListColumns: ObjectListColumnsService,
		private objectListMenu: ObjectListMenuService,
		private adminAssetsManagement: AdminAssetsManagementService,
		private assetDefaultsService: AssetDefaultsService,
		private cachedHttpService: CxCachedHttpService,
		private attributeDependencyTypesProvider: AttributeDependencyTypesProvider,
		private cxDialogService: CxDialogService,
		private globalUnloadService: GlobalUnloadService,
		@Inject('security') private readonly security: Security,
		@Inject('securityApiService') private readonly securityApiService: SecurityApiService,
		@Inject('geographyOptionsService') private readonly geographyOptionsService: GeographyOptionsService,
		@Inject('masterAccountApiService') private readonly masterAccountApiService: MasterAccountApiService,
		@Inject('geographyApiService') private readonly geographyApiService: GeographyApiService,
		@Inject('metricConstants') private readonly metricConstants: MetricConstants
	) { }

	ngOnInit(): void {

		this.filterManager = new TableFilterManager().withHiddenSupport();
		this.filterManager.getFilterChangeObservable().subscribe(() => this.filterAndRedraw());

		this.columnDefs = [
			this.objectListColumns.contextMenuColumn({isVisible: (item) => !FolderUtils.isFolder(item)}),
			this.objectListColumns.textColumn('name', this.locale.getString('attributes.databaseName')),
			this.objectListColumns.categoryColumn('uiType', this.locale.getString('attributes.dataType')),
			this.objectListColumns.categoryColumn('objectGroup', this.locale.getString('attributes.objectGroup')),
			this.objectListColumns.yesNoBooleanColumn('reportable', this.locale.getString('attributes.reportable')),
			this.objectListColumns.simpleDropdownColumn<IExtendedAdminAttribute, BoundaryField>(
				'boundaryField',
				this.locale.getString('attributes.geography'),
				(attribute) => this.getGeographyOptions(attribute),
				(row) => this.isGeographyEditable(row)),
			this.objectListColumns.yesNoBooleanColumn('caseSensitive', this.locale.getString('attributes.caseSensitive')),
			this.objectListColumns.textColumn('drillPathAttributeName', this.locale.getString('attributes.drillPath')),
			this.objectListColumns.textColumn('uiParents', this.locale.getString('attributes.parent')),
			this.objectListColumns.yesNoBooleanColumn('delimitedMultiValue', this.locale.getString('attributes.delimitedMultivalue')),
			this.objectListColumns.numberColumn('sourceCount', this.locale.getString('attributes.sourceCount')),
			this.objectListColumns.numberColumn('distinctValuesCount', this.locale.getString('attributes.distinctValue')),
			this.objectListColumns.percentColumn('populatedPercent', this.locale.getString('attributes.populatedPercent')),
			this.objectListColumns.numberColumn('sourceCount', this.locale.getString('attributes.sourceCount')),
			this.objectListColumns.dateColumn('dateLatest', this.locale.getString('attributes.mostRecentDate'), 'YYYYMMDDHHmmss'),
			this.objectListColumns.checkboxColumn('showInDrill', this.locale.getString('administration.showInDrill'),
				(row) => this.isVisibilityEditable(row)),
			this.objectListColumns.checkboxColumn('showInDocExp', this.locale.getString('administration.showInDocExp'),
				(row) => this.isVisibilityEditable(row)),
			this.objectListColumns.checkboxColumn('showInReporting', this.locale.getString('administration.showInReporting'),
				(row) => this.isVisibilityEditable(row)),
			this.objectListColumns.checkboxColumn('useInClarabridgeSearch', this.locale.getString('administration.useInClarabridgeSearch'),
				(row) => this.isVisibilityEditable(row) && !this.isClarabridgeSearchLimitReached()),
		];
		this.loadAttributes();

		this.globalUnloadService.addHandler('attributes-edit', () => this.cancelChanges());
	}

	ngOnChanges(changes: SimpleChanges<AttributesTableComponent>): void {
		if (ChangeUtils.hasChange(changes.project)) {
			this.loadAttributes();
		}
		if (ChangeUtils.hasChange(changes.editMode)) {
			this.gridApi.redrawRows();
		}
	}

	ngOnDestroy(): void {
		this.globalUnloadService.removeHandler('attributes-edit');
	}

	onGridReady(params: GridReadyEvent) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

	}

	private loadAttributes(): void {
		if (WorkspaceProjectUtils.isProjectSelected(this.project)) {
			this.loading = this.adminAssetsManagement.getAttributes(this.project).then(attributes => {
				this.setAttributes(attributes);
				this.refreshAttrStats();
				this.ref.markForCheck();
				setTimeout(() => this.gridColumnApi.autoSizeAllColumns());
			});
		} else {
			this.setAttributes([]);
		}
	}

	private setAttributes(attributes: IAdminAttributeTreeItem[]): void {
		this.attributes = attributes;
		const withoutFolders = attributes.filter(attr => !FolderUtils.isFolder(attr));
		if (!withoutFolders.isEmpty() && withoutFolders.every(attr => attr.hide)) {
			// set to show hidden if everything is hidden
			this.filterManager.setShowHidden(true);
		}
	}

	showLastCachedTimestamp(): boolean {
		return WorkspaceTransitionUtils.isProjectSelected(this.project);
	}

	refreshAttrStats(): void {
		this.loadingStats = true;
		this.attributeManagementService.getAttributesStats(this.project, []).then((response) => {
			let stats = response.attributesStats;
			this.lastCachedTimestamp = this.getLastCachedTimestamp(new Date(response.lastCachedDate));
			this.populateAttrStats(stats);
		}).finally(() => {
			this.loadingStats = false;
			this.ref.markForCheck();
		});
	}

	private populateAttrStats(stats: AttributeStats[]): void {
		let statsMap = _.indexBy(stats, 'id');
		this.attributes.forEach(attribute => {
			// stats reqponse has all attributes lowercased, so ignoring them
			_.extend(attribute, _.omit(statsMap[attribute.id], 'name', 'id'));
		});
		this.gridApi.redrawRows();
	}

	private getLastCachedTimestamp(lastCachedDate: Date): string {
		let deltaSeconds = moment(new Date()).diff(lastCachedDate, 'seconds');
		let ageText = deltaSeconds <= 60
			? this.locale.getString('common.lessThanMinute')
			: this.ageService.getAgeText(deltaSeconds);
		return this.locale.getString('attributes.statsCachedTimestamp', { value: ageText });
	}

	getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
		if (!params.node) {
			return;
		}
		if (FolderUtils.isFolder(params.node.data)) {
			return [];
		}
		const attribute = params.node.data as IExtendedAdminAttribute;
		let options: (string | MenuItemDef)[] = [];

		if (this.canManageDefaults() && (attribute.type === AttributeType.TEXT || attribute.type === AttributeType.NUMBER
				|| this.metricConstants.isWordAttribute(attribute.name))) {
			options.push({
				name: this.locale.getString('administration.editDefaults'),
				action: () => this.assetDefaultsService.editAttributeDefaults(attribute, this.project),
			});
		}
		options.push({
			name: attribute.hide ? this.locale.getString('common.show') : this.locale.getString('common.hide'),
			action: () => this.toggleHide(attribute),
		});
		options.push(this.objectListMenu.getAllHiddenOption(this.filterManager));

		if (attribute.name !== ClarabridgeMetricName.DOCUMENT_DATE) {
			options.push({
				name: this.locale.getString('common.dependencies'),
				action: () => this.openDependencies(attribute),
			});
		}
		return options;
	};

	private toggleHide(item: IAdminAttribute): void {
		item.hide = !item.hide;
		let key = this.getAttributeAnalyzeObjectKey(item, this.accountProject);
		this.securityApiService.hideObjectForUser(HiddenItemType.ATTRIBUTES, item.hide, key, item.displayName);
		this.security.getHiddenObject(HiddenItemType.ATTRIBUTES)[key] = !!item.hide;
		this.cachedHttpService.cache(Caches.ATTRIBUTES).invalidate();
		this.filterAndRedraw();
	}

	private filterAndRedraw(): void {
		this.gridApi?.onFilterChanged();
		this.gridApi?.redrawRows();
	}

	private openDependencies(attribute: IAdminAttribute): void {
		let input: DependenciesDialogInput<ProjectAsset> = {
			asset: {
				assetId: attribute.id,
				name: attribute.displayName ? attribute.displayName : attribute.name,
				contentProviderId: this.accountProject.contentProviderId,
				projectId: this.accountProject.projectId,
				accountId: this.accountProject.accountId
			} as ProjectAsset,
			dependencyTypesProvider: this.attributeDependencyTypesProvider
		};

		this.cxDialogService.openDialog(DependenciesModalComponent, input, { size: ModalSize.LARGE });
	}

	private getAttributeAnalyzeObjectKey(item, project: IProjectSelection): string {
		return [this.security.getMasterAccountId(), project.contentProviderId, project.accountId,
			project.projectId, item.id].join('-');
	}

	private canManageDefaults(): boolean {
		return this.project?.accessLevel === ProjectAccessLevelValue.MANAGER
			&& this.security.has(MasterAccountPermissionAction.MANAGE_PROJECTS);
	}

	getNameIcon = (data: IAdminAttributeTreeItem): string => {
		if (FolderUtils.isFolder(data))
			return;

		if (data.objectGroup === 'System') {
			return IconsMap.QUALTRICS_XM_ICON;
		}
		return;
	};

	private getGeographyOptions(attribute: IAdminAttribute): UIOption<BoundaryField>[] {
		return attribute.type === AttributeType.NUMBER
			? this.geographyOptionsService.getNumericAttributeGeographyOptions()
			: this.geographyOptionsService.getTextAttributeGeographyOptions();
	}

	private isGeographyEditable(data: IAdminAttributeTreeItem): boolean {
		return this.editMode && data.type !== AttributeType.DATE && !FolderUtils.isFolder(data);
	}

	private isVisibilityEditable(data: IAdminAttributeTreeItem): boolean {
		return this.editMode && !FolderUtils.isFolder(data);
	}

	// there is a lot going on here, need to change this to a single endpoint if possible
	saveChanges(): Promise<void> {
		const cpId = this.accountProject.contentProviderId;
		const accountId = this.accountProject.accountId;
		const projectId = this.accountProject.projectId;

		const attributeItems = this.attributes.filter(attr => !FolderUtils.isFolder(attr)) as IExtendedAdminAttribute[];

		// doc explorer and drill items just save all enabled (as they were initially implemented that way)
		const showInDocExpItems = this.attributes.filter(attr => !FolderUtils.isFolder(attr) && !attr.showInDocExp);
		const showInDrillItems = this.attributes.filter(attr => !FolderUtils.isFolder(attr) && !attr.showInDrill);

		// reporting flag is saved as "delta" (added/removed) for improved reliability and performance
		const showInReportingItemsChanges = ObjectListUtils.getFieldChanges(attributeItems, 'showInReporting');
		const hideInReportingItemsDelta = new ModificationDelta<IAsset>();
		showInReportingItemsChanges.forEach(attr => {
			if (attr.showInReporting) {
				hideInReportingItemsDelta.removed.push({assetId: attr.id, assetType: AssetType.ATTRIBUTE});
			} else {
				hideInReportingItemsDelta.added.push({assetId: attr.id, assetType: AssetType.ATTRIBUTE});
			}
		});

		// clara search saves only changes
		const useInClarabridgeSearchChanges: PropertyValue<boolean>[] = ObjectListUtils.getFieldChanges(attributeItems,
			'useInClarabridgeSearch')
			.map(attr => ({id: attr.id, value: attr.useInClarabridgeSearch}));

		// geography saves only changed values
		const geographyChanges: AttributeGeography[] = ObjectListUtils.getFieldChanges(attributeItems, 'boundaryField')
			.map(attr => ({attributeName: attr.name, boundaryField: attr.boundaryField}));

		const promises = [
			PromiseUtils.wrap(this.masterAccountApiService.updateDocExplorerAttributes(cpId, accountId, projectId, showInDocExpItems)),
			PromiseUtils.wrap(this.masterAccountApiService.updateDrillMenuAttributesAndModels(cpId, accountId, projectId, showInDrillItems)),
			hideInReportingItemsDelta.isEmpty() ? Promise.resolve()
				: this.assetAccessApiService.updateReportingAssetAccess(this.project, hideInReportingItemsDelta),
			useInClarabridgeSearchChanges.isEmpty() ? Promise.resolve()
				: this.attributeManagementService.saveAttributesSettings(this.project, useInClarabridgeSearchChanges),
			PromiseUtils.wrap(this.geographyApiService.saveAttributeGeographies(this.project, geographyChanges)),
		];
		return Promise.all(promises as any[]).then(() => {
			ObjectListUtils.clearDirty(this.attributes as IExtendedAdminAttribute[]);
		});
	}

	cancelChanges(): Promise<void> {
		if (!this.hasChanges()) {
			return Promise.resolve();
		} else {
			const dialogMessage = this.locale.getString('administration.projectPropertiesMsg');
			const dialogTitle = this.locale.getString('administration.projectPropertiesTitle');
			return this.cxDialogService.showUnsavedChangesDialog(dialogTitle, dialogMessage).then(result => {
				if (result) {
					return this.saveChanges();
				} else {
					this.loadAttributes();
					return Promise.resolve();
				}
			}, _.noop);
		}
	}

	private hasChanges(): boolean {
		return this.attributes.some(item => {
			if (FolderUtils.isFolder(item)) {
				return false;
			}
			return !!item._dirty;
		});
	}

	private isClarabridgeSearchLimitReached(): boolean {
		return this.attributes.filter((attr: IAdminAttribute) => attr.useInClarabridgeSearch).length >= SettingsState.ENABLED_FOR_SEARCH_LIMIT;
	}

	export(): Promise<unknown> {
		if (this.visibility === ProjectTabType.HIDDEN) {
			return this.assetAccessApiService.exportHiddenAssetGroupsInfo(this.project, ObjectType.ATTRIBUTE);
		} else {
			let visibleData: IExtendedAdminAttribute[] = [];
			this.gridApi.forEachNodeAfterFilter(node => {
				if (!FolderUtils.isFolder(node.data))
					visibleData.push(node.data);
			});
			return this.attributeManagementService.requestAttributesReport(this.project, this.filterManager.isShowHidden(),
				[], // TODO honor filters
				visibleData.map(attr => attr.id));
		}
	}

}
