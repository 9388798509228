import * as _ from 'underscore';
import { TreeService } from '@cxstudio/services/tree-service.service';
import { FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { NameService } from '@cxstudio/common/name-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { FilterItemType } from './entity/filter-item-type.enum';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { GeneratedFolderType } from './generated-folder-type';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { AttributesService } from '@app/modules/project/attribute/attributes.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { ProjectContextService } from '@app/modules/project/context/project-context.service';
import { ModelsService } from '@app/modules/project/model/models.service';
import { IReportModel } from '@app/modules/project/model/report-model';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { ObjectUtils } from '@app/util/object-utils';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { AccountOrWorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';

export interface IFilterUpdateResult {
	items: Array<IFilter|IFolderItem>|IFilter;
	shareAfterSave?: boolean;
	updated?: IFilter;
}

export class FilterUpdateService {

	constructor(
		private readonly treeService: TreeService,
		private readonly filterManagementApiService: FilterManagementApiService,
		private readonly $q: ng.IQService,
		private readonly nameService: NameService,
		private readonly locale: ILocale,
		private readonly FolderService,
		private readonly projectContextService: ProjectContextService,
		private readonly attributesService: AttributesService,
		private readonly modelsService: ModelsService,
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly downgradeDialogService: DowngradeDialogService
	) {}

	private getFoldersForFilter(filter: IFilter, filterList: any[]): any[] {
		let folderSvc = new this.FolderService(FolderTypes.FILTER);
		return folderSvc.getFoldersForMove(filterList, filter || {}, () => undefined, true)
			.map(folder => folder.obj);
	}

	private getDateFolder(filterList: any[]): IFolderItem {
		return _.findWhere(filterList, {id: FilterTypes.CXSTUDIO, type: FilterItemType.FOLDER, generatedFolderType: GeneratedFolderType.CUSTOM});
	}

	private filterModal(
		filterBuildingData: any,
		selectedProject: AccountOrWorkspaceProject,
		filterList: any[],
		filter: IFilter,
		editMode: boolean,
		folders: IFolderItem[],
		selectedFolder?: IFolderItem): any {

		filter = filter || {} as IFilter;
		folders = folders || [] as IFolderItem[];
		filterList = filterList || [];

		let project;
		if (filter && Object.keys(filter).length) {
			project = this.getFilterProject(filter);
		} else {
			project = ObjectUtils.copy(selectedProject);
		}
		const projectToCompare = _.omit(project, 'projectName');

		return this.downgradeDialogService.openFilterCreationDialog({
			filterBuildingData,
			project,
			currentList: filterList.filter(oneFilter => {
				return !/.*folder.*/i.test(oneFilter.type) &&
					_.isEqual(projectToCompare, this.getFilterProject(oneFilter));
			}),
			filter,
			editMode,
			folders,
			currentFolder: (() => {
				if (selectedFolder)
					return selectedFolder;
				return filter && filterList.find(aFilter => aFilter.id === filter.parentId);
			})()
		});
	}

	private getFilterProject(filter: IFilter): AccountOrWorkspaceProject {
		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE)) {
			return filter.workspaceProject;
		} else {
			return {
				contentProviderId: filter.contentProviderId,
				accountId: filter.accountId,
				projectId: filter.projectId
			};
		}
	}

	create = (
		filterBuildingData: any,
		project: AccountOrWorkspaceProjectData,
		filterList: any[],
		folder?: IFolderItem): ng.IPromise<any> => {

		let folders = this.getFoldersForFilter(undefined, filterList);
		let modal = this.filterModal(filterBuildingData, project, filterList, null, true, folders, folder);
		return modal.result.then(result => {
			let definition = result.result;
			let shareAfterSave = result.doShare;
			const projectData = WorkspaceTransitionUtils.getProjectData(project);
			let data = _.extend(projectData, {
				name: definition.name,
				rule: definition.rule,
				parentId: definition.parentId,
				labels: definition.labels
			});
			return this.filterManagementApiService.createFilter(data).then((filter) => {
				delete filter.rule;

				if (filter.dateFilter) {
					let dateFolder = this.getDateFolder(filterList);
					this.treeService.addItem(filterList, filter, dateFolder);
					return {items: [filter, dateFolder], shareAfterSave};
				} else {
					let targetFolder = filterList.find(aFilter => {
						return aFilter.id === filter.parentId;
					});
					this.treeService.addItem(filterList, filter, targetFolder);
					return {items: filter, shareAfterSave};
				}
			});
		})
			.catch();
	};

	copy = (filter: IFilter, filterList: any[]): ng.IPromise<any> => {
		let filterCopy = angular.copy(filter);
		filterCopy.name = this.nameService.uniqueName(filter.name + ' - ' + this.locale.getString('dashboard.copyName'), filterList, 'name');
		(filterCopy as any).hide = false;

		return this.filterManagementApiService.duplicateFilter(filterCopy).then(createdFilter => {
			delete createdFilter.rule;
			if (createdFilter.dateFilter) {
				let dateFolder = this.getDateFolder(filterList);
				this.treeService.addItem(filterList, createdFilter, dateFolder);
				return { items: [createdFilter, dateFolder], updated: createdFilter };
			} else if (createdFilter.parentId) {
				let targetFolder = _.findWhere(filterList, {id: createdFilter.parentId});
				this.treeService.addItem(filterList, createdFilter, targetFolder);
				return { items: [createdFilter, targetFolder], updated: createdFilter };
			} else {
				this.treeService.addItem(filterList, createdFilter);
				return { items: [createdFilter], updated: createdFilter };
			}
		});
	};

	edit = (filter: IFilter,
		editMode: boolean,
		filterBuildingData: any,
		filterList?: any[],
		folders?: IFolderItem[]
	): ng.IPromise<IFilterUpdateResult> => {

		let isDateFilter = filter.dateFilter;
		let previousFolder = filter.parentId;
		let parent = filter.parent;

		let project = this.getFilterProject(filter);
		return this.$q.all([
			this.getFilter(filter),
			this.getFilterableAttributes(project),
			this.getModels(project),
			this.getTimezone(project),
			PromiseUtils.old(this.projectContextService.getProjectContext(project)),
			PromiseUtils.old(this.attributesService.getWordAttributes(project))
		]).then(result => {
			filterBuildingData = {
				attributes: result[1],
				models: result[2],
				internalProject: InternalProjectTypes.isStudioAdminProject(project.projectId),
				projectTimezone: result[3],
				projectContext: result[4],
				wordAttributes: result[5],
				customDateRanges: filterBuildingData.customDateRanges,
				viewMode: filterBuildingData.viewMode,
				canShare: filterBuildingData.canShare,
			};

			let modal = this.filterModal(filterBuildingData, project, filterList, result[0], editMode, folders);

			return modal.result.then(modalResult => {
				let update = modalResult.result;
				let shareAfterSave = modalResult.doShare;
				let retainEditPermission = modalResult.retainEditPermission || false;

				if (filter.sharingStatus === SharingStatus.PRIVATE && retainEditPermission) {
					filter.sharingStatus = SharingStatus.SHARED;
				}

				let updatedFilterData = $.extend(angular.copy(filter), update);
				return this.filterManagementApiService.updateFilter(filter.id, updatedFilterData, retainEditPermission)
					.then(updatedFilter => {
						delete updatedFilter.rule;
						delete filter.rule;
						filter = $.extend(filter, updatedFilter);
						filterList = filterList || [];

						if (modalResult.removeFilter) {
							this.treeService.deleteItem(filterList, filter);
							return {items: filter, shareAfterSave};
						}
						this.treeService.updateItem(filterList, filter);
						if (filter.dateFilter !== isDateFilter) {
							let dateFolder = this.getDateFolder(filterList);
							this.treeService.moveItem(filterList, filter, filter.dateFilter ? dateFolder : undefined);
							return {items: [parent, filter, dateFolder], shareAfterSave};
						} else if (previousFolder !== updatedFilter.parentId) {
							let targetFolder = !updatedFilter.parentId ? undefined
								: filterList.find(aFilter => aFilter.id === updatedFilter.parentId);
							this.treeService.moveItem(filterList, filter, targetFolder);
						}
						return {items: filter, shareAfterSave};
					});
			}).catch();
		});
	};

	private getTimezone(project: AccountOrWorkspaceProject): ng.IPromise<string|number> {
		return InternalProjectTypes.isStudioAdminProject(project.projectId)
			? this.$q.when(0)
			: PromiseUtils.old(this.projectContextService.getProjectTimezone(project));
	}

	private getFilter(filter: IFilter): ng.IPromise<IFilter> {
		if (filter.type === FilterTypes.CXSCORECARD) {
			return this.$q.when(filter);
		}
		return this.filterManagementApiService.getFilter(filter.id);
	}

	getFilterableAttributes = (project: AccountOrWorkspaceProject): ng.IPromise<IReportAttribute[]> => {
		let result = PromiseUtils.old(this.attributesService.getAttributes(project));
		if (InternalProjectTypes.isAdminProject(project.projectId)) {
			result = result.then(attributes => attributes.filter(attr => attr.type === 'TEXT'));
		}
		return result;
	};

	getModels = (project: AccountOrWorkspaceProject): ng.IPromise<IReportModel[]> => {
		return PromiseUtils.old(this.modelsService.getModelsWithHierarchies(project));
	};
}

app.service('filterUpdateService', FilterUpdateService);
