import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { HighchartsCasesDefinitionService } from '@app/modules/widget-visualizations/highcharts/highcharts-dual-with-cases/highcharts-cases-definition.service';
import { HighchartsDualDefinitionService } from '@app/modules/widget-visualizations/highcharts/highcharts-dual/highcharts-dual-definition.service';
import { HighchartsRendererService } from '@app/modules/widget-visualizations/highcharts/highcharts-renderer.service';
import { HighchartsVisualizationBaseComponent } from '@app/modules/widget-visualizations/highcharts/highcharts-visualization-base.class';
import { VisualizationDataService } from '@app/modules/widget-visualizations/visualization-data.service';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { Security } from '@cxstudio/auth/security-service';
import { DateInterval } from '@app/modules/plot-lines/reference-lines/point-metadata-utils';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { ChartValidator } from '@cxstudio/reports/visualizations/chart-validator.service';
import { Chart, YAxisOptions, YAxisTitleOptions } from 'highcharts';
import { Subscription } from 'rxjs';
import { ElementResizer } from '../../element-resizer';
import { WidgetContentEvent, WidgetContentEventType } from '../../visualization-component.interface';
import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';

@Component({
	selector: 'highcharts-dual-with-cases',
	template: `
	<div #mainContainer [hidden]="hasError()" class="w-100-percent h-100-percent p-absolute">
		<div #topChart class="p-absolute top-chart w-100-percent p-8 kb-focusable"
			[ngClass]="!casesError ? 'overflow-y-auto' : 'overflow-y-hidden'"
			[style.height]="resizerState.positionPercent + '%'"
			tabindex="0"
			(keydown)="onTopSectionKeydown($event)">
			<div *ngIf="!casesError" #casesContainerWrapper class="casesContainerWrapper">
				<figure #casesContainer class="w-100-percent h-100-percent"></figure>
			</div>
			<div *ngIf="casesError" class="w-100-percent h-100-percent d-flex flex-direction-column justify-center">
				<div class="d-flex justify-center mb-8">
					<span>{{casesError}}</span>
				</div>
				<div *ngIf="showButtons && !demo" class="d-flex justify-center">
					<button
						class="btn btn-primary"
						(click)="openAdvancedWidgetSettings()">
						{{'cases.addCases'|i18n}}
					</button>
					<button
						class="btn btn-secondary"
						(click)="disableCasesVisualization()">
						{{'cases.disableView'|i18n}}
					</button>
				</div>
			</div>
		</div>
		<div class="p-absolute bottom-chart w-100-percent p-8 kb-focusable"
			[style.height]="'calc(100% - ' + resizerState.positionPercent + '%)'"
			tabindex="0"
			(keydown)="onBottomSectionKeydown($event)">
			<div #dualContainerWrapper class="dualContainerWrapper">
				<figure #dualContainer class="w-100-percent h-100-percent"></figure>
			</div>
		</div>
		<div #dragLine
			class="resizer"
			[style.top]="'calc(' + resizerState.positionPercent + '% - 2px)'"
			[class.action-border]="resizerState.dragging"
			[class.border-gray-400]="!resizerState.dragging"
			cdkDrag
			cdkDragLockAxis="y"
			[cdkDragBoundary]="mainContainer"
			(cdkDragEnded)="onResizerMoved($event)"
			(mousedown)="resizerState.dragging = true"
			(mouseup)="resizerState.dragging = false">
		</div>
	</div>
	<widget-error *ngIf="hasError()" [widgetError]="widgetError" [demo]="demo"></widget-error>
	`,
	styles: [`
		.top-chart {
			top: 0;
			margin-bottom: 10px;
		}
		.bottom-chart {
			bottom: 0;
			margin-top: 10px;
		}
		.casesContainerWrapper, .dualContainerWrapper {
			height: 100%;
			width: 100%;
		}
		.resizer {
			position: absolute;
			height: 0;
			left: 0;
			right: 0;
			border-style: solid;
			cursor: ns-resize;
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HighchartsDualWithCasesComponent extends HighchartsVisualizationBaseComponent
	implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('mainContainer') mainContainer: ElementRef<HTMLElement>;
	@ViewChild('dualContainer') dualContainer: ElementRef<HTMLElement>;
	@ViewChild('casesContainer') casesContainer: ElementRef<HTMLElement>;
	@ViewChild('dragLine') dragLine: ElementRef<HTMLElement>;

	@ViewChild('topChart') topChart: ElementRef<HTMLElement>;

	@ViewChild('casesContainerWrapper') casesContainerWrapper: ElementRef<HTMLElement>;
	@ViewChild('dualContainerWrapper') dualContainerWrapper: ElementRef<HTMLElement>;

	private casesContainerResizeObserver: ResizeObserver;

	errorMessage: string;

	dualChart: Chart;
	dualOptions: Highcharts.Options;
	casesChart: Chart;
	casesOptions: Highcharts.Options;

	resizerState = {
		dragging: false,
		positionPercent: 50
	};

	casesError: string;
	editModeChangeSubscription: Subscription;
	showButtons: boolean;

	constructor(
		ref: ChangeDetectorRef,
		private visualizationDataService: VisualizationDataService,
		private highchartsDualDefinition: HighchartsDualDefinitionService,
		private highchartsCasesDefinition: HighchartsCasesDefinitionService,
		private highchartsRenderer: HighchartsRendererService,
		private currentObjects: CurrentObjectsService,
		private locale: CxLocaleService,
		@Inject('reportUtils') reportUtils: ReportUtils,
		@Inject('chartValidator') private chartValidator: ChartValidator,
		@Inject('security') private security: Security,
	) {
		super(ref, reportUtils);
	}

	ngOnInit() {
		this.prepareChartOptions();

		this.showButtons = this.currentObjects.isEditMode() && this.isWidgetOwner();

		this.editModeChangeSubscription = this.currentObjects.getEditModeChange().subscribe(editMode => {
			this.showButtons = editMode && this.isWidgetOwner();
			this.ref.detectChanges();
		});
	}

	private isWidgetOwner(): boolean {
		return this.security.isCurrentUser(this.widget.properties.runAs);
	}

	ngAfterViewInit(): void {
		this.initChartResizer(this.dualContainer, () => this.dualChart);

		if (this.casesContainer) {
			this.initChartResizer(this.casesContainer, () => this.casesChart);
			this.initCasesContainerResizer(this.casesContainerWrapper, this.dualContainerWrapper, this.topChart, () => this.casesOptions);
		}

		this.renderChart();
	}

	initCasesContainerResizer(casesContainerWrapper: ElementRef, dualContainerWrapper: ElementRef, topChart: ElementRef,
		optionsProvider: () => Highcharts.Options): void {
		let resizer = new ElementResizer(casesContainerWrapper.nativeElement,
			() => this.onCasesContainerResize(
				casesContainerWrapper.nativeElement, dualContainerWrapper.nativeElement, topChart.nativeElement, optionsProvider()));
		let throttledResize = _.throttle(() => {
			resizer.resize();
			this.updateCasesChartMargins();
		}, 200);
		this.casesContainerResizeObserver = new ResizeObserver(throttledResize);
		this.casesContainerResizeObserver.observe(casesContainerWrapper.nativeElement);
	}

	protected onCasesContainerResize(casesContainerElement: HTMLElement, dualContainerElement: HTMLElement, topChartElement: HTMLElement,
		chartOptions: Highcharts.Options): void {
		let dateRanges: DateInterval[] = this.highchartsCasesDefinition.getDateRanges(this.dataObject,
			this.widget.visualProperties, this.utils, this.demo);
		this.reportUtils.handleCasesContainerResize(casesContainerElement, dualContainerElement, topChartElement, chartOptions, dateRanges);
	}

	ngOnDestroy(): void {
		this.editModeChangeSubscription?.unsubscribe();

		if (this.casesContainerResizeObserver) {
			this.casesContainerResizeObserver.disconnect();
			delete this.casesContainerResizeObserver;
		}

		super.ngOnDestroy();
	}

	openAdvancedWidgetSettings(): void {
		this.contentEvent.emit({type: WidgetContentEventType.OPEN_ADVANCED_SETTINGS});
	}

	disableCasesVisualization(): void {
		this.contentEvent.emit({type: WidgetContentEventType.DISABLE_CASES_VISUALIZATION});
	}

	private prepareChartOptions(): void {
		if (this.checkError(this.visualizationDataService.validateData(this.dataObject, this.widget.name)))
			return;
		let chartOptions = this.highchartsDualDefinition.getChartOptions(this.dataObject, this.widget.visualProperties,
			this.utils, this, this.demo);

		let validationMessage = this.chartValidator.getValidationMessage(chartOptions.series as any,
			this.dataObject.data, this.widget.visualProperties);
		if (this.checkError(validationMessage))
			return;

		this.dualOptions = chartOptions;

		if (!this.highchartsCasesDefinition.hasAvailableSelectedCases(this.dataObject, this.widget.visualProperties, this.utils, this.demo)) {
			this.casesError = this.utils.engagorCases === undefined
				? this.locale.getString('cases.casesLoadingError')
				: this.locale.getString('cases.noSelectedCasesAvailable');
		}
		this.casesOptions = this.highchartsCasesDefinition.getChartOptions(this.dataObject, this.widget.visualProperties,
			this.utils, this.demo);
	}

	private renderChart() {
		this.clearChart();
		if (this.dualOptions) {
			this.dualChart = this.highchartsRenderer
				.renderChart(this.dualOptions as unknown, this.dualContainer.nativeElement,
					this.highchartsRenderer.compositeCallback(
						this.highchartsRenderer.pointHighlightingCallback(this.utils, this.demo),
						this.highchartsCasesDefinition.synchronizeCrosshairsCallback(() => this.dualChart, () => this.casesChart)));
		}

		setTimeout(() => {
			if (this.casesOptions && this.dualChart && !this.casesError) {
				let casesOptionsYAxis: YAxisOptions = this.getYAxis(this.casesOptions);

				let casesOptionsYAxisTitle: YAxisTitleOptions = casesOptionsYAxis?.title;
				if (casesOptionsYAxisTitle) {
					casesOptionsYAxisTitle.x = this.calculateCasesChartTitleX();
				}

				this.casesOptions.chart.marginLeft = this.dualChart.plotLeft;
				this.casesOptions.chart.marginRight = this.dualChart.chartWidth - this.dualChart.plotWidth - this.dualChart.plotLeft;

				this.casesChart = this.highchartsRenderer
					.renderChart(this.casesOptions as unknown, this.casesContainer.nativeElement,
						this.highchartsCasesDefinition.synchronizeCrosshairsCallback(() => this.dualChart, () => this.casesChart));
			}
		});
	}

	private updateCasesChartMargins() {
		if (this.casesChart && this.dualChart && !this.casesError) {
			this.casesChart.update({
				chart: {
					marginLeft: this.dualChart.plotLeft,
					marginRight: this.dualChart.chartWidth - this.dualChart.plotWidth - this.dualChart.plotLeft
				}
			});
		}
	}

	private calculateCasesChartTitleX() {
		let dualChartYAxis = this.getYAxis(this.dualChart);
		return dualChartYAxis.options.labels.x - dualChartYAxis.labelOffset;
	}

	private getYAxis(dataObject: Chart|Highcharts.Options): any {
		let yAxis = dataObject.yAxis;
		return _.isArray(yAxis) ? yAxis[0] : yAxis;
	}

	onResizerMoved(event: CdkDragEnd) {
		this.resizerState.positionPercent += event.distance.y / this.mainContainer.nativeElement.offsetHeight * 100;
		if (this.resizerState.positionPercent < 0)
			this.resizerState.positionPercent = 0;
		else if (this.resizerState.positionPercent > 100)
			this.resizerState.positionPercent = 100;
		event.source._dragRef.reset();
		this.resizerState.dragging = false;
	}

	protected onResize(chart: Chart, currentSize: string, lastSize: string) {
		this.reportUtils.handleChartResize(chart, currentSize, lastSize);
	}

	protected clearChart(): void {
		if (this.casesChart?.container)
			this.casesChart.destroy();
		delete this.casesChart;
		if (this.dualChart?.container)
			this.dualChart.destroy();
		delete this.dualChart;
	}

	onTopSectionKeydown(event: KeyboardEvent): void {
		const sectionElement: HTMLElement = $(this.mainContainer.nativeElement).find('.top-chart').first().get(0);
		if (KeyboardUtils.isEventKey(event, Key.TAB)) {
			KeyboardUtils.intercept(event);
			this.dualContainerWrapper.nativeElement.parentElement.focus();
		} else if (KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)) {
			KeyboardUtils.intercept(event);
			//no actions required
		} else {
			this.onSectionKeydown(event, sectionElement);
		}
	}

	onBottomSectionKeydown(event: KeyboardEvent): void {
		const sectionElement: HTMLElement = $(this.mainContainer.nativeElement).find('.bottom-chart').first().get(0);
		if (KeyboardUtils.isEventKey(event, Key.TAB)) {
			const focusableElements = $(sectionElement).parents('.br-widget-box').find(`:focusable`);
			const lastFocusableElement = focusableElements.last().get(0);
			lastFocusableElement.focus();
			//move to next widget is handled in widget-item
		} else if (KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)) {
			KeyboardUtils.intercept(event);
			this.topChart.nativeElement.focus();
		} else {
			this.onSectionKeydown(event, sectionElement);
		}
	}

	onSectionKeydown(event: KeyboardEvent, sectionElement: HTMLElement): void {
		const activeElement = document.activeElement;
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			if (activeElement === sectionElement) {
				//whole widget focus is handled in widget-item
			} else {
				KeyboardUtils.intercept(event);
				sectionElement.focus();
			}
		} else if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			KeyboardUtils.intercept(event);
			const focusableElements = $(sectionElement).find(`:focusable`);
			const firstFocusableElement = focusableElements.first().get(0);
			firstFocusableElement.focus();
		}
	}
}
