import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ColorArrayComponent } from '@app/shared/components/color-array.component';
import { CbColorSketchComponent } from '@app/shared/components/color-picker/responsive-color-picker/cb-color-sketch/cb-color-sketch.component';
import { ColorPickerColorsComponent } from '@app/shared/components/color-picker/responsive-color-picker/color-picker-colors/color-picker-colors.component';
import { ColorPickerPalettesComponent } from '@app/shared/components/color-picker/responsive-color-picker/color-picker-palettes/color-picker-palettes.component';
import { ResponsiveColorPickerComponent } from '@app/shared/components/color-picker/responsive-color-picker/responsive-color-picker.component';
import { DatePickerPopupComponent } from '@app/shared/components/date-picker/date-picker-popup.component';
import { DateRangePickerPopupComponent } from '@app/shared/components/date-picker/date-range-picker-popup.component';
import { CbDiscussionCommentListComponent } from '@app/shared/components/discussions/cb-discussion-comment-list.component';
import { FilterLaneComponent } from '@app/shared/components/filter/filter-lane.component';
import { FilterLanesComponent } from '@app/shared/components/filter/filter-lanes.component';
import { ObjectListFilterComponent } from '@app/shared/components/filter/object-list-filter.component';
import { CheckboxButtonComponent } from '@app/shared/components/forms/checkbox-button.component';
import { ClearableInputComponent } from '@app/shared/components/forms/clearable-input.component';
import { CheckboxDropdownComponent } from '@app/shared/components/forms/dropdown/checkbox-dropdown.component';
import { SimpleDropdownComponent } from '@app/shared/components/forms/dropdown/simple-dropdown.component';
import { InputWithDefaultComponent } from '@app/shared/components/forms/input-with-default.component';
import { PercentInputComponent } from '@app/shared/components/forms/percent-input.component';
import { SearchListComponent } from '@app/shared/components/forms/lists/search-list.component';
import { PrioritySelectionComponent } from '@app/shared/components/forms/priority-selection.component';
import { RadioButtonsComponent } from '@app/shared/components/forms/radio-buttons.component';
import { TagsInputComponent } from '@app/shared/components/forms/tags-input.component';
import { SearchableTreeComponent } from '@app/shared/components/forms/tree/searchable-tree.component';
import { SelectFromTreeComponent } from '@app/shared/components/forms/tree/select-from-tree.component';
import { SubtreeComponent } from '@app/shared/components/forms/tree/subtree.component';
import { TreeItemTemplateComponent } from '@app/shared/components/forms/tree/tree-item-template.component';
import { TreeNodeComponent } from '@app/shared/components/forms/tree/tree-node.component';
import { TreeSearchPipe } from '@app/shared/components/forms/tree/tree-search.pipe';
import { TreeSortPipe } from '@app/shared/components/forms/tree/tree-sort.pipe';
import { LoaderComponent } from '@app/shared/components/loader.component';
import { PaginationComponent } from '@app/shared/components/pagination/pagination.component';
import { SpineHeaderComponent } from '@app/shared/components/spine/spine-header.component';
import { CollapsingPanelComponent } from '@app/shared/components/structural/collapsing-panel.component';
import { CollapsingSectionComponent } from '@app/shared/components/structural/collapsing-section.component';
import { FormattedTableComponent } from '@app/shared/components/table/formatted-table.component';
import { TableMenuComponent } from '@app/shared/components/table/table-menu.component';
import { TextWithHelpComponent } from '@app/shared/components/text-with-help.component';
import { TreeSelectionComponent } from '@app/shared/components/tree-selection/tree-selection.component';
import { CheckboxIndeterminateDirective } from '@app/shared/directives/checkbox-indeterminate.directive';
import { ColorSelectorUpgrade } from '@app/shared/directives/color-selector-upgrade.directive';
import { DetectScrollOptionsDirective } from '@app/shared/directives/detect-scroll-options.directive';
import { LimitedDecimalDirective } from '@app/shared/directives/limited-decimal.directive';
import { MaxDirective } from '@app/shared/directives/max.directive';
import { MinDirective } from '@app/shared/directives/min.directive';
import { StrictMinDirective } from '@app/shared/directives/strict-min.directive';
import { StrictMaxDirective } from '@app/shared/directives/strict-max.directive';
import { MenuDirectivesModule } from '@app/shared/menu/menu-directives.module';
import { ExcludeValuePipe } from '@app/shared/pipes/exclude-value.pipe';
import { InArrayPipe } from '@app/shared/pipes/in-array.pipe';
import { NegatePipe } from '@app/shared/pipes/negate.pipe';
import { WeightedSortPipe } from '@app/shared/pipes/weighted-sort.pipe';
import { RouteParamsProvider } from '@app/shared/providers/route-params-provider';
import { ScopeProvider } from '@app/shared/providers/scope-provider';
import {
	AccountSwitcherComponent, AccountSwitcherModule, DragHandleComponent, DragHandleModule,
	InlineHelpComponent, NoticeComponent, SidebarNavModule, GenericRadioButtonsModule, RadioGroupComponent,
	UnifiedAngularComponentsModule, ToggleComponent,
	SpacesComponent, ExpandingInputComponent
} from '@discover/unified-angular-components/dist/unified-angular-components';
import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BusyConfig, NgBusyModule } from 'ng-busy';
import { FileUploadModule } from 'ng2-file-upload';
import { ColorSketchModule } from 'ngx-color/sketch';
import { NgPipesModule } from 'ngx-pipes';
import { AlertComponent } from './components/alert.component';
import { DragHandleDowngradeComponent } from './components/cb-drag-handle.component';
import { CharactersLeftCounterComponent } from './components/characters-left-counter.component';
import { ColorDropdownComponent } from './components/color-picker/color-dropdown/color-dropdown.component';
import { ColorInputComponent } from './components/color-picker/color-input/color-input.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { CbCommentFormatBarComponent } from './components/discussions/cb-comment-format-bar.component';
import { CbDiscussionCommentComponent } from './components/discussions/cb-discussion-comment.component';
import { DropdownCollapsibleToggleOptionComponent } from './components/dropdown-collapsible-toggle-option/dropdown-collapsible-toggle-option.component';
import { DropdownToggleOptionComponent } from './components/dropdown-toggle-option/dropdown-toggle-option.component';
import { DatePointComponent } from './components/filter/date-point/date-point.component';
import { DateRangeComponent } from './components/filter/date-range/date-range.component';
import { FontSelectorComponent } from './components/font-selector/font-selector.component';
import { CheckboxButtonsComponent } from './components/forms/checkbox-buttons.component';
import { InclusionListComponent } from './components/inclusions-list/inclusion-list.component';
import { KebabMenuComponent } from './components/kebab-menu/kebab-menu.component';
import { LabeledToggleSwitchComponent } from './components/labeled-toggle-switch/labeled-toggle-switch.component';
// downgrades from unified components library
import './components/library-downgrades';
import { ProjectSelectorComponent } from './components/project-selector/project-selector.component';
import { RefreshTriggerComponent } from './components/refresh-trigger.component';
import { ShowMoreComponent } from './components/show-more/show-more.component';
import { SimpleTableComponent } from './components/simple-table/simple-table.component';
import { ObjectListSortComponent } from './components/sort/object-list-sort.component';
import { SortLaneComponent } from './components/sort/sort-lane.component';
import { SortLanesComponent } from './components/sort/sort-lanes.component';
import { FormattedTableColumnsOauthClientsService } from './components/table/formatted-table-columns-oauth-clients.service';
import { FormattedTableColumnsService } from './components/table/formatted-table-columns.service';
import { ToggleListComponent } from './components/toggle-list/toggle-list.component';
import { ToggleLockComponent } from './components/toggle-lock.component';
import { SearchableHierarchyUpgradeDirective } from './components/upgrades/searchable-hierarchy-upgrade.directive';
import { BtnLoadingDirective } from './directives/btn-loading.directive';
import { ContextMenuOptionUpgrade } from './directives/context-menu-option-upgrade.directive';
import { CxKeyboardScrollDirective } from './directives/cx-keyboard-scroll.directive';
import { DebounceDirective } from './directives/debounce.directive';
import { PwCheckDirective } from './directives/pw-check.directive';
import { ScrollingModalDirective } from './directives/scrolling-modal.directive';
import { SimpleShareTableUpgrade } from './directives/simple-share-table-upgrade.directive';
import { CapitalizeFirstLetterPipe } from './pipes/capitalize-first-letter.pipe';
import { EscapeHtmlPipe } from './pipes/escape-html.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { HighlightMatchPipe } from './pipes/highlight-match.pipe';
import { MiddleEllipsisPipe } from './pipes/middle-ellipsis.pipe';
import { NameFilterPipe } from './pipes/name-filter.pipe';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';
import { UnderscoreToSpacePipe } from './pipes/underscore-to-space.pipe';
import { UserTimeFormatPipe } from './pipes/user-time-format.pipe';
import { WeightedFilterPipe } from './pipes/weighted-filter.pipe';
import { BulkService } from './services/bulk.service';
import { ClipboardService } from './services/clipboard.service';
import { CurrentObjectsService } from './services/current-objects-service';
import { DevToolsService } from './services/dev-tools.service';
import { SanitizePipe } from './pipes/sanitize.pipe';
import { OrientationComponent } from '@app/shared/components/forms/orientation.component';
import { DisabledToggleComponent } from '@app/shared/components/forms/disabled-toggle.component';
import { ReportTextFilterComponent } from './components/filter/report-text-filter/report-text-filter.component';
import { WordsSelectionUpgrade } from './directives/words-selection.upgrade.directive';
import { ValueRequiredDirective } from './directives/value-required.directive';
import { PageContentHeaderComponent } from '@app/shared/components/page-content-header/page-content-header.component';
import { TruncateNamesListPipe } from './pipes/truncate-names-list.pipe';
import { TabKeyHandlerDirective } from './directives/tab-keyboard-handling.directive';
import { EmptyComponent } from './components/empty-component.directive';
import { TrimDirective } from './directives/trim.directive';
import { SplitButtonMenuComponent } from './components/split-button-menu/split-button-menu.component';
import { ColorSwatchComponent } from './components/color-swatch/color-swatch.component';
import { SelectedColorSwatchComponent } from './components/selected-color-swatch/selected-color-swatch.component';
import { DescriptionComponent } from './components/description/description.component';
import { PaginationCustomLastButtonComponent } from './components/pagination/pagination-custom-last-button/pagination-custom-last-button.component';
import { IconModule } from '@app/modules/icon/icon.module';
import { CxFormModule } from '@app/modules/cx-form/cx-form.module';
import { I18nModule } from '@app/shared/i18n/i18n.module';
import { MenuSubsectionComponent } from './menu/menu-subsection/menu-subsection.component';
import { SupportPostLinkComponent } from '@app/shared/components/support-post-link/support-post-link.component';
import { SubmenuFlyoutComponent } from './menu/submenu-flyout/submenu-flyout.component';

@NgModule({
	imports: [
		CommonModule,
		NgbModule,
		FormsModule,
		DragDropModule,
		NgBusyModule.forRoot(new BusyConfig({
			template: LoaderComponent
		})),
		ColorSketchModule,
		SidebarNavModule,
		DragHandleModule,
		AccountSwitcherModule,
		GenericRadioButtonsModule,
		UnifiedAngularComponentsModule,
		NgPipesModule,
		ColorPickerModule,
		MenuDirectivesModule,
		IconModule,
		CxFormModule,
		I18nModule,
	],
	exports: [
		CommonModule,
		NgbModule,
		NgBusyModule,
		FormsModule,
		FileUploadModule,
		AlertComponent,
		InputWithDefaultComponent,
		PercentInputComponent,
		ClearableInputComponent,
		DatePickerPopupComponent,
		DateRangePickerPopupComponent,
		DatePointComponent,
		DateRangeComponent,
		InlineHelpComponent,
		TextWithHelpComponent,
		ObjectListFilterComponent,
		ObjectListSortComponent,
		FormattedTableComponent,
		PaginationComponent,
		PrioritySelectionComponent,
		ProjectSelectorComponent,
		RadioButtonsComponent,
		RefreshTriggerComponent,
		ReportTextFilterComponent,
		SearchableTreeComponent,
		SelectFromTreeComponent,
		TagsInputComponent,
		CollapsingPanelComponent,
		CollapsingSectionComponent,
		CheckboxButtonComponent,
		DropdownToggleOptionComponent,
		DropdownCollapsibleToggleOptionComponent,
		LabeledToggleSwitchComponent,
		SearchListComponent,
		CheckboxDropdownComponent,
		SimpleDropdownComponent,
		SpineHeaderComponent,
		ResponsiveColorPickerComponent,
		BtnLoadingDirective,
		CheckboxIndeterminateDirective,
		DebounceDirective,
		DetectScrollOptionsDirective,
		MaxDirective,
		MinDirective,
		PwCheckDirective,
		StrictMinDirective,
		StrictMaxDirective,
		ValueRequiredDirective,
		LimitedDecimalDirective,
		NameFilterPipe,
		WeightedFilterPipe,
		InArrayPipe,
		NegatePipe,
		FilterPipe,
		UserTimeFormatPipe,
		ExcludeValuePipe,
		CxKeyboardScrollDirective,
		ColorSelectorUpgrade,
		ContextMenuOptionUpgrade,
		CbCommentFormatBarComponent,
		CbDiscussionCommentComponent,
		CbDiscussionCommentListComponent,
		ColorInputComponent,
		ColorDropdownComponent,
		ToggleLockComponent,
		TreeSelectionComponent,
		DragHandleDowngradeComponent,
		HighlightMatchPipe,
		SidebarNavModule,
		SafeHTMLPipe,
		EscapeHtmlPipe,
		SanitizePipe,
		DatePickerComponent,
		DragHandleModule,
		FontSelectorComponent,
		KebabMenuComponent,
		CharactersLeftCounterComponent,
		AccountSwitcherModule,
		GenericRadioButtonsModule,
		InclusionListComponent,
		ToggleListComponent,
		SimpleTableComponent,
		SimpleShareTableUpgrade,
		MiddleEllipsisPipe,
		CheckboxButtonsComponent,
		ScrollingModalDirective,
		NoticeComponent,
		SearchableHierarchyUpgradeDirective,
		UnderscoreToSpacePipe,
		CapitalizeFirstLetterPipe,
		SearchableTreeComponent,
		OrientationComponent,
		DisabledToggleComponent,
		ToggleComponent,
		WordsSelectionUpgrade,
		PageContentHeaderComponent,
		TruncateNamesListPipe,
		TabKeyHandlerDirective,
		EmptyComponent,
		TrimDirective,
		SplitButtonMenuComponent,
		ColorSwatchComponent,
		SelectedColorSwatchComponent,
		DescriptionComponent,
		PaginationCustomLastButtonComponent,
		I18nModule,
		MenuSubsectionComponent,
		SupportPostLinkComponent,
		SubmenuFlyoutComponent,
	],
	declarations: [
		AlertComponent,
		InputWithDefaultComponent,
		PercentInputComponent,
		ClearableInputComponent,
		TextWithHelpComponent,
		LoaderComponent,
		ObjectListFilterComponent,
		ObjectListSortComponent,
		FilterLaneComponent,
		FilterLanesComponent,
		DatePickerPopupComponent,
		DateRangePickerPopupComponent,
		DatePointComponent,
		DateRangeComponent,
		SortLaneComponent,
		SortLanesComponent,
		FormattedTableComponent,
		TableMenuComponent,
		PaginationComponent,
		PrioritySelectionComponent,
		ProjectSelectorComponent,
		RadioButtonsComponent,
		RefreshTriggerComponent,
		ReportTextFilterComponent,
		SearchableTreeComponent,
		SelectFromTreeComponent,
		SubtreeComponent,
		TreeItemTemplateComponent,
		TreeNodeComponent,
		TreeSearchPipe,
		TreeSortPipe,
		TagsInputComponent,
		CollapsingPanelComponent,
		CollapsingSectionComponent,
		CheckboxButtonComponent,
		SearchListComponent,
		CheckboxDropdownComponent,
		SimpleDropdownComponent,
		SpineHeaderComponent,
		ColorArrayComponent,
		ResponsiveColorPickerComponent,
		BtnLoadingDirective,
		CheckboxIndeterminateDirective,
		DebounceDirective,
		DetectScrollOptionsDirective,
		MaxDirective,
		MinDirective,
		PwCheckDirective,
		StrictMinDirective,
		StrictMaxDirective,
		ValueRequiredDirective,
		LimitedDecimalDirective,
		NameFilterPipe,
		WeightedFilterPipe,
		WeightedSortPipe,
		InArrayPipe,
		NegatePipe,
		FilterPipe,
		UserTimeFormatPipe,
		ExcludeValuePipe,
		CxKeyboardScrollDirective,
		ColorSelectorUpgrade,
		ContextMenuOptionUpgrade,
		CbCommentFormatBarComponent,
		CbDiscussionCommentComponent,
		CbDiscussionCommentListComponent,
		ColorInputComponent,
		ColorDropdownComponent,
		ToggleLockComponent,
		TreeSelectionComponent,
		DragHandleDowngradeComponent,
		HighlightMatchPipe,
		ShowMoreComponent,
		SafeHTMLPipe,
		DatePickerComponent,
		FontSelectorComponent,
		KebabMenuComponent,
		EscapeHtmlPipe,
		CharactersLeftCounterComponent,
		InclusionListComponent,
		ToggleListComponent,
		SimpleTableComponent,
		SimpleShareTableUpgrade,
		MiddleEllipsisPipe,
		CheckboxButtonsComponent,
		ScrollingModalDirective,
		SearchableHierarchyUpgradeDirective,
		CapitalizeFirstLetterPipe,
		UnderscoreToSpacePipe,
		DropdownToggleOptionComponent,
		DropdownCollapsibleToggleOptionComponent,
		LabeledToggleSwitchComponent,
		CbColorSketchComponent,
		ColorPickerPalettesComponent,
		ColorPickerColorsComponent,
		SearchableTreeComponent,
		SanitizePipe,
		OrientationComponent,
		DisabledToggleComponent,
		WordsSelectionUpgrade,
		PageContentHeaderComponent,
		TruncateNamesListPipe,
		TabKeyHandlerDirective,
		EmptyComponent,
		TrimDirective,
		SplitButtonMenuComponent,
		ColorSwatchComponent,
		SelectedColorSwatchComponent,
		DescriptionComponent,
		PaginationCustomLastButtonComponent,
		MenuSubsectionComponent,
		SupportPostLinkComponent,
		SubmenuFlyoutComponent,
	],
	providers: [
		NameFilterPipe,
		WeightedFilterPipe,
		FilterPipe,
		MiddleEllipsisPipe,
		UserTimeFormatPipe,
		WeightedSortPipe,
		InArrayPipe,
		NegatePipe,
		ExcludeValuePipe,
		HighlightMatchPipe,
		ClipboardService,
		ScopeProvider,
		RouteParamsProvider,
		SafeHTMLPipe,
		EscapeHtmlPipe,
		DevToolsService,
		FormattedTableColumnsService,
		FormattedTableColumnsOauthClientsService,
		BulkService,
		CurrentObjectsService
	]
})
export class SharedModule { }
