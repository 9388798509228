import { ErrorDialogService } from '@cxstudio/common/cb-error-dialog.service';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { ErrorExtractor } from '../error-extractor';

/**
 * Modify rest url, add custom headers
 */
app.factory('ServerErrorHandler', (
	$log, $q, $injector, errorExtractor: ErrorExtractor, globalNotificationService: GlobalNotificationService) => {
	return class ServerErrorHandler {
		readonly supportedStatuses = [400, 404, 500];

		handleError = (response): void => {

			let errorDialogService: ErrorDialogService = $injector.get('errorDialogService');
			let locale = $injector.get('locale');

			$log.error(`Request ${response.config.url} failed.`, response.status, response.statusText);

			switch (response.status) {
				case 500: {
					response.config.processed = true; //stop propagation
					let errMsg = locale.getString('common.internalServerError');
					if (response.statusText)
						errMsg = response.statusText;
					if (response.headers && response.headers().error)
						errMsg = errorExtractor.extract(response.headers());
					if (response.headers && response.headers().eid) {
						errMsg = locale.getString('error.internalException');
						globalNotificationService.showError(errMsg, response.headers().eid, 5);
					} else {
						errorDialogService.error(errMsg);
					}
					break;
				}
				case 404: {
					response.config.processed = true; //stop propagation
					let url = '';
					if (response.config) {
						url = response.config.url;
					}
					errorDialogService.error(locale.getString('common.notFound404') + url);
					break;
				}
				case 400: {
					response.config.processed = true; //stop propagation
					let message = '';
					if (response.data instanceof Array) {
						message = response.data.join('<br>');
					} else if (response.data) {
						message = response.data;
					} else {
						message = locale.getString('common.badRequest');
					}
					errorDialogService.error(message);
					break;
				}
			}

			if (!response.config.processed) {
				errorDialogService.error(locale.getString('common.unknownError'));
				$log.error(response);
				$log.error(response && response.data);
			}

			return $q.reject(response);
		};

		support = (status: number): boolean => _.contains(this.supportedStatuses, status);
	};
});
