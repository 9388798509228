import { TransferApiService } from '@app/modules/user-administration/transfer/transfer-api.service';
import { TransferGroup } from '@app/modules/user-administration/transfer/transfer-group';
import { BulkService } from '@app/shared/services/bulk.service';
import { Security } from '@cxstudio/auth/security-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { NameService } from '@cxstudio/common/name-service';
import { ShareAction } from '@cxstudio/common/share-actions.constant';
import { DashboardTemplateService } from '@app/modules/unified-templates/dashboard-templates/dashboard-template-service.service';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { TaggingHelper } from '@app/modules/item-grid/services/tagging-helper.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DashboardUtils } from '@app/modules/dashboard/services/utils/dashboard-utils.class';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ResponsiveDashboardService } from '@cxstudio/reports/responsiveness/responsive-dashboard-service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { UsersGroupsApiService } from '@cxstudio/services/data-services/users-groups-api.service';
import { WidgetApiService } from '@app/modules/dashboard-edit/widget-api.service';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { TreeService } from '@cxstudio/services/tree-service.service';
import { SharingService } from '@cxstudio/sharing/sharing-service.service';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { DashboardPropsService } from '@cxstudio/services/dashboard-props.service';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { RecentDateService } from '@app/modules/dashboard/services/recent-date-service/recent-date.service';
import { Book } from '@cxstudio/dashboards/entity/book';
import { User } from '@cxstudio/user-administration/users/entities/user';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { DashboardViewService } from '@app/modules/dashboard/dashboard-view/dashboard-view.service';
import { DashboardAccessService } from '@app/modules/dashboard/dashboard-access.service';
import { DowngradeToastService } from '@app/modules/downgrade-utils/downgrade-toast.service';
import { HtmlUtils } from '@app/shared/util/html-utils.class';
import { DashboardCreationOriginDetails } from '@app/modules/dashboard/dashboard-creation-origin-details';
import { ErrorDialogService } from '@cxstudio/common/cb-error-dialog.service';
import { ClipboardService } from '@app/shared/services/clipboard.service';
import { ConfidentialitySettings } from './entity/dashboard-properties';
import { UrlService } from '@cxstudio/common/url-service.service';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import { AmplitudeGroupsUtils } from '@app/modules/analytics/amplitude/amplitude-groups-utils';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { TypeGuards } from '@app/util/typeguards.class';
import { DashboardTemplate } from '@app/modules/unified-templates/unified-templates-management/dashboard-templates-management/dashboard-templates-management.component';
import { AmplitudeEventUtils } from '@app/modules/analytics/amplitude/amplitude-event-utils';
import { UnifiedTemplate } from '@app/modules/unified-templates/common-templates/dto/unified-template';

class DashboardData extends Dashboard {
	widgets: Widget[] = [];
	parentId?: number;
}

class PublishDashboard extends Dashboard {
	nameWithSaveTime: string;
}

interface DashboardListItem {
	id: number;
	name: string;
	nameWithSaveTime?: string;
}

interface IDashboardServiceScope extends ISimpleScope {
	dashboards: DashboardTreeItem[];
}

type IDashboardFolder = IFolderItem | {
	id: number | string;
	type: string;
};

export type CreatedDashboardBase = Dashboard | DashboardTemplate | UnifiedTemplate;

type DashboardTreeItem = Dashboard | IDashboardFolder;

/**
 * Service for all common dashboard actions
 */
export class DashboardService {
	private static readonly SHARING_EMAIL_LIMIT = 500;

	readonly FEEDBACK_FOLDER: IDashboardFolder = {
		name: this.locale.getString('dashboard.feedbackFolder'),
		id: DashboardType.FEEDBACK_FOLDER,
		description: '',
		type: DashboardType.FOLDER
	};

	constructor(
		private readonly locale: ILocale,
		private readonly treeService: TreeService,
		private readonly $rootScope: IDashboardServiceScope,
		private readonly $q: ng.IQService,
		private readonly nameService: NameService,
		private readonly $log: ng.ILogService,
		private readonly $uibModal: ng.ui.bootstrap.IModalService,
		private readonly cbDialogService: CBDialogService,
		private readonly dashboardPropsService: DashboardPropsService,
		private readonly downgradeDialogService: DowngradeDialogService,
		private readonly dashboardApiService: DashboardApiService,
		private readonly dashboardFiltersService: DashboardFiltersService,
		private readonly widgetApiService: WidgetApiService,
		private readonly dashboardTemplateService: DashboardTemplateService,
		private readonly errorDialogService: ErrorDialogService,
		private readonly transferApiService: TransferApiService,
		private readonly security: Security,
		private readonly globalNotificationService: GlobalNotificationService,
		private readonly usersGroupsApiService: UsersGroupsApiService,
		private readonly sharingService: SharingService,
		private readonly responsiveDashboardService: ResponsiveDashboardService,
		private readonly redirectService: RedirectService,
		private readonly bulkService: BulkService,
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly recentDateService: RecentDateService,
		private readonly dashboardListService: DashboardListService,
		private readonly dashboardViewService: DashboardViewService,
		private readonly dashboardAccessService: DashboardAccessService,
		private readonly $location: ng.ILocationService,
		private readonly urlService: UrlService,
		private readonly downgradeToastService: DowngradeToastService,
		private readonly clipboardService: ClipboardService
	) { }

	/*
	 * Dashboards section
	 */

	// returns a private to process a newly created/copied dashboard
	private getNewDashboardProcessingFn(sourceObject?: CreatedDashboardBase): (resp) => Dashboard {
		return (apiResponse) => {
			const dashboard = apiResponse.data;
			const sourceParent = sourceObject && !TypeGuards.isLegacyDashboardTemplate(sourceObject) ?
				(sourceObject as any).parent :
				undefined;

			this.processCreatedDashboard(dashboard, sourceParent);
			this.trackDashboardCreation(dashboard, sourceObject);
			this.dashboardFiltersService.addHierarchyFilter(dashboard);

			return dashboard;
		};
	}

	/**
	 * Track creation event in analytics
	 */
	trackDashboardCreation(dashboard: Dashboard, sourceObject: CreatedDashboardBase): void {
		AmplitudeAnalyticsService.trackEvent(
			AmplitudeEvent.DASHBOARD_CREATE,
			AmplitudeGroupsUtils.dashboardGroup(dashboard.id),
			AmplitudeEventUtils.dashboardCreateData(sourceObject)
		);
	}

	processCreatedDashboard = (dashboard: Dashboard, parent?: IDashboardFolder) => {
		dashboard.permissions = { // set default values
			OWN: true,
			EDIT: true
		};
		if (!this.isNewTablesEnabled()) {
			if (!dashboard.createdByPinnedFeedback && parent && parent.id === DashboardType.FEEDBACK_FOLDER) {
				parent = undefined;
			}
			this.treeService.addItem(this.dashboardListService.getCurrentDashboardsList(), dashboard, parent);
		}
	};

	private processUpdatedDashboard(updatedDashboard): void {
		if (!this.isNewTablesEnabled()) {
			const folderTo = _.findWhere(this.dashboardListService.getCurrentDashboardsList(),
				{ id: updatedDashboard.parentId }) as any as IFolderItem;
		//we do not have parent object in API response
			updatedDashboard.parent = folderTo;
			updatedDashboard.level = folderTo ? folderTo.level + 1 : 0;
			this.treeService.updateItem(this.dashboardListService.getCurrentDashboardsList(), updatedDashboard);
		}
	}

	saveDashboard = (dashboard: Dashboard) => {
		if (!dashboard.id) {
			return this.dashboardApiService.createNewDashboard(dashboard).then((response) => {
				const newDashboard = response.data;
				this.processCreatedDashboard(newDashboard);
				return newDashboard;
			});
		} else {
			return this.dashboardApiService.updateDashboard(dashboard.id, dashboard).then((response) => {
				const updatedDashboard = response.data;
				this.processUpdatedDashboard(updatedDashboard);
				return updatedDashboard;
			});
		}
	};

	createDashboard = (dashboardName?: string, templateId?: number | string, selectedFolder?): ng.IPromise<Dashboard> => {
		const dashPropsModal = this.newDashPropsDialog(dashboardName, {
			templateId,
			selectedFolder
		});
		return dashPropsModal.result.then((creationResult) => {
			const dashboardProperties = creationResult.model;
			const template = creationResult.template;

			let dashboardCreatePromise: ng.IHttpPromise<Dashboard>;
			let notificationMessage;

			if (!template) {
				dashboardCreatePromise = this.dashboardApiService.createNewDashboard(dashboardProperties);
			} else {
				const templateWidgets = template.widgets;
				const filteredWidgets = this.filterScorecardWidgets(templateWidgets);

				if (filteredWidgets.length !== templateWidgets.length) {
					notificationMessage = this.locale.getString('templates.legacyWidetsWereNotAdded',
						{ widgets: this.locale.getString('templates.scorecard') });
					template.widgets = filteredWidgets;
				}

				dashboardCreatePromise = this.dashboardTemplateService.createDashboardFromTemplate(dashboardProperties, template);
			}

			return dashboardCreatePromise.then((apiResponse) => {
				if (notificationMessage) {
					this.globalNotificationService.addSuccessNotification(notificationMessage);
				}
				return this.getNewDashboardProcessingFn(template)(apiResponse);
			});
		});
	};

	createNewDashboardProperties = (dashboardRootName?: string) => {
		dashboardRootName = dashboardRootName || this.locale.getString('dashboard.dashboardName');
		const dashboardName = this.getUniqueDashboardName(dashboardRootName);
		return {
			name: dashboardName,
			type: ObjectType.DASHBOARD,
			description: '',
			properties: {
				sampling: 'SAMPLE',
				viewOnlyDrill: true,
				defaultShowTotal: true
			}
		};
	};

	private filterScorecardWidgets(templateWidgets: Widget[]): Widget[] {
		return _.filter(templateWidgets, (widget) => {
			return widget.name !== WidgetType.SCORECARD;
		});
	}

	getUniqueDashboardName = (baseName) => {
		return this.nameService.uniqueName(baseName, this.dashboardListService.getCurrentDashboardsList(), 'name');
	};

	newDashPropsDialog = (dashboardRootName?: string, config = {}) => {
		dashboardRootName = dashboardRootName || this.locale.getString('dashboard.dashboardName');
		const dashboardName = this.getUniqueDashboardName(dashboardRootName);
		const newDash = {
			name: dashboardName,
			type: ObjectType.DASHBOARD,
			description: '',
			properties: {
				sampling: 'SAMPLE',
				viewOnlyDrill: true,
				defaultShowTotal: true,
				confidentiality: new ConfidentialitySettings()
			}
		};

		const propertiesDialogConfig = {
			isProperties: true,
			existingItem: false,
			itemList: this.dashboardListService.getCurrentDashboardsList()
		};
		_.extend(propertiesDialogConfig, config);

		return this.dashboardPropertiesDialog(newDash, propertiesDialogConfig);
	};

	/**
	 * @param { isProperties: boolean, existingItem: boolean, hiddenItems: Object, customLabels: Object, itemList: [] } config
	 */
	dashboardPropertiesDialog = (dashboard, config) => {
		config.existingItem = config.existingItem || false;
		config.hiddenItems = config.hiddenItems || {};
		config.customLabels = config.customLabels || {};
		config.itemList = config.itemList || [];
		config.folders = config.folders || this.getDashboardEditingFolders();
		config.explicitelyAllowFolderSelection = config.explicitelyAllowFolderSelection || false;

		this.setupInitialDashboardProperties(dashboard, config);

		return this.$uibModal.open({
			templateUrl: 'partials/dashboards/dashboard-creation-wizard.html',
			controller: 'DashboardPropsCtrl',
			backdrop: 'static',
			resolve: {
				treeItem: () => dashboard,
				config: () => config
			}
		});
	};

	private setupInitialDashboardProperties(item, config): void {
		if (config.existingItem && (!item.id || (this.dashboardPropsService.currentDashboard !== item.id))) {
			this.dashboardPropsService.loadDashboard(item);
		}
	}

	private getDashboardEditingFolders(): IDashboardFolder[] {
		const folders = angular.copy(_.filter(this.dashboardListService.getCurrentDashboardsList(), { type: DashboardType.FOLDER }));
		const rootFolder = { id: -1, name: this.locale.getString('common.selectPrompt') } as IDashboardFolder;

		folders.unshift(rootFolder as any);
		return folders;
	}

	createDashboardByPinnedFeedback = (widget, name, dashboardData): ng.IPromise<Dashboard> => {
		return this.createPredefined(widget, name, dashboardData, this.dashboardApiService.createDashboardByPinnedFeedback);
	};

	// predefined dashboard, but with overwrite in place to copy image widgets
	saveVersionAs = (widget, name, dashboardData): ng.IPromise<Dashboard> => {
		return this.createPredefined(widget, name, dashboardData, this.dashboardApiService.saveDashboardVersionAs);
	};

	createPredefinedDashboard = (
		widget: Widget | Widget[], name: string, dashboardData: Dashboard, originDetails?: DashboardCreationOriginDetails
	): ng.IPromise<Dashboard> => {
		return this.createPredefined(widget, name, dashboardData, this.dashboardApiService.createPredefinedDashboard, originDetails);
	};

	private createPredefined = (
		widget: Widget | Widget[], name: string, dashboardData: Dashboard, apiMethod, originDetails?: DashboardCreationOriginDetails
	): ng.IPromise<Dashboard> => {
		name = name || this.nameService.uniqueName(
			this.locale.getString('dashboard.dashboardName'), this.dashboardListService.getCurrentDashboardsList(), 'name');
		const data: DashboardData = new DashboardData(null, name);

		let dashId;
		if (dashboardData) {
			data.appliedFiltersArray = dashboardData.appliedFiltersArray;
			data.parentId = dashboardData.parentId;

			data.properties = dashboardData.properties;
			data.workspaceProject = dashboardData.workspaceProject;

			//ignore dashboard's filters for dashboards, that has been started from pop document explorer
			if (!_.isArray(widget) && widget.ignoreDateRangeFilters) {
				data.appliedFiltersArray = data.appliedFiltersArray.filter((item) => {
					if (item.selectedAttribute && item.selectedAttribute.filterType === 'dateRange') {
						return false;
					}
					return true;
				});
			}
			dashId = dashboardData.id;
		}

		if (_.isArray(widget)) {
			data.widgets = widget;
		} else {
			data.widgets.push(widget);
		}

		return apiMethod(dashId, data, originDetails).then(this.getNewDashboardProcessingFn());
	};

	removeDashboard = (dashboard, skipConfirm?) => {
		const confirmPromise = skipConfirm ? this.$q.when() : this.removeConfirmation(dashboard);
		return confirmPromise.then(() => {
			return this.dashboardListService.setLoading(this.dashboardApiService.deleteDashboard(dashboard.id).then(() => {
				this.$log.debug('Removed dashboard:', dashboard);
				if (!this.isNewTablesEnabled())
					this.treeService.deleteItem(this.dashboardListService.getCurrentDashboardsList(), dashboard);
				return dashboard;
			}));
		});
	};

	private removeConfirmation(dashboard): ng.IPromise<any> {
		if (this.isBook(dashboard)) {
			return this.removeBookConfirmation(dashboard);
		}

		return this.removeDashboardConfirmation(dashboard);
	}

	private removeDashboardConfirmation(dashboard): ng.IPromise<any> {
		const escapedDashboardName = HtmlUtils.escapeHtml(dashboard.name);
		const deleteModalTitle = this.locale.getString('common.deleteObjectTitle', { objectName: dashboard.name });

		const embedWidgetsPromise = this.dashboardApiService.getEmbedWidgetNames([dashboard.id]);
		const messagePromise = embedWidgetsPromise.then((result) => {
			return _.isEmpty(result)
				? this.locale.getString('common.deleteObjectText', { objectName: escapedDashboardName })
				: this.locale.getString('dashboard.deleteDashboardWithEmbedNote', { dashboardName: escapedDashboardName });
		});
		const itemsPromise = embedWidgetsPromise;
		const DANGER = true;
		return this.cbDialogService.confirmTableWithLoading(
			deleteModalTitle,
			'',
			[{ name: 'widgetName', displayName: this.locale.getString('object.widget') }],
			[],
			{ msg: messagePromise, items: itemsPromise },
			this.locale.getString('common.delete'),
			this.locale.getString('common.cancel'),
			DANGER
		).result;
	}

	private removeBookConfirmation(dashboard): ng.IPromise<any> {
		const escapedDashboardName = HtmlUtils.escapeHtml(dashboard.name);
		const deleteModalTitle = this.locale.getString('common.deleteObjectTitle', { objectName: dashboard.name });
		const deleteModalWarning = this.locale.getString('common.deleteObjectText', { objectName: escapedDashboardName });
		return this.cbDialogService.danger(deleteModalTitle, deleteModalWarning).result;
	}

	removeDashboardsBulk = (dashboardList) => {
		const deleteModalTitle = this.locale.getString('dashboard.bulkDeleteTitle');

		const embedWidgetsPromise = this.dashboardApiService.getEmbedWidgetNames(_.map(dashboardList, 'id'));
		const messagePromise = embedWidgetsPromise.then((result) => {
			const messageKey = _.isEmpty(result) ? 'dashboard.bulkDeleteText' : 'dashboard.deleteDashboardBulkWithEmbedNote';
			return this.locale.getString(messageKey, {
				numberOfDashboards: dashboardList.length,
				namesList: this.bulkService.getNamesList(dashboardList, 'name')
			});
		});
		const itemsPromise = embedWidgetsPromise;
		const DANGER = true;
		const modalResult = this.cbDialogService.confirmTableWithLoading(
			deleteModalTitle,
			'',
			[
				{ name: 'widgetName', displayName: this.locale.getString('object.widget') },
				{ name: 'dashboardName', displayName: this.locale.getString('object.dashboard') }
			],
			[],
			{ msg: messagePromise, items: itemsPromise },
			this.locale.getString('common.delete'),
			this.locale.getString('common.cancel'),
			DANGER
		).result;

		return modalResult.then(() => {
			return this.dashboardListService.setLoading(this.dashboardApiService.deleteDashboardsBulk(_.map(dashboardList, 'id'))).then(() => {
				if (!this.isNewTablesEnabled())
					_.each(dashboardList, (dashboard) => {
						this.treeService.deleteItem(this.dashboardListService.getCurrentDashboardsList(), dashboard);
					});
				return dashboardList;
			});
		});
	};

	transferDashboardsBulk = (transferredDashboards): Promise<any> => {
		return this.downgradeDialogService.openBulkTransferDialog({
			selectedObjects: transferredDashboards,
			mode: TransferGroup.DASHBOARDS
		}).result.then(result => {
			return this.transferApiService.makeTransfer(result);
		}, () => {});
	};

	moveDashboard = (dashboard: Dashboard, folderTo): ng.IPromise<Dashboard> => {
		this.$log.debug(`Moving ${dashboard.name} to ${folderTo.name}`);
		dashboard.parentId = folderTo.id;
		return this.dashboardApiService.moveDashboard(dashboard.id, folderTo.id).then(() => {
			if (!folderTo.id && dashboard.createdByPinnedFeedback) {
				folderTo = _.findWhere(this.dashboardListService.getCurrentDashboardsList(),
					{ id: DashboardType.FEEDBACK_FOLDER } as any);
				folderTo.hide = false;
				TaggingHelper.untag(folderTo, TaggingHelper.tags.HIDDEN);
			} else if (!folderTo.id) {
				folderTo = undefined;
			}
			if (!this.isNewTablesEnabled()) {
				this.treeService.moveItem(this.dashboardListService.getCurrentDashboardsList(), dashboard, folderTo);
			}
			return dashboard;
		});
	};

	copyDashboard = (dashboard: Dashboard) => {
		if ((dashboard as any).shared && !this.security.isCurrentUser(dashboard.ownerName)) {
			let titleKey;
			let messageKey;
			if (this.isBook(dashboard)) {
				titleKey = 'dashboard.duplicateBookTitle';
				messageKey = 'dashboard.duplicateBookMessage';
			} else {
				titleKey = 'dashboard.duplicateTitle';
				messageKey = 'dashboard.duplicateMessage';
			}
			const dlg = this.cbDialogService.confirm(
				this.locale.getString(titleKey),
				this.locale.getString(messageKey),
				this.locale.getString('dashboard.duplicate'),
				this.locale.getString('common.cancel'));
			return dlg.result.then(() => {
				return this.copyDashboardInternal(dashboard);
			});
		} else {
			return this.copyDashboardInternal(dashboard);
		}
	};

	private copyDashboardInternal(dashboard: Dashboard): ng.IPromise<Dashboard> {
		this.$log.debug('Copying ' + dashboard.name);
		const name = this.nameService.uniqueName(
			`${dashboard.name} - ${this.locale.getString('dashboard.copyName')}`, this.dashboardListService.getCurrentDashboardsList(), 'name');
		return this.dashboardApiService.copyDashboard(dashboard.id, name)
			.then(this.getNewDashboardProcessingFn(dashboard));
	}

	applyDashboardUpdates = (dashboard: Dashboard, newProperties, retainEditPermission: boolean): ng.IPromise<Dashboard> => {
		const tmpDashboard = $.extend({}, dashboard, newProperties);
		return this.dashboardApiService.updateDashboard(tmpDashboard.id, tmpDashboard, retainEditPermission).then((response) => {
			const updatedDashboard = response.data;
			this.processUpdatedDashboard(updatedDashboard);
			this.dashboardPropsService.commitUpdates();
			dashboard = _.extend(dashboard, updatedDashboard);
			return dashboard;
		});
	};

	private applyNameUpdates(dashboard: Dashboard, newProperties): ng.IPromise<Dashboard> {
		const nameProps = _.pick(newProperties, ['name', 'description']);
		return this.dashboardApiService.renameDashboard(dashboard.id, nameProps).then(() => {
			dashboard = _.extend(dashboard, nameProps);
			this.dashboardPropsService.commitUpdates();
			return dashboard;
		});
	}

	applyUpdates(dashboard: Dashboard, item, isFull: boolean, retainEditPermission: boolean): ng.IPromise<Dashboard> {
		return isFull
			? this.applyDashboardUpdates(dashboard, item, retainEditPermission)
			: this.applyNameUpdates(dashboard, item);
	}

	//update dashboard
	renameDashboard = (dashboard: Dashboard, full: boolean = false): ng.IPromise<Dashboard> => {
		const dashProperties = this.dashboardPropertiesDialog(dashboard, {
			isProperties: full,
			existingItem: true,
			itemList: this.dashboardListService.getCurrentDashboardsList()
		});
		return dashProperties.result.then((updateResult) => {
			const item = updateResult.model;
			const ownerUpdateResult = updateResult.ownerUpdateResult;
			const retainEditPermission = ownerUpdateResult && ownerUpdateResult.retainEditPermission;

			return this.applyUpdates(dashboard, item, full, retainEditPermission)
				.then((data) => {
					return this.onDashboardPropertiesChange(data, dashboard, updateResult);
				});
		}, (trigger: any) => {
			if (trigger === 'escape key press') {
				this.dashboardPropsService.revertUpdates();
			}
		});
	};

	onDashboardPropertiesChange(data: Dashboard, dashboard: Dashboard, updateResult): Dashboard {
		const ownerUpdateResult = updateResult.ownerUpdateResult;
		const projectUpdateResult = updateResult.projectUpdateResult;
		const personalizationResult = updateResult.personalizationResult;
		const modernLookAndFeelResult = updateResult.modernLookAndFeelResult;
		const retainEditPermission = ownerUpdateResult && ownerUpdateResult.retainEditPermission;

		let refreshRequired = false;

		if (projectUpdateResult && projectUpdateResult.updated) {
			this.$rootScope.$broadcast('widgetTemplatesRefreshEvent');
			if (projectUpdateResult.newProject && projectUpdateResult.newProject <= 0) {
				return data;
			}

			this.$rootScope.$broadcast('dashboardFiltersChangedEvent');
			refreshRequired = true;
		}

		if (personalizationResult && personalizationResult.updated) {
			this.$rootScope.$broadcast('dashboardHierarchyToggleEvent');
			refreshRequired = true;
		}

		if (ownerUpdateResult && ownerUpdateResult.updated) {
			if (dashboard.permissions) {
				delete dashboard.permissions.OWN;
			}
			this.$rootScope.$broadcast('changeWidgetsOwnerEvent',
				ownerUpdateResult.oldOwnerEmail, ownerUpdateResult.newOwnerEmail);
			refreshRequired = true;
			if (retainEditPermission === false) {
				this.redirectService.goToDashboardList();
			}
		}

		if (modernLookAndFeelResult && modernLookAndFeelResult.updated) {
			refreshRequired = true;
		}
		if (refreshRequired) {
			this.$rootScope.$broadcast('dashboardRefreshEvent');
		}
		return data;
	}

	/**
	 * @param { isProperties: boolean? } extendedConfig
	 */
	editBookProperties = (book: Book, extendedConfig?): ng.IPromise<Dashboard> => {
		extendedConfig = extendedConfig || {};
		const config = {
			existingItem: true,
			loadEditors: true,
			folders: this.getDashboardEditingFolders(),
			isProperties: true
		};
		_.extend(config, extendedConfig);

		this.setupInitialDashboardProperties(book, config);

		const editPropertiesModal = this.$uibModal.open({
			templateUrl: 'partials/dashboards/book-properties-dialog.html',
			controller: 'DashboardPropsCtrl',
			backdrop: 'static',
			resolve: {
				treeItem: () => {
					return book;
				},
				config: () => {
					return config;
				}
			}
		});

		return editPropertiesModal.result.then((editPropertiesResult) => {
			return this.applyUpdates(book, editPropertiesResult.model, config.isProperties,
				editPropertiesResult.ownerUpdateResult.retainEditPermission).then((data) => {
				if (!this.isNewTablesEnabled()) {
					this.treeService.updateItem(this.dashboardListService.getCurrentDashboardsList(), data);
				}
				return data;
			});
		});
	};

	renameBook = (book) => {
		return this.editBookProperties(book, {
			isProperties: false
		});
	};

	private cleanUpItem(item): void {
		for (const k in item) {
			if (k.indexOf('_') === 0) {
				delete item[k];
			}
		}
	}

	private handleSharingResponse(errors): void {
		if (!$.isEmptyObject(errors)) {
			const formatError = (type, error) => {
				if (error.localizationCode) {
					return `${type} ${error.user}: ${this.locale.getString('error.' + error.localizationCode)}`;
				} else {
					return `${type} ${error.user}: ${error.message}`;
				}
			};

			const formattedErrors = [];
			for (const errorType in errors) {
				if (errors[errorType]) {
					errors[errorType].forEach(error => formattedErrors.push(formatError(errorType, error)));
				}
			}
			const model = {
				errors: formattedErrors
			};
			this.cbDialogService.custom(this.locale.getString('common.error'), 'partials/dashboards/errors-dialog.html', model);
		}
	}

	private processSharedDashboards(modalInstance, dashboards, userSelfRemoval, userStillEditor): ng.IPromise<void> {
		const changedDashboards = [];
		const promises = _.map(dashboards, (dashboard) => {
			return this.dashboardApiService.getDashboard(dashboard.id);
		});
		return this.$q.all(promises).then((responses) => {
			responses.forEach((response: any) => {
				const changedDashboard = response.data;
				if (changedDashboard) {
					changedDashboards.push(changedDashboard);

					if (changedDashboard.createdByPinnedFeedback) {
						let message = this.buildSharedFeedbackMessage(changedDashboard);
						this.downgradeToastService.addToast(message, undefined, true);
					} else {
						this.globalNotificationService.addItemSavedNotification(changedDashboard.name);
					}

					this.$rootScope.$broadcast('sharingPerformed', changedDashboard, userSelfRemoval, userStillEditor);
				}
			});
			modalInstance.close({ $value: changedDashboards });
		});
	}

	private buildSharedFeedbackMessage(dashboard: Dashboard): string {
		let url = this.urlService.getDashboardUrl(dashboard.id);
		let viewDashboard = this.locale.getString('sharing.viewSharedDashboard');
		let feedbackShared = this.locale.getString('sharing.feedbackShared');
		return `${feedbackShared} <a class="text-white underline font-bold" href="${url}" target="_blank">${viewDashboard}</a>`;
	}

	buildPreviewAsFunction(dashboard, modalHandler: (modal: ng.ui.bootstrap.IModalInstanceService) => void):
		(modalInstance: ng.ui.bootstrap.IModalInstanceService, data, item) => void {
		return (modalInstance, data, item) => {
			modalHandler(modalInstance);
			let user = {
				email: item.entity.userEmail,
				fullName: item.entity.userFullName
			};
			this.dashboardViewService.enterViewAsMode(user, data, dashboard.id);
			this.$location.path(`/preview/${dashboard.id}`);
		};
	}

	buildDashboardShareSaveFunction(
		isBulk: boolean, dashboardInEditMode: boolean, dashboardVersionId: number, currentUserEmail?: string
	): (modal, data) => any {
		return (modalInstance, data) => {
			if (data.loading.save) {
				return;
			}

			data.loading.save = true;
			// subarrays: create, add, delete, update
			const allEntities = _.chain(data.sharedEntities)
				.filter((item) => item.action === ShareAction.UPDATE) // updates are within sharedEntities
				.union(data.changedEntities)
				.filter((item) => item.action !== ShareAction.CREATE) // create is in separate property
				.map((item) => angular.extend({}, item)) // copy items to not change ui
				.each(this.cleanUpItem)
				.value();

			const restData = _.groupBy(allEntities, 'type'); // user and group

			restData.create = _.chain(data.changedEntities)
				.filter((item) => item.action === ShareAction.CREATE)
				.map((item) => angular.extend({}, item))
				.each(this.cleanUpItem)
				.value();

			restData.sendEmail = data.sendEmail;
			restData.message = data.notifyMessage;

			const isOwner = !isBulk && data.dashboards[0].permissions.OWN;
			let propertiesChange = (data.dashboards[0].properties.editorsAllowedToShare !== data.editorsAllowedToShare)  ||
				(data.dashboards[0].properties.allowSharingThroughEmbedding !== data.allowSharingThroughEmbedding);

			if (isOwner && propertiesChange) {
				this.updateSharingProperties(data.dashboards[0].id, !data.editorsAllowedToShare, data.allowSharingThroughEmbedding);
			}

			// do not send update request, if share entities were not changed
			if (!allEntities.length && !restData.create.length && !propertiesChange) {
				modalInstance.close(data.dashboards);
				return;
			}

			// detect if user still has access to dashboard
			let userSelfRemoval = false;
			let userStillEditor = true;

			if (currentUserEmail && !isOwner && !isBulk) {
				// checking user state on editor sharing
				userSelfRemoval = true;
				userStillEditor = false;
				for (let object of data.sharedEntities) {
					if (object.type === 'user') {
						if (currentUserEmail === object.entity.userEmail) {
							userSelfRemoval = false;
							if (object.permission === 'EDIT') {
								userStillEditor = true;
							}
						}
					} else if (object.type === 'group') {
						for (let userEmail of object.entity.userEmails) {
							if (currentUserEmail === userEmail) {
								userSelfRemoval = false;
								if (object.permission === 'EDIT') {
									userStillEditor = true;
								}
							}
						}
					}
				}
			}
			// user self unshare and user self remove edit permission on dashboard.
			if (!isOwner && !isBulk && dashboardInEditMode && (!userStillEditor || userSelfRemoval)) {
				// cancelling changes made in edit mode
				data.loading.init = this.verifySharedEntitiesCount(data.changedEntities, restData).then(() => {
					this.widgetApiService.restoreWidgetsTempState(data.dashboards[0].id, dashboardVersionId).then(() => {
						data.loading.init = this.dashboardApiService.shareDashboard(data.dashboards[0].id, restData)
							.then((errors) => {
								processShareResult(errors.data);
							})
							.finally(() => {
								data.loading.save = false;
							});
					});
				});
			} else {
				const promise = isBulk
					? this.verifyOwnershipRemoval(data.changedEntities, data.dashboards).then(() => {
						return this.verifySharedEntitiesCount(data.changedEntities, restData).then(() => {
							return this.dashboardApiService.shareDashboardsBulk(_.pluck(data.dashboards, 'id'), restData);
						});
					})
					: this.verifySharedEntitiesCount(data.changedEntities, restData).then(() => {
						return this.dashboardApiService.shareDashboard(_.pluck(data.dashboards, 'id'), restData);
					});

				data.loading.init = promise
					.then((errors) => {
						processShareResult(errors.data);
					})
					.finally(() => {
						data.loading.save = false;
					});
			}

			const processShareResult = (errors) => {
				this.handleSharingResponse(errors);
				data.loading.init = this.processSharedDashboards(modalInstance, data.dashboards, userSelfRemoval, userStillEditor);
			};
		};
	}

	private verifyOwnershipRemoval(changedEntities, dashboards): ng.IPromise<void> {
		const dashboardOwners = _.pluck(dashboards, 'ownerName');

		const userEntities = changedEntities
			.filter((entity) => entity.type === 'user');

		const isOwnerChangeRequested = _.some(userEntities, (userEntity) => {
			const userEmail = userEntity.displayName;
			return dashboardOwners.contains(userEmail);
		});

		if (isOwnerChangeRequested) {
			const notifyDialog = this.cbDialogService.notify(
				this.locale.getString('dashboard.partialShareWarning'),
				this.locale.getString('dashboard.partialShareMessage'));

			return notifyDialog.result.then(() => {
				return this.$q.when();
			});
		}

		return this.$q.when();
	}

	private verifySharedEntitiesCount(changedEntities, restData): ng.IPromise<void> {
		if (restData.sendEmail) {
			return this.countNewSharedUsers(changedEntities).then((count) => {
				if (count > DashboardService.SHARING_EMAIL_LIMIT) {
					const confirmDialog = this.cbDialogService.confirm(
						this.locale.getString('common.warning'),
						this.locale.getString('dashboard.shareEmailNotificationLimitExceeded',
							{emailLimit: DashboardService.SHARING_EMAIL_LIMIT}),
						this.locale.getString('dashboard.shareInAppOnly'),
						this.locale.getString('dashboard.updateUsers'));

					return confirmDialog.result.then(() => {
						restData.sendEmail = false;
					});
				} else {
					return this.$q.when();
				}
			});
		} else {
			return this.$q.when();
		}
	}

	private countNewSharedUsers(changedEntities): ng.IPromise<number> {
		const createdEntities = changedEntities
			.filter((entity) => entity.action === ShareAction.ADD);
		if (createdEntities.length === 0) {
			return this.$q.when(0);
		}

		const accessChange = _.groupBy(createdEntities, 'type');
		// interrupt the backend count if the limit is reached
		return this.usersGroupsApiService.countUsers(accessChange, DashboardService.SHARING_EMAIL_LIMIT);
	}

	private updateSharingProperties(dashboardId: number, preventFromSharing: boolean, allowSharingThroughEmbedding: boolean) {
		return this.dashboardApiService.updateSharingProperties(dashboardId, preventFromSharing, allowSharingThroughEmbedding);
	}

	shareDashboardsBulk = (selectedDashboards) => {
		return this.shareDashboards({
			header: this.locale.getString('dashboard.shareBulk'),
			saveFunction: this.buildDashboardShareSaveFunction(true, false, 0),
			dashboards: selectedDashboards,
			resolve: (dashboards) => {
				return dashboards;
			}
		});
	};

	shareDashboard = (dashboard: Dashboard, dashboardInEditMode = false, dashboardVersionId?: number) => {
		return this.shareDashboards({
			header: this.getShareDashboardHeaderText(dashboard),
			saveFunction: this.buildDashboardShareSaveFunction(false, dashboardInEditMode, dashboardVersionId, this.security.getEmail()),
			previewAsFunction: this.buildPreviewAsFunction(dashboard, modal => modal.close()),
			dashboards: [dashboard],
			resolve: (dashboards) => {
				return dashboards;
			}
		});
	};

	shareDashboardToUser = (dashboard: Dashboard, shareToUser: User,
		previewModalHandler: (modal: ng.ui.bootstrap.IModalInstanceService) => void) => {
		return this.shareDashboards({
			header: this.getShareDashboardHeaderText(dashboard),
			saveFunction: this.buildDashboardShareSaveFunction(false, false, null, this.security.getEmail()),
			previewAsFunction: this.buildPreviewAsFunction(dashboard, previewModalHandler),
			dashboards: [dashboard],
			shareToUser: this.getShareToUserEntity(shareToUser),
			resolve: (dashboards) => {
				return dashboards;
			}
		});
	};

	restoreShareDashboard = (data) => {
		let dashboard = data?.dashboards[0];
		return this.shareDashboards({
			header: this.getShareDashboardHeaderText(dashboard),
			saveFunction: data.saveFunction,
			previewAsFunction: this.buildPreviewAsFunction(dashboard, modal => modal.close()),
			dashboards: [dashboard],
			data,
			resolve: (dashboards) => {
				return dashboards;
			}
		});
	};

	private getShareToUserEntity(shareToUser): any {
		if (!shareToUser) {
			return null;
		}

		return {
			entity: shareToUser,
			type: 'user',
			_name: shareToUser.userEmail,
			displayName: shareToUser.userEmail,
			iconType: 'user'
		};
	}

	private shareDashboards(dialogConfig): ng.IPromise<any> {
		let dlg;
		const context = {
			bulkMode: dialogConfig.dashboards.length > 1,
			items: dialogConfig.dashboards
		};
		return this.sharingService.checkUnsharable(context).then((filteredContext) => {
			dlg = this.$uibModal.open({
				component: 'dashboardSharing',
				backdrop: 'static',
				windowClass: 'modal-md',
				keyboard: false,
				resolve: {
					bulkMode: () => {
						return filteredContext.bulkMode;
					},
					dashboards: () => {
						return filteredContext.items;
					},
					modalTitle: () => {
						return dialogConfig.header;
					},
					saveFunction: () => {
						return dialogConfig.saveFunction;
					},
					previewAsFunction: () => {
						return dialogConfig.previewAsFunction;
					},
					shareToUser: () => {
						return dialogConfig.shareToUser;
					},
					data: () => {
						return dialogConfig.data;
					}
				}
			});

			const deferred = this.$q.defer();
			dlg.result.then((result) => {
				deferred.resolve(dialogConfig.resolve(result));
			}, (reason) => {
				if (reason === 'deleteDashboard') {
					return this.removeDashboard(dialogConfig.dashboards[0], true)
						.then(deferred.reject, deferred.reject);
				} else {
					deferred.reject();
				}
			});
			return deferred.promise;
		});
	}

	private getShareDashboardHeaderText(dashboard): string {
		return this.isBook(dashboard)
			? this.locale.getString('dashboard.bookShareTitle', { name: dashboard.name })
			: this.locale.getString('dashboard.shareTitle', { name: dashboard.name });
	}

	rateDashboard = (dashboard, rating: number): ng.IPromise<any> => {
		this.$log.debug(`Rate ${dashboard.name}: ${rating}`);
		const deferred = this.$q.defer();
		this.dashboardApiService.rateDashboard(dashboard.id, rating).then(() => {
			//a = (x1 + x2 + x3 + x4) / 4
			//a_new = (x1 + x2 + x3 + x4 + x5) / 5 = (a * 4 + x5) / 5
			//a_change = (x1 + x2 + x3 + x5) / 4 = a + (x5 - x4) / 4
			if (!dashboard.userRating) { // 0 is also NONE
				dashboard.rating = (dashboard.rating * dashboard.ratingCount + rating) / (dashboard.ratingCount + 1);
				dashboard.ratingCount += 1;
			} else {
				dashboard.rating += (rating - dashboard.userRating) / dashboard.ratingCount;
			}
			dashboard.userRating = rating;
			deferred.resolve(dashboard);
		});
		return deferred.promise;
	};

	favoriteDashboard = (dashboard: Dashboard): ng.IPromise<Dashboard> => {
		return this.dashboardApiService.setDashboardFavorite(dashboard.id, dashboard.favorite).then(() => {
			return dashboard;
		});
	};

	canEditDashboard(dashboard: Dashboard): boolean {
		return this.dashboardAccessService.canEditDashboard(dashboard);
	}

	canShare(dashboard: Dashboard): boolean {
		return this.dashboardAccessService.canShare(dashboard);
	}

	hasSharePermission(): boolean {
		return this.dashboardAccessService.hasSharePermission();
	}

	refreshDashboard = (dashboardId?: number): void => {
		this.$rootScope.$broadcast('dashboardRefreshEvent', dashboardId);
	};

	hardRefreshDashboard = (dashboardId: number): void => {
		this.responsiveDashboardService.refreshDashboard();
		this.$rootScope.$broadcast('dashboardHardRefreshEvent', dashboardId);
	};

	runReportGeneration(dashboardId: number): void {
		this.dashboardApiService.runReportGeneration(dashboardId);
	}

	unshareDashboardWithOrganization = (dashboardId: number, sharedOrganizationEntries): void => {
		const hierarchyGroups = _.each(angular.copy(sharedOrganizationEntries), (group) => {
			group.action = ShareAction.DELETE;
		});
		const restData = {
			group: hierarchyGroups,
			create: [],
			sendEmail: false,
			message: ''
		};
		this.dashboardApiService.shareDashboard(dashboardId, restData);
	};

	publishDashboardFromSource = (target: Dashboard, dashboards: Dashboard[]) => {
		const sourceList = this.findEditableDashboards(target, dashboards);

		if (isEmpty(sourceList)) {
			this.errorDialogService.info(this.locale.getString('dashboard.noEligibleDashboard'));
			return {
				result: this.$q.reject()
			};
		}
		return this.openPublishDialog(target, sourceList);
	};

	private findEditableDashboards(target: Dashboard, dashboards: Dashboard[]): DashboardListItem[] {
		return _.chain(dashboards).filter((treeItem) => {
			return this.isDashboard(treeItem) && treeItem.id !== target.id
				&& (treeItem.permissions.EDIT || treeItem.permissions.OWN);
		}).map((dashboard: PublishDashboard) => {
			let modifiedDateString = '';
			if (dashboard.modifiedDate) {
				const formattedDate = this.recentDateService.formatToRecentDate(dashboard.modifiedDate);
				modifiedDateString = ` (${this.locale.getString('dashboard.modifiedDate')}: ${formattedDate})`;
			}
			dashboard.nameWithSaveTime = dashboard.name + modifiedDateString;
			return _.pick(dashboard, ['id', 'name', 'nameWithSaveTime']);
		}).value();
	}

	getAllDrillableDashboards = (current: Dashboard, onlyCanShare: boolean = false): DashboardListItem[] => {
		return _.chain(this.dashboardListService.getCurrentDashboardsList()).filter((treeItem) => {
			return this.isDashboard(treeItem) && treeItem.id !== current.id
				&& (!onlyCanShare || this.canShare(treeItem));
		}).filter((dashboard: Dashboard) => {
			return this.hasSameProject(dashboard, current);
		}).map((dashboard: Dashboard) => {
			return _.pick(dashboard, ['id', 'name']);
		}).sortBy((dashboard: { id: number; name: string }) => {
			return dashboard.name.toLowerCase();
		}).value();
	};

	getDashboardById = (id: number): DashboardTreeItem => {
		return _.findWhere(this.dashboardListService.getCurrentDashboardsList(), { id });
	};

	private hasSameProject(source: Dashboard, target: Dashboard): boolean {
		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE)) {
			return WorkspaceProjectUtils.isProjectSelected(source.workspaceProject)
				&& WorkspaceProjectUtils.isProjectSelected(target.workspaceProject)
				&& source.workspaceProject.workspaceId === target.workspaceProject.workspaceId
				&& source.workspaceProject.projectId === target.workspaceProject.projectId;
		} else {
			const sourceProps = source.properties;
			const targetProps = target.properties;
			return sourceProps && sourceProps.project > -1
				&& sourceProps.cbContentProvider === targetProps.cbContentProvider
				&& sourceProps.cbAccount === targetProps.cbAccount
				&& sourceProps.project === targetProps.project;
		}
	}

	private openPublishDialog(target, sourceList): IModalInstanceService {
		const dialogData = {
			header: this.locale.getString('dashboard.publishDashboardHeader'),
			template: 'partials/dashboard/dashboard-publish-dialog.html',
			okBtnName: this.locale.getString('dashboard.publish'),
			cancelBtnName: this.locale.getString('common.cancel'),
			windowClass: 'modal-md',
			sourceList,
			target,
			source: angular.copy(sourceList[0]),
			onSourceClick: (node, source) => {
				angular.copy(node, source);
			}
		};

		return this.cbDialogService.showDialog(dialogData);
	}

	private isBook(treeItem: DashboardTreeItem): treeItem is Dashboard {
		return treeItem.type === DashboardType.BOOK;
	}

	private isDashboard(treeItem: DashboardTreeItem): treeItem is Dashboard {
		return treeItem.type === DashboardType.DASHBOARD;
	}

	isFolder = (treeItem: DashboardTreeItem): treeItem is IDashboardFolder => {
		return treeItem.type === DashboardType.FOLDER;
	};

	getEditableDashboards = (currentDashboard: Dashboard): Dashboard[] => {
		const hiddenDashboards = this.security.loggedUser.hiddenDashboards;
		return _.chain(this.dashboardListService.getCurrentDashboardsList())
			.filter(treeItem => !this.isFolder(treeItem))
			.filter((dashboard: Dashboard) => {
				return dashboard.permissions && dashboard.permissions.EDIT
					&& dashboard.type === DashboardType.DASHBOARD && !hiddenDashboards[dashboard.id.toString()]
					&& (!currentDashboard || dashboard.id !== currentDashboard.id)
					&& this.filterStudioAdmin(currentDashboard, dashboard);
			})
			.value() as Dashboard[];
	};

	private filterStudioAdmin(source: Dashboard, target: Dashboard): boolean {
		if (!source) { // creating widget from alert, driver, or interaction explorer
			return !DashboardUtils.isStudioAdminDashboard(target);
		}
		if (DashboardUtils.isStudioAdminDashboard(target)) {
			return DashboardUtils.isStudioAdminDashboard(source);
		}
		return true;
	}

	showDashboardComponents(dashboard: any): ng.IPromise<any> {
		return this.$uibModal.open({
			component: 'componentsModal',
			resolve: {
				asset: () => {
					return {
						assetId: dashboard.id,
						name: dashboard.name,
						type: dashboard.type
					};
				},
				projectSelection: () => {
					return {
						contentProviderId: parseInt(dashboard.properties.cbContentProvider, 10),
						accountId: parseInt(dashboard.properties.cbAccount, 10),
						projectId: parseInt(dashboard.properties.project, 10)
					};
				},
				dashboards: () => [dashboard]
			},
			size: 'lg',
			backdrop: 'static'
		}).result;
	}

	showBookComponents(book: any): ng.IPromise<any> {
		return this.$uibModal.open({
			component: 'componentsModal',
			resolve: {
				asset: () => {
					return {
						assetId: book.id,
						name: book.name,
						type: book.type
					};
				},
				book: () => book,
				dashboards: () => this.dashboardListService.getCurrentDashboardsList(),
			},
			size: 'lg',
			backdrop: 'static'
		}).result;
	}

	copyBookLink = (book: Book, event: Event): void => {
		this.clipboardService.copyToClipboard(this.urlService.getBookUrl(book.id), event.target);

		this.globalNotificationService.addSuccessNotification(this.locale.getString('common.linkCopied'));
	};

	copyDashboardLink = (dashboard: Dashboard, event: Event): void => {
		this.clipboardService.copyToClipboard(this.urlService.getDashboardUrl(dashboard.id), event.target);

		this.globalNotificationService.addSuccessNotification(this.locale.getString('common.linkCopied'));
	};

	private isNewTablesEnabled(): boolean {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_TABLES);
	}
}

app.service('dashboardService', DashboardService);
