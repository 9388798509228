import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { AnalyticDataUtils, HasChildren } from '@app/modules/widget-visualizations/utilities/analytic-data-utils.class';
import { ReportErrorMessagesService } from '@app/modules/widget-visualizations/utilities/report-error-messages.service';
import { SortUtils } from '@app/shared/util/sort-utils';
import { ObjectUtils } from '@app/util/object-utils';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { IDataPointObject } from '@cxstudio/reports/entities/report-definition';
import { AttributeUrlType } from '@cxstudio/reports/providers/cb/constants/attribute-url-type.constant';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { GroupingUtils } from '@cxstudio/reports/utils/analytic/grouping-utils.class';
import { ReportNumberFormatUtils } from '@cxstudio/reports/utils/report-number-format-utils.service';
import { TableServiceUtils } from '@cxstudio/reports/utils/table-service-utils';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { DatePeriodName } from '@cxstudio/reports/entities/date-period';
import { PopDiffPrefix } from './constants/pop-difference-prefix.constant';
import { PeriodOverPeriodMetricService } from '../reports/providers/cb/period-over-period/period-over-period-metric.service';

type NestedDataPointObject = IDataPointObject & HasChildren<any>;

/**
 * Utility service for table visualizations
 */
export class TableService {
	checks;

	constructor(
		private readonly $rootScope,
		private readonly locale: ILocale,
		private readonly $log,
		private readonly reportUtils: ReportUtils,
		private readonly reportErrorMessages: ReportErrorMessagesService,
		private readonly reportNumberFormatUtils: ReportNumberFormatUtils,
		private readonly gridUtils: GridUtilsService,
		private readonly metricConstants: MetricConstants,
		private readonly periodOverPeriodMetricService: PeriodOverPeriodMetricService
	) {
		this.checks = {
			simple: (data) => data && (data.doc_count > 0 || data.volume > 0 || data.distinctcase > 0),
			preview: (data) => data && (data.chunks && data.chunks.length > 0),
			an_metric: (data) => data && (data.volume > 0 || data.period_2_volume > 0),
			analytics: (data) => data && data.volume > 0,
			isDefined: (data) => data,
			// eslint-disable-next-line id-blacklist
			undefined: (data) => {
				//If we're not sure of report type, check all
				return (this.checks.simple(data) ||
					this.checks.preview(data) ||
					this.checks.an_metric(data) ||
					this.checks.analytics(data));
			}
		};
	}

	private fulfillMetricData(obj, columns): void {
		for (let col of columns) {
			if (col.field === 'sentiment_score') {
				// eslint-disable-next-line no-bitwise
				obj.sentimentIndex = Math.random() * 5 >> 0;
				obj[col.field] = Math.random() + obj.sentimentIndex - 2.5;
			} else if (col.field !== 'sentiment_breakdown') {
				obj[col.field] = 2.3 + Math.random() * 100;
			} else {
				//sentiment breakdown
				obj.sentiment_strongly_negative = 10;
				obj.sentiment_negative = 20;
				obj.sentiment_neutral = 40;
				obj.sentiment_positive = 20;
				obj.sentiment_strongly_positive = 10;
				obj.sentiment_none = 10;
			}
		}
	}

	private fulfillAttrData(obj, columns, index): void {
		let offset = Math.min(index % (columns.length + 2), columns.length - 1);

		// eslint-disable-next-line no-bitwise
		let attrValue = 'volume' + (index / (columns.length + 2) >> 0);
		// query params are required for proper hierarchy building
		let imageUrl = `${window.location.origin + window.location.pathname}/img/widgets/empty_image.png?data=${attrValue}`;
		let linkUrl = `https://www.clarabridge.com/?data=${attrValue}`;

		for (let i = 0; i <= offset; i++) {
			let value;
			if (columns[i].type === AttributeUrlType.IMAGE) {
				value = imageUrl;
			} else if (columns[i].type === AttributeUrlType.LINK) {
				value = linkUrl;
			} else {
				value = attrValue;
			}
			obj[columns[i].field] = value;
		}
		obj.level = offset;
		obj.leaf = ((offset + 1) === columns.length);
	}



	private hasAnyData(dataObject, dataField, dataStructure): boolean {
		return dataObject[dataField].filter(this.checks[dataStructure]).length > 0 ;
	}

	private getDataStructureFromReportType(reportType?): string {
		if (_.isUndefined(reportType)) {
			return 'undefined';
		}
		if (reportType === 'definition_table') {
			return 'isDefined';
		}
		if (reportType === 'cb_an_metric') {
			return 'an_metric';
		}
		if (reportType === 'cb_an_preview') {
			return 'isDefined';
		}
		if (reportType.indexOf('cb_an') !== -1) {
			return 'analytics';
		}
		if (reportType.indexOf('simple') !== -1) {
			return 'simple';
		}
		return 'undefined';
	}


	private registerSorter(dataView, options, grid, parentWidgetName,
		element, parentScope, tableSorter): void {
		grid.onSort.subscribe((e, args) => {
			tableSorter(e, args, dataView, options, grid, parentScope);
		});
	}

	private markWidgetAsRendered(utils): void {
		if (!utils) {
			return;
		}

		this.reportUtils.handleWidgetRenderedEvent(utils.widgetId, utils.widgetType, utils.containerId);
	}

	processIfNoData = <T extends {metadata; total}= any>(element, dataObject: T, dataField: keyof T = 'data' as keyof T,
		reportType?: WidgetType | string, utils?): boolean => {
		if (_.isUndefined(dataObject)) {
			this.reportErrorMessages.putInternalServerError(element);
			return false;
		}

		// Check if dataObject is empty, also need to check
		// if dataObject is undefined
		if (dataObject && (_.isUndefined(dataObject[dataField]) || dataObject[dataField] === null)) {
			this.reportErrorMessages.putInvalidDataFormatError(element);
			this.markWidgetAsRendered(utils);
			return false;
		}

		if (!this.hasAnyData(dataObject, dataField, this.getDataStructureFromReportType(reportType))) {
			if (dataObject.metadata.filteredByConfidentiality && dataObject.total.volume !== 0) {
				this.reportErrorMessages.putFilteredByConfidentiality(element);
			} else {
				this.reportErrorMessages.putNoDataWarning(element);
			}
			this.markWidgetAsRendered(utils);
			return false;
		}

		return true;
	};

	processIfNoDataMetric = (element, dataObject, dataField, utils): boolean => {
		if (!dataField) {
			dataField = 'data';
		}

		if (_.isUndefined(dataObject)) {
			element.empty();
			return false;
		}

		if (dataObject && isEmpty(dataObject[dataField])) {
			if (dataObject.metadata.filteredByConfidentiality && dataObject.total.volume !== 0) {
				this.reportErrorMessages.putFilteredByConfidentiality(element);
			} else {
				this.reportErrorMessages.putNoDataWarning(element);
			}
			this.markWidgetAsRendered(utils);
			return false;
		}

		return true;
	};

	processIfNoDataMultimetric = (element, dataObject, utils): boolean => {
		let dataPresent = false;
		for (let data of dataObject.data) {
			if (data.distinctcase > 0) {
				dataPresent = true;
				break;
			}
		}

		if (!dataPresent) {
			if (dataObject.metadata.filteredByConfidentiality && dataObject.total.volume !== 0) {
				this.reportErrorMessages.putFilteredByConfidentiality(element);
			} else {
				this.reportErrorMessages.putNoDataWarning(element);
			}
			this.markWidgetAsRendered(utils);
			return false;
		}

		return true;
	};

	wrapColumns = (cols): any[] => {
		if (this.$rootScope.isMobile) {
			let newCols = [];
			cols.forEach((col) => {
				let newCol = ObjectUtils.copy(col);
				newCol.formatter = this.wrapMobileFormatter(col.formatter);
				newCols.push(newCol);
			});
			return newCols;
		}
		return cols;
	};

	wrapMobileFormatter = (formatter) => {
		return (row, cell, value, columnDef, dataContext) => {
			let _value = formatter ? formatter(row, cell, value, columnDef,
				dataContext) : value;
			if (!_value)
				_value = '';
			return '<span class=\'property\'>&nbsp;&nbsp;' + columnDef.name +
				':&nbsp;</span>' +
				'<span class=\'value\'>' + _value + '&nbsp;</span>';
		};
	};

	postRenderMobile = (element) => {
		TableServiceUtils.postRenderMobile(element);
	};
	postRenderRegular = (element) => {
		TableServiceUtils.postRenderRegular(element);
	};


	getColumns = (dataObject, utils) => {
		if (!_.isUndefined(dataObject) && !_.isUndefined(dataObject.columns)) {
			dataObject.columns.forEach((col) => {
				col.cssClass = `__col__${col.id}`;
				col.name = this.gridUtils.replaceHTML(col.name);
			});
			this.$log.debug('getColsFromData', dataObject.columns);
			return dataObject.columns;
		}

		if (!_.isUndefined(utils) && !_.isUndefined(utils.allColumns)) {
			utils.allColumns.forEach((col) => {
				col.cssClass = `__col__${col.id}`;
				col.name = this.gridUtils.replaceHTML(col.name);
			});
			this.$log.debug('getColsFromOpt', utils.allColumns);
			return utils.allColumns;
		}

		let data = [];
		if (!_.isUndefined(dataObject.rawData))
			data = dataObject.rawData;
		if (!_.isUndefined(dataObject.data))
			data = dataObject.data;

		if (data.length > 0) {
			let result = Object.keys(data[0]);
			let columns = [];
			_.forEach(result, (k) => {
				if (k !== 'id') {
					let column = {
						id: k,
						name: this.gridUtils.replaceHTML(k.toUpperCase()),
						field: k,
						width: 50,
						sortable: true,
						cssClass: k
					};
					columns.push(column);
				}
			});
			this.$log.debug('getColsFromCols', columns);
			return columns;
		}
		this.$log.debug('no columns found!!!!');
	};

	isColumnsChanged = (columns1, columns2) => {
		if (columns1.length !== columns2.length)
			return true;
		let len = columns1.length;
		for (let i = 0; i < len; i++) {
			if (columns1[i].name !== columns2[i].name)
				return true;
		}
		return false;
	};

	gridSorter = (e, args, dataView, options, grid) => {
		let cols = args.sortCols;
		dataView.sort((dataRow1, dataRow2) => {
			for (let i = 0, l = cols.length; i < l; i++) {
				let field = cols[i].sortCol.field;
				let sign = cols[i].sortAsc ? 1 : -1;
				//For comparing numbers vs strings, need to check if its number by removing ,
				let value1 = dataRow1[field];
				let value2 = dataRow2[field];

				if (isNaN(value1) !== isNaN(value2)) {
					return isNaN(value1) ? 1 : -1;
				}

				let directionalMultiplier = value1 > value2 ? 1 : -1;
				let result = value1 === value2 ? 0 : directionalMultiplier * sign;

				if (result !== 0) {
					return result;
				}
			}
			return 0;
		});

		options.sortBy = cols[0].sortCol.field;
		options.direction = cols[0].sortAsc ? SortDirection.ASC : SortDirection.DESC;
		grid.invalidate();
		grid.render();

	};

	analyticTableGridSorter = (e, args, dataView, options, grid, parentScope) => {
		let cols = args.sortCols;
		let activeCell = grid.getActiveCell();
		let highlightedItem;
		if (activeCell) {
			highlightedItem = dataView.getItemByIdx(activeCell.row);
		}

		let multiColumnSorter = (dataRow1, dataRow2) => {
			for (let i = 0, l = cols.length; i < l; i++) {
				let direction = cols[i].sortAsc ? 1 : -1;
				let customSorter = cols[i].sortCol.sorter;
				let result = !_.isUndefined(customSorter) ?
					customSorter(dataRow1, dataRow2) * direction :
					defaultSorter(cols[i], dataRow1, dataRow2);

				if (result !== 0) {
					return result;
				}
			}
			return 0;
		};

		let defaultSorter = (column, dataRow1, dataRow2) => {
			let field = column.sortCol.field;
			let comparator;
			if (column.sortCol.dataType === 'number') {
				let direction = column.sortAsc ? 1 : -1;
				comparator = SortUtils.getNumericComparator(field, direction);
			} else {
				let sortDirection = column.sortAsc ? SortDirection.ASC : SortDirection.DESC;
				let sortByFunc = (row) => {
					return row[field] === undefined || row[field] === null
						? row[field]
						: row[field].toString();
				};
				comparator = SortUtils.getAlphanumericComparator(sortByFunc, sortDirection);
			}
			return comparator(dataRow1, dataRow2);
		};

		let updateTooltips = (sortCols) => {
			_.each(grid.getColumns(), (column) => {
				column.toolTip = column.name;
			});
			_.each(sortCols, (sort) => {
				let column = sort.sortCol;
				if (!column.name) { // automatic sort, need to find real column
					column = _.findWhere(grid.getColumns(), {field: column.field}) || column;
				}
				let sortSuffix = sort.sortAsc
					? this.locale.getString('widget.sortAscending')
					: this.locale.getString('widget.sortDescending');
				column.toolTip = `${column.name} - ${sortSuffix}`;
			});
		};

		let getAdditionalSortings = (sorting, groupingsInternal, columns) => {
			let groupSortings = [];
			if (!groupingsInternal) return groupSortings;

			let field = sorting.sortCol.field;
			groupingsInternal.forEach((grouping) => {
				if (grouping.identifier !== field) {
					let sort = {
						sortCol: {
							field: grouping.identifier,
							sorter: undefined
						},
						sortAsc: sorting.sortAsc
					};

					let sortColumn = _.findWhere(columns, {field: grouping.identifier});
					if (!_.isUndefined(sortColumn) &&
							!_.isUndefined(sortColumn.sorter)) {
						sort.sortCol.sorter = sortColumn.sorter;
					}

					groupSortings.push(sort);
				}
			});
			return groupSortings;
		};

		updateTooltips(cols);
		let groupings = (parentScope && parentScope.utils)
			? GroupIdentifierHelper.getGroupings(parentScope.utils.selectedAttributes)
			: undefined;

		if (options.showTotal && groupings && !_.findWhere(groupings, {identifier: args.sortCols[0].sortCol.field})) {
			let data = parentScope.tableData;
			let sortBy = cols[0].sortCol.field;
			let dir = cols[0].sortAsc ? 1 : -1;
			let comparator = SortUtils.getNumericComparator(sortBy, dir);

			let hierarchyData = AnalyticDataUtils.getHierarchyData(data, groupings);
			let sortedHierarchyData = AnalyticDataUtils.recursiveSort(hierarchyData, comparator);
			parentScope.tableData = AnalyticDataUtils.transformToFlat<NestedDataPointObject>(sortedHierarchyData as NestedDataPointObject[]);
			dataView.setItems(parentScope.tableData);

			grid.getColumns().forEach((column) => {
				column.formatter = this.reportNumberFormatUtils.wrapFormatterForShowTotal(column.formatter, options);
			});
		} else {
			options.showTotal = false;
			dataView.setFilterArgs({
				showTotal: options.showTotal
			});

			let calculations = parentScope && parentScope.utils && parentScope.utils.getMetricNames()
				? parentScope.utils.getMetricNames() : [];
			cols.forEach((sortColumn) => {
				if (_.contains(calculations, sortColumn.sortCol.field)) {
					sortColumn.sortCol.dataType = 'number';
				}
			});

			if (cols.length === 1) {
				let columns = (parentScope && parentScope.utils
						&& parentScope.utils.allColumns)
					? parentScope.utils.allColumns : [];

				let groupSortings = getAdditionalSortings(
					cols[0], groupings, columns);
				cols = cols.concat(groupSortings);
			}

			this.$log.debug('on sort', dataView);
			dataView.sort(multiColumnSorter);
		}

		options.sortBy = cols[0].sortCol.field;
		options.direction = cols[0].sortAsc ? SortDirection.ASC : SortDirection.DESC;
		if (highlightedItem && activeCell) {
			grid.resetActiveCell();
			grid.setActiveCell(dataView.getRowById(highlightedItem.id), activeCell.cell);
		}
		grid.invalidate();
		grid.render();
	};

	previewTableGridSorter = (e, args, dataView, options, grid) => {
		// sorting is performed by processing table data in another place.
		// Here we only processing settings and update slick gtid UI
		let cols = args.sortCols;
		if (options.direction === SortDirection.DESC && cols[0].sortAsc) {
			delete options.sortBy;
			delete options.direction;
			delete cols[0].sortAsc;

			let sortHeader = _.find(e.currentTarget.children, (childNode) => {
				return childNode.id.endsWith(cols[0].sortCol.field);
			});

			let arrowSpan = _.find(sortHeader.children, (childNode) => {
				return childNode.className.indexOf('slick-sort-indicator') !== -1;
			});

			if (arrowSpan) {
				arrowSpan.className = 'slick-sort-indicator';
			}

			grid.setSortColumns([]);
		} else {
			options.sortBy = cols[0].sortCol.field;
			options.direction = cols[0].sortAsc ? SortDirection.ASC : SortDirection.DESC;
		}
	};

	gridSingleSorter = <T>(columnField: keyof T, isAsc: boolean, grid, gridData: T[]) => {
		let sign = isAsc ? 1 : -1;

		gridData.sort((dataRow1, dataRow2) => {
			let value1: any = dataRow1[columnField];
			let value2: any = dataRow2[columnField];

			if (isNaN(value1) !== isNaN(value2)) {
				return isNaN(value1) ? 1 : -1;
			}

			let directionalMultiplier = value1 > value2 ? 1 : -1;
			return (value1 === value2) ? 0 : directionalMultiplier * sign;
		});
	};

	createTable = (extCols, append, dataView, tableOptions,
		populateCustomRenderers, options, columnsAll, showPoP: boolean = false, groupingsCount: number) => {
		let grid;
		let initialColumns;

		if (!_.isUndefined(extCols)) {
			extCols = this.retranslateColumns(extCols, options.periodLabel, showPoP, groupingsCount);
			grid = new Slick.Grid(append, dataView, this.wrapColumns(
				populateCustomRenderers(extCols)), tableOptions);
			initialColumns = ObjectUtils.copy(extCols);
		} else {
			let columns;
			if (_.isUndefined(options.table) || _.isUndefined(options.table
				.columns) || !options.table.columns) {
				columns = columnsAll;
			} else {
				columns = options.table.columns;
			}
			columns = this.retranslateColumns(columns, options.periodLabel, showPoP, groupingsCount);
			grid = new Slick.Grid(append, dataView, this.wrapColumns(
				populateCustomRenderers(columns)), tableOptions);
			initialColumns = ObjectUtils.copy(columns);
		}

		grid.registerPlugin(new Slick.AutoTooltips({
			enableForHeaderCells: true
		}));
		return { grid, initialColumns };
	};

	retranslateColumns = (columns, periodLabels, showPoP: boolean = false, groupingsCount: number = 1): any[] => {
		return columns.map((col, index) => {
			let baseFieldName = col.historicMetric ?
				this.getPOPBaseMetric(col.field) :
				col.field;
			let baseConstant = this.metricConstants.getMetricByName(baseFieldName);
			let customizedColumnName;
			let isGrouping = index < groupingsCount;
			if (baseConstant && !isGrouping) {
				if (col.historicMetric) {
					if (periodLabels[DatePeriodName.PERIOD2]?.length && col.field.indexOf(PopDiffPrefix.HISTORIC) === 0) {
						customizedColumnName = periodLabels[DatePeriodName.PERIOD2] + ' ' + baseConstant.displayName;
					} else {
						customizedColumnName = this.periodOverPeriodMetricService
							.getActualPeriodOverPeriodMetricDisplayName({...col, name: col.id, isPopMetric: true}, baseConstant.displayName);
					}
				} else if (showPoP && periodLabels[DatePeriodName.PERIOD1]?.length > 0) {
					customizedColumnName = periodLabels[DatePeriodName.PERIOD1] + ' ' + baseConstant.displayName;
				}
			}
			let displayName = customizedColumnName ?? baseConstant?.displayName ?? col.displayName;
			let toolTip = customizedColumnName ?? baseConstant?.displayName ?? col.toolTip;
			let name = customizedColumnName ?? baseConstant?.displayName ?? col.name;

			return {
				...col,
				displayName,
				toolTip,
				name
			};
		});
	};

	getPOPBaseMetric = (field: string): string => {
		return field
			.replace(new RegExp(`^${PopDiffPrefix.HISTORIC}`), '')
			.replace(new RegExp(`^${PopDiffPrefix.DELTA}`), '')
			.replace(new RegExp(`^${PopDiffPrefix.P_VALUE}`), '')
			.replace(new RegExp(`^${PopDiffPrefix.SIGNIFICANCE}`), '')
			.replace(new RegExp(`^${PopDiffPrefix.PERCENT_CHANGE}`), '');
	};

	registerColumnsResizeHandler = (options, grid) => {
		grid.onColumnsResized.subscribe((e, args) => {
			if (!_.isUndefined(options.table)) {
				options.table.columns = args.grid.getColumns();
			} else {
				options.table = {
					columns: args.grid.getColumns()
				};
			}

		});
	};

	registerCommonHandlers = (dataView, options, grid, parentWidgetName, element) => {
		grid.onHeaderCellRendered.subscribe((e, args) => {
			let cols = args.grid.getColumns();
			if (args.column.id === cols[0].id) {
				if (!_.isUndefined(options.table))
					options.table.columns = cols;
				else
					options.table = {
						columns: cols
					};
			}
		});

		// wire up model events to drive the grid
		dataView.onRowCountChanged.subscribe((e, args) => {

			grid.updateRowCount();
			grid.render();

			if (this.$rootScope.isMobile) {
				this.postRenderMobile(element);
			}
		});

		dataView.onRowsChanged.subscribe((e, args) => {
			grid.invalidateRows(args.rows);
			grid.render();

			if (this.$rootScope.isMobile) {
				this.postRenderMobile(element);
			}
		});

	};

	registerGridSorter = (dataView, options, grid, parentWidgetName,
		element, parentScope?) => {
		this.registerSorter(dataView, options, grid, parentWidgetName, element,
			parentScope, this.gridSorter);
	};

	registerAnalyticTableGridSorter = (dataView, options, grid, parentWidgetName,
		element, parentScope) => {
		this.registerSorter(dataView, options, grid, parentWidgetName, element,
			parentScope, this.analyticTableGridSorter);
	};

	registerPreviewTableGridSorter = (dataView, options, grid, parentWidgetName,
		element, parentScope) => {
		this.registerSorter(dataView, options, grid, parentWidgetName, element,
			parentScope, this.previewTableGridSorter);
	};

	getAnalyticTableData = (dataObject, selectedAttributes) => {
		_.each(dataObject.data, (row, index) => { row.id = index; });
		return this.getProcessedTableData(dataObject.data, selectedAttributes);
	};

	getProcessedTableData = (data, selectedAttributes, popLevel?) => {
		let result = data;
		_.chain(selectedAttributes)
			.filter(AnalyticMetricTypes.isTime)
			.filter(GroupingUtils.isDoNotShowEmptyPeriods)
			.each((group) => {
				result = !_.isUndefined(popLevel)
					? AnalyticDataUtils.removeEmptyPointsForPop(result,
						selectedAttributes.indexOf(group), selectedAttributes, popLevel)
					: AnalyticDataUtils.removeEmptyPointsForTable(result,
						selectedAttributes.indexOf(group), selectedAttributes);
			}).value();

		return AnalyticDataUtils.removeHiddedDataPoints(result);
	};

	getAnalyticTableDemoData = (columns) => {

		//because this widget has too many combinations, so generating data on the fly
		//show at least three leaf rows. all attr has 'value' as value field, 2.3 as metric value
		//breakdown is 10/20/40/20/10
		if (!columns || columns.length < 1) return;

		let data = [];

		//split columns to attrs and metric
		let attrCols = [];
		let metricCols = [];
		for (let col of columns) {
			if (col.attribute)
				attrCols.push(col);
			else
				metricCols.push(col);
		}
		//generate attrs columns
		let num = (attrCols.length + 2) * 3;

		for (let j = 0; j < num; j++) {
			let obj = {
				id: j
			};
			this.fulfillAttrData(obj, attrCols, j);
			this.fulfillMetricData(obj, metricCols);
			data.push(obj);
		}
		let total = {
			id: -1
		};
		this.fulfillMetricData(total, metricCols);
		return { data, total };
	};

	slickDefinitionCustomRenderer = (customFormatters, postRenderers, customSorters) => {
		return (columns) => {
			let newColumns = [];

			columns.forEach((column) => {
				let newColumn = ObjectUtils.copy(column);
				if (!_.isUndefined(newColumn.formatterName)) {
					if ('sentiment' !== newColumn.formatterName && newColumn.formatter) {
						let existingFormatter = newColumn.formatter;
						newColumn.formatter = (row, col, value) => {
							let formattedVal = customFormatters[newColumn.formatterName](row, col, value);
							return existingFormatter(row, col, formattedVal);
						};
					} else {
						newColumn.formatter = customFormatters[newColumn.formatterName];
					}
				}

				if (!_.isUndefined(newColumn.objectField)) {
					let wrappedFormatter = newColumn.formatter;
					newColumn.formatter = (row, cell, value, columnDef, dataContext) => {
						let objectFieldValue = value[newColumn.objectField];
						return wrappedFormatter(row, cell, objectFieldValue, columnDef, dataContext);
					};
				}

				if (!_.isUndefined(newColumn.asyncPostRenderName)) {
					newColumn.asyncPostRender = postRenderers[newColumn.asyncPostRenderName];
				}

				if (!_.isUndefined(customSorters)) {
					newColumn.sorter = customSorters[newColumn.field];
				}
				newColumn.toolTip = newColumn.name;

				newColumns.push(newColumn);
			});

			return newColumns;
		};
	};
}

app.factory('tableService', TableService);
