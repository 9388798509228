import ILocale from '@cxstudio/interfaces/locale-interface';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { TimePrimaryGroup, TimePrimaryGroups, TrendBy } from '@cxstudio/reports/attributes/time-primary-group.enum';
import * as _ from 'underscore';

export class TimePrimaryGroupsService {
	YEAR = { name: 'time.year', metricType: AnalyticMetricType.TIME,
		displayName: this.locale.getString('filter.year'), trendBy: TrendBy.YEAR };
	QUARTER = { name: 'time.quarter', metricType: AnalyticMetricType.TIME,
		displayName: this.locale.getString('filter.quarter'), trendBy: TrendBy.QUARTER };
	MONTH = { name: 'time.month', metricType: AnalyticMetricType.TIME,
		displayName: this.locale.getString('filter.month'), trendBy: TrendBy.MONTH };
	WEEK = { name: 'time.week', metricType: AnalyticMetricType.TIME,
		displayName: this.locale.getString('filter.week'), trendBy: TrendBy.WEEK, isDate: true };
	DAY = { name: 'time.day', metricType: AnalyticMetricType.TIME,
		displayName: this.locale.getString('filter.day'), trendBy: TrendBy.DAY, isDate: true };
	HOUR = { name: 'time.hour', metricType: AnalyticMetricType.TIME,
		displayName: this.locale.getString('filter.hour'), trendBy: TrendBy.HOUR, isDate: true };
	//fake value for formatting week with custom description
	WEEK_WITH_DES = { name: 'time.week.des', metricType: AnalyticMetricType.TIME,
		displayName: this.locale.getString('filter.week'), trendBy: TrendBy.WEEK_WITH_DES, isDate: true };

	values = [ this.YEAR, this.QUARTER, this.MONTH,
		this.WEEK, this.DAY, this.HOUR ]; //option values

	constructor(
		private locale: ILocale,
		private DateRange,
	) {

	}

	getHistoricOptions(name: TimePrimaryGroup): any[] {
		let values = TimePrimaryGroups.getValues();
		let index = values.indexOf(name);
		let biggerGroups = values.slice(0, index);
		return biggerGroups.map(groupName => this.getHistoricOption(groupName))
			.filter(dateOption => !!dateOption)
			.map(dateOption => dateOption.value);
	}

	private getHistoricOption(name: TimePrimaryGroup): any {
		switch (name) {
			case TimePrimaryGroup.YEAR: return this.DateRange.historicOptions.SAME_LAST_YEAR;
			case TimePrimaryGroup.QUARTER: return this.DateRange.historicOptions.SAME_LAST_QUARTER;
			case TimePrimaryGroup.MONTH: return this.DateRange.historicOptions.SAME_LAST_MONTH;
			case TimePrimaryGroup.WEEK: return this.DateRange.historicOptions.SAME_LAST_WEEK;
			default: return undefined;
		}
	}

	getTrendBy(name: TimePrimaryGroup): TrendBy {
		return _.findWhere(this.values, {name})?.trendBy;
	}
}

app.service('timePrimaryGroups', TimePrimaryGroupsService);
