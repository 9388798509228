import * as _ from 'underscore';
import { ConversationChartOptions, ConversationType } from './conversation-chart-options.class';
import { ConversationChartUtils } from './conversation-chart-utils.class';
import { ConversationDataPoint } from './conversation-data-point.class';
import { ConversationSpineState } from '@cxstudio/conversation/conversation-style-utils.class';
import { ChartPathUtils } from './chart-path-utilities.class';
import { BubbleRoundingOptions } from './bubble-rounding-options.enum';
import { IChartSVG } from '@cxstudio/conversation/conversation-chart.class';
import { RandomUtils } from '@app/util/random-utils.class';
import { ApplicationTheme } from '@cxstudio/header/application-theme';
import { SpineTooltipManager } from '@cxstudio/conversation/spine-tooltip-manager.class';

export type ConversationDataFilter = (data: ConversationDataPoint, index: number) => boolean;

export class BasicConversationChart {
	conversation: IChartSVG;
	config: ConversationChartOptions;
	dataSet: ConversationDataPoint[];
	tooltipManager: SpineTooltipManager;
	staticWidth: number;
	width: number;
	chartUtils: ConversationChartUtils = new ConversationChartUtils();
	pathUtils: ChartPathUtils;

	private chartIdPrefix = RandomUtils.randomString(); // random id prefix to use for path links

	constructor(dataSet, config: ConversationChartOptions, rootSvg: IChartSVG, tooltip: SpineTooltipManager) {
		this.dataSet = dataSet;
		this.config = config;
		this.tooltipManager = tooltip;
		this.width = config.basicChart.width;
		this.pathUtils = new ChartPathUtils(config);
		this.staticWidth = this.width - ((this.config.basicChart.padding * 2) + this.config.basicChart.channelOffset);

		this.conversation = rootSvg.append('g')
			.attr('id', 'spine-conversation');
		this.conversation.append('style').text(config.basicChart.style);

		if (config.type === ConversationType.CHAT) {
			// rendering silence labels before main chart to keep it behind (for hover behavior)
			this.renderSilence();
		}
		this.renderData();
	}

	private renderData(): void {
		this.conversation.append('g')
			.selectAll('path')
			.data(this.dataSet)
			.enter()
			.append('path')
			.attr('d', (data: ConversationDataPoint, i: number) => {
				let height: number = data.height;
				let width: number = this.getWidth(data);
				let xPos: number = this.getXPos(data);
				let yPos: number = data.yPosition;
				let rounding: BubbleRoundingOptions = this.getRoundingRequirement(i);

				return this.pathUtils.getPathD(xPos, yPos, height, width, rounding);
			})
			.attr('class', this.getPointClass)
			.on('mouseover', (event: MouseEvent, data: ConversationDataPoint) => this.mouseoverElement(data, event))
			.on('mouseout', this.mouseout)
			.on('click', (event: MouseEvent, data: ConversationDataPoint) => this.click(data, event));
	}

	private renderSilence(): void {
		let silences = _.filter(this.dataSet, data => data.isSilence);

		let silenceGroup = this.conversation.append('g');
		// invisible line to draw a centered text over it
		silenceGroup.selectAll('path')
			.data(silences)
			.enter()
			.append('path')
			.attr('id', this.getElementId)
			.attr('d', (data: ConversationDataPoint) => {
				let height: number = data.height;
				let width: number = this.getWidth(data);
				let xPos: number = this.getXPos(data);
				let topPos: number = data.yPosition;
				let y = topPos + height - 3; // vertically centered
				return this.pathUtils.getLine(xPos, y, xPos + width, y);
			})
			.attr('fill', 'transparent');

		silenceGroup.selectAll('text')
			.data(silences)
			.enter()
			.append('text')
			.append('textPath')
			.attr('stroke', '#43444f')
			.attr('text-anchor', 'middle')
			.attr('startOffset', '50%')
			.attr('font-size', '12px')
			.attr('xlink:href', (data, index) => '#' + this.getElementId(data, index))
			.text(data => data.text)
			.on('click', (event: MouseEvent, data: ConversationDataPoint) => this.click(data, event));
	}

	private getElementId = (data: ConversationDataPoint, index: number): string => {
		let id = `${data.timestamp}_${index}`;
		return `${this.chartIdPrefix}_${id}`;
	};

	private getWidth = (data: ConversationDataPoint): number => {
		if (data.isSilence) return this.width - (this.config.basicChart.padding * 2);

		return this.staticWidth ? this.staticWidth : data.duration;
	};

	private getXPos = (data: ConversationDataPoint): number => {
		return data.channel === this.config.basicChart.rightChannel
			|| data.channel === this.config.basicChart.botChannel
			? this.config.basicChart.padding + this.config.basicChart.channelOffset
			: this.config.basicChart.padding;
	};

	private click = (data: ConversationDataPoint, event: MouseEvent): void => {
		if (data?.events?.click)
			data.events.click();
		this.select(data);
		this.tooltipManager.hideTooltip();
	};

	showTooltipForPoint = (data: ConversationDataPoint, spinePath: Element): void => {
		this.mouseoverElement(data, null, spinePath);
		$(document).one('mousedown', () => this.tooltipManager.hideTooltip());
	};

	private getPointClass = (data: ConversationDataPoint, i: number): string => {
		let classes = `cb-spine-item ${this.config.basicChart.pointClass(data)} ${data.isFiltered ? 'filtered' : ''}`;
		return classes;
	};

	private mouseoverElement(data: ConversationDataPoint, event: MouseEvent, target?: Element): void {
		if (this.config.getTooltip) {
			this.tooltipManager.showTooltip(this.config.getTooltip(data), this.config.theme, event, target);
		}
	}

	private mouseout = (): void => {
		this.tooltipManager.hideTooltip();
	};

	private applyState(filterFunction: ConversationDataFilter, state: ConversationSpineState): void {
		this.conversation
			.selectAll('path')
			.filter(filterFunction)
			.classed(state, true)
			.raise();
	}

	private clearState(state: ConversationSpineState): void {
		this.conversation
			.selectAll('path')
			.classed(state, false);
	}

	clearHighlight = (): void => {
		this.clearState(ConversationSpineState.highlighted);
	};

	highlight = (filterFunction: ConversationDataFilter): void => {
		this.clearHighlight();
		this.applyState(filterFunction, ConversationSpineState.highlighted);
	};

	hoverHighlight = (filterFunction: ConversationDataFilter): void => {
		this.clearState(ConversationSpineState.topicHover);
		this.conversation.classed(ConversationSpineState.topicHover, true);
		this.applyState(filterFunction, ConversationSpineState.topicHover);
	};
	topicHighlight = (filterFunction: ConversationDataFilter): void => {
		this.clearState(ConversationSpineState.topicHighlight);
		this.conversation.classed(ConversationSpineState.topicHighlight, true);
		this.applyState(filterFunction, ConversationSpineState.topicHighlight);
	};

	clearHover = (): void => {
		this.conversation.classed(ConversationSpineState.topicHover, false);
		this.clearState(ConversationSpineState.topicHover);
	};

	clearTopicHighlight = (): void => {
		this.conversation.classed(ConversationSpineState.topicHighlight, false);
		this.clearState(ConversationSpineState.topicHighlight);
	};

	select = (dataPoint: ConversationDataPoint): void => {
		this.clearState(ConversationSpineState.selected);
		if (dataPoint)
			this.applyState(data => data === dataPoint, ConversationSpineState.selected);
	};

	private getRoundingRequirement = (index: number): BubbleRoundingOptions => {
		if (this.chartUtils.isStartOfCluster(this.dataSet, index, this.config.sameClusterPredicate))
			return BubbleRoundingOptions.TOP;
		if (this.chartUtils.isEndOfCluster(this.dataSet, index, this.config.sameClusterPredicate))
			return BubbleRoundingOptions.BOTTOM;
		if (this.chartUtils.isWrappedBySameCluster(this.dataSet, index, this.config.sameClusterPredicate))
			return BubbleRoundingOptions.NONE;

		return BubbleRoundingOptions.ALL;
	};

}
