import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';
import { ProjectAssetsErrors, ProjectAssetsLoading } from '@app/modules/units/project-selection-error/project-selection-error.component';
import * as _ from 'underscore';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { Security } from '@cxstudio/auth/security-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ProjectAccessLevelItems } from '@cxstudio/user-administration/users/project-access/project-access-levels';
import { FavoriteProperties } from '@cxstudio/auth/entities/favorite-properties';
import { Project } from '@cxstudio/user-administration/users/project-access/project-class';
import { ProjectAccess } from '@cxstudio/user-administration/users/project-access/project-access-class';
import { SessionPreferencesService } from '@app/core/storage/session-preferences.service';
import Authorization from '@cxstudio/auth/authorization-service';
import { AdminProjectsService } from '@cxstudio/internal-projects/admin-projects-service.service';
import { DataProvidersService } from '@cxstudio/services/data-providers.service';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';


export class ProjectSelectorController implements ng.IController {

	private static readonly PROJECT_SEPARATOR = '----------------------------------';

	loading: ProjectAssetsLoading;
	errors: ProjectAssetsErrors;
	allProjects: boolean;
	allProjectsLimit: boolean;
	noProjects: boolean;
	internalProjects: boolean;
	hasInternalProjects: boolean;
	preselected: FavoriteProperties;
	disable: boolean;
	projectsProvider: any;
	onAccountChange: ({$properties}: {$properties: Partial<IProjectSelection>}) => void;
	onProjectChange: ({$properties}: {$properties: Partial<IProjectSelection>}) => void;
	onProjectSelectionFinished: ({$properties}: {$properties: Partial<IProjectSelection>}) => void;
	alwaysShowSelectors: boolean;
	horizontalLabeledMode: boolean;
	hideProjects: boolean;
	projectAccess: string;
	withPermissions: boolean;
	labelled: boolean;

	props: Partial<IProjectSelection>;
	private contentProviders: any[];
	private accounts: any[];
	private projects: any[];
	private hasInitialSelection: boolean;
	loadingAccounts: boolean;
	loadingProjects: boolean;

	private lastContentProvider: number;
	private lastAccount: number;

	constructor(
		private $scope: ISimpleScope,
		private dataProvidersRefreshService: DataProvidersService,
		private security: Security,
		private ProjectAccessLevels: ProjectAccessLevelItems,
		private locale: ILocale,
		private contentProviderOptionsService: ContentProviderOptionsService,
		private authorization: Authorization,
		private adminProjectsService: AdminProjectsService,
		private sessionPreferences: SessionPreferencesService
	) { }

	$onInit(): void {
		_.extend(this.loading, {
			projects: false,
			accounts: false
		});

		this.alwaysShowSelectors = !!this.alwaysShowSelectors;
		this.horizontalLabeledMode = !!this.horizontalLabeledMode;
		this.allProjects = !_.isUndefined(this.allProjects) ? this.allProjects : true;
		this.allProjectsLimit = !_.isUndefined(this.allProjectsLimit) ? this.allProjectsLimit : false;
		this.hideProjects = !_.isUndefined(this.hideProjects) ? this.hideProjects : false;
		this.internalProjects = !_.isUndefined(this.internalProjects) ? this.internalProjects : false;
		this.projectsProvider = this.projectsProvider || this.dataProvidersRefreshService;
		this.props = {};
		this.contentProviders = [];
		this.accounts = [];
		this.projects = [];
		let initialSelection = this.resolveInitialSelection();
		if (initialSelection) {
			this.hasInitialSelection = true;
			this.props.contentProviderId = initialSelection.contentProviderId;
			this.projectsProvider.currentContentProviderId = initialSelection.contentProviderId;
			this.props.accountId = initialSelection.accountId;
			this.projectsProvider.currentAccountId = initialSelection.accountId;
			this.projectsProvider.currentProjectId = initialSelection.projectId;
			this.loadingAccounts = true;
			this.loadingProjects = true;
		}
		this.errors = this.errors || {};

		this.reloadContentProviders();
	}

	private resolveInitialSelection(): IProjectSelection {
		if (this.hasFavoriteSelection(this.preselected)) {
			return {
				contentProviderId: this.preselected.favoriteCP,
				accountId: this.preselected.favoriteAccount,
				projectId: this.preselected.favoriteProject
			};
		}

		let recentProject = this.sessionPreferences.getRecentProject(this.security.getMasterAccountId());
		if (recentProject) return recentProject;

		let favoriteProps = this.security.getFavoriteProperties() || {} as FavoriteProperties;
		if (this.hasFavoriteSelection(favoriteProps)) {
			return {
				contentProviderId: favoriteProps.favoriteCP,
				accountId: favoriteProps.favoriteAccount,
				projectId: favoriteProps.favoriteProject
			};
		}
	}

	private hasFavoriteSelection(props: FavoriteProperties): boolean {
		return !isEmpty(props)
			&& !isEmpty(props.favoriteCP)
			&& props.favoriteCP !== -1;
	}

	contentProviderChanged = () => {
		this.projectsProvider.currentContentProviderId = this.props.contentProviderId;
		this.reloadAccounts();
	};

	accountChanged = (keepProjectSelection?: boolean) => {
		if (!keepProjectSelection)
			this.projectsProvider.currentProjectId = undefined;

		this.projectsProvider.currentAccountId = this.props.accountId;
		this.clearProjectErrors();
		this.reloadProjects();

		if (this.onAccountChange)
			this.onAccountChange({$properties: this.props});
	};

	projectChanged = () => {
		if (this.props.projectId === null || this.props.projectId === -1) // all projects
			this.props.projectId = undefined;

		this.projectsProvider.currentProjectId = this.props.projectId;

		this.storeProjectSelection();
		this.clearProjectErrors();
		this.checkProjectsCount();

		if (this.onProjectChange) {
			this.populateProjectName();
			this.onProjectChange({$properties: this.props});
		}
	};

	private populateProjectName(): void {
		let project = _.findWhere(this.projects, { projectId: this.props.projectId });
		let projectName = project && project.name;
		this.props.projectName = projectName;
	}

	private checkProjectsCount = (): void => {
		if (this.allProjectsLimit && !this.props.projectId && this.projects.length > 10) {
			this.errors.tooManyProjects = true;
		}
	};

	private storeProjectSelection = (): void => {
		let project: IProjectSelection = {
			contentProviderId: this.props.contentProviderId,
			accountId: this.props.accountId,
			projectId: this.props.projectId
		};
		this.sessionPreferences.setRecentProject(this.security.getMasterAccountId(), project);
	};

	private reloadContentProviders = () => {
		this.loading.promise = this.projectsProvider.reloadContentProviders().then(() => {
			this.onContentProvidersLoaded(this.projectsProvider.contentProviders);
		}, () => {
			this.onContentProvidersLoaded([]);
		});
	};

	private onContentProvidersLoaded = (contentProviders: []) => {
		this.contentProviders = contentProviders;

		if (_.size(this.contentProviders) === 1 || this.hasInitialSelection) {
			if (_.size(this.contentProviders) === 1) {
				// if initial selection is not accessible anymore
				this.props.contentProviderId = this.contentProviders[0].id;
			} else {
				this.props.contentProviderId = this.projectsProvider.currentContentProviderId;
			}
			this.verifyEmptyContentProviders();
			this.reloadAccounts();
		} else {
			this.verifyEmptyContentProviders();
			this.projectSelectionFinished();
		}
	};

	private verifyEmptyContentProviders = (): void => {
		let noContentProviders = _.size(this.contentProviders) === 0;
		this.errors.noContentProviders = noContentProviders;

		if (noContentProviders) {
			this.props.contentProviderId = undefined;
			this.props.accountId = undefined;
			this.props.projectId = undefined;

			this.onAccountsLoaded([]);
			this.onProjectsLoaded([]);
		}
	};

	private reloadAccounts = () => {
		let contentProviderId = this.props.contentProviderId;
		if (!this.isValidValue(contentProviderId)) {
			this.projectSelectionFinished();
			return;
		}

		this.props.accountId = undefined;
		this.props.projectId = undefined;
		this.loading.accounts = true;
		this.clearProjectErrors();

		this.lastContentProvider = contentProviderId;
		this.loading.promise = this.projectsProvider.reloadAccounts(contentProviderId).then(() => {
			if (this.lastContentProvider !== contentProviderId)
				return; // reject previous response
			this.onAccountsLoaded(this.projectsProvider.accounts);
		}, () => {
			this.onAccountsLoaded([]);
		});
	};

	private onAccountsLoaded = (accounts: []) => {
		this.accounts = accounts;
		this.loading.accounts = false;

		this.errors.noAccounts = _.isEmpty(this.accounts);
		this.errors.noProjects = false;
		this.$scope.$emit('accounts:loaded', this.accounts);

		if (_.size(this.accounts) === 1 || this.hasInitialSelection) {
			this.props.accountId = this.projectsProvider.currentAccountId;
			this.verifyEmptyAccounts();
			this.accountChanged(true);
		} else {
			this.verifyEmptyAccounts();
			this.projectSelectionFinished();
		}
	};

	private verifyEmptyAccounts = (): void => {
		if (_.size(this.accounts) === 0) {
			this.props.accountId = undefined;
			this.props.projectId = undefined;

			this.onProjectsLoaded([]);
		}
	};

	private reloadProjects = () => {
		let contentProviderId = this.props.contentProviderId;
		let accountId = this.props.accountId;

		if (!this.isValidValue(contentProviderId) || !this.isValidValue(accountId)) {
			this.projectSelectionFinished();
			return;
		}

		this.props.projectId = undefined;
		this.loading.projects = true;
		this.clearProjectErrors();
		this.hasInternalProjects = false;
		this.lastAccount = accountId;
		let accessLevel = this.projectAccess && this.ProjectAccessLevels.findByValue(this.projectAccess);
		this.loading.promise = this.projectsProvider.reloadProjects(contentProviderId, accountId, accessLevel).then(() => {
			if (this.lastAccount !== accountId)
				return; // reject previous response
			this.onProjectsLoaded(this.projectsProvider.projects);
		}, () => {
			this.onProjectsLoaded([]);
		});
	};

	private onProjectsLoaded = (projects: any[]) => {
		this.projects = [];
		if (this.internalProjects && this.authorization.hasStudioAdminProjectAccess()) {
			let studioProject = this.adminProjectsService.getStudioAdminProject();
			this.projects.push({
				projectId: studioProject.projectId,
				name: studioProject.title,
				type: studioProject.type,
				internalProject: true
			});
			this.projects.push({
				projectId: 0,
				name: ProjectSelectorController.PROJECT_SEPARATOR,
				disabled: true
			});
			this.hasInternalProjects = true;
		}
		this.projects.pushAll(projects);
		this.loading.projects = false;


		this.errors.noProjects = _.isEmpty(this.projects);
		this.$scope.$emit('projects:loaded', this.projects);

		if (_.size(this.projects) === 1 || this.hasInitialSelection) {
			this.props.projectId = this.projectsProvider.currentProjectId;
			this.verifyEmptyProjects();
			this.projectChanged();
		} else {
			this.verifyEmptyProjects();
			this.projectSelectionFinished();
		}

		if (this.withPermissions) {
			this.loadProjectAccess(projects);
		}
	};

	private verifyEmptyProjects = (): void => {
		if (_.size(this.projects) === 0) {
			this.props.projectId = undefined;
		}
	};

	isNeedNoSelectedProjectOption = () => {
		return this.allProjects || !this.isValidValue(this.props.projectId);
	};

	getNoSelectedAccountLabel = () => {
		return _.isEmpty(this.accounts)
			? this.locale.getString('dashboard.modalNoAccs')
			: this.locale.getString('common.selectAccount');
	};

	getNoSelectedProjectOptionLabel = () => {
		if (_.isEmpty(this.projects)) {
			return this.locale.getString('dashboard.modalNoProjects');
		}
		return this.allProjects ? this.locale.getString('common.allProjects') : this.locale.getString('common.selectProject');
	};

	isValidValue = (id) => {
		return ValueUtils.isSelected(id);
	};

	showContentProviders = () => {
		return this.alwaysShowSelectors || _.size(this.contentProviders) > 1;
	};

	showAccounts = () => {
		return this.alwaysShowSelectors || (this.accountsLoaded() && _.size(this.accounts) > 1);
	};

	showProjects = () => {
		return (this.alwaysShowSelectors || this.noProjects || (this.projectsLoaded() && !_.isEmpty(this.projects)))
			&& !this.hideProjects;
	};

	private accountsLoaded = () => {
		return this.isValidValue(this.props.contentProviderId) && !this.loading.accounts;
	};

	private projectsLoaded = () => {
		return this.isValidValue(this.props.contentProviderId)
			&& this.isValidValue(this.props.accountId)
			&& !this.loading.projects;
	};

	private projectSelectionFinished = () => {
		this.checkProjectsCount();
		if (this.onProjectSelectionFinished) {
			this.onProjectSelectionFinished({
				$properties: this.props
			});
		}
	};

	private clearProjectErrors = () => {
		this.errors.noProjectSelected = false;
		this.errors.noProjectAttributes = false;
		this.errors.tooManyProjects = false;
	};

	private loadProjectAccess = (projects: Project[]): void => {
		let cpId = this.props.contentProviderId;
		let accountId = this.props.accountId;
		if (isEmpty(cpId) || isEmpty(accountId)) {
			return;
		}
		this.contentProviderOptionsService.getProjectsAccessLevel(
			cpId, accountId, this.security.getUser().userId, false).then((response) => {
			if (!response.data || isEmpty(response.data.data)) {
				return;
			}

			let account = response.data.data[0];
			this.updateProjectAccess(projects, account.projects);
		});
	};

	private updateProjectAccess = (projects, projectAccess: ProjectAccess[]): void => {
		_.each(projects, (project: any) => {
			let matchProject: ProjectAccess = _.find(projectAccess, (p: ProjectAccess) => {
				return p.project.id === project.projectId;
			});
			project.accessLevel = matchProject && matchProject.accessLevel;
		});
	};
}

app.component('projectSelector', {
	controller: ProjectSelectorController,
	templateUrl: 'partials/components/project-selector-component.html',
	bindings: {
		loading: '<',
		errors: '<',
		allProjects: '<?',
		allProjectsLimit: '<?',
		hideProjects: '<?',
		internalProjects: '<',
		preselected: '<?',
		disable: '<?',
		projectsProvider: '<?',
		projectAccess: '@?',
		withPermissions: '<',
		onAccountChange: '&?',
		onProjectChange: '&?',
		onProjectSelectionFinished: '&?',
		alwaysShowSelectors: '<?',
		labelled: '<'
	}
});
