import { ObjectUtils } from '@app/util/object-utils';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { MetricComparisonType, MetricWidgetComparison, MetricWidgetProperties } from '@cxstudio/reports/entities/metric-widget-properties';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { BasicWidgetDescriptionBuilder } from './basic-widget-description-builder';

export class MetricDescriptionBuilder extends BasicWidgetDescriptionBuilder {

	getDescription = (data?: any) => {
		let calculations = this.properties.selectedMetrics?.map(metric => metric.displayName);

		return this.getUtilsPromise(this.widget).then(utils => {
			let comparison = '';
			if ((this.properties as MetricWidgetProperties).comparisons?.length) {
				let comp = (this.properties as MetricWidgetProperties).comparisons[0];

				let periodName = this.getComparisonName(comp, utils);
				let value = (comp.type === MetricComparisonType.GOAL) ?
					comp.value.goal : '';
				comparison = this.locale.getString(`widgetDescription.genericComparison`, { comparisonName: periodName, value });
			}
			let total = this.utils.getTotal(this.visual, data);
			let palette = this.utils.getPaletteDescription('metricPalette', this.visual.color);
			if (calculations?.length) {
				return this.locale.getString('widgetDescription.metricDescription', {
					calculations: calculations.join(', '),
					comparison,
					total,
					palette
				});
			}
			return '';
		}) as any;
	};

	getAriaLabel = (widget: Widget, data?: any): Promise<string> => {
		let metricDefinition = ObjectUtils.copy(widget.properties.selectedMetrics[0]);

		const IGNORE_ALIGNMENT = {ignoreAlignment: true};

		let hasData = data?.data?.length > 0;
		let metricName = metricDefinition.name;
		let metricDisplayName = metricDefinition.displayName;
		let widgetTitle = widget.displayName;
		let primaryMetricValue = hasData
			? this.utils.getValueFormatter().format(data?.data[0][metricName], metricDefinition, undefined, IGNORE_ALIGNMENT)
			: 0;

		let ariaLabel = this.locale.getString('singleMetric.descriptionBase', {
			widgetTitle,
			metricDisplayName,
			primaryMetricValue,
		});

		return this.getUtilsPromise(this.widget).then(utils => {
			_.forEach((widget.properties as MetricWidgetProperties).comparisons, (comp) => {
				let value = hasData ? data?.data[0][`${metricName}_${comp.identifier}_value`] : 0;
				value = this.utils.getValueFormatter().format(value, metricDefinition, undefined, IGNORE_ALIGNMENT);

				let difference = hasData ? data?.data[0][`${metricName}_${comp.identifier}_diff`] : 0;
				difference = this.utils.getValueFormatter().format(difference, metricDefinition, undefined, IGNORE_ALIGNMENT);

				let periodName = this.getComparisonName(comp, utils);

				let aboveBelow = difference > 0 ?
					this.locale.getString('common.above') :
					this.locale.getString('common.below');

				ariaLabel = `${ariaLabel}, ${this.locale.getString('singleMetric.descriptionComparison',
					{value, difference, aboveBelow, metricDisplayName, periodName})}`;
			});

			return ariaLabel;
		});
	};

	protected getUtilsPromise(widget: Widget): Promise<Pick<WidgetUtils, 'periodFormatter'>> {
		let comparisons = (widget.properties as MetricWidgetProperties).comparisons;

		let utilsPromise = Promise.resolve({ periodFormatter: key => key});

		if (comparisons?.length && _.any(comparisons, comp => comp.type === MetricComparisonType.TIME)) {
			utilsPromise = this.utils.getWidgetUtils(this.widget);
		}

		return utilsPromise;
	}

	protected getComparisonName(comp: MetricWidgetComparison, utils: Pick<WidgetUtils, 'periodFormatter'>): string {

		if (comp.label) {
			return comp.label;
		} else {
			return comp.type === MetricComparisonType.TIME ?
				utils.periodFormatter(comp.value.dateFilterMode) :
				this.locale.getString('widget.goal');
		}
	}

}
