import { Component, OnInit, Input, Output, EventEmitter, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ProjectSelectorData } from './project-selector-data';
import { IProjectPreselection } from '@cxstudio/projects/project-preselection.interface';
import { Subscription, Observable } from 'rxjs';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';
import { PromiseUtils } from '@app/util/promise-utils';


@Component({
	selector: 'project-selector',
	templateUrl: './project-selector.component.html'
})
export class ProjectSelectorComponent implements OnInit, OnDestroy {
	@Input() prefix: string;
	@Input() projectSelection: IProjectPreselection;
	@Input() clear: Observable<void>;
	@Input() horizontal: boolean = false;
	@Input() hideProject: boolean = false;
	@Input() disable: boolean;
	@Input() appendToBody: boolean;
	@Output() projectSelectionChange = new EventEmitter<IProjectPreselection>();
	@Output() errorsChange = new EventEmitter<string[]>();
	@Output() loading = new EventEmitter<Promise<any>>();

	contentProvider: ProjectSelectorData<any>;
	account: ProjectSelectorData<any>;
	project: ProjectSelectorData<any>;

	private eventsSubscription$: Subscription;

	settingsLoading: Promise<any>;
	cpValidationInProgress: boolean = false;

	constructor(
		private ref: ChangeDetectorRef,
		@Inject('contentProviderOptionsService') private contentProviderOptionsService: ContentProviderOptionsService
	) {}

	ngOnInit(): void {
		this.contentProvider = new ProjectSelectorData('id', this.projectSelection?.cbContentProvider);
		this.account = new ProjectSelectorData('accountId', this.projectSelection?.cbAccount);
		this.project = new ProjectSelectorData('projectId', this.projectSelection?.project, 'name');

		this.contentProvider.onChange.subscribe(this.onContentProviderChange);
		this.account.onChange.subscribe(this.onAccountChange);
		this.project.onChange.subscribe(this.onProjectChange);

		if (this.clear) {
			this.eventsSubscription$ = this.clear.subscribe(this.onClear);
		}

		this.reloadContentProviders();
	}

	ngOnDestroy(): void {
		if (this.eventsSubscription$) {
			this.eventsSubscription$.unsubscribe();
		}
	}

	getPrefix = (): string => {
		return this.prefix ? this.prefix + ' ' : '';
	};

	onContentProviderChange = (): void => {
		this.account.clear();
		this.project.clear();
		this.onSelectionChange();

		this.validateContentProviderAndReloadAccounts();
	};

	onAccountChange = (): void => {
		this.project.clear();
		this.onSelectionChange();

		if (!this.cpValidationInProgress) {
			this.reloadProjects();
		}
	};

	onProjectChange = (): void => {
		this.onSelectionChange();
	};

	onSelectionChange = (): void => {
		this.projectSelectionChange.emit({
			cbContentProvider: this.contentProvider.getSelection(),
			cbAccount: this.account.getSelection(),
			project: this.project.getSelection(),
			projectName: this.project.getSelectionName()
		});
	};

	reloadContentProviders = (): void => {
		this.settingsLoading = this.contentProviderOptionsService.getContentProviders()
			.then((contentProviders) => {
				this.contentProvider.setOptions(contentProviders.data);
				if (this.contentProvider.hasOptions()) {
					if (this.contentProvider.isSelected())
						this.validateContentProviderAndReloadAccounts();
					else this.account.clear();
				}
			});
		this.loading.emit(this.settingsLoading);
	};

	validateContentProviderAndReloadAccounts = (): void => {
		if (!this.contentProvider.isSelected()) {
			return;
		}
		this.cpValidationInProgress = true;
		let cp = {id: this.contentProvider.getSelection()};
		let action = 'validate';
		let cpData = JSON.parse(JSON.stringify(cp));
		this.settingsLoading = PromiseUtils.wrap(this.contentProviderOptionsService.validateCP(cpData, action)).then((data) => {
			if (data.valid) {
				this.resetErrors();
				this.reloadAccounts();
			} else {
				this.setErrors(data);
			}
			this.ref.detectChanges();
			this.cpValidationInProgress = false;
		});
		this.loading.emit(this.settingsLoading);
	};

	reloadAccounts = (): void => {
		if (!this.contentProvider.isSelected()) {
			this.account.clearSelection();
			return;
		}
		this.settingsLoading = PromiseUtils.wrap(this.contentProviderOptionsService
			.getUserAccounts(this.contentProvider.getSelection(), {})).then((accounts) => {
			this.account.setOptions(accounts.data);
			if (this.account.hasOptions()) {
				if (this.account.isSelected())
					this.reloadProjects();
				else this.project.clear();
				this.ref.detectChanges();
			}
		},
		(error) => {
			this.setErrors(error);
		});
		this.loading.emit(this.settingsLoading);
	};

	reloadProjects = () => {
		if (!this.contentProvider.isSelected() || !this.account.isSelected()) {
			this.project.clearSelection();
			return;
		}
		let optionPromise = this.contentProviderOptionsService.getOptions(
			this.contentProvider.getSelection(),
			'projects',
			{
				contentProviderId: this.contentProvider.getSelection(),
				accountId: this.account.getSelection()
			},
			true
		);

		this.settingsLoading = optionPromise.then(response => {
			this.resetErrors();
			this.project.setOptions(response.data);
			this.ref.detectChanges();
		}, error => {
			this.project.clear();
			this.setErrors(error);
		});
		this.loading.emit(this.settingsLoading);
	};

	private onClear = (): void => {
		this.contentProvider.clearSelection();
		this.account.clearSelection();
		this.project.clearSelection();
	};

	private resetErrors(): void {
		this.errorsChange.emit([]);
	}

	private setErrors(errors): void {
		let errorsForCP = [];
		errorsForCP.pushAll(errors);
		this.errorsChange.emit(errorsForCP);
	}
}
