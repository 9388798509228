import {
	AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input,
	OnChanges, OnInit, Renderer2, RendererStyleFlags2, SimpleChanges, ViewChild
} from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { MetricWidgetType } from '@cxstudio/dashboards/widgets/type-definitions/metric-widget-type.class';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { GenericVisualizationBaseComponent } from '../generic-visualization-base.class';
import { HorizontalMetricWidgetSizer } from './horizontal-metric-widget-sizer.class';
import { MetricCssVariables } from './metric-css-variables.class';
import { MetricDisplayValue, MetricWidgetRenderingService, SimplePOPData } from './metric-widget-rendering.service';
import MetricWidgetSizeUtils from './metric-widget-size-utils.class';
import { MetricWidgetSizer } from './metric-widget-sizer.class';
import { VerticalMetricWidgetSizer } from './vertical-metric-widget-sizer.class';
import { CalculationWithFormat } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { WidgetError } from '../common/widget-error/widget-error.class';

@Component({
	selector: 'metric-widget',
	templateUrl: './metric-widget.component.html',
	styles: [`
		* {
			line-height: 1;
		}

		.css-metric-widget {
			justify-content: var(--wrapper-horizontal-align);
			background-color: var(--white);
		}

		.current-value h1 { font-size: var(--main-font-size); }
		:host .current-value ::ng-deep .value-affix { font-size: var(--main-affix-font-size); }
		.historic-value h2 { font-size: var(--secondary-font-size); }
		:host .historic-value ::ng-deep .value-affix { font-size: var(--secondary-affix-font-size); }
		.delta {
			font-size: var(--delta-font-size);
			margin-top: var(--delta-spacing);
		}
		.metric p {
			word-break: var(--line-wrap);
			line-height: 1.1;
		}
		p {
			font-size: var(--label-font-size);
			display: -webkit-box;
			-webkit-line-clamp: var(--line-clamp);
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.vertical.metric-wrapper {
			justify-content: center;
			align-items: var(--wrapper-vertical-align);
		}
		.vertical.with-previous-period .current-value { margin-bottom: var(--vertical-separation); }
		.vertical article {
			flex: 1 1 auto;
			margin-right: var(--chart-spacing);
		}
		.vertical.without-change-graph article { padding: 16px; }
		.vertical article, .vertical .svg-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			flex: 1;
		}

		.vertical .svg-wrapper {
			width: var(--chart-width);
		}

		.horizontal.metric-wrapper {
			flex-direction: column;
			justify-content: center;
		}

		.horizontal article {
			align-items: baseline;
			justify-content: center;
			width: 100%;
			padding: var(--margin);
		}
		.horizontal section:first-child { margin-right: var(--horizontal-comp-spacing); }
		.horizontal section:last-child p { margin-bottom: var(--secondary-period-label-margin) !important; }

		.without-change-graph.without-previous-period article {
			justify-content: center;
			align-items: center;
			padding: var(--margin);
			margin-right: 0;
		}

		.css-metric-widget .step-chart { transform: none; }

		.croppable {
			max-width: var(--croppable-max-width);
			overflow: var(--croppable-overflow);
		}

		h1, h2 { text-overflow: ellipsis; }
	`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetricWidgetComponent extends GenericVisualizationBaseComponent implements OnInit, AfterViewInit, OnChanges {
	@Input() widget: MetricWidgetType;
	@Input() dataObject: ReportDataObject;
	@Input() demo: boolean;
	@Input() utils: WidgetUtils;

	@ViewChild('mainWidget', { static: false}) widgetElement: ElementRef;

	readonly ALLOWED_SIZES = [ 88, 80, 72, 64, 56, 48, 40, 32, 24 ];

	data: SimplePOPData = {} as SimplePOPData;
	labels: any = {};
	orientationClasses: string[];
	sizeClass: string;
	isHorizontallyOriented: boolean;
	renderPrepared: boolean;
	sizer: MetricWidgetSizer;
	currentBaselineIndex: number;
	cssVars: MetricCssVariables;
	isLabelSingleLine: boolean;
	requiresCrop: boolean;

	constructor(
		private readonly metricWidgetRendering: MetricWidgetRenderingService,
		private readonly locale: CxLocaleService,
		private readonly renderer: Renderer2,
		private readonly betaFeaturesService: BetaFeaturesService,
		readonly ref: ChangeDetectorRef,
		@Inject('reportUtils') readonly reportUtils: ReportUtils
	) {
		super(
			ref,
			reportUtils,
		);
	}

	onChartResize = (): void => {
		this.resetSizingRules();
		this.recalculate();
	};

	ngOnInit(): void {
		this.initializeWidget();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes) {
			this.initializeWidget();
			this.ref.detectChanges();
		}
	}

	ngAfterViewInit(): void {
		this.updateLineClamp(2);
		this.initResizer(this.widgetElement, this.onChartResize);
		this.recalculate();
		this.ref.detectChanges();
		// not sure why utils is undefined for first time
		if (this.utils) {
			this.markRendered();
		}
	}

	getAriaLabel(): string {
		let comparison = '';
		if (this.widget.visualProperties.previousPeriod) {
			comparison = this.locale.getString('widget.metricAriaLabelComparison', {
				direction: this.locale.getString(`common.${this.data.currentValue > this.data.lastValue ? 'increased' : 'decreased'}`),
				diff: this.getDiff().replace(/^-/, ''),
				period: this.labels.previousPeriodLabel,
				value: this.getLastValue()
			});
		}
		let metric = this.widget.properties.selectedMetrics[0];
		return this.locale.getString('widget.metricAriaLabel', {
			name: metric?.displayName || metric?.name,
			period: this.labels.currentPeriodLabel,
			value: this.getCurrentValue(),
			comparison
		});
	}

	private initializeWidget(): void {
		if (this.dataObject?.metadata?.filteredByConfidentiality) {
			this.widgetError = WidgetError.DATA_RESTRICTED;
			return;
		}

		if (this.widget.properties && this.widget.visualProperties && this.utils && this.dataObject) {
			this.data = this.metricWidgetRendering.getValues(this.widget.properties, this.widget.visualProperties,
				this.dataObject);
			$.extend(this.data, this.metricWidgetRendering.createLevels(this.data));
			this.labels = this.metricWidgetRendering.getLabels(this.widget.properties, this.widget.visualProperties,
				this.utils);
		}
	}

	/**
	 * Reset all wrapping/cropping rules so we can do a clean calculation
	 */
	private resetSizingRules(): void {
		this.convertObjectToCssVars({lineWrap: 'normal'});
		this.convertObjectToCssVars({croppableMaxWidth: 'auto'});
		this.convertObjectToCssVars({croppableOverflow: 'visible'});
		this.convertObjectToCssVars({wrapperVerticalAlign: 'center'});
		this.convertObjectToCssVars({wrapperHorizontalAlign: 'center'});
		this.requiresCrop = false;
		this.isLabelSingleLine = false;
		this.updateLineClamp(2);
		delete this.isHorizontallyOriented;
	}

	private recalculate(sizeIndex: number = 0, config?: { wrapText?: boolean; lineClamp?: number }): void {
		config = config || { wrapText: false, lineClamp: 2};
		let setSize = this.ALLOWED_SIZES[sizeIndex];
		this.setMeasurements(setSize);
		this.currentBaselineIndex = sizeIndex;

		this.isHorizontallyOriented = this.widget.visualProperties.trendArrow ?
			false :
			!!this.isHorizontallyOriented;
		this.orientationClasses =
			MetricWidgetSizeUtils.getOrientationClasses($(this.widgetElement.nativeElement),
				this.widget.visualProperties, !this.isHorizontallyOriented);
		this.sizeClass = MetricWidgetSizeUtils.getWidgetSizeClass($(this.widgetElement.nativeElement), this.widget.visualProperties);
		this.renderPrepared = true;

		this.sizer = this.isHorizontallyOriented ?
			new HorizontalMetricWidgetSizer() :
			new VerticalMetricWidgetSizer();

		this.ref.detectChanges();

		this.setOrientationSpecificCssVars(sizeIndex);
		this.convertObjectToCssVars(this.cssVars);

		if (!this.sizer.isPeriodTextFit(this.widgetElement, this.cssVars, this.widget.visualProperties)) {
			if (this.currentBaselineIndex < this.ALLOWED_SIZES.length - 1) {
				this.updateLineClamp(config.lineClamp || 2);
				this.currentBaselineIndex++;
				this.recalculate(this.currentBaselineIndex, config);
				return;
			}

			if (this.canTryHorizontalOrientation(config.wrapText)) {
				// if it won't fit vertical, try horizontal orientation
				this.isHorizontallyOriented = true;
				this.recalculate();
				return;
			}

			if (this.canTryWrapping()) {
				const wrapText = true;
				// if it still wont fit, switch back to vertical, wrap text and try again
				this.convertObjectToCssVars({lineWrap: 'break-word'});
				this.isHorizontallyOriented = false;
				this.recalculate(0, { wrapText });
				return;
			}

			if (this.canTrySingleLineLabels()) {
				this.updateLineClamp(1);
				this.isLabelSingleLine = true;
				this.recalculate(0, {lineClamp: 1});
				return;
			}

			this.requiresCrop = true;

			this.convertObjectToCssVars({croppableMaxWidth: '100%'});
			this.convertObjectToCssVars({croppableOverflow: 'hidden'});

			// at this point, need to start cropping content
			// crop horizontal first, if necessary, bc removal of chart may make vertical fit
			this.cropHorizontalUntilFit();
			this.cropVerticalUntilFit();
		}
	}

	/**
	 * Start removing content until it fits vertically
	 */
	private cropVerticalUntilFit(historicValueHidden: boolean = false): void {
		if (!this.sizer.isFitVertical(this.widgetElement, this.cssVars, this.widget.visualProperties, historicValueHidden)) {
			this.convertObjectToCssVars({wrapperVerticalAlign: 'flex-start'});
		}
	}

	/**
	 * Remove content until it fits horizontally
	 */
	private cropHorizontalUntilFit(): void {
		if (!this.sizer.isFitHorizontal(this.widgetElement, this.cssVars)) {
			this.convertObjectToCssVars({wrapperHorizontalAlign: 'flex-start'});
		}
	}

	// metric widgets with trend charts are always vertical
	private canTryHorizontalOrientation(isWrapping: boolean): boolean {
		return !this.widget.visualProperties.trendArrow &&
			!this.isHorizontallyOriented &&
			!isWrapping &&
			this.currentBaselineIndex === (this.ALLOWED_SIZES.length - 1);
	}

	private canTryWrapping(): boolean {
		return this.currentBaselineIndex === (this.ALLOWED_SIZES.length - 1) &&
			this.isHorizontallyOriented &&
			!this.sizer.isPeriodTextFit(this.widgetElement, this.cssVars);
	}

	private canTrySingleLineLabels(): boolean {
		return this.currentBaselineIndex === (this.ALLOWED_SIZES.length - 1) &&
			!this.sizer.isFitVertical(this.widgetElement, this.cssVars, this.widget.visualProperties) &&
			!this.requiresCrop && !this.isLabelSingleLine;
	}

	private setOrientationSpecificCssVars(sizeIndex: number): void {
		let mainMetricSize = this.ALLOWED_SIZES[sizeIndex];
		$.extend(this.cssVars, this.sizer.getAdditionalCssVars(this.widgetElement, mainMetricSize), this.sizer.defaults);
	}

	private updateLineClamp(lines: number): void {
		this.renderer.setStyle(this.widgetElement.nativeElement, `--line-clamp`, lines, RendererStyleFlags2.DashCase);
	}

	private convertObjectToCssVars(cssVars: Partial<MetricCssVariables>): void {
		_.mapObject(cssVars, (value, key) => {
			if (typeof value === 'number') {
				value = `${value}px`;
			}
			this.renderer.setStyle(this.widgetElement.nativeElement, `--${key.toKabobCase()}`, `${value}`, RendererStyleFlags2.DashCase);
		});
	}

	setMeasurements(mainMetricSize: number): void {
		const MAX_SIZE = 88;
		const MIN_SIZE = 24;
		const PERIOD_LABEL_BOTTOM_MARGIN = 16;

		if (mainMetricSize > MAX_SIZE) {
			mainMetricSize = MAX_SIZE;
		}
		if (mainMetricSize < MIN_SIZE) {
			mainMetricSize = MIN_SIZE;
		}
		this.cssVars = {
			mainFontSize: mainMetricSize,
		} as MetricCssVariables;

		const smallAfixes = this.betaFeaturesService.isFeatureEnabled(BetaFeature.METRIC_WIDGET_SMALL_AFFIX);
		const smallerMainAffix = ( mainMetricSize - 32 < 16 ) ? 16 : mainMetricSize - 32;
		this.cssVars.mainAffixFontSize = smallAfixes ?  smallerMainAffix : mainMetricSize;
		this.cssVars.secondaryFontSize = mainMetricSize >= 32 ? mainMetricSize - 16 : 16;
		const smallerSecondaryAffix = ( this.cssVars.secondaryFontSize - 32 < 16 ) ? 14 : this.cssVars.secondaryFontSize - 32;
		this.cssVars.secondaryAffixFontSize = smallAfixes ? smallerSecondaryAffix : this.cssVars.secondaryFontSize;
		this.cssVars.deltaFontSize = this.cssVars.secondaryFontSize > 16 ? 16 : 14;
		this.cssVars.verticalSeparation = this.cssVars.secondaryFontSize;
		this.cssVars.secondaryPeriodLabelMargin = PERIOD_LABEL_BOTTOM_MARGIN + ((mainMetricSize - this.cssVars.secondaryFontSize) / 2);
	}

	formatValue = (value: number): string => {
		return this.metricWidgetRendering.formatMainValue(value, this.widget.properties.selectedMetrics[0], this.utils?.metrics);
	};

	getCurrentValue = (): string => {
		const parts = this.getCurrentValueParts();
		return this.metricWidgetRendering.getDisplayMetricValueAsString(parts);
	};

	getCurrentValueParts = (): MetricDisplayValue => {
		if (!this.data.currentValue && this.data.currentValue !== 0) {
			return { value: this.locale.getString('widget.na') };
		}
		return this.metricWidgetRendering.getMainMetricDisplayValue(
			this.data.currentValue, this.widget.properties.selectedMetrics[0], this.utils?.metrics);
	};

	getDiff = (): string => {
		return this.metricWidgetRendering.getDifferenceString(this.dataObject.data[0], this.widget.properties, this.utils);
	};

	getLastValue = (): string => {
		const parts = this.getLastValueParts();
		return this.metricWidgetRendering.getDisplayMetricValueAsString(parts);
	};

	getLastValueParts = (): MetricDisplayValue => {
		return this.metricWidgetRendering.getComparisonMetricDisplayValue(
			this.data.lastValue, this.widget.properties.selectedMetrics[0] as CalculationWithFormat,
			this.widget.properties.comparisons?.[0], this.utils.metrics);
	};

	getDirectionalColorClass = (newValue: number, oldValue: number) => {
		return this.metricWidgetRendering.getDirectionalColorClass(this.widget.properties.selectedMetrics, newValue, oldValue);
	};

	getDiffSymbol = (newValue: number, oldValue: number) => {
		return this.metricWidgetRendering.getDifferenceSymbol(newValue, oldValue, this.widget.properties);
	};

	getCustomClasses = () => {
		return [
			...this.orientationClasses,
			this.widget.visualProperties.trendArrow ? 'with-change-graph' : 'without-change-graph',
			this.widget.visualProperties.previousPeriod ? 'with-previous-period' : 'without-previous-period'
		];
	};

	getLabel = (): string => {
		return this.metricWidgetRendering.getAriaLabel(
			this.labels.currentPeriodLabel,
			this.getCurrentValue(),
			this.widget.visualProperties.previousPeriod,
			this.getDiff(),
			this.labels.previousPeriodLabel,
			this.getLastValue());
	};

	getChangeGraphWidth = (): number => {
		return this.metricWidgetRendering.getChangeGraphWidth(this.data, this.widget.visualProperties);
	};

	getPath = (): string => {
		return this.metricWidgetRendering.buildPath(this.data, this.widget.visualProperties, $(this.widgetElement.nativeElement));
	};
}

app.directive('metricWidget', downgradeComponent({ component: MetricWidgetComponent }));
