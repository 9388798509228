import * as cloneDeep from 'lodash.clonedeep';

import { EventEmitter, Inject, Output, Component, Input, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import Listener from '@cxstudio/common/listener';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { HomePageReportProperties } from '@app/modules/home-page/home-page-common/entities/home-page-report-properties';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';

@Component({
	selector: 'home-page-edit',
	templateUrl: './home-page-edit.component.html',
})
export class HomePageEditComponent implements OnInit {

	@Input() homePage: HomePage;
	@Input() allItems: HomePage[];
	@Output() onSave = new EventEmitter<HomePage>();
	@Output() onCancel = new EventEmitter<void>();

	isWorkspaceEnabled: boolean;
	originalHomePage: HomePage;
	saveListener: Listener;

	private widgetSettingsChanged: boolean;

	constructor(
		@Inject('cbDialogService') private cbDialogService: CBDialogService,
		private readonly betaFeaturesService: BetaFeaturesService,
		private locale: CxLocaleService,
		private readonly cxDialogService: CxDialogService,
	) { }

	ngOnInit(): void {
		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
		this.initProjectSelection(this.homePage.reportProperties);
		this.initProjectSelection(this.homePage.quickInsights);

		this.originalHomePage = cloneDeep(this.homePage);
		this.saveListener = new Listener();
	}

	cancel(): void {
		if (this.hasChanges()) {
			this.cxDialogService.showUnsavedChangesDialog(
				this.locale.getString('homePage.unsavedHeader'),
				this.locale.getString('homePage.unsavedChanges')
			)
				.then(save => {
					if (save)
						this.save();
					else this.onCancel.emit();
				}, () => {});
		} else this.onCancel.emit();
	}

	save(): void {
		let validateResult = this.validate();
		if (validateResult) {
			if (this.homePage.widgetsConfig.widgets) {
				this.homePage.widgetsConfig.widgets = _.first(this.homePage.widgetsConfig.widgets, this.homePage.widgetsConfig.layout);

				this.homePage.widgetsConfig.widgets.filter(widget => !!widget)
					.forEach(widget => widget.id = widget.id > 0 ? widget.id : undefined);
			}

			if (this.homePage.carousel?.tabs?.length) {
				this.homePage.carousel.tabs = _.filter(this.homePage.carousel.tabs, tab => !!tab.name);
			}
			if (this.homePage.default && this.homePage.name === this.locale.getString('homePage.defaultName')) {
				this.homePage.name = '';
			}
			if (this.hasOwnerChanged()) {
				this.cbDialogService.confirm(
					this.locale.getString('homePage.quickInsightsOwnerTransferHeader'),
					this.locale.getString('homePage.quickInsightsOwnerTransferText', { newOwner: this.homePage.reportProperties.owner })).result
					.then(() => this.onSave.emit(this.homePage));
			} else {
				this.onSave.emit(this.homePage);
			}
		}
	}

	private hasOwnerChanged = (): boolean => this.homePage.reportProperties.owner !== this.originalHomePage.reportProperties.owner;

	private validate(): boolean {
		this.homePage.name = this.homePage.name.trim();
		if (this.homePage.name !== this.originalHomePage.name) {
			if (!this.homePage.name) {
				this.cbDialogService.notify(this.locale.getString('common.error'),
					this.locale.getString('homePage.homePageNameEmpty'));
				return false;
			}
			if (!!_.findWhere(this.allItems, {name: this.homePage.name})) {
				this.cbDialogService.notify(this.locale.getString('common.error'),
					this.locale.getString('homePage.homePageNameError'));
				return false;
			}
		}

		if (this.requiresProjectValidation()) {
			if (!this.homePage.reportProperties.owner) {
				this.cbDialogService.notify(this.locale.getString('common.error'),
					this.locale.getString('homePage.quickInsightsOwnerError'));
				return false;
			}
			if (!this.isProjectSelected(this.homePage.reportProperties)) {
				this.cbDialogService.notify(this.locale.getString('common.error'),
					this.locale.getString('homePage.quickInsightsProjectError'));
				return false;
			}
		}

		if (this.homePage.quickInsights.enabled && !this.homePage.quickInsights.inheritProjectSetting) {
			if (!this.isProjectSelected(this.homePage.quickInsights)) {
				this.cbDialogService.notify(this.locale.getString('common.error'),
					this.locale.getString('homePage.quickInsightsProjectError'));
				return false;
			}

			if (!this.homePage.quickInsights.owner) {
				this.cbDialogService.notify(this.locale.getString('common.error'),
					this.locale.getString('homePage.quickInsightsOwnerError'));
				return false;
			}
		}

		if (this.homePage.widgetsConfig.enabled) {
			const layout = this.homePage.widgetsConfig.layout;
			if (_.some(this.homePage.widgetsConfig.widgets, (widget, index) => index < layout && !widget)) {
				this.cbDialogService.notify(
					this.locale.getString('homePage.emptyWidgetsHeader'),
					this.locale.getString('homePage.emptyWidgetsError'),
					null,
					'warning-modal');
				return false;
			}
		}
		return true;
	}

	private isProjectSelected(props: IProjectSelection | { workspaceProject: WorkspaceProject }): boolean {
		if (!this.isOldProject(props)) {
			return WorkspaceProjectUtils.isProjectSelected(props.workspaceProject);
		} else {
			return ProjectIdentifier.isProjectSelected(props);
		}
	}

	private isOldProject(props: IProjectSelection | { workspaceProject: WorkspaceProject }): props is IProjectSelection {
		return !this.isWorkspaceEnabled;
	}

	private requiresProjectValidation(): boolean {
		return this.homePage.quickInsights.enabled;
	}

	hasChanges(): boolean {
		const simpleFields = [
			'name',
			'carousel',
			'reportProperties',
			'quickInsights'
		];

		this.initProjectSelection(this.originalHomePage.reportProperties);
		this.initProjectSelection(this.originalHomePage.quickInsights);

		let original = _.pick(this.originalHomePage, simpleFields);
		let current = _.pick(this.homePage, simpleFields);
		if (!_.isEqual(original, current) || !_.isEmpty(this.homePage.accessChange))
			return true;

		if (this.hasWidgetConfigChanged() || this.widgetSettingsChanged )
			return true;

		return false;
	}

	hasWidgetConfigChanged(): boolean {
		if (this.originalHomePage.widgetsConfig.enabled !== this.homePage.widgetsConfig.enabled ||
			this.originalHomePage.widgetsConfig.layout !== this.homePage.widgetsConfig.layout ||
			this.originalHomePage.widgetsConfig.widgets.length!== this.getPopulatedHomepageWidgets()) {
			return true;
		}
		return false;
	}

	getPopulatedHomepageWidgets(): number {
		//Changes should be tracked only against populated widgets
		if (this.homePage.widgetsConfig.widgets) {
			return this.homePage.widgetsConfig.widgets.filter(widget => widget !== null).length;
		}
		return 0;
	}

	widgetsChanged(widgetSettings: boolean) {
		// cannot reliably track widget changes, so assume any change as dirty state
		if (widgetSettings)
			this.widgetSettingsChanged = true;
	}

	private initProjectSelection(property: HomePageReportProperties) {
		if (_.isUndefined(property.contentProviderId)) {
			property.contentProviderId = -1;
		}

		if (_.isUndefined(property.accountId)) {
			property.accountId = -1;
		}

		if (_.isUndefined(property.projectId)) {
			property.projectId = -1;
		}
	}
}


