<div *ngIf="isLabelEnabled()" class="d-flex">
	<span data-testid="label-score" *ngIf="isScoreDisplayed() && score !== undefined" class="ml-8 font-bold ph-4">
		{{ score }}
	</span>
	<div class="scorecard-pill text-0_875rem font-semibold ph-8 break-word text-center pv-4 overflow-hidden"
			[ngStyle]="{'background-color': bgColorHex, 'color': getTextColor()}">
		{{ label }}
	</div>
</div>

<div data-testid="label-score" *ngIf="!isLabelEnabled()">
	<b>{{ score }}</b>
</div>
