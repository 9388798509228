import { Component, Input, ChangeDetectionStrategy, OnInit, OnChanges, SimpleChanges,
	EventEmitter, Output, Inject, ViewChild, ElementRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

import * as _ from 'underscore';

import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { Security } from '@cxstudio/auth/security-service';
import { CxLocaleService } from '@app/core';
import { DashboardFilterLabelsService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-labels.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { IFocusMoveParams } from '@app/shared/components/forms/tree/focus';
import { ITreeSelectionUINode } from '@app/shared/components/tree-selection/tree-selection.component';
import { ITreeSelection, ITreeSelectionNode, TreeSelectionBuilder, TreeSelectionStrategy } from '@app/shared/components/tree-selection/tree-selection';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { ModelsService } from '@app/modules/project/model/models.service';
import { ReportModelsService } from '@app/modules/project/model/report-models.service';


@Component({
	selector: 'topic-multiselect',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './topic-multiselect.component.html',
})
export class TopicMultiselectComponent implements OnInit, OnChanges {
	@Input() dashboard: Dashboard;
	@Input() model: any;
	@Input() tree: ITreeSelection;
	@Input() projectIdentifier: AccountOrWorkspaceProject;
	@Input() showMultiselectButtons: boolean;
	@Input() searchLabel: string;
	@Input() appendToBody: boolean;
	@Input() dropdownPlacement: string;
	@Input() disabled: boolean;

	@Output() onNodeClick = new EventEmitter<ITreeSelection>();

	@ViewChild('ngDropdownToggle', {static: false}) dropdownToggle: ElementRef;
	@ViewChild(NgbDropdown, {static: false}) private ngDropdown: NgbDropdown;

	treeSelection: ITreeSelection;
	root: ITreeSelectionUINode;
	selectNoneText: string;
	selectEverythingText: string;
	highlightSelectedBackground: boolean;

	constructor(
		private locale: CxLocaleService,
		@Inject('dashboardFilterLabels') private dashboardFilterLabels: DashboardFilterLabelsService,
		private readonly reportModelsService: ReportModelsService,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly modelsService: ModelsService,
	) {}

	ngOnInit(): void {
		this.treeSelection = this.tree || TreeSelectionBuilder.none();
		this.root = {} as ITreeSelectionUINode;
		this.dropdownPlacement = this.dropdownPlacement || 'bottom-left bottom-right top-left top-right';

		this.selectNoneText = this.locale.getString('dashboard.selectNoTopics');
		this.selectEverythingText = this.locale.getString('dashboard.selectEverythingTopics');
		this.highlightSelectedBackground = true;

		if (!this.projectIdentifier) {
			this.projectIdentifier = this.reportAssetUtilsService.getDashboardProject(this.dashboard);
		}

		this.showMultiselectButtons = _.isUndefined(this.showMultiselectButtons) ? true : this.showMultiselectButtons;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.tree?.previousValue !== changes.tree?.currentValue) {
			this.treeSelection = this.tree || TreeSelectionBuilder.none();
		}
	}

	getContainer = (): string | null => {
		return this.appendToBody ? 'body' : null;
	};

	loadTopics = (): Promise<ITreeSelectionUINode[]> => {
		let treePromise = this.dashboard
			? this.reportModelsService.getDashboardModelTree(this.dashboard, this.model.id)
			: this.modelsService.getModelTree(this.projectIdentifier, this.model.id);

		return treePromise.then(modelTree => modelTree.root.children);
	};

	convertNodes = (node: ITreeSelectionUINode, children: ITreeSelectionUINode[]): ITreeSelectionNode[] => {
		return children.map((child) => {
			return {
				id: child.id,
				name: child.name,
				path: child.path as string,
				idPath: child.idPath,
				modelId: child.modelId
			};
		});
	};

	onTreeSelectionChanged = (treeSelection: ITreeSelection): void => {
		this.onNodeClick.emit(treeSelection);
	};

	getSelectedValueLabel = (): string => {
		return this.getTopicDashboardFilterLabel(this.treeSelection);
	};

	private getTopicDashboardFilterLabel(treeSelection): string {
		let strategy = treeSelection.strategy;

		switch (strategy) {

			case TreeSelectionStrategy.EVERYTHING:
				return this.model.displayName;
			case TreeSelectionStrategy.SUBSET:
				return this.dashboardFilterLabels.generateTopicFilterSubsetLabel(treeSelection.nodes, 1);
			default:
				return this.locale.getString('dashboard.selectTopicProposition');
		}
	}

	isOpen = (): boolean => {
		return this.ngDropdown?.isOpen();
	};

	closePopup = (): void => {
		this.ngDropdown.close();
		setTimeout(() => { this.dropdownToggle.nativeElement.focus(); }, 0);
	};

	stopEvent = (event: KeyboardEvent): void => {
		event.preventDefault();
		event.stopPropagation();
	};

	focusMoveHandler = ($event: IFocusMoveParams): void => {
		this.ngDropdown.close();
		setTimeout(() => { this.dropdownToggle.nativeElement.focus(); }, 0);
	};
}

app.directive('topicMultiselect', downgradeComponent({component: TopicMultiselectComponent}));
