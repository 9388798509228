import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { DashboardChangeAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/dashboard-change-action';
import { DashboardPropertiesChangeAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/dashboard-properties-change-action';
import { DashboardChangeType } from '@app/modules/dashboard-actions/undo/dashboard-change-type.enum';
import { DashboardHistoryStateService } from '@app/modules/dashboard-actions/undo/dashboard-history-state.service';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { TemplatePlaceholderType } from '@app/modules/unified-templates/common-templates/dto/template-placeholder-type.enum';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { GroupType } from '@app/modules/user-administration/groups/group-type';
import { PromiseUtils } from '@app/util/promise-utils';
import Authorization from '@cxstudio/auth/authorization-service';
import { Security } from '@cxstudio/auth/security-service';
import { DashboardTemplatesApiService } from '@cxstudio/dashboard-templates/api/dashboard-templates-api.service';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { DashboardProperties } from '@cxstudio/dashboards/entity/dashboard-properties';
import { Label } from '@cxstudio/dashboards/entity/label';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { AdminProjectsService } from '@cxstudio/internal-projects/admin-projects-service.service';
import { MAPropertiesService } from '@cxstudio/master-accounts/ma-properties-service.service';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';
import Hierarchy from '@cxstudio/organizations/Hierarchy';
import { ColorPalettes } from '@cxstudio/reports/coloring/color-palettes.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { IContentProviderSelectionCtrlExtension } from '@cxstudio/services/ContentProviderSelectionControllerExtension';
import { DashboardPropsService } from '@cxstudio/services/dashboard-props.service';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { LabelsApiService } from '@cxstudio/services/data-services/labels-api.service';
import { User } from '@cxstudio/user-administration/users/entities/user';
import * as _ from 'underscore';
import { DashboardPropertiesConfig } from './dashboard-properties-modal-config.class';
import { Dashboard } from './entity/dashboard';
import { DashboardType } from './entity/dashboard-type';
import { FrontlineDashboardUtils } from '@app/modules/dashboard/services/utils/frontline-dashboard-utils';

interface IDashboardPropsScope extends ISimpleScope {
	model: Dashboard;
	onWorkspaceProjectSelected: () => void;
	isWorkspaceEnabled: () => boolean;
	isDashboardConfidentialityEnabled: () => boolean;
	onWorkspaceProjectsLoading: (promise: any) => void;
	hasMultipleProviders: () => boolean;
	hasContentProvider: () => boolean;
	hasMultipleAccounts: () => boolean;
	getDashboardEditors: (search: any) => ng.IPromise<any>;
	needsTemplateConfiguration: () => boolean;
	templateOptionsLoaded: () => boolean;
	reloadProjects: () => void;
	showColorSelector: () => boolean;
	showDrillableDashboardSelector: () => boolean;
	getTagClass: (tag: any) => string;
	togglePersonalization: () => void;
	reloadHierarchies: () => ng.IPromise<any>;
	confirmAndSave: () => void;
	labelAdded: (tag: any) => void;
	labelRemoved: (tag: any) => void;
	loadLabels: (query: any) => ng.IPromise<Label[]>;
	cancel: () => void;
	isProjectSelected: () => boolean;
	isHierarchySelected: () => boolean;
	getCurrentHierarchy: () => any;
	saveBookProperties: () => void;
	isShowNotEditorWarning: () => boolean;
	isOwnerChanged: () => boolean;
	switchOptimization: () => void;
	switchViewOnlyDrill: () => void;
	switchModernLookAndFeelEnabled: () => void;
	isCurrentHierarchyMismatch: () => void;
	isValid: () => boolean;
	hierarchyChanged: () => void;
	hasHierarchies: () => boolean;
	showIndicateUserPlacement: () => boolean;
	getAllDrillableDashboards: (query: any) => Dashboard[];
	deselectAll: () => void;
	updateSelectedProject: () => void;
	getCPTypeById: (cpId: any) => string;
	isFrontlineEnabled: () => boolean;
	isFrontlineDashboard: () => boolean;
	propsCtrl: IDashboardPropsScope;
	getFolderPath: (folder: any) => string;
	defaultsPanelCollapsed: boolean;
	advancedPanelCollapsed: boolean;
	promises: { loadingTemplate: any; loadingTemplateOptions: any; saving: any };
	config: any;
	isEdited: any;
	folders: any[];
	viewOnlyDrill: boolean;
	user: User;
	selectedOwner: User;
	dashboardEditors: any[];
	itemList: any;
	initialItem: Dashboard;
	hiddenItems: any;
	customLabels: any;
	defaultBrandingColor: string;
	settingsLoading: any;
	maxLength: number;
	allUsersInHierarchy: boolean;
	optimizationEnabled: any;
	modernLookAndFeelEnabled: any;
	workspaceProjectsLoading: any;
	contentProviders: any[];
	cbAccounts: any[];
	searchingDashboardEditors: boolean;
	template: any;
	templateOptions: any;
	TemplatePlaceholderType: typeof TemplatePlaceholderType; // used in template-fill-placeholders.html
	allDrillableDashboards: any[];
	showErrorsForCP: any[];
	projects: any;
	palettes: any;
	providerColors: any;
	filteredHierarchies: any;
	sharedOrg: any;
	propertiesDialog: any;
	showHierarchyGroupWarning: any;
	isProperties: any;
	onDefaultLocaleChange: (defaultLocale: string) => void;
}


export class DashboardPropertiesController implements ng.IController {
	private providerExtension: IContentProviderSelectionCtrlExtension;
	private originalHierarchyId;
	private initialPropsSet = false;
	private hierarchies = [];

	constructor(
		private $scope: IDashboardPropsScope,
		private treeItem: Dashboard,
		private config: DashboardPropertiesConfig,
		private $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
		private contentProviderOptionsService: ContentProviderOptionsService,
		private security: Security,
		private locale: ILocale,
		private dashboardPropsService: DashboardPropsService,
		private organizationApiService: OrganizationApiService,
		private dashboardHistoryState: DashboardHistoryStateService,
		private dashboardApiService: DashboardApiService,
		private $q: ng.IQService,
		private cbDialogService: CBDialogService,
		private dashboardFiltersService: DashboardFiltersService,
		private maPropertiesService: MAPropertiesService,
		private dashboardTemplatesApiService: DashboardTemplatesApiService,
		private templateOptionsService,
		private labelsApiService: LabelsApiService,
		private ContentProviderSelectionControllerExtension,
		private currentWidgets: ICurrentWidgets,
		private dashboardService: DashboardService,
		private colorPalettes: ColorPalettes,
		private downgradeDialogService: DowngradeDialogService,
		private adminProjectsService: AdminProjectsService,
		private authorization: Authorization,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly betaFeaturesService: BetaFeaturesService
	) {

		this.$scope.isWorkspaceEnabled = this.isWorkspaceEnabled;
		this.$scope.isDashboardConfidentialityEnabled = this.isDashboardConfidentialityEnabled;
		this.$scope.onWorkspaceProjectsLoading = this.onWorkspaceProjectsLoading;
		this.$scope.onWorkspaceProjectSelected = this.onWorkspaceProjectSelected;
		this.$scope.hasMultipleProviders = this.hasMultipleProviders;
		this.$scope.hasContentProvider = this.hasContentProvider;
		this.$scope.hasMultipleAccounts = this.hasMultipleAccounts;
		this.$scope.getDashboardEditors = this.getDashboardEditors;
		this.$scope.needsTemplateConfiguration = this.needsTemplateConfiguration;
		this.$scope.templateOptionsLoaded = this.templateOptionsLoaded;
		this.$scope.reloadProjects = this.reloadProjects;
		this.$scope.showColorSelector = this.showColorSelector;
		this.$scope.showDrillableDashboardSelector = this.showDrillableDashboardSelector;
		this.$scope.getTagClass = this.getTagClass;
		this.$scope.togglePersonalization = this.togglePersonalization;
		this.$scope.reloadHierarchies = this.reloadHierarchies;
		this.$scope.confirmAndSave = this.confirmAndSave;
		this.$scope.labelAdded = this.labelAdded;
		this.$scope.labelRemoved = this.labelRemoved;
		this.$scope.loadLabels = this.loadLabels;
		this.$scope.cancel = this.cancel;
		this.$scope.saveBookProperties = this.saveBookProperties;
		this.$scope.isShowNotEditorWarning = this.isShowNotEditorWarning;
		this.$scope.isOwnerChanged = this.isOwnerChanged;
		this.$scope.switchOptimization = this.switchOptimization;
		this.$scope.switchModernLookAndFeelEnabled = this.switchModernLookAndFeelEnabled;
		this.$scope.isCurrentHierarchyMismatch = this.isCurrentHierarchyMismatch;
		this.$scope.isValid = this.isValid;
		this.$scope.switchViewOnlyDrill = this.switchViewOnlyDrill;
		this.$scope.isHierarchySelected = this.isHierarchySelected;
		this.$scope.isProjectSelected = this.isProjectSelected;
		this.$scope.getCurrentHierarchy = this.getCurrentHierarchy;
		this.$scope.hierarchyChanged = this.hierarchyChanged;
		this.$scope.hasHierarchies = this.hasHierarchies;
		this.$scope.showIndicateUserPlacement = this.showIndicateUserPlacement;
		this.$scope.getAllDrillableDashboards = this.getAllDrillableDashboards;
		this.$scope.deselectAll = this.deselectAll;
		this.$scope.updateSelectedProject = this.updateSelectedProject;
		this.$scope.getCPTypeById = this.getCPTypeById;
		this.$scope.isFrontlineEnabled = this.isFrontlineEnabled;
		this.$scope.isFrontlineDashboard = this.isFrontlineDashboard;
		this.$scope.onDefaultLocaleChange = this.onDefaultLocaleChange;

		this.$scope.propsCtrl = $scope;
		this.$scope.getFolderPath = this.getFolderPath;

		this.$scope.promises = {
			loadingTemplate: null,
			loadingTemplateOptions: null,
			saving: null
		};

		this.treeItem.properties = this.treeItem.properties || {} as DashboardProperties;
		this.$scope.config = config;
		this.$scope.isEdited = config.existingItem;

		this.$scope.folders = _.chain(this.config.folders)
			.filter(folder => folder.id !== DashboardType.FEEDBACK_FOLDER)
			.map((folder: any) => {
				folder.displayName = this.getFolderPath(folder);
				return folder;
			})
			.value();

		this.$scope.model = {
			id: this.treeItem.id,
			properties: angular.copy(this.treeItem.properties),
			workspaceProject: angular.copy(this.treeItem.workspaceProject),
			type: this.treeItem.type,
			name: this.treeItem.name,
			description: this.treeItem.description,
			ownerId: this.treeItem.ownerId,
			ownerName: this.treeItem.ownerName,
			parentId: this.config.selectedFolder
				? this.config.selectedFolder.id
				: this.treeItem.parentId,
			labels: angular.copy(this.treeItem.labels) || []
		} as Dashboard;
		this.$scope.viewOnlyDrill = this.$scope.model.properties.viewOnlyDrill;

		this.$scope.user = this.security.getUser();
		this.$scope.selectedOwner = {};

		if (this.config.existingItem) {
			this.$scope.selectedOwner = {
				userId: this.treeItem.ownerId,
				userEmail: this.treeItem.ownerName
			};
		} else {
			this.$scope.selectedOwner = this.$scope.user;
		}

		this.$scope.dashboardEditors = [];
		this.$scope.updateSelectedProject();

		this.$scope.itemList = this.config.itemList;
		this.$scope.initialItem = treeItem;

		this.$scope.hiddenItems = this.config.hiddenItems;
		this.$scope.customLabels = this.config.customLabels;
		if (this.config.existingItem && this.treeItem.type === DashboardType.DASHBOARD) {
			this.$scope.model.name = this.dashboardPropsService.current.name;
			this.$scope.model.description = this.dashboardPropsService.current.description;
		}

		this.$scope.defaultBrandingColor = this.getPrimaryBrandingColor();
		this.$scope.settingsLoading = null;

		this.$scope.maxLength = 256;
		this.$scope.allUsersInHierarchy = true;

		this.$scope.optimizationEnabled = _.isUndefined(this.$scope.model.properties.optimization) && this.isNewItem()
			? this.maPropertiesService.isFeatureEnabled(MasterAccountProperty.DEFAULT_DASHBOARD_OPTIMIZATION)
			: isTrue(this.$scope.model.properties.optimization);
		this.$scope.modernLookAndFeelEnabled = this.isNewItem() ? true :
			!!this.$scope.model.properties.modernLookAndFeelEnabled;
		this.originalHierarchyId = this.$scope.model.properties.hierarchyId;
		// don't load CPs in rename
		if (this.config.isProperties) {
			this.providerExtension = new this.ContentProviderSelectionControllerExtension($scope);
			this.initFavoriteProperties();
			this.providerExtension.init();
			this.initIsProperties();
		}

		this.initTemplate();
	}

	$onInit = () => {
		// required for typescript validation
		this.dashboardPropsService.updateFirstDashboardProps(this.$scope.model);
	};

	isWorkspaceEnabled = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
	};

	isDashboardConfidentialityEnabled = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.DASHBOARD_CONFIDENTIALITY);
	};

	isHierarchySelected = (): boolean => {
		if (this.$scope.model.properties.hierarchyId > 0
			&& this.isPersonalizationToggled()) {
			return true;
		}
		return false;
	};

	isProjectSelected = (): boolean => {
		if (this.$scope.model.properties.project > 0) {
			return true;
		}
		return false;
	};

	getCurrentHierarchy = (): any => {
		let hierarchyList = this.hierarchies;
		return _.findWhere(hierarchyList, {id: this.$scope.model.properties.hierarchyId});
	};

	onWorkspaceProjectsLoading = (promise): void => {
		this.$scope.workspaceProjectsLoading = promise;
	};

	hasMultipleProviders = (): boolean =>
		this.$scope.contentProviders && _.where(this.$scope.contentProviders, {type: 'CB'}).length > 1;

	hasContentProvider = (): boolean =>
		this.$scope.contentProviders && this.$scope.contentProviders.length > 0;

	hasMultipleAccounts = (): boolean =>
		this.$scope.cbAccounts && this.$scope.cbAccounts.length > 1;

	private getPrimaryBrandingColor = (): string|null => {
		if (this.security.getCurrentMasterAccount() && this.security.getCurrentMasterAccount().brandingColors) {
			return this.security.getCurrentMasterAccount().brandingColors.COLOR_4;
		}
		return null;
	};

	private initFavoriteProperties = (): void => {
		if (!this.isNewItem())
			return;

		let favoriteProperties = this.security.getFavoriteProperties();

		if (favoriteProperties?.favoriteCP) {
			this.$scope.model.properties.cbContentProvider = favoriteProperties.favoriteCP || -1;
			this.$scope.model.properties.cbAccount
				= !_.isUndefined(favoriteProperties.favoriteAccount) ? favoriteProperties.favoriteAccount : -1;
			this.$scope.model.properties.project = favoriteProperties.favoriteProject || -1;
		}
	};

	getDashboardEditors = (search): ng.IPromise<any> => {
		if (this.$scope.model && this.$scope.model.id) {
			this.$scope.searchingDashboardEditors = true;

			return this.dashboardApiService.getDashboardEditors(this.$scope.model.id, search, 20)
				.then((users) => {
					this.$scope.searchingDashboardEditors = false;

					users.sort((left, right) => left.userEmail.localeCompare(right.userEmail));
					this.$scope.dashboardEditors = users;
					return users;
				});
		} else {
			return this.$q.when([]);
		}
	};

	needsTemplateConfiguration = (): boolean => this.$scope.template && this.templateHasPlaceholders();
	templateOptionsLoaded = (): boolean => !!this.$scope.templateOptions;

	templateHasPlaceholders = (): boolean => {
		let template = this.$scope.template;
		return template.placeholders
			&& (template.placeholders.calculations.length > 0 || template.placeholders.groupings.length > 0);
	};

	private initTemplate = (): void => {
		let templateId = this.config.templateId;
		if (!templateId) return;

		this.$scope.TemplatePlaceholderType = TemplatePlaceholderType;

		this.$scope.promises.loadingTemplate = this.dashboardTemplatesApiService.getTemplate(templateId)
			.then((template) => { this.$scope.template = template; });

		this.$scope.$watch(
			'[model.properties.cbContentProvider, model.properties.cbAccount, model.properties.project, model.workspaceProject]',
			this.reloadTemplateOptions, true);
	};

	private reloadTemplateOptions = (newValue: number[], oldValue: number[]): void => {
		if (newValue !== oldValue && newValue[2] === oldValue[2]) {
			this.$scope.model.properties.project = -1;
			if (newValue[1] === oldValue[1]) {
				this.$scope.model.properties.cbAccount = -1;
			}
		}
		let project = this.reportAssetUtilsService.getDashboardProject(this.$scope.model);

		if (!_.isUndefined(project) && WorkspaceTransitionUtils.isProjectSelected(project)) {
			this.$scope.promises.loadingTemplateOptions = this.templateOptionsService
				.loadTemplateOptions(project)
				.then((templateOptions) => {
					templateOptions.attributes = templateOptions.attributes || [];
					templateOptions.models = templateOptions.models || [];
					templateOptions.metrics = templateOptions.metrics || [];
					templateOptions.projectContext = templateOptions.projectContext || {};
					this.$scope.templateOptions = templateOptions;

				});
		} else {
			delete this.$scope.templateOptions;
		}
	};

	private updateName = (): void => {
		this.dashboardPropsService.current.name = this.$scope.model.name;
	};

	private updateDescription = (): void => {
		this.dashboardPropsService.current.description = this.$scope.model.description;
	};

	private updateOwner = () => {
		if (!this.isSupportingPropertiesUpdate())
			return;
		this.$scope.model.ownerId = this.$scope.selectedOwner.userId;
		this.$scope.model.ownerName = this.$scope.selectedOwner.userEmail;
		this.dashboardPropsService.current.ownerId = this.$scope.model.ownerId;
		this.dashboardPropsService.current.ownerName = this.$scope.model.ownerName;
	};

	private isSupportingPropertiesUpdate = () => {
		return this.config.existingItem && (this.treeItem.type === DashboardType.DASHBOARD || this.treeItem.type === DashboardType.BOOK);
	};

	getCPTypeById = (cpId): string => {
		if (!this.$scope.contentProviders || !this.$scope.contentProviders.length)
			return;
		let cp: any = _.findWhere(this.$scope.contentProviders, {id: cpId});
		return cp ? cp.type : undefined;
	};

	onWorkspaceProjectSelected = () => {
		this.updateSelectedProject();

		if (!WorkspaceProjectUtils.isProjectSelected(this.$scope.model.workspaceProject)) {
			return;
		}

		let palettePromise = this.reloadAccountPalette(this.$scope.model);
		let palettesPromise = this.colorPalettes.getWidgetPalettes();

		this.$scope.settingsLoading = this.$q.all([palettePromise, palettesPromise]).then((data) => {
			this.$scope.showErrorsForCP = [];
			this.$scope.providerColors = data[0];
			this.$scope.palettes = data[1];
		}, (error) => {
			this.providerExtension.clearProjects();
			this.$scope.showErrorsForCP = [];
			this.$scope.showErrorsForCP.pushAll(error);
		});
	};

	reloadProjects = () => {
		if (!this.config.isProperties) {
			return;
		}
		if (!this.initialPropsSet) {
			this.dashboardPropsService.initializeDashboardProps(this.$scope.model, this.config.existingItem);
			this.initialPropsSet = true;
		}
		if (this.providerExtension.isEmpty(this.$scope.model.properties.cbAccount)
			|| this.providerExtension.isEmpty(this.$scope.model.properties.cbContentProvider)) {
			this.$scope.model.properties.project = -1;
			this.$scope.allDrillableDashboards = [];
			return;
		}
		let optionPromise = this.contentProviderOptionsService.getOptions(
			this.$scope.model.properties.cbContentProvider,
			'projects',
			{
				contentProviderId: this.$scope.model.properties.cbContentProvider,
				accountId: this.$scope.model.properties.cbAccount
			},
			true
		);

		let palettePromise = this.reloadAccountPalette(this.$scope.model);

		let palettesPromise = this.colorPalettes.getWidgetPalettes();

		this.$scope.settingsLoading = this.$q.all([optionPromise, palettePromise, palettesPromise]).then((data) => {
			this.$scope.showErrorsForCP = [];
			this.$scope.projects = data[0].data;

			if (this.authorization.hasStudioAdminProjectAccess()) {
				_.each(this.$scope.projects, (project: any) => project.sortField = project.name );
				let studioProject = this.adminProjectsService.getStudioAdminProject();
				this.$scope.projects.push({
					projectId: studioProject.projectId,
					name: studioProject.title,
					sortField: '',
					type: studioProject.type,
					internalProject: true,
					hasProjectSeparator: true
				});
			}
			this.$scope.palettes = data[2];
			if (data[0].data && data[0].data.length > 0) {
				this.providerExtension.checkProperty('project', this.$scope.projects, 'projectId');

				if (this.$scope.projects.length === 1) {
					this.$scope.model.properties.project = this.$scope.projects[0].projectId;
					this.$scope.updateSelectedProject();
				}
			}
			this.$scope.providerColors = data[1];
		}, (error) => {
			this.providerExtension.clearProjects();
			this.$scope.showErrorsForCP = [];
			this.$scope.showErrorsForCP.pushAll(error);
		});
	};

	isCurrentHierarchyMismatch = (): boolean => {
		let project = this.reportAssetUtilsService.getDashboardProject(this.$scope.model);
		return this.isHierarchyMismatch(this.getCurrentHierarchy(), project);
	};

	isHierarchyMismatch = (hierarchy: Hierarchy, project: AccountOrWorkspaceProject): boolean => {
		if (!hierarchy || !WorkspaceTransitionUtils.isProjectSelected(project)) {
			return false;
		}
		let searchProps = WorkspaceTransitionUtils.isWorkspaceProject(project)
			? { workspaceId: project.workspaceId, projectId: project.projectId }
			: { contentProviderId: project.contentProviderId, projectId: project.projectId };
		return !_.findWhere(hierarchy.projects, searchProps);
	};

	private reloadAccountPalette = (dashboard: Dashboard): ng.IPromise<string[]> => {
		return this.colorPalettes.getDashboardDesignerPalette(dashboard);
	};

	showColorSelector = (): boolean => {
		if (this.isWorkspaceEnabled()) {
			return WorkspaceProjectUtils.isProjectSelected(this.$scope.model.workspaceProject)
				&& this.$scope.providerColors;
		}
		return this.$scope.model.properties.project !== -1 && this.$scope.providerColors;
	};

	showDrillableDashboardSelector = (): boolean => this.$scope.model.properties.project > -1;

	getTagClass = (tag): string => {
		return _.findWhere(this.$scope.allDrillableDashboards, { id: tag.id })
			? '' : 'br-drillable-tag-invalid';
	};

	togglePersonalization = (): void => {
		if (this.$scope.model.properties.allowPersonalization) {
			this.$scope.reloadHierarchies();
			this.$scope.allUsersInHierarchy = true;
		} else {
			this.$scope.model.properties.hierarchyId = -1;
			this.$scope.model.properties.userPlacementEnabled = false;
			this.$scope.model.properties.showOrganizationContext = false;
		}
		this.$scope.hierarchyChanged();
	};

	private updateFilteredHierachies = (): void => {
		let project = this.reportAssetUtilsService.getDashboardProject(this.$scope.model);
		this.$scope.filteredHierarchies = _.filter(this.hierarchies,
			(hierarchy) => !this.isHierarchyMismatch(hierarchy, project));
	};

	reloadHierarchies = (): ng.IPromise<any> => {
		let deferred = this.$q.defer();
		this.$scope.settingsLoading = this.organizationApiService.getOrganizationList()
			.then((result) => {
				let data = result.data.length === 0 ? [] : result.data;
				return data;
			})
			.then((result) => this.loadSelectedHierarchy(result))
			.then((result) => {
				this.hierarchies = result;
				this.updateFilteredHierachies();
				this.validateHierarchy();
				deferred.resolve({});
			});
		return deferred.promise;
	};

	private loadSelectedHierarchy = (hierarchyList): ng.IPromise<any> => {
		let hierarchyId = this.$scope.model.properties.hierarchyId;
		let selectedHierarchy = _.findWhere(hierarchyList, {id: hierarchyId});
		if (!selectedHierarchy && hierarchyId > 0) {
			return this.organizationApiService.getOrgHierarchyIgnoreErrors(hierarchyId).then((response) => {
				hierarchyList.push({
					name: this.locale.getString('common.disabledOrgHierarchy', {name: response.data.name}),
					id: hierarchyId,
					disabled: true
				});
				return hierarchyList;
			}, () => {
				hierarchyList.push({
					name: this.locale.getString('common.deletedOrgHierarchy'),
					id: hierarchyId,
					disabled: true
				});
				return hierarchyList;
			});
		}
		return this.$q.resolve(hierarchyList);
	};


	private checkPropertiesChangedAndShowDialog = (): ng.IPromise<boolean> => {
		let changedProperties: any = { owner: this.$scope.isOwnerChanged() };
		return this.getSharedOrganizationEntries(this.$scope.model.id).then((sharedOrg) => {
			this.$scope.sharedOrg = angular.copy(sharedOrg);
			changedProperties.personalization = !!sharedOrg.length && (this.isPersonalizationDisabled() || this.isSharedOrgHierarchyChanged());
			if (changedProperties.owner || changedProperties.personalization) {
				let localizationProperties = {
					oldOwnerEmail: this.getOldOwnerEmail(),
					newOwnerEmail: this.getNewOwnerEmail(),
					organizationName: this.getSharedOrgName(sharedOrg)
				};
				return PromiseUtils.old(this.downgradeDialogService.openDashboardChangeOwnerModal({
					bodyText: this.getPropertiesChangedDialogText(localizationProperties, changedProperties),
					showCheckbox: changedProperties.owner
				}));
			} else {
				return this.$q.when(undefined);
			}
		});
	};

	private getPropertiesChangedDialogText = (localizationProperties, changedProperties): string => {
		if (changedProperties.owner && changedProperties.personalization) {
			return this.locale.getString('dashboard.ownerAndPersonalizationText', localizationProperties);
		} else if (changedProperties.owner) {
			return this.locale.getString('dashboard.transferOwnerWidgetText', localizationProperties);
		} else if (changedProperties.personalization) {
			return this.locale.getString('dashboard.disablePersonalizationText', localizationProperties);
		}
		return '';
	};

	private getSharedOrgName = (sharedOrg): string => {
		if (sharedOrg.length) {
			let hierarchyIdList = sharedOrg.map((org) => org.entity.hierarchyEntries[0].hierarchyId)
				.filter((id) => id !== this.$scope.model.properties.hierarchyId);
			return this.hierarchies
				.filter((hierarchy) => hierarchyIdList.includes(hierarchy.id))
				.map((hierarchy) => hierarchy.name).join(', ');
		}
		return '';
	};

	private isPersonalizationToggled = (): boolean =>
		this.$scope.model.properties.allowPersonalization !== this.treeItem.properties.allowPersonalization;

	private isPersonalizationDisabled = (): boolean =>
		this.isPersonalizationToggled() && !this.$scope.model.properties.allowPersonalization;

	private isHierarchyContextToggled = (): boolean =>
		this.$scope.model.properties.showOrganizationContext !== this.treeItem.properties.showOrganizationContext;

	private isIndicateUserPlacementToggled = (): boolean =>
		this.$scope.model.properties.userPlacementEnabled !== this.treeItem.properties.userPlacementEnabled;

	private isUserPlacementColorChanged = (): boolean =>
		this.$scope.model.properties.userPlacementColor !== this.treeItem.properties.userPlacementColor;

	private isHierarchyChanged = (): boolean =>
		this.$scope.model.properties.hierarchyId !== this.treeItem.properties.hierarchyId;

	private isSharedOrgHierarchyChanged = (): boolean => {
		if (this.$scope.sharedOrg.length) {
			return _.find(this.$scope.sharedOrg, (org: any) =>
				org.entity.hierarchyEntries[0].hierarchyId !== this.$scope.model.properties.hierarchyId);
		}
		return false;
	};

	private triggerPersonalizationToggle = (allowPersonalization): void => {
		if (!this.isPersonalizationToggled()) return;

		this.triggerHierarchyHistoryChange(allowPersonalization);
	};

	private triggerHierarchyHistoryChange = (allowPersonalization): void => {
		if (!allowPersonalization)
			this.currentWidgets.getDashboardHistoryIfAvailable(this.treeItem.id + '')?.clearAppliedHierarchyNode();

		this.$scope.model.properties.isAttributeFiltersApplied = true;
	};

	private getSharedOrganizationEntries = (dashboardId): ng.IPromise<any> => {
		if (dashboardId) {
			return this.dashboardApiService.getDashboardUsers(dashboardId).then((result) => {
				return _.filter(result.shareEntities.GROUP, (item: any) => {
					return item && item.entity.type === GroupType.WHOLE_HIERARCHY;
				});
			});
		}
		return this.$q.when([]);
	};

	private save = (retainEditPermission?) => {
		let isOwnerChanged = this.$scope.isOwnerChanged();
		let dashboardOwnerUpdateResult: any = {
			updated: false
		};

		let projectUpdateResult: any = {
			updated: false
		};

		let personalizationResult = {
			updated: false
		};

		let modernLookAndFeelResult = {
			updated: this.treeItem.properties.modernLookAndFeelEnabled !== this.$scope.modernLookAndFeelEnabled
		};

		if (this.$scope.model.type === DashboardType.DASHBOARD) {
			this.$scope.model.properties.optimization = this.$scope.optimizationEnabled;
			this.$scope.model.properties.viewOnlyDrill = this.$scope.viewOnlyDrill;
			this.$scope.model.properties.modernLookAndFeelEnabled = this.$scope.modernLookAndFeelEnabled;
		}

		if (this.$scope.template) {
			this.$scope.model.appliedFiltersArray = this.$scope.template.appliedFiltersArray;
		}

		this.updateItemProperties();
		if (this.config.existingItem && this.config.isProperties) {
			if (isOwnerChanged) {
				dashboardOwnerUpdateResult = {
					updated: true,
					oldOwnerEmail: this.getOldOwnerEmail(),
					newOwnerEmail: this.getNewOwnerEmail(),
					retainEditPermission
				};
			}
			this.updateOwner();

			if (this.$scope.model.type === DashboardType.DASHBOARD) {
				if (!this.$scope.model.properties.allowPersonalization) {
					this.treeItem.appliedFiltersArray = this.dashboardFiltersService.getAttributeFilters(this.treeItem.appliedFiltersArray);
					this.treeItem.appliedFiltersArray.unshift({isOrgHierarchy: true});
				}

				// eslint-disable-next-line eqeqeq
				if (this.treeItem.properties.project != this.$scope.model.properties.project) {
					this.treeItem.appliedFiltersArray = this.dashboardFiltersService.getHierarchyFilter(this.treeItem.appliedFiltersArray);
					this.currentWidgets.getDashboardHistoryIfAvailable(this.treeItem.id + '')?.saveFilterUpdates(this.treeItem.appliedFiltersArray);
					projectUpdateResult = {
						updated: true,
						oldProject: this.treeItem.properties.project,
						newProject: this.$scope.model.properties.project
					};
				}
			}

			if (this.$scope.model.type !== 'folder') {

				this.createAction(DashboardChangeType.PROPERTIES_CHANGED);

				this.dashboardPropsService.updateDashboardProps(this.$scope.model);
				this.dashboardPropsService.commitUpdates();
			}

			if (this.$scope.model.type === DashboardType.DASHBOARD) {
				personalizationResult.updated = this.isPersonalizationToggled() ||
					this.isUserPlacementColorChanged() || this.isIndicateUserPlacementToggled() ||
					this.isHierarchyChanged() || this.isHierarchyContextToggled();
				this.triggerPersonalizationToggle(this.$scope.model.properties.allowPersonalization);

				let unshareDashboard = this.$scope.sharedOrg && !!this.$scope.sharedOrg.length
					&& (this.isPersonalizationDisabled() || this.isSharedOrgHierarchyChanged());
				if (unshareDashboard) {
					this.dashboardService.unshareDashboardWithOrganization(this.$scope.model.id, this.$scope.sharedOrg);
				}
			}
		}

		this.$uibModalInstance.close({
			model: this.$scope.model,
			ownerUpdateResult: dashboardOwnerUpdateResult,
			projectUpdateResult,
			personalizationResult,
			modernLookAndFeelResult,
			template: this.$scope.template
		});

	};

	private createAction = (dashboardChangeType: DashboardChangeType): void => {
		let actions: DashboardChangeAction[] = [];
		actions.push(new DashboardPropertiesChangeAction(this.treeItem, this.dashboardPropsService.initial,
			this.$scope.model, this.dashboardPropsService, this.dashboardService));
		this.dashboardHistoryState.addDashboardChange(dashboardChangeType, actions);
	};

	confirmAndSave = () => {
		if (this.$scope.model.id && this.config.isProperties) {
			this.$scope.promises.saving = this.checkPropertiesChangedAndShowDialog().then((retainEditPermission) => {
				this.save(retainEditPermission);
			});
		} else {
			this.save();
		}
	};

	private revertUpdatesAndCloseModal = (): void => {
		this.dashboardPropsService.revertUpdates();
		this.$uibModalInstance.dismiss('cancel');
	};

	labelAdded = (tag) => {
		this.$scope.model.labels = this.$scope.model.labels || [];
		this.$scope.model.labels.push(tag);
	};

	labelRemoved = (tag) => {
		this.$scope.model.labels = _.without(this.$scope.model.labels, tag);
	};

	loadLabels = (query) => {
		return this.labelsApiService.getLabels(query);
	};

	cancel = () => {
		if (this.itemSupportsUnsavedChangesDialog() && this.hasChangedInitialItem()) {
			this.showUnsavedChangesDialog();
		} else {
			// still need to reset potential name change
			this.revertUpdatesAndCloseModal();
		}
	};

	private itemSupportsUnsavedChangesDialog = (): boolean => {
		let itemType = this.$scope.model.type;
		return itemType === DashboardType.DASHBOARD || itemType === DashboardType.BOOK;
	};

	private hasChangedInitialItem = (): boolean => {
		return (this.config.isProperties && this.dashboardPropsService.hasChanges(this.$scope.model))
			|| this.dashboardPropsService.isItemRenamed(this.$scope.model);
	};

	private showUnsavedChangesDialog = () => {
		let itemType = this.$scope.model.type;

		let dialogHeader = itemType === DashboardType.DASHBOARD
			? 'dashboard.unsavedChangesHeader' : 'dashboard.unsavedBookHeader';
		let dialogText = itemType === DashboardType.DASHBOARD
			? 'dashboard.unsavedChanges' : 'dashboard.unsavedBookChanges';

		this
			.downgradeDialogService
			.showUnsavedChangesDialogAndResolve(
				this.$scope.confirmAndSave,
				this.revertUpdatesAndCloseModal,
				dialogHeader,
				dialogText,
				!this.isValid()
			)
			.catch(() => {})
		;
	};

	private isValid(): boolean {
		return this.$scope.propertiesDialog
			&& !this.$scope.propertiesDialog.$invalid
			&& !this.isCurrentHierarchyMismatch();
	}

	saveBookProperties = (): void => {
		let dashboardOwnerUpdateResult = {
			updated: false
		};
		if (this.$scope.isOwnerChanged()) {
			this.cbDialogService.confirmYesNo(this.locale.getString('dashboard.transferBookOwnerTitle'),
				this.locale.getString('dashboard.transferBookOwnerMessage'), this.saveBook);
		} else {
			this.updateItemProperties();
			this.$uibModalInstance.close({
				model: this.$scope.model,
				ownerUpdateResult: dashboardOwnerUpdateResult
			});
		}
	};

	private saveBook = (retainEditPermission): void => {

		let isOwnerChanged = this.$scope.isOwnerChanged();
		let dashboardOwnerUpdateResult: any = {
			updated: false
		};
		if (isOwnerChanged) {
			dashboardOwnerUpdateResult = {
				updated: true,
				oldOwnerEmail: this.getOldOwnerEmail(),
				newOwnerEmail: this.getNewOwnerEmail(),
				retainEditPermission
			};
		}
		this.updateItemProperties();
		this.updateOwner();
		this.$uibModalInstance.close({
			model: this.$scope.model,
			ownerUpdateResult: dashboardOwnerUpdateResult
		});

	};

	private updateItemProperties = (): void => {
		if (!this.isSupportingPropertiesUpdate())
			return;

		this.updateName();
		this.updateDescription();
	};

	isShowNotEditorWarning = (): boolean => {
		if (!this.$scope.selectedOwner) return false;
		let selectedUser: any = _.findWhere(this.$scope.dashboardEditors, {userId: this.$scope.selectedOwner.userId});
		return selectedUser && !selectedUser.editor;
	};

	isOwnerChanged = (): boolean => {
		return this.$scope.model && this.$scope.model.ownerId
			&& this.$scope.selectedOwner && this.$scope.selectedOwner.userId
			&& this.$scope.selectedOwner.userId !== this.$scope.model.ownerId;
	};

	private getOldOwnerEmail = (): string => this.$scope.model.ownerName;

	private getNewOwnerEmail = (): string => this.$scope.selectedOwner.userEmail;

	private getFolderPath = (folder): string => {
		let path = folder.name;
		while (folder.parent) {
			folder = folder.parent;
			path = folder.name + ' > ' + path;
		}

		return path;
	};

	private isNewItem = (): boolean => {
		let initialItemId = this.$scope.initialItem.id;
		return _.isUndefined(initialItemId) || initialItemId <= 0;
	};

	switchOptimization = (): void => {
		this.$scope.optimizationEnabled = !this.$scope.optimizationEnabled;
	};

	switchModernLookAndFeelEnabled = (): void => {
		this.$scope.modernLookAndFeelEnabled = !this.$scope.modernLookAndFeelEnabled;
	};

	switchViewOnlyDrill = (): void => {
		this.$scope.viewOnlyDrill = !this.$scope.viewOnlyDrill;
	};

	private isHierarchyGroupInDashboard = (): void => {
		if (this.isNewItem())
			return;

		let dashboardId = this.$scope.model.id;
		let hierarchyId = this.$scope.model.properties.hierarchyId;
		if (hierarchyId > 0 && hierarchyId !== this.originalHierarchyId) {
			this.organizationApiService.isHierarchyGroupInDashboard(dashboardId, hierarchyId).then((response) => {
				this.$scope.showHierarchyGroupWarning = response.data;
			});
		} else {
			this.$scope.showHierarchyGroupWarning = false;
		}
	};

	private validateHierarchy = (): void => {
		if (!this.$scope.model.properties.allowPersonalization) {
			return;
		}
		let hierarchySelected = this.$scope.model.properties.hierarchyId > 0;
		let hierarchyInactive = hierarchySelected && _.findWhere(this.hierarchies, {
			id: this.$scope.model.properties.hierarchyId,
			disabled: true});
		this.$scope.propertiesDialog.hierarchySelector?.$setValidity('noHierarchy', hierarchySelected);
		this.$scope.propertiesDialog.hierarchySelector?.$setValidity('inactiveHierarchy', !hierarchyInactive);
	};

	hierarchyChanged = (): void => {
		this.validateHierarchy();
		this.isHierarchyGroupInDashboard();
	};

	hasHierarchies = (): boolean =>
		(this.hierarchies && this.hierarchies.length > 0)
			|| (this.$scope.model.properties && this.$scope.model.properties.hierarchyId > 0);

	showIndicateUserPlacement = (): boolean => {
		if (this.$scope.model.properties && this.$scope.model.properties.hierarchyId >= 0) {
			let selectedHierarchy: any = _.findWhere(this.hierarchies, {id: this.$scope.model.properties.hierarchyId});
			return selectedHierarchy ? this.$scope.model.properties.allowPersonalization && selectedHierarchy.classified : false;
		}
		return false;
	};

	getAllDrillableDashboards = (query: string): Dashboard[] => {
		if (isEmpty(this.$scope.allDrillableDashboards)) {
			this.$scope.allDrillableDashboards = this.dashboardService.getAllDrillableDashboards(this.$scope.model, true);
		}

		return !query ? this.$scope.allDrillableDashboards
			: _.filter(this.$scope.allDrillableDashboards,
				(dashboard) => dashboard.name.search(new RegExp(query?.escapeRegExp(), 'i')) > -1);
	};

	deselectAll = (): void => {
		this.$scope.model.properties.drillableDashboards = [];

	};

	updateSelectedProject = (): void => {
		this.$scope.allDrillableDashboards = this.dashboardService.getAllDrillableDashboards(this.$scope.model, true);
		this.updateFilteredHierachies();
	};

	private initIsProperties = (): void => {

		if (_.isUndefined(this.$scope.model.properties.preventDashboardEditorsShare)) {
			this.$scope.model.properties.preventDashboardEditorsShare = true;
		}

		if (_.isUndefined(this.$scope.model.properties.userPlacementEnabled)) {
			this.$scope.model.properties.userPlacementEnabled = false;
		}

		if (_.isUndefined(this.$scope.model.properties.allowSharingThroughEmbedding)) {
			this.$scope.model.properties.allowSharingThroughEmbedding = false;
		}

		if (_.isUndefined(this.$scope.model.properties.userPlacementColor)) {
			this.$scope.model.properties.userPlacementColor = this.$scope.defaultBrandingColor;
		}

		this.$scope.isProperties = this.config.isProperties;
		this.providerExtension.reloadContentProviders();
		this.$scope.reloadHierarchies().then(() => {
			if (!this.initialPropsSet) {
				if (!this.providerExtension.isValidationInProgress()) {
					this.dashboardPropsService.initializeDashboardProps(this.$scope.model, this.config.existingItem);
					this.initialPropsSet = true;
				}
			}
		});
	};

	isFrontlineEnabled = () => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.FRONTLINE_DASHBOARDS);
	};

	isFrontlineDashboard = () => {
		return FrontlineDashboardUtils.isFrontlineDashboard(this.$scope.model);
	};

	onDefaultLocaleChange = (defaultLocale: string) => {
		this.$scope.model.properties.defaultLocale = defaultLocale;
	};
}

app.controller('DashboardPropsCtrl', DashboardPropertiesController);
