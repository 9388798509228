import * as _ from 'underscore';
import { RefreshStatus } from '@cxstudio/common/refresh-status.constant';
import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { downgradeComponent } from '@angular/upgrade/static';


// trigger to refresh something and automatically update with refresh / refreshing / refreshed text + icon
// refresh function is the function to reload whatever data, and must return a promise
// OPTIONAL: text is the refresh/refreshing/refreshed text to use
// OPTIONAL: refresh limit is the time (in seconds) to delay between refreshes.
// can be set to zero for no limit, or ommitted to use the default of 5 seconds
@Component({
	selector: 'refresh-trigger',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div (click)="clickHandler()">
			<span [ngClass]="refreshStatus">
				{{getRefreshText()}}
				<i [hidden]="isComplete()"
					class="q-icon-refresh"
					[ngClass]="{'rotate-infinite' : isInProgress()}">
				</i>
			</span>
		</div>
	`
})
export class RefreshTriggerComponent implements OnInit {
	@Input() refreshFunction: () => Promise<any>;
	@Input() refreshLimit: number;
	@Input() text: {
		refresh: string;
		refreshed: string;
		refreshing: string;
	};

	refreshStatus: RefreshStatus;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService
	) {}

	ngOnInit(): void {
		this.refreshStatus = RefreshStatus.AVAILABLE;
		this.refreshLimit = !_.isUndefined(this.refreshLimit) ? this.refreshLimit : 5;

		this.text = this.text || {} as any;
		this.text.refresh = this.text.refresh || this.locale.getString('common.refresh');
		this.text.refreshed = this.text.refreshed || this.locale.getString('common.refreshed');
		this.text.refreshing = this.text.refreshing || this.locale.getString('common.refreshing');
	}

	getRefreshText = () => {
		switch (this.refreshStatus) {
			case RefreshStatus.IN_PROGRESS:
				return this.text.refreshing;
			case RefreshStatus.COMPLETE:
				return this.text.refreshed;
			case RefreshStatus.AVAILABLE:
				return this.text.refresh;
		}
	};

	clickHandler = () => {
		if (this.refreshStatus === RefreshStatus.AVAILABLE) {
			this.refreshStatus = RefreshStatus.IN_PROGRESS;
			this.refreshFunction().then(this.refreshComplete);
		}
	};

	isInProgress = (): boolean => {
		return this.refreshStatus === RefreshStatus.IN_PROGRESS;
	};

	isComplete = (): boolean => {
		return this.refreshStatus === RefreshStatus.COMPLETE;
	};

	private refreshComplete = () => {
		setTimeout(() => {
			this.refreshStatus = RefreshStatus.COMPLETE;
			this.ref.markForCheck();
		}, 500);

		if (this.refreshLimit && this.refreshLimit > 0) {
			setTimeout(() => {
				this.refreshStatus = RefreshStatus.AVAILABLE;
				this.ref.markForCheck();
			}, (this.refreshLimit * 1000));
		} else {
			this.refreshStatus = RefreshStatus.AVAILABLE;
			this.ref.markForCheck();
		}
	};
}

app.directive('refreshTrigger', downgradeComponent({component: RefreshTriggerComponent}));
