import ILocale from '@cxstudio/interfaces/locale-interface';
import { GlobalNotificationType, GlobalNotification} from '@cxstudio/common/global-notification/global-notification';
import { GlobalWarning } from '@cxstudio/common/global-notification/global-warning';
import { GlobalError } from '@cxstudio/common/global-notification/global-error';

/**
 * Modify rest url, add custom headers
 */


// TODO: Combine with toast service

export class GlobalNotificationService {
	private DEFAULT_TIMEOUT = 4000;
	private MAX_NOTIFICATIONS = 5;

	constructor(
		private $rootScope: ng.IRootScopeService,
		private $timeout: ng.ITimeoutService,
		private locale: ILocale
	) {
		this.$rootScope.globalNotifications = [];
	}

	showWarning(message: string, autoHide?: number): void {
		let newMessage = new GlobalWarning(message);
		this.showErrorInternal(newMessage, autoHide);
	}

	showError(message: string, eid: string, autoHide?: number): void {
		let newMessage = new GlobalError(message, eid);
		this.showErrorInternal(newMessage, autoHide);
	}

	private showErrorInternal(message: GlobalNotification, autoHide?): void {
		this.$rootScope.globalWarning.message = message;
		this.$rootScope.globalWarning.visible = true;
		if (autoHide) {
			this.$timeout(() => {
				if (this.$rootScope.globalWarning.message === message) {
					this.$rootScope.globalWarning.visible = false;
				}
			}, autoHide * 1000);
		}
	}

	addSuccessNotification = (message: string): void => {
		let notification = this.createNotification(message, GlobalNotificationType.SUCCESS);
		this.addNotification(notification, this.DEFAULT_TIMEOUT);
	};

	addNotification(notification: GlobalNotification, seconds: number): void {
		this.addNotificationToArray(notification, seconds, this.$rootScope.globalNotifications);
	}

	private addNotificationToArray(notification: GlobalNotification, seconds: number, notificationsArray): void {
		if (notificationsArray.length > this.MAX_NOTIFICATIONS) {
			notificationsArray.removeAt(0);
		}
		notificationsArray.push(notification);
		this.$timeout(() => {
			notificationsArray.remove(notification);
		}, seconds);
	}

	createNotification(message: string, type: GlobalNotificationType): GlobalNotification {
		return new GlobalNotification(message, type);
	}

	addSavedNotification(): void {
		this.addSuccessNotification(this.locale.getString('common.saved'));
	}

	addItemSavedNotification(itemName: string): void {
		this.addSuccessNotification(this.locale.getString('common.itemSaved', {itemName}));
	}

	addCreatedNotification(itemName: string): void {
		this.addSuccessNotification(this.locale.getString('common.itemAdded', {itemName}));
	}

	addUpdatedNotification(itemName: string): void {
		this.addSuccessNotification(this.locale.getString('common.itemUpdated', {itemName}));
	}

	addEnabledNotification(itemName: string): void {
		this.addSuccessNotification(this.locale.getString('common.itemEnabled', {itemName}));
	}

	addDisabledNotification(itemName: string): void {
		this.addSuccessNotification(this.locale.getString('common.itemDisabled', {itemName}));
	}

	addDeletedNotification(itemName: string): void {
		this.addSuccessNotification(this.locale.getString('common.itemDeleted', {itemName}));
	}

	addDeletedAndReplaceNotification(itemName: string, replacedName: string): void {
		this.addSuccessNotification(this.locale.getString('common.itemDeletedAndReplaced', {itemName, replacedName}));
	}

	addReplacedNotification(itemName: string, replacedName: string): void {
		this.addSuccessNotification(this.locale.getString('common.itemReplaced', {itemName, replacedName}));
	}

	addItemMovedNotification(itemName: string, folder: any): void {
		if (!folder || !folder.name) {
			this.addSuccessNotification(this.locale.getString('common.itemMovedToRoot', {itemName}));
		} else {
			this.addSuccessNotification(this.locale.getString('common.itemMovedToFolder',
				{itemName, folderName: folder.name}));
		}
	}


	addFolderNotification(folderName: string): void {
		this.addSuccessNotification(this.locale.getString('common.folderWasAdded', {folderName}));
	}

	deleteFolderNotification(folderName: string): void {
		this.addSuccessNotification(this.locale.getString('common.folderWasDeleted', {folderName}));
	}

	addVisibilityChangedNotification(itemName: string, isHidden: boolean): void {
		this.addSuccessNotification(this.locale.getString(isHidden ? 'common.itemHidden' : 'common.itemMadeVisible',
			{itemName}));
	}
}

app.service('globalNotificationService', GlobalNotificationService);
