import TimeReferenceLine from './time-reference-line';
import TimeReferenceType from './time-reference-line-type';
import CategoryIndexSearchMode from './category-index-search-mode';
import ChartType from '@cxstudio/reports/entities/chart-type';
import { ReferenceLinesLabelsUtils } from './reference-lines-labels-utils.service';
import { ReferenceLineIndex } from './reference-line-index.constants';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { Injectable } from '@angular/core';
import { TimeReferenceLinesIndexSearcher } from '@app/modules/plot-lines/reference-lines/time-reference-lines-index-searcher.service';

@Injectable({
	providedIn: 'root'
})
export class TimeReferenceLinesUtils {

	constructor(
		private readonly timeReferenceLinesIndexSearcher: TimeReferenceLinesIndexSearcher,
		private readonly referenceLinesLabelsUtils: ReferenceLinesLabelsUtils
	) {}

	buildEvent = (
		timeRefLine: TimeReferenceLine, hierarchyData: any[], options: VisualProperties, utils: WidgetUtils
	): Highcharts.AxisPlotLinesOptions => {
		let isHorizontalBar: boolean = this.isHorizotantalBar(options);
		let spansCreated: boolean = this.spansCreated(options.timeReferenceLines);
		let label: Highcharts.AxisPlotLinesLabelOptions = timeRefLine.displayLabel
			? this.referenceLinesLabelsUtils.getEventLabel(timeRefLine, isHorizontalBar, spansCreated)
			: null;

		let index: number = this.timeReferenceLinesIndexSearcher.getCategoryIndex(timeRefLine.date, hierarchyData,
			options, utils, CategoryIndexSearchMode.EXACT);
		if (index !== -1) {
			return this.transformToPlotLine(timeRefLine, label, index);
		} else return undefined;
	};

	buildEventLabelLine = (
		timeRefLine: TimeReferenceLine, hierarchyData: any[], options: VisualProperties, utils: WidgetUtils
	): Highcharts.AxisPlotLinesOptions => {
		let labelLine: any = this.referenceLinesLabelsUtils.createLabelLine(timeRefLine);
		return this.buildEvent(labelLine, hierarchyData, options, utils);
	};

	private spansCreated = (timeReferenceLines: TimeReferenceLine[]): boolean => {
		return !!_.find(timeReferenceLines, timeReferenceLine => {
			return timeReferenceLine.timeReferenceType === TimeReferenceType.SPAN;
		});
	};

	private isHorizotantalBar = (options: VisualProperties): boolean => {
		return options.subChartType === ChartType.BAR;
	};

	private transformToPlotLine(timeRefLine: TimeReferenceLine, label: Highcharts.AxisPlotLinesLabelOptions,
		categoryIndex: number): Highcharts.AxisPlotLinesOptions {
		return {
			label,
			color: timeRefLine.color,
			dashStyle: timeRefLine.type || (timeRefLine as any).selectedBaseLineStyle.type, // old format
			width: timeRefLine.width || (timeRefLine as any).selectedPlotLineWidthNumber,
			zIndex: this.getTimeReferenceLineIndex(timeRefLine.labelLine, timeRefLine.timeReferenceType),
			value: categoryIndex
		};
	}

	buildSpan = (
		timeRefLine: TimeReferenceLine, hierarchyData: any[], options: VisualProperties, utils: WidgetUtils
	): Highcharts.AxisPlotBandsOptions => {
		let from: number = this.timeReferenceLinesIndexSearcher.getCategoryIndex(timeRefLine.from, hierarchyData,
			options, utils, CategoryIndexSearchMode.FROM);
		let to: number = this.timeReferenceLinesIndexSearcher.getCategoryIndex(timeRefLine.to, hierarchyData,
			options, utils, CategoryIndexSearchMode.TO);
		if (from !== -1 && to !== -1 && from < to) {
			let isHorizontal: boolean = this.isHorizotantalBar(options);
			let label: Highcharts.AxisPlotBandsLabelOptions = timeRefLine.displayLabel
				? this.referenceLinesLabelsUtils.getSpanLabel(timeRefLine, isHorizontal)
				: null;

			return this.transformToPlotBand(timeRefLine, label, from, to);
		} else return undefined;
	};

	buildSpanLabelLine = (
		timeRefLine: TimeReferenceLine, hierarchyData: any[], options: VisualProperties, utils: WidgetUtils
	): Highcharts.AxisPlotBandsOptions => {
		let labelLine: any = this.referenceLinesLabelsUtils.createLabelLine(timeRefLine);
		return this.buildSpan(labelLine, hierarchyData, options, utils);
	};

	private transformToPlotBand(
		timeRefLine: TimeReferenceLine, label: Highcharts.AxisPlotBandsLabelOptions, fromIndex: number, toIndex: number
	): Highcharts.AxisPlotBandsOptions {
		return {
			label,
			color: timeRefLine.color,
			from: fromIndex,
			to: toIndex,
			zIndex: this.getTimeReferenceLineIndex(timeRefLine.labelLine, timeRefLine.timeReferenceType),
		};
	}

	private getTimeReferenceLineIndex = (labelLine: boolean, type?: TimeReferenceType): number => {
		switch (type) {
			case TimeReferenceType.EVENT:
				if (labelLine) {
					return ReferenceLineIndex.EVENT_LABEL;
				}
				return ReferenceLineIndex.EVENT;
			case TimeReferenceType.SPAN:
				if (labelLine) {
					return ReferenceLineIndex.SPAN_LABEL;
				}
				return ReferenceLineIndex.SPAN;
		}
	};

	getAxis = (chartOptions: Highcharts.Options): Highcharts.AxisOptions => {
		if (_.isArray(chartOptions.xAxis)) {
			return chartOptions.xAxis[0];
		} else {
			return chartOptions.xAxis as Highcharts.AxisOptions;
		}
	};
}
