import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { SamlIdentityProvider } from '@app/modules/system-administration/saml-settings/entities/saml-identity-provider';
import { SamlIdentityProviderApiService } from '@app/modules/system-administration/saml-settings/saml-identity-provider-api.service';
import { SamlIdentityProvidersListActions } from '@app/modules/system-administration/saml-settings/saml-identity-providers/miscellaneous/saml-identity-providers-list-actions.service';
import { SamlIdentityProvidersListMenu } from '@app/modules/system-administration/saml-settings/saml-identity-providers/miscellaneous/saml-identity-providers-list-menu.service';
import { SamlIdentityProviderDialogComponent, SamlIdenityProviderDialogInput } from '@app/modules/system-administration/saml-settings/saml-identity-provider-dialog/saml-identity-provider-dialog.component';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import MasterAccountSamlLink from '@app/modules/system-administration/master-account/entities/master-account-saml-link';
import { MasterAccountManageApi } from '@app/modules/system-administration/master-account/master-account-manage-api.service';

@Component({
	selector: 'saml-identity-providers',
	templateUrl: './saml-identity-providers.component.html',
	providers: [SamlIdentityProvidersListActions, SamlIdentityProvidersListMenu]
})

export class SamlIdentityProvidersComponent implements OnInit {
	readonly METADATA_FILE_CLASS: string = 'br-metadataFile';

	gridOptions: SlickgridOptions;
	gridType = GridTypes.IDENTITY_PROVIDERS;

	changedItems: any[];
	loading: Promise<any>;
	nameSearch: string;
	identityProviders: SamlIdentityProvider[];
	masterAccountSamlLink: MasterAccountSamlLink[];

	constructor(
		private identityProviderApi: SamlIdentityProviderApiService,
		private actionsService: SamlIdentityProvidersListActions,
		private contextMenuUtils: SamlIdentityProvidersListMenu,
		private cxDialogService: CxDialogService,
		private masterAccountManageApi: MasterAccountManageApi,
		private gridUtils: GridUtilsService,
		@Inject('contextMenuTree') private contextMenuTree: ContextMenuTree
	) {

	}

	ngOnInit(): void {
		this.gridOptions = {
			onClick: (event, row) => this.onItemClick(event, row)
		};

		this.loading = Promise.all([this.loadLinkedMasterAccounts(), this.loadIdentityProviders()]).then(() => {
			this.populateMasterAccountLinksField();
		});
		this.actionsService.onChange.subscribe(() => this.reloadIdentityProviders());
	}

	private loadLinkedMasterAccounts(): Promise<void> {
		return this.masterAccountManageApi.getExternalSamlEnabledMasterAccounts().then((links) => {
			this.masterAccountSamlLink = links;
		});
	}

	private onItemClick(event, object: SamlIdentityProvider): void {
		if (this.gridUtils.isNameClick(event)) {
			this.editIdentityProvider(object);
		} else if (this.gridUtils.isMenuClick(event)) {
			this.contextMenuTree.showObjectListMenu(event, object, this.contextMenuUtils.getContextMenu(object, this), 'identity-providers', 360);
		} else if (this.gridUtils.isToggleClick(event)) {
			this.actionsService.toggle(object);
		} else if (this.gridUtils.isColumnClick(event, this.METADATA_FILE_CLASS)) {
			this.actionsService.downloadMetadataFile(object);
		}
	}

	private loadIdentityProviders(): Promise<void> {
		return this.identityProviderApi.getIdentityProviders().then(identityProviders => {
			this.identityProviders = identityProviders;
			// We have to populate 'id' because SlickGrid expects this field in each object
			this.identityProviders.forEach((identityProvider) => identityProvider.id = identityProvider.entityId);
		});
	}

	private reloadIdentityProviders(): void {
		this.loading = this.loadIdentityProviders().then(() => {
			this.populateMasterAccountLinksField();
			this.refreshGrid();
		});
	}

	private populateMasterAccountLinksField(): void {
		this.identityProviders.forEach((identityProvider) => {
			let link = _.findWhere(this.masterAccountSamlLink, { aliasName: identityProvider.aliasName });
			if (link) {
				identityProvider.linkedMasterAccounts = link.linkedMasterAccounts;
			}
		});
	}

	private refreshGrid(): void {
		this.changedItems = this.identityProviders;
	}

	createIdentityProvider(): void {
		let dialogInput = {
			identityProvider: new SamlIdentityProvider(),
			allIdentityProviders: this.identityProviders
		} as SamlIdenityProviderDialogInput;

		this.cxDialogService.openDialog(SamlIdentityProviderDialogComponent, dialogInput, {size: ModalSize.MEDIUM}).result
			.then(identityProvider => {
				this.actionsService.create(identityProvider);
			}).catch(() => {});
	}

	editIdentityProvider(samlIdentityProvider: SamlIdentityProvider) {
		let dialogInput = {
			identityProvider: samlIdentityProvider,
			allIdentityProviders: this.identityProviders
		} as SamlIdenityProviderDialogInput;

		this.cxDialogService.openDialog(SamlIdentityProviderDialogComponent, dialogInput, {size: ModalSize.MEDIUM}).result
			.then(identityProvider => {
				this.actionsService.update(identityProvider);
			}).catch(() => {});
	}

}

app.directive('samlIdentityProviders', downgradeComponent({component: SamlIdentityProvidersComponent}));
