import * as _ from 'underscore';

import { Model } from '@cxstudio/reports/entities/model';
import { Security } from '@cxstudio/auth/security-service';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import { ScorecardViewData } from './entities/scorecard-view-data';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ScorecardPreviewResult } from '@cxstudio/projects/scorecards/entities/scorecard-preview-result';
import { ScorecardMetric } from '@cxstudio/projects/scorecards/entities/scorecard-metric';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { Caches } from '@cxstudio/common/caches';
import { ModelsService } from '@app/modules/project/model/models.service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { PromiseUtils } from '@app/util/promise-utils';
import { ModelUtils } from '@app/modules/project/model/model-utils';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';
import { ScorecardRebuttedDocument } from './entities/scorecard-rebutted-document';

const SCORECARDS_BASE_URL = 'rest/scorecards';

interface IScorecardPreviewRequestDTO {
	scoreCard: Scorecard;
	projectId: number;
	modelId: number;
	newScorecard: boolean;
	scoreCardId?: number;
	newSample: boolean;
	sampleSize: number;
}

export default class ScorecardsApiService {

	constructor(
		private contentProviderOptionsService: ContentProviderOptionsService,
		private modelsService: ModelsService,
		private security: Security,
		private $http: ng.IHttpService,
		private httpHandlers: HttpHandlers,
		private cachedHttpService: CachedHttpService
	) {
	}

	private getScorecardPath = (contentProviderId: number, accountId: number, scorecard?: Scorecard): string => {
		const suffix = scorecard ? `/scorecard/${scorecard.id}` : '';
		return `${SCORECARDS_BASE_URL}/cp/${contentProviderId}/account/${accountId}${suffix}`;
	};

	// ---------------------------------------------------------------------

	save = (contentProviderId: number, accountId: number, scorecard: Scorecard): ng.IPromise<Scorecard> => {
		const url: string = this.getScorecardPath(contentProviderId, accountId);
		return this.$http.post(url, scorecard)
			.then(this.httpHandlers.success, this.httpHandlers.error)
			.then(this.invalidateAllCaches);
	};

	updateActiveState = (contentProviderId: number, accountId: number, scorecard: Scorecard): ng.IPromise<Scorecard> => {
		const config: any = { local: true };
		const updateCases: boolean = false;

		return this.update(contentProviderId, accountId, scorecard, updateCases, config);
	};

	update = (contentProviderId: number, accountId: number, scorecard: Scorecard, updateCases: boolean = false,
		config?: any): ng.IPromise<Scorecard> => {
		const url: string = this.getScorecardPath(contentProviderId, accountId, scorecard);
		return this.$http.put(url, {scorecard, updateCases}, config)
			.then(this.httpHandlers.success, this.httpHandlers.error)
			.then(this.invalidateAllCaches);
	};

	remove = (contentProviderId: number, accountId: number, scorecard: Scorecard): ng.IPromise<number> => {
		const url: string = this.getScorecardPath(contentProviderId, accountId, scorecard);
		return this.$http.delete(url)
			.then(this.httpHandlers.success, this.httpHandlers.error)
			.then(this.invalidateAllCaches);
	};

	private invalidateAllCaches = <T> (response: T): T => {
		this.cachedHttpService.cache(Caches.METRICS).invalidate();
		this.cachedHttpService.cache(Caches.FILTERS).invalidate();
		this.cachedHttpService.cache(Caches.ATTRIBUTES).invalidate();
		return response;
	};

	getScorecards = (contentProviderId: number, accountId: number, projectId?: number, runAsUser?: string,
		cache = CacheOptions.NOT_CACHED): ng.IPromise<Scorecard[]> => {

		const parameters: any = {
			contentProviderId,
			accountId
		};

		if (projectId) {
			parameters.projectId = projectId;
		}

		if (runAsUser) {
			parameters.userEmail = runAsUser;
		}

		return this.cachedHttpService.cache(Caches.ATTRIBUTES)
			.post(SCORECARDS_BASE_URL, parameters, { cache }).then(this.httpHandlers.success, this.httpHandlers.error);
	};

	getProjectScorecards = (project: IProjectSelection, cache = CacheOptions.CACHED, runAsUser?: string): ng.IPromise<Scorecard[]> => {
		return this.getScorecards(project.contentProviderId, project.accountId, project.projectId, runAsUser, cache);
	};

	getActiveScorecards = (project: IProjectSelection, cache = CacheOptions.CACHED, runAsUser?: string): ng.IPromise<Scorecard[]> => {
		return this.getScorecards(project.contentProviderId, project.accountId, project.projectId, runAsUser, cache)
			.then(result => result.filter(scorecard => scorecard.active));
	};

	getScorecard = (contentProviderId: number, accountId: number, scorecardId: number): ng.IPromise<ScorecardViewData> => {
		const url: string = this.getScorecardPath(contentProviderId, accountId, {id: scorecardId});
		return this.$http.get(url).then(this.httpHandlers.success, this.httpHandlers.error);
	};

	getScorecardModels = (contentProviderId: number, accountId: number, projectId: number, cache = CacheOptions.NOT_CACHED,
		runAsUser?: string): ng.IPromise<Model[]> => {
		return this.contentProviderOptionsService.getScorecardModels(contentProviderId, accountId, projectId,
			runAsUser || this.security.getEmail(), cache);
	};

	getModelTree = (contentProviderId: number, accountId: number, projectId: number, modelId: number): ng.IPromise<Model> => {
		return PromiseUtils.old(this.modelsService.getModelTree(
			new ProjectIdentifier(contentProviderId, accountId, projectId), modelId))
			.then(modelTree => ModelUtils.populateNodesPath(modelTree));
	};

	getPreviewResult = (props: IProjectSelection, scorecard: Scorecard, newScorecard: boolean, newSample: boolean,
		sampleSize: number): ng.IPromise<ScorecardPreviewResult> => {
		const url: string = this.getScorecardPath(props.contentProviderId, props.accountId) + '/preview';
		let scorecardForRequest = _.pick(scorecard, 'threshold', 'scorecardTopics');
		let scorecardRequestDTO = {
			scoreCard: scorecardForRequest,
			projectId: props.projectId,
			modelId: scorecard.modelId,
			newScorecard,
			scoreCardId: scorecard.id,
			newSample,
			sampleSize
		} as IScorecardPreviewRequestDTO;
		return this.$http.post(url, scorecardRequestDTO).then(this.httpHandlers.success, this.httpHandlers.error);
	};

	getFullScorecardMetrics = (contentProviderId: number, accountId: number, projectId: number): ng.IPromise<ScorecardMetric[]> => {
		const parameters: any = {};

		if (projectId) {
			parameters.projectId = projectId;
		}

		const url: string = this.getScorecardPath(contentProviderId, accountId) + '/metrics/full';

		return this.cachedHttpService.cache(Caches.METRICS)
			.get(url, { params: parameters }).then(this.httpHandlers.success, this.httpHandlers.error);
	};

	rebutDocument = (contentProviderId: number, reduttedDocument: ScorecardRebuttedDocument, config?: any): ng.IPromise<void> => {
		const url: string = `${SCORECARDS_BASE_URL}/cp/${contentProviderId}/rebut`;

		return this.$http.put(url, reduttedDocument, config)
			.then(this.httpHandlers.success, this.httpHandlers.error);
	};
}

app.service('scorecardsApiService', ScorecardsApiService);
