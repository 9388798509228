import { Component, forwardRef, Input, Output, EventEmitter, OnInit,
	ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { TagsService } from '@app/modules/account-administration/properties/tags.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { TypeGuards } from '@app/util/typeguards.class';

@Component({
	selector: 'tags-input',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TagsInputComponent), multi: true},
	],
	template: `
		<div class="tags-input">
			<div class="tags tags-flex" [class.disabled]="disabled">
				<span class="tag-item" *ngFor="let tag of tags" [ngClass]="tagCustomClasses && tagCustomClasses[tag] || ''">
					<span *ngIf="!disabled"
						tabindex="0"
						class="remove-button cursor-pointer"
						role="button"
						(click)="removeTag(tag)"
						[attr.aria-label]="'common.remove'|i18n">
						&times;
					</span>
					<span class="tag">{{formatTag(tag)}}</span>
				</span>

				<span #newTagRef class="input" style="visibility: hidden; width: auto; white-space: pre; position: absolute;"></span>
				<input *ngIf="isInputAllowed()"
					class="input"
					[ngClass]="{'text-danger': invalidTag}"
					type="text"
					name="tags"
					[(ngModel)]="newTag"
					(ngModelChange)="changeHandler()"
					[disabled]="disabled"
					[ngbTypeahead]="tagSuggestions"
					(selectItem)="onTagSelection($event)"
					[resultTemplate]="itemTemplate"
					(focus)="focusHandler()"
					(blur)="blurHandler()"
					(keyup.enter)="addTagFromInput()"
					(keydown.backspace)="removeLastTag()"
					(input)="updateInputWidth($event.target.value)"
					[ngStyle]="{'width': inputWidth || 0, 'flex': inputWidth ? '' : 1}"/>
			</div>
		</div>
		<ng-template #itemTemplate let-result="result" let-term="term">
				<ngb-highlight class="suggestion-item" [result]="formatTag(result)" [term]="term"></ngb-highlight>
		</ng-template>
`})
export class TagsInputComponent<T> implements ControlValueAccessor, OnInit {
	@Input() addOnBlur: boolean;
	@Input() addOnEnter: boolean;
	@Input() loadOnFocus: boolean;
	@Input() maxTags: number;
	@Input() maxLength: number;
	@Input() minLength: number;
	@Input() tagCustomClasses: {[key: string]: string};
	@Input() maxResultsToShow: number;
	@Input() customValidation: (tag: string) => boolean;
	@Input() tagsSource: (search: string) => Promise<string[]>;
	@Input() formatTag: (tag: T) => string;
	@Input() matchFn: (tag, value) => boolean = (tag, value) => _.isEqual(tag, value);
	@Output() onTagAdded = new EventEmitter<T>();
	@Output() onTagRemoved = new EventEmitter<T>();
	@ViewChild('newTagRef', { read: ElementRef, static: true }) newTagRef: ElementRef;

	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	private onTouchedCallback: () => void = _.noop;
	private onChangeCallback: (val: T[]) => void = _.noop;

	disabled: boolean;
	tags: T[];
	newTag: string;
	inputWidth: string;

	invalidTag: boolean;

	focus$ = new Subject<string>();

	constructor(
		private ref: ChangeDetectorRef,
		private tagsService: TagsService
	) {}

	ngOnInit(): void {
		this.tags = this.tags || [];
		this.formatTag = this.formatTag || ((tag: T) => String(tag));
		this.matchFn = this.matchFn || ((tag, value) => _.isEqual(tag, value));
	}

	// This logic only applies for strings
	addTagFromInput(): void {
		if (!this.addOnEnter) return;
		if (_.isUndefined(this.newTag)) return;
		
		const trimmedValue = this.newTag.trim();
		if (trimmedValue === '') return;

		if (!this.isTagValid(trimmedValue)) {
			this.invalidTag = true;
			return;
		}

		this.addTag(trimmedValue as unknown as T);
		this.clearInput();
	}

	updateInputWidth(value: string): void {
		this.newTagRef.nativeElement.innerText = value;
		let width = this.newTagRef.nativeElement.offsetWidth;
		this.inputWidth = width && value ? width + 'px' : '';
	}

	private clearInput(): void {
		this.newTag = '';
		
		this.updateInputWidth('');
	}

	private addTag(tag: T): void {
		this.tags.push(tag);
		this.onTagAdded.emit(tag);
		this.onChange();
	}

	private isTagValid(tag: string): boolean {
		if (this.maxLength && tag.length > this.maxLength) {
			return false;
		}
		if (this.minLength && tag.length <= this.minLength) {
			return false;
		}
		if (this.tags.some(existingTag => this.matchFn(existingTag, tag))) {
			return false;
		}

		if (this.customValidation) {
			return this.customValidation(tag);
		}

		return true;
	}

	removeTag(tagToRemove: T): void {
		this.tags = this.tags.filter(tag => tagToRemove !== tag);
		this.onTagRemoved.emit(tagToRemove);
		this.onChange();
	}

	removeLastTag(): void {
		if (!this.tags.length) return;
		if (this.newTag === '') {
			this.removeTag(this.tags[this.tags.length - 1]);
		}
	}

	focusHandler(): void {
		if (this.loadOnFocus) {
			if (TypeGuards.isString(this.newTag) )
				this.focus$.next(this.newTag);
			else 
				this.focus$.next();
		}
	}

	blurHandler(): void {
		if (this.addOnBlur) {
			this.addTagFromInput();
		}
	}

	changeHandler(): void {
		this.invalidTag = false;
	}

	isInputAllowed(): boolean {
		if (this.maxTags) {
			return this.tags.length < this.maxTags;
		}
		return true;
	}

	private onChange(): void {
		this.onChangeCallback(this.tags as T[]);
		this.onTouchedCallback();
	}


	tagSuggestions = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focus$;

		return merge(debouncedText$, inputFocus$).pipe(
			switchMap(term =>
				this.tagsSource
					? this.tagsSource(term).then(
						suggestions => {
							this.maxResultsToShow = (this.maxResultsToShow > 0) ?
								this.maxResultsToShow :
								suggestions.length;

							return suggestions
								.filter(value => !this.tags.some(tag => { 
									return this.matchFn(tag, value);}))
								.slice(0, this.maxResultsToShow);
						})
					: Promise.resolve([])
			)
		);
	};

	onTagSelection = (event: NgbTypeaheadSelectItemEvent): void => {
		event.preventDefault();
		this.clearInput();
		this.addTag(event.item);
	};

	//ControlValueAccessor
	writeValue(value: any): void {
		if (value && value !== this.tags) {
			this.tags = value;
			this.ref.markForCheck();
		}
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
		this.ref.markForCheck();
	}
}
// Angularjs needs to use <ng-tags-input> because <tags-input> is already in use by a third party component. When that is removed, we should standardize on tags-input
app.directive('ngTagsInput', downgradeComponent({component: TagsInputComponent}));
