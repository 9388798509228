import { Injectable } from '@angular/core';
import { CustomMathMetrics } from '../adapter/custom-math-metrics';
import { TokenSuggestion } from './custom-math-suggestion.class';
import { MathMetricTokenType } from './math-metric-token';

export interface EditorState {
	text: string;
	appliedMetrics: CustomMathMetrics;
	caretPosition: number;
}

@Injectable({
	providedIn: 'root'
})
export class CustomMathMemoryService {
	storedStates: EditorState[] = [];
	undoneStates: EditorState[] = [];

	setInitialEditorState(text: string, appliedMetrics: CustomMathMetrics): void {
		this.emptyLists();
		this.storedStates.push({text, appliedMetrics, caretPosition: text.length});
	}

	private emptyLists(): void {
		this.storedStates = [];
		this.undoneStates = [];
	}

	remember(text: string, appliedMetrics: CustomMathMetrics, caretPosition: number): void {
		let lastStoredState: EditorState = this.storedStates[this.storedStates.length - 1];
		if (text !== lastStoredState.text || appliedMetrics !== lastStoredState.appliedMetrics) {
			this.storedStates.push({text, appliedMetrics, caretPosition});
			this.undoneStates = [];
		}
	}

	undo(): EditorState {
		let currentState = this.storedStates.pop();
		this.undoneStates.push(currentState);
		return this.storedStates[this.storedStates.length - 1];
	}

	// the first one is always initial state
	canUndo(): boolean {
		return this.storedStates.length > 1;
	}

	redo(): EditorState {
		let restoredState = this.undoneStates.pop();
		this.storedStates.push(restoredState);
		return restoredState;
	}

	canRedo(): boolean {
		return this.undoneStates.length > 0;
	}

	saveEditorStateOnSuggestionApply(skipEditorStateRemembering: boolean, suggestion: TokenSuggestion, 
		appliedMetricsBeforeUpdate: CustomMathMetrics, appliedMetricsAfterUpdate: CustomMathMetrics): boolean {
		let saveEditorState: boolean = !skipEditorStateRemembering;
		let tokenType: MathMetricTokenType = suggestion.tokenType;
		if (tokenType === MathMetricTokenType.METRIC || tokenType === MathMetricTokenType.SCORECARD_METRIC) {
			let appliedMetricsChanged: boolean = !_.isEqual(appliedMetricsBeforeUpdate, appliedMetricsAfterUpdate);
			saveEditorState = saveEditorState && appliedMetricsChanged;
		}
		return saveEditorState;
	}

}