import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { ArchiveApi } from '@app/modules/archive/archive-page/archive-api.service';
import { ArchiveItem } from '@app/modules/archive/archive-page/archive-item';
import { ArchiveListActions } from '@app/modules/archive/archive-page/archive-list-actions.service';
import { ArchiveListMenu } from '@app/modules/archive/archive-page/archive-list-menu.service';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { Subscription } from 'rxjs';

export interface ListArchiveItem extends ArchiveItem {
	localizedAssetType: string;
	level: number;
}

@Component({
	selector: 'archive-page',
	templateUrl: './archive-page.component.html',
	providers: [ArchiveListActions, ArchiveListMenu]
})
export class ArchivePageComponent implements OnInit, OnDestroy {
	gridOptions: SlickgridOptions;
	gridType = GridTypes.ARCHIVE;
	gridNameField = 'assetName';

	loading: Promise<any>;
	archiveItems: ListArchiveItem[];
	changedItems: any[];

	private changeSubscription: Subscription;

	constructor(
		private locale: CxLocaleService,
		private archiveApi: ArchiveApi,
		private contextMenu: ArchiveListMenu,
		private actionsService: ArchiveListActions,
		private gridUtils: GridUtilsService,
		@Inject('contextMenuTree') private contextMenuTree: ContextMenuTree
	) { }

	ngOnInit(): void {
		this.gridOptions = {
			onClick: this.onRowClick
		};
		this.changeSubscription = this.actionsService.change$.subscribe(() => this.reload());
		this.reload();
	}

	ngOnDestroy(): void {
		this.changeSubscription.unsubscribe();
	}

	private reload(): void {
		this.loading = this.archiveApi.getArchive().then(result => {
			this.archiveItems = result.map(item => {
				let listItem: ListArchiveItem = {
					...item,
					level: 0,
					localizedAssetType: this.getLocalizedType(item.assetType)
				};
				return listItem;
			});
			this.refreshGrid();
		});
	}

	private getLocalizedType = (type: string): string => {
		let langKey = this.getObjectTypeLangKey(type);
		return this.locale.getString(langKey);
	};

	private getObjectTypeLangKey = (assetType: string): string | undefined => {
		switch (assetType) {
			case DashboardType.DASHBOARD:
				return 'object.dashboard';
			case DashboardType.BOOK:
				return 'object.book';
			case FilterTypes.CXSTUDIO:
				return 'object.filter';
			case 'metricStudio':
				return 'object.metric';
		}
	};

	private refreshGrid(): void {
		this.changedItems = this.archiveItems;
	}

	private onRowClick = (event, object: ArchiveItem): void => {
		if (this.gridUtils.isMenuClick(event)) {
			let objectMenu = this.contextMenu.getContextMenu(object);
			this.contextMenuTree.showObjectListMenu(event, object, objectMenu, 'archive', 360);
		}
	};
}

app.directive('archivePage', downgradeComponent({component: ArchivePageComponent}));
