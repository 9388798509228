import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { WidgetApiService } from '@app/modules/dashboard-edit/widget-api.service';
import { DashboardCreationOriginDetails } from '@app/modules/dashboard/dashboard-creation-origin-details';
import { HomePageDashboard } from '@app/modules/home-page/home-page-common/entities/home-page-dashboard';
import { HomePageWidgetConstants } from '@app/modules/home-page/home-page-common/home-page-widget-constants';
import { HomePageApiDashboard, HomePageApiService } from '@app/modules/home-page/home-page-management/home-page-api.service';
import { KeyboardSelector } from '@app/shared/util/keyboard-selector.enum';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { PromiseUtils } from '@app/util/promise-utils';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';
import { Security } from '@cxstudio/auth/security-service';
import { DropdownOrientationUtils } from '@cxstudio/common/dropdown-orientation-utils.service';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import WidgetService from '@cxstudio/services/widget-service';

@Component({
	selector: 'copy-submenu',
	templateUrl: './copy-submenu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})


export class CopySubmenuComponent {
	@Input() dashboard?: Dashboard;
	@Input() dashboardHistory?: IDashboardHistoryInstance;
	@Input() label: string;
	@Input() newDashboardName: string;
	@Input() menuToRightOnly: boolean;
	@Input() disabled: boolean;

	@Input() originDetails: DashboardCreationOriginDetails;

	/**
	 * @param Widgets provides a static list of widgets
	 */
	@Input() widgets: Widget[];

	/**
	 * @param getWidgets callback to provide a dynamic list of widgets
	 */
	@Input() getWidgets: (...args) => Widget[];

	@Output() targetSelected = new EventEmitter<void>();

	@ViewChild('submenu', {static: false}) submenu: ElementRef;

	readonly BASE_LIMIT: number = 20; // max 20 items shown
	currentLimit = this.BASE_LIMIT;
	isOpened: boolean = undefined; // for lazy loading
	searchFilter: string = '';
	dashboards: Dashboard[] = undefined;
	menuToLeft: boolean = false;
	hasEditDashboards: boolean;

	constructor(
		@Inject('widgetService') private widgetService: WidgetService,
		private toastService: ToastService,
		private widgetApiService: WidgetApiService,
		private locale: CxLocaleService,
		private homePageApiService: HomePageApiService,
		@Inject('dropdownOrientationUtils') private dropdownOrientationUtils: DropdownOrientationUtils,
		@Inject('security') private security: Security,
		@Inject('dashboardService') private dashboardService: DashboardService,
	) {}

	showSubmenu = (event: Event): void => {
		if (this.disabled)
			return;

		this.isOpened = true; // manually show/hide menu to support hiding delay
		if (!this.dashboards) {
			this.dashboards = this.dashboardService.getEditableDashboards(this.dashboard);
			this.hasEditDashboards = !_.isEmpty(this.dashboards);
		}

		if (!this.menuToRightOnly) {
			let eventLocation = Object.assign({}, event) as unknown as JQuery.Event;
			// keyboard events need a location attached to them to open menu in correct direction
			if (!eventLocation.clientX) {
				eventLocation.clientX = this.submenu.nativeElement.getBoundingClientRect().x;
			}
			let availableSpace = this.dropdownOrientationUtils.getAvailableSpace(eventLocation);
			// show to the left in 4th quarter of the screen, without "smart" calculation of available space
			this.menuToLeft = (availableSpace.right < window.innerWidth / 4);
		}
	};

	canCreate = (): boolean => this.security.has('create_dashboard');

	getFilteredDashboards(): Dashboard[] {
		return this.dashboards.filter(this.filterDashboards);
	}

	filterDashboards = (option): boolean => {
		if (option === null || !this.searchFilter)
			return true;
		return option.name && option.name.toLowerCase().contains(this.searchFilter.toLowerCase());
	};

	performCopyToNew = (): void => {
		this.performCopyInternal();
	};

	performCopy = (dashboard: Dashboard): void => {
		this.performCopyInternal(dashboard);
	};

	private performCopyInternal = (targetDashboard?: Dashboard): void => {
		this.doTargetSelectedCallback();
		let copyWidgets: Widget[] = this.widgets ?
			this.widgets :
			this.getWidgets(targetDashboard);

		let preserveIds = this.isHomePageDashboard(this.dashboard);
		this.widgetService.copyWidgets(copyWidgets, true, preserveIds).then((copies) => {
			this.getCopyApiPromise(targetDashboard, copies).then((name: string) => {
				this.toastService.addToast(this.getMessage(name, copyWidgets.length), AlertLevel.CONFIRM);
			});
		});
	};

	private getCopyApiPromise = (targetDashboard: Dashboard, copies: Widget[]): Promise<string> => {
		if (this.isHomePageDashboard(this.dashboard)) {
			let widget = copies[0]; //only one widget allowed
			let homePageId = this.dashboard.homePageId;
			let homePageDashboard = {
				dashboardId: targetDashboard?.id,
				name: targetDashboard?.name || this.getUniqueName(),
				widgetName: widget.displayName,
				widgetDescription: widget.description,
				query: this.dashboardHistory?.getTextFilter(),
			} as HomePageApiDashboard;
			return this.homePageApiService.copyWidgetToDashboard(homePageId, widget.id, homePageDashboard).then(response => {
				if (!targetDashboard) {
					this.dashboardService.processCreatedDashboard(response);
				}
				return response.name;
			});
		} else if (!targetDashboard) {
			return PromiseUtils.wrap(
				this.dashboardService.createPredefinedDashboard(copies, this.newDashboardName, this.dashboard, this.originDetails)
					.then(response => {
						return response.name;
					}));
		} else {
			return this.widgetApiService.copyWidgets(targetDashboard.id, copies).then(() => {
				return targetDashboard.name;
			});
		}
	};

	private isHomePageDashboard(sourceDashboard: Dashboard): sourceDashboard is HomePageDashboard  {
		return sourceDashboard?.id === HomePageWidgetConstants.HOME_PAGE_DASHBOARD_ID;
	}

	private getUniqueName = () => {
		return this.newDashboardName
			|| this.dashboardService.getUniqueDashboardName(this.locale.getString('dashboard.dashboardName'));
	};

	checkKeydown(event: KeyboardEvent): void {
		if (KeyboardUtils.isEventKey(event, Key.RIGHT)) {
			event.stopPropagation();
			this.showSubmenu(event);
		}

		if (KeyboardUtils.isEventKey(event, Key.LEFT)) {
			this.toggleSubmenu(event, false);
		}
	}

	toggleSubmenu(event?: Event, forceState?: boolean): void {
		if (this.disabled)
			return;

		event.stopPropagation();
		if (forceState !== undefined) {
			this.isOpened = forceState;
		} else {
			this.isOpened = !this.isOpened;
		}

		if (this.isOpened) this.showSubmenu(event);
		if (!this.isOpened) {
			this.onCloseSubmenu();
		}
	}

	private onCloseSubmenu() {
		let target = this.submenu.nativeElement.querySelector(`a,input,[tabindex]:not(${KeyboardSelector.NEGATIVE_TAB_INDEX})`);
		target.focus();
	}

	private doTargetSelectedCallback(): void {
		this.isOpened = false;
		this.targetSelected.emit();
	}

	private getMessage(dashboardName: string, count: number): string {
		let message: string = (count === 1)
			? this.locale.getString('widget.copySuccess', { dashboardName })
			: this.locale.getString('widget.copiesSuccess', { dashboardName, count });
		return message;
	}
}

app.directive('copySubmenu', downgradeComponent({ component: CopySubmenuComponent }));
