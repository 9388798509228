import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { RequestAccessData } from '@app/modules/access-management/entities/request-access-data';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { CHANGE_MA_STATUS } from '@cxstudio/common/url-service.service';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { GrantAccessData } from '../entities/grant-access-data';
import RequestAccessMessageRequest from '@app/modules/access-management/services/requests/request-access-message-request';



@Injectable({
	providedIn: 'root'
})
export class SharingRequestApi {

	readonly BASE_URL = 'rest/sharing-request';
	constructor(
		@Inject('$http') private $http: ng.IHttpService,
		@Inject('httpHandlers') private httpHandlers: HttpHandlers,
		@Inject('redirectService') private redirectService: RedirectService
	) {}

	getRequestAccessData = (objectType: string, objectId: number): Promise<boolean> => {
		return this.processSharingResponse(
			this.$http.get(`${this.BASE_URL}/${objectType}/${objectId}`,
				{local: true} as ng.IRequestShortcutConfig));
	};

	sendSharingRequest = (objectType: string, objectId: number, request: RequestAccessMessageRequest): Promise<void> => {
		return Promise.resolve(this.$http.post(`${this.BASE_URL}/${objectType}/${objectId}`, request)
			.then(this.httpHandlers.success));
	};

	getGrantAccessData = (objectType: string, objectId: number, userId: number): Promise<GrantAccessData> => {
		return this.processSharingResponse(
			this.$http.get(`${this.BASE_URL}/${objectType}/${objectId}/user/${userId}`,
				{local: true} as ng.IRequestShortcutConfig));
	};

	private processSharingResponse(httpPromise: ng.IHttpPromise<RequestAccessData>): Promise<any> {
		return Promise.resolve(httpPromise.then(this.httpHandlers.success, (errorResponse: ng.IHttpPromiseCallbackArg<any>) => {
			if (errorResponse.status === CHANGE_MA_STATUS) {
				this.redirectService.saveCurrentMA({accountId: errorResponse.data});
				return;
			} else {
				return Promise.reject(errorResponse);
			}
		}));
	}

}

app.service('sharingRequestApi', downgradeInjectable(SharingRequestApi));
