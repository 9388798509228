
import { DocViewPreferences } from '@app/modules/document-explorer/preferences/doc-view-preferences.class';
import { IReportModel } from '@app/modules/project/model/report-model';
import { Security } from '@cxstudio/auth/security-service';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import { DocumentTranslationService } from '@app/modules/document-explorer/translation/document-translation.service';
import { PreviewDocument } from '@cxstudio/reports/document-explorer/preview-document';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { DateFormatters } from '@app/modules/document-explorer/date-label/date-label.component';
import { KeyboardUtils, Key, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import { DocumentPreviewSelector } from '@app/shared/util/document-preview-selector';
import { KeyboardSelector } from '@app/shared/util/keyboard-selector.enum';
import { DocumentTypeUtils } from '@app/modules/document-explorer/document-type-utils.class';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import ng from 'angular';
import { AmplitudeDocumentSource } from '@app/modules/document-explorer/amplitude-document-source.enum';
import { AmplitudeEventUtils } from '@app/modules/analytics/amplitude/amplitude-event-utils';

export interface TopicRemoveEvent {
	sentenceId: number;
	topic: string;
}

export class PrimaryPane implements ng.IComponentController {
	typeChecker: DocumentTypeUtils;
	documentManager: IDocumentPreviewerControls;
	private preferences: DocViewPreferences; // empty in widget
	private predefinedMetrics: Metric[];
	isStatsEnabled: () => boolean;
	showExportButton: () => boolean;

	private projectUniqueId: string;
	isDocExplorer: boolean;
	auditMode: boolean;
	auditModeModels: IReportModel[];
	formatters: DateFormatters;

	constructor(
		private $scope: ISimpleScope,
		private documentTranslationService: DocumentTranslationService,
		private applicationThemeService: ApplicationThemeService,
		private security: Security,
		private masterAccountApiService: MasterAccountApiService,
	) {}

	$onInit = () => {
		this.typeChecker = DocumentTypeUtils;
		this.$scope.$watch(() => this.auditMode, () => this.reloadTuningSuggestionTopics());
	};

	getCurrentDocument = (): PreviewDocument => {
		return this.documentManager?.documents[this.documentManager.selectedDocument];
	};

	notTwitterOrConversation = (): boolean => {
		return this.getCurrentDocument()
			&& !DocumentTypeUtils.isTwitter(this.getCurrentDocument())
			&& !this.isConversation();
	};

	isConversation = (): boolean => {
		return DocumentTypeUtils.isConversation(this.getCurrentDocument());
	};

	getDocumentWrapStyle = (): any => {
		return {
			'overflow-y': this.isConversation() ? 'hidden' : 'auto'
		};
	};

	movePrimaryPane = (event: KeyboardEvent): void => {
		if (KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)) {
			let isFirstElement = this.checkFirstElement(event);
			if (isFirstElement) {
				this.returnSentencesPane(event);
			}
		}
	};

	checkFirstElement = (event: KeyboardEvent): boolean => {
		let isFirstElement = $(event.currentTarget).find(`:tabbable`).first()[0] === document.activeElement;
		return isFirstElement;
	};

	returnSentencesPane = (event: KeyboardEvent): void => {
		let sentencesContainer: EventTarget = event.target;
		event.preventDefault();
		$(sentencesContainer)
			.closest(DocumentPreviewSelector.WIDGET_DOCUMENT_PREVIEW_SELECTOR)
			.find(`${DocumentPreviewSelector.SENTENCES_PANE_SELECTOR} :tabbable`)
			.not(KeyboardSelector.NEGATIVE_TAB_INDEX).first().trigger('focus');
	};

	isSingleVerbatim = (): boolean => {
		return this.preferences && this.preferences.settings && this.preferences.settings.singleVerbatimMode;
	};

	getNavigationHelper = () => {
		return this.isSingleVerbatim() ? this.documentManager.switchVerbatimHelper : this.documentManager.switchDocumentHelper;
	};

	toggleTranslate = (): void => {
		let currentState = this.getCurrentDocument().translateActive;
		if (currentState === undefined)
			this.documentTranslationService.checkLimitReached();
		this.getCurrentDocument().translateActive = !currentState;

		if (this.getCurrentDocument().translateActive) {
			const document = this.documentManager.documents[this.documentManager.selectedDocument];
			const {source, documentId, documentType } = AmplitudeEventUtils.getBaseDocumentViewEvent(document, this.isDocExplorer);

			AmplitudeAnalyticsService.trackEvent(
				AmplitudeEvent.DOCEXPLORER_TRANSLATE_CLICK,
				{ documentId },
				{ source, documentType }
			);
		}
	};

	getUniquePrefix = (): string => {
		return ProjectIdentifier.toStringKey(this.documentManager.getProjectIdentifier()) + '_';
	};

	modelSearchFilter = (modelId: number): boolean => {
		if (!this.isDocExplorer) {
			return true;
		}
		return this.preferences?.settings?.modelSearch?.[this.projectUniqueId]?.[modelId] !== false;
	};

	getDocumentDate = (): string => {
		return this.getCurrentDocument()?.documentDate;
	};

	getActionColor = (): string => {
		return this.applicationThemeService.getActionColor(!this.isDocExplorer);
	};

	reloadTuningSuggestionTopics(): void {
		if (!this.auditMode
			|| !this.security.getCurrentMasterAccount().tuningSuggestionsSettings.topic
			|| !!this.auditModeModels)
			return;
		this.documentManager.state.loadingDocPanel = this.masterAccountApiService.getTuningSuggestionsAvailableModels(
			this.documentManager.getProjectIdentifier(),
			this.documentManager.getWorkspaceProject(),
			this.documentManager.getAssetParameters()).then(models => {
			this.auditModeModels = models;
		});
	}

	goNextDocument(): void {
		this.getNavigationHelper().next();
		this.trackDocumentChangeEvent('Next');
	}

	goPreviousDocument(): void {
		this.getNavigationHelper().previous();
		this.trackDocumentChangeEvent('Previous');
	}

	private trackDocumentChangeEvent(direction: string): void {
		const document = this.documentManager.documents[this.documentManager.selectedDocument];
		const {source, documentId, documentType } = AmplitudeEventUtils.getBaseDocumentViewEvent(document, this.isDocExplorer);

		AmplitudeAnalyticsService.trackEvent(
			AmplitudeEvent.DOCEXPLORER_DOCUMENT_CHANGE,
			{ documentId },
			{ direction, source, documentType }
		);
	}

	private getBaseAmplitudeEvent(): {source; documentId; documentType } {
		const currentDoc = this.documentManager.documents[this.documentManager.selectedDocument];
		const documentId = currentDoc.id;
		const documentType = DocumentTypeUtils.getAmplitudeDocumentType(currentDoc);
		const source = this.isDocExplorer ?
			AmplitudeDocumentSource.DOC_EXPLORER:
			AmplitudeDocumentSource.FEEDBACK_WIDGET;
		return { source, documentId, documentType };
	}

}

app.component('primaryPane', {
	bindings: {
		getSelectedVerbatim: '<getVerbatimFn',
		formatters: '<',
		permalink: '<',
		createDashboardFn: '<',
		documentManager: '<',
		voiceEnabled: '<',
		engagorEnabled: '<',
		preferences: '=',
		showSentiment: '<',
		showSentences: '<',
		filters: '<',
		noResults: '<',
		createDashboardCallback: '<',
		isDocExplorer: '<',
		projectUniqueId: '<',
		auditMode: '<',
		predefinedMetrics: '<',
		showExportData: '<',
		isStatsEnabled: '<',
	},
	controller: PrimaryPane,
	templateUrl: 'partials/document-explorer/panes/primary-pane.html'
});
