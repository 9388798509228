import ILocale from '@cxstudio/interfaces/locale-interface';
import { FilterCreatorService } from '@cxstudio/report-filters/filter-creator.service';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import { DateFilterService } from '@cxstudio/services/date-filter-service';
import { DashboardFilter } from './dashboard-filter';
import { DashboardFilterTypes } from './dashboard-filter-types-constant';
import { DashboardFilterValue } from './dashboard-filter-value';
import { DashboardFiltersService } from './dashboard-filters-service';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterMatchModeValue } from '@cxstudio/reports/entities/filter-match-mode-value';
import { FilterMatchModes } from '@cxstudio/report-filters/constants/filter-match-modes.service';
import { ITreeSelection, TreeSelectionStrategy } from '@app/shared/components/tree-selection/tree-selection';
import { EnrichmentAttributesService } from '@cxstudio/reports/document-explorer/enrichment-attributes.service';
import { AttributeType } from '@app/modules/project/attribute/attribute-type';
import { FilterAttributeTypes } from '@cxstudio/report-filters/constants/filter-attribute-types.constant';

export class DashboardFilterLabelsService {
	readonly NO_LABEL: string = '';
	readonly VALUES_DELIMITER: string = ', ';
	readonly LINE_BREAK: string = '<br>';
	readonly ANY_NUMBER: string = '*';

	filterLabelMethods: {[key: string]: (filter: DashboardFilter, timezone?, forExport?: boolean) => string} = {};
	filterTooltipMethods: {[key: string]: (filter: DashboardFilter, timezone?, plainText?: boolean) => string} = {};

	getFilterLabel: (dashboardFilter: DashboardFilter, timezone?, forExport?: boolean) => string;
	getFilterTooltip: (dashboardFilter: DashboardFilter, timezone?, plainText?: boolean) => string;

	constructor(
		private dateFilterService: DateFilterService,
		private locale: ILocale,
		private dashboardFiltersService: DashboardFiltersService,
		private filterCreatorService: FilterCreatorService,
		private filterMatchModes: FilterMatchModes,
		private $rootScope: ng.IRootScopeService,
		private enrichmentAttributesService: EnrichmentAttributesService
	) {

		// Bar label methods
		this.filterLabelMethods[DashboardFilterTypes.METRIC] = (filter: DashboardFilter) => {
			return '['
					+ ((filter.selectedAttributeValue as DashboardFilterValue).min ?
						(filter.selectedAttributeValue as DashboardFilterValue).min : this.ANY_NUMBER)
					+ ' TO '
					+ ((filter.selectedAttributeValue as DashboardFilterValue).max ?
						(filter.selectedAttributeValue as DashboardFilterValue).max : this.ANY_NUMBER)
					+ ']';
		};

		this.filterLabelMethods[DashboardFilterTypes.TOPIC] = (filter: DashboardFilter) => {
			let maxTopicsToDisplay = 3;
			return this.getTopicFilterLabel(filter, maxTopicsToDisplay);
		};

		this.filterLabelMethods[DashboardFilterTypes.DATE_RANGE] = (filter: DashboardFilter, timezone): string => {
			return this.dateFilterService.getDateFilterLabel(filter.selectedAttributeValue as unknown as DateFilter, timezone);
		};

		this.filterLabelMethods[DashboardFilterTypes.TEXT] = (filter: DashboardFilter) => {
			return (filter.selectedAttributeValue as DashboardFilterValue)?.value || '';
		};

		this.filterLabelMethods[DashboardFilterTypes.ATTRIBUTE] = (filter: DashboardFilter, timezone, forExport: boolean) => {
			return forExport ? this.getAttributeMultivalueExportLabel(filter) : this.getAttributeMultivalueLabel(filter);
		};

		this.filterLabelMethods.fallBack = this.getMultivalueLabel;

		// Tooltip label methods
		this.filterTooltipMethods[DashboardFilterTypes.METRIC] = this.filterLabelMethods[DashboardFilterTypes.METRIC];

		this.filterTooltipMethods[DashboardFilterTypes.DATE_RANGE] = this.filterLabelMethods[DashboardFilterTypes.DATE_RANGE];

		this.filterTooltipMethods[DashboardFilterTypes.TEXT] = this.filterLabelMethods[DashboardFilterTypes.TEXT];

		this.filterTooltipMethods[DashboardFilterTypes.TOPIC] = (filter: DashboardFilter) => {
			return this.wrapTooltip(this.getTopicFilterLabel(filter, undefined, this.LINE_BREAK));
		};

		this.filterTooltipMethods[DashboardFilterTypes.ATTRIBUTE] = this.getAttributeTooltipLabel;

		this.filterTooltipMethods.fallBack = this.getMultivalueTooltip;

		this.getFilterLabel = this.getFilterLabelGenerator(this.filterLabelMethods);
		this.getFilterTooltip = this.getFilterLabelGenerator(this.filterTooltipMethods);
	}

	// return a function used for generating labels with the provided generator
	private getFilterLabelGenerator = (labelMethods): (filter: DashboardFilter, timezone?, plainText?: boolean) => string => {
		return (dashboardFilter: DashboardFilter, timezone = undefined, plainText: boolean = false): string => {
			if (dashboardFilter.isOrgHierarchy) {
				return this.NO_LABEL;
			}

			if (dashboardFilter.isDrillToDashboardFilter) {
				return (dashboardFilter as any).label;
			}

			let label;
			if (dashboardFilter.selectedAttribute?.existMatch) {
				label = labelMethods.fallBack(dashboardFilter, timezone, plainText);
			}

			if (this.dashboardFiltersService.hasValue(dashboardFilter) || dashboardFilter.selectedAttribute?.existMatch) {
				let filterType = this.dashboardFiltersService.getFilterType(dashboardFilter.selectedAttribute);

				if (labelMethods[filterType]) {
					label = labelMethods[filterType](dashboardFilter, timezone, plainText);
				} else {
					if (_.isArray(dashboardFilter.multiValues)
							&& !isEmpty(dashboardFilter.multiValues)) {
						label = labelMethods.fallBack(dashboardFilter, timezone, plainText);
					}
				}
			}

			label = label || '';
			return label;
		};
	};

	private getTopicFilterLabel = (filter: DashboardFilter, maxToDisplay: number, joinString?: string): string => {
		let treeSelection = filter.selectedAttributeValue as unknown as ITreeSelection;
		let strategy = treeSelection.strategy;
		switch (strategy) {
			case TreeSelectionStrategy.EVERYTHING:
				return filter.selectedAttribute.displayName;
			case TreeSelectionStrategy.SUBSET:
				return this.generateTopicFilterSubsetLabel(treeSelection.nodes, maxToDisplay, joinString);
			default:
				return this.NO_LABEL;
		}
	};

	generateTopicFilterSubsetLabel = (nodes, maxToDisplay: number, joinString: string = this.VALUES_DELIMITER) => {
		let numSelected = nodes.length;

		if (maxToDisplay && numSelected > maxToDisplay) {
			return this.locale.getString('filter.firstAndMoreCount', {
				first: nodes[0].path,
				moreCount: nodes.length - 1
			});
		}
		return nodes
			.map(node => node.path)
			.join(joinString);
	};

	private getMultivalueLabel = (filter: DashboardFilter): string => {
		let multivalue = filter.multiValues;
		let nestedSelected = this.dashboardFiltersService.getNestedSelectedChildren(multivalue);
		let length = nestedSelected.length;
		if (!length) {
			nestedSelected = multivalue;
		}
		// FIXME this logic is incorrect: either statement above or below is redundant
		if (length <= 0) return this.NO_LABEL;

		if (length === 1 && nestedSelected[0].existMatch) {
			return this.locale.getString(`reportFilters.${nestedSelected[0].name}`);
		}

		let itemsWithDisplayNames = nestedSelected.filter(item => item?.displayName);
		return this.getMultiValueSelectionsText(itemsWithDisplayNames, length, true);
	};

	private getAttributeMultivalueLabel = (filter: DashboardFilter): string => {
		return (filter.selectedAttribute.existMatch || this.dashboardFiltersService.isNumericAttribute(filter.selectedAttribute))
			? this.dashboardFiltersService.getFilterDisplayName(filter.selectedAttribute)
			: this.getMultivalueLabel(filter);
	};

	private getAttributeMultivalueExportLabel = (filter: DashboardFilter): string => {
		let genericFilter = this.dashboardFiltersService.buildDashboardFilterRule(filter);
		let matchModePrefix = genericFilter ? this.filterCreatorService.getAppliedMatchMode(genericFilter).displayName : '';

		if (filter.selectedAttribute.existMatch) {
			return `${matchModePrefix}`;
		}

		if (this.dashboardFiltersService.isNumericAttribute(filter.selectedAttribute)) {
			if (filter.genericFilterFormat?.type === FilterRuleType.numericOpenRange) {
				let filterValue = filter.genericFilterFormat.value;
				let matchModeText = filter.genericFilterFormat.matchMode === FilterMatchModeValue.IS ?
					'filter.attributeValueIsGreaterThanOrEqualPDF' :
					'filter.attributeValueIsLessThanOrEqualPDF';
				return this.locale.getString(matchModeText, { value: filterValue });
			}

			if (filter.genericFilterFormat?.type === FilterRuleType.numericRange) {
				let filterValue = this.locale.getString('filter.valueBetween', {
					from: filter.genericFilterFormat.from,
					to: filter.genericFilterFormat.to
				});

				return `${matchModePrefix} ${filterValue}`;
			}
		}

		return `${matchModePrefix} ${this.getMultivalueLabel(filter)}`;
	};

	private getAttributeTooltipLabel = (filter: DashboardFilter, timezone?, plainText?: boolean): string => {
		if (this.dashboardFiltersService.isNumericAttribute(filter.selectedAttribute)) {
			let genericFilter = this.dashboardFiltersService.buildDashboardFilterRule(filter);
			let matchModePrefix = genericFilter ? this.filterCreatorService.getAppliedMatchMode(genericFilter).displayName : '';

			if (filter.genericFilterFormat?.type === FilterRuleType.numericOpenRange) {
				let filterValue = filter.genericFilterFormat.value;
				return this.wrapTooltip(`${matchModePrefix} ${filterValue}`, plainText);
			}

			if (filter.genericFilterFormat?.type === FilterRuleType.numericRange) {
				let filterValue = this.locale.getString('filter.valueBetween', {
					from: filter.genericFilterFormat.from,
					to: filter.genericFilterFormat.to
				});

				return this.wrapTooltip(`${matchModePrefix} ${filterValue}`, plainText);
			}
		}

		return this.getMultivalueTooltip(filter, timezone, plainText);
	};

	private getMultivalueTooltip = (filter, timezone = undefined, plainText: boolean = false): string => {
		let multivalue = filter.multiValues;
		let nestedSelected = this.dashboardFiltersService.getNestedSelectedChildren(multivalue);
		let length = nestedSelected.length;
		if (!length) {
			nestedSelected = multivalue;
		}

		if (length <= 0 && !filter.selectedAttribute.existMatch) return this.NO_LABEL;

		let genericFilter = this.dashboardFiltersService.buildDashboardFilterRule(filter);
		let itemsWithDisplayNames = nestedSelected.filter(item => item?.displayName);
		let matchModePrefix = genericFilter ? this.filterCreatorService.getAppliedMatchMode(genericFilter).displayName : '';

		if (filter.selectedAttribute.existMatch || itemsWithDisplayNames[0].existMatch) return `${matchModePrefix}`;

		let selectionsText = this.getMultiValueSelectionsText(itemsWithDisplayNames, length, plainText);
		return this.wrapTooltip(`${matchModePrefix} ${selectionsText}`, plainText);
	};

	getMultiValueSelectionsText = (itemsWithDisplayNames: Array<{displayName: string}>,
		numOfSelections: number, compress: boolean): string => {
		const MAX_ATTRIBUTE_VALUES = 2;
		if (compress && numOfSelections > MAX_ATTRIBUTE_VALUES)
			return this.locale.getString('filter.firstAndMoreCount', {
				first: itemsWithDisplayNames[0].displayName,
				moreCount: numOfSelections - 1
			});

		return itemsWithDisplayNames
			.map(item => item.displayName)
			.join(compress ? this.VALUES_DELIMITER : this.LINE_BREAK);
	};

	private wrapTooltip = (tooltipContent: string, plainText: boolean = false): string => {
		return plainText ? tooltipContent : `<p class="text-left filter-tooltip">${tooltipContent}</p>`;
	};

	getExistLabel(matchMode: FilterMatchModeValue): string {
		return matchMode === FilterMatchModeValue.IS ?
			this.locale.getString('reportFilters.hasAnyValue') :
			this.locale.getString('reportFilters.hasNoValue');
	}

	/**
	 * Returns filter description in the format of "Attribute is/is not x, y"
	 * Uses same truncation style as getMultiValueSelectionsText
	 */
	getMultivalueAttributeLongText(filter: DashboardFilter, isPdf: boolean = false): string {
		let matchModeText = filter.selectedAttribute.matchMode === FilterMatchModeValue.IS ?
			'filter.attributeValueIs' :
			'filter.attributeValueIsNot';

		if (filter.selectedAttribute.settings && filter.selectedAttribute.type === FilterAttributeTypes.NUMBER) {
			filter.multiValues.forEach(filterValue => {
				filterValue.displayName =
					this.enrichmentAttributesService
						.formatAttributeValue(filterValue.name, AttributeType.NUMBER, filter.selectedAttribute.settings);
			});
		}

		return this.locale.getString(matchModeText,
			{
				attribute: isPdf ? '' : this.dashboardFiltersService.getFilterDisplayName(filter.selectedAttribute),
				values: this.getMultiValueSelectionsText(filter.multiValues, filter.multiValues.length, true)
			});
	}

	getRangeText(filter: DashboardFilter): string {
		let matchModeText = filter.genericFilterFormat.matchMode === FilterMatchModeValue.IS ?
			'filter.attributeValueIsBetween' :
			'filter.attributeValueIsNotBetween';
		return this.locale.getString(matchModeText, {
			attribute: this.dashboardFiltersService.getFilterDisplayName(filter.selectedAttribute)
		});
	}

	getOpenRangeText(filter: DashboardFilter): string {
		let matchModeText = filter.genericFilterFormat.matchMode === FilterMatchModeValue.IS ?
			'filter.attributeValueIsGreaterThanOrEqual' :
			'filter.attributeValueIsLessThanOrEqual';

		return this.locale.getString(matchModeText, {
			attribute: this.dashboardFiltersService.getFilterDisplayName(filter.selectedAttribute),
			value: filter.genericFilterFormat.value
		});
	}

	hasValue = (filter: DashboardFilter): boolean => {
		return this.dashboardFiltersService.hasValue(filter) || filter.selectedAttribute?.existMatch || filter.isDrillToDashboardFilter;
	};

	getExistMatchMode(filter: DashboardFilter): FilterMatchModeValue | undefined {
		if (filter.selectedAttribute?.existMatch)
			return filter.selectedAttribute.matchMode;

		if (!isEmpty(filter.multiValues) && filter.multiValues[0].existMatch)
			return (filter.multiValues[0] as any).matchMode;
	}

	getDashboardHeaderFilterLabel = (filter: DashboardFilter, isPdf: boolean = false): string => {
		if (!this.hasValue(filter)) return '';

		if (this.getExistMatchMode(filter)) {
			let matchMode = this.getExistLabel(this.getExistMatchMode(filter));
			return `${isPdf ? '' : this.dashboardFiltersService.getFilterDisplayName(filter.selectedAttribute)} ${matchMode}`;
		}

		if (this.filterMatchModes.isEqual(filter.genericFilterFormat) && !isEmpty(filter.multiValues)) {
			return this.getMultivalueAttributeLongText(filter, isPdf);
		}

		if (filter.isDrillToDashboardFilter) {
			return this.locale.getString('reportFilters.drilledFilter').toUpperCase()
				+ ': '
				+ this.getFilterLabel(filter);
		}

		if (this.dashboardFiltersService.isText(filter.selectedAttribute)) {
			if (isPdf) return filter.selectedAttributeValue.value;

			return this.locale.getString('filter.textContains', {value: filter.selectedAttributeValue.value});
		}

		// PDF range and open range labels just use getFilterLabel
		if (isPdf) return this.getFilterLabel(filter, this.$rootScope.projectTimezone, true);


		if (this.filterMatchModes.isRange(filter.genericFilterFormat)) {
			return this.getRangeText(filter);
		}

		if (this.filterMatchModes.isOpenRange(filter.genericFilterFormat)) {
			return this.getOpenRangeText(filter);
		}

		return this.getFilterLabel(filter);
	};
}


// service for determining display labels for dashboard filters in tooltips and compact filter bar
app.service('dashboardFilterLabels', DashboardFilterLabelsService);
