import * as _ from 'underscore';

import { ModalBindings } from '@cxstudio/common/modal-bindings';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ProjectAsset } from '@cxstudio/asset-management/project-asset';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { SharingService, IShareEntity } from '@cxstudio/sharing/sharing-service.service';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { ShareAction } from '@cxstudio/common/share-actions.constant';
import { Security } from '@cxstudio/auth/security-service';
import { ComponentPermission } from './component-permission';
import { AssetPermission } from '../../asset-permission';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { SharableComponentGroup } from './sharable-component-group';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import { MetricManagementApiService } from '@cxstudio/metrics/api/metric-management-api.service';
import { AssetEditPermissionAction } from '@cxstudio/asset-management/asset-edit-permission-action';
import { DashboardComponentsApiService } from '@app/modules/dashboard/services/assets/dashboard-components-api.service';


interface ComponentPermissionOption {
	id: ComponentPermission;
	name: string;
}

export interface ShareComponentsDialogParams {
	asset: ProjectAsset;
	projectSelection: IProjectSelection;
}

export abstract class ShareComponentsModalController<T extends ShareComponentsDialogParams> extends ModalBindings<T> {
	private loading: any;
	private loadingShares: boolean;

	private componentGroups: Array<SharableComponentGroup<any>>;
	private accessOptions: ComponentPermissionOption[];

	private sharedEntities: IShareEntity[] = [];
	private entityMap = {};

	constructor(
		protected $q: ng.IQService,
		protected locale: ILocale,
		protected dashboardComponentsApiService: DashboardComponentsApiService,
		protected sharingService: SharingService,
		protected security: Security,
		protected filterManagementApiService: FilterManagementApiService,
		protected metricApiService: MetricManagementApiService,
		protected globalNotificationService: GlobalNotificationService,
		protected dashboardApiService: DashboardApiService
	) {
		super();
	}

	protected init(): void {
		this.loading = {};

		this.initAccessOptions();

		this.initComponents();
		this.loadUsersAndGroups();
		this.loadPublicStatus();
	}

	protected abstract getTitle(): string;
	protected abstract getPrompt(): string;

	protected abstract loadComponentGroups(): ng.IPromise<Array<SharableComponentGroup<any>>>;
	protected abstract loadSharedEntities(): ng.IPromise<any>;
	protected abstract getPublicStatus(): SharingStatus;

	private initAccessOptions(): void {
		this.accessOptions = [{
			id: ComponentPermission.GRANT_ACCESS,
			name: this.locale.getString('dashboard.accessOptionGrant')
		}, {
			id: ComponentPermission.NO_CHANGES,
			name: this.locale.getString('dashboard.accessOptionNoChanges')
		}];
	}

	private initComponents(): void {
		this.loading.promise = this.loadComponentGroups().then(groups => this.componentGroups = groups);
	}

	protected buildProjectAsset(): ProjectAsset {
		return {
			assetId: this.resolve.asset.assetId,
			name: this.resolve.asset.name,
			type: this.resolve.asset.type,
			contentProviderId: this.resolve.projectSelection.contentProviderId,
			projectId: this.resolve.projectSelection.projectId
		};
	}

	save(): void {
		this.loading.promise = this.shareComponents().then(() => this.close());
	}

	private shareComponents(): ng.IPromise<any> {
		let recipients = this.sharedEntities.filter(entity => entity.permission !== AssetPermission.OWN);

		let sharingPromises = this.componentGroups
			.map(group => group.shareTo(recipients))
			.filter(promise => !!promise);

		if (sharingPromises.length > 0) {
			return this.$q.all(sharingPromises).then(() => {
				let notificationMessage = this.locale.getString('dashboard.componentsSharedSuccessfully');
				this.globalNotificationService.addSuccessNotification(notificationMessage);
			});
		} else {
			return this.$q.when();
		}
	}

	cancel(): void {
		this.dismiss({$value: 'cancel'});
	}

	getPreviouslySharedHeading(): string {
		return this.locale.getString('common.previouslyShared', { sharedNum: this.sharedEntities.length });
	}

	private loadUsersAndGroups(): void {
		this.loadingShares = true;

		this.loading.dataInitPromise = this.loadSharedEntities().then((dashboardEntities) => {
			return this.sharingService.loadUsersAndGroups(dashboardEntities,
				AssetEditPermissionAction.EDIT_DASHBOARD).then((usersAndGroups: any) => {
				usersAndGroups.users.forEach(user => this.addAvailableEntity(user));
				usersAndGroups.groups.forEach(group => this.addAvailableEntity(group));

				let sharingTableContent = this.sharingService.produceSharingTableContent(dashboardEntities, this.entityMap);
				sharingTableContent.users.forEach((user) => this.sharedEntities.push(user));
				sharingTableContent.groups.forEach((group) => this.sharedEntities.push(group));

				this.loadingShares = false;
			});
		});
	}

	private loadPublicStatus(): void {
		if (this.getPublicStatus() === SharingStatus.PUBLIC) {
			let publicItem = angular.copy(this.sharingService.PUBLIC);
			publicItem.permission = AssetPermission.VIEW;
			publicItem.action = ShareAction.ADD;

			if (!_.find(this.sharedEntities, publicItem)) {
				this.sharedEntities.push(publicItem);
			}
		}
	}

	private addAvailableEntity(entity): void {
		let entityKey = entity.type + entity._name;

		if (!this.entityMap[entityKey]) {
			this.entityMap[entityKey] = entity;
		}
	}

}
