import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
} from '@angular/core';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { LabelDisplayOption } from '@cxstudio/projects/scorecards/entities/label-display-options';

@Component({
	selector: 'scorecard-label',
	templateUrl: './scorecard-label.component.html',
	styles: [`
		.scorecard-pill {
			max-width: 20ch;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			border-radius: var(--line-height-1_75rem, 28px);
			line-height: var(--line-height-1_25rem, 20px);
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScorecardLabelComponent implements OnInit {
	@Input() label: string;
	@Input() bgColorHex: string;
	@Input() labelDisplayOption: LabelDisplayOption;
	@Input() score: string;

	ngOnInit(): void {
		this.validateBgColor();
	}

	getTextColor(): string {
		return ColorUtilsHelper.pickContrastTextColor(this.bgColorHex);
	}

	isLabelEnabled(): boolean {
		return !!this.label;
	}

	isScoreDisplayed(): boolean {
		return this.labelDisplayOption === LabelDisplayOption.LABEL_WITH_SCORE;
	}

	validateBgColor(): void {
		const hexPattern = /^#[0-9A-F]{6}$/i;
		if (!hexPattern.test(this.bgColorHex)) {
			this.bgColorHex = '#000000';
		}
	}
}
