import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { HTTPStatusCode } from '@app/core/http-status-code.enum';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { BookActionsService } from '@app/modules/book/book-actions.service';
import { BookProcessingService, BookRequestParams } from '@app/modules/book/book-processing.service';
import { BookTabsComponent } from '@app/modules/book/book-tabs/book-tabs.component';
import { BookViewTab, DashboardTabMetadata, EmbedTabMetadata } from '@app/modules/book/book-view-tab';
import { IActionsMenuItem } from '@app/modules/dashboard-actions/actions-menu/actions-menu.component';
import { FullscreenService } from '@app/modules/dashboard-actions/fullscreen-button/fullscreen.service';
import { DashboardRunHelperService } from '@app/modules/dashboard/dashboard-run-helper.service';
import DashboardRunType from '@app/modules/dashboard/dashboard-run-type.enum';
import { DashboardRunService } from '@app/modules/dashboard/dashboard-run.service';
import { MetadataPreloaderService } from '@app/modules/dashboard/metadata-preloader.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { QualtricsEmbedData } from '@app/modules/widget-visualizations/qualtrics/qualtrics-embed-data';
import { IRouteParams } from '@app/shared/providers/route-params-provider';
import { Security } from '@cxstudio/auth/security-service';
import { Book } from '@cxstudio/dashboards/entity/book';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { FullScreenAutoRefreshService } from '@app/modules/dashboard/services/full-screen-auto-refresh/full-screen-auto-refresh.service';
import { ContentProviderLimiter } from '@cxstudio/reports/content-provider-limiter.service';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { PromiseUtils } from '@app/util/promise-utils';
import { PageTitleUtil } from '@app/core/page-title-util.class';

@Component({
	selector: 'book-page',
	templateUrl: './book-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookPageComponent implements OnInit, OnDestroy {

	@ViewChild(BookTabsComponent) private bookTabsRef: BookTabsComponent;

	readonly ACTIVE_BOOK_LIMIT = 3;

	loadingPromise: ng.IPromise<any>;
	bookTabs: BookViewTab[];
	currentBook: Book;
	bookMenuItems: IActionsMenuItem<Book>[] = [];

	showFullscreen: boolean;

	private renderHistory: BookViewTab[] = [];

	constructor(
		private locale: CxLocaleService,
		private bookActions: BookActionsService,
		private dialogService: CxDialogService,
		private fullscreen: FullscreenService,
		private dashboardRunService: DashboardRunService,
		private dashboardRunHelperService: DashboardRunHelperService,
		private metadataPreloaderService: MetadataPreloaderService,
		private bookProcessing: BookProcessingService,
		@Inject('$routeParams') private $routeParams: IRouteParams,
		private fullscreenAutorefresh: FullScreenAutoRefreshService,
		@Inject('$location') private $location: ng.ILocationService,
		@Inject('dashboardApiService') private dashboardApiService: DashboardApiService,
		@Inject('$rootScope') private $rootScope: ng.IRootScopeService,
		@Inject('redirectService') private redirectService: RedirectService,
		@Inject('contentProviderLimiter') private contentProviderLimiter: ContentProviderLimiter,
		@Inject('security') private security: Security,
		@Inject('currentWidgets') private currentWidgets: ICurrentWidgets,
		private applicationThemeService: ApplicationThemeService

	) { }

	@HostBinding('class.experimental-ui') get classExperimentalUi() {
		return this.security.getContext()?.experimentalUI;
	}

	ngOnInit(): void {
		this.applicationThemeService.resetDashboardTheme();

		this.showFullscreen = this.fullscreen.isEnabled;

		this.fullscreenAutorefresh.setDashboardIdProvider(() => {
			let tab = this.getCurrentTab();
			if (tab && tab.isDashboardTab())
				return `${tab.metadata.dashboardId}_${this.bookTabs.indexOf(tab)}`;
			else return undefined;
		});
		this.initPageParams();
	}

	ngOnDestroy(): void {
		this.applicationThemeService.resetDashboardTheme();
		this.currentWidgets.clear();
		this.contentProviderLimiter.reset();
	}

	onTabKeydown(event: KeyboardEvent) {
		let isTargetContainer = event.target === $('.dashboard-view-container :focusable').first()[0];

		if (event.shiftKey && isTargetContainer) {
			event.preventDefault();
			event.stopPropagation();
			$('.dashboard-tab.selected #tab-title').trigger('focus');
		}
	}

	processEditButton(): void {
		this.bookActions.editBook(this.currentBook);
	}

	canEdit(): boolean {
		return this.bookActions.canEdit(this.currentBook);
	}

	isFullscreen(): boolean {
		return this.fullscreen.isFullscreen;
	}

	canShare(): boolean {
		return this.bookActions.canShare(this.currentBook);
	}

	shareBook(): void {
		this.bookActions.shareBook(this.currentBook);
	}

	onEditDashboard(dashboard: Dashboard) {
		this.bookActions.editDashboardFromBook(this.currentBook, dashboard);
	}

	private initPageParams(): void {
		if (!this.$routeParams.dashboardId) {
			return; // currently don't support
		}
		this.metadataPreloaderService.stopPreloading();
		this.loadDashboard(Number(this.$routeParams.dashboardId));
	}

	private getDashboardRunType(): DashboardRunType {
		// if not dashboard export
		if (!this.$rootScope.pdfToken) {
			return DashboardRunType.USER;
		}

		if (this.$rootScope.pdf) {
			if (this.$rootScope.scheduled) {
				return DashboardRunType.SCHEDULED_PDF;
			} else {
				return DashboardRunType.PDF;
			}
		}

		return DashboardRunType.DASHBOARD_PREVIEW;
	}

	private loadDashboard(bookId: number) {
		let tabId = this.isDashboardTab() ? this.bookActions.getInitialTab(true) : undefined;
		this.loadingPromise = this.dashboardApiService.getDashboard(bookId, true, this.getDashboardRunType(),
			tabId ? Number(tabId) : undefined, true)
			.then(response => {
				let book = response.data as Book;
				this.currentBook = book;
				if (book.type === DashboardType.DASHBOARD) {
					this.$location.path(`/home/${book.id}`);
					return;
				}
				if (book.type === DashboardType.BOOK) {
					PageTitleUtil.setTitle(this.locale.getString('pageTitle.object', { objectName: book.name }));
					return PromiseUtils.old(this.processBook(book));
				}
			}, (response) => {
				let status = response.status;
				if (status === HTTPStatusCode.MASTER_ACCOUNT_CHANGED) { // dashboard from another MA, need to reload
					this.redirectService.saveCurrentMA({accountId: response.data}, true);
				} else if (status === 403) {
					this.redirectService.goToRequestAccess(ObjectType.DASHBOARD, bookId);
				} else if (status === 404) {
					this.showErrorAndRedirect('dashboard.notExist');
				}
			});
	}

	private processBook(book: Book): Promise<void> {
		let params: BookRequestParams;
		if (this.$rootScope.preselectedTabId) {
			params = {
				tabId: this.$rootScope.preselectedTabId,
				tabVersionId: this.$rootScope.tabSnapshotId,
			};
		}
		return this.bookProcessing.getBookTabsData(book, params).then(bookWidgets => {
			this.bookTabs = bookWidgets;
			if (!_.isEmpty(bookWidgets)) {
				this.selectDefaultTab();
			}
		});
	}

	getBookMenu(): IActionsMenuItem<Book>[] {
		return this.bookActions.getBookMenuItems(this.currentBook, this.getCurrentTab());
	}

	private getCurrentTab(): BookViewTab {
		return _.findWhere(this.bookTabs, {active: true});
	}

	private selectDefaultTab() {
		let tabId = this.bookActions.getInitialTab();
		let currentTab = tabId && _.find(this.bookTabs, tab => tab.getTabId() === tabId);
		let dashboardRunInitialized = true;
		if (!tabId || !currentTab) {
			tabId = this.bookTabs[0].getTabId();
			currentTab = this.bookTabs[0];
			dashboardRunInitialized = false;
		}
		currentTab.active = true;

		if (currentTab.isDashboardTab()) {
			this.dashboardRunService.postProcessBookInit(currentTab.metadata.dashboardId,
				dashboardRunInitialized ? null : + this.$routeParams.dashboardId);
		}
		this.selectDashboardTab(currentTab, false);
	}

	private showErrorAndRedirect(msgKey: string): void {
		this.dialogService.warning(this.locale.getString('common.error'), this.locale.getString(msgKey)).result.then(() => {
			this.redirectService.goToDashboardList();
		});
	}

	selectDashboardTab(tab: BookViewTab, sendEvent = true): void {
		this.$location.search({tab: tab.getTabId(), type: tab.getTabType()});
		let alreadyLoaded = tab.rendered;
		this.renderTab(tab);
		if (sendEvent && !alreadyLoaded && tab.isDashboardTab()) {
			this.dashboardApiService.sendTabChangeEvent(tab, this.currentBook, this.$rootScope.pdfToken);
		}
		if (tab.isDashboardTab()) {
			this.dashboardRunHelperService.markFirstPassWidgets(tab.metadata.widgets);
			this.dashboardRunHelperService.populateDashboardRunTimestamp(tab.metadata.widgets);
			this.contentProviderLimiter.setHighPriorityGroup(tab && tab.metadata.dashboardId || undefined);
		}

		this.bookMenuItems = this.bookActions.getBookMenuItems(this.currentBook, this.getCurrentTab());
	}

	private renderTab(tab: BookViewTab): void {
		tab.rendered = true;

		if (_.contains(this.renderHistory, tab))
			this.renderHistory.remove(tab);
		this.renderHistory.push(tab);
		if (this.renderHistory.length > this.ACTIVE_BOOK_LIMIT) {
			let oldTab = this.renderHistory.shift();
			oldTab.rendered = false;
		}
	}

	private isDashboardTab(): boolean {
		return this.$location.search().type !== BookViewTab.URL_TYPE_XM;
	}

	updateEmbedData(tab: BookViewTab<EmbedTabMetadata>, embedData: QualtricsEmbedData): void {
		tab.metadata.embedData = embedData;
		this.bookTabsRef.refreshTabs();
	}

	isMissingSnapshot(metadata: DashboardTabMetadata): boolean {
		return metadata.frontline && !metadata.snapshotDate;
	}
}

app.directive('bookPage', downgradeComponent({component: BookPageComponent}));
