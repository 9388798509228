import * as moment from 'moment';
import * as cloneDeep from 'lodash.clonedeep';
import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { ObjectListFilter } from '@app/shared/components/filter/object-list-filter';
import { Caches } from '@cxstudio/common/caches';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { IAdminModel } from '@app/modules/project/model/admin-model';
import { AssetVisibilityUtils } from '@cxstudio/reports/utils/asset-visibility-utils.service';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';

@Injectable({
	providedIn: 'root'
})
export class AdminModelsApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private cxHttp: CxHttpService,
		private cxCachedHttpService: CxCachedHttpService,
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.MODELS);
	}

	getAdminModels = (project: IProjectSelection, filters: ObjectListFilter[],
		cache = CacheOptions.CACHED): AssetPromisePlatformProject<IAdminModel[]> => {
		let config = { cache };
		let data = this.createRequestParameters(filters);

		let url = this.getBasePath(project);
		return this.cachedHttp.post(url, { filters: data }, config)
			.then(AssetVisibilityUtils.getVisibleModels) as AssetPromisePlatformProject<IAdminModel[]>;
	};

	private createRequestParameters = (filters: ObjectListFilter[] = []): ObjectListFilter[] => {
		return _.chain(cloneDeep(filters))
			.filter((objectFilter: any) => {
				return objectFilter.field && objectFilter.condition;
			})
			.map((objectFilter: any) => {
				if (objectFilter.date) {
					objectFilter.date = moment(objectFilter.date).startOf('day').format();
				}

				if (objectFilter.fromDate) {
					objectFilter.fromDate = moment(objectFilter.fromDate).startOf('day').format();
				}

				if (objectFilter.toDate) {
					objectFilter.toDate = moment(objectFilter.toDate).endOf('day').format();
				}
				return objectFilter;
			})
			.value();
	};

	requestModelsReport = (
		project: IProjectSelection, withHidden: boolean, filters: ObjectListFilter[], modelOrder: number[]
	): AssetPromisePlatformProject<void> => {
		let url = this.getBasePath(project) + '/xls';
		let data = this.createRequestParameters(filters);
		return this.cxHttp.post(url, {filters: data, withHidden, modelOrder} ) as AssetPromisePlatformProject<void>;
	};

	private getBasePath = (project: IProjectSelection): string => {
		return `rest/manage/cp/${project.contentProviderId}/account/${project.accountId}/project/${project.projectId}/models`;
	};
}
