import * as _ from 'underscore';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import BulkUpdateValue from '@cxstudio/user-administration/bulk/bulk-update-value.enum';
import UserBulkUpdateGridDefinitionService from './user-bulk-update-grid-definition.service';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';

@Injectable({
	providedIn: 'root'
})
export default class UsersBulkDataAccessGridDefinition extends UserBulkUpdateGridDefinitionService {

	constructor(
		protected locale: CxLocaleService,
		@Inject('gridFormatterService') protected gridFormatterService
	) {
		super(locale, gridFormatterService);
	}

	init = (): Promise<IGridColumn[]> => {
		//radio group name need to unique
		let identifer = 'bulkUpdate_dataAccess_row_';
		let columns: IGridColumn[] = [
			{
				id: 'name',
				name: this.locale.getString('common.project'),
				field: 'displayName',
				sortable: true,
				minWidth: 280,
				width: 450,
				cssClass: 'cell-title',
				searchable: true,
				defaultSortColumn: SortDirection.ASC,
				formatter: this.gridFormatterService.getNameFormatter()
			}, {
				id: BulkUpdateValue.ADMIN,
				name: this.locale.getString('contentProvider.adminRole'),
				field: BulkUpdateValue.ADMIN,
				sortable: true,
				minWidth: 80,
				width: 150,
				cssClass: BulkUpdateValue.ADMIN,
				selectAll: false,
				resizable: false,
				formatter: this.getUpdateRadioFormatter(identifer),
				header: this.getHeader(false)
			}, {
				id: BulkUpdateValue.READ_ONLY,
				name: this.locale.getString('contentProvider.readOnlyRole'),
				field: BulkUpdateValue.READ_ONLY,
				sortable: true,
				minWidth: 80,
				width: 150,
				cssClass: BulkUpdateValue.READ_ONLY,
				selectAll: false,
				resizable: false,
				formatter: this.getUpdateRadioFormatter(identifer),
				header: this.getHeader(false)
			}, {
				id: BulkUpdateValue.NO_ACCESS,
				name: this.locale.getString('contentProvider.noAccessRole'),
				field: BulkUpdateValue.NO_ACCESS,
				sortable: true,
				minWidth: 80,
				width: 150,
				cssClass: BulkUpdateValue.NO_ACCESS,
				selectAll: false,
				resizable: false,
				formatter: this.getUpdateRadioFormatter(identifer),
				header: this.getHeader(false)
			}, {
				id: BulkUpdateValue.NONE,
				name: this.locale.getString('group.noChange'),
				field: BulkUpdateValue.NONE,
				sortable: true,
				minWidth: 80,
				width: 150,
				cssClass: BulkUpdateValue.NONE,
				selectAll: true,
				resizable: false,
				formatter: this.getUpdateRadioFormatter(identifer),
				header: this.getHeader(true)
			}];
		return Promise.resolve(columns);
	};

	protected getUpdateRadioFormatter = (identifer: string) => {
		return (row, cell, value, columnDef, dataContext) => {
			let checked = (columnDef.field === BulkUpdateValue.NONE && !dataContext.updateValue)
				|| dataContext.updateValue === columnDef.field;
			return this.gridFormatterService.getRadioFormatter(checked, false, identifer + row);
		};
	};
}

app.service('usersBulkDataAccessGridDefinition', UsersBulkDataAccessGridDefinition);
