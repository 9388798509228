import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Security } from '@cxstudio/auth/security-service';
import { CurrentMasterAccount } from '@cxstudio/auth/entities/current-master-account';
import { ScreeningSettings } from '../account-administration/properties/screening-settings-panel/screening-settings-panel.component';
import { CbDocument } from '@cxstudio/reports/entities/cb-document.class';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { CxLocaleService } from '@app/core';
import { ClarabridgeAttributeName } from '@cxstudio/reports/providers/cb/constants/clarabridge-attribute-name';
import { ConversationDocument } from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import * as cloneDeep from 'lodash.clonedeep';
import { ISupportDescriptors } from './SentenceContext';
import { GroupColorTypeService, PredefinedMetricColorTypeDefinition } from '@cxstudio/reports/utils/color/group-color-type.service';

@Injectable({
	providedIn: 'root'
})

export class ProfanityDisguiseService {
	maskMessage: string = this.locale.getString('widget.profanityMaskMessage');

	readonly PROFANITY_MESSAGE_PLACEHOLDER = 'PROFANITY_MESSAGE_PLACEHOLDER';
	readonly PROFANITY = 'profanity';

	constructor(
		private locale: CxLocaleService,
		@Inject('security') private security: Security,
		@Inject('GroupColorType') private readonly GroupColorType: GroupColorTypeService
	) {}

	isFeedbackScreeningEnabled = (): boolean => {
		let currentMasterAccount: CurrentMasterAccount = this.security.getCurrentMasterAccount();
		let screeningSettings: ScreeningSettings = currentMasterAccount.screeningSettings;
		return screeningSettings.feedbackScreeningEnabled && !this.security.getContext().allowViewProfanity;
	};

	maskProfanityInDocuments = (documents: CbDocument[] | ConversationDocument): void => {
		if (!this.isFeedbackScreeningEnabled() || !documents) {
			return;
		}

		_.forEach(documents, document => {
			this.maskProfanityInSentences(document.sentences);
		});
	};

	maskProfanityInSentences = (sentences: PreviewSentence[]): void => {
		if (!this.isFeedbackScreeningEnabled() || !sentences) {
			return;
		}

		_.forEach(sentences, this.maskProfanityInSingleSentence);
	};

	needToMaskSentence = (sentence: PreviewSentence): boolean => {
		return this.isFeedbackScreeningEnabled()
				&& this.hasProfanityAttribute(sentence);
	};

	hasProfanityAttribute = (sentence: PreviewSentence): boolean => {
		return sentence.attributes?.[ClarabridgeAttributeName.CB_PROFANITY]?.length > 0;
	};

	hasProfanityDescriptor = (item: ISupportDescriptors): boolean => {
		if (!item) {
			return false;
		}
		return item.descriptors?.contains(this.PROFANITY);
	};

	replaceProfanityMaskWithPlaceholder = (text: string): string => {
		return text.replaceAll(this.maskMessage, this.PROFANITY_MESSAGE_PLACEHOLDER);
	};

	replacePlaceholderWithProfanityMask = (message: string): string => {
		return message.replaceAll(this.PROFANITY_MESSAGE_PLACEHOLDER, this.maskMessage);
	};

	private maskProfanityInSingleSentence = (sentence: PreviewSentence): void => {
		if (!sentence) {
			return;
		}
		if (sentence.sentenceContext) {
			if (this.hasProfanityDescriptor(sentence.sentenceContext.next)) {
				sentence.sentenceContext.next.text = this.maskMessage;
			}
			if (this.hasProfanityDescriptor(sentence.sentenceContext.previous)) {
				sentence.sentenceContext.previous.text = this.maskMessage;
			}
		}
		if (this.hasProfanityDescriptor(sentence)) {
			sentence.text = this.maskMessage;
			sentence.chunks = [{
				text: this.maskMessage
			}];
		}
	};

	getProfanityInstancesBySentiment = (predefinedMetrics, document): Array<{value: string; sentences: number[]}> => {
		let sentimentMetric = _.findWhere(predefinedMetrics, {name: PredefinedMetricConstants.SENTIMENT});
		sentimentMetric = cloneDeep(sentimentMetric);
		sentimentMetric.name = 'dScore'; // sentence field for sentiment value

		let sentimentNameFn = (this.GroupColorType.getAllMetricColorTypes().SENTIMENT_3 as PredefinedMetricColorTypeDefinition)
			.getCategoryFunction(sentimentMetric, true);

		return _.chain(document.sentences || [])
			.filter((s: any) => s.attributes && s.attributes[ClarabridgeAttributeName.CB_PROFANITY])
			.map((s: any) => {
				let sentimentLevel = sentimentNameFn(s).name;
				s.attributes[ClarabridgeAttributeName.CB_PROFANITY].push(sentimentLevel);
				return {id: s.id, sentiment: sentimentLevel};
			})
			.groupBy(s => s.sentiment)
			.mapObject((sentimentGroup, k) => {
				return { value: k, sentences: _.map(sentimentGroup, s => s.id)};
			})
			.values()
			.value();
	};
}

app.service('profanityDisguiseService', downgradeInjectable(ProfanityDisguiseService));
