import { Injectable } from '@angular/core';
import { CustomMathExpressionAdapter} from '@app/modules/metric/definition/custom-math/adapter/custom-math-expression-adapter';
import { FormulaSegment, TextToken, TextTokenType  } from '@app/modules/metric/definition/custom-math/adapter/formula-segment';
import { CustomMathAdapterUtils } from '@app/modules/metric/definition/custom-math/adapter/custom-math-adapter-utils.class';
import { CustomMathAssets } from '@app/modules/metric/definition/custom-math/adapter/custom-math-assets';
import { CustomMathErrorType, CustomMathWarningType, WarningAssetType } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-error';
import { CustomMathToken } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-token';
import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';
import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';
import { MetricExpressionItem } from '@cxstudio/metrics/custom-math/metric-expression-item.class';
import { FilterMetricDefinition } from '@cxstudio/metrics/entities/filter-metric-definition.class';
import { MetricDefinition } from '@cxstudio/metrics/entities/metric-definition';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { CustomMathMetrics } from './custom-math-metrics';

@Injectable({providedIn: 'root'})
export class MetricAdapter implements CustomMathExpressionAdapter {

	parseToken(token: CustomMathToken, assets: CustomMathAssets, appliedMetrics: CustomMathMetrics): FormulaSegment {
		let { metric, showWarning } = this.findMetricByDisplayName(token.value, assets, appliedMetrics.customMetrics);

		let textToken: TextToken = CustomMathAdapterUtils.getItemNameToken(token, TextTokenType.METRIC, !metric);
		if (!metric && textToken) {
			textToken.errors = [CustomMathErrorType.MISSED_REFERENCE];
		}
		if (metric && textToken && showWarning) {
			textToken.warnings = [{
				type: CustomMathWarningType.DUPLICATE_NAME_WITH_OWNER,
				params: {
					name: metric.displayName,
					type: WarningAssetType.METRIC,
					owner: metric.ownerName
				}
			}];
		}

		let segment: FormulaSegment = {
			text: token.text,
			startOffset: token.offset,
			textTokens: CustomMathAdapterUtils.getSegmentTextTokens('metric', TextTokenType.PREFIX, textToken)
		};

		if (metric) {
			let id = metric.id;
			let name = metric.name;
			let definitionType = metric.definition.type;
			let aggrLevel;
			if (this.isFilterMetricDefinition(metric.definition)) {
				aggrLevel = metric.definition.aggrLevel;
			}
			let display = `metric[${token.value}]`;
			segment.expression = new MetricExpressionItem(id, name, display);
		} else {
			segment.expression = new ExpressionItem(ExpressionPieces.METRIC, token.value);
		}
		return segment;
	}

	toString(expression: ExpressionItem, assets: CustomMathAssets): string {
		let metricExpression = expression as MetricExpressionItem;
		let metric = this.findMetricByName(metricExpression.name, assets);
		let displayName = CustomMathAdapterUtils.getCalculationName(metricExpression.displayName);
		if (metric) {
			displayName = metric.displayName;
		}
		displayName = CustomMathAdapterUtils.escapeSpecialChars(displayName);
		return `metric[${displayName}]`;
	}

	private isFilterMetricDefinition(definition: MetricDefinition): definition is FilterMetricDefinition {
		return definition.type === MetricType.FILTER;
	}

	private findMetricByDisplayName = (displayName: string, assets: CustomMathAssets,
		appliedCustomMetrics: Metric[]): {metric?: Metric; showWarning?: boolean} => {
		if (!displayName) {
			return {};
		}
		let metricsWithSameDisplayName = _.filter(assets.metrics, customMetric => customMetric.displayName === displayName);
		let appliedMetric = _.findWhere(appliedCustomMetrics, {displayName});
		let metric = appliedMetric ? appliedMetric : metricsWithSameDisplayName[0];
		return { metric, showWarning: metricsWithSameDisplayName.length > 1 };
	};

	private findMetricByName = (name: string, assets: CustomMathAssets): Metric => {
		return _.find(assets.metrics, (metric) => metric.name === name);
	};
}
