import { Component, Input, Output, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject, SimpleChanges, OnChanges, EventEmitter } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { SearchListGroup } from '@app/shared/components/forms/lists/search-list.component';
import { ChangeUtils } from '@app/util/change-utils';
import { downgradeComponent } from '@angular/upgrade/static';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import AlertSubscriptionTemplatesApi from '@cxstudio/alert-subscription-templates/alert-subscription-templates-api.service';
import IAlertSubscriptionTemplate, { AlertSubscriptionTemplateType } from '@cxstudio/alert-subscription-templates/types/alert-subscription-template';
import Hierarchy from '@cxstudio/organizations/Hierarchy';
import Group from '@cxstudio/user-administration/groups/Group';

import { Security } from '@cxstudio/auth/security-service';
import { EngagorApiService, EngagorAssignees } from '@cxstudio/services/data-services/engagor-api.service';
import { Assignee } from '@cxstudio/alert-subscription-templates/types/assignee';
import { AssigneeType } from '@cxstudio/alert-subscription-templates/types/assignee-type';
import { DesignerAlertType } from '@cxstudio/alerts/designer/designer-alert-type';
import { GroupType } from '@app/modules/user-administration/groups/group-type';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { AlertHierarchySelectionEditDialogComponent } from './alert-hierarchy-selection-edit-dialog.component';
import { Alert } from './alert.class';
import { AlertSubscription } from './alert-subscription';
import { ObjectSharingConstants } from '@app/modules/asset-management/object-sharing-constants';



const DEFAULT_TEMPLATE_NAME = 'Default';

@Component({
	selector: 'alert-subscriptions-table',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './alert-subscriptions-table.component.html'
})
export class AlertSubscriptionsTableComponent implements OnInit, OnChanges {

	@Input() group: Group;
	@Input() socialEnabled: boolean;
	@Input() socialTopics: any[];
	@Input() hierarchies: Hierarchy[];
	@Input() subscriptions: AlertSubscription[];
	@Input() socialTopicsError: string;
	@Input() alertWarnings;
	@Input() viewOnly: boolean;
	@Input() defaultTopicId: number;

	// pagination
	@Input() currentPage: number;
	@Input() itemsPerPage: number;

	private loading: any;
	@Output() updateLoading = new EventEmitter<any>();

	private getAssigneeFunctions = {};
	private subscriptionTemplates: {[key: string]: IAlertSubscriptionTemplate[] };

	engagorAssignees: EngagorAssignees;
	dropdownTemplates: Array<SearchListGroup<IAlertSubscriptionTemplate>>;


	constructor(
		private readonly cxDialogService: CxDialogService,
		private readonly locale: CxLocaleService,
		private readonly ref: ChangeDetectorRef,
		@Inject('security') private readonly security: Security,
		@Inject('alertSubscriptionTemplatesApi') private readonly alertSubscriptionTemplatesApi: AlertSubscriptionTemplatesApi,
		@Inject('engagorApiService') private readonly engagorApiService: EngagorApiService,
	) {}

	ngOnInit(): void {
		if (this.socialEnabled) {
			this.loadEngagorUsers();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.subscriptions)) {
			this.resetSubscriptionTemplates();
		}
	}

	private loadEngagorUsers = (): void => {
		this.loading = this.engagorApiService.getAssignees();
		this.updateLoading.emit(this.loading);

		this.loading.then(assignees => {
			this.engagorAssignees = assignees;
			this.ref.detectChanges();
		}).finally(() => {
			this.updateLoading.emit(this.loading);
		});
	};

	getDefaultAssigneeFunction = (subscription: AlertSubscription): (() => Assignee) => {
		if (!this.getAssigneeFunctions[subscription.alert.alertId]) {
			this.getAssigneeFunctions[subscription.alert.alertId] = () => subscription.alert.defaultAssignee;
		}
		return this.getAssigneeFunctions[subscription.alert.alertId];
	};

	selectSubscriptionAssignee = (subscription: AlertSubscription, assignee: Assignee): void => {
		subscription.alert.defaultAssignee = assignee;
	};

	clearAssignee = (subscription: AlertSubscription): void => {
		subscription.alert.defaultAssignee = null;
	};

	createCaseClicked = (subscription: AlertSubscription): void => {
		if (subscription.createCase === true && _.isEmpty(subscription.alert.topicId)) {
			subscription.alert.topicId = this.defaultTopicId;
		} else {
			delete subscription.alert.topicId;
		}
	};

	resetSubscriptionTemplates = () => {
		this.subscriptionTemplates = {};
		(this.subscriptions || []).forEach(subscription => {
			const alert: any = (subscription && subscription.alert) || {};
			let key: string = this.getTemplateKey(alert);
			if (this.security.has('manage_groups')
				&& key && !this.subscriptionTemplates[key]
				&& subscription.alert.alertSubscriptionTemplateId) {
				this.alertSubscriptionTemplatesApi.getEnabledTemplatesForCurrentUser(
					alert.contentProviderId, alert.accountId, alert.projectId).then(templates => {
					this.subscriptionTemplates[key] = templates;
					this.ref.detectChanges();
				});
			}
		});
	};

	private getTemplateKey(alert: Alert): string {
		if (alert) {
			let values = [alert.contentProviderId, alert.accountId, alert.projectId];
			if (_.every(values, value => _.isNumber(value))) {
				return values.join('_');
			}
		}
		return null;
	}

	getTemplateForSubscription = (subscription) => {
		return _.findWhere(
			this.getAvailableTemplatesForSubscription(subscription),
			{id: subscription.alert.alertSubscriptionTemplateId}
		);
	};

	private getAccessibleTemplates(alert: Alert): IAlertSubscriptionTemplate[] {
		let key = this.getTemplateKey(alert);
		return key ? this.subscriptionTemplates[key] : [];
	}

	getAvailableTemplatesForSubscription = (subscription: AlertSubscription, all = false): IAlertSubscriptionTemplate[] => {
		const alert = subscription && subscription.alert;
		if (!alert) return [];
		let accessibleTemplates = this.getAccessibleTemplates(subscription.alert);

		return all ? accessibleTemplates : _.filter(accessibleTemplates, template => {
			return template.type !== AlertSubscriptionTemplateType.PRODUCT_FEEDBACK
				&& (this.isAlertHierarchySelectionEnabled() || !this.isHierarchyAssigneeTemplate(template));
		});
	};

	isHierarchyAssigneeTemplate = (template: IAlertSubscriptionTemplate): boolean => {
		return template.caseSettings?.assigneeObject?.type === AssigneeType.HIERARCHY;
	};

	isHierarchyAssigneeTemplateSubscription = (subscription): boolean => {
		let accessibleTemplates = this.getAvailableTemplatesForSubscription(subscription, true);
		let template = _.findWhere(accessibleTemplates, { id: subscription.alert.alertSubscriptionTemplateId });
		return template && this.isHierarchyAssigneeTemplate(template);
	};

	populateDropdownTemplates = (subscription): void => {
		const templates = this.getAvailableTemplatesForSubscription(subscription);
		this.dropdownTemplates = [{
			label: this.locale.getString('alert.newVerbatim'),
			list: templates.filter(t => t.type === AlertSubscriptionTemplateType.NEW_VERBATIM)
		}];
		let alert = subscription.alert;
		if (alert.type === DesignerAlertType.SCORECARD) {
			this.dropdownTemplates.push({
				label: this.locale.getString('alert.scorecard'),
				list: templates.filter(t => t.type === AlertSubscriptionTemplateType.SCORECARD
					&& t.scorecardId === alert.scorecardId)
			});
		}
	};

	retrieveTemplatesForSubscription = (subscription: AlertSubscription): void => {
		let alert = subscription.alert;
		let key = this.getTemplateKey(alert);
		if (key && !this.subscriptionTemplates[key]) {
			this.loading =
				this.alertSubscriptionTemplatesApi.getEnabledTemplatesForCurrentUser(alert.contentProviderId, alert.accountId, alert.projectId);
			this.updateLoading.emit(this.loading);

			this.loading.then(templates => {
				this.subscriptionTemplates[key] = templates;
				this.populateDropdownTemplates(subscription);
				this.ref.detectChanges();
			}).finally(() => {
				this.updateLoading.emit(this.loading);
			});
		} else {
			this.populateDropdownTemplates(subscription);
		}
	};

	selectTemplate = (subscription: AlertSubscription, template: IAlertSubscriptionTemplate) => {
		if (subscription && subscription.alert && template) {
			this.checkDependantSubscriptions(subscription, template);
			subscription.alert.alertSubscriptionTemplateId = template.id;
			let assignee: Assignee = template.caseSettings.assigneeObject;
			this.selectSubscriptionAssignee(subscription, assignee);
		}
	};

	checkDependantSubscriptions = (subscription: AlertSubscription, newTemplate: IAlertSubscriptionTemplate) => {
		let alert = subscription.alert;
		let currentTemplateId = alert.alertSubscriptionTemplateId;
		let warningId = this.alertWarnings.findIndex(warn => warn.alertId === alert.alertId);
		if (alert.subscriptionsCount < 2 || currentTemplateId === newTemplate.id
				|| (!currentTemplateId && newTemplate.name === DEFAULT_TEMPLATE_NAME)) {
			return;
		}
		if (warningId < 0) {
			let accessibleTemplates = this.getAccessibleTemplates(alert);
			let initialTemplate = accessibleTemplates.find(template => template.id === currentTemplateId);
			this.alertWarnings.push({
				alertId: alert.alertId,
				initialTemplateId: currentTemplateId,
				message: this.locale.getString('alertTemplates.groupSubscriptionWarning', {
					alertName: alert.alertName,
					initialTemplateName: initialTemplate && initialTemplate.name || DEFAULT_TEMPLATE_NAME
				})
			});
		} else if (this.alertWarnings[warningId].initialTemplateId === newTemplate.id
				|| (!this.alertWarnings[warningId].initialTemplateId && newTemplate.name === DEFAULT_TEMPLATE_NAME)) {
			this.alertWarnings.splice(warningId, 1);
		}
	};

	updateSubscriptionsCount = (subscription) => {
		subscription.alert.subscriptionsCount += subscription.subscribed ? 1 : -1;
	};

	editAlertHierarchySelection = (alertSubscription: AlertSubscription): void => {
		let dialog: NgbModalRef = this.cxDialogService.openDialog(
			AlertHierarchySelectionEditDialogComponent, { group: this.group, alertSubscription }, { size: ModalSize.MEDIUM });

		dialog.result.then(() => {
			let hierarchySelected = this.isAlertSubscriptionHierarchySelected(alertSubscription);
			if (!hierarchySelected) {
				alertSubscription.alert.defaultAssignee = null;
			}
			this.ref.markForCheck();
		}).catch(_.noop);
	};

	getSubscriptionColumnsCount = (): number => {
		let columnsCount = 3; // 3 columns no matter what
		columnsCount += this.socialEnabled ? 4 : 0;
		columnsCount += this.isAlertHierarchySelectionEnabled() ? 1 : 0;

		return columnsCount;
	};

	getHierarchyName = (subscription: AlertSubscription): string => {
		let hierarchySelection = subscription.alert.hierarchySelections
			&& subscription.alert.hierarchySelections[this.group.groupId];

		if (!hierarchySelection || isEmpty(hierarchySelection.levels))
			return this.locale.getString('common.noneSelected');
		if (_.contains(hierarchySelection.levels, 1))
			return this.locale.getString('common.allSelected');

		let hierarchy = _.find(this.hierarchies, {id: hierarchySelection.hierarchyId});
		if (!hierarchy)
			return this.locale.getString('common.noneSelected');

		return hierarchy.name;
	};

	isHierarchicalCaseSubscription = (subscription: AlertSubscription): boolean => {
		let alertHierarchySelectionEnabled = this.isAlertHierarchySelectionEnabled();
		let hierarchySelected = this.isAlertSubscriptionHierarchySelected(subscription);
		let creatingCase = subscription.createCase;
		let hierarchyAssignee = this.isHierarchyAssigneeTemplateSubscription(subscription);
		let isAssigneeAccessible = alertHierarchySelectionEnabled && creatingCase;
		let isHierarchical = hierarchySelected || hierarchyAssignee;
		return isAssigneeAccessible && isHierarchical;
	};

	private isAlertSubscriptionHierarchySelected = (subscription: AlertSubscription): boolean => {
		let hierarchySelection = subscription.alert.hierarchySelections
			&& subscription.alert.hierarchySelections[this.group.groupId];

		return hierarchySelection && !isEmpty(hierarchySelection.levels);
	};

	isAssigneeVisible = (subscription: AlertSubscription): boolean => {
		let createCaseSelected: boolean = this.isCreateCaseColumnEnabled() && subscription.createCase;

		return createCaseSelected && !this.isHierarchicalCaseSubscription(subscription);
	};

	isAssigneeDisabled = (subscription: AlertSubscription): boolean => {
		return !subscription.subscribed || (this.isTemplateWithAssigneeApplied(subscription.alert));
	};

	isTemplateWithAssigneeApplied = (alert: Alert) => {
		let templateId: number = alert.alertSubscriptionTemplateId;
		if (!templateId) {
			return false;
		}

		let accessibleTemplates = this.getAccessibleTemplates(alert);
		let template: IAlertSubscriptionTemplate = _.findWhere(accessibleTemplates, {id: alert.alertSubscriptionTemplateId});
		return !!template?.caseSettings?.assigneeObject;
	};

	getFilteredAlertSubscriptions = (): AlertSubscription[] => {
		if (_.isUndefined(this.subscriptions)) {
			return [];
		}

		let sliceFrom: number = (this.currentPage - 1) * this.itemsPerPage;
		let sliceTo: number = this.currentPage * this.itemsPerPage;
		return this.subscriptions.slice(sliceFrom, sliceTo);
	};

	trackByAlertSubscriptionIndex = (index: number, alertSubscription: AlertSubscription) => {
		return index;
	};

	isNotSubscribedOrViewOnly = (subscription: AlertSubscription): boolean => {
		return !subscription.subscribed || this.viewOnly;
	};

	isAssigneeSelectorClickable = (subscription: AlertSubscription): boolean => {
		return this.isAssigneeDisabled(subscription) || this.viewOnly;
	};

	isAlertHierarchySelectionEnabled = (): boolean => {
		return GroupType.WHOLE_HIERARCHY === this.group.type;
	};

	isCreateCaseColumnEnabled = ():boolean => {
		return this.socialEnabled;
	};

	getAssigneeLabel = (): string => {
		return this.locale.getString('cases.assignee');
	};

	isAssigneeColumnEnabled = (): boolean => {
		return this.socialEnabled;
	};

	shouldShowEmailWarning = (): boolean => {
		const emailEnabled = this.subscriptions?.some(subscription => {
			return subscription.subscribed && subscription['sendEmail'];
		});
		return emailEnabled && this.group.usersCount > ObjectSharingConstants.EMAIL_USER_LIMIT;
	};

}

app.directive('alertSubscriptionsTable', downgradeComponent({component: AlertSubscriptionsTableComponent}));
