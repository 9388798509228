import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';
import { TextToken } from '../adapter/formula-segment';

export enum TextField {
	TEXT =  'text',
	VALUE = 'value'
}

@Injectable()
export class CustomMathFunctionHelper {

	readonly FUNCTION_START = 'abs(';

	highlightFunctionStartTokens = (textTokens: TextToken[]): number[] => {
		return this.getAndProcessFunctionsStart(textTokens, TextField.TEXT, true);
	};

	findFunctionStartExpressionItems = (expressionItems: ExpressionItem[]): number[] => {
		return this.getAndProcessFunctionsStart(expressionItems, TextField.VALUE, false);
	};

	private getAndProcessFunctionsStart = (items: any[], textField: string, markAsFunction: boolean): number[] => {
		let startFunctionIndexes = [];
		
		items.forEach((item, index) => {
			if (item[textField] === this.FUNCTION_START) {
				if (markAsFunction) {
					item.isFunction = true;
				}
				startFunctionIndexes.push(index);
			}
		});

		return startFunctionIndexes;
	};

	highlightFunctionEndToken = (textTokens: TextToken[], startFunctionIndex: number): void => {
		let functionEndToken: any = this.getFunctionEndItem(textTokens, startFunctionIndex, TextField.TEXT);
		if (functionEndToken) {
			functionEndToken.isFunction = true;
		}
	};

	isFunctionCompleted = (expressionItems: ExpressionItem[], startFunctionIndex: number): boolean => {
		return !!this.getFunctionEndItem(expressionItems, startFunctionIndex, TextField.VALUE);
	};

	private getFunctionEndItem = 
		(items: TextToken[] | ExpressionItem[], startFunctionIndex: number, textField: TextField): TextToken | ExpressionItem => {
			let OPEN_PARENTHESIS = '(';
			let CLOSE_PARENTHESIS = ')';

			let functionEndItem: TextToken | ExpressionItem;
			
			let additionalOpenParenthesis: number = 0;
			let itemsAfterFunctionStart: any[] = items.slice(startFunctionIndex + 1);
			for (let item of itemsAfterFunctionStart) {
				let itemText = item[textField];

				if (itemText === OPEN_PARENTHESIS || itemText === this.FUNCTION_START) {
					additionalOpenParenthesis++;
				}

				if (itemText === CLOSE_PARENTHESIS) {
					if (additionalOpenParenthesis === 0) {
						functionEndItem = item;
						break;
					} else {
						additionalOpenParenthesis--;
					}
				}
			}

			return functionEndItem;
		};

}

app.service('customMathFunctionHelper', downgradeInjectable(CustomMathFunctionHelper));