import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService, CxLocaleService } from '@app/core';
import { ConfigService } from '@app/core/config.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { URLListItem } from '@discover/unified-angular-components/dist/unified-angular-components';
import { Security } from '@cxstudio/auth/security-service';
import { RedirectDestinationValues } from '@cxstudio/services/redirect-destination';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { from, Observable } from 'rxjs';
import { concatMap, share } from 'rxjs/operators';
import { ContentProviderApi } from '../system-administration/content-provider/content-provider.api.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class SidebarService {

	showSidebar: boolean = false;
	contentProvidersLoading$: Observable<any>;
	config;
	openedWithKeyboard: boolean = false;

	readonly toggleElementId = 'sidebar-toggle';

	constructor(
		@Inject('security') private security: Security,
		@Inject('redirectService') private redirectService: RedirectService,
		private contentProviderApiService: ContentProviderApi,
		private configService: ConfigService,
		private locale: CxLocaleService,
		private cxHttp: CxHttpService) {

		this.contentProvidersLoading$ = this.loadContentProviders();
	}

	loadSidebarConfig = (): Promise<any> => {
		return PromiseUtils.wrap(this.configService.getSidebarConfig().then(response => this.config = response));
	};

	isLinkEnabled = (configType) => this.config[configType] && this.config[configType].enabledForUser;

	getEngagorRedirectionUrl = (parameters) => {
		parameters.masterAccountId = this.security.getMasterAccountId();
		return this.redirectService.getRedirectionUrlWithParams(RedirectDestinationValues.ENGAGOR, parameters);
	};

	redirectToAnalyze = (provider, account) =>
		this.redirectService.getRedirectionUrlWithParams(RedirectDestinationValues.ANALYZE, {
			providerId: provider.id,
			accountId: account.accountId
		});

	redirectToConnectors = (provider, account) =>
		this.redirectService.getRedirectionUrlWithParams(RedirectDestinationValues.CONNECTORS, {
			masterAccountId: this.security.getMasterAccountId(),
			providerId: provider.id,
			accountId: account.accountId
		});

	private loadContentProviders = (): Observable<any> => {
		return this.contentProviderApiService.getUserContentProvidersWithAccounts();
	};

	getOAuthLinks(): Observable<URLListItem[]> {
		return from(
			this.cxHttp.get<URLListItem[], HttpParams>(`rest/config/unified-sidebar`)
		)
			.pipe(share());
	}

	private getCaseManagementLinks(): URLListItem {
		if (this.isLinkEnabled('engagor')) {
			let engageLinks: URLListItem = {
				name: this.locale.getString('sidebar.casesTitle'),
				url: null,
				children: [{
					name: this.locale.getString('sidebar.inbox'),
					url: this.getEngagorRedirectionUrl({ redirect: `messages/${this.config.engagor.accountId}` })
				}]
			};

			return engageLinks;
		}
	}

	private getNarrativeLink(): URLListItem {
		if (this.isLinkEnabled('narrative')) {
			let link: URLListItem = {
				name: this.locale.getString('sidebar.narrative'),
				url: this.redirectService.getRedirectionUrlWithParams(
					RedirectDestinationValues.NARRATIVE, {masterAccountId: this.security.getMasterAccountId()})
			};

			return link;
		}
	}

	private getSampledAuditLink(): URLListItem {
		if (this.isLinkEnabled('sampledAudit')) {
			let link: URLListItem = {
				name: this.locale.getString('sidebar.sampledAudits'),
				url: '#/sampled_audits'
			};

			return link;
		}
	}

	private getLinkGroup = (providers: any[], getRedirectLink: (provider, account) => string): URLListItem[] => {
		let compiledLinks = [];
		_.each(providers, (provider: any): void => {
			if (!provider?.accounts?.length) return;

			let providerGroup = {
				name: provider.name,
				url: null,
				children: []
			} as unknown as URLListItem;

			if (provider.accounts?.length > 1) {
				_.each(provider.accounts,
					(account: any) => providerGroup.children.push(
						{ name: account.accountName, url: getRedirectLink(provider, account) }
					));
			} else if (provider.accounts.length === 1) {
				// if there's only 1 account, don't need to list out accounts
				providerGroup.url = getRedirectLink(provider, provider.accounts[0]);
				delete providerGroup.children;
			}

			compiledLinks.push(providerGroup);
		});

		return compiledLinks;
	};

	getNonOAuthLinks = (): Observable<URLListItem[]> => {
		return from(this.loadSidebarConfig())
			.pipe(concatMap(() => {
				return new Observable<URLListItem[]>(obs => {
					this.contentProvidersLoading$.subscribe(
						(providers: any) => {
							let analyzeLinks = this.getLinkGroup(providers, this.redirectToAnalyze);
							let connectorsLinks = this.getLinkGroup(providers, this.redirectToConnectors);

							let analyzeLinksGroup = {} as URLListItem;
							let connectorsLinksGroup = {} as URLListItem;

							if (this.isLinkEnabled('analyze') && analyzeLinks.length) {
								analyzeLinksGroup = {
									name: 'Designer',
									url: null,
									children: analyzeLinks
								} as URLListItem;

								// if there is only 1 Content Provider with 1 Acct, dont show any hierarchy
								if (analyzeLinks.length === 1 && !analyzeLinks[0].children?.length) {
									analyzeLinksGroup.url = analyzeLinks[0].url;
									delete analyzeLinksGroup.children;
								}
							}

							if (this.isLinkEnabled('connectors') && connectorsLinks.length) {
								connectorsLinksGroup = {
									name: 'Connectors',
									url: null,
									children: connectorsLinks
								} as URLListItem;

								// if there is only 1 Content Provider with 1 Acct, dont show any hierarchy
								if (connectorsLinks.length === 1 && !connectorsLinks[0].children?.length) {
									connectorsLinksGroup.url = connectorsLinks[0].url;
									delete connectorsLinksGroup.children;
								}
							}

							return obs.next([
								{ name: 'Studio', url: '#/dashboards' },
								this.getCaseManagementLinks(),
								connectorsLinksGroup,
								analyzeLinksGroup,
								this.getNarrativeLink(),
								this.getSampledAuditLink()
							].filter(linkItem => !!linkItem?.name));
						});
				});
			}));
	};

	closeSidebarViaKeyboard = (focusHamburgerOnly: boolean) => {
		if (!focusHamburgerOnly)
			this.toggleSidebar();
		document.getElementById(this.toggleElementId).focus();
	};


	toggleSidebar = (openedWithKeyboard: boolean = false): void => {
		this.showSidebar = !this.showSidebar;
		this.openedWithKeyboard = openedWithKeyboard;

		// if the sidebar is open, any click should close it
		if (this.showSidebar) {
			setTimeout(() => {
				$(document).on('click', () => this.toggleSidebar());
			}, 1);
		} else {
			$(document).off('click');
		}
	};
}

app.service('sidebarService', downgradeInjectable(SidebarService));
