import { FiltersService } from '@app/modules/filter/services/filters.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { PromiseUtils } from '@app/util/promise-utils';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ColorPalettes } from '@cxstudio/reports/coloring/color-palettes.service';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { IWidgetSettings } from '@cxstudio/reports/providers/cb/services/widget-settings.service';
import * as _ from 'underscore';

export interface IAdminProject {
	projectId: number;
	title: string;
	type: AdminProjectType;
}

export enum AdminProjectType {
	STUDIO_PROJECT = 'STUDIO_PROJECT'
}

export class AdminProjectsService {

	constructor(
		private locale: ILocale,
		private metricConstants: MetricConstants,
		private readonly filtersService: FiltersService,
		private colorPalettes: ColorPalettes,
		private $q: ng.IQService
	) {

	}

	getStudioAdminProject(): IAdminProject {
		return {
			projectId: InternalProjectTypes.STUDIO_ADMIN_PROJECT_ID,
			type: AdminProjectType.STUDIO_PROJECT,
			title: this.locale.getString('common.studioInternalProject'),
		};
	}

	getProjectAttributes(projectId: number): IReportAttribute[] {
		switch (projectId) {
			case InternalProjectTypes.STUDIO_ADMIN_PROJECT_ID: return this.getStudioProjectAttributes();
			default: throw new Error(`Incorrect admin project id: ${projectId}`);
		}
	}

	private getStudioProjectAttributes(): IReportAttribute[] {
		let constants = this.metricConstants.get();
		return [
			constants.USER,
			constants.USER_ACTION,
			constants.SERVER_TIME,
			constants.OBJECT,
			constants.TARGET,
			constants.SOURCE,
			constants.DETAILS
		];
	}

	getWidgetSettings(project: IProjectSelection): ng.IPromise<IWidgetSettings> {
		return this.$q.all([
			PromiseUtils.old(this.filtersService.getStudioDateFilters(project)),
			this.colorPalettes.getWidgetPalettes()
		]).then(result => {
			return {
				attributes: this.getProjectAttributes(project.projectId),
				dateFilters: result[0],
				models: [],
				projectContext: {},
				studioPalettes: result[1],
			} as IWidgetSettings;
		});
	}
}

app.service('adminProjectsService', AdminProjectsService);
