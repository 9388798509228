import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter,
	Inject, Input, Output, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { Subscription } from 'rxjs';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { DomainsProcessingService } from '@app/modules/account-administration/api/domains-processing.service';
import { CxWizardStepComponent } from '@app/modules/wizard/cx-wizard-step/cx-wizard-step.component';
import { Security } from '@cxstudio/auth/security-service';
import { UserApiService } from '@cxstudio/services/data-services/user-api-service';
import { UserEditFormData } from '../../editor/user-edit-form-data';
import { SelectedAccountsData, UserCreateOptions } from '../user-create-wizard.component';
import { CurrentMasterAccount } from '@cxstudio/auth/entities/current-master-account';
import { SystemPermissionsService } from '../../system-permissions/system-permissions.service';
import { FormValidationService } from '@app/shared/services/form-validation.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import * as cloneDeep from 'lodash.clonedeep';
import { UserCreateWizardHelperService } from '../user-create-wizard-helper.service';
import { LicenseService } from '../../license.service';
import { License } from '@app/modules/user-administration/permissions/license.class';
import { MasterAccountContractInfo } from '@cxstudio/master-accounts/contracts/master-account-contract-info';
import { AllowedDomains } from '@app/modules/system-administration/master-account/email-domains/allowed-domains';
import {UsersGroupsLogicService} from '@app/modules/user-administration/services/users-groups-logic.service';

@Component({
	selector: 'user-create-main-step',
	templateUrl: './user-create-main-step.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCreateMainStepComponent implements OnInit, AfterViewInit, OnDestroy {

	@Input() user: UserEditFormData;
	@Input() passwordPolicy: any;
	@Input() allowedDomains: AllowedDomains;
	@Input() licenseTypes: License[];
	@Input() permissionItems: any[];
	@Input() licenseInfo: MasterAccountContractInfo;

	@Output() dialogValidityChange = new EventEmitter<boolean>();
	@Output() optionsChange = new EventEmitter<UserCreateOptions>();
	@Output() licenseChange = new EventEmitter<number>();
	@Output() accountsChange = new EventEmitter<SelectedAccountsData>();
	@Output() accountsReset = new EventEmitter<void>();

	@ViewChild('userDialog', {static: true}) public userDialog: UntypedFormGroup;
	private userDialogFormStatus$: Subscription;

	formValidationService: FormValidationService;

	isForcedExternalAuthentication: boolean;

	pwdPolicy: any;
	pwdPattern: any;

	allowedDomainsErrorMessage: string;

	isAdminOrgUser: boolean;
	isAdminOrgMasterAccount: boolean;
	isSamlForMAEnabled: boolean;
	isAuthByUniqueId: boolean;
	isNeedXmAccountId: boolean;

	options: UserCreateOptions;
	currentMasterAccount: CurrentMasterAccount;

	assignableLicenseTypes: any[];

	private resetSystemPermissions: boolean;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly cxDialogService: CxDialogService,
		private readonly ref: ChangeDetectorRef,
		private readonly domainsProcessingService: DomainsProcessingService,
		private readonly systemPermissionsService: SystemPermissionsService,
		private readonly userGroupsLogicService: UsersGroupsLogicService,
		private readonly userCreateWizardHelperService: UserCreateWizardHelperService,
		private readonly licenseService: LicenseService,
		@Inject('security') private readonly security: Security,
		@Inject('userApiService') private readonly userApiService: UserApiService,
		private readonly wizardStep: CxWizardStepComponent
	) {}

	ngOnInit(): void {
		this.wizardStep.setValid(false);
		this.dialogValidityChange.emit(false);

		this.formValidationService = new FormValidationService(this.userDialog);

		this.currentMasterAccount = this.security.getCurrentMasterAccount();

		this.isSamlForMAEnabled = this.security.isSamlForMAEnabled();
		this.isAuthByUniqueId = this.security.isAuthByUniqueId();
		this.isNeedXmAccountId = this.security.isQualtricsIntegrationEnabled() || this.currentMasterAccount.adminOrg;
		this.isAdminOrgMasterAccount = this.currentMasterAccount.adminOrg;

		this.options = {};
		this.options.customFieldName = this.currentMasterAccount.customField;
		this.options.permissions = this.permissionItems;
		this.optionsChange.emit(this.options);

		this.isForcedExternalAuthentication = this.security.isForcedExternalAuthentication();

		this.pwdPolicy = this.passwordPolicy.data || {};
		this.allowedDomainsErrorMessage = '';
		this.pwdPattern = new RegExp('.*');
		if (this.pwdPolicy && this.pwdPolicy.requireSpecialChars) {
			this.pwdPattern = this.security.getPwdPattern();
		}

		this.assignableLicenseTypes = this.licenseService.getAssignableLicenseTypes(this.licenseTypes, this.licenseInfo);
		this.populateAssignableLicensesDisplayNames(this.assignableLicenseTypes);
	}

	ngAfterViewInit(): void {
		this.userDialogFormStatus$ = this.userDialog.statusChanges.subscribe(() => {
			let dialogValid: boolean = this.userDialog.valid;
			this.wizardStep.setValid(dialogValid);
			this.dialogValidityChange.emit(dialogValid);
		});
	}

	ngOnDestroy(): void {
		if (this.userDialogFormStatus$) {
			this.userDialogFormStatus$.unsubscribe();
		}
	}

	hasError(controlName: string, errorName: string): boolean {
		return this.formValidationService.hasError(controlName, errorName);
	}

	showFirstNameError(): boolean {
		return this.isValidLengthFirstName() || this.invalidFirstName();
	}

	isValidLengthFirstName(): boolean {
		let firstNameControl: AbstractControl = this.userDialog.controls['firstName'];
		return firstNameControl?.touched && this.formValidationService.hasError('firstName', 'required');
	}

	invalidFirstName(): boolean {
		let firstNameControl: AbstractControl = this.userDialog.controls['firstName'];
		let invalidValue = this.userGroupsLogicService.isUnsafeName(firstNameControl?.value);
		if (invalidValue) {
			this.formValidationService.updateControlErrors('firstName', 'invalid', true);
		}
		return !!invalidValue;
	}

	showLastNameError(): boolean {
		return this.isValidLengthLastName() || this.invalidLastName();
	}

	isValidLengthLastName(): boolean {
		let lastNameControl: AbstractControl = this.userDialog.controls['lastName'];
		return lastNameControl?.touched && this.formValidationService.hasError('lastName', 'required');
	}
	invalidLastName(): boolean {
		let lastNameControl: AbstractControl = this.userDialog.controls['lastName'];
		let invalidValue = this.userGroupsLogicService.isUnsafeName(lastNameControl?.value);
		if (invalidValue) {
			this.formValidationService.updateControlErrors('lastName', 'invalid', true);
		}
		return !!invalidValue;
	}

	showPasswordInvalidError(): boolean {
		let passwordControl: AbstractControl = this.userDialog.controls['password'];
		return passwordControl?.touched &&
			(this.formValidationService.hasError('password', 'minlength') ||
			this.formValidationService.hasError('password', 'required'));
	}

	showPasswordPatternError(): boolean {
		let passwordControl: AbstractControl = this.userDialog.controls['password'];
		return passwordControl?.touched && this.formValidationService.hasError('password', 'pattern');
	}

	showPasswordEmailError(): boolean {
		let email: AbstractControl = this.userDialog.controls['email'];
		let passwordControl: AbstractControl = this.userDialog.controls['password'];
		return (email?.touched && passwordControl?.touched) && passwordControl.value?.trim() === email.value?.trim();
	}

	hasPasswordError(): boolean {
		return this.showPasswordEmailError()
			|| this.showPasswordInvalidError()
			|| this.showPasswordPatternError();
	}

	showConfirmPasswordNotMatchError(): boolean {
		let conformPasswordControl: AbstractControl = this.userDialog.controls['confirmPassword'];
		return conformPasswordControl?.touched && this.formValidationService.hasError('confirmPassword', 'pwmatch');
	}

	showXmAccountIdError(): boolean {
		let xmAccountIdControl: AbstractControl = this.userDialog.controls['xmAccountId'];
		return xmAccountIdControl?.touched && !_.isEmpty(xmAccountIdControl.errors);
	}

	isExistingUser = (): boolean => {
		return !_.isUndefined(this.user.userId);
	};

	isExistingUserInOtherMA = (): boolean => {
		return this.isExistingUser() && !_.isUndefined(this.user.existingMasterAccountId);
	};

	isDisabledUser = (): boolean => {
		return this.isExistingUser() && _.isUndefined(this.user.existingMasterAccountId);
	};

	isCurrentUserAnyAdmin = (): boolean => {
		return this.security.isAnyAdmin();
	};

	populateAssignableLicensesDisplayNames(licenseTypes :any[]) {
		licenseTypes.forEach(licenseType => {
			let countLabel: string = this.getCountLabel(licenseType.licenseTypeId);
			licenseType.displayName = licenseType.licenseTypeName + countLabel;
		});
	}

	getCountLabel = (licenseTypeId: number): string => {
		if (!this.isNeedToCountLicenses()) {
			return '';
		}
		return this.licenseService.getCountLabel(licenseTypeId, this.licenseInfo);
	};

	passwordDisabled = (): boolean => {
		return this.user.forceRegistration || this.isExistingUser();
	};

	// false on init; can be true on typing/blur/autofill
	forciblyShowEmailError = (): boolean => {
		return (this.userDialog.controls['email']?.touched || this.userDialog.controls['email']?.dirty) &&
			(
				!this.isEmailStringValid()
				|| this.formValidationService.hasError('email', 'emailExist')
				|| this.formValidationService.hasError('email', 'domainRestricted')
			);
	};

	isEmailStringValid = (): boolean => {
		return !this.formValidationService.hasError('email', 'invalid')
			&& !this.formValidationService.hasError('email', 'required')
			&& !this.formValidationService.hasError('email', 'minlength');

	};

	isUniqueIdValid = (): boolean => {
		return !this.formValidationService.hasError('authUniqueId', 'invalid')
			&& !this.formValidationService.hasError('authUniqueId', 'required')
			&& !this.formValidationService.hasError('authUniqueId', 'minlength');
	};

	validateUniqueId = (): void => {
		let userUniqueId = this.user.authUniqueId;
		if (!userUniqueId) return;
		this.userApiService.checkUniqueIdExists(userUniqueId).then((response) => {
			this.formValidationService.updateControlErrors('authUniqueId', 'uniqueIdExists', response.exist);
		});
	};

	showUniqueIdError(): boolean {
		return !this.isUniqueIdValid()
				|| this.formValidationService.hasError('authUniqueId', 'uniqueIdExists');
	}

	validateXmAccountId = (xmAccountId: string): void => {

		if (!xmAccountId) return;

		this.userApiService.checkXmAccountIdExists(xmAccountId).then((response) => {
			this.formValidationService.updateControlErrors('xmAccountId', 'xmAccountIdExists', response.exist);
		});
	};

	isXmAccountIdEnabled = (): boolean => {
		return this.security.isAccountOwner() || this.security.isAnyAdmin() || this.security.isCustomerAdmin();
	};

	getRestoreUserWarning = (): string => {
		return this.locale.getString('administration.userRestoreWarning', {action: this.locale.getString('common.next')});
	};

	// VALIDATE EMAIL

	validateEmail = (): void => {
		this.validateEmailDomain();
		this.validateEmailUnique();
	};

	validateEmailDomain = (): void => {
		if (this.isEmailStringValid()) {
			let allowed = true;
			if (this.allowedDomains.enabled) {
				let parsedDomain = this.user.email.split('@')[1];
				allowed = this.domainsProcessingService.validateLowerCaseEmailDomain(this.allowedDomains, parsedDomain);
				if (!allowed) {
					this.allowedDomainsErrorMessage = this.locale.getString('administration.domainRestriction', {
						domain: parsedDomain
					});
				}
				this.formValidationService.updateControlErrors('email', 'domainRestricted', !allowed);
			}
		} else {
			this.formValidationService.updateControlErrors('email', 'domainRestricted', false);
		}
	};

	validateEmailUnique = (): void => {
		if (this.isEmailStringValid() && !this.formValidationService.hasError('email', 'domainRestricted')) {
			// set form invalid while a request is processing
			this.setEmailValidationPending(true);
			this.userApiService.checkEmailDetails(this.user.email).then((result) => {
				if (!this.userDialog) {
					//dialog already closed
					return;
				}

				this.setEmailValidationPending(false);

				let userExist = !_.isUndefined(result.userId);

				let alreadyInMasterAccount = userExist && result.masterAccountMember;

				this.formValidationService.updateControlErrors('email', 'emailExist', alreadyInMasterAccount);

				if (userExist && !alreadyInMasterAccount) {
					//If not already in master account, need to check if this user is an internal user. if so dont allow them to be added
					//If internal account user, show error
					this.isAdminOrgUser = result.defaultAdminOrgUser && !this.isAdminOrgMasterAccount;

					this.formValidationService.updateControlErrors('email', 'adminOrgUser', this.isAdminOrgUser);
					if (!this.isAdminOrgUser) {
						this.fillPropertiesFromExistentUser(result);
					}
				} else {
					this.resetProperties();
				}
			});
		} else {
			this.formValidationService.updateControlErrors('email', 'emailExist', false);
		}
	};

	private setEmailValidationPending(pending: boolean): void {
		this.formValidationService.updateControlErrors('email', 'pending', pending);
	}

	isEmailValidationPending(): boolean {
		return this.formValidationService.hasError('email', 'pending');
	}

	isCurrentUserInternal = (): boolean => {
		return this.security.isAdminOrgUser();
	};

	private fillPropertiesFromExistentUser = (existentUser): void => {
		let user = this.user;
		user.userId = existentUser.userId;
		user.firstName = existentUser.firstName;
		user.lastName = existentUser.lastName;
		user.email = existentUser.userEmail;
		user.licenseTypeId = existentUser.licenseTypeId;
		user.xmAccountId = existentUser.xmAccountId;
		user.authUniqueId = existentUser.authUniqueId;

		user.existingMasterAccountId = existentUser.defaultMasterAccountId;
		if (existentUser.defaultMasterAccountId) {
			user.defaultMasterAccountId = existentUser.defaultMasterAccountId;
		} else {
			user.defaultMasterAccountId = this.security.getMasterAccountId();
		}

		let systemPermissions = [
			'systemAdministrator', 'liteAdmin',
			'studioApiUser', 'platformApiUser',
			'manageInternalTemplates', 'shareInternalTemplates', 'createInternalTemplates',
			'downloadTrainingData'
		];
		systemPermissions.forEach((systemPermission) => {
			user[systemPermission] = existentUser.systemPermissions[systemPermission];
		});
		this.resetSystemPermissions = true;
		user.permissions = existentUser.permissions;

		user.forceRegistration = false;
		this.clearPasswordFields();
		this.handleLicenseChange();

		this.accountsChange.emit(
			{
				initialSelectedAccounts: cloneDeep(existentUser.linkedAccounts),
				selectedAccounts: cloneDeep(existentUser.linkedAccounts)
			}
		);
	};

	onLicenseChange(license: any): void {
		this.user.licenseTypeId = license.licenseTypeId;
		this.handleLicenseChange();
	}

	handleLicenseChange = (): void => {
		this.licenseChange.emit(this.user.licenseTypeId);
	};

	private resetProperties = (): void => {
		this.user.userId = undefined;

		if (this.resetSystemPermissions) {
			this.user.systemAdministrator = false;
			this.user.liteAdmin = false;
			this.user.studioApiUser = false;
			this.user.platformApiUser = false;
			this.user.manageInternalTemplates = false;
			this.user.shareInternalTemplates = false;
			this.user.createInternalTemplates = false;
			this.user.downloadTrainingData = false;
		}
		this.resetSystemPermissions = false;

		this.user.defaultMasterAccountId = this.security.getMasterAccountId();
		this.user.existingMasterAccountId = undefined;
		this.accountsReset.emit();
	};

	private clearPasswordFields = (): void => {
		this.user.password = '';
		this.user.confirmPassword = '';
		if (this.userDialog.controls['confirmPassword']) {
			this.formValidationService.updateControlErrors('confirmPassword', 'pwmatch', false);
		}
	};

	// CUSTOM FIELD

	showCustomField = (): boolean => {
		return this.userCreateWizardHelperService.isAccountOwner() || !!this.options.customFieldName;
	};

	updateCustomFieldName = (customFieldName: string): void => {
		this.options.customFieldName = customFieldName;
		this.optionsChange.emit(this.options);
	};

	updateCustomFieldValue = (customFieldValue: string): void => {
		this.user.customField = customFieldValue;
	};

	// CHECKBOXES

	grantPlatformApiUser = (): void => {
		if (isFalse(this.user.platformApiUser)) {
			return;
		}

		this.cxDialogService.regularWithConfirm(
			this.locale.getString('administration.platformApiUser'), this.locale.getString('administration.grantPlatformApiUser'),
			this.locale.getString('common.continue'), this.locale.getString('common.cancel'), {class: 'platform-api-dialog'}).
			result.then(() => {}, () => {
				this.user.platformApiUser = false;
				this.ref.markForCheck();
			});
	};

	isInternalMasterAccount = (): boolean => {
		return this.security.getCurrentMasterAccount().adminOrg;
	};

	hasCustomerPermissions = (): boolean => {
		return this.systemPermissionsService.hasCustomerPermissions();
	};

	// LICENSES

	licenseLimitExceeded = (): boolean => {
		return this.userCreateWizardHelperService.licenseLimitExceeded(this.licenseInfo, this.user);
	};

	isNeedToCountLicenses = (): boolean => {
		return this.userCreateWizardHelperService.isNeedToCountLicenses(this.licenseInfo, this.user);
	};

	getLicenseExceededMessage = (): string => {
		return this.licenseService.getLicenseExceededMessage(this.user.licenseTypeId, this.licenseTypes);
	};

	licenseIsMissing = (): boolean => {
		return this.user.licenseTypeId === undefined;
	};
}
