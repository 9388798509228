export enum SupportedLocaleGenerated {
	en_US = 'en-US',
	en_UK = 'en-UK',
	es_EM = 'es-EM',
	es_LAC = 'es-LAC',
	fr_FR = 'fr-FR',
	id_ID = 'id-ID',
	pt_PT = 'pt-PT',
	nl_NL = 'nl-NL',
	de_DE = 'de-DE',
	zht_ZHT = 'zht-ZHT',
	ja_JA = 'ja-JA',
	fi_FI = 'fi-FI',
	it_IT = 'it-IT',
	pt_BR = 'pt-BR',
	sv_SV = 'sv-SV',
	pb_PB = 'pb-PB',
}
