import { DoBootstrap, NgModule } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CxNgInitComponent } from '@app/downgrade/cx-ng-init.component';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { SystemAdministrationModule } from '@app/modules/system-administration/system-administration.module';
import { WidgetSettingsModule } from '@app/modules/widget-settings/widget-settings.module';
import { DocumentExplorerModule } from '@app/modules/document-explorer/document-explorer.module';
import { AccessManagementModule } from '@app/modules/access-management/access-management.module';
import { DashboardModule } from '@app/modules/dashboard/dashboard.module';
import { HeaderModule } from '@app/modules/header/header.module';
import { UserAdministrationModule } from '@app/modules/user-administration/user-administration.module';
import { MetricModule } from '@app/modules/metric/metric.module';
import { ProjectModule } from '@app/modules/project/project.module';
import { ScopeProvider } from '@app/shared/providers/scope-provider';
import { FilterBuilderModule } from '@app/modules/filter-builder/filter-builder.module';
import { DashboardListModule } from './modules/dashboard-list/dashboard-list.module';
import { ConversationModule } from '@app/modules/conversation/conversation.module';
import { InteractionsModule } from './modules/interaction-explorer/interactions.module';
import { ScrollCarouselModule } from '@app/modules/scroll-carousel/scroll-carousel.module';
import { ScorecardModule } from './modules/scorecards/scorecard.module';
import { AlertModule } from './modules/alert/alert.module';
import { NotificationModule } from './modules/notification/notification.module';
import { WidgetContainerModule } from './modules/widget-container/widget-container.module';
import { AccountAdministrationModule } from './modules/account-administration/account-administration.module';
import { DashboardActionsModule } from '@app/modules/dashboard-actions/dashboard-actions.module';
import { HomePageManagementModule } from '@app/modules/home-page/home-page-management/home-page-management.module';
import { UserModule } from '@app/modules/user/user.module';
import { TokensModule } from './modules/tokens/tokens.module';
import { LayoutModule } from './modules/layout/layout.module';
import { QuickInsightsModule } from '@app/modules/home-page/quick-insights/quick-insights.module';
import { HttpClientModule } from '@angular/common/http';
import { UnitsModule } from './modules/units/units.module';
import { SampledAuditsModule } from './modules/sampled-audits/sampled-audits.module';
import { MasterAccountsModule } from './modules/system-administration/master-account/master-accounts.module';
import { SamlSettingsModule } from './modules/system-administration/saml-settings/saml-settings.module';
import { KeyboardNavigationModule } from './modules/keyboard-navigation/keyboard-navigation.module';
import { CaseManagementModule } from './modules/case-management/case-management.module';
import { HomePageWidgetsModule } from '@app/modules/home-page/home-page-widgets/home-page-widgets.module';
import { WidgetVisualizationsModule } from '@app/modules/widget-visualizations/widget-visualizations.module';
import { ArchiveModule } from '@app/modules/archive/archive.module';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { HomePageLayoutModule } from '@app/modules/home-page/home-page-layout/home-page-layout.module';
import { BookModule } from '@app/modules/book/book.module';
import { DowngradeUtilsModule } from '@app/modules/downgrade-utils/downgrade-utils.module';
import { PillsModule } from '@app/modules/pills/pills.module';
import { ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';
import { LocalStorageModule } from 'angular-2-local-storage';
import { LoginModule } from '@app/modules/login/login.module';
import { GridsterModule } from '@app/modules/gridster/gridster.module';
import { FilterModule } from '@app/modules/filter/filter.module';
import { ProfanityModule } from '@app/modules/profanity/profanity.module';
import { MasterAccountsContractsModule } from '@app/modules/master-accounts-contracts/master-accounts-contracts.module';
import { PlotLinesModule } from './modules/plot-lines/plot-line.module';
import { ReportsModule } from '@app/modules/reports/reports.module';
import { SchedulesModule } from './modules/schedules/schedules.module';
import { UserBulkModule } from '@app/modules/user-bulk/user-bulk.module';
import { AssetManagementModule } from './modules/asset-management/asset-management.module';
import { AttributeModule } from './modules/attribute/attribute.module';
import { ModelModule } from './modules/model/model.module';
import { HierarchyModule } from './modules/hierarchy/hierarchy.module';
import { ContextModule } from '@app/modules/context/context.module';
import { DashboardEditModule } from '@app/modules/dashboard-edit/dashboard-edit.module';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { GeoLocationModule } from './modules/geolocation/geolocation.module';
import { AnalyticsModule } from '@app/modules/analytics/analytics.module';
import { DriversModule } from './modules/drivers/drivers.module';
import { EmbedModule } from '@app/modules/embed/embed.module';
import { CalendarModule } from './modules/calendar/calendar-format/calendar.module';
import { UnifiedTemplatesModule } from './modules/unified-templates/unified-templates.module';
import { RedirectModule } from './modules/redirect-intercept/redirect.module';
import { ConsumptionTrackerModule } from './modules/consumption-tracker/consumption-tracker.module';
import { IconModule } from '@app/modules/icon/icon.module';
import { ErrorPageModule } from '@app/modules/error-page/error-page.module';
import {
	AlertSubscriptionTemplateModule
} from '@app/modules/alert-subscription-template/alert-subscription-template.module';
import { DashboardTemplatesModule } from '@app/modules/dashboard-templates/dashboard-templates.module';
import { InlineStylesCSPModule } from './core/CSP/inline-styles-csp.module';
import { DateUtilsModule } from './modules/utils/dates/date-utils.module';
import { FeatureLimiterModule } from '@app/modules/feature-limiter/feature-limiter.module';
import { AllPermissionsService } from './core/authorization/all-permissions.service';

@NgModule({
	imports: [
		UpgradeModule,
		BrowserModule,
		BrowserAnimationsModule,
		CoreModule.forRoot(),
		ContextModule,
		DowngradeUtilsModule,
		SharedModule,
		SystemAdministrationModule,
		AccountAdministrationModule,
		ProjectModule,
		WidgetSettingsModule,
		DashboardActionsModule,
		HeaderModule,
		DashboardModule,
		DashboardEditModule,
		AttributeModule,
		ModelModule,
		HierarchyModule,
		DocumentExplorerModule,
		PillsModule,
		AccessManagementModule,
		AssetManagementModule,
		KeyboardNavigationModule,
		BookModule,
		UserModule,
		UserAdministrationModule,
		UserBulkModule,
		FilterBuilderModule,
		FilterModule,
		MetricModule,
		DashboardListModule,
		ConversationModule,
		ScrollCarouselModule,
		InteractionsModule,
		UnitsModule,
		ScorecardModule,
		AlertModule,
		AlertSubscriptionTemplateModule,
		DashboardTemplatesModule,
		NotificationModule,
		ArchiveModule,
		GridsterModule,
		WidgetContainerModule,
		WidgetVisualizationsModule,
		HomePageManagementModule,
		QuickInsightsModule,
		HomePageWidgetsModule,
		HomePageLayoutModule,
		TokensModule,
		LayoutModule,
		HttpClientModule,
		SampledAuditsModule,
		CaseManagementModule,
		MasterAccountsModule,
		SamlSettingsModule,
		DialogModule,
		LoginModule,
		ProfanityModule,
		MasterAccountsContractsModule,
		PlotLinesModule,
		ReportsModule,
		SchedulesModule,
		HierarchyModule,
		AuthenticationModule,
		DriversModule,
		UnifiedTemplatesModule,
		LocalStorageModule.forRoot({
			prefix: 'cxs',
			storageType: 'localStorage'
		}),
		GeoLocationModule,
		AnalyticsModule,
		EmbedModule,
		CalendarModule,
		RedirectModule,
		ConsumptionTrackerModule,
		IconModule,
		ErrorPageModule,
		InlineStylesCSPModule,
		DateUtilsModule,
		FeatureLimiterModule,
	],
	declarations: [
		CxNgInitComponent,
	],
	providers: [
		{ provide: Window, useValue: window },
		ScopeProvider,
		ToastService,
		AllPermissionsService,
	],
})
export class AppModule implements DoBootstrap {
	constructor(private readonly upgrade: UpgradeModule) { }

	ngDoBootstrap(): void {
		$(document).ready(() => {
			this.upgrade.bootstrap(document.documentElement, ['brooklyn.dashboard']);
		});
	}
}
