import { DashboardEvent, DrillFilterEvent, URLEvent } from '@app/core/cx-event.enum';
import { DashboardChangeAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/dashboard-change-action';
import { WidgetLinkingAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-linking-action';
import { WidgetApiService } from '@app/modules/dashboard-edit/widget-api.service';
import { DashboardRunHelperService } from '@app/modules/dashboard/dashboard-run-helper.service';
import DashboardRunType from '@app/modules/dashboard/dashboard-run-type.enum';
import { IDashboardVersion } from '@app/modules/dashboard/dashboard-versions/dashboard-versions.component';
import { VersionsHeaderService } from '@app/modules/dashboard/dashboard-versions/versions-header.service';
import { DashboardViewService } from '@app/modules/dashboard/dashboard-view/dashboard-view.service';
import { MetadataPreloaderService } from '@app/modules/dashboard/metadata-preloader.service';
import { DashboardUtils } from '@app/modules/dashboard/services/utils/dashboard-utils.class';
import { ReportFiltersService } from '@app/modules/filter/services/report-filters.service';
import { ReportAttributesService } from '@app/modules/project/attribute/report-attributes.service';
import { ReportProjectContextService } from '@app/modules/project/context/report-project-context.service';
import { ReportModelsService } from '@app/modules/project/model/report-models.service';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { UserPropertiesApiService } from '@app/modules/user/user-properties-api.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { Security } from '@cxstudio/auth/security-service';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { CHANGE_MA_STATUS } from '@cxstudio/common/url-service.service';
import { IDashboardFilter } from '@app/modules/dashboard/dashboard-filter';
import { WidgetGridsterSelectors } from '@cxstudio/dashboards/components/widget-gridster-selectors.constant';
import { DashboardRunStatus } from '@cxstudio/dashboards/constants/dashboard-run-status';
import { DashboardFilterTypes } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-types-constant';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { DashboardSaveState } from '@cxstudio/dashboards/dashboard-save-status.service';
import { DashboardProperties } from '@cxstudio/dashboards/entity/dashboard-properties';
import { HeadlessChromeRenderingStatus } from '@cxstudio/dashboards/headless-chrome-render-status';
import { WidgetToolbarConstants } from '@cxstudio/dashboards/widgets-toolbar/add-widget-toolbar-constants.constant';
import { AddWidgetToolbarPosition } from '@cxstudio/dashboards/widgets-toolbar/add-widget-toolbar-position.service';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import Widget, { WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { AdminProjectsService } from '@cxstudio/internal-projects/admin-projects-service.service';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { IMainDashboardCtrlScope } from '@cxstudio/main-dashboard.controller';
import { MAPropertiesService } from '@cxstudio/master-accounts/ma-properties-service.service';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';
import { HierarchyLoadStatus } from '@cxstudio/organizations/hierarchy-load-status';
import { ContentProviderLimiter } from '@cxstudio/reports/content-provider-limiter.service';
import { EnrichmentAttributesService } from '@cxstudio/reports/document-explorer/enrichment-attributes.service';
import { ResponsiveDashboardService } from '@cxstudio/reports/responsiveness/responsive-dashboard-service';
import { DatePeriodUtils } from '@cxstudio/reports/utils/analytic/date-period-utils.service';
import { DashboardDrillService } from '@app/modules/reports/utils/context-menu/drill/drill-options/dashboard-drill.service';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { DashboardScheduleService } from '@app/modules/dashboard/services/scheduling/dashboard-schedule.service';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { DashboardPropsService } from '@cxstudio/services/dashboard-props.service';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { PageTitleUtil } from '@app/core/page-title-util.class';
import { RedirectService } from '@cxstudio/services/redirect-service';
import * as _ from 'underscore';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { MetricComparisonUtils } from '@app/modules/widget-settings/cb-metric/metric-comparison-utils';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';
import { AmplitudeGroupsUtils } from '@app/modules/analytics/amplitude/amplitude-groups-utils';
import { AmplitudeEventUtils } from '@app/modules/analytics/amplitude/amplitude-event-utils';
import { ObjectUtils } from '@app/util/object-utils';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { DashboardExportService } from '@cxstudio/reports/utils/export/dashboard-export-service.service';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { DashboardHistoryApiService } from '@app/modules/dashboard/services/dashboard-history-api-service/dashboard-history-api-service';
import { RouteService } from '@cxstudio/services/route-service';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { IReportModel } from '@app/modules/project/model/report-model';
import { DateRangeUtils } from '@app/modules/utils/dates/date-range-utils.class';
import {
	FullScreenAutoRefreshService
} from '@app/modules/dashboard/services/full-screen-auto-refresh/full-screen-auto-refresh.service';
import { ContentProviderService } from '@cxstudio/reports/utils/content-provider.service';
import { ErrorDialogService } from '@cxstudio/common/cb-error-dialog.service';
import { DashboardFilterSelection } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-selection';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { WidgetVisibilityMonitorService } from '@app/modules/dashboard/widget-visibility-monitor.service';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { FrontlineDashboardUtils } from '@app/modules/dashboard/services/utils/frontline-dashboard-utils';
import { DowngradeToastService } from '@app/modules/downgrade-utils/downgrade-toast.service';
import { AlertLevel } from '@discover/unified-angular-components/dist/unified-angular-components';
import { DashboardSnapshot } from '@cxstudio/dashboards/entity/dashboard-snapshot';
import { WidgetTemplatesService } from '@app/modules/dashboard/widget-templates.service';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';

declare let app: ng.IModule;

interface SaveVersionAsParams {
	dashboardId: number;
	versionId: number;
	saveAsName: string;
	dashboardConfig: DashboardProperties;
}

interface ChangeVersionParams {
	dashboardId: number;
	version: IDashboardVersion;
	saveAndQuit: boolean;
}
interface IDashboardScope extends ISimpleScope, IMainDashboardCtrlScope {
	commentsURL: boolean;
	getFiltersPanelHeight: () => number;
	gridsterPosition: () => any;
	discussionOffset: number;
	delegateWidgetCreation: (report: any) => void;
	getWidgetToolbarClasses: () => string[];
	isExperimentalUI: () => boolean;
	isVersionsHeaderVisible: () => boolean;
	changeDashboardVersion: (dashVersion: ChangeVersionParams) => void;
	loading: {
		create?: boolean;
	};
	saveVersionAs: (dashVersion: SaveVersionAsParams) => void;
	showDashboardDiscussions: boolean;
	availableTemplates: any[];
	templates: any[];
	showFiltersPanel: () => boolean;
	projectTimezone: string; // TODO get rid of this timezone, move into required places
	personalization: any;
	getWidgets: () => Widget[];
	widgetsLoad: {
		promise: ng.IPromise<any>; // loading widgets for selected dashboard
		loaded: boolean;
		lastRequestTimestamp: number;
	};
	dashboardProps: any;
	showFilters: boolean;
	isPdfExport: () => boolean;
}
interface IDashboardRouteParams {
	dashboardId: string;
}

// eslint-disable-next-line prefer-arrow-callback
app.controller('DashboardCtrl', function(
	$scope: IDashboardScope,
	$location: ng.ILocationService,
	$routeParams: IDashboardRouteParams,
	$rootScope: ng.IRootScopeService,
	$timeout: ng.ITimeoutService,
	security: Security,
	locale: ILocale,
	dashboardService: DashboardService,
	dashboardScheduleService: DashboardScheduleService,
	dashboardExportService: DashboardExportService,
	contentProviderService: ContentProviderService,
	maPropertiesService: MAPropertiesService,
	contentProviderLimiter: ContentProviderLimiter,
	dashboardPropsService: DashboardPropsService,
	errorDialogService: ErrorDialogService,
	downgradeToastService: DowngradeToastService,
	cbDialogService: CBDialogService,
	dashboardApiService: DashboardApiService,
	widgetApiService: WidgetApiService,
	$q: ng.IQService,
	dashboardDrill: DashboardDrillService,
	DashboardHistory,
	dashboardHistoryApiService: DashboardHistoryApiService,
	dashboardFiltersService: DashboardFiltersService,
	addWidgetToolbarPosition: AddWidgetToolbarPosition,
	currentWidgets: ICurrentWidgets,
	currentObjects: CurrentObjectsService,
	widgetsEditService: WidgetsEditService,
	dashboardViewService: DashboardViewService,
	reportUtils: ReportUtils,
	metadataPreloaderService: MetadataPreloaderService,
	datePeriodUtils: DatePeriodUtils,
	dashboardSaveState: DashboardSaveState,
	fullscreenAutorefresh: FullScreenAutoRefreshService,
	redirectService: RedirectService,
	routeService: RouteService,
	userPropertiesApiService: UserPropertiesApiService,
	adminProjectsService: AdminProjectsService,
	responsiveDashboardService: ResponsiveDashboardService,
	dashboardRunHelperService: DashboardRunHelperService,
	enrichmentAttributesService: EnrichmentAttributesService,
	reportSettingsService: ReportSettingsService,
	reportProjectContextService: ReportProjectContextService,
	reportAttributesService: ReportAttributesService,
	reportModelsService: ReportModelsService,
	reportFiltersService: ReportFiltersService,
	versionsHeaderService: VersionsHeaderService,
	cachedHttpService: CachedHttpService,
	environmentService: EnvironmentService,
	applicationThemeService: ApplicationThemeService,
	betaFeaturesService: BetaFeaturesService,
	widgetVisibilityMonitor: WidgetVisibilityMonitorService,
	widgetTemplatesService: WidgetTemplatesService
) {
	// wrap initialization functionality in IIFE (or another function) to comply with linting rules
	// disable history/version mode on page load

	versionsHeaderService.reset();
	currentObjects.setSnapshotView(false);

	cachedHttpService.invalidateDashboardCache();


	applicationThemeService.resetDashboardTheme();

	$scope.dashboardProps = dashboardPropsService;

	$scope.widgetsLoad = {
		promise: null, // loading widgets for selected dashboard
		loaded: false,
		lastRequestTimestamp: 0
	};

	if ($location.search().comments) {
		$scope.commentsURL = true;
	}

	if (routeService.isDashboardPreview()) {
		$scope.previewAsMode = true;
	} else {
		dashboardViewService.exitViewAsMode();
		$scope.previewAsMode = false;
	}

	if ($routeParams.dashboardId) {
		contentProviderService.clear();
		metadataPreloaderService.stopPreloading();

		const paramDashboardId = Number.parseInt($routeParams.dashboardId, 10);

		$scope.dashboardContainer.dashboard = null;

		const runType = getDashboardRunType();

		getDashboard(paramDashboardId, runType);

	} else {
		initWidgetTemplates();
	}

	function getDashboard(paramDashboardId, runType): void {
		$scope.dashboardLoad.promise = dashboardApiService.getDashboard(paramDashboardId, true,
			runType, undefined, true).then((response) => {

			const dashboard = response.data;

			// redirection for books
			if (dashboard.type === DashboardType.BOOK) {
				$location.path('/dashboard/' + dashboard.id);
				return;
			}

			if ($location.search().scheduleKey && $location.search().scheduleGroup) {
				let identity = {
					key: $location.search().scheduleKey,
					group: $location.search().scheduleGroup
				};
				dashboardScheduleService.openScheduleDialog($scope.dashboardContainer.dashboard, identity);
			}

			const forcedEditMode = !!$location.search().edit;
			// delay watcher to not trigger on initial change below
			$timeout(() => initializeSnapshotWatcher());

			if (FrontlineDashboardUtils.isFrontlineDashboard(dashboard) && !forcedEditMode) {
				currentObjects.setSnapshotView(true);

				return dashboardApiService.getLatestDashboardSnapshot(dashboard.id)
					.then(snapshot => initializeDashboardSnapshot(dashboard, snapshot));
			}

			return initializeDashboard(dashboard, forcedEditMode);
		}, (response) => {
			const status = response.status;
			if (status === CHANGE_MA_STATUS) { // dashboard from another MA, need to reload
				redirectService.saveCurrentMA({ accountId: response.data }, true);
			} else if (status === 403) {
				redirectService.goToRequestAccess(ObjectType.DASHBOARD, paramDashboardId);
			} else if (status === 404) {
				showDashboardErrorDialog('dashboard.notExist', paramDashboardId);
			}
		});
	}


	function consumeEngageToken(paramDashboardId, runType, engageToken): void {
		let engageJWTPayload = {
			engageToken,
			dashboardId: paramDashboardId
		};
		dashboardApiService.consumeEngageToken(engageJWTPayload).then((tokenResponse) => {
			if (tokenResponse.data.length !== 0) {
				security.setEngageDashboardAccessToken(tokenResponse.data);
			}
			getDashboard(paramDashboardId, runType);
		});
	}

	function initializeDashboard(dashboard: Dashboard, editMode: boolean = false): void {
		$scope.dashboardContainer.dashboard = dashboard;
		$scope.dashboardContainer.dashboardHistory = new DashboardHistory();

		if ($rootScope.appliedFiltersArray)
			$scope.dashboardContainer.dashboard.appliedFiltersArray = $rootScope.appliedFiltersArray;
		$scope.dashboardContainer.dashboardHistory.init($scope.dashboardContainer.dashboard);
		$scope.dashboardContainer.dashboardHistory.applyFilterUpdates($scope.dashboardContainer.dashboard.appliedFiltersArray);
		initWidgetTemplates();

		if (editMode)
			$scope.changeEditMode(true); // init edit mode from url //TODO get rid of this

		PageTitleUtil.setTitle(locale.getString('pageTitle.object', { objectName: dashboard.name }));

		dashboardDrill.handleDrillToDashboard($scope.dashboardContainer.dashboard.id);
		reloadDashboardWithPersonalization(true);
	}

	function initializeDashboardSnapshot(dashboard: Dashboard, snapshot?: DashboardSnapshot): void {
		if (!snapshot) {
			if (!canEditDashboard(dashboard)) {
				downgradeToastService.addToast(
					locale.getString('dashboard.dashboardNoPublishedReport'),
					AlertLevel.WARNING
				);
			}

			currentObjects.setSnapshotView(false);
		}

		if (snapshot) {
			FrontlineDashboardUtils.mergeDashboardSnapshot(dashboard, snapshot);
		}

		initializeDashboard(dashboard);
	}

	function getDashboardRunType(): DashboardRunType {
		// if not dashboard export
		if (!$rootScope.pdfToken) {
			return DashboardRunType.USER;
		}

		if ($rootScope.pdf) {
			if ($rootScope.scheduled) {
				return DashboardRunType.SCHEDULED_PDF;
			} else {
				return DashboardRunType.PDF;
			}
		}

		return DashboardRunType.DASHBOARD_PREVIEW;
	}

	widgetsEditService.initWidgets([], $scope.dashboardContainer.dashboard);

	$scope.getWidgets = () => widgetsEditService.getWidgets();

	(document as any).headlessChromeRenderingStatus = HeadlessChromeRenderingStatus.LOADING;

	loadMAProperties();

	$scope.$on('dashboardLoadingFinishedEvent', () => {
		if ($rootScope.pdfToken) {
			// Perform dashboard export
			// legacy loading indication
			(document as any).headlessChromeRenderingCallback = true;
			// external PDF service loading indication
			(document as any).headlessChromeRenderingStatus = HeadlessChromeRenderingStatus.READY;
		}
		dashboardRunHelperService.setRunStatus($scope.dashboardContainer.dashboard?.id, DashboardRunStatus.COMPLETED);
	});
	initFilters();

	fullscreenAutorefresh.setDashboardIdProvider(() => $scope.dashboardContainer.dashboard.id);

	$scope.$on(URLEvent.SCOPE_LOCATION_CHANGE_START, (event, nextAbsoluteUrl) => {
		let dashboardId = $scope.dashboardContainer.dashboard?.id;
		if (dashboardRunHelperService.dashboardLeaveEvent(nextAbsoluteUrl, dashboardId)) {
			dashboardRunHelperService.setRunStatus(dashboardId, DashboardRunStatus.INTERRUPTED_DEPARTURE);
		}
	});

	function initPersonalization(): ng.IPromise<any> {
		$scope.personalization = dashboardViewService.getPersonalization($scope.dashboardContainer.dashboard, getContainerId());
		dashboardSaveState.personalizationInitializing = true;
		return $scope.personalization.init();
	}

	function getContainerId(): string {
		return $scope.dashboardContainer.dashboard.id + '';
	}

	function initFilters(): void {
		$scope.projectTimezone = null; // local
		updateProjectTimezone();

		$scope.$watch(() => $scope.dashboardContainer.dashboard?.properties?.project, () => {
			if (isProjectSpecified($scope.dashboardContainer.dashboard?.properties)) {
				updateProjectTimezone();
				updateDateRangeFilters();
			}
		});

		function updateProjectTimezone(): void {
			let hasProperties = !!$scope.dashboardContainer?.dashboard?.properties;
			if (!hasProperties || isProjectSpecified($scope.dashboardContainer.dashboard.properties)) {
				$scope.dashboardFilters.projectTimezone = $scope.projectTimezone = null;
				return;
			}

			reportProjectContextService.getDashboardProjectTimezone($scope.dashboardContainer.dashboard).then((timezone) => {
				$scope.dashboardFilters.projectTimezone = $scope.projectTimezone = timezone;
			});
		}

		function updateDateRangeFilters(): void {
			$scope.dashboardFilters.projectDateFilters = {};
			if (!$scope.dashboardContainer || !$scope.dashboardContainer.dashboard || !$scope.dashboardContainer.dashboard.properties) {
				return;
			}

			let dateFilter = dashboardFiltersService.getDashboardDateFilter($scope.dashboardContainer.dashboard.appliedFiltersArray);
			let selectedDateFilter = dateFilter?.selectedAttributeValue?.dateFilterMode;

			reportFiltersService.getDashboardDateFilters($scope.dashboardContainer.dashboard)
				.then((data) => {
					$scope.dashboardFilters.projectDateFilters.dateFilters =
						datePeriodUtils.processDateFilters(data, [selectedDateFilter]);

					if (DateRangeUtils.isCustomDateFilterMode(selectedDateFilter)) {
						let filterId = DateRangeUtils.getCustomDateFilterId(selectedDateFilter);
						let foundDateFilter = _.findWhere(data, {id: filterId});
						if (foundDateFilter) {
							let updates = dateFilter?.selectedAttributeValue;
							(updates as DateFilter).dateDisplayName = foundDateFilter.name;
							$scope.dashboardContainer.dashboardHistory.
								applyFilterUpdates($scope.dashboardContainer.dashboard.appliedFiltersArray);
						}
					}
				});
		}

		function isProjectSpecified(properties: DashboardProperties): boolean {
			if (!properties) return false;

			let projectIdentifier = ProjectIdentifier.fromDashboardProperties(properties);
			return WorkspaceTransitionUtils.isProjectSelected(projectIdentifier);
		}

		function isDashboardFilterApplied(): boolean {
			let isHierarchySelectable = $scope.personalization && $scope.personalization.isHierarchySelectable();
			return $scope.dashboardContainer.dashboard && dashboardFiltersService.canChangeFilters(
				$scope.dashboardContainer.dashboard.appliedFiltersArray,
				isHierarchySelectable
			);
		}

		$scope.showFiltersPanel = () => {
			return isDashboardFilterApplied();
		};

		$scope.$watch(() => $scope.personalization?.hierarchyNodes, checkFilterBarState);

		function checkFilterBarState(): void {
			$scope.showFilters = isDashboardFilterApplied();
			updateFilterButtonState();
		}

		function updateFilterButtonState(): void { // temporary until move actions into dashboard ctrl scope
			$rootScope.$broadcast('filters:visibility', isDashboardFilterApplied(), $scope.showFilters);
		}

		$scope.$watch('editMode', updateFilterButtonState); // temporary until move actions into dashboard ctrl scope
	}

	function showDashboardErrorDialog(msg, dashboardId: number): void {
		errorDialogService.messages.splice(0, errorDialogService.messages.length);
		let dlg = errorDialogService.error(locale.getString(msg));
		dlg.opened.then(() => resetFavoriteDashboardAndRedirect(dashboardId));
	}

	function resetFavoriteDashboardAndRedirect(dashboardId: number): void {
		let favoriteProps = security.getFavoriteProperties();
		let favoriteId = favoriteProps.favoriteDashboard;
		if (favoriteId > -1 && favoriteId === dashboardId) {
			let newProps = angular.copy(favoriteProps);
			// reset user favorite dashboard/book preference
			newProps.favoriteDashboard = -1;
			userPropertiesApiService.updateFavoriteProperties(newProps);
		}
		$location.url($location.path());
		redirectService.goToDashboardList();
	}


	function initWidgetTemplates(): void {
		widgetTemplatesService.getWidgets().then((templates) => {
			$scope.templates = templates;
			refreshAvailableTemplates();
		});
	}

	function refreshAvailableTemplates(): void {
		let dashboard = $scope.dashboardContainer.dashboard;
		let projectId = dashboard?.properties?.project;

		let templates;
		if (InternalProjectTypes.isStudioAdminProject(projectId)) {
			templates = $scope.templates.filter((template) => {
				return template.type === WidgetDisplayType.CONTENT
					|| InternalProjectTypes.isStudioAdminSupported(template.name);
			}).filter((template, index, list) => {
				let previous = index > 0 ? list[index - 1] : undefined;
				return template !== previous;
			});
		} else {
			templates = angular.copy($scope.templates);
		}

		$scope.availableTemplates = templates;
	}

	function loadMAProperties(): void {
		$scope.showDashboardDiscussions = maPropertiesService.isFeatureEnabled(MasterAccountProperty.DASHBOARD_DISCUSSIONS);
		// need to revisit whether 'beta-discussions' global class is required
		$rootScope.showDashboardDiscussions = $scope.showDashboardDiscussions;
	}

	function recalculatePageHeightForExport(exportConfig): void {
		// We should consider height of the pageBreak widget
		let globalHeight = ((exportConfig.dashboardHeight - 1) * exportConfig.pixelPerUnit);
		let amountOfPages = Math.ceil(globalHeight / exportConfig.pageHeight);

		$('.br-global-view').height((amountOfPages * exportConfig.pageHeight) + 'px');
	}

	function setWidgets(widgets: Widget[], fireEvent?: boolean): void {
		widgetVisibilityMonitor.reset();
		DashboardUtils.sortWidgets(widgets);

		widgetsEditService.initWidgets(widgets, $scope.dashboardContainer.dashboard);
		currentWidgets.setWidgets(getContainerId(), widgets, $scope.dashboardContainer.dashboardHistory);
		$scope.widgetsLoad.loaded = true;

		responsiveDashboardService.enterDashboard($scope.dashboardContainer.dashboard.id);

		let exportConfig = dashboardExportService.getExportConfig(widgets);

		if ($rootScope.pdf && exportConfig.pdfPageBreakEnabled) {
			recalculatePageHeightForExport(exportConfig);
		}

		if (fireEvent && $scope.editMode) {
			$scope.$broadcast('dashboardSaveInitialStateEvent');
		}
	}

	function onDashboardHierarchyToggleEvent(event): void {
		reloadPersonalization(false);
	}

	function reloadPersonalization(firstPass?: boolean): ng.IPromise<any> {
		return initPersonalization().then(hierarchyLoadStatus => {
			postReloadPersonalization(hierarchyLoadStatus, firstPass);
		}, hierarchyLoadStatus => {
			postReloadPersonalization(hierarchyLoadStatus, firstPass);
		});
	}

	function postReloadPersonalization(status: HierarchyLoadStatus, firstPass?: boolean): void {
		let denied = false;
		if (HierarchyLoadStatus.FORBIDDEN === status) {
			errorDialogService.error(locale.getString('widget.hierarchyForbidden'));
			denied = true;
		} else if (HierarchyLoadStatus.DEACTIVATED === status) {
			errorDialogService.error(locale.getString('widget.hierarchyDeactivated'));
		} else if (HierarchyLoadStatus.NO_ACCESS === status) {
			errorDialogService.error(locale.getString('widget.hierarchyNoAccess'));
			denied = true;
		} else if (HierarchyLoadStatus.ERROR === status) {
			errorDialogService.error(locale.getString('widget.hierarchyError'));
		}
		$scope.$emit('dashboard.hierarchyAccessUpdate', denied, $scope.widgetsLoad.loaded);

		$scope.dashboardContainer.dashboardHistory.setPersonalization($scope.personalization);
		$scope.dashboardFilters.personalization = $scope.personalization;

		updateWidgets(firstPass);
		dashboardSaveState.personalizationInitializing = false;
	}

	function reloadDashboard(): void {
		let current = $scope.dashboardContainer.dashboard;
		dashboardPropsService.loadDashboard(current);
		if ($scope.projectAttributesFiltered) {
			//clear dashboard attributes
			$scope.projectAttributesFiltered.splice(0);
		}
		if (DashboardUtils.isStudioAdminDashboard(current) || (current.properties && current.properties.project > 0)) {
			reloadDashboardFilterOptions();
		}
	}

	function canEditDashboard(dashboard: Dashboard): boolean {
		return security.has('edit_dashboard') && dashboard.permissions.EDIT;
	}

	function updateWidgets(firstPass?: boolean): void {
		$scope.widgetsLoad.loaded = false;
		widgetsEditService.getWidgets().removeAll();

		$scope.changeEditMode($location.search().edit
			&& canEditDashboard($scope.dashboardContainer.dashboard));

		// update views and recent list
		$scope.dashboardContainer.dashboard.useDate = Date.now();
		$scope.dashboardContainer.dashboard.views = $scope.dashboardContainer.dashboard.views + 1;

		let currentRequest = $scope.widgetsLoad.lastRequestTimestamp = new Date().getTime();
		let dashboardId = $scope.dashboardContainer.dashboard.id;

		widgetsEditService.startSaving();
		$scope.widgetsLoad.promise = PromiseUtils.old(loadWidgets($scope.dashboardContainer.dashboard)).then((widgets: Widget[]) => {
			if (!betaFeaturesService.isFeatureEnabled(BetaFeature.OH_ENRICHMENTS)) {
				MetricComparisonUtils.removeEnrichmentComparisons(widgets);
			}
			if (firstPass) {
				dashboardRunHelperService.markFirstPassWidgets(widgets);
			}
			dashboardRunHelperService.populateDashboardRunTimestamp(widgets);

			if (!$scope.dashboardContainer.dashboard || $scope.dashboardContainer.dashboard.id !== dashboardId) {
				// dashboard was changed, while widgets were loading
				return;
			}

			if (isEmpty(widgets)) {
				$rootScope.$broadcast('dashboardLoadingFinishedEvent');
			} else reportUtils.registerWidgetsRendering(widgets);

			if (currentRequest !== $scope.widgetsLoad.lastRequestTimestamp) { // ignore previous requests
				return;
			}

			setWidgets(widgets.length ? angular.fromJson(widgets as any) : [], true);

			$location.search({});

			AmplitudeAnalyticsService.trackEvent(AmplitudeEvent.DASHBOARD_VIEW,
				AmplitudeGroupsUtils.dashboardGroup(dashboardId),
				AmplitudeEventUtils.dashboardViewData($scope.dashboardContainer.dashboard, widgets, 'dashboard'));
			widgetsEditService.finishSaving();
		}, (response) => {
			let status = response.status;
			if (status === 403) {
				showDashboardErrorDialog('dashboard.noAccess', $scope.dashboardContainer.dashboard.id);
			} else if (status === 404) {
				showDashboardErrorDialog('dashboard.notExist', $scope.dashboardContainer.dashboard.id);
			}
		});
	}

	function loadWidgets(dashboard: Dashboard): Promise<any> {
		const versionId = $rootScope.snapshotId || dashboard.snapshotMetadata?.dashboardVersionId;
		return versionId > 0
			? dashboardHistoryApiService.getWidgets(dashboard.id, versionId)
			: widgetApiService.getDashboardWidgets(dashboard.id);
	}

	function exportWidgets(): void {
		// passing "exporting" object from main controller to avoid alert
		let options = {} as any;
		options.allWidgets = true;
		options.hasChanges = $scope.dashboardContainer.dashboardHistory.isViewFilterChanged();
		dashboardExportService.exportDashboard($scope.dashboardContainer.dashboard.name,
			currentWidgets.getDashboardContainer(getContainerId()),
			$scope.exporting, options);
	}

	function getDashboardViewOptions(): any {
		return angular.extend({
			personalization: $scope.personalization,
			dashboardHistory: $scope.dashboardContainer.dashboardHistory
		}, currentWidgets.getExportParams(getContainerId()));
	}

	function exportDashboardPdfSendByEmail(): void {
		dashboardExportService.exportPDF($scope.dashboardContainer.dashboard, widgetsEditService.getWidgets(), getDashboardViewOptions());
	}

	function exportDashboardPdfDownload(): ng.IPromise<any> {
		let exportConfig = dashboardExportService.getExportConfig(widgetsEditService.getWidgets());
		let hasIntersection = dashboardExportService.checkWidgetsForIntersection(exportConfig, widgetsEditService.getWidgets(), $scope.editMode);

		function performExport(): void {
			$scope.widgetsLoad.promise = dashboardExportService.exportPDFDownload($scope.dashboardContainer.dashboard,
				widgetsEditService.getWidgets(), getDashboardViewOptions());
		}
		if (!hasIntersection) {
			performExport();
			return;
		}

		let confirmation = cbDialogService.confirm(locale.getString('dashboard.exportData'),
			locale.getString('dashboard.hasIntersectionWithPageBreak'),
			locale.getString('common.continue'), locale.getString('common.cancel'));

		return confirmation.result.then(performExport);
	}

	function reloadDashboardWithPersonalization(firstPass?: boolean): void {
		$scope.dashboardLoad.promise = reloadPersonalization(firstPass);
		reloadDashboard();
	}

	function reloadDashboardFilterOptions(): void {
		if (DashboardUtils.isStudioAdminDashboard($scope.dashboardContainer.dashboard)) {
			$scope.projectAttributesFiltered.splice(0);
			addPredefinedFilters();
			let attributes = adminProjectsService.getProjectAttributes(InternalProjectTypes.STUDIO_ADMIN_PROJECT_ID)
				.filter(attr => attr.name !== 'volume');
			$scope.projectAttributesFiltered.push(getProjectAttributesGroup(attributes));
			return;
		}

		let dashboard = $scope.dashboardContainer.dashboard;
		let attributesPromise = PromiseUtils.old(reportAttributesService.getDashboardAttributesOrEmpty(dashboard));
		let modelsPromise = PromiseUtils.old(reportModelsService.getDashboardModels(dashboard));
		let timezonePromise = PromiseUtils.old(reportProjectContextService.getDashboardProjectTimezone(dashboard));
		let settingsPromise = PromiseUtils.old(reportSettingsService.getDashboardProjectSettings(dashboard));
		let filtersPromise = dashboardFiltersService.isSavedFilterApplied(dashboard)
			? PromiseUtils.old(reportFiltersService.getDashboardFiltersWithFolders(dashboard))
			: PromiseUtils.old(Promise.resolve([]));

		$q.all([attributesPromise, timezonePromise, modelsPromise, settingsPromise, filtersPromise])
			.then((responses) => {
				let attributes = responses[0];
				let projectTimezone = responses[1];
				let models = responses[2];
				let settings = responses[3];
				let filters = responses[4] as IFilter[];

				if (settings) {
					attributes.forEach(attribute => {
						attribute.settings = enrichmentAttributesService.getAttributeSettings(attribute, settings);
					});

					// updating filters in dashboard-filter-panel
					let appliedFilters = updateAttributesWithSettings(attributes, $scope.dashboardContainer.dashboardHistory.getAppliedFilters());
					dashboardFiltersService.updateSavedFilterSettings(filters, appliedFilters);
					$scope.dashboardContainer.dashboardHistory.setAppliedFilters(appliedFilters);

					// updating filters in dashboard-filter-edit
					$scope.dashboardContainer.dashboard.appliedFiltersArray =
						dashboardFiltersService.updateSavedFilterSettings(filters, $scope.dashboardContainer.dashboard.appliedFiltersArray);
					$scope.dashboardContainer.dashboard.appliedFiltersArray =
						updateAttributesWithSettings(attributes, $scope.dashboardContainer.dashboard.appliedFiltersArray);
				}

				$scope.dashboardFilters.projectTimezone = $scope.projectTimezone = projectTimezone;
				$scope.projectAttributesFiltered.splice(0);
				addPredefinedFilters();
				getDashboardFilterGroups(attributes, models).forEach((group) => {
					$scope.projectAttributesFiltered.push(group);
				});

			});
	}

	function updateAttributesWithSettings(attributes: IReportAttribute[], appliedFilters: DashboardFilter[]): DashboardFilter[] {
		appliedFilters.forEach(appliedFilter => {
			let selectedAttribute = appliedFilter.selectedAttribute;
			if (selectedAttribute) {
				let attribute: any = _.findWhere(attributes, { id: selectedAttribute.id });
				if (attribute && attribute.settings) {
					selectedAttribute.settings = attribute.settings;
				}
			}
		});
		return appliedFilters;
	}

	function resetFilterList(): void {
		reloadDashboardFilterOptions();
		addPredefinedFilters();
	}

	function addPredefinedFilters(): void {
		//Needed for case of rolling back changes after selecting some predefined filters
		let items = $scope.projectAttributesFiltered;
		items.removeAt(_.findIndex(items, { filterType: DashboardFilterTypes.SAVED_FILTER }));
		items.removeAt(_.findIndex(items, { filterType: DashboardFilterTypes.DATE_RANGE }));

		let savedFiltersGroup = getSavedFiltersGroup();
		if (!hasFilter(DashboardFilterTypes.SAVED_FILTER)) {
			items.insert(0, savedFiltersGroup);
		}

		let dateFilter = getDateFilter();
		if (!hasFilter('dateFilter')) {
			items.insert(0, dateFilter);
		}

		if (DashboardUtils.isStudioAdminDashboard($scope.dashboardContainer.dashboard)) {
			return;
		}

		let textFilter = getTextFilter();
		if (!hasFilter(DashboardFilterTypes.TEXT) && !_.any(items, { filterType: DashboardFilterTypes.TEXT })) {
			items.insert(0, textFilter);
		}
	}

	function getDashboardFilterGroups(attributes: IReportAttribute[], models: IReportModel[]): any[] {
		let result = [];

		let modelsGroup = getProjectModelsGroup(models);
		if (modelsGroup !== null)
			result.push(modelsGroup);

		let attributesGroup = getProjectAttributesGroup(attributes);
		if (attributesGroup !== null)
			result.push(attributesGroup);

		return result;
	}

	function getSavedFiltersGroup(): IDashboardFilter {
		return {
			displayName: locale.getString('filter.savedFilters'),
			name: DashboardFilterTypes.SAVED_FILTER,
			filterType: DashboardFilterTypes.SAVED_FILTER
		};
	}

	function getProjectModelsGroup(models: IReportModel[]): IDashboardFilter {
		if (!models || models.length === 0) return null;

		let modelsChildren = models.map((model) => {
			return {
				id: model.id,
				name: model.name,
				displayName: model.name,
				hide: model.hide,
				filterType: DashboardFilterTypes.TOPIC
			};
		});

		return {
			displayName: locale.getString('widget.topics'),
			name: 'topics',
			children: modelsChildren
		};
	}

	function getTextFilter(): IDashboardFilter {
		return {
			displayName: locale.getString('filter.textFilter'),
			name: DashboardFilterTypes.TEXT,
			filterType: DashboardFilterTypes.TEXT
		};
	}

	function getDateFilter(): IDashboardFilter {
		return {
			displayName: locale.getString('filter.dateRange'),
			name: 'dateFilter',
			filterType: DashboardFilterTypes.DATE_RANGE
		};
	}

	function getProjectAttributesGroup(attributes: IReportAttribute[]): IDashboardFilter {
		if (!attributes || attributes.length === 0) return null;

		let attributesGroupChildren: DashboardFilterSelection[] = [];

		attributes.filter(MetricFilters.NOT_DATE).forEach((attribute) => {
			let dashboardFilterAttribute = attribute as any as DashboardFilterSelection;
			dashboardFilterAttribute.filterType = DashboardFilterTypes.ATTRIBUTE;
			if (!hasFilter(dashboardFilterAttribute.name)) {
				attributesGroupChildren.push(dashboardFilterAttribute);
			}
		});

		return {
			displayName: locale.getString('widget.groupAttributes'),
			name: 'attributes',
			children: attributesGroupChildren
		};
	}

	function hasFilter(filterName): boolean {
		let applied = _.filter($scope.dashboardContainer.dashboard.appliedFiltersArray,
			(filter) => {
				return filter.selectedAttribute && filter.selectedAttribute.name === filterName;
			}
		);
		return applied.length > 0;
	}

	//$scope.$on('dashboardSaveEvent', performFullDashboardSave);
	$scope.$on(DashboardEvent.RELOAD, () => {
		reloadDashboardWithPersonalization();
	});
	$scope.$on('dashboardExportEvent', exportWidgets);
	$scope.$on('dashboardExportPdfSendEvent', exportDashboardPdfSendByEmail);
	$scope.$on('dashboardExportPdfDownloadEvent', exportDashboardPdfDownload);
	$scope.$on('dashboardSaveInitialStateEvent', saveWidgetsInitialState);
	$scope.$on(DashboardEvent.RESET_WIDGETS, resetWidgets);
	$scope.$on('dashboardRefreshEvent', contentProviderLimiter.reset);
	$scope.$on('dashboardHardRefreshEvent', contentProviderLimiter.reset);
	$scope.$on('dashboardFiltersChangedEvent', reloadDashboardFilterOptions);
	$scope.$on('dashboardHierarchyToggleEvent', onDashboardHierarchyToggleEvent);
	$scope.$on('dashboardRollbackFinished', resetFilterList);
	$scope.$on('widgetTemplatesRefreshEvent', refreshAvailableTemplates);
	$scope.$on(DashboardEvent.APPLY_HISTORY_STATE, (event, changes: DashboardChangeAction[]) => {
		let gridsterScope = widgetsEditService.getGridsterScope();
		if (gridsterScope) {
			gridsterScope.gridster.beginBulkUpdate();
		}
		$timeout(() => {
			$scope.widgetsLoad.promise = PromiseUtils.old(
				widgetsEditService.applyDashboardChanges(getContainerId(), ...changes).then(() => {
					if (_.any(changes, change => change instanceof WidgetLinkingAction)) {
						$scope.$broadcast(DrillFilterEvent.DRILL_FILTERS_RESET);
					}
					if (gridsterScope) {
						$timeout(() => {
							gridsterScope.gridster.endBulkUpdate();
							widgetsEditService.updateSelectionProperties();
						});
					}
				}));
		});
	});

	function resetWidgets(event, widgets): void {
		setWidgets(widgets);
	}

	function saveWidgetsInitialState(): void {
		widgetsEditService.startSaving();
		// TODO temp solution, revisit while redesigning UI
		const widgets = ObjectUtils.copy(widgetsEditService.getWidgets());
		if (!$scope.widgetsLoad.loaded)
			return; // widgets are still loading, will save in setWidgets()
		widgetApiService.saveWidgetsTempState($scope.dashboardContainer.dashboard.id, widgets).then((versionId) => {
			widgetsEditService.finishSaving();
			dashboardSaveState.versionId = versionId;
		}, widgetsEditService.finishSaving);
	}

	$scope.saveVersionAs = (dashVersion: SaveVersionAsParams): void => {

		let dashboardConfig = dashVersion.dashboardConfig || {} as DashboardProperties;

		let resource = dashVersion.versionId ? dashboardHistoryApiService.getWidgets(dashVersion.dashboardId, dashVersion.versionId)
			: widgetApiService.getDashboardWidgets(dashVersion.dashboardId);

		$scope.loading = $scope.loading || {};
		$scope.loading.create = true;

		$scope.widgetsLoad.promise = PromiseUtils.old(resource).then((widgets) => {
			let dashboardProperties = $scope.dashboardContainer.dashboard;
			_.extend(dashboardProperties, dashboardConfig);

			dashboardService.saveVersionAs(widgets, dashVersion.saveAsName, dashboardProperties).then((dashboard) => {
				$scope.loading.create = false;
				versionsHeaderService.reset();

				$location.path(`/dashboard/${dashboard.id}`).search('edit', true);
			}, () => {
				$scope.loading.create = false;
			});
		});
	};

	$scope.changeDashboardVersion = (dashVersion: ChangeVersionParams): void => {

		let versionId = dashVersion.version.id;
		let currentRequest = $scope.widgetsLoad.lastRequestTimestamp = new Date().getTime();
		if (dashVersion.saveAndQuit) {
			$scope.dismissAutoSaved();
			dashboardHistoryApiService.revertWidgets(dashVersion.dashboardId, versionId).then((widgets) => {
				if (currentRequest !== $scope.widgetsLoad.lastRequestTimestamp)
					return;
				$scope.dashboardContainer.dashboard.appliedFiltersArray =
					dashVersion.version.dashboard && dashVersion.version.dashboard.appliedFiltersArray;
				$scope.dashboardContainer.dashboardHistory.saveFilterUpdates($scope.dashboardContainer.dashboard.appliedFiltersArray);
				setWidgets(widgets as Widget[]);
				versionsHeaderService.disable();
			});
		} else {
			let resource = versionId ? dashboardHistoryApiService.getWidgets(dashVersion.dashboardId, versionId)
				: widgetApiService.getDashboardWidgets(dashVersion.dashboardId);
			versionsHeaderService.setDirty(versionId > 0);

			$scope.widgetsLoad.promise = PromiseUtils.old(resource).then((widgets) => {

				if (currentRequest !== $scope.widgetsLoad.lastRequestTimestamp)
					return;
				$scope.dashboardContainer.dashboard.appliedFiltersArray =
					dashVersion.version.dashboard && dashVersion.version.dashboard.appliedFiltersArray;
				$scope.dashboardContainer.dashboardHistory.setAppliedFilters($scope.dashboardContainer.dashboard.appliedFiltersArray);
				setWidgets(widgets as Widget[]);
			});
		}
	};

	$scope.$watch('editMode', (editMode) => {
		if (editMode === undefined || editMode === false) {
			if ($scope.dashboardContainer.dashboard) {
				PageTitleUtil.setTitle(locale.getString('pageTitle.object',
					{ objectName: $scope.dashboardContainer.dashboard.name }));
			}
		}
	});

	function initializeSnapshotWatcher(): void {
		$scope.$watch(() => currentObjects.isSnapshotView(), (currentIsSnapshotView: boolean, previousIsSnapshotView: boolean) => {
			if (currentIsSnapshotView === previousIsSnapshotView) {
				return;
			}

			const dashboardId = Number.parseInt($routeParams.dashboardId, 10);

			$scope.dashboardLoad.promise = dashboardApiService.getDashboard(dashboardId).then((response) => {
				const dashboard = response.data;

				dashboardApiService.getLatestDashboardSnapshot(dashboard.id).then(snapshot => {
					if (currentIsSnapshotView) {
						initializeDashboardSnapshot(dashboard, snapshot);

						return;
					}

					if (snapshot) {
						FrontlineDashboardUtils.addSnapshotMetadata(dashboard, snapshot);
					}

					initializeDashboard(dashboard);
				});
			});
		});
	}

	/**
	 * Widget dynamic toolbar
	 */
	$scope.getWidgetToolbarClasses = () => {
		return addWidgetToolbarPosition.getClasses();
	};


	$scope.delegateWidgetCreation = (report) => {
		$scope.$broadcast('addNewWidget', report);
	};

	$scope.$on('$destroy', () => {
		applicationThemeService.resetDashboardTheme();
		fullscreenAutorefresh.destroy();
		currentWidgets.clear();
		contentProviderLimiter.reset();
		widgetVisibilityMonitor.disconnect();
		$scope.dismissAutoSaved();
	});

	$scope.gridsterPosition = () => {
		let top;
		let bottom;
		let left;
		let right;

		if ($scope.editMode && !$scope.widgetToolbarHidden && !$scope.hideWidgetToolbarOnZoom()) {
			top = $scope.getFiltersPanelHeight();
			if (addWidgetToolbarPosition.isTop()) {
				top += WidgetToolbarConstants.HORIZONTAL_TOOLBAR_HEIGHT;
			}
			if (addWidgetToolbarPosition.isBottom()) {
				bottom = WidgetToolbarConstants.HORIZONTAL_TOOLBAR_HEIGHT;
			}
			if (addWidgetToolbarPosition.isLeft()) {
				left = WidgetToolbarConstants.VERTICAL_TOOLBAR_WIDTH;
			}
			if (addWidgetToolbarPosition.isRight()) {
				right = WidgetToolbarConstants.VERTICAL_TOOLBAR_WIDTH;
			}
		} else {
			return { top: $scope.getFiltersPanelHeight() + 'px' };
		}

		return { top, bottom, left, right };

	};

	$scope.getFiltersPanelHeight = () => {
		let filtersPanel: HTMLElement = document.querySelector(WidgetGridsterSelectors.FILTER_BAR_SELECTOR);
		if (!filtersPanel)
			return 0;
		return $(filtersPanel).hasClass('ng-hide') ? 0 : WidgetToolbarConstants.FILTER_BAR_HEIGHT;
	};

	$scope.isExperimentalUI = () => security.getContext()?.experimentalUI;

	$scope.isVersionsHeaderVisible = () => {
		return versionsHeaderService.isEnabled();
	};

	$scope.isPdfExport = () => {
		return environmentService.isPdfExport();
	};
});
