import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { EsQueryService } from '@app/modules/filter-builder/es-query/es-query.service';
import { ObjectUtils } from '@app/util/object-utils';
import { ScorecardMetric } from '@cxstudio/projects/scorecards/entities/scorecard-metric';
import { FilterEmptyObject } from '@cxstudio/report-filters/constants/filter-empty-object.constant';
import { IFilterMatchMode, FilterRuleMode } from '@cxstudio/report-filters/constants/filter-match-modes.service';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { FilterMatchModeValue } from '@cxstudio/reports/entities/filter-match-mode-value';
import { ClarabridgeMetricName } from '@cxstudio/reports/providers/cb/constants/clarabridge-metrics-names';
import { FilterAttributeTypes } from '@cxstudio/report-filters/constants/filter-attribute-types.constant';
import { ReportAsset } from '@cxstudio/reports/entities/report-asset';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';

type IFilterRuleSelection = IFilterRule; // need a deeper look to understand what type is it, for now using IFilterRule
type FilterRuleConverter = (item: IFilterRuleSelection,
	priorRule?: IFilterRule, matchMode?: IFilterMatchMode) => IFilterRule;

@Injectable({
	providedIn: 'root'
})
export class FilterRuleService {

	private readonly DATE_RANGE_FIELDS = ['fromMode', 'fromDate', 'fromAmount', 'fromPeriod', 'toMode', 'toDate', 'toAmount', 'toPeriod'];

	private readonly filterDefinitions: {[key in FilterRuleType]?: FilterRuleConverter} = {};


	constructor(
		private locale: CxLocaleService,
		private esQueryService: EsQueryService,
		@Inject('metricConstants') private readonly metricConstants: MetricConstants,
	) {
		this.filterDefinitions[FilterRuleType.topicEquality] = (topic, priorRule, matchMode) => {
			return {
				type: FilterRuleType.topicEquality,
				topicId: topic ? parseInt(topic.name, 10) : priorRule.topicId,
				rootNodeId: topic ? (topic as any).rootNodeId : priorRule.rootNodeId,
				displayName: topic ? topic.displayName : priorRule.displayName,
				modelMatch: priorRule.modelMatch,
				matchMode: priorRule && priorRule.matchMode
					? priorRule.matchMode
					: FilterMatchModeValue.IS,
				values: this.getTopicValues(priorRule, topic, matchMode)
			};
		};

		this.filterDefinitions[FilterRuleType.numericRange] = (attribute, priorRule) => {
			return {
				type: FilterRuleType.numericRange,
				existMatch: priorRule.existMatch,
				attributeName: attribute ? attribute.name : priorRule.attributeName,
				displayName: attribute ? attribute.displayName : priorRule.displayName,
				matchMode: priorRule && priorRule.matchMode
					? priorRule.matchMode
					: FilterMatchModeValue.IS,
				from: priorRule ? priorRule.from : undefined,
				to: priorRule ? priorRule.to : undefined
			};
		};

		this.filterDefinitions[FilterRuleType.numericOpenRange] = (attribute, priorRule) => {
			return {
				type: FilterRuleType.numericOpenRange,
				existMatch: priorRule.existMatch,
				attributeName: attribute ? attribute.name:priorRule.attributeName,
				displayName: attribute ? attribute.displayName:priorRule.displayName,
				matchMode: priorRule && priorRule.matchMode
					? priorRule.matchMode
					: FilterMatchModeValue.IS,
				value: priorRule?priorRule.value:undefined
			};
		};

		this.filterDefinitions[FilterRuleType.numericEquality] = (attribute, priorRule) => {
			return {
				type: FilterRuleType.numericEquality,
				existMatch: priorRule.existMatch,
				attributeName: attribute ? attribute.name:priorRule.attributeName,
				displayName: attribute ? attribute.displayName:priorRule.displayName,
				matchMode: priorRule && priorRule.matchMode
					? priorRule.matchMode
					: FilterMatchModeValue.IS,
				value: priorRule ? priorRule.value: undefined,
				values: priorRule ? priorRule.values: []
			};
		};

		this.filterDefinitions[FilterRuleType.stringEquality] = (attribute, priorRule) => {
			return {
				type: FilterRuleType.stringEquality,
				existMatch: attribute && attribute.name === ClarabridgeMetricName.WORDS_MTOKEN ? undefined : priorRule.existMatch,
				attributeName: attribute ? attribute.name : priorRule.attributeName,
				displayName: attribute ? attribute.displayName : priorRule.displayName,
				matchMode: priorRule && priorRule.matchMode
					? priorRule.matchMode
					: FilterMatchModeValue.IS,
				values: priorRule ? priorRule.values : []
			};
		};

		this.filterDefinitions[FilterRuleType.stringEqualityLC] = (attribute, priorRule) => {
			return {
				type: FilterRuleType.stringEqualityLC,
				attributeName: attribute ? attribute.name : priorRule.attributeName,
				displayName: attribute ? attribute.displayName : priorRule.displayName,
				matchMode: priorRule && priorRule.matchMode
					? priorRule.matchMode
					: FilterMatchModeValue.IS,
				values: priorRule ? priorRule.values : []
			};
		};

		this.filterDefinitions[FilterRuleType.esQueryRule] = (attribute, priorRule) => {
			return {
				type: FilterRuleType.esQueryRule,
				name: attribute.name,
				displayName: attribute ? attribute.displayName : priorRule.displayName,
				esQueryObject: this.esQueryService.generateEmptyEsQueryObject(),
				matchMode: priorRule && priorRule.matchMode
					? priorRule.matchMode
					: FilterMatchModeValue.IS,
				values: priorRule ? priorRule.values : []
			};
		};

		this.filterDefinitions[FilterRuleType.text] = (attribute) => {
			return {
				type: FilterRuleType.text,
				name: attribute.name,
				displayName: this.locale.getString('filter.textFilter'),
				matchMode: FilterMatchModeValue.IS,
				value: undefined
			};
		};

		this.filterDefinitions[FilterRuleType.dateRange] = (attribute, priorRule) => {
			let result = {
				type: FilterRuleType.dateRange,
				name: attribute ? attribute.name : priorRule.name,
				displayName: attribute ? attribute.displayName : priorRule.displayName,
				dateFilterMode: DateFilterMode.CUSTOM
			};
			return _.extend(result, _.pick(priorRule, this.DATE_RANGE_FIELDS));
		};

		this.filterDefinitions[FilterRuleType.savedFilter] = (attribute, priorRule) => {
			return {
				type: FilterRuleType.savedFilter,
				displayName: attribute ? attribute.displayName: priorRule.displayName,
				name: attribute ? attribute.name: priorRule.name,
				filterType: attribute.filterType,
				filterId: (attribute as IFilter).id
			};
		};

		this.filterDefinitions[FilterRuleType.scorecardFilter] = (attribute) => {
			return {
				type: FilterRuleType.scorecardFilter,
				displayName: attribute.displayName,
				name: attribute.name,
				filterType: attribute.filterType,
				uniqueId: (attribute as ScorecardMetric).id,
				scorecardId: attribute.scorecardId,
				passing: attribute.passing
			};
		};

		this.filterDefinitions[FilterRuleType.predefined] = (attribute, priorRule) => {
			return attribute ? ObjectUtils.copy(attribute) : ObjectUtils.copy(priorRule);
		};

		this.filterDefinitions[FilterRuleType.and] = (attribute, priorRule) => {
			return {
				type: FilterRuleType.and,
				filterRules: [priorRule, ObjectUtils.copy(FilterEmptyObject.RULE)]
			};
		};

		this.filterDefinitions[FilterRuleType.or] = (attribute, priorRule) => {
			return {
				type: FilterRuleType.or,
				filterRules: [priorRule, ObjectUtils.copy(FilterEmptyObject.RULE)]
			};
		};

		this.filterDefinitions[FilterRuleType.empty] = (attribute, priorRule) => {
			return {
				type: FilterRuleType.empty,
				displayName: attribute.displayName
			};
		};
	}

	private getTopicValues(priorRule: IFilterRule, topic: IFilterRuleSelection, matchMode: IFilterMatchMode) {
		let rootNodeId;
		if (matchMode && matchMode.modelMatch) {
			//existing topic missing rootNodeId, and assume is modelId + 1,
			//if get non exist node error, user need to reselect topic from dropdown and save to correct it
			//CSI-3256
			rootNodeId = priorRule.rootNodeId || (priorRule.topicId + 1);
			return [{idPath: rootNodeId + '', nodeId: rootNodeId}];
		}
		if (priorRule && priorRule.modelMatch && topic) {
			rootNodeId = topic.rootNodeId;
			return [{idPath: rootNodeId + '', nodeId: rootNodeId}];
		}
		return priorRule ? priorRule.values : [];
	}

	getRuleObject(type: FilterRuleType, item: IFilterRuleSelection,
		priorRule?: IFilterRule, matchMode?: IFilterMatchMode): IFilterRule {
		return this.filterDefinitions[type](item, priorRule, matchMode);
	}

	generateNewRule(item: ReportAsset | IFilterRule, priorRule?: IFilterRule,
		matchMode?: IFilterMatchMode): IFilterRule {
		if (item) {
			let type: FilterRuleType;
			if ((item as ReportAsset).metricType === AnalyticMetricType.CLARABRIDGE) {
				if (item.type === ReportAssetType.SYS) {
					if (this.metricConstants.isLCAttribute(item.name)) {
						type = FilterRuleType.stringEqualityLC;
					} else if (this.metricConstants.isESQueryAttribute(item.name)) {
						type = FilterRuleType.esQueryRule;
					} else {
						type = FilterRuleType.stringEquality;
					}
				} else if (item.type === ReportAssetType.TOPICS || item.type === ReportAssetType.TOPIC_LEAF) {
					type = FilterRuleType.topicEquality;
				} else {
					type = FilterRuleType.stringEquality;
				}
			} else if (item.type === FilterRuleType.dateRange
					|| item.type === FilterRuleType.savedFilter
					|| item.type === FilterRuleType.scorecardFilter
					|| item.type === FilterRuleType.predefined
					|| item.type === FilterRuleType.text
					|| item.type === FilterRuleType.empty) {
				type = item.type;
			} else {
				type = this.checkRuleTypeByAttr(item.type as any, priorRule.isThreshold);
			}
			return this.getRuleObject(type, item as IFilterRule, priorRule, matchMode);
		}

		if (matchMode && priorRule) {
			priorRule.matchMode = matchMode.apiValue;
			if (priorRule.modelMatch || priorRule.existMatch || matchMode.existMatch) {
				priorRule.values = [];
			}
			priorRule.modelMatch = matchMode.modelMatch;
			priorRule.existMatch = matchMode.existMatch;
			let type = priorRule.type === FilterRuleType.topicEquality
				? FilterRuleType.topicEquality
				: this.checkRuleTypeByMode(matchMode, priorRule);
			return this.getRuleObject(type, undefined, priorRule, matchMode);
		}

		return;
	}

	private checkRuleTypeByAttr(type: FilterAttributeTypes, isThreshold: boolean) {
		if (isThreshold) {
			return FilterRuleType.numericOpenRange;
		}

		if (type === FilterAttributeTypes.NUMBER) {
			return FilterRuleType.numericEquality;
		}

		if (type === FilterAttributeTypes.DATE) {
			return FilterRuleType.dateRange;
		}

		return FilterRuleType.stringEquality;
	}

	private checkRuleTypeByMode(matchMode: IFilterMatchMode, priorRule: IFilterRule) {
		if (priorRule && priorRule.type) {
			if (priorRule.type === FilterRuleType.stringEquality) {
				return FilterRuleType.stringEquality;
			} else if (priorRule.type === FilterRuleType.stringEqualityLC) {
				return FilterRuleType.stringEqualityLC;
			} else if (matchMode.mode === FilterRuleMode.EQUAL) {
				return FilterRuleType.numericEquality;
			} else if (matchMode.mode === FilterRuleMode.RANGE) {
				return FilterRuleType.numericRange;
			} else if (matchMode.mode === FilterRuleMode.OPEN_RANGE) {
				return FilterRuleType.numericOpenRange;
			}
		}
		return;
	}

}

app.service('filterRuleService', downgradeInjectable(FilterRuleService));
