import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { ComparisonConfigurationModalComponent, IComparisonConfigurationInput }
	from '@app/modules/widget-settings/cb-metric/comparison-configuration-modal/comparison-configuration-modal.component';
import { MetricComparisonUtils } from '@app/modules/widget-settings/cb-metric/metric-comparison-utils';
import { SearchListEvent } from '@app/shared/components/forms/lists/search-list.component';
import { ChangeUtils } from '@app/util/change-utils';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { IDateRange } from '@cxstudio/reports/entities/date-period';
import { ComparisonDateRange, ComparisonValue, HierarchyEnrichmentValue, MetricComparisonType, MetricWidgetComparison } from '@cxstudio/reports/entities/metric-widget-properties';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import * as cloneDeep from 'lodash.clonedeep';

export interface IComparisonListItem {
	type: MetricComparisonType;
	displayName: string;
	disabled?: boolean;
}

@Component({
	selector: 'comparison-item',
	templateUrl: './comparison-item.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComparisonItemComponent implements OnInit, OnChanges {

	@Input() comparison: MetricWidgetComparison;
	@Output() comparisonChange = new EventEmitter<MetricWidgetComparison>();
	@Output() update = new EventEmitter<void>();

	@Input() options: IComparisonListItem[];
	@Input() primaryDateFilterMode: DateFilterMode;
	@Input() projectTimezone: string;
	@Input() metric: ReportCalculation;
	@Input() metrics: Metric[];
	@Input() dateFilters: any[];
	@Input() hierarchyEnrichments: HierarchyEnrichmentValue[];

	periodOptions = {
		historic: {},
		dateFilters: [],
	};

	dateFilterRange: IDateRange;

	constructor(
		private readonly cxDialogService: CxDialogService,
	) { }

	ngOnInit(): void {
		this.periodOptions.dateFilters = this.dateFilters;
		this.dateFilterRange = {
			from: this.comparison.value.from,
			to: this.comparison.value.to,
		};
	}

	ngOnChanges(change: SimpleChanges) {
		if (ChangeUtils.hasChange(change.dateFilters)) {
			this.periodOptions.dateFilters = change.dateFilters.currentValue;
		}
	}

	onChange(): void {
		this.update.emit();
	}

	onComparisonChanged(item: IComparisonListItem) {
		if (this.comparison.type !== item.type) {
			this.comparisonChange.emit(MetricComparisonUtils.getComparisonDefaults(item.type, this.hierarchyEnrichments));
		}
	}

	selectComparisonDateFilter = (dateFilterMode: DateFilterMode,
		dateFilterRange: IDateRange): void => {
		const dateFilter = this.comparison.value as ComparisonDateRange;
		dateFilter.dateFilterMode = dateFilterMode;
		dateFilter.from =  dateFilterRange.from;
		dateFilter.to = dateFilterRange.to;

		if (dateFilterMode === DateFilterMode.CUSTOM) {
			this.onChange();
		}
	};

	selectComparisonEnrichment = (event: SearchListEvent<HierarchyEnrichmentValue>) => {
		this.comparison.value = event.node as ComparisonValue;
		this.onChange();
	};

	openComparisonConfig = () => {
		this.configureComparison(this.comparison).then((updatedProps) => {
			_.extend(this.comparison, updatedProps);
			this.onChange();
		}, _.noop);
	};

	private configureComparison = (comparison: MetricWidgetComparison): Promise<MetricWidgetComparison> => {
		let comparisonOption = _.findWhere(this.options, {type: comparison.type});
		let input: IComparisonConfigurationInput = {
			comparison: cloneDeep(comparison),
			displayName: comparisonOption.displayName || '',
			metric: this.metric,
			metrics: this.metrics,
		};
		return this.cxDialogService.openDialog(ComparisonConfigurationModalComponent, input, {size: ModalSize.SMALL}).result;
	};

}
