import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { DocumentExplorerPagination } from '@app/modules/widget-visualizations/report-pagination.service';
import { Security } from '@cxstudio/auth/security-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget, { WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import { IFilterRule, IFilterRuleValue } from '@cxstudio/reports/entities/adhoc-filter.class';
import { FilterMatchModeValue } from '@cxstudio/reports/entities/filter-match-mode-value';
import { PreviewWidget } from '@cxstudio/reports/entities/preview-widget.class';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { PreviewMode } from '../entities/preview-mode';
import VisualProperties from '../entities/visual-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { PreviewService } from '../preview/preview-service';

export class DocExplorerHelperService {
	constructor(
		private $timeout: ng.ITimeoutService,
		private locale: ILocale,
		private previewService: PreviewService,
		private environmentService: EnvironmentService,
		private security: Security,
		private readonly downgradeDialogService: DowngradeDialogService
	) {}

	initPaginationOptions = (): void => {
		this.$timeout(() => {
			$('li.pagination-last').addClass('disabled')
				.attr('title', this.locale.getString('docExplorer.lastPageTooltip'))
				.css('display', 'inline-block');
			$('li.pagination-last>a').attr('disabled', 'disabled');
		});
	};

	isRefreshPaginationOptions = (documentManager: IDocumentPreviewerControls): boolean => {
		return documentManager.totalItems >= 10000 && documentManager.isDocExplorer;
	};

	generateSettingsAndOpenDocExplorer = (
		documentDescriptor: any, documentIdFilterValues: IFilterRuleValue[], paging?: DocumentExplorerPagination, dashboard?: Dashboard,
		encodedDescriptor?: string, selectedSentence?: number): IModalInstanceService => {
		let widgetSettings: Widget = this.previewService.createDocumentExplorerSettings();
		widgetSettings.selectivePreview = true;
		widgetSettings.properties.encodedDescriptor = encodedDescriptor;

		widgetSettings.properties.contentProviderId = documentDescriptor.contentProviderId;
		widgetSettings.properties.accountId = documentDescriptor.accountId;
		widgetSettings.properties.project = documentDescriptor.projectId;
		widgetSettings.properties.initialSentence = selectedSentence;

		widgetSettings.properties.adhocFilter = {
			type: FilterTypes.AND,
			filterRules: [{
				attributeDisplayName: 'Document Id', // redundant?
				attributeName: '_id_document',
				displayName: 'Document Id',
				type: FilterRuleType.stringEquality,
				matchMode: FilterMatchModeValue.IS,
				values: documentIdFilterValues
			} as IFilterRule]
		};


		if (selectedSentence) {
			widgetSettings.properties.adhocFilter.filterRules.push({
				displayName: this.locale.getString('preview.selectedSentence'),
				type: FilterRuleType.raw,
				query: `_id_sentence:(${selectedSentence})`
			} as any);
		}

		return this.openDocExplorer(widgetSettings, dashboard, paging, documentDescriptor.scorecardId);
	};

	openDocExplorer = (widgetSettings: PreviewWidget, dashboard?: Dashboard, paging?: DocumentExplorerPagination,
		selectedScorecard?: number): IModalInstanceService => {
		let fullscreen = this.environmentService.isIframe();
		return this.downgradeDialogService.openDocumentExplorerModal({
			widget: widgetSettings,
			dashboard,
			paging,
			selectedScorecard
		}, fullscreen) as any as IModalInstanceService; // we only need "close", which is supported by both modals
	};

	createDocumentExplorerSettings = (): Widget => {
		const widget = {} as Widget;
		widget.name = WidgetType.PREVIEW;
		widget.properties = {};
		widget.parentWidget = {} as Widget;
		widget.properties.runAs = this.security.getEmail();
		widget.properties.page = {lookAheadLimit: 100, start: 0};
		widget.properties.itemsPerPage = 20;
		widget.properties.widgetType = WidgetType.PREVIEW;
		widget.properties.previewMode = PreviewMode.DOCUMENT;
		widget.properties.documentExplorer = true;
		widget.type = WidgetDisplayType.CB;
		widget.properties.snippets = false;
		widget.visualProperties = {} as VisualProperties;
		widget.documentExplorerName = this.locale.getString('widget.docExplorer');
		return widget;
	};
}

app.service('docExplorerHelperService', DocExplorerHelperService);
