import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CxLocaleService } from '@app/core';
import { ReportableHierarchy } from '@app/modules/hierarchy/enrichment/reportable-hierarchy';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { HtmlUtils } from '@app/shared/util/html-utils.class';
import { Label } from '@cxstudio/dashboards/entity/label';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import { ScorecardMetric } from '@cxstudio/projects/scorecards/entities/scorecard-metric';
import { CustomMathAssets } from '../adapter/custom-math-assets';
import { SupportTextErrors, TextToken, TextTokenType } from '../adapter/formula-segment';
import { CustomMathErrorType, CustomMathWarning, CustomMathWarningType } from '../tokenizer/custom-math-error';
import { TooltipPosition, TooltipType } from './token-tooltip';
import { CustomMathUtils } from '@app/modules/metric/definition/custom-math/editor/custom-math-utils.service';


@Injectable({
	providedIn: 'root'
})
export class CustomMathTooltipsService {
	static MATH_EDITOR_CONTENT_WRAPPER_ID: string = 'editor-content-wrapper';
	static TOOLTIP_TEXT_ATTRIBUTE = 'data-tooltip';

	static readonly TEXT_HEIGHT: number = 16;
	static readonly INDENTATION: number  = 4;

	constructor(
		private locale: CxLocaleService,
		private sanitizer: DomSanitizer
	) {}

	// 1. ADD MOUSE EVENTS AND Z-INDEX TO SPANS

	updateTooltipElements(onTooltipSpanMouseenter: (event: any) => void, onTooltipSpanMouseleave: (event: any) => void): void {
		let infoTextTokens: JQuery = $(`#${CustomMathTooltipsService.MATH_EDITOR_CONTENT_WRAPPER_ID} .${TooltipType.INFO}`);
		let warningTextTokens: JQuery = $(`#${CustomMathTooltipsService.MATH_EDITOR_CONTENT_WRAPPER_ID} .${TooltipType.WARNING}`);
		let errorTextTokensAndSegments: JQuery = $(`#${CustomMathTooltipsService.MATH_EDITOR_CONTENT_WRAPPER_ID} .${TooltipType.ERROR}`);

		let allElementsWithTooltips: HTMLElement[] = []
			.concat(this.getElementsArray(infoTextTokens))
			.concat(this.getElementsArray(warningTextTokens))
			.concat(this.getElementsArray(errorTextTokensAndSegments));
		if (allElementsWithTooltips.length) {
			allElementsWithTooltips.forEach(elementWithTooltip => {
				elementWithTooltip.onmouseenter = onTooltipSpanMouseenter;
				elementWithTooltip.onmouseleave = onTooltipSpanMouseleave;

				if (this.isSegment(elementWithTooltip)) {
					$(elementWithTooltip.children[0]).css('z-index', 1);
				} else {
					$(elementWithTooltip).css('z-index', 1);
				}
			});
		}
	}

	private getElementsArray(elements: JQuery): HTMLElement[] {
		return elements.length > 0 ? (elements.toArray() as HTMLElement[]) : [];
	}

	private isSegment(textSpan: HTMLElement): boolean {
		return textSpan.parentElement.id === CustomMathTooltipsService.MATH_EDITOR_CONTENT_WRAPPER_ID;
	}

	// 2 INFO TOOLTIPS CONTENT

	getInfoTooltipContent(classList: DOMTokenList, displayName: string, assets: CustomMathAssets): SafeHtml {
		let tooltipContent: SafeHtml;
		if (this.isAttributeToken(classList)) {
			tooltipContent = this.getAttributeTooltipContent(assets.attributes, displayName, classList);
		} else if (this.isSystemMetricToken(classList)) {
			tooltipContent = this.getSystemMetricTooltipContent(assets.standardMetrics, assets.predefinedMetrics, displayName);
		} else if (this.isScorecardMetricToken(classList)) {
			tooltipContent =
			this.getScorecardMetricTooltipContent(assets.scorecardMetrics, assets.scorecards, displayName);
		} else if (this.isHierarchyMetricTooltipText(classList)) {
			tooltipContent = this.getHierarchyMetricTooltipContent(assets.hierarchies, displayName);
		} else if (this.isCustomMetricToken(classList)) {
			tooltipContent = this.getCustomMetricTooltipContent(assets.metrics, displayName);
		}
		return tooltipContent;
	}

	//  2.1 INFO ATTRIBUTE TOOLTIP

	private getAttributeTooltipContent(attributes: IReportAttribute[], displayName: string, classList: DOMTokenList): SafeHtml {
		let attribute: IReportAttribute = _.findWhere(attributes, {displayName});
		let databaseName: string = attribute.name;
		let dataType: string = classList.contains('attribute-number') ? 'Number' : 'Text';
		return this.buildAttributeTooltipContent(databaseName, dataType);
	}

	private buildAttributeTooltipContent(databaseName: string, dataType: string): SafeHtml {
		let tooltip: string = this.buildFirstLine('metrics.attribute');
		tooltip += this.buildNextLine('metrics.databaseName', databaseName);
		tooltip += this.buildNextLine('metrics.dataType', dataType);
		return this.sanitizer.bypassSecurityTrustHtml(tooltip);
	}

	// 2.2 INFO SYSTEM METRIC TOOLTIP

	private getSystemMetricTooltipContent(standardMetric: Metric[], predefinedMetrics: Metric[], displayName: string): SafeHtml {
		let systemMetric: Metric = _.findWhere(standardMetric, {displayName}) || _.findWhere(predefinedMetrics, {displayName});
		return this.buildSystemMetricTooltipContent(systemMetric.name);
	}

	private buildSystemMetricTooltipContent(metricName: string): SafeHtml {
		let tooltip: string = this.buildFirstLine('metrics.systemMetric');
		tooltip += this.buildSimpleLine(metricName);
		return this.sanitizer.bypassSecurityTrustHtml(tooltip);
	}

	// 2.3 INFO SCORECARD METRIC TOOLTIP

	private getScorecardMetricTooltipContent(scorecardMetrics: ScorecardMetric[], scorecards: Scorecard[], displayName: string): SafeHtml {
		let scorecardMetric: ScorecardMetric = _.findWhere(scorecardMetrics, {displayName});
		let scorecard: Scorecard = _.findWhere(scorecards, {id: scorecardMetric.scorecardId});
		return this.buildScorecardMetricTooltipContent(scorecard, scorecardMetric.nodeFullPath);
	}

	private buildScorecardMetricTooltipContent(scorecard: Scorecard, nodeFullPath: string): SafeHtml {
		let tooltip: string = this.buildFirstLine('metrics.scorecardMetric');
		tooltip += this.buildNextLine('metrics.rubric', scorecard.name);
		tooltip += this.buildNextLine('common.model', scorecard.modelName);
		tooltip += this.buildNextLine('metrics.path', nodeFullPath);
		tooltip += this.buildNextLine('scorecards.creator', scorecard.creatorEmail);
		return this.sanitizer.bypassSecurityTrustHtml(tooltip);
	}

	// 2.4 INFO HIERARCHY METRIC TOOLTIP

	private getHierarchyMetricTooltipContent(hierarchies: ReportableHierarchy[], displayName: string): SafeHtml {
		let hierarchyName: string = displayName.split(CustomMathUtils.ORGANIZATION_HIERARCHY_FORMULA_DELIMETER)[0];
		let hierarchy: ReportableHierarchy = _.findWhere(hierarchies, {name: hierarchyName});
		return this.buildHierarchyMetricTooltipContent(hierarchy.owner);
	}

	private buildHierarchyMetricTooltipContent(owner: string): SafeHtml {
		let tooltip: string = this.buildFirstLine('metrics.hierarchyMetric');
		tooltip += this.buildNextLine('common.owner', owner);
		return this.sanitizer.bypassSecurityTrustHtml(tooltip);
	}

	// 2.5 INFO CUSTOM METRIC TOOLTIP

	private getCustomMetricTooltipContent(metrics: Metric[], displayName: string): SafeHtml {
		 let metric: Metric = _.findWhere(metrics, {displayName});
		 let customMetricType = this.getCustomMetricType(metric.definition.type);
		 let metricLabels: Label[] = metric.labels;
		 return this.buildCustomMetricTooltipContent(metric.ownerName, customMetricType, metricLabels);
	}

	private buildCustomMetricTooltipContent(owner: string, type: string, labels: Label[]): SafeHtml {
		let tooltip: string = this.buildFirstLine('metrics.customMetric');
		tooltip += this.buildNextLine('common.owner', owner);
		tooltip += this.buildNextLine('common.type', type);
		tooltip += this.buildCustomMetricLabels(labels);
		return this.sanitizer.bypassSecurityTrustHtml(tooltip);
	}

	private getCustomMetricType(type: MetricType): string {
		switch (type) {
			case MetricType.TOP_BOX:
				return this.locale.getString('metrics.topBoxType');
			case MetricType.BOTTOM_BOX:
				return this.locale.getString('metrics.bottomBoxType');
			case MetricType.SATISFACTION:
				return this.locale.getString('metrics.satisfactionType');
			case MetricType.FILTER:
				return this.locale.getString('metrics.filterMetric');
			case MetricType.VARIABLE:
				return this.locale.getString('metrics.variable');
		}
	}

	// 3 WARNINGS AND ERRORS TOOLTIPS

	getTextErrors = (segment: SupportTextErrors, customMathErrors: string[],
		errorMessages: {[key in CustomMathErrorType]: string}): string => {
		customMathErrors ??= [];
		segment ??= { errors: []} as SupportTextErrors;
		return _.chain(segment.errors)
			.map(error => {
				const errorMessage = this.getErrorMessage(segment.text, error, errorMessages);
				if (!_.contains(customMathErrors, errorMessage)) {
					customMathErrors.push(errorMessage);
				}
				return errorMessage;
			})
			.uniq().join(' ').value();
	};

	private getErrorMessage = (text: string, error: CustomMathErrorType, errorMessages: {[key in CustomMathErrorType]: string}): string => {
		text = text.trim().length ? text : this.locale.getString('metrics.token');
		return this.locale.getString(errorMessages[error], {token: text});
	};

	getTextWarnings = (token: TextToken, customMathWarnings: string[], dismissedWarnings: string[] = [],
		warningMessages: {[key in CustomMathWarningType]: string}): string => {
		return _.chain(token.warnings).map(warning => {
			const warningMessage = this.getWarningMessage(token.text, warning, warningMessages);
			if (!_.contains(customMathWarnings, warningMessage) && !_.contains(dismissedWarnings, warningMessage)) {
				customMathWarnings.push(warningMessage);
			}
			return warningMessage;
		})
			.uniq().join(' ').value();
	};

	private getWarningMessage = (text: string, warning: CustomMathWarning,
		warningMessages: {[key in CustomMathWarningType]: string}): string => {
		return this.locale.getString(warningMessages[warning.type], warning.params);
	};

	// 4 TYPES CHECKS

	isWarningOrErrorToken(classList: DOMTokenList): boolean {
		return !!(classList && (classList.contains(TooltipType.WARNING) || classList.contains(TooltipType.ERROR)));
	}

	infoTooltipsSupported(tokenType: TextTokenType): boolean {
		return tokenType === TextTokenType.NUMERIC_ATTRIBUTE
			|| tokenType === TextTokenType.TEXT_ATTRIBUTE
			|| tokenType === TextTokenType.PREDEFINED_METRIC
			|| tokenType === TextTokenType.METRIC
			|| tokenType === TextTokenType.SCORECARD_METRIC
			|| tokenType === TextTokenType.HIERARCHY_METRIC;
	}

	isInfoToken(classList: DOMTokenList): boolean {
		return classList?.contains(TooltipType.INFO);
	}

	private isAttributeToken(classList: DOMTokenList): boolean {
		return classList.contains(TextTokenType.NUMERIC_ATTRIBUTE) || classList.contains(TextTokenType.TEXT_ATTRIBUTE);
	}

	private isSystemMetricToken(classList: DOMTokenList): boolean {
		return classList.contains(TextTokenType.PREDEFINED_METRIC);
	}

	private isCustomMetricToken(classList: DOMTokenList): boolean {
		return classList.contains(TextTokenType.METRIC);
	}

	private isScorecardMetricToken(classList: DOMTokenList): boolean {
		return classList.contains(TextTokenType.SCORECARD_METRIC);
	}

	private isHierarchyMetricTooltipText(classList: DOMTokenList): boolean {
		return classList.contains(TextTokenType.HIERARCHY_METRIC);
	}

	// 5 HTML BUILDERS

	private buildFirstLine(localeKey: string): string {
		return `<b>${this.locale.getString(localeKey)}</b>\n`;
	}

	private buildNextLine(localeKey: string, value: string): string {
		return `<span class="d-block">${this.locale.getString(localeKey)}: ${HtmlUtils.escapeHtml(value)}</span>\n`;
	}

	private buildSimpleLine(value: string): string {
		return `<span class="d-block">${HtmlUtils.escapeHtml(value)}</span>\n`;
	}

	private buildCustomMetricLabels(labels: Label[]): string {
		const OPEN_LABELS_DIV: string = '<div class="custom-math-tooltip-labels d-inline-flex">\n';
		const CLOSE_LABELS_DIV: string = '</div>';

		let labelsTemplate: string = OPEN_LABELS_DIV;
		labels.forEach(label => labelsTemplate += this.buildLabel(label.text));
		labelsTemplate += CLOSE_LABELS_DIV;

		return labelsTemplate;
	}

	private buildLabel(text: string): string {
		return `<span class="metric-label">${HtmlUtils.escapeHtml(text)}</span>\n`;
	}

	// 6 TOOLTIP POSITION

	getTooltipPosition(textSpan: HTMLElement): TooltipPosition {
		return {
			top: this.calculateTooltipTop(textSpan),
			left: this.calculateTooltipLeft(textSpan)
		};
	}

	// 6.1 TOOLTIP TOP

	private calculateTooltipTop(textSpan: HTMLElement): string {
		let textPosition: JQueryCoordinates = $(textSpan).position();
		return textPosition.top + CustomMathTooltipsService.TEXT_HEIGHT + CustomMathTooltipsService.INDENTATION + 'px';
	}

	// 6.2 TOOLTIP LEFT

	private calculateTooltipLeft(textSpan: HTMLElement): string {
		return this.nameTakesOneLine(textSpan)
			? this.calculateSingleLineTextTooltipLeft(textSpan)
			: this.calculateSeveralLinesTextTooltipLeft(textSpan);
	}

	// 6.2.1 OBJECT NAME TAKES ONE LINE

	private nameTakesOneLine(textSpan: HTMLElement): boolean {
		return $(textSpan).height() === CustomMathTooltipsService.TEXT_HEIGHT;
	}

	private calculateSingleLineTextTooltipLeft(textSpan: HTMLElement): string {
		let textPosition: JQueryCoordinates = $(textSpan).position();
		let textWidth: number = $(textSpan).width();
		return textPosition.left + textWidth / 2 + 'px';
	}

	// 6.2.2 OBJECT NAME TAKES SEVERAL LINES

	private calculateSeveralLinesTextTooltipLeft(textSpan: HTMLElement): string {
		if (this.isSegment(textSpan)) {
			let parentChildren: any = textSpan.parentElement.children;
			let textSpanIndex: number = _.indexOf(parentChildren, textSpan);
			return textSpanIndex === 0
				? this.calculateSingleLineTextTooltipLeft(textSpan)
				: this.calculateBrokenSegmentTooltipLeft(parentChildren[textSpanIndex - 1]);
		} else {
			return this.calculateBrokenTokenTooltipLeft(textSpan);
		}
	}

	// 6.2.2.1 SEGMENT TEXT

	private calculateBrokenSegmentTooltipLeft(previousSpan: HTMLElement): string {
		let textSpanLeft: number = this.getBrokenSegmentTextLeft(previousSpan);
		return textSpanLeft + this.getTextMiddle(textSpanLeft) + 'px';
	}

	private getBrokenSegmentTextLeft(previousSpan: HTMLElement): number {
		return $(previousSpan).position().left + $(previousSpan).width();
	}

	private getTextMiddle(textSpanLeft: number): number {
		let editorWidth: number = $(`#${CustomMathTooltipsService.MATH_EDITOR_CONTENT_WRAPPER_ID}`).width();
		let editorContentLeft: number = $(`#${CustomMathTooltipsService.MATH_EDITOR_CONTENT_WRAPPER_ID}`).position().left;
		let sameLineTextWidth: number = textSpanLeft - editorContentLeft;
		return (editorWidth - sameLineTextWidth) / 2;
	}

	// 6.2.2.2 TOKEN TEXT

	private calculateBrokenTokenTooltipLeft(textSpan: HTMLElement): string {
		let textSpanLeft: number = this.getBrokenTokenTextLeft(textSpan);
		return  textSpanLeft + this.getTextMiddle(textSpanLeft) + 'px';
	}

	private getBrokenTokenTextLeft(textSpan: HTMLElement): number {
		let segmentTokens = textSpan.parentElement.children;
		let openBracketSpan: Element = this.isSystemMetricSegment(segmentTokens) ? segmentTokens[0] : segmentTokens[1];
		return $(openBracketSpan).position().left + $(openBracketSpan).width();
	}

	private isSystemMetricSegment(segmentTokens: HTMLCollection): boolean {
		return segmentTokens[0].classList.contains(TextTokenType.SYNTAX);
	}

}
