import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CxLocaleService, ILocaleInstance, SupportedLocale } from '@app/core/cx-locale.service';
import { DomainsProcessingService } from '@app/modules/account-administration/api/domains-processing.service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import { UserHomePageService } from '@app/modules/home-page/home-page-layout/user-home-page.service';
import { SidebarService } from '@app/modules/layout/sidebar.service';
import { AllowedDomains } from '@app/modules/system-administration/master-account/email-domains/allowed-domains';
import { MasterAccountIdentity } from '@app/modules/system-administration/master-account/entities/master-account-identity';
import { TranslationApi } from '@app/modules/translation/translation-api.service';
import { TranslationCacheService } from '@app/modules/translation/translation-cache.service';
import { Unit } from '@app/modules/units/unit';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { UserPropertiesApiService } from '@app/modules/user/user-properties-api.service';
import { FormValidationService } from '@app/shared/services/form-validation.service';
import { ObjectUtils } from '@app/util/object-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { ClarabridgeAppType } from '@cxstudio/auth/entities/clarabridge-app-type.enum';
import { FavoriteProperties } from '@cxstudio/auth/entities/favorite-properties';
import { Security } from '@cxstudio/auth/security-service';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { ApplicationTheme } from '@cxstudio/header/application-theme';
import { DateFormat } from '@cxstudio/header/date-format';
import { ContractApiService } from '@cxstudio/master-accounts/contracts/contract-api-service.service';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';
import { IProjectPreselection } from '@cxstudio/projects/project-preselection.interface';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { BasicPasswordSettings } from '@cxstudio/services/data-services/security-api.service';
import { UserApiService } from '@cxstudio/services/data-services/user-api-service';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import * as _ from 'underscore';
import { LanguageLoaderService } from '@app/core/i18n/language-loader.service';
import { SupportedLocaleGenerated } from '@locales/supported-locales';

interface IListItem {
	id: string;
	name: string;
}

interface IUserModel {
	language: string;
	oldPassword: string;
	newPassword: string;
	confirmPassword: string;
	dateFormat: DateFormat;
	translateLanguage: string;
	properties: any;
	applicationTheme: ApplicationTheme;
	favoriteMasterAccount: MasterAccountIdentity;
	favoriteHomePage: number;
	workspaceProject: WorkspaceProject;
	preferredEmail: string;
	preferredEmailPending?: boolean;
}

interface IFavoriteProperties extends IProjectPreselection {
	favoriteDashboard: number;
	startupApp: ClarabridgeAppType;
}

@Component({
	selector: 'user-preferences-dialog',
	templateUrl: './user-preferences-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserPreferencesDialogComponent implements OnInit {
	@ViewChild('propsDialogForm', {static: true}) propsDialogForm: UntypedFormGroup;

	private formValidation: FormValidationService;

	preferencesLoading: Promise<any>;
	saving: boolean;
	errorMessage: string;

	isSamlForMAEnabled: boolean;
	isForcedExternalAuthentication: boolean;
	model: IUserModel;
	initialProps: IUserModel;

	showFavoriteProjectProps: boolean;
	projectsLoading: Promise<any>;
	showErrorsForCP: string[];

	// workspace
	isWorkspaceEnabled: boolean;

	availableDashboards: any[];
	availableStartupApps: any[];
	availableThemeOptions: Array<{ name: ApplicationTheme; displayName: string; order: number }>;
	availableMasterAccounts: MasterAccountIdentity[];
	availableHomePages: HomePage[];
	selectedHomePage: HomePage;

	languages: IListItem[];
	dateFormats: IListItem[];
	showTranslateProps: boolean;
	translateLanguages: IListItem[];

	showPasswordControls: boolean;

	passwordPolicy: {
		requireSpecialChars: boolean;
		minLength: number;
	};
	pwdPattern: RegExp;

	allowedDomains: AllowedDomains;
	allowedDomainsErrorMessage: string;

	clearDefaultsSubject: Subject<void> = new Subject<void>();

	loadingTranslationLanguages: Promise<any>; //for test

	constructor(
		private ref: ChangeDetectorRef,
		private modal: NgbActiveModal,
		@Inject('security') private security: Security,
		private locale: CxLocaleService,
		private userHomePageService: UserHomePageService,
		private userPropertiesApiService: UserPropertiesApiService,
		private translationCache: TranslationCacheService,
		@Inject('securityApiService') private securityApiService,
		@Inject('dateService') private dateService,
		private translationApi: TranslationApi,
		@Inject('maPropertiesService') private maPropertiesService,
		@Inject('dashboardApiService') private dashboardApiService: DashboardApiService,
		private gridUtils: GridUtilsService,
		private sidebarService: SidebarService,
		@Inject('contractApiService') private contractApiService: ContractApiService,
		private applicationThemeService: ApplicationThemeService,
		private betaFeaturesService: BetaFeaturesService,
		private domainsProcessingService: DomainsProcessingService,
		@Inject('userApiService') private readonly userApiService: UserApiService,
		private languageLoader: LanguageLoaderService,
	) {}

	@HostListener('window:keydown.escape', ['$event'])
	onKeyEscape() {
		this.cancel(true);
	}

	ngOnInit(): void {
		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
		this.isForcedExternalAuthentication = this.security.isForcedExternalAuthenticationForUser();
		this.showPasswordControls = this.security.requiresPasswordControlsForUser();

		this.availableMasterAccounts = this.security.getMasterAccounts();

		let favoriteMasterAccountId = this.security.getContext().favoriteMasterAccount;
		let favoriteMasterAccount = this.availableMasterAccounts
			.find(ma => ma.accountId === favoriteMasterAccountId);

		this.model = {
			language: this.locale.getLocale(),
			oldPassword: '',
			newPassword: '',
			confirmPassword: '',
			dateFormat: this.security.getContext().dateFormat || DateFormat.US,
			translateLanguage: '', // must be initialized after translate languages loading from backend
			properties: this.convertFromFavoriteProperties(this.security.getContext().properties) || {},
			applicationTheme: this.security.getContext().applicationTheme,
			favoriteMasterAccount,
			favoriteHomePage: this.security.getContext().favoriteHomePage,
			workspaceProject: this.convertToFavoriteWorkspaceProps(this.security.getContext().properties),
			preferredEmail: this.security.getContext().preferredEmail,
			preferredEmailPending: this.security.getContext().preferredEmailPending
		};

		this.initialProps = ObjectUtils.copy(this.model);
		this.showFavoriteProjectProps = this.security.has('content_provider');

		this.loadingTranslationLanguages = this.checkTranslateContract()
			.then(result => this.initLocalization(result));

		this.preferencesLoading = Promise.all([
			this.loadDashboards(),
			this.loadingTranslationLanguages,
			this.initPasswordPolicy(),
			this.loadHomePages(),
			this.loadAllowedDomains(),
		]);

		this.loadAvailableStartupApps();

		if (this.isDarkModeAvailable()) {
			this.initThemeOptions();
		}

		this.formValidation = new FormValidationService(this.propsDialogForm);
	}

	private convertToFavoriteWorkspaceProps(properties: FavoriteProperties): WorkspaceProject {
		let defaults: WorkspaceProject = {
			projectId: -1,
			workspaceId: -1
		};

		if(!properties) {
			return defaults;
		}

		return {
			projectId: properties.workspaceProject.projectId,
			workspaceId: properties.workspaceProject.workspaceId
		};
	}

	private checkTranslateContract = (): Promise<boolean> => {
		return Promise.resolve(this.contractApiService.isQuickTranslateAvailable() as PromiseLike<boolean>);
	};

	private initLocalization(translateContractAvailable: boolean): void {
		let locales: ILocaleInstance[] = this.locale.getSupportedLocales();

		if (!this.betaFeaturesService.isFeatureEnabled(BetaFeature.JAPANESE_UI)) {
			locales = locales.filter((locale) => locale.id !== SupportedLocale.ja_JA);
		}

		if (!CONFIG.dev) {
			locales = locales.filter((locale) => locale.id !== SupportedLocale.pb_PB);
		}

		this.languages = _.map(locales, item => {
			return {
				id: item.id,
				name: this.locale.getString(item.label)
			};
		});

		this.dateFormats = this.dateService.getDateFormats();

		this.showTranslateProps = this.maPropertiesService.isFeatureEnabled(MasterAccountProperty.QUICK_TRANSLATE)
				&& translateContractAvailable;
		if (this.showTranslateProps) {
			this.translateLanguages = [];
			let languages = this.translationApi.getSupportedLanguages();
			this.translateLanguages = _.map(languages, language => {
				let displayName = language.nativeName;
				if (language.nativeName !== language.name)
					displayName += ' (' + language.name + ')';
				return {
					id: language.id,
					name: displayName
				};
			});

			this.model.translateLanguage = this.security.getContext().translateLanguage || '';

			if (!this.model.translateLanguage) {
				this.model.translateLanguage = this.model.language.substring(0, 2);
				if (!_.findWhere(this.translateLanguages, {id: this.model.translateLanguage})) {
					this.model.translateLanguage = 'en';
				}
			}
		}

	}

	private initPasswordPolicy(): Promise<any> {
		return this.securityApiService.getPasswordPolicy(this.security.getUser().defaultMasterAccountId).then(response => {
			this.passwordPolicy = response.data;
			this.pwdPattern = new RegExp('');
			if (this.passwordPolicy && this.passwordPolicy.requireSpecialChars)
				this.pwdPattern = this.security.getPwdPattern();
			this.ref.markForCheck();
		});

	}

	private checkReload(): void {
		this.languageLoader.ensureLanguage(this.model.language as SupportedLocaleGenerated);
		if (this.security.getContext().dateFormat !== this.model.dateFormat) {
			window.location.reload();
		}
	}

	onProjectSelectionChange = (projectSelection: IProjectPreselection): void => {
		this.model.properties.cbContentProvider = projectSelection.cbContentProvider;
		this.model.properties.cbAccount = projectSelection.cbAccount;
		this.model.properties.project = projectSelection.project;
	};

	workspaceChanged = (workspace: Unit) => {
		if (workspace) {
			this.model.properties.cbContentProvider = workspace.contentProviderId;
			this.model.properties.cbAccount = workspace.accountId;
		} else {
			this.model.properties.cbContentProvider = -1;
			this.model.properties.cbAccount = -1;
		}
	};

	workspaceProjectChanged = (newProject?: WorkspaceProjectData) => {
		if (newProject) {
			this.model.workspaceProject = newProject;
			this.model.properties.project = newProject.projectId;
		}

		if (this.model.properties.project === null || this.model.properties.project === -1) {
			this.model.properties.project = undefined;
		}
	};

	savePassword = (password: BasicPasswordSettings): Promise<string> => {
		if (!password || !password.oldPassword || !password.newPassword) {
			return Promise.resolve('');
		} else {
			return this.securityApiService.setPassword(password).then(response => response.data);
		}
	};

	saveAll = (): Promise<boolean> => {
		let pwd = {
			oldPassword: this.model.oldPassword,
			newPassword: this.model.newPassword
		};

		let promises: Array<Promise<any>> = [
			this.savePassword(pwd),
			this.saveProperties()
		];

		return Promise.all(promises).then(result => {
			// change frontend favorite properties as well
			_.extend(this.security.getContext().properties, this.convertToFavoriteProperties(this.model.properties));
			if (result[0]) {
				if (result[0] === 'incorrect password') {
					this.errorMessage = 'Incorrect password';
				} else {
					this.errorMessage = result[0] as string;
				}
				return false;
			} else {
				return true;
			}
		}).then((result: boolean) => {
			if (!_.isEmpty(this.model.properties)) {
				return this.userPropertiesApiService.updateFavoriteProperties(
					this.convertToFavoriteProperties(this.model.properties))
					.then(() => result);
			}
			return result;
		});
	};

	private saveProperties(): Promise<void> {
		let updates = {} as any;
		if (this.initialProps.dateFormat !== this.model.dateFormat) {
			updates.user_date_format = this.model.dateFormat;
		}
		if (this.initialProps.translateLanguage !== this.model.translateLanguage) {
			updates.translate_language = this.model.translateLanguage;
		}
		if (this.locale.getLocale() !== this.model.language) {
			updates.user_locale = this.model.language;
		}
		if (this.initialProps.applicationTheme !== this.model.applicationTheme) {
			updates.application_theme = this.model.applicationTheme;
		}
		if (this.model.favoriteMasterAccount !== this.initialProps.favoriteMasterAccount) {
			updates.favorite_master_account = this.model.favoriteMasterAccount ? this.model.favoriteMasterAccount.accountId : null;
		}
		if (this.model.favoriteHomePage !== this.initialProps.favoriteHomePage) {
			updates.favoriteHomePage = this.model.favoriteHomePage;
		}
		if (this.model.preferredEmail !== this.initialProps.preferredEmail) {
			updates.preferredEmail = this.model.preferredEmail;
		}

		return this.userPropertiesApiService.updateProperties(updates).then(() => {
			// need to update context in case we don't reload the page
			if (this.security.getContext().translateLanguage !== this.model.translateLanguage) {
				this.security.getContext().translateLanguage = this.model.translateLanguage;
				this.translationCache.reset();
			}
			if (this.initialProps.applicationTheme !== this.model.applicationTheme) {
				this.security.getContext().applicationTheme = this.model.applicationTheme;
			}
			if (this.model.favoriteHomePage !== this.initialProps.favoriteHomePage) {
				this.security.getContext().favoriteHomePage = this.model.favoriteHomePage;
			}
			if (this.model.preferredEmail !== this.initialProps.preferredEmail) {
				this.security.getContext().preferredEmail = this.model.preferredEmail;
				this.security.getContext().preferredEmailPending = true;
			}
		});
	}

	private convertFromFavoriteProperties(properties: FavoriteProperties): IFavoriteProperties {
		let defaults: IFavoriteProperties = {
			cbContentProvider: -1,
			cbAccount: -1,
			project: -1,
			favoriteDashboard: -1,
			startupApp: ClarabridgeAppType.NONE
		};
		if (!properties) return defaults;

		//for existing favorite dashboard
		if (properties.favoriteDashboard && properties.favoriteDashboard !== -1) {
			properties.startupApp = ClarabridgeAppType.STUDIO;
		}
		return {
			cbContentProvider: properties.favoriteCP || defaults.cbContentProvider,
			cbAccount: !_.isUndefined(properties.favoriteAccount) ? properties.favoriteAccount : defaults.cbAccount,
			project: properties.favoriteProject || defaults.project,
			favoriteDashboard: properties.favoriteDashboard || defaults.project,
			startupApp: properties.startupApp || defaults.startupApp
		};
	}

	// Since we update both model props, we can just send one properties
	private convertToFavoriteProperties(properties: IFavoriteProperties): FavoriteProperties {
		return {
			favoriteCP: properties.cbContentProvider,
			favoriteAccount: properties.cbAccount,
			favoriteProject: properties.project,
			favoriteDashboard: ClarabridgeAppType.STUDIO === properties.startupApp ? properties.favoriteDashboard : -1,
			startupApp: properties.startupApp,
			workspaceProject: this.model.workspaceProject
		};
	}

	save = () => {
		this.errorMessage = null;
		this.saving = true;
		this.validateProperties().then(() => this.saveAll()).then(result => {
			this.applicationThemeService.applyApplicationTheme();
			this.applicationThemeService.setDashboardTheme(this.applicationThemeService.getAppliedApplicationTheme());
			if (result) {
				this.security.getContext().favoriteMasterAccount = this.model.favoriteMasterAccount?.accountId;
				this.modal.close();
				this.checkReload();
			}
		}, () => {
			this.saving = false;
			this.ref.markForCheck();
		});
	};

	cancel = (usingEscapeKey: boolean = false) => this.modal.dismiss({usingEscapeKey});

	onPasswordCompareChange = (): void => {
		this.ref.detectChanges();
	};

	isNeedFavoriteMasterAccountSelector = (): boolean => {
		return this.availableMasterAccounts?.length > 1;
	};

	isNeedHomePageSelector(): boolean {
		return this.availableHomePages?.length > 1;
	}

	isNeedPreferredEmailConfirm(): boolean {
		return this.model.preferredEmailPending
			|| this.model.preferredEmail !== this.initialProps.preferredEmail;
	}

	onFavoriteMasterAccountChange = (masterAccount: MasterAccount) => {
		this.model.favoriteMasterAccount = masterAccount;
	};

	clearFavoriteMasterAccount = () => {
		this.model.favoriteMasterAccount = null;
	};

	onFavoriteHomePageChange = (homePage: HomePage) => {
		this.selectedHomePage = homePage;
		this.model.favoriteHomePage = homePage.id;
	};

	clearFavoriteHomePage = () => {
		this.selectedHomePage = null;
		this.model.favoriteHomePage = null;
	};

	clearFavorites = () => {
		this.clearDefaultsSubject.next();
		this.model.properties.favoriteDashboard = -1;
		this.model.properties.startupApp = ClarabridgeAppType.NONE;
	};

	onCpErrorsChange = (errors: string[]) => {
		this.showErrorsForCP = errors;
	};

	onProjectsLoading = (loadingPromise: Promise<any>) => {
		this.projectsLoading = loadingPromise;
		this.ref.markForCheck();
	};

	private loadDashboards = (): Promise<void> => {
		return PromiseUtils.wrap(this.dashboardApiService.getAllDashboards()).then((allDashboards) => {
			let dashboards = _.filter(this.gridUtils.processItemsTree(allDashboards), (item) => {
				return item.type && item.type === DashboardType.DASHBOARD || item.type === DashboardType.BOOK;
			});
			this.availableDashboards = [{id: -1, name: ''}].concat(dashboards);
		});
	};

	private loadAvailableStartupApps = (): void => {
		let availableStartupApps = [
			{ displayName: this.locale.getString('common.none'), name: ClarabridgeAppType.NONE, order: 1},
			{ displayName: this.locale.getString('header.openStudio'), name: ClarabridgeAppType.STUDIO, order: 2}];

		this.sidebarService.loadSidebarConfig().then(() => {
			if (this.sidebarService.isLinkEnabled('analyze')) {
				availableStartupApps.push({
					displayName: this.locale.getString('header.openAnalyze'),
					name: ClarabridgeAppType.ANALYZE,
					order: 3
				});
			}

			if (this.sidebarService.isLinkEnabled('engagor')) {
				availableStartupApps.push({
					displayName: this.locale.getString('header.openEngagor'),
					name: ClarabridgeAppType.ENGAGOR,
					order: 4
				});
			}

			if (this.sidebarService.isLinkEnabled('connectors')) {
				availableStartupApps.push({
					displayName: this.locale.getString('header.openConnectors'),
					name: ClarabridgeAppType.CONNECTORS,
					order: 5
				});
			}

			this.availableStartupApps = availableStartupApps;
		});
	};

	showErrorForHomePage = (): boolean => {
		return this.model.properties.project < 0
			&& (this.model.properties.startupApp === ClarabridgeAppType.ANALYZE
				|| this.model.properties.startupApp === ClarabridgeAppType.CONNECTORS);
	};

	getErrorForHomePage = (): string => {
		let name = this.model.properties.startupApp === ClarabridgeAppType.CONNECTORS
			? this.locale.getString('header.openConnectors')
			: this.locale.getString('header.openAnalyze');
		return this.locale.getString('header.homePageWarning', { name });
	};

	isValid = (): boolean => {
		return (this.isForcedExternalAuthentication || this.propsDialogForm?.valid) && !this.showErrorForHomePage();
	};

	private initThemeOptions = (): void => {
		this.availableThemeOptions = [
			{ name: ApplicationTheme.AUTOMATIC, displayName: this.locale.getString('common.automaticTheme'), order: 0 },
			{ name: ApplicationTheme.DEFAULT, displayName: this.locale.getString('common.default'), order: 1 },
			{ name: ApplicationTheme.DARK, displayName: this.locale.getString('common.darkTheme'), order: 2 },
		];
	};

	private loadHomePages(): Promise<void> {
		return this.userHomePageService.getUserHomePages().then(homePages => {
			let defaultPage = _.findWhere(homePages, {default: true});
			defaultPage.name = `${defaultPage.name} (${this.locale.getString('common.default')})`;
			(defaultPage as any).css = 'italic';
			_.each(homePages, homePage => (homePage as any).sortKey = homePage.default ?  '' : homePage.name);
			this.availableHomePages = homePages;
			this.selectedHomePage = _.findWhere(this.availableHomePages, {id: this.model.favoriteHomePage});
		});
	}

	private loadAllowedDomains(): Promise<void> {
		return PromiseUtils.wrap(this.userApiService.getAllowedDomains().then(domains => {
			this.allowedDomains = domains;
		}));
	}

	isDarkModeAvailable = (): boolean => {
		return this.security.getCurrentMasterAccount().darkModeAvailable;
	};

	showPreferredEmail(): boolean {
		return this.isInternalUser() || this.isUserSyncEnabled();
	}

	isUserSyncEnabled(): boolean {
		return this.security.isUserSyncingEnabled();
	}

	isInternalUser(): boolean {
		return this.security.isAdminOrgUser();
	}

	validatePreferredEmail(): Promise<void> {
		if (!!this.model.preferredEmail && !this.formValidation.hasError('preferredEmail', 'email')) {
			let allowed = true;
			if (this.allowedDomains.enabled) {
				let parsedDomain = this.model.preferredEmail.split('@')[1];
				allowed = this.domainsProcessingService.validateLowerCaseEmailDomain(this.allowedDomains, parsedDomain);
				if (!allowed) {
					this.allowedDomainsErrorMessage = this.locale.getString('administration.domainRestriction', {
						domain: parsedDomain
					});
				}
				this.formValidation.updateControlErrors('preferredEmail', 'domainRestricted', !allowed);
			}
		} else {
			this.formValidation.updateControlErrors('preferredEmail', 'domainRestricted', false);
			return !!this.model.preferredEmail ? Promise.reject() : Promise.resolve();
		}
	}

	private validateProperties(): Promise<any> {
		let promises = [];
		if (!!this.model.preferredEmail && this.model.preferredEmail !== this.initialProps.preferredEmail) {
			promises.push(this.validatePreferredEmail());
		}
		return Promise.all(promises);
	}

	isQualtricsIntegrationEnabled(): boolean {
		return this.security.isQualtricsIntegrationEnabled();
	}

}
