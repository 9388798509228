import * as _ from 'underscore';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { IFilterActionsService } from '@cxstudio/report-filters/filter-actions-service';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { AssetPermission } from '@cxstudio/asset-management/asset-permission';
import { ReportFilterManagement } from '@cxstudio/report-filters/report-filters-management.component';
import { FolderContextMenuUtils } from '@cxstudio/folders/folder-context-menu-utils.service';
import { FilterItemType } from '@cxstudio/report-filters/entity/filter-item-type.enum';
import BulkUpdateLabelsEntity from '@cxstudio/bulk/bulk-update-labels-entity';
import * as cloneDeep from 'lodash.clonedeep';
import { MenuDivider } from '@cxstudio/context-menu/drill-menu-option.component';
import { ContextMenuAction } from '@app/shared/menu/context-menu-action.enum';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';
import { ApplicationPermissionAction } from '@app/modules/user-administration/permissions/application-permission-action';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { DowngradeToastService } from '@app/modules/downgrade-utils/downgrade-toast.service';
import ManageFilterService from '@app/modules/filter/services/manage-filter.service';
import { Security } from '@cxstudio/auth/security-service';
import ILocale from '@cxstudio/interfaces/locale-interface';


// eslint-disable-next-line prefer-arrow-callback
app.factory('FilterContextMenuUtils', function(
	locale: ILocale,
	FolderService,
	security: Security,
	FilterActionsService,
	manageFilterService: ManageFilterService,
	downgradeToastService: DowngradeToastService,
	betaFeaturesService: BetaFeaturesService,
) {
	return class FilterContextMenuUtils extends BaseContextMenuUtils {
		private scope: ReportFilterManagement;
		private folderSvc: any;
		private actionsService: IFilterActionsService;

		private readonly OPTIONS = {
			VIEW: {text: locale.getString('common.view'), name: ContextMenuAction.VIEW,
				func: (filter: any) => this.actionsService.viewFilter(filter)},

			EDIT: {text: locale.getString('common.edit'), name: ContextMenuAction.EDIT,
				func: (filter: any) => this.actionsService.editFilter(filter)},

			CREATE_FILTER: {text: locale.getString('reportFilters.createNewFilter'), name: 'create_filter_in_folder',
				func: (folder: any) => this.actionsService.createFilter(folder)},

			RENAME_FOLDER: {text: locale.getString('common.rename'), name: ContextMenuAction.RENAME,
				func: (filter: any) => this.actionsService.renameFolder(filter)},

			CREATE_SUBFOLDER: {text: locale.getString('dashboard.addSubfolder'), name: ContextMenuAction.ADD_FOLDER,
				func: (folder: any) => this.actionsService.createFolder(folder)},

			RENAME_FILTER: {text: locale.getString('common.rename'), name: ContextMenuAction.RENAME,
				func: (filter: any) => this.actionsService.renameFilter(filter)},

			MOVE: {text: locale.getString('dashboard.move'), name: ContextMenuAction.MOVE_TO, searchBox: true, isExpandable: true },

			REMOVE_FOLDER: {text: locale.getString('dashboard.menuDeleteFolder'), name: ContextMenuAction.DELETE,
				func: (folder: any) => this.actionsService.removeFolder(folder)},

			REMOVE_SUBFOLDER: {text: locale.getString('dashboard.menuDeleteSubfolder'), name: ContextMenuAction.DELETE,
				func: (folder: any) => this.actionsService.removeFolder(folder)},

			REMOVE_FILTER: {text: locale.getString('common.delete'), name: ContextMenuAction.DELETE,
				func: (filter: any) => this.actionsService.deleteFilter(filter)},

			BULK_REMOVE_FILTER: {text: locale.getString('common.delete'), name: ContextMenuAction.DELETE},

			COPY: {text: locale.getString('dashboard.copy'), name: ContextMenuAction.COPY,
				func: (filter: any) => this.actionsService.copyFilter(filter)},

			SHARE: {text: locale.getString('dashboard.share'), name: ContextMenuAction.SHARE,
				func: (filters: any[]) => this.actionsService.shareFilters(filters)},

			CREATE_TEMPLATE: {
				text: locale.getString('templates.menuSaveAsTemplate'),
				name: 'createTemplate',
				func: (filter) => this.actionsService.saveFilterAsTemplate(filter)
			},

			EDIT_LABELS: {text: locale.getString('common.editLabels'), name: 'editLabels'},

			SHOW: {text: locale.getString('common.show'),  name: 'show-item',
				func: (filter: any) => this.actionsService.toggleHide(filter), disabled: false},

			HIDE: {text: locale.getString('common.hide'), name: 'hide-item',
				func: (filter: any) => this.actionsService.toggleHide(filter), disabled: false},

			DEPENDENCIES: {text: locale.getString('common.dependencies'), name: 'dependencies',
				func: (filter: any) => this.actionsService.showDependencies(filter)},

			SHOW_HIDE: {text: locale.getString('common.showHide'), name: ContextMenuAction.SHOW_HIDE},


			EDIT_DEFINITION: {text: locale.getString('common.edit'), name: ContextMenuAction.EDIT,
				func: (filter: any) => this.actionsService.editPredefinedFilter(filter)},

			VIEW_DEFINITION: {text: locale.getString('common.view'), name: ContextMenuAction.VIEW,
				func: (filter: any) => this.actionsService.viewPredefinedFilter(filter)},

			TRANSFER_SELECTED: {text: locale.getString('dashboard.transferOwnerWidgetConfirmOption'), name: ContextMenuAction.TRANSFER_SELECTED},

			SHOW_HIDDEN: { text: locale.getString('dashboard.showAllHidden'), name: ContextMenuAction.TOGGLE_HIDDEN, func: () => {
				this.scope.ui.hideFilters = false;
			}},

			HIDE_HIDDEN: { text: locale.getString('dashboard.hideAllHidden'), name: ContextMenuAction.TOGGLE_HIDDEN, func: () => {
				this.scope.ui.hideFilters = true;
			}},

			ENABLE: {text: locale.getString('common.enable'), name: ContextMenuAction.ENABLE, func: (filter) => {
				this.actionsService.toggleScorecardFilterState(filter, false);
			}},

			DISABLE: {text: locale.getString('common.disable'), name: ContextMenuAction.DISABLE, func: (filter) => {
				this.actionsService.toggleScorecardFilterState(filter, true);
			}}
		};

		constructor(scope: ReportFilterManagement) {
			super();
			this.scope = scope;
			this.folderSvc = new FolderService(FolderTypes.FILTER);
			this.actionsService = new FilterActionsService(this.scope);
		}

		getContextMenu(item: any): any[] {
			if (this.scope.selectionUtils.multipleObjectsSelected()) {
				return BaseContextMenuUtils.enforceDividerRules(this.getMultiSelectMenuOptions());
			}

			if (item.type === FolderTypes.FILTER) {
				return BaseContextMenuUtils.enforceDividerRules(this.getFolderMenuOptions(item));
			}

			if (item.type === FilterTypes.CXSTUDIO) {
				return BaseContextMenuUtils.enforceDividerRules(this.getStudioFilterMenuOptions(item));
			}

			if (item.type === FilterTypes.CXANALYZE) {
				return BaseContextMenuUtils.enforceDividerRules(this.getAnalyzeFilterMenuOptions(item));
			}

			if (item.type === FilterTypes.PREDEFINED) {
				return BaseContextMenuUtils.enforceDividerRules(this.getPredefinedFilterMenuOptions(item));
			}
			if (this.isScorecardFilter(item)) {
				let toggleOption = cloneDeep(item.disabled
					? this.OPTIONS.ENABLE
					: this.OPTIONS.DISABLE
				);
				if (!security.has('manage_scorecards')) {
					toggleOption.disabled = true;
					toggleOption.disabledMessage = locale.getString('common.noPermission');
				}
				return [this.OPTIONS.VIEW, toggleOption];
			}

			return [];
		}

		isVisibleObject(item: any): boolean {
			return this.scope.selectionUtils.visibleObjFilter(item);
		}

		private isScorecardFilter(item: IFilter): boolean {
			return item.type === FilterTypes.CXSCORECARD;
		}

		private hasDateRangeFilter(filters): boolean {
			return _.some(filters, (filter: any) => filter.dateFilter);
		}

		private getMultiSelectMenuOptions(): any[] {
			let items: any[] = [];

			let selectedFilters = _.filter(this.scope.filterList, (filter) =>
				this.scope.selectionUtils.isSupportedType(filter) && filter.selected
			);

			let isOwner = (filters): boolean => {
				return _.filter(filters, this.isOwner).length === filters.length;
			};

			let isStudioFilters = (filters): boolean => {
				return _.every(filters, (filter: any) => filter.type === FilterTypes.CXSTUDIO || filter.type === FolderTypes.FILTER);
			};

			if (isStudioFilters(selectedFilters)) {
				let moveToItems = _.uniq(_.flatten(_.map(selectedFilters, (filter) => {
					let bulkFunction = this.wrapFunctionForBulkAction(
						(item, folderTo) => this.actionsService.moveToFolder(item, folderTo), selectedFilters);

					return this.folderSvc.getFoldersForMove(this.scope.filterList, filter, bulkFunction);
				})), false, (moveItem) => moveItem.text);

				if (moveToItems && moveToItems.length > 0) {
					items.push(this.extend(this.OPTIONS.MOVE, {items: moveToItems}));
				}
			}

			if (manageFilterService.hasSharePermission() && isStudioFilters(selectedFilters)) {
				items.push(this.extend(this.OPTIONS.SHARE, {func: () =>
					this.actionsService.shareFilters(selectedFilters)}));
			}

			if (_.filter(selectedFilters, (filter: any) => {
				return (filter.ownerId === security.loggedUser.user.userId || filter.permissionLevel === AssetPermission.EDIT)
						&& filter.type !== FilterItemType.ANALYZE;
			}).length === selectedFilters.length) {
				let bulkEditLabels = () => {
					let ids = _.pluck(selectedFilters, 'id');
					this.actionsService.bulkUpdateLabels().result.then(labels => {
						let updateLabelsEntity = new BulkUpdateLabelsEntity(labels, ids);
						this.actionsService.updateLabelsRequest(updateLabelsEntity).then(updateLabelsPromise => {
							const updatedFilters = updateLabelsPromise;
							_.each(updatedFilters, updatedFilter => {
								updatedFilter.selected = false;
							});
							const msg = `${updatedFilters.length} ${locale.getString('common.filters')}`;
							downgradeToastService.addToast(`${locale.getString('filter.updatedLabel')} ${msg}.`);
							this.scope.refreshGrid(updatedFilters);
						}).catch(err => {
							_.noop();
						});
					}).catch(err => {
						_.noop();
					});
				};
				items.push(MenuDivider, this.extend(this.OPTIONS.EDIT_LABELS, {func: bulkEditLabels}));
			}

			let showHideOptions = this.getShowHideOptions(selectedFilters);
			items.push(MenuDivider, this.extend(this.OPTIONS.SHOW_HIDE, { items: showHideOptions }));

			if (isOwner(selectedFilters) && isStudioFilters(selectedFilters)) {
				items.push(MenuDivider, this.extend(this.OPTIONS.BULK_REMOVE_FILTER, { func: () =>
					this.actionsService.bulkDeleteFilters(selectedFilters)}));

				this.addTransferringOption(items, selectedFilters);
			}

			if (this.filtersSelectedAreScorecard(selectedFilters)) {
				items = this.getEnabledDisabledBulkOptions(selectedFilters);

			}

			return items;
		}

		private filtersSelectedAreScorecard(selectedItems: IFilter[]): boolean {
			return _.every(selectedItems, this.isScorecardFilter);
		}

		private getEnabledDisabledBulkOptions(selectedFilters: IFilter[]): any[] {
			let options = [];
			let enableOption = angular.copy(this.OPTIONS.ENABLE);
			let disableOption = angular.copy(this.OPTIONS.DISABLE);
			let enabledScorecardFilters = _.filter(selectedFilters, filter => !filter.disabled);
			let disabledScorecardFilters = _.filter(selectedFilters, filter => filter.disabled);



			enableOption.func = this.wrapFunctionForBulkAction(
				(item) => this.actionsService.toggleScorecardFilterState(item, false), selectedFilters);


			disableOption.func = this.wrapFunctionForBulkAction(
				(item) => this.actionsService.toggleScorecardFilterState(item, true), selectedFilters);

			if (enabledScorecardFilters.length) {
				options.push(disableOption);
			}
			if (disabledScorecardFilters.length) {
				options.push(enableOption);
			}

			return options;
		}

		private addTransferringOption(menuItems: any[], selectedItems: any[]): void {
			if (!(this.scope.project.projectId > 0) || this.hasDateRangeFilter(selectedItems)) {
				return;
			}

			menuItems.push(this.extend(this.OPTIONS.TRANSFER_SELECTED, {
				func: () => {
					let projectIdentifier = this.scope.project;
					this.actionsService.bulkTransferFilters(selectedItems, projectIdentifier).then(() => {
						this.scope.selectionUtils.clearSelections();
						this.scope.selectionUtils.refreshObjects(selectedItems);
					});
				}
			}));
		}

		private getFolderMenuOptions(item: any): any[] {

			if (item.id === FilterTypes.CXDATE) {
				return [];
			}

			let items = [];

			if (security.has(MasterAccountPermissionAction.CREATE_FILTER))
				items.push(this.OPTIONS.CREATE_FILTER);

			if (item.level < FolderContextMenuUtils.MAX_DEPTH - 1) {
				items.push(this.OPTIONS.CREATE_SUBFOLDER);
			}

			items.push(this.OPTIONS.RENAME_FOLDER);

			let moveToItems = this.folderSvc.getFoldersForMove(this.scope.filterList, item, (filter, folderTo) => {
				this.actionsService.moveToFolder(filter, folderTo);
			});

			if (moveToItems && moveToItems.length > 0 && !item.dateFilter) {
				items.push(this.extend(this.OPTIONS.MOVE, {items: moveToItems}));
			}

			if (item.level > 0) {
				items.push(this.OPTIONS.REMOVE_SUBFOLDER);
			} else {
				items.push(this.OPTIONS.REMOVE_FOLDER);
			}

			return items;
		}

		private getStudioFilterMenuOptions(item: any): any[] {
			let items: any[] = [];
			let moveToItems = this.folderSvc.getFoldersForMove(this.scope.filterList, item, (filter, folderTo) => {
				this.actionsService.moveToFolder(filter, folderTo);
			});

			let canCreateFilter = security.has('create_filter');

			if (this.isOwner(item)) {
				if (canCreateFilter) {
					items.push(this.OPTIONS.EDIT);
					items.push(this.OPTIONS.RENAME_FILTER);
				} else {
					items.push(this.OPTIONS.VIEW);
				}

				if (manageFilterService.canShare(item))
					items.insert(3, this.OPTIONS.SHARE);

				if (betaFeaturesService.isFeatureEnabled(BetaFeature.FILTER_TEMPLATES)
						&& security.isAdminOrgUser()
						&& security.has(MasterAccountPermissionAction.CREATE_FILTER)
						&& security.has(ApplicationPermissionAction.CREATE_INTERNAL_TEMPLATES)) {
					items.push(this.OPTIONS.CREATE_TEMPLATE);
				}

				items.push(MenuDivider);

				if (moveToItems && moveToItems.length > 0 && !item.dateFilter) {
					items.push(this.extend(this.OPTIONS.MOVE, {items: moveToItems}));
				}

				if (canCreateFilter)
					items.push(this.OPTIONS.COPY);

				let showHideOptions = this.getShowHideOptions(item);
				items.push(MenuDivider, this.extend(this.OPTIONS.SHOW_HIDE, { items: showHideOptions }));
				items.push(this.OPTIONS.DEPENDENCIES);

				items.push(MenuDivider, this.OPTIONS.REMOVE_FILTER);
				this.addTransferringOption(items, [item]);
			} else {
				let canEditFilter = this.canEditFilter(item);

				if (canCreateFilter && canEditFilter) {
					items.push(this.OPTIONS.EDIT);
				} else {
					items.push(this.OPTIONS.VIEW);
				}

				if (manageFilterService.canShare(item)) {
					items.push(this.OPTIONS.SHARE);
				}

				if (canCreateFilter) {
					items.push(this.OPTIONS.COPY);
				}

				if (moveToItems && moveToItems.length > 0 && !item.dateFilter) {
					items.unshift(this.extend(this.OPTIONS.MOVE, {items: moveToItems}));
				}

				let showHideOptions = this.getShowHideOptions(item);
				items.push(MenuDivider, this.extend(this.OPTIONS.SHOW_HIDE, { items: showHideOptions }));
				if (canEditFilter) {
					items.push(this.OPTIONS.DEPENDENCIES);
				}
			}
			return items;
		}

		private canEditFilter = (filter: IFilter): boolean => {
			return AssetPermission.EDIT === filter.permissionLevel;
		};

		private getShowHideOptions = (item: any | any[]) => {
			let showOption = angular.copy(this.OPTIONS.SHOW);
			let hideOption = angular.copy(this.OPTIONS.HIDE);
			let toggleVisibility = (this.scope.ui.hideFilters) ?
				this.OPTIONS.SHOW_HIDDEN :
				this.OPTIONS.HIDE_HIDDEN;

			if (item.constructor === Array) {
				let selectedItems = item;
				hideOption.func =
					this.wrapFunctionForBulkAction(
						this.wrapToggleFunction(true, (oneItem) => this.actionsService.toggleHide(oneItem)), selectedItems);
				showOption.func =
					this.wrapFunctionForBulkAction(
						this.wrapToggleFunction(false, (oneItem) => this.actionsService.toggleHide(oneItem)), selectedItems);
			} else {
				if (item.hide) {
					hideOption.disabled = true;
					hideOption.func = _.noop;
				} else if (!item.hide) {
					showOption.disabled = true;
					showOption.func = _.noop;
				}
			}

			return [showOption, hideOption, MenuDivider, toggleVisibility];
		};

		private getAnalyzeFilterMenuOptions(item: any): any[] {
			let items: any[] = [];

			items.push(this.OPTIONS.DEPENDENCIES);
			let showHideOptions = this.getShowHideOptions(item);
			items.push(MenuDivider, this.extend(this.OPTIONS.SHOW_HIDE, { items: showHideOptions }));

			return items;
		}

		private isOwner(filter: any): boolean {
			return manageFilterService.isOwner(filter);
		}

		private getPredefinedFilterMenuOptions(item: any): any[] {
			// can edit sentiment only when project is selected
			return this.actionsService.canEditPredefinedFilter(item)
				? [this.OPTIONS.EDIT_DEFINITION]
				: [this.OPTIONS.VIEW_DEFINITION];
		}
	};
});
