import { CustomMathAdapterUtils } from '@app/modules/metric/definition/custom-math/adapter/custom-math-adapter-utils.class';
import { TextTokenType } from '@app/modules/metric/definition/custom-math/adapter/formula-segment';
import { MathMetricToken, MathMetricTokenType } from '@app/modules/metric/definition/custom-math/editor/math-metric-token';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { ScorecardMetric } from '@cxstudio/projects/scorecards/entities/scorecard-metric';
import { CustomMathMetrics } from '../adapter/custom-math-metrics';
import { HierarchyMetricAdapter } from '../adapter/hierarchy-metric-adapter.service';
import { AttributeType } from '@app/modules/project/attribute/attribute-type';
import { ReportableHierarchy } from '@app/modules/hierarchy/enrichment/reportable-hierarchy';
import { QualtricsIconsId } from '@discover/unified-icons/src/types/qualtrics-icons';

interface InlineHelpElement {
	icon: QualtricsIconsId;
	text: string;
	visible: boolean;
}

export interface TokenSuggestion {
	tokenType: MathMetricTokenType;
	displayName: string;
	metricName?: string;

	inlineHelpElements?: InlineHelpElement[];

	insertValue: (isWrapped?: boolean, leadingTokens?: MathMetricToken[], textToWrap?: string) => string;
	getCaretPositionAfterInsert: (isWrapped?: boolean, leadingTokens?: MathMetricToken[], textToWrap?: string) => number;
	onAfterInsert?: (leadingTokens: MathMetricToken[]) => AfterInsertOptions | undefined;
}

export interface AfterInsertOptions {
	suggestedWrap?: boolean;
	typeToSuggest?: MathMetricTokenType;
}

export class CustomMathSuggestion {
	private static suggestionToTextTokenType = CustomMathSuggestion.getSuggestionToTextTokenTypeMapping();

	private static getSuggestionToTextTokenTypeMapping(): Map<MathMetricTokenType, TextTokenType[]> {
		let mapping = new Map<MathMetricTokenType, TextTokenType[]>();
		mapping.set(MathMetricTokenType.NUMERIC_ATTRIBUTE, [TextTokenType.NUMERIC_ATTRIBUTE, TextTokenType.TEXT_ATTRIBUTE]);
		mapping.set(MathMetricTokenType.TEXT_ATTRIBUTE, [TextTokenType.TEXT_ATTRIBUTE]);
		mapping.set(MathMetricTokenType.PREDEFINED_METRIC, [TextTokenType.PREDEFINED_METRIC]);
		mapping.set(MathMetricTokenType.METRIC, [TextTokenType.METRIC]);
		mapping.set(MathMetricTokenType.HIERARCHY_METRIC, [TextTokenType.HIERARCHY_METRIC]);
		mapping.set(MathMetricTokenType.SCORECARD_METRIC, [TextTokenType.SCORECARD_METRIC]);
		return mapping;
	}

	static getTextTokenTypes(suggestion: TokenSuggestion): TextTokenType[] {
		return this.suggestionToTextTokenType.get(suggestion.tokenType) || [];
	}


	static getAggregationSuggestion(word: string, tokenType: MathMetricTokenType): TokenSuggestion {
		return this.getKeywordSuggestion(word, tokenType, true);
	}

	static getMathFunctionSuggestion(word: string): TokenSuggestion {
		return this.getKeywordSuggestion(word, MathMetricTokenType.RESERVED_WORD, false);
	}

	private static getKeywordSuggestion(word: string, tokenType: MathMetricTokenType, aggregation: boolean): TokenSuggestion {
		// reserved words should all be inserted with parenthesis after
		let open = aggregation ? '[' : '(';
		let close = aggregation ? ']' : ')';
		return {
			tokenType,
			displayName: word,
			insertValue: (isWrapped?: boolean, leadingTokens?: MathMetricToken[], textToWrap?: string) => {
				if (textToWrap) {
					return isWrapped ? `${word}` : `${word}${textToWrap}`;
				}
				return isWrapped ? `${word}` : `${word}${open}${close}`;
			},
			getCaretPositionAfterInsert: (isWrapped?: boolean, leadingTokens?: MathMetricToken[], textToWrap?: string): number => {
				if (textToWrap) {
					return `${word}${textToWrap}`.length;
				}
				return word.length + 1; //to put after open bracket
			}
		};
	}

	static getAttributeSuggestion(attr: IReportAttribute): TokenSuggestion {
		if (attr.type === AttributeType.NUMBER) {
			// if it's numeric and not wrapped in a function, jump to the beginning and pop open suggestions
			return {
				tokenType: MathMetricTokenType.NUMERIC_ATTRIBUTE,
				displayName: attr.displayName,
				insertValue: (isWrapped: boolean): string => {
					return isWrapped ? attr.displayName : `[${attr.displayName}]`;
				},
				onAfterInsert: (leadingTokens: MathMetricToken[]): AfterInsertOptions | undefined => {
					if (!this.isInsideAggregateFunction(TextTokenType.NUMERIC_AGGREGATION, leadingTokens)
						&& !this.isInsideAggregateFunction(TextTokenType.TEXT_AGGREGATION, leadingTokens)
						&& !this.isInsideAggregateFunction(TextTokenType.GENERIC_AGGREGATION, leadingTokens)) {
						return {
							suggestedWrap: true,
							typeToSuggest: MathMetricTokenType.NUMERIC_AGGREGATION
						};
					}
				},
				getCaretPositionAfterInsert: (isWrapped: boolean): number => {
					return isWrapped ? attr.displayName.length + 1 : 0;
				}
			};
		} else {
			// not numeric attributes are considered as text ones
			return {
				tokenType: MathMetricTokenType.TEXT_ATTRIBUTE,
				displayName: attr.displayName,
				insertValue: (isWrapped: boolean): string => {
					return isWrapped ?
						attr.displayName :
						`count distinct[${attr.displayName}]`;
				},
				getCaretPositionAfterInsert: (isWrapped: boolean): number => {
					return isWrapped ? attr.displayName.length + 1 :
						`count distinct[${attr.displayName}]`.length;
				}
			};
		}
	}

	private static isInsideAggregateFunction(aggregationTokenType: TextTokenType, tokens: MathMetricToken[]): boolean {
		if (tokens?.length > 1 && tokens[0].text === '[') {
			return tokens[1].type === aggregationTokenType;
		} else if (tokens?.length > 2 && tokens[0].text === '' && tokens[1].text === '[') {
			return tokens[2].type === aggregationTokenType;
		}
		return false;
	}

	static getPredefinedMetricSuggestion(metric: Metric): TokenSuggestion {
		return this.baseMetricSuggestion(metric.displayName, MathMetricTokenType.PREDEFINED_METRIC);
	}

	static getMetricSuggestion(metric: Metric, warningMessage: string): TokenSuggestion {
		return this.baseMetricSuggestion(metric.displayName, MathMetricTokenType.METRIC, 'metric', metric.name, warningMessage);
	}

	static getScorecardMetricSuggestion(metric: ScorecardMetric, warningMessage: string): TokenSuggestion {
		return this.baseMetricSuggestion(metric.displayName, MathMetricTokenType.SCORECARD_METRIC, 'scorecard', metric.name, warningMessage);
	}

	static getHierarchySuggestions(hierarchy: ReportableHierarchy): TokenSuggestion[] {
		return hierarchy.properties
			.map(prop => `${hierarchy.name}${HierarchyMetricAdapter.HIERARCHY_PARAMETER_DELIMITER}${prop.name}`)
			.map(display => this.baseMetricSuggestion(display, MathMetricTokenType.HIERARCHY_METRIC, 'hierarchy'));
	}

	private static baseMetricSuggestion = (displayName: string, tokenType: MathMetricTokenType, prefix: string = '',
		metricName?: string, warningMessage?: string): TokenSuggestion => {
		let getTextToInsert = (isWrapped?: boolean) => {
			let insert = CustomMathAdapterUtils.escapeSpecialChars(displayName);
			return isWrapped ? `${insert}` : `${prefix}[${insert}]`;
		};

		let suggestion: TokenSuggestion = {
			tokenType,
			displayName,
			insertValue: getTextToInsert,
			getCaretPositionAfterInsert: (isWrapped?: boolean): number => {
				let insertText = getTextToInsert(isWrapped);
				return isWrapped ? insertText.length + 1 : insertText.length;
			}
		};

		if (tokenType === MathMetricTokenType.METRIC || tokenType === MathMetricTokenType.SCORECARD_METRIC) {
			suggestion.metricName = metricName;
			let inlineWarning: InlineHelpElement = {
				icon: 'warning',
				text: warningMessage,
				visible: false
			};
			suggestion.inlineHelpElements = [inlineWarning];
		}

		return suggestion;
	};

	// it's invoked for each of metric suggestions types separately
	static populateHelpMessages(suggestions: TokenSuggestion[], helpMessages: string[]): void {
		let identifiersMap = _.countBy(suggestions, suggestion => suggestion.displayName);
		_.each(suggestions, (suggestion, index) => {
			if (identifiersMap[suggestion.displayName] > 1) {
				let inlineHelp: InlineHelpElement = {
					icon: 'help',
					text: helpMessages[index],
					visible: true
				};
				suggestion.inlineHelpElements.push(inlineHelp);
			}
		});
	}

	// it's invoked for metricsSuggestions which contains metrics of predefined-metric, custom-metric and scorecard-metric types
	static populateShowWarning(suggestions: TokenSuggestion[], appliedMetrics: CustomMathMetrics): void {
		_.each(suggestions, suggestion => {
			if (suggestion.tokenType === MathMetricTokenType.METRIC) {
				this.populateShowWarningCondition(suggestion, appliedMetrics.customMetrics);
			} else if (suggestion.tokenType === MathMetricTokenType.SCORECARD_METRIC) {
				this.populateShowWarningCondition(suggestion, appliedMetrics.scorecardMetrics);
			}
		});
	}

	private static populateShowWarningCondition(suggestion: TokenSuggestion, metrics: any[]): void {
		let appliedMetricWithSameDisplayName = _.findWhere(metrics, {displayName: suggestion.displayName});
		if (appliedMetricWithSameDisplayName && suggestion.metricName !== appliedMetricWithSameDisplayName.name) {
			suggestion.inlineHelpElements[0].visible = true;
			return;
		}
		suggestion.inlineHelpElements[0].visible = false;
	}
}
