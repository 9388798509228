import { HomePageWidgetConstants } from '@app/modules/home-page/home-page-common/home-page-widget-constants';
import { ReportProjectContextService } from '@app/modules/project/context/report-project-context.service';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { WidgetDescriptionService } from '@app/modules/widget-container/widget-description/widget-description.service';
import { WidgetSettingTab } from '@app/modules/widget-settings/settings/widget-settings-tab.enum';
import { WidgetEditorService } from '@app/modules/widget-settings/widget-editor.service';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { FavoriteProperties } from '@cxstudio/auth/entities/favorite-properties';
import { Security } from '@cxstudio/auth/security-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { IgnoredDashboardFilterService } from '@cxstudio/dashboards/dashboard-filters/ignored-dashboard-filter-service';
import { IgnoredDashboardFilterTag } from '@cxstudio/dashboards/dashboard-filters/ignored-dashboard-filter-tag';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { FilterUtils } from '@cxstudio/report-filters/filter-utils.service';
import { ColorFunctionService } from '@cxstudio/reports/coloring/color-function.service';
import { ColorPaletteNames } from '@cxstudio/reports/coloring/color-palette-constants.service';
import { IColorSelectorPalette } from '@cxstudio/reports/coloring/color-selector.component';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { IWidgetFilterHistory, WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { IWidgetSettings } from '@cxstudio/reports/providers/cb/services/widget-settings.service';
import { WidgetConversionsService } from '@cxstudio/reports/providers/cb/widget-conversions.service';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { IReportSettingsScope } from '@cxstudio/reports/report-settings-modal.component';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';
import { SelectorWidgetUtils } from '@cxstudio/reports/widget-types/selector/selector-widget-utils';
import { ReportWidget } from '@cxstudio/reports/widgets/report-widget.class';
import { CustomFilterService } from '@cxstudio/services/custom-filter-service';
import { WidgetDataService } from '@cxstudio/services/data-services/widget-data-service.class';
import { WidgetDataServiceFactory } from '@cxstudio/services/data-services/widget-data-service.factory';
import { EventEmitterService, IHandlerCallback } from '@cxstudio/services/event/event-emitter.service';
import EventType from '@cxstudio/services/event/event-type.enum';
import WidgetService from '@cxstudio/services/widget-service';
import * as _ from 'underscore';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';
import { SelectorWidgetNavigationType } from '@app/modules/widget-settings/selector-widget/selector-widget-navigation-type.enum';
import { ObjectUtils } from '@app/util/object-utils';


interface IClarabridgeSettingsScope extends IReportSettingsScope {
	// modal bindings
	options: IWidgetSettingsOptions;
	widget: Widget;

	// modal component variables
	visualProps: VisualProperties;
	props: WidgetProperties;


	clearErrors: () => void;
	pushError: (error: any) => void;
	showErrorsForCP: any[];
	dashboardDefaultColor: any;
	dashboardFilters: { personalization: any };
	getVisibilityClasses: () => string[];
	getFilterTabIcon: () => string;
	getFilterTooltip: () => string;
	getClasses: () => string[];
	filterTabDisabled: () => boolean;
	getLoadingPromise: () => ng.IPromise<any>;
	clickTab: (tab: string) => void;
	getWidgetDefaultColor: () => IColorSelectorPalette;
	convertWidget: (targetWidget: ReportWidget) => void;
	updateAutoTitle: () => void;
	isInheritingDashboardDateFilter: () => boolean;
	isDashboardDateFilterPresent: () => any;
	getNodeDisplayName: (name: any, value: any, targetName: any, array: any) => any;
	getDisplayName: (name: any, value: any, targetName: any, array: any) => any;
	broadcastSettings: (eventName: any, value: any) => void;
	showFiltersLock: () => boolean;
	projectTimezone: any;
	intersectionObserver: any;
	//metricsForColoring: any; // potentially redundant
	conversions: any[];
	addLoadingPromise: (promise: any) => any;
	currentSettings: string;
	ui: Record<string, unknown>;
	settingsReady: any;
	loading: { isLoaded: boolean; globalPromise: any };
	visualizationTemplate: string;
	definitionTemplate: string;
	tabs: any[];
	projectSelection: IProjectSelection;
}

export interface IWidgetSettingsOptions extends IWidgetSettings {
	notRecommendedItems: any;
}

export class ClarabridgeSettings {

	latestStatsRequest: ng.IPromise<any>;
	recommendItemsEventHandler: IHandlerCallback;
	widgetFilterHistory: IWidgetFilterHistory;
	attributeStatsWaitlist: ReportGrouping[];
	attributeStatsMap: {[key: string]: boolean};
	conversionState: {[type in WidgetType]?: Widget} =  {}; // used to restore previous settings when switching between types

	widgetDataService: WidgetDataService;

	constructor(
		private $scope: IClarabridgeSettingsScope,
		private $log: ng.ILogService,
		private $q: ng.IQService,
		private $timeout: ng.ITimeoutService,
		private dashboardFiltersService: DashboardFiltersService,
		private widgetService: WidgetService,
		private colorUtils: ColorUtils,
		private colorFunctionService: ColorFunctionService,
		private widgetConversions: WidgetConversionsService,
		private security: Security,
		private customFilterService: CustomFilterService,
		private currentWidgets: ICurrentWidgets,
		private eventEmitterService: EventEmitterService,
		private filterUtils: FilterUtils,
		private locale: ILocale,
		private widgetEditorService: WidgetEditorService,
		private widgetDataServiceFactory: WidgetDataServiceFactory,
		private betaFeaturesService: BetaFeaturesService,
		private readonly reportProjectContextService: ReportProjectContextService,
		private readonly widgetsEditService: WidgetsEditService,
		private readonly widgetDescriptionService: WidgetDescriptionService,
		private readonly ignoredDashboardFilterService: IgnoredDashboardFilterService,
		private readonly realDataPreviewService: RealDataPreviewService,
	) {
		this.$scope.tabs = [];

		this.$scope.definitionTemplate = this.getDefinitionTemplate(this.$scope.props.widgetType);
		this.$scope.visualizationTemplate = this.getVisualizationTemplate(this.$scope.props.widgetType);

		this.$scope.loading = {
			isLoaded: false,
			globalPromise: null,
		};

		// shares some data across tabs
		this.$scope.options = {} as any;
		this.$scope.ui = this.$scope.ui || {};
		this.$scope.settingsReady = null;
		this.$scope.currentSettings = WidgetSettingTab.END_POINT_TAB;
		this.$scope.showErrorsForCP = [];

		this.widgetDataService = this.widgetDataServiceFactory.create(null);

		this.$scope.addLoadingPromise = (promise) => {
			if (this.$scope.loading.globalPromise) {
				this.$scope.loading.globalPromise = this.$q.all([this.$scope.loading.globalPromise, promise]);
			} else {
				this.$scope.loading.globalPromise = promise;
			}
			this.$q.when(this.$scope.loading.globalPromise).then(() => {
				this.$scope.loading.isLoaded = true;
			}, () => {
				this.$scope.loading.isLoaded = false;
				this.$scope.settingsReady = false;
			});
			return promise;
		};


		this.$scope.$watch('currentSettings', () => {
			this.$scope.$emit('widgetSettingsTabChanged', this.$scope.currentSettings);
		});

		//
		// events from child scopes
		//
		this.$scope.$on('contentProviderLoaded', (event, contentProvider) => {
			// nothing right now
			let newCreate = this.isNewWidgetInit();

			if (newCreate) {
				this.clearSettings(); // clear and init default props in all tabs
				let favoriteProps = this.security.getFavoriteProperties() || {} as FavoriteProperties;
				if (favoriteProps && (isEmpty($scope.props.contentProviderId) || $scope.props.contentProviderId === -1)) {
					$scope.props.contentProviderId = favoriteProps.favoriteCP || -1;
					$scope.props.accountId = isEmpty(favoriteProps.favoriteAccount) ? -1 : favoriteProps.favoriteAccount;
					$scope.props.project = favoriteProps.favoriteProject || -1;
				}
			}
			if (!contentProvider) {
				this.$scope.currentSettings = WidgetSettingTab.END_POINT_TAB;
			}
		});

		this.$scope.$on('accountLoaded', (event, account) => {
			if (ValueUtils.isNotSelected(account)) {
				this.$scope.currentSettings = WidgetSettingTab.END_POINT_TAB;
			}
		});
		this.$scope.$on('projectLoaded', (event, project, needReset) => {
			//this.$scope.project = project;
			if (project) {
				if (needReset) {
					this.clearSettings();
				}
				this.updateProjectSelection();
				this.reloadSettings();
				this.reloadTimezone();
			} else {
				this.clearSettings();
				this.$scope.currentSettings = WidgetSettingTab.END_POINT_TAB;
			}
		});

		this.$scope.$on('workspaceProjectUpdate', (event, workspaceProject: WorkspaceProject, needReset: boolean = false) => {
			let isNewWidget = this.isNewWidgetInit();
			if (isNewWidget) {
				this.clearSettings(); // clear and init default props in all tabs
			}
			if (WorkspaceProjectUtils.isProjectSelected(workspaceProject)) {
				if (needReset) {
					this.clearSettings();
				}
				this.reloadSettings();
				this.reloadTimezone();
			} else {
				if (!isNewWidget) {
					this.clearSettings();
				}
				this.$scope.currentSettings = WidgetSettingTab.END_POINT_TAB;
			}
		});

		this.initialTab();
		this.updateProjectSelection();

		this.$scope.conversions = [];

		this.reloadConversions();

		//attribute stats population
		this.$scope.$on('widget:updateRecommendedItems', () => this.updateRecommendedItems());
		this.recommendItemsEventHandler
			= this.eventEmitterService.subscribe(EventType.UPDATE_RECOMMEND_ITEMS, () => this.updateRecommendedItems());

		this.attributeStatsWaitlist = [];
		this.attributeStatsMap = {};
		$timeout(() => {
			if ('IntersectionObserver' in window) {
				let config = {
					root: document.querySelector('.br-widget-dialog'),
					rootMargin: '0px 0px 300px 0px',
					threshold: 0.5
				};
				this.$scope.intersectionObserver = new IntersectionObserver((entries, self) => {
					entries.forEach((entry: any) => {
						if (entry.isIntersecting) {
							let item = entry.target?.dataset;
							let statsItem = {name: item.hierarchyItemName, metricType: item.hierarchyItemMetricType};
							this.addAttributeStatsItem(statsItem);
						}
					});
				}, config);
			}
		}, 300);

		this.$scope.showFiltersLock = this.showFiltersLock;
		this.$scope.broadcastSettings = this.broadcastSettings;
		this.$scope.getDisplayName = this.getDisplayName;
		this.$scope.getNodeDisplayName = this.getNodeDisplayName;
		this.$scope.isDashboardDateFilterPresent = this.isDashboardDateFilterPresent;
		this.$scope.isInheritingDashboardDateFilter = this.isInheritingDashboardDateFilter;
		this.$scope.updateAutoTitle = this.updateAutoTitle;
		this.$scope.convertWidget = this.convertWidget;
		this.$scope.getWidgetDefaultColor = this.getWidgetDefaultColor;
		this.$scope.getLoadingPromise = this.getLoadingPromise;
		this.$scope.clickTab = this.clickTab;
		this.$scope.filterTabDisabled = this.filterTabDisabled;
		this.$scope.getFilterTooltip = this.getFilterTooltip;
		this.$scope.getFilterTabIcon = this.getFilterTabIcon;
		this.$scope.getClasses = this.getClasses;

		this.$scope.pushError = this.pushError;
		this.$scope.clearErrors = this.clearErrors;

		let containerId = $scope.widget.containerId
			? $scope.widget.containerId
			: $scope.widget.dashboardId + '';
		let dashboardHistory = this.currentWidgets.getDashboardHistory(containerId);
		this.$scope.dashboardFilters = {
			personalization: dashboardHistory.getPersonalization()
		};
	}

	// required to pass typescript validation for controller
	$onInit = () => {
		//empty
	};

	$onDestroy(): void {
		if (this.recommendItemsEventHandler) {
			this.recommendItemsEventHandler.unsubscribe();
		}
		if (this.$scope.intersectionObserver) {
			this.$scope.intersectionObserver.disconnect();
		}
	}

	private isNewWidgetInit(): boolean {
		return this.$scope.props === null
			|| _.isUndefined(this.$scope.props.contentProviderId)
			|| this.$scope.widget.created;
	}

	filterTabDisabled = (): boolean => {
		return SelectorWidgetUtils.isCustomSelection(this.$scope.props)
			|| this.$scope.widget.visualProperties.visualization === SelectorWidgetNavigationType.SELECTOR_SEARCH
			|| WidgetType.OBJECT_VIEWER === this.$scope.props.widgetType;
	};

	getFilterTooltip = (): string => {
		let customSelection = SelectorWidgetUtils.isCustomSelection(this.$scope.props);
		return customSelection ? this.locale.getString('selectorWidget.filterDisabledTitle') : '';
	};

	getLoadingPromise = (): ng.IPromise<any> => {
		return this.$scope.loading.globalPromise;
	};

	clickTab = (tab: string): void => {

		if (tab === this.$scope.currentSettings) {
			return;
		}

		if (this.realDataPreviewService.hasPreviewChanges()) {
			this.realDataPreviewService.setTabModified(true);
		}

		if (tab === WidgetSettingTab.VISUAL_TAB) {
			this.realDataPreviewService.setTabModified(true);
		}

		this.$scope.currentSettings = tab;
		if (tab === WidgetSettingTab.VISUAL_TAB || tab === WidgetSettingTab.SETTINGS_TAB) {
			this.$scope.currentSettings = tab;
			this.$scope.$broadcast('widget:updateRecommendedItems');
		}

		if (tab === WidgetSettingTab.END_POINT_TAB) {
			this.$scope.currentSettings = tab;
			this.updateAutoDescription();
		}
	};

	updateAutoDescription = () => {
		if (this.$scope.props.autoDescription) {
			this.widgetDescriptionService.generateDescription(this.$scope.widget).then((description) => {
				this.$scope.widget.description = description;
			});
		}
	};

	showFiltersLock = (): boolean => {
		return this.$scope.props.lockFilters;
	};

	getFilterTabIcon = (): string => {
		if (this.showFiltersLock()) return 'q-icon-lock';
		if (!!this.getFilterTooltip()) return 'q-icon-info text-info';
	};

	initialTab = () => {
		if (this.isAllEndpointIdsSelected()) {
			this.$scope.currentSettings = this.$scope.definitionTemplate
				? WidgetSettingTab.SETTINGS_TAB
				: WidgetSettingTab.VISUAL_TAB;
		}
	};

	isAllEndpointIdsSelected = (): boolean => {
		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE)) {
			return WorkspaceProjectUtils.isProjectSelected(this.$scope.props.workspaceProject);
		} else {
			return ValueUtils.isSelected(this.$scope.props.contentProviderId)
				&& ValueUtils.isSelected(this.$scope.props.accountId)
				&& ValueUtils.isSelected(this.$scope.props.project);
		}
	};

	clearSettings = (): void => {
		this.$log.debug('clearSettings');
		this.$scope.settingsReady = false;

		if (isEmpty(this.$scope.props.contentProviderId)) {
			this.$scope.props.contentProviderId = -1;
		}
		if (isEmpty(this.$scope.props.accountId)) {
			this.$scope.props.accountId = -1;
		}
		if (isEmpty(this.$scope.props.project)) {
			this.$scope.props.project = -1;
		}
		this.updateProjectSelection();
		this.$scope.projectTimezone = null;

		this.$scope.$broadcast('clearSettings');
		this.widgetEditorService.clearSettings();
		this.conversionState = {};
	};

	private updateProjectSelection(): void {
		let projectIdentifier = ProjectIdentifier.fromWidgetProperties(this.$scope.props);
		if (ProjectIdentifier.isProjectSelected(projectIdentifier)
			|| InternalProjectTypes.isAdminProject(projectIdentifier.projectId)) {
			this.$scope.projectSelection = projectIdentifier;
		} else {
			delete this.$scope.projectSelection;
		}
	}

	broadcastSettings = (eventName, value) => {
		this.$scope.$broadcast(eventName, value);
	};

	getDisplayName = (name, value, targetName, array) => {
		if (name === null || value === null || targetName === null || !array) { // array can be undefined or null
			return 'N/A';
		}

		for (let item of array) {
			if (item[name] === value || item[name] === parseInt(value, 10)) {
				return item[targetName];
			}
		}
		return 'N/A';
	};

	getNodeDisplayName = (name, value, targetName, array) => {
		if (name === null || value === null || targetName === null || array === null) {
			return;
		}

		for (let item of array) {
			if (item.root) {
				if (item.root[name] === value) {
					return item.root[targetName];
				}
				let result = this.getNodeDisplayNameInternal(name, value, targetName, item.root.children);
				if (result) {
					return result;
				}
			}
		}
	};

	isDashboardDateFilterPresent = () => {
		return this.dashboardFiltersService.isDashboardDateFilterApplied(this.widgetsEditService.getDashboard());
	};

	// need to move this into service instead of passing to each controller
	isInheritingDashboardDateFilter = (): boolean => {
		let ignoredFilters: IgnoredDashboardFilterTag[] = this.$scope.props.ignoredDashboardFilters;

		return this.isDashboardDateFilterPresent()
				&& !this.ignoredDashboardFilterService.hasDateTag(ignoredFilters);
	};

	updateAutoTitle = (): void => {
		this.$scope.widget.displayName = this.widgetService.updateAutoTitle(
			this.$scope.props, this.$scope.options);
	};

	convertWidget = (targetWidget: ReportWidget) => {
		let sourceWidget = ObjectUtils.copy(this.$scope.widget);
		sourceWidget.properties = ObjectUtils.copy(this.$scope.props);
		sourceWidget.visualProperties = ObjectUtils.copy(this.$scope.visualProps);
		if (sourceWidget.properties.widgetType === 'cb_an_table') {
			_.each(sourceWidget.properties.selectedAttributes, (attribute: any) => {
				if (attribute.size && attribute.size > 1000) {
					attribute.size = 1000;
				}
			});
			delete sourceWidget.visualProperties.color; // it was set to 'sentiment'
		}
		this.conversionState[sourceWidget.properties.widgetType] = ObjectUtils.copy(sourceWidget);
		targetWidget.initFrom(sourceWidget, undefined, this.conversionState[targetWidget.getWidgetType()]);
		let convertedWidget = targetWidget.getWidget();

		this.$scope.updateWidget(convertedWidget);

		this.$scope.tabs.forEach((tabScope) => {
			tabScope.initTab();
		});
		this.$scope.visualizationTemplate = this.getVisualizationTemplate(this.$scope.props.widgetType);
		this.$scope.definitionTemplate = this.getDefinitionTemplate(this.$scope.props.widgetType);

		let timer = this.$timeout(() => {
			this.reloadConversions();
			this.reloadSettings();
			this.$timeout.cancel(timer);
		}, 1);

	};

	getWidgetDefaultColor = (): IColorSelectorPalette => {
		if (!this.$scope.options.studioPalettes) // not loaded yet
			return undefined;
		if (!this.$scope.dashboardDefaultColor && this.widgetsEditService.getDashboard()) {
			let colorObject = this.colorUtils.getDashboardDefaultColor(
				this.widgetsEditService.getDashboard().properties);
			if (!colorObject) {
				colorObject = {
					name: _.findWhere(this.$scope.options.studioPalettes, {defaultPalette: true}).name
				};
			}
			if (colorObject.name !== ColorPaletteNames.CUSTOM) {
				const colors = this.colorFunctionService.getBuilder()
					.withPalettes(this.$scope.options.studioPalettes, this.$scope.options.providerColors)
					.buildColorArray(colorObject.name);
				colorObject.customColor = colors[0];
			}
			if (!colorObject.customColor) {
				colorObject.customColor = ColorUtilsHelper.DEFAULT_CUSTOM_COLOR;
			}
			this.$scope.dashboardDefaultColor = colorObject;
		}
		return this.$scope.dashboardDefaultColor;
	};

	private getDefinitionTemplate = (widgetType: string): string => {
		let path = 'partials/widgets/settings/cb/definitions/';
		switch (widgetType) {
			case 'cb_an_table' : return path + 'cb-analytic-table-definition.html';
			case 'cb_an_scorecard' : return path + 'cb-analytic-scorecard-definition.html';
			case 'cb_an_selector' : return undefined; // selector has no definition tab
		}
		return null;
	};

	private getVisualizationTemplate = (widgetType: WidgetType): string => {
		let path = 'partials/widgets/settings/cb/visualizations/';
		switch (widgetType) {
			case 'cb_an_preview' : return path + 'cb-analytic-preview-visual-settings.html';
			case 'cb_an_scorecard':
			case 'cb_an_table' : return path + 'cb-analytic-table-visual-settings.html';
			case 'cb_an_metric' : return path + 'cb-new-analytic-metric-visual-settings.html';
			case 'cb_an_line' : return path + 'cb-analytic-dual-visual-settings.html?type=line';
			case 'cb_an_bar' : return path + 'cb-analytic-dual-visual-settings.html?type=bar';
			case 'cb_an_heatmap' : return path + 'cb-analytic-heatmap-visual-settings.html';
			case 'cb_an_cloud' : return path + 'cb-analytic-cloud-visual-settings.html';
			case 'cb_an_pie' : return path + 'cb-analytic-pie-visual-settings.html';
			case 'cb_an_scatter' : return path + 'cb-analytic-scatter-visual-settings.html';
			case 'cb_an_selector' : return path + 'selector-visualization.html';
			case 'cb_an_network' : return path + 'cb-analytic-network-visual-settings.html';
			case 'cb_an_map': return path + 'cb-analytic-map-definition-visual-settings.html';
			case 'cb_object_viewer' : return path + 'cb-object-viewer-visual-settings.html';
			case 'cb_an_model_viewer' : return path + 'model-viewer-visual-settings.html';
		}
		return null;
	};

	private reloadSettings = (): void => {
		this.$log.debug('Reloading settings');
		this.$scope.settingsReady = false;
		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE)) {
			if (!WorkspaceProjectUtils.isProjectSelected(this.$scope.props.workspaceProject)) {
				this.$scope.settingsReady = false;
				return;
			}
		} else {
			if (ValueUtils.isNotSelected(this.$scope.props.contentProviderId)
					|| ValueUtils.isNotSelected(this.$scope.props.project)) {
				this.$scope.settingsReady = false;
				return;
			}
		}

		let callback = (ready, error) => {
			this.$scope.settingsReady = ready;
			if (!ready) {
				this.$scope.currentSettings = WidgetSettingTab.END_POINT_TAB;
				if (error) this.$scope.pushError(error);
			}
			this.reloadConversions();
		};
		this.$scope.$broadcast('reloadSettings', callback);
		this.widgetEditorService.reloadSettings(callback);
	};

	private reloadTimezone = (): void => {
		this.reportProjectContextService.getWidgetProjectTimezone(this.$scope.widget).then((timezone) => {
			this.$scope.projectTimezone = timezone;
		});
	};

	private getNodeDisplayNameInternal = (name, value, targetName, array): string[] => {
		if (name === null || value === null || targetName === null || array === null) {
			return;
		}

		for (let item of array) {
			if (item[name] === value) {
				return item[targetName];
			}
			if (item.children) {
				let result = this.getNodeDisplayNameInternal(name, value, targetName, item.children);
				if (result) {
					return result;
				}
			}
		}
	};

	private reloadConversions = () => {
		if (InternalProjectTypes.isAdminProject(this.$scope.props.project)) {
			this.$scope.conversions = [];
			return;
		}

		this.$scope.conversions = this.widgetConversions.getSublist(
			_.without(this.getSupportedConversions(), this.$scope.props.widgetType));
	};

	private getSupportedConversions(): WidgetType[] {
		if (HomePageWidgetConstants.isHomePageWidget(this.$scope.widget)) {
			return [
				WidgetType.BAR,
				WidgetType.LINE,
				WidgetType.PIE,
			];
		} else {
			return [
				WidgetType.BAR,
				WidgetType.LINE,
				WidgetType.TABLE,
				WidgetType.SCATTER,
				WidgetType.PIE,
				WidgetType.HEATMAP,
				WidgetType.CLOUD,
			];
		}
	}

	private updateRecommendedItems(): void {
		this.$scope.options.notRecommendedItems = this.$scope.options.notRecommendedItems || {};
		let items = this.$scope.options.notRecommendedItems;
		items.models = items.models || {};
		items.attributes = items.attributes || {};
		let currentRequest = this.latestStatsRequest = this.getRecommendedItems().then((response) => {
			if (currentRequest !== this.latestStatsRequest) {
				return; //not latest
			}

			if (response && response.data) {
				let data = response.data;
				let notRecommendedAttributes = this.toNotRecommendedItems(data.attributes, 'name');
				let notRecommendedModels = this.toNotRecommendedItems(data.models, 'id');
				_.each(Object.keys(notRecommendedAttributes), (key) => {
					items.attributes[key] = true;
				});
				_.each(Object.keys(notRecommendedModels), (key) => {
					items.models[key] = true;
				});
			}
		});
	}

	private toNotRecommendedItems(items, field): any {
		let itemMap = {};
		_.each(items, (item: any) => {
			if (!item.hasValue) {
				const key = item[field] + '';
				itemMap[key] = true;
			}
		});
		return itemMap;
	}

	//grey out not recommend attributes in options
	private getRecommendedItems(): ng.IPromise<any> {
		if (this.isAllEndpointIdsSelected()
				&& ReportConstants.isAnalyticWidget(this.$scope.props.widgetType)
				&& !InternalProjectTypes.isAdminProject(this.$scope.props.project)) {
			let widgetSettings = this.getAttributeStatsProperties();
			let filtersProvider = this.currentWidgets.getDashboardHistory(widgetSettings.containerId);
			let promise = this.customFilterService.postprocessWidgetProperties(widgetSettings, filtersProvider);
			return promise.then((updatedSettings) => {
				let newWidgetFilterHistory = _.pick(updatedSettings.properties, this.filterUtils.getWidgetFilterFields());
				if (this.filterUtils.isWidgetFiltersChanged(this.widgetFilterHistory, newWidgetFilterHistory)) {
					this.$scope.options.notRecommendedItems.models = {};
					this.$scope.options.notRecommendedItems.attributes = {};
					this.attributeStatsMap = {};
				}
				updatedSettings.properties.selectedAttributes = ObjectUtils.copy(this.attributeStatsWaitlist);
				this.attributeStatsWaitlist = [];
				this.widgetFilterHistory = newWidgetFilterHistory;
				return !_.isEmpty(updatedSettings.properties.selectedAttributes)
					? this.widgetDataService.getReportData(updatedSettings)
					: this.$q.when();
			});
		}
		return this.$q.when();
	}

	private getAttributeStatsProperties(): Widget {
		let widgetSettings = ObjectUtils.copy(this.$scope.widget) as Widget;
		widgetSettings.properties = ObjectUtils.copy(this.$scope.props) as WidgetProperties;
		widgetSettings.name = WidgetType.ATTR_STATS;
		widgetSettings.properties.widgetType = WidgetType.ATTR_STATS;
		delete widgetSettings.visualProperties;
		widgetSettings.properties.selectedAttributes = [];
		widgetSettings.properties.selectedMetrics = [];
		this.customFilterService.preprocessWidgetProperties(widgetSettings);
		return widgetSettings;
	}

	private addAttributeStatsItem(item): void {
		let statsItem = _.pick(item, 'name', 'metricType') as ReportGrouping;
		if (!this.attributeStatsMap[statsItem.name]) {
			this.attributeStatsWaitlist.push(statsItem);
			this.attributeStatsMap[statsItem.name] = true;
		}
	}

	getClasses = (): string[] => {
		let classes = [];
		if (HomePageWidgetConstants.isHomePageWidget(this.$scope.widget))
			classes.push('home-page-widget');
		return classes;
	};

	private pushError = (error) => {
		if (error.constructor === Array)
			this.$scope.showErrorsForCP.pushAll(error);
		else
			this.$scope.showErrorsForCP.push(error);
	};

	private clearErrors = () => {
		this.$scope.showErrorsForCP.length = 0;
	};
}


app.controller('ClarabridgeSettingsCtrl', ClarabridgeSettings);
