import * as _ from 'underscore';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { IFolderService } from '@cxstudio/folders/folder-service.factory';
import ScorecardsApiService from '@cxstudio/projects/scorecards/scorecards-api-service';
import { ScorecardMetric } from '@cxstudio/projects/scorecards/entities/scorecard-metric';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { GeneratedFolderType } from '@cxstudio/report-filters/generated-folder-type';

export default class ScorecardMetricsService {
	folderSvc: IFolderService;
	SCORECARD_FOLDER;

	constructor(
		private scorecardsApiService: ScorecardsApiService,
		private FolderService,
		private betaFeaturesService: BetaFeaturesService,
		private locale: ILocale,
		private $q: ng.IQService
	) {
		this.folderSvc = new this.FolderService(FolderTypes.METRIC);

		this.SCORECARD_FOLDER = {
			name: this.locale.getString('metrics.scorecardMetrics'),
			id: ReportAssetType.SCORECARD,
			description: '',
			type: FolderTypes.METRIC,
			level: 0,
			_collapsed: true,
			generatedFolderType: GeneratedFolderType.SYSTEM,
			children: []
		};
	}

	getScorecardFolders = (
		scorecardMetrics: ScorecardMetric[],
		props: IProjectSelection,
		masterAccountId: number): any[] => {

		let propsObject = {
			masterAccountId,
			contentProviderId: props.contentProviderId,
			accountId: props.accountId,
			projectId: props.projectId};

		let scorecardFolders = [];
		let rootScorecardFolder = angular.copy(this.SCORECARD_FOLDER);

		scorecardMetrics.forEach((metric: ScorecardMetric): void => {
			let scorecardFolder = _.findWhere(scorecardFolders, {id: 'scorecard_' + metric.scorecardId});
			if (!scorecardFolder) {
				scorecardFolder = this.folderSvc.getScorecardsFolder(
					metric.scorecardName, metric.scorecardId, FolderTypes.METRIC, rootScorecardFolder);
				$.extend(scorecardFolder, propsObject);
				scorecardFolders.push(scorecardFolder);
			}

			metric.parentId = scorecardFolder.id;
			metric.id = metric.name; // scorecard metric doens't have numuric id, use name as unique id
		});

		if (!isEmpty(scorecardFolders)) {
			scorecardFolders.push(rootScorecardFolder);
		}

		return scorecardFolders;
	};

	getFullScorecardMetrics = (contentProviderId: number, accountId: number, projectId: number): ng.IPromise<ScorecardMetric[]> => {
		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)) {
			return this.scorecardsApiService.getFullScorecardMetrics(contentProviderId, accountId, projectId);
		} else {
			return this.$q.when([]);
		}
	};

}

app.service('scorecardMetricsService', ScorecardMetricsService);
