import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { PasswordResetContext } from '@app/modules/user/password-reset-context';

@Injectable({
	providedIn: 'root'
})
export class PasswordResetApiService {
	constructor(
		private readonly cxHttp: CxHttpService
	) {}

	requestResetPassword(email: string): Promise<void> {
		return this.cxHttp.post('rest/password-reset/request', {email});
	}

	generatePassword(passwordPolicy): Promise<string> {
		let params = new HttpParams()
			.append('min', passwordPolicy.minLength);
		return this.cxHttp.get(`rest/password-reset/generate`, {params});
	}

	getContext(): Promise<PasswordResetContext> {
		return this.cxHttp.get('rest/password-reset/context');
	}

	updatePassword(newPassword: string): Promise<void> {
		return this.cxHttp.post('rest/password-reset/perform', newPassword);
	}
}
