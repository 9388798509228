import { ChangeDetectionStrategy, EventEmitter, Output, Component, Inject, Input, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { Security } from '@cxstudio/auth/security-service';
import { WidgetIcons } from '@app/modules/dashboard/widget-templates.service';
import Widget, { IDrillObject } from '@cxstudio/dashboards/widgets/widget';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { HomePageWidgetConstants } from '../home-page/home-page-common/home-page-widget-constants';

interface IHistoryOption {
	displayName: string;
	onClick: () => void;
	class?: string;
}

@Component({
	selector: 'drill-breadcrumbs',
	template: `
<nav *ngIf="path.length" class="d-flex ph-16">
	<div ngbDropdown class="mr-8 __TEST-drill-history">
		<p class="mb-0">
			<a href="javascript:void(0);" ngbDropdownToggle class="font-size-1">
				<span class="q-icon q-icon-angle-left nested-angle" aria-hidden="true"></span>
				<span class="q-icon q-icon-angle-left nested-angle" aria-hidden="true"></span>
				<span class="q-icon q-icon-angle-left nested-angle" aria-hidden="true"></span>
			</a>
		</p>
		<ul ngbDropdownMenu class="dropdown-menu">
			<li *ngFor="let option of historyOptions" (click)="option.onClick()" class="{{option.class}}">
				<a href="javascript:void(0);"
					*ngIf="!option.class || option.class.indexOf('divider') === -1"
					[innerHTML]=option.displayName class="d-flex align-items-center h-2em"></a>
			</li>
		</ul>
	</div>

	<p class="breadcrumb-list mb-0 d-flex">
		<span class="mr-4">
			<a href="javascript:void(0);" (click)="resetDrill()">{{'common.main'|i18n}}</a>
		</span>

		<span class="mr-4 breadcrumb-ellipsis" *ngIf="isOverFlow()">/ ...</span>

		<span class="mr-4" *ngFor="let drillLevel of path | slice:(-1 * crumbsToShow); index as $index; let $last = last;">
			/ <span (click)="!$last && goTo(drillLevel)"
				[ngClass]="{'cursor-pointer action-color':!$last}"
				title="{{drillLevel.point.popName}}">{{drillLevel.point.displayName}}
				<span *ngIf="drillLevel.type === 'key_terms'">- {{'widget.keyTerms' | i18n}}</span>
				<span *ngIf="drillLevel.point.popName">*</span></span>
		</span>
	</p>
</nav>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DrillBreadcrumbsComponent implements OnInit {
	@Input() path: IDrillObject[];
	@Input() crumbsToShow: number = 3;
	@Input() rootWidget: Widget;
	@Output() onSetDrill = new EventEmitter<number>();
	@Output() onResetDrill = new EventEmitter<any>();
	@Output() onExport = new EventEmitter<any>();

	historyOptions: IHistoryOption[];

	constructor(
		private locale: CxLocaleService,
		@Inject('metricConstants') private metricConstants,
		@Inject('security') private security: Security,
	) {}


	ngOnInit(): void {
		this.path = this.path || [];
		this.crumbsToShow = this.crumbsToShow || undefined;
		this.historyOptions = this.getHistoryOptions();
	}

	backOne(): void {
		let priorDrillIndex = this.path.length - 2;
		this.setDrillToLevel(priorDrillIndex);
	}

	goTo(item: IDrillObject): void {
		// because we are limiting in the repeater, we cannot rely
		// on $index in the view to match the index in the array
		let actualIndex = _.indexOf(this.path, item);

		this.setDrillToLevel(actualIndex);
	}

	private setDrillToLevel(index): void {
		this.onSetDrill.emit(index);
	}

	resetDrill(): void {
		this.onResetDrill.emit();
	}

	isOverFlow(): boolean {
		return this.crumbsToShow && (this.path.length > this.crumbsToShow);
	}

	private getRootName(list: any[], identifier: string): string | undefined {
		let match = GroupIdentifierHelper.findGroup(list, identifier);

		if (match) return match.displayName;
	}

	getGroupName(item, previousItem?): string {
		item.point._group = item.point._group || {};

		let name;
		if (!previousItem) {
			name = this.getRootName(this.rootWidget.properties.selectedAttributes, item.point._group.identifier);
			if (name) return name;
		}

		if (/^[0-9]+$/.test(item.point._group.name)) {
			if (previousItem) {
				name = previousItem.point.groupBy.displayName;
			}
			if (name) return name;
		}

		if (item.type !== 'an_preview' && item.point.groupBy.metricType !== undefined) {
			if (previousItem) {
				name = previousItem.point.groupBy.displayName;
			}
			if (name) return name;
		}

		if (previousItem && previousItem.point) {
			switch (previousItem.point.groupBy.name) {
				case '_lc':
					return this.metricConstants.get().LC.displayName;
				case '_mstokenname':
					return this.metricConstants.get().WORDS.displayName;
				case '_hashtag':
					return this.metricConstants.get().HASHTAG.displayName;
			}
		}

		return item.point._group.name;
	}

	private getMenuItemHTML(item: IDrillObject, previousItem: IDrillObject): string {
		let icon = `<span class="mr-8 ${WidgetIcons[item.type]}" aria-hidden="true"></span>`;
		let text = `${this.getGroupName(item, previousItem)}: ${item.point.displayName}${item.point.popName ? '*' : ''}`;

		let retVal = item.point.popName
			? `<span title="${item.point.popName}">${icon} ${text}</span>`
			: `${icon} ${text}`;

		return retVal;
	}


	// reverse the path history, remove the latest item, and add the root level to the beginning
	getHistoryOptions(): IHistoryOption[] {
		let history: IHistoryOption[] = this.path.map((item, index, array) => {
			return {
				onClick: () => { this.setDrillToLevel(index); },
				displayName: this.getMenuItemHTML(item, array[index - 1])
			};
		}).reverse();

		history = history.splice(1);
		let mainIcon = `<span class="mr-8 ${WidgetIcons[this.rootWidget.properties.widgetType]}" aria-hidden="true"></span>`;
		let mainLabel = `${this.locale.getString('common.main')}`;
		history.push({
			displayName: `${mainIcon} ${mainLabel}`,
			onClick: () => this.onResetDrill.emit()
		});

		if (this.security.has('create_dashboard')
			&& this.rootWidget.containerId !== HomePageWidgetConstants.CONTAINER_ID
			&& this.rootWidget.id > 0 && !this.containKeyTerms()) {

			history.unshift({
				displayName: `<span class="drill-menu">${this.locale.getString('widget.exportToDashboard')}</span>`,
				onClick: () => this.onExport.emit(),
				class: 'hide-embed'
			}, { class: 'divider hide-embed' } as IHistoryOption);
		}

		return history;
	}

	private containKeyTerms(): boolean {
		return !!_.findWhere(this.path, {type: DrillType.KEY_TERMS});
	}
}

app.directive('drillBreadcrumbs', downgradeComponent({component: DrillBreadcrumbsComponent}));
