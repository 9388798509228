import { downgradeInjectable } from '@angular/upgrade/static';
import { IAdminAttribute } from '@app/modules/project/attribute/admin-attribute';
import { AdminAttributesApiService } from '@app/modules/project/attribute/admin-attributes-api.service';
import { WorkspaceAttributeManagementApiService } from '@app/modules/project/attribute/workspace-attribute-management-api.service';
import { PropertyValue } from '@app/modules/project/state-changes/property-changes';
import { AssetPromise, ProjectAssetsHelper } from '@app/modules/units/project-assets-helper.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ObjectListFilter } from '@app/shared/components/filter/object-list-filter';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Injectable } from '@angular/core';
import { AttributesStatsResponse } from '@app/modules/project/attribute/attributes-stats-response';

@Injectable({ providedIn: 'root' })
export class AttributeManagementService {

	constructor(
		private readonly adminAttributesApiService: AdminAttributesApiService,
		private readonly workspaceAttributeManagementApiService: WorkspaceAttributeManagementApiService,
	) {}

	getAdminAttributes(project: AccountOrWorkspaceProject, filters: ObjectListFilter[]): AssetPromise<IAdminAttribute[]> {
		return ProjectAssetsHelper.getAssetsForProject<IAdminAttribute[]>(
			project,
			(accountProject) => this.adminAttributesApiService.getAdminAttributes(accountProject, filters),
			(workspaceProject) => this.workspaceAttributeManagementApiService.getAdminAttributes(workspaceProject, filters),
			() => []
		);
	}

	getAttributesStats(project: AccountOrWorkspaceProject,
		filters: any[], cache = CacheOptions.CACHED): AssetPromise<AttributesStatsResponse> {
		return ProjectAssetsHelper.getAssetsForProject<AttributesStatsResponse>(
			project,
			(accountProject) => this.adminAttributesApiService.getAttributesStats(accountProject, filters, cache),
			(workspaceProject) => this.workspaceAttributeManagementApiService.getAttributesStats(workspaceProject, filters, cache),
			() => null,
		);
	}

	requestAttributesReport = (
		project: AccountOrWorkspaceProject, withHidden: boolean, filters: ObjectListFilter[], attributeOrder: number[]
	): AssetPromise<void> => {
		return ProjectAssetsHelper.getAssetsForProject<void>(
			project,
			(accountProject) => this.adminAttributesApiService.requestAttributesReport(
				accountProject, withHidden, filters, attributeOrder),
			(workspaceProject) => this.workspaceAttributeManagementApiService.requestAttributesReport(
				workspaceProject, withHidden, filters, attributeOrder),
			() => []
		);
	};

	saveAttributesSettings = (
		project: AccountOrWorkspaceProject, useInClarabridgeSearch: PropertyValue<boolean>[]
	): AssetPromise<void> => {
		return ProjectAssetsHelper.getAssetsForProject<void>(
			project,
			(accountProject) => this.adminAttributesApiService.saveAttributesSettings(accountProject, useInClarabridgeSearch),
			(workspaceProject) => this.workspaceAttributeManagementApiService.saveAttributesSettings(workspaceProject, useInClarabridgeSearch),
			() => []
		);
	};
}

app.service('attributeManagementService', downgradeInjectable(AttributeManagementService));
