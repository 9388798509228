import * as Highcharts from 'highcharts';
import { CloudNavigation } from '@app/modules/keyboard-navigation/cloud-navigation.service';
import { HighchartsVisualization } from '@app/modules/widget-visualizations/highcharts/highcharts-visualization.interface';
import { AnalyticDataUtils } from '@app/modules/widget-visualizations/utilities/analytic-data-utils.class';
import { ReportErrorMessagesService } from '@app/modules/widget-visualizations/utilities/report-error-messages.service';
import { GroupIdentifierHelper } from '../utils/analytic/group-identifier-helper';
import { PointSelectionUtils } from '../utils/analytic/point-selection-utils.service';
import { ReportDefinitionInitializers } from '../utils/report-definition-initializers.service';
import { ReportScopeUtils } from '../utils/report-scope-utils.service';
import { ReportUtils } from '../utils/visualization/report-utils.service';
import { CloudD3Definition } from './definitions/d3/cloud-d3-definition';
import { CloudRendererService } from './definitions/d3/renderers/cloud-d3-renderer.factory';

/**
 * Function should be named in camelCase
 * params - required services (auto-injected)
 */
app.directive('d3Cloud', (
	$timeout,
	tableService,
	pointSelectionUtils: PointSelectionUtils,
	reportUtils: ReportUtils,
	reportScopeUtils: ReportScopeUtils,
	cloudRenderer: CloudRendererService,
	reportDefinitionInitializers: ReportDefinitionInitializers,
	reportErrorMessages: ReportErrorMessagesService,
	cloudNavigation: CloudNavigation
) => {

	return {
		restrict: 'A',
		replace: true,
		template: '<div></div>',
		scope: {
			options: '=',
			dataObject: '=data',
			demo: '=',
			view: '=',
			trigger: '=',
			utils: '=',
			handleClick: '=',
			handleRightClick: '='
		},
		link: (scope, element) => {
			scope.selectedPoint = null;

			if (_.isUndefined(scope.utils)) {
				scope.utils = {widgetType: 'undefined'};
			}

			if (!tableService.processIfNoData(element, scope.dataObject, undefined, scope.utils.widgetType, scope.utils)) {
				return;
			}

			delete scope.lastWords;

			reportDefinitionInitializers.initializeSize(scope);

			let getWords = (records) => {
				let limit = Math.min(records.length, 300);
				return records.slice(0, limit);
			};

			let redrawChart = () => {
				// destroy chart if it already exists
				if (scope.chart?.container) {
					scope.chart.destroy();
				}

				let chartOptions = new CloudD3Definition(element);
				scope.chart = new Highcharts.Chart(chartOptions);

				let data = getWords(scope.dataObject.data);
				if (scope.utils.selectedAttributes) {
					let groups = GroupIdentifierHelper.getGroupings(scope.utils.selectedAttributes);
					AnalyticDataUtils.getHierarchyData(data, groups);
				}

				let width = scope.chart.containerWidth;
				let height = scope.chart.containerHeight || scope.chart.chartHeight;
				let textSize = scope.options.size;
				if (scope.options.attributeSelections
						&& scope.options.attributeSelections.size) {
					textSize = scope.options.attributeSelections.size.name;
				}
				let selectedPoint = pointSelectionUtils.getSelectedPoint(scope.utils.containerId, scope.utils.widgetId);
				if (selectedPoint && !scope.demo) {
					pointSelectionUtils.enablePointSelection(scope.utils.containerId, scope.utils.widgetId, true);
				}
				$(element).off('keydown.cloud');
				// if all values are negative, we have to put a warning instead
				if (!cloudRenderer.render(scope, data, width, height, textSize, selectedPoint, scope.demo)) {
					reportErrorMessages.putNegativeSizeWarning(element);
				} else {
					$(element).on('keydown.cloud', cloudNavigation.getKeydownHandler(scope));
				}

			};

			scope.ready = false; // wait until it finished creation before handling anything
			redrawChart();
			reportUtils.initDestroyListener(scope);

			let timer;
			// resize handler
			let chartResizeHandler = reportUtils.chartResizeHandler(scope as HighchartsVisualization);

			reportUtils.initResizeHandler(scope, element,
				(value) => {
					if (!scope.ready || value === '0x0') {
						// don't redraw when element is detached (e.g. refresh widget)
						return;
					}
					chartResizeHandler(value);
					$timeout.cancel(timer);
					timer = $timeout(() => {
						$timeout.cancel(timer);
						scope.chart = new Highcharts.Chart(new CloudD3Definition(element));
						redrawChart();
					}, 500);
				}
			);

			let destroyChart = scope.$on('$destroy', () => {
				// destroy chart if it has been rendered
				if (scope.chart.container) {
					scope.chart.destroy();
				}
				destroyChart();
			});

			if (!scope.demo) {
				reportScopeUtils.emitContextMenuHandling(scope, element);
			}

			scope.$watch('trigger', (newValue, oldValue) => {
				if (newValue === oldValue) {
					return;
				}

				if (scope.demo) {
					delete scope.lastWords;
				}
				reportDefinitionInitializers.initializeSize(scope);
				scope.chart = new Highcharts.Chart(new CloudD3Definition(element));
				redrawChart();
			});

		} // link end
	};
});
