import { AfterViewInit, ChangeDetectionStrategy, Component, Input, Inject} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { IWidgetActions, WidgetAction } from '@cxstudio/home/widgets-edit.service';
import { IDashboardData } from '@cxstudio/interfaces/dashboard-data.interface';
import ChartType from '@cxstudio/reports/entities/chart-type';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';

@Component({
	selector: 'template-widget',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
<figure class="template-image-container h-100-percent" [style.background-image]="getBackgroundImage()">
	<div *ngIf="editMode" class="d-flex h-100-percent w-100-percent justify-center align-center">
		<button (click)="widgetActions.emit(WidgetAction.OPEN_SETTINGS)" class="btn btn-template-widget-edit btn-template-widget btn-primary">
			{{templateButtonText(widget.visualProperties.visualization)}}
		</button>
		<button (click)="widgetActions.emit(WidgetAction.DELETE)" class="btn btn-secondary btn-template-widget-delete btn-template-widget">
			<i class="q-icon-delete"></i>
			{{'common.delete' | i18n}}
		</button>
	</div>
</figure>`,
	styles: [`
.template-image-container {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: contain;
}`]})
export class TemplateWidgetComponent implements AfterViewInit {

	@Input() widget: Widget;
	@Input() editMode: boolean;
	@Input() widgetActions: IWidgetActions;
	@Input() current: IDashboardData;

	WidgetAction = WidgetAction;

	constructor(
		private locale: CxLocaleService,
		private sanitizer: DomSanitizer,
		@Inject('reportUtils') readonly reportUtils: ReportUtils,
	) { }

	templateButtonText(visualization: string): string {
		switch (visualization) {
			case 'DUAL':
			case 'CLOUD':
			case 'BUBBLE':
			case 'PIE':
				return this.locale.getString('templates.reportButton');
			case 'CB_AN_METRIC':
				return this.locale.getString('templates.metricButton');
			case 'IMAGE':
				return this.locale.getString('templates.imageButton');
			case 'LABEL':
				return this.locale.getString('templates.labelButton');
			case 'TEXT':
				return this.locale.getString('templates.textButton');
			case 'VIDEO':
				return this.locale.getString('templates.videoButton');
			default:
				return this.locale.getString('templates.genericButton');
		}
	}

	ngAfterViewInit(): void {
		this.reportUtils.handleWidgetRenderedEvent(this.widget.id, this.widget.name, this.widget.containerId);
	}

	getBackgroundImage(): SafeStyle {
		let image = this.getVisualizationImage(this.widget.visualProperties.visualization as WidgetVisualization,
			this.widget.visualProperties.subChartType);
		return this.sanitizer.bypassSecurityTrustStyle(`url('/dashboard/img/templates/${image}')`);
	}

	private getVisualizationImage(visualization: WidgetVisualization, chartType?: ChartType): string {
		switch (visualization) {
			case WidgetVisualization.PREVIEW_BUBBLES: return 'preview-bubbles.png';
			case WidgetVisualization.PREVIEW_TABLE: return  'preview-bubbles.png';
			case WidgetVisualization.STACKEDPIE: return 'pie-chart.png';
			case WidgetVisualization.BUBBLE: return 'bubble-chart.png';
			case WidgetVisualization.DUAL: {
				switch (chartType) {
					case ChartType.BAR: return 'bar-chart.png';
					case ChartType.COLUMN: return 'column-chart.png';
					case ChartType.SPLINE: return 'trend.png';
					case ChartType.BUBBLE: return 'dual-axis.png';
					default: return 'dual-axis.png';
				}
			}
			case WidgetVisualization.CLOUD: return 'word-cloud.png';
			case WidgetVisualization.CB_AN_TABLE: return 'doubleagg.png';
			case WidgetVisualization.CB_AN_METRIC: return 'metric.png';
			case WidgetVisualization.TEXT: return 'textblock.png';
			case WidgetVisualization.IMAGE: return 'image.png';
			case WidgetVisualization.LABEL: return 'label.png';
			case WidgetVisualization.VIDEO: return 'video.png';
			default: return 'dual-axis.png';
		}
	}

}

app.directive('templateWidget', downgradeComponent({component: TemplateWidgetComponent}));
