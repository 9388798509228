import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import { SpotCheckKeyboardSelector } from './spot-check-keyboard-selector';

export class SpotCheckModalKeyboardUtils {

	static processSpotCheckModalKeyboard(event: KeyboardEvent): void {
		let target: EventTarget = event.target;

		let modalElements: JQuery = SpotCheckModalKeyboardUtils.getAllFocusableModalElements();
		let modalElementsCount: number = modalElements.length;

		if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			KeyboardUtils.intercept(event);
			SpotCheckModalKeyboardUtils.focusCloseButton();
		} else if (KeyboardUtils.isEventKey(event, Key.TAB) && modalElementsCount && target === modalElements[modalElementsCount - 1]) {
			KeyboardUtils.intercept(event);
			modalElements.first().trigger('focus');
		} else if (KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT) && modalElementsCount && target === modalElements[0]) {
			KeyboardUtils.intercept(event);
			let lastModalElement: JQuery = modalElements.last();
			let affectedWidgetsContainer: JQuery = lastModalElement.closest(SpotCheckKeyboardSelector.AFFECTED_WIDGETS_CONTAINER_SELECTOR);
			if (affectedWidgetsContainer.length) {
				affectedWidgetsContainer.trigger('focus');
			} else {
				lastModalElement.trigger('focus');
			}
		}
	}

	static getAllFocusableModalElements(): JQuery {
		return $(`${SpotCheckKeyboardSelector.SPOT_CHECK_MODAL_SELECTOR} :focusable`);
	}

	static focusCloseButton(): void {
		SpotCheckModalKeyboardUtils.getAllFocusableModalElements().first().trigger('focus');
	}

	static closeModal(): void {
		SpotCheckModalKeyboardUtils.getAllFocusableModalElements().first().trigger('click');
	}

	static focusSpotCheckToggle(): void {
		$(SpotCheckKeyboardSelector.SPOT_CHECK_MODAL_TOGGLE_SELECTOR).trigger('focus');
	}
}
