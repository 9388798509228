import * as _ from 'underscore';

import ILocale from '@cxstudio/interfaces/locale-interface';
import { Security } from '@cxstudio/auth/security-service';
import IAlertSubscriptionTemplate, { IAlertSubscriptionTemplateMetadata, AlertSubscriptionTemplateType }
	from '@cxstudio/alert-subscription-templates/types/alert-subscription-template';
import AlertSubscriptionTemplatesApi from './alert-subscription-templates-api.service';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { IReportModel } from '@app/modules/project/model/report-model';
import { ModalBindings } from '@cxstudio/common/modal-bindings';
import { CaseTitleMode } from './types/case-title-mode.enum';
import { AttributeType } from '@app/modules/project/attribute/attribute-type';
import { CasePriority } from './types/case-priority.enum';
import { UibTab } from '@cxstudio/common/uib-tab';
import { SubscriptionTemplateTitleVariable } from './types/subscription-template-title-variable';
import EngagorTopic from '@cxstudio/engagor/engagor-topic';
import { CaseApiService } from '@app/modules/case-management/services/case-api-service.service';
import { EngagorApiService, EngagorAssignees } from '@cxstudio/services/data-services/engagor-api.service';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { AttributeLoader } from '@cxstudio/services/attribute-loader.service';
import { AttributesService } from '@app/modules/project/attribute/attributes.service';
import { AlertSubscriptionTemplateCaseSettings } from './types/alert-subscription-template-case-settings';
import { InboxTemplateTitleUtils } from '@app/modules/inbox-template/inbox-template-title-utils.class';
import { PromiseUtils } from '@app/util/promise-utils';
import { ModelsService } from '@app/modules/project/model/models.service';
import { AccountOrWorkspaceProject, WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';

export interface ITemplateModel extends IReportModel {
	displayName: string;
}

export interface ITemplateAttribute extends IReportAttribute {
	nativeId: number;
}

export interface ITemplatePinnedAttribute extends ITemplateAttribute {
	mappedName?: string;
	pinned?: boolean;
}

interface ITemplatesEditorModalParams {
	template: IAlertSubscriptionTemplate;
	editMode: boolean;
}

interface ITemplateEditorAssetsData {
	projectAttributes: ITemplateAttribute[];
	projectModels: ITemplateModel[];
	defaultAttributes?: ITemplateAttribute[];
	defaultTopicId?: number;
	engagorTopics?: EngagorTopic[];
	engagorAssignees?: EngagorAssignees;
}

export class TemplatesEditorModalController extends ModalBindings<ITemplatesEditorModalParams> {

	readonly ATTRIBUTE_LIMIT: number = 30;
	readonly MODEL_LIMIT: number = 5;
	readonly TEMPLATE_NAME_LIMIT: number = 100;
	readonly titlePatten = /{[^{}]+}/g;

	modalTitle: string;

	caseMetadata: IAlertSubscriptionTemplateMetadata;
	mentionMetadata: IAlertSubscriptionTemplateMetadata;

	template: Partial<IAlertSubscriptionTemplate>;
	editMode: boolean;
	availableCaseAttributes: ITemplateAttribute[];
	engagorTopics: EngagorTopic[];
	engagorAssignees?: EngagorAssignees;

	checkingNameUniqueness: boolean;
	uniqueNameError: boolean;
	invalidNameError: boolean;
	emptyNameError: boolean;
	errors = {
		topicNotSelected: false,
		titleRequired: false
	};
	warnings = {
		missingTitleVariables: null
	};
	titleLengthError: boolean;
	callbacks;

	tabs: UibTab[];
	private caseTitleVariables: SubscriptionTemplateTitleVariable[];
	private initialTemplate: Partial<IAlertSubscriptionTemplate>;

	loading: ng.IPromise<any> | null;

	constructor(
		private locale: ILocale,
		private alertSubscriptionTemplatesApi: AlertSubscriptionTemplatesApi,
		private readonly downgradeDialogService: DowngradeDialogService,
		private security: Security,
		private attributesService: AttributesService,
		private modelsService: ModelsService,
		private caseApiService: CaseApiService,
		private engagorApiService: EngagorApiService,
		private readonly betaFeaturesService: BetaFeaturesService,
		private masterAccountApiService: MasterAccountApiService,
		private $q: ng.IQService
	) {
		super();
	}

	$onInit = (): void => {
		this.modalTitle = this.locale.getString('alertTemplates.editTemplateHeading');
		this.loading = this.loadAssets().then(assets => {
			this.initDialog(assets);
			this.loading = null;
		});
	};

	private loadAssets(): ng.IPromise<ITemplateEditorAssetsData> {
		let needEngagor = this.security.getCurrentMasterAccount().engagorEnabled;

		let propsTemplate: IAlertSubscriptionTemplate = this.resolve.template;
		let props = this.getTemplateProject(propsTemplate);
		let assets: {[key in keyof ITemplateEditorAssetsData]: ng.IPromise<ITemplateEditorAssetsData[key]>} = {
			projectAttributes: this.getAttributes(props),
			projectModels: this.getModels(props),
		};
		if (this.isNewTemplate())
			assets.defaultAttributes = this.getDefaultAttributes(props);
		if (needEngagor) {
			let {defaultTopicId, engagorTopics} = this.getEngagorSettings();
			assets.defaultTopicId = defaultTopicId;
			assets.engagorTopics = engagorTopics;
		}

		assets.engagorAssignees = this.engagorApiService.getAssignees();
		return this.$q.all(assets) as ng.IPromise<ITemplateEditorAssetsData>;
	}

	private isNewTemplate(): boolean {
		return !this.resolve.template.id;
	}

	private initDialog(assets: ITemplateEditorAssetsData): void {
		this.engagorTopics = [{id: null, name: this.locale.getString('common.selectPrompt')}].concat(assets.engagorTopics);
		this.engagorAssignees = assets.engagorAssignees;
		this.template = angular.copy(this.resolve.template);
		this.template = _.defaults(this.template, this.getDefaults(assets));

		this.editMode = this.resolve.editMode;
		if (!this.isMetricTemplate()) {
			let models = assets.projectModels ? angular.copy(assets.projectModels) : [];
			models.forEach(model => {
				model.displayName = model.name;
			});
			let attributes = assets.projectAttributes ? angular.copy(assets.projectAttributes) : [];
			attributes.forEach(attribute => {
				attribute.nativeId = attribute.id;
			});
			this.caseMetadata = {
				attributes: angular.copy(attributes),
				models: angular.copy(models)
			};
			this.mentionMetadata = {
				attributes: angular.copy(attributes),
				models: angular.copy(models)
			};
			this.availableCaseAttributes = angular.copy(attributes).filter(attribute => attribute.type === AttributeType.TEXT);
			this.syncAssets(attributes, models);
		}
		this.initializeCaseTitleOptions();
		this.initialTemplate = angular.copy(this.template);
	}

	getDefaults(assets: ITemplateEditorAssetsData): Partial<IAlertSubscriptionTemplate> {
		let titleMode = this.isMetricTemplate() ? CaseTitleMode.CUSTOM : CaseTitleMode.DEFAULT;
		let defaults = {
			topicId: assets.defaultTopicId,
			caseSettings: {
				titleMode,
				priority: CasePriority.LOW
			}
		};
		if (!this.isMetricTemplate()) {
			defaults = _.extend(defaults, {
				caseMetadata: {
					attributes: [],
					models: []
				},
				mentionMetadata: {
					attributes: assets.defaultAttributes ? angular.copy(assets.defaultAttributes) : [],
					models: []
				}
			});
			this.populateCaseSettingsLocks(defaults.caseSettings);
		}

		if (this.isMetricTemplate()) {
			defaults.caseSettings = _.extend(defaults.caseSettings, {
				caseBody: this.locale.getString('alert.defaultCaseCreateBody')
			});
		}

		return defaults;
	}

	private populateCaseSettingsLocks = (caseSettings: AlertSubscriptionTemplateCaseSettings): void => {
		caseSettings.locks = {
			title: true,
			priority: true,
			assignee: true
		};
	};
	save = (): void => {
		if (!this.template.name) {
			this.emptyNameError = true;
		}

		if (this.isEngagorIntegrationEnabled() && !this.template.topicId) {
			this.errors.topicNotSelected = true;
		}

		if (this.isEngagorIntegrationEnabled()
				&& this.template.caseSettings.titleMode === CaseTitleMode.CUSTOM
				&& !this.template.caseSettings.customTitle) {
			this.errors.titleRequired = true;
		}

		if (this.shouldDisableSave() || this.checkingNameUniqueness) {
			return;
		}

		InboxTemplateTitleUtils.prepareTitleVariablesToSave(this.template as IAlertSubscriptionTemplate, this.caseTitleVariables);

		let updateCases = !!this.editMode
			&& this.isEngagorIntegrationEnabled()
			&& (this.template.type === AlertSubscriptionTemplateType.SCORECARD
				|| this.template.type === AlertSubscriptionTemplateType.NEW_VERBATIM
				|| this.template.type === AlertSubscriptionTemplateType.PRODUCT_FEEDBACK)
			&& this.hasMetadataChanged();

		this.close({$value: {template: this.template, updateCases}});
	};

	hasMetadataChanged = (): boolean => {
		return !this.isNewTemplate()
			&& (!_.isEqual(this.initialTemplate.caseMetadata, this.template.caseMetadata)
				|| !_.isEqual(this.initialTemplate.mentionMetadata, this.template.mentionMetadata));
	};

	shouldDisableSave = (): boolean => {
		return this.invalidNameError
			|| this.uniqueNameError
			|| this.emptyNameError
			|| this.titleLengthError
			|| this.errors.topicNotSelected
			|| this.errors.titleRequired;
	};

	isDataChanged = (): boolean => this.initialTemplate && !_.isEqual(this.initialTemplate, this.template);

	cancel = (): void => {
		if (!this.isDataChanged()) {
			this.dismiss();

			return;
		}

		this
			.downgradeDialogService
			.showUnsavedChangesDialogAndResolve(
				this.save,
				this.dismiss,
				'alertTemplates.unsavedChangesHeader',
				'alertTemplates.unsavedChanges',
				false
			)
			.catch(() => {})
		;
	};

	checkName = (): void => {
		this.emptyNameError = false;
		if ( /[\"\'\\]/.test(this.template.name) ) {
			this.invalidNameError = true;
			return;
		}

		this.invalidNameError = false;
		this.checkingNameUniqueness = true;
		this.getTemplates(this.getTemplateProject(this.template)).then(result => {
			this.checkingNameUniqueness = false;
			this.uniqueNameError = (result || []).some(template => ((template && template.name) === this.template.name));
		});
	};

	private getTemplates = (project: AccountOrWorkspaceProject): ng.IPromise<IAlertSubscriptionTemplate[]> => {
		return WorkspaceTransitionUtils.isWorkspaceProject(project)
			? this.alertSubscriptionTemplatesApi.getWorkspaceTemplates(project as WorkspaceProject)
			: this.alertSubscriptionTemplatesApi.getAllTemplates(project);
	};

	private getTemplateProject(template: Partial<IAlertSubscriptionTemplate>): AccountOrWorkspaceProject {
		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE)) {
			return template.workspaceProject;
		} else {
			return {
				contentProviderId: template.contentProviderId,
				accountId: template.accountId,
				projectId: template.projectId
			};
		}
	}

	syncAssets = (allAttributes: ITemplateAttribute[], allModels: ITemplateModel[]): void => {
		this.template.caseMetadata.attributes = this.template.caseMetadata.attributes.map(attribute => {
			return allAttributes.find(attr => (attr.id === attribute.nativeId));
		}).filter(attr => !!attr);
		this.template.mentionMetadata.attributes = this.template.mentionMetadata.attributes.map(attribute => {
			return allAttributes.find(attr => (attr.id === attribute.nativeId));
		}).filter(attr => !!attr);
		this.template.caseMetadata.models = this.template.caseMetadata.models.map(item => {
			let availableModel = allModels.find(model => (model.id === item.id));
			return availableModel ? _.extend(item, availableModel) : undefined;
		}).filter(model => !!model);
		this.template.mentionMetadata.models = this.template.mentionMetadata.models.map(item => {
			let availableModel = allModels.find(model => (model.id === item.id));
			return availableModel ? _.extend(item, availableModel) : undefined;
		}).filter(model => !!model);
		this.initialTemplate = angular.copy(this.template);
	};

	private initializeCaseTitleOptions = (): void => {
		InboxTemplateTitleUtils.prepareTitleVariablesToView(this.template as IAlertSubscriptionTemplate);
		this.updateTitleVariablesOptions();
	};

	updateTitleVariablesOptions = (): void => {
		if (this.isMetricTemplate()) {
			this.caseTitleVariables = InboxTemplateTitleUtils.getMetricAlertTitleVariables();
		} else {
			this.caseTitleVariables = InboxTemplateTitleUtils.getAttributeTitleVariables(this.template as IAlertSubscriptionTemplate);
			this.checkMissingTitleVariables();
		}
	};

	private checkMissingTitleVariables(): void {
		if (this.template.caseSettings.titleMode === CaseTitleMode.CUSTOM && this.template.caseSettings.customTitle) {
			let variables = this.template.caseSettings.customTitle.match(this.titlePatten);
			let missingStr = _.filter(variables, (variable) => {
				let variableLabel = variable.substring(1, variable.length - 1 );
				return !_.some(this.caseTitleVariables, (tilteVariable) => tilteVariable.label === variableLabel);
			}).join(', ');

			if (missingStr) {
				this.warnings.missingTitleVariables
					= this.locale.getString('alertTemplates.missingTitleVariables', { missingStr });
				return;
			}
		}
		this.warnings.missingTitleVariables = null;
	}

	isEngagorIntegrationEnabled = (): boolean => {
		return this.security.getCurrentMasterAccount().engagorEnabled;
	};

	hasInteractionInformation = (): boolean => {
		return this.isEngagorIntegrationEnabled() && !this.isMetricTemplate();
	};

	isScorecardTemplate = (): boolean => {
		return this.template.type === AlertSubscriptionTemplateType.SCORECARD;
	};

	private isMetricTemplate = (): boolean => {
		return this.template.type === AlertSubscriptionTemplateType.METRIC;
	};

	private getEngagorSettings(): {defaultTopicId: ng.IPromise<number>; engagorTopics: ng.IPromise<EngagorTopic[]>} {
		let engagorTopics = this.engagorApiService.getTopics().then(topics => topics, () => []); // ignore if failed
		let engagorSettingsPromise = this.masterAccountApiService.getEngagorSettings();
		let defaultTopicId = this.$q.all([engagorSettingsPromise, engagorTopics]).then(result => {
			let engagorSettings = result[0];
			let topics = result[1];
			if (engagorSettings.topicId) {
				let matchedTopic: EngagorTopic = _.findWhere(topics, {id: parseInt(engagorSettings.topicId, 10)});
				if (matchedTopic) {
					return matchedTopic.id;
				}
			}
		}, _.noop);
		return {engagorTopics, defaultTopicId};
	}

	private getAttributes(props: AccountOrWorkspaceProject): ng.IPromise<ITemplateAttribute[]> {
		return PromiseUtils.old(this.attributesService.getAttributes(props)).then(attributes => {
			return _.filter(attributes, AttributeLoader.noSpecialAttributes) as ITemplateAttribute[];
		});
	}

	private getModels(props: AccountOrWorkspaceProject): ng.IPromise<ITemplateModel[]> {
		return PromiseUtils.old(this.modelsService.getModels(props)).then(models => {
			return models as ITemplateModel[];
		});
	}

	private getDefaultAttributes(props: AccountOrWorkspaceProject): ng.IPromise<ITemplateAttribute[]> {
		return WorkspaceTransitionUtils.isWorkspaceProject(props)
			? this.caseApiService.getWorkspaceCaseAttributes(props)
			: this.caseApiService.getCaseAttributes(props);
	}
}

app.component('templatesEditorModal', {
	controller: TemplatesEditorModalController,
	templateUrl: 'partials/alert-subscription-templates/template-editor-modal.html',
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
});
