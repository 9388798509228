import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { CxLocationService, ILocationSearch } from '@app/core/cx-location.service';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { Book, IBookTab } from '@cxstudio/dashboards/entity/book';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { CxLocaleService } from '@app/core';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { HTTPStatusCode } from '@app/core/http-status-code.enum';
import { downgradeComponent } from '@angular/upgrade/static';
import { DashboardBookStateService } from '@cxstudio/dashboards/books/dashboard-book-state.service';
import { DashboardProperties } from '@cxstudio/dashboards/entity/dashboard-properties';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { Security } from '@cxstudio/auth/security-service';
import { NameService } from '@cxstudio/common/name-service';
import { IRouteParams } from '@app/shared/providers/route-params-provider';
import { PageTitleUtil } from '@app/core/page-title-util.class';

type IBookPageParams = ILocationSearch<'needRedirect' | 'folder'>;

@Component({
	selector: 'book-edit-page',
	template: `
<div class="bg-dashboard h-100-percent d-flex flex-direction-column p-relative overflow-y-auto"
	[ngBusy]="loadingPromise">
	<book-editor class="flex-fill overflow-hidden"
		*ngIf="currentBook"
		[book]="currentBook"
		(save)="onSave($event)"
		(cancel)="onCancel()"
		(createNew)="createNewBook()"
	></book-editor>
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookEditPageComponent implements OnInit {

	loadingPromise: ng.IPromise<any>;
	currentBook: Book;

	constructor(
		private locale: CxLocaleService,
		private location: CxLocationService,
		private dialogService: CxDialogService,
		private dashboardListService: DashboardListService,
		@Inject('dashboardBookState') private readonly dashboardBookState: DashboardBookStateService,
		@Inject('$routeParams') private $routeParams: IRouteParams,
		@Inject('dashboardApiService') private dashboardApiService: DashboardApiService,
		@Inject('redirectService') private redirectService: RedirectService,
		@Inject('nameService') private nameService: NameService,
		@Inject('security') private security: Security,
	) { }

	ngOnInit(): void {
		if (!this.$routeParams.bookId) {
			PageTitleUtil.setTitle(this.locale.getString('dashboard.newTabs'));
			let bookBaseName = this.locale.getString('dashboard.tabsName');
			let bookName = this.nameService.uniqueName(bookBaseName,
				this.dashboardListService.getCurrentDashboardsList(), 'name');
			let folderId = Number(this.getPageParams().folder);
			this.currentBook = {
				name: bookName,
				type: DashboardType.BOOK,
				description: '',
				properties: {} as DashboardProperties,
				parentId: isNaN(folderId) ? undefined : folderId,
				tabs: [{
					name: this.locale.getString('dashboard.tab', {index: 1})
				} as IBookTab],
				ownerName: this.security.getEmail()
			} as Book;
		} else {
			this.loadBook(Number(this.$routeParams.bookId));
		}
		this.dashboardListService.reloadDashboards();
	}

	private getPageParams(): IBookPageParams {
		return this.location.search();
	}

	private loadBook(bookId: number) {
		this.loadingPromise = this.dashboardApiService.getDashboard(bookId).then(response => {
			let book = response.data as Book;
			this.currentBook = book;
			if (book.type === DashboardType.DASHBOARD) {
				this.redirectService.goToDashboard(book.id);
				return;
			}
			if (book.type === DashboardType.BOOK) {
				PageTitleUtil.setTitle(this.locale.getString('pageTitle.object', { objectName: book.name }));
				this.currentBook = book;
			}
		}, (response) => {
			let status = response.status;
			if (status === HTTPStatusCode.MASTER_ACCOUNT_CHANGED) { // dashboard from another MA, need to reload
				this.redirectService.saveCurrentMA({accountId: response.data}, true);
			} else if (status === 403) {
				this.dialogService.warning(this.locale.getString('common.error'), this.locale.getString('dashboard.noAccess'))
					.result.then(() => {
						this.redirectService.goToDashboardList();
					});
			}
		});
	}

	createNewBook(): void {
		this.redirectService.goToNewBook();
	}

	onSave(book: Book): void {
		this.dashboardBookState.resetState();
		this.exitEditor(book);
	}

	onCancel(): void {
		this.exitEditor();
	}

	private exitEditor(book?: Book): void {
		let redirectTo = book || this.currentBook;
		if (this.getPageParams().needRedirect && redirectTo.id) {
			this.redirectService.goToBook(redirectTo.id);
		} else {
			this.redirectService.goToDashboardList();
		}
	}
}

app.directive('bookEditPage', downgradeComponent({component: BookEditPageComponent}));

