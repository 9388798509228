import * as cloneDeep from 'lodash.clonedeep';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { AttributeSettingsService, ProjectAttributeSettingsMap } from '@app/modules/project/attribute/attribute-settings.service';
import { ModelSettingsService } from '@app/modules/project/model/model-settings.service';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { ISortByOptions, SortByOptionsService } from '@app/modules/project/settings/sort-by-options.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { AnalyticMetricType, AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { Inject } from '@angular/core';
import { ModelGroupingSettings, NumberFormatSettings, NumericAttributeGroupingSettings, TextAttributeGroupingSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { SelectionPlaceholders } from '@app/modules/unified-templates/dashboard-templates/selection-placeholders.class';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { ClarabridgeMetricName } from '@cxstudio/reports/providers/cb/constants/clarabridge-metrics-names';

export type GroupingSettings = TextAttributeGroupingSettings | NumericAttributeGroupingSettings | ModelGroupingSettings;
export type CalculationSettings = NumberFormatSettings;
export type ProjectSettingsMap = ProjectAttributeSettingsMap;
@Injectable({
	providedIn: 'root'
})
export class ReportSettingsService {
	constructor(
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly attributeSettingsService: AttributeSettingsService,
		private readonly modelSettingsService: ModelSettingsService,
		private readonly sortByOptionsService: SortByOptionsService,
		@Inject('metricConstants') private readonly metricConstants: MetricConstants
	) {}

	getGroupingSettings(widgetProperties: WidgetProperties, grouping: ReportGrouping): Promise<GroupingSettings> {
		let project = this.reportAssetUtilsService.getWidgetPropertiesProject(widgetProperties);
		return this.getGroupingDefaults(project, grouping);
	}

	getGroupingDefaults(project: AccountOrWorkspaceProject, grouping: ReportGrouping): Promise<GroupingSettings> {
		if (grouping.metricType === AnalyticMetricType.ATTRIBUTE) {
			return this.getAttributeGroupingSettings(project, grouping.id, grouping.type);
		} else if (AnalyticMetricTypes.isTopics(grouping)) {
			const modelId = parseInt(grouping.name, 10);
			return this.getTopicGroupingSettings(project, modelId);
		} else if (this.metricConstants.isWordAttribute(grouping.name)) {
			return this.getWordGroupingSettings(project, grouping.name);
		} else {
			return Promise.resolve({} as GroupingSettings);
		}
	}

	populateGroupingProjectDefaults(widgetProperties: WidgetProperties, grouping: ReportGrouping): Promise<ReportGrouping> {
		return this.getGroupingSettings(widgetProperties, grouping)
			.then(groupingDefaults => {
				let enrichedGrouping: ReportGrouping = cloneDeep(grouping);
				_.extend(enrichedGrouping, groupingDefaults);
				return enrichedGrouping;
			});
	}

	populateGroupingDefaults(project: AccountOrWorkspaceProject, grouping: ReportGrouping): Promise<ReportGrouping> {
		return this.getGroupingDefaults(project, grouping)
			.then(groupingDefaults => {
				let enrichedGrouping: ReportGrouping = cloneDeep(grouping);
				_.extend(enrichedGrouping, groupingDefaults);
				return enrichedGrouping;
			});
	}

	getCalculationSettings(widgetProperties: WidgetProperties, calculation: ReportCalculation): Promise<CalculationSettings> {
		let project = this.reportAssetUtilsService.getWidgetPropertiesProject(widgetProperties);
		return this.getCalculationSettingsByProject(project, calculation);
	}

	getCalculationSettingsByProject(project: AccountOrWorkspaceProject, calculation: ReportCalculation): Promise<CalculationSettings> {
		if (calculation.metricType === AnalyticMetricType.ATTRIBUTE && calculation.type === ReportAssetType.NUMBER) {
			const attributeId = calculation.id;
			let settingsPromise = this.attributeSettingsService.getNumericAttributeSettings(project, attributeId, CacheOptions.CACHED);
			return settingsPromise.then(result => {
				if (result?.calculation) {
					result.calculation.customFormatting = true;
					return result.calculation;
				}
			});
		}
		return Promise.resolve({} as CalculationSettings);
	}

	getWidgetProjectSettings(widgetProperties: WidgetProperties): Promise<ProjectSettingsMap> {
		let project = this.reportAssetUtilsService.getWidgetPropertiesProject(widgetProperties);
		return this.attributeSettingsService.getProjectSettings(project);
	}

	getDashboardProjectSettings(dashboard: Dashboard): Promise<ProjectSettingsMap> {
		let project = this.reportAssetUtilsService.getDashboardProject(dashboard);
		return this.attributeSettingsService.getProjectSettings(project);
	}

	private getAttributeGroupingSettings(
		project: AccountOrWorkspaceProject, attributeId: number, type: ReportAssetType
	): Promise<GroupingSettings> {
		let settingsPromise;
		if (type === ReportAssetType.TEXT) {
			settingsPromise = this.attributeSettingsService.getTextAttributeSettings(project, attributeId, CacheOptions.CACHED);
		} else if (type === ReportAssetType.NUMBER) {
			settingsPromise = this.attributeSettingsService.getNumericAttributeSettings(project, attributeId, CacheOptions.CACHED);
		} else {
			return Promise.resolve({} as GroupingSettings);
		}
		let sortByOptionsPromise = this.sortByOptionsService.getOptions(project, CacheOptions.CACHED);
		return Promise.all([settingsPromise, sortByOptionsPromise])
			.then(results => {
				let settings = results[0] && results[0].grouping ? results[0].grouping : undefined;
				let options = results[1];
				if (settings) {
					settings.sortBy = this.validateSortBy(settings.sortBy, options);
				}
				return settings;
			});
	}

	private getWordGroupingSettings = (
		project: AccountOrWorkspaceProject, attributeName: string
	): Promise<TextAttributeGroupingSettings> => {
		return this.attributeSettingsService.getWordAttributeSettings(project, attributeName, CacheOptions.CACHED).then(result => {
			return result.grouping;
		});
	};

	getTopicGroupingSettings(project: AccountOrWorkspaceProject, modelId: number): Promise<ModelGroupingSettings> {
		let settingsPromise = this.modelSettingsService.getModelSettings(project, modelId, CacheOptions.CACHED);
		let sortByOptionsPromise = this.sortByOptionsService.getOptions(project, CacheOptions.CACHED);
		return Promise.all([settingsPromise, sortByOptionsPromise])
			.then(results => {
				let settings = results[0]?.topics;
				let options = results[1];
				if (settings) {
					settings.sortBy = this.validateSortBy(settings.sortBy, options);
					settings.type = (settings.selectedLevel === TopicReportGrouping.LEAF_LEVEL)
						? ReportAssetType.TOPIC_LEAF : ReportAssetType.TOPICS;
				}
				return settings;
			});
	}

	private validateSortBy(sortBy: string, options: ISortByOptions): string {
		let search = {name: sortBy};
		return _.findWhere(options.standardMetrics, search)
				|| _.findWhere(options.predefinedMetrics, search)
				|| _.findWhere(options.attributes, search)
				|| _.findWhere(options.metrics, search)
			? sortBy : 'volume';
	}

	hasPrimaryGrouping = (visualProps: VisualProperties): boolean => {
		return visualProps.attributeSelections?.[SelectionPlaceholders.values.groupings.PRIMARY_GROUP];
	};

}

app.service('reportSettingsService', downgradeInjectable(ReportSettingsService));
