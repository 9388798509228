import { ContentProviderId } from '@cxstudio/generic-types';
import { ContentProviderOptionsService } from './data-services/content-provider-options.service';

export interface IContentProviderSelectionCtrlExtension {
	checkProperty(prop: string, values, idField: string): void;
	clearProjects(): void;
	reloadContentProviders(): void;
	isEmpty(value): boolean;
	init(): void;
	isValidationInProgress(): boolean;
}


//WORKSPACE beta, could be removed after GA
app.factory('ContentProviderSelectionControllerExtension', (
	contentProviderOptionsService: ContentProviderOptionsService
) => {
	let scope;

	return class CPSelectionCtrlExtension implements IContentProviderSelectionCtrlExtension {
		cpValidationInProgress = false;

		constructor(initScope) {
			scope = initScope;
			scope.showErrorsForCP = [];

			scope.$watch('model.properties.cbContentProvider', () => {
				if (scope.model.properties.cbContentProvider === -1) {
					this.clearAccounts('cb');
					return;
				}
				this.validateCPandReloadAccounts(scope.model.properties.cbContentProvider, 'cb');
			});

			scope.$watch('model.properties.cbAccount', () => {
				if (this.isEmpty(scope.model.properties.cbAccount)) {
					this.clearProjects();
					return;
				}
				if (!this.cpValidationInProgress) {
					scope.reloadProjects();
				}
			});
		}

		checkProperty = (prop: string, values, idField: string): void => {
			let searchObject = {};
			searchObject[idField] = scope.model.properties[prop];
			if (!_.isUndefined(scope.model.properties[prop]) && !_.findWhere(values, searchObject)) {
				scope.model.properties[prop] = -1;
			} else if (_.isUndefined(scope.model.properties[prop]) || scope.model.properties[prop] === null) {
				scope.model.properties[prop] = -1;
			}
		};

		clearProjects = (): void => {
			scope.projects = [];
			scope.model.properties.project = -1;
		};

		reloadContentProviders = (): void => {
			scope.settingsLoading = contentProviderOptionsService.getContentProviders()
				.then((contentProviders) => {
					scope.contentProviders = contentProviders.data;
					if (contentProviders.data?.length > 0 ) {

						this.processProviders();

						this.checkProperty('cbContentProvider', scope.contentProviders, 'id');

						if (scope.model.properties.cbContentProvider !== -1)
							this.validateCPandReloadAccounts(scope.model.properties.cbContentProvider, 'cb');
						else this.clearAccounts('cb');
					}
				});
		};

		/**
		 *  checks for undefined, null, -1
		 */
		isEmpty = (value): boolean => !value && value !== 0 || value === -1;

		init = (): void => {
			this.checkPropAndSetIfEmpty('cbContentProvider');
			this.checkPropAndSetIfEmpty('cbAccount');
			this.checkPropAndSetIfEmpty('project');
		};

		isValidationInProgress = (): boolean => this.cpValidationInProgress;

		private checkPropAndSetIfEmpty(propName: string): void {
			if (this.isEmpty(scope.model.properties[propName])) {
				scope.model.properties[propName] = -1;
			}
		}

		private reloadAccounts(type): void {
			let cp = `${type}ContentProvider`;
			let acc = `${type}Account`;
			let accs = `${type}Accounts`;
			if (this.isEmpty(scope.model.properties[cp])) {
				scope.model.properties[acc] = -1;
				return;
			}
			scope.settingsLoading = contentProviderOptionsService.getUserAccounts(scope.model.properties[cp], {}).then((accounts) => {
				scope[accs] = accounts.data;
				if (accounts.data && accounts.data.length > 0) {
					this.checkProperty(acc, scope[accs], 'accountId');

					if (scope[accs].length === 1) {
						scope.model.properties[acc] = scope[accs][0].accountId;
					}

					if (scope.model.properties[acc] !== -1)
						scope.reloadProjects();
					else this.clearProjects();
				}
			},
			(error) => {
				scope.showErrorsForCP = [];
				scope.showErrorsForCP.push(error);
			});
		}

		private validateCPandReloadAccounts(contentProviderId: ContentProviderId, cpType): void {
			if (_.isUndefined(contentProviderId) || contentProviderId === null || contentProviderId === -1) {
				return;
			}
			this.cpValidationInProgress = true;
			let cp = {id: contentProviderId};
			let action = 'validate';
			let cpData = JSON.parse(JSON.stringify(cp));
			scope.settingsLoading = contentProviderOptionsService.validateCP(cpData, action).then((data) => {
				scope.showErrorsForCP = [];
				if (data.valid) {
					this.reloadAccounts(cpType);
				} else {
					scope.showErrorsForCP.pushAll(data);
				}
				this.cpValidationInProgress = false;
			});

		}

		private clearAccounts(type): void {
			scope[`${type}Accounts`] = [];
			scope.model.properties[`${type}Account`] = -1;
		}

		private processProviders(): void {
			let cbCount = 0,
				cbId = -1;
			let types: {CB?: boolean} = {};
			scope.contentProviders.forEach((cp) => {
				if (!types[cp.type]) {
					types[cp.type] = true;
				}
				if (cp.type === 'CB') {
					cbId = cp.id;
					cbCount++;
				}
			});
			if (types.CB)
				scope.currentSettings = 'CB';
			if (cbCount === 1) {
				scope.model.properties.cbContentProvider = cbId;
			}
		}
	};
});
