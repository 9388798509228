
import * as _ from 'underscore';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';

const GROUPING_PREFIX: string = '_grouping_';
const INDEXED_GROUPING_PREFIX: string = GROUPING_PREFIX + 'g';

const GROUPING_IDENTIFIER_REGEXP: RegExp = /_grouping(_g\d*)?_(.*)/;

export const FULL_PATH: string = '_fullPath';

export class GroupIdentifierHelper {

	static getIdentifier(group: ReportGrouping): string {
		if (!group)
			return '';
		if (GroupIdentifierHelper.keepOriginalName(group))
			return group.name;
		return group.identifier || GROUPING_PREFIX + group.name;
	}

	private static keepOriginalName(group: ReportGrouping): boolean {
		//some group need to keep as name
		//sentiment, effort, time, driver in pop report
		//admin group and _pop
		//for now only use for studio metric and topics
		return !AnalyticMetricTypes.isTopics(group)
			&& !AnalyticMetricTypes.isAttribute(group)
			&& !AnalyticMetricTypes.isStudioMetric(group)
			&& !AnalyticMetricTypes.isHierarchyModel(group);
	}

	static getGroup(group: ReportGrouping): ReportGrouping | null {
		if (!group || !group.name) {
			return null;
		}
		return {
			identifier: GroupIdentifierHelper.getIdentifier(group),
			name: group.name,
			type: group.type,
			metricType: group.metricType,
			displayName: group.displayName
		};
	}

	static getGroupings(groups: ReportGrouping[]): ReportGrouping[] {
		return _.chain(groups)
			.filter((value: ReportGrouping): boolean => !!(value && value.name))
			.map((value: ReportGrouping): ReportGrouping => GroupIdentifierHelper.getGroup(value))
			.value();
	}

	static findGroup(groups: ReportGrouping[], name: string): ReportGrouping | null {
		return _.find(groups, (group: ReportGrouping): boolean => {
			return GroupIdentifierHelper.getIdentifier(group) === name;
		});
	}

	static generateIdentifier(group: ReportGrouping, index: number): string {
		if (GroupIdentifierHelper.keepOriginalName(group))
			return group.name;
		return INDEXED_GROUPING_PREFIX + index + GroupIdentifierHelper.buildIdentifierPostfix(group);
	}

	private static buildIdentifierPostfix(group: ReportGrouping): string {
		return '_' + group.name;
	}

	static getGroupingField(object: any, group: ReportGrouping, postfix: string): any {
		for (let key in object) {
			if (key.startsWith(GROUPING_PREFIX)
					&& key.endsWith(GroupIdentifierHelper.buildIdentifierPostfix(group) + postfix)) {
				return object[key];
			}
		}

		return null;
	}

	static populateIdentifier(groups: ReportGrouping[]): void {
		_.each(groups, (group: ReportGrouping, index: number): void => {
			group.identifier = GroupIdentifierHelper.generateIdentifier(group, index);
		});
	}

	static isSameGroupingIdentifiers(first: string, second: string): boolean {
		if (!!first && first === second)
			return true;

		let firstGroupingName = GroupIdentifierHelper.extractGroupingName(first);
		let secondGroupingName = GroupIdentifierHelper.extractGroupingName(second);
		return !!firstGroupingName && firstGroupingName === secondGroupingName;
	}

	private static extractGroupingName(identifier: string): string {
		let match = GROUPING_IDENTIFIER_REGEXP.exec(identifier);
		return match && match[2];
	}

}
