import * as uuid from 'uuid';
import { Component, ChangeDetectionStrategy, Input, OnDestroy, OnChanges, ViewChild, OnInit } from '@angular/core';
import {
	AutomatedCallSummaryApiService, AutomatedCallSummaryPostCallBody
} from '@app/modules/document-explorer/context-pane/explorer-automated-call-summary/automated-call-summary-api.service';
import { Observable, Subscription, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { Clipboard } from '@angular/cdk/clipboard';
import { ConversationDocument } from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { LocalStorageService } from 'angular-2-local-storage';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { AutomatedSummariesUtils } from '../explorer-automated-summaries/automated-summaries-utils.class';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';

@Component({
	selector: 'explorer-automated-call-summary',
	templateUrl: './explorer-automated-call-summary.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [`
		::ng-deep .automated-call-summary-tab .ng-busy > *,
		::ng-deep .automated-call-summary-tab .ng-busy > ng-component > *,
		::ng-deep .automated-call-summary-tab .ng-busy {
			position: relative !important;
		}
		::ng-deep .automated-call-summary-tab lib-ng-busy {
			display: flex !important;
			position: absolute !important;
		}
	`]
})

export class ExplorerAutomatedCallSummaryComponent implements OnDestroy, OnChanges, OnInit {
	@Input() document: ConversationDocument;
	@ViewChild('acc') acc: NgbAccordion;
	summary$: Observable<string>;
	storedSummary: string;
	isLoading: boolean = false;
	summarySubscription: Subscription;
	feedbackGiven: boolean = false;
	isCoachingAssistantEnabled: boolean;



	constructor(
		private readonly automatedCallSummaryApiService: AutomatedCallSummaryApiService,
		private readonly clipboard: Clipboard,
		private localStorageService: LocalStorageService,
		private readonly betaFeaturesService: BetaFeaturesService,
	) {}

	ngOnInit(): void {
		this.isCoachingAssistantEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.COACHING_ASSISTANT);
	}

	ngOnDestroy(): void {
		if (this.summarySubscription) {
			this.summarySubscription.unsubscribe();
		}
	}

	ngOnChanges(changes: SimpleChanges<ExplorerAutomatedCallSummaryComponent>): void {
		if (ChangeUtils.hasChange(changes.document)) {
			this.summary$ = null;
			this.feedbackGiven = false;
			this.summarySubscription?.unsubscribe();
			this.acc.collapse('automatedCallSummaryPanel');
		}
	}

	documentSummaryExists(): boolean {
		if (!this.document) return false;

		const summaryKey = this.document.id.toString();
		const summary = this.localStorageService.get(summaryKey);
		this.feedbackGiven = this.localStorageService.get(summaryKey + 'feedback') ?? false;
		if (summary) {
			this.storedSummary = summary.toString();
			this.summary$ = of(summary.toString());
			return true;
		}
		this.storedSummary = null;
		return false;
	}

	generateSummary(isRegeneration?: boolean): void {
		if (!this.document) return;

		this.isLoading = true;
		const summaryKey = this.document.id.toString();
		const body = this.generatePostACSBody();

		const regenerationMetric = isRegeneration ?
			AmplitudeEvent.AUTOMATED_CALL_SUMMARY_REGENERATE :
			AmplitudeEvent.AUTOMATED_CALL_SUMMARY_GENERATE;
		AmplitudeAnalyticsService.trackEvent(regenerationMetric, { documentId: this.document.id });

		this.summary$ = this.automatedCallSummaryApiService
			.generateAutomatedCallSummary(body)
			.pipe(
				map(({ summary }) => {
					this.isLoading = false;
					this.storedSummary = summary;
					this.localStorageService.set(summaryKey, this.storedSummary);
					this.localStorageService.set(summaryKey + 'feedback', false);
					return summary;
				}),
				finalize(() => {
					this.summaryLoaded();
				})
			);

		this.summarySubscription = this.summary$.subscribe();
	}


	summaryLoaded(): void {
		AmplitudeAnalyticsService.trackEvent(
			AmplitudeEvent.AUTOMATED_CALL_SUMMARY_LOADED,
			{ documentId: this.document.id },
			{ generated: !this.isLoading }
		);

		if(this.isLoading) {
			this.isLoading = false;
			if(!this.storedSummary) { this.summary$ = null; }
			this.summarySubscription.unsubscribe();
		}
	}

	sendSummaryFeedback(feedbackRating: number): void {
		this.feedbackGiven = true;
		this.localStorageService.set(this.document.id + 'feedback', true);
		AmplitudeAnalyticsService.trackEvent(
			AmplitudeEvent.AUTOMATED_CALL_SUMMARY_RATING,
			{ documentId: this.document.id },
			{ rating: feedbackRating, naturalId: this.document.natural_id }
		);
	}

	generatePostACSBody(): AutomatedCallSummaryPostCallBody {
		const conversationPhrases = AutomatedSummariesUtils.getConversationPhrases(this.document);
		const conversationMetadata: AutomatedCallSummaryPostCallBody = {
			language: this.document.sentences[0]?.attributes?._languagedetected?.[0] || 'en',
			sourceType: this.document.sentences[0]?.attributes?.cb_interaction_type?.[0] || 'voice',
			phrases: conversationPhrases,
			uniqueDocumentIdentifier: uuid.v4()
		};
		return conversationMetadata;
	}

	copySummary(): void {
		this.summary$.subscribe((summary) => {
			this.clipboard.copy(summary);
		});
	}
}
