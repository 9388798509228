import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import Authorization from '@cxstudio/auth/authorization-service';
import { Security } from '@cxstudio/auth/security-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import * as _ from 'underscore';
import Hierarchy from './Hierarchy';
import { HierarchyPublicationState } from './hierarchy-publication-state';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { OrganizationUtils } from '@app/modules/hierarchy/organization-utils';

export class OrganizationsComponent implements ng.IComponentController {
	readonly HIERARCHY_STATE_TIMEOUT: number = 10000; //10 seconds

	pagination: {
		currentPage: number;
		totalItems: number;
		maxSize: number;
		orgQuery: string;
	} = {
			currentPage: 1,
			totalItems: 0,
			maxSize: 10,
			orgQuery: ''
		};
	pagedOrgs: Hierarchy[];
	activeTimeout: ng.IPromise<void>;

	currentMasterAccountName: string;

	promises: {
		// page blocking promise
		loadingHierarchies: PromiseLike<any>;
		// this promise only creates small spinner
		loadingHierarchiesBackground: PromiseLike<any>;
	} = {
			loadingHierarchies: null,
			loadingHierarchiesBackground: null
		};

	orgQueryByOptions = [ {
		value: 'name',
		name: this.locale.getString('common.name')
	}, {
		value: 'owner',
		name: this.locale.getString('common.owner')
	}, {
		value: 'projects.projectName',
		name: this.locale.getString('common.project')
	}];

	orgQueryBy = this.orgQueryByOptions[0].value;

	constructor(
		private $log: ng.ILogService,
		private authorization: Authorization,
		private $uibModal: ng.ui.bootstrap.IModalService,
		private locale: ILocale,
		private organizationApiService: OrganizationApiService,
		private $timeout: ng.ITimeoutService,
		private security: Security,
		private readonly downgradeDialogService: DowngradeDialogService,
		private betaFeaturesService: BetaFeaturesService,
	) {}

	$onInit = () => {
		this.currentMasterAccountName = this.security.getCurrentMasterAccount().accountName;
		this.reloadPagedOrgList(true);
	};

	$onDestroy(): void {
		if (this.activeTimeout) {
			this.$timeout.cancel(this.activeTimeout);
		}
	}

	reloadPagedOrgList = (blockPage?: boolean) => {
		let queryParams: any = {};
		queryParams.isPaginated = true;
		queryParams.pageSize = this.pagination.maxSize;
		queryParams.currentPage = this.pagination.currentPage;
		queryParams.filterText = this.pagination.orgQuery;
		queryParams.filterField = this.orgQueryBy;

		let promise = this.organizationApiService.getPagedOrganizationList(queryParams)
			.then((resp) => {
				let result = resp.data;
				this.pagination.totalItems = result.totalCount;
				this.pagedOrgs = result.data;

				if (this.isHierarchyPublishingEnabled()
						&& (this.hasClassifyingHierarchies() || this.hasPublishingHierarchies() || this.hasUnpublishingHierarchies())) {
					if (!this.activeTimeout) {
						this.activeTimeout = this.$timeout(() => {
							this.reloadPagedOrgList();
							this.activeTimeout = null;
						}, this.HIERARCHY_STATE_TIMEOUT);
					}
				}
			});

		if (blockPage) {
			this.promises.loadingHierarchies = promise;
		} else {
			this.promises.loadingHierarchiesBackground = promise;
		}
	};

	isLoadingHierarchiesInBackground = (): boolean => {
		return (this.promises.loadingHierarchiesBackground as any)?.$$state.status === 0;
	};

	onOrgQueryByChange = () => {
		this.pagination.orgQuery = '';
	};

	addOH = () => {

		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.DYNAMIC_FILTERING_HIERARCHY)) {
			return this.downgradeDialogService.openNewHierarchyDialog().then((result: any) => {
				this.organizationApiService.addOrgHierarchy({ name: result.hierarchyName, hierarchyMode: result.hierarchyMode } as Hierarchy)
					.then(() => {
						// set the search filter so that new org will appear
						this.searchForOrgName(result.hierarchyName);
						this.$log.debug('Org hierarchy saved:', result.hierarchyName);
						this.reloadPagedOrgList();
					}, this.reloadPagedOrgList);
			});
		}

		let addHierarchyDialog = this.$uibModal.open({
			component: 'organizationNameDialog',
			resolve: {
				headerText: () => this.locale.getString('organization.addOrgHierarchy'),
				bodyText: () => this.locale.getString('organization.orgNameLabel')
			}
		});
		addHierarchyDialog.result.then((hierarchyName: string) => {
			this.organizationApiService.addOrgHierarchy({ name: hierarchyName } as Hierarchy).
				then(() => {
					// set the search filter so that new org will appear
					this.searchForOrgName(hierarchyName);
					this.$log.debug('Org hierarchy saved:', hierarchyName);
					this.reloadPagedOrgList();
				}, this.reloadPagedOrgList);
		});
	};

	private searchForOrgName = (name: string): void => {
		this.orgQueryBy = 'name';
		this.pagination.orgQuery = name;
	};

	isHierarchyPublishing = (hierarchy: Hierarchy) => OrganizationUtils.isHierarchyPublishing(hierarchy);

	isHierarchyClassifying = (hierarchy: Hierarchy) =>OrganizationUtils.isHierarchyClassifying(hierarchy);

	isHierarchyUnpublishing = (hierarchy: Hierarchy) => OrganizationUtils.isHierarchyUnpublishing(hierarchy);

	isHierarchyPublishingEnabled = () => {
		return this.authorization.hasManageOrganizationsAccess();
	};

	hasPublishingHierarchies = () => {
		let hierarchies = this.pagedOrgs;
		if (!hierarchies)
			return false;

		return _.some(hierarchies, this.isHierarchyPublishing);
	};

	hasClassifyingHierarchies = () => {
		let hierarchies = this.pagedOrgs;
		if (!hierarchies)
			return false;

		return _.some(hierarchies, this.isHierarchyClassifying);
	};

	hasUnpublishingHierarchies = () => {
		let hierarchies = this.pagedOrgs;
		if (!hierarchies)
			return false;

		return _.some(hierarchies, this.isHierarchyUnpublishing);
	};
}

app.component('organizations', {
	controller: OrganizationsComponent,
	templateUrl: 'partials/organizations/organizations.component.html'
});
