import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { WidgetUpdateAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-update-action';
import { DashboardChangeType } from '@app/modules/dashboard-actions/undo/dashboard-change-type.enum';
import { DashboardAccessService } from '@app/modules/dashboard/dashboard-access.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { DocumentExplorerDialogService } from '@app/modules/document-explorer/document-explorer-dialog.service';
import { FullPageDocumentExplorerService } from '@app/modules/document-explorer/document-explorer-page/full-page-document-explorer.service';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { DocumentExplorerWidget } from '@cxstudio/dashboards/widgets/document-explorer-widget';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import { IDashboardData } from '@cxstudio/interfaces/dashboard-data.interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { IDataPointObject } from '@cxstudio/reports/entities/report-definition';
import { RecolorUtils } from '@cxstudio/reports/providers/cb/services/recolor-utils';
import { AnalyticDrillUtils } from '@cxstudio/reports/utils/contextMenu/drill/analytic-drill-utils';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import WidgetService from '@cxstudio/services/widget-service';

type WidgetDrillFunction = (point: Partial<IDataPointObject>, drillTo: DrillType) => Widget;
type ExplorerDrillFunction = (point: Partial<IDataPointObject>, drillTo: DrillType) => DocumentExplorerWidget;
export type DrillActionFunction = (point: Partial<IDataPointObject>, drillTo: DrillType) => void;

interface IDrillControllerScope extends ISimpleScope {
	dashboardData: IDashboardData;
	pagination: any;
	state: {
		view: {
			trigger: number;
		};
	};
}

export interface IContextMenuActions {
	applyRecolor: (point: Partial<IDataPointObject>, color: string) => void;
	applyDrillWidget: (drillFunction: WidgetDrillFunction | ExplorerDrillFunction) => DrillActionFunction;
	openAnDocumentExplorer: (previewFunction, isFullPage?: boolean) => DrillActionFunction;
}


@Injectable({
	providedIn: 'root'
})
export class WidgetDrillActionsService {

	constructor(
		@Inject('analyticDrillUtils') private readonly analyticDrillUtils: AnalyticDrillUtils,
		@Inject('widgetsEditService') private readonly widgetsEditService: WidgetsEditService,
		@Inject('widgetService') private readonly widgetService: WidgetService,
		@Inject('currentObjects') private readonly currentObjects: CurrentObjectsService,
		private readonly cxDialogService: CxDialogService,
		private readonly documentExplorerDialogService: DocumentExplorerDialogService,
		private readonly locale: CxLocaleService,
		private readonly dashboardAccessService: DashboardAccessService,
		private readonly fullPageDocExplorerService: FullPageDocumentExplorerService,		
	) { }

	getContextMenuActions($scope: IDrillControllerScope, widget: Widget): IContextMenuActions {
		let redrawFn = () => $scope.state.view.trigger++;
		return {
			applyRecolor: (point, color) => this.performRecolor(widget, redrawFn, point, color),
			applyDrillWidget: (drillFunction) => this.getApplyDrillFunction($scope, widget, drillFunction),
			openAnDocumentExplorer: (previewFunction, isFullPage) => this.getDocumentExplorerFunction(
				$scope.dashboardData, $scope.pagination, previewFunction, isFullPage)
		};
	}

	private performRecolor = (widget: Widget, redrawFn: () => void, point: Partial<IDataPointObject>, color: string): void => {
		let action = WidgetUpdateAction.wrapChange(() => widget, () => {
			RecolorUtils.addWidgetRecolor(point, color, widget.visualProperties);
		});
		this.widgetsEditService.applyDashboardChanges(widget.containerId, action);
		this.widgetsEditService.addDashboardHistoryState(DashboardChangeType.UPDATED, [action]);
		redrawFn();
	};

	private getApplyDrillFunction = ($scope: ISimpleScope, widget: Widget, drillFunction: WidgetDrillFunction): DrillActionFunction => {
		return (point: IDataPointObject, drillTo: DrillType) => {
			if (this.currentObjects.isEditMode()) {
				if (!widget.hasDataAccess) {
					this.cxDialogService.notify(
						this.locale.getString('common.warning'),
						this.locale.getString('widget.widgetDrillNoDataAccess', {
							projectName: widget.properties.projectName
						})
					);
					return;
				}
				let created = drillFunction(point, drillTo);
				if (this.widgetService.isOverlapping(created, created.containerId)) {
					created.posX -= created.width;
					created.posY += created.height;
				}

				//DISC-27111
				delete created.linkedTo;
				delete created.linkedWidgets;
				
				this.widgetsEditService.createAndSaveWidget(created);
			} else {
				$scope.$emit('drill:add',
					this.analyticDrillUtils.convertDrillObject(widget, point, drillTo));
			}
		};
	};

	getDocumentExplorerFunction = (dashboardData: IDashboardData, pagination, previewFunction: ExplorerDrillFunction,
		isFullPage: boolean = false): DrillActionFunction => {

		return (point, drillTo) => {
			if (_.isUndefined(point)
					|| (!this.dashboardAccessService.canDrillToFeedbackInView(dashboardData.dashboard) && !this.currentObjects.isEditMode())) {
				return;
			}

			let widget = previewFunction(point, drillTo);

			if (isFullPage) {
				this.fullPageDocExplorerService.openFullPageDocExplorer(widget);
			} else {
				this.documentExplorerDialogService.open({
					dashboard: dashboardData.dashboard,
					paging: pagination,
					widget
				}).result.finally(() => this.focusWidget(widget));
			}
		};
	};

	focusWidget = (widget: Widget): void => {
		$(`#widget-${widget.parentWidget.id} :focusable`).first().trigger('focus');
	};

}

app.service('widgetDrillActions', downgradeInjectable(WidgetDrillActionsService));
