import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import {AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { MetricWidgetComparison, MetricComparisonType, MetricWidgetProperties } from '@cxstudio/reports/entities/metric-widget-properties';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ITableColumn, ExportType } from '@cxstudio/reports/providers/cb/services/table-column-service.service';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { CalculationColorService } from '@cxstudio/reports/utils/color/calculation-color-service.service';
import { ExportBuilder } from '@cxstudio/reports/utils/export/export-builder.class';
import { FormatterBuilderUtilsService } from '@app/modules/widget-visualizations/formatters/formatter-builder-utils.service';
import { PopDiffPrefix } from '@cxstudio/services/constants/pop-difference-prefix.constant';
import { DateService, DateTimeFormat } from '@cxstudio/services/date-service.service';
import { ComparisonValue, MultiMetricDataProcessor, MultiMetricDataType } from '@cxstudio/services/multi-metric-data-processor';
import { UserObjectsService } from '@cxstudio/services/user-objects.service';
import * as moment from 'moment';
import * as _ from 'underscore';
import { AnalyticsDataFormattingService } from '@app/modules/widget-visualizations/utilities/analytics-data-formatting.service';
import { HighchartsAnalyticUtils } from '@app/modules/widget-visualizations/utilities/highcharts-analytic-utils.service';
import { PeriodOverPeriodMetricService } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric.service';
import { ReportNumberFormatUtils } from '../report-number-format-utils.service';
import { PeriodOverPeriodMetricType } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric-type';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { ExportAssetsOptions } from './export-utils.service';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { StatsTableService } from '@app/modules/reports/utils/stats-table.service';
import { CapitalizationService } from '@cxstudio/services/capitalization.service';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { MetricConstants } from '../../providers/cb/constants/metric-constants.service';

interface IObjectViewerRow {
	id: number;
	parentId: number;
	nodeName: string;
	level: number;
	leaf: boolean;
	weightN: number;
	autoFail: boolean;
	weight: number;
	present: boolean;
}

interface HeaderItem {
	key: string;
	displayName: string;
	exportType?: string;
	grouping?: AttributeGrouping;
	bandFormatter?: any;
}

type ExportDataProcessor = (utils: ExportBuilder, widget: Widget, options, allOptions, data, tmpData, allCols?) => void;

type WidgetTypeProcessors = Record<WidgetType, ExportDataProcessor>;
interface IWidgetExporterTypes extends Partial<WidgetTypeProcessors> {
	general: ExportDataProcessor;
}

export class ExportDataProcessors implements IWidgetExporterTypes {

	readonly TABLE_FORMATTING_SUPPORTED_WIDGETS: WidgetType[] = [
		WidgetType.METRIC, WidgetType.BAR, WidgetType.LINE,
		WidgetType.PIE, WidgetType.HEATMAP, WidgetType.SCATTER,
		WidgetType.CLOUD, WidgetType.NETWORK, WidgetType.TABLE
	];

	constructor(
		private locale: ILocale,
		private ANPreviewExportHelper,
		private globalNotificationService: GlobalNotificationService,
		private capitalization: CapitalizationService,
		private analyticsDataFormatting: AnalyticsDataFormattingService,
		private formatterBuilderUtils: FormatterBuilderUtilsService,
		private DateRange,
		private dashboardFiltersService: DashboardFiltersService,
		private dateService: DateService,
		private userObjectsService: UserObjectsService,
		private highchartsAnalyticUtils: HighchartsAnalyticUtils,
		private calculationColorService: CalculationColorService,
		private periodOverPeriodMetricService: PeriodOverPeriodMetricService,
		private reportNumberFormatUtils: ReportNumberFormatUtils,
		private statsTableService: StatsTableService,
		private metricConstants: MetricConstants
	) {}

	cb_an_preview = (utils: ExportBuilder, widget: Widget, options, allOptions, data, tmpData): ng.IPromise<void> => {
		let projectTimezone = allOptions.options ? allOptions.options.projectTimezone : null;
		projectTimezone = projectTimezone || moment.tz.guess();
		if (data.total && data.total.volume) {
			let volume = data.total.volume;
			utils.joinColumns([this.locale.getString('widget.exportTotal'), volume]);
			if (data.total.twitterDocumentCount) {
				utils.joinColumns([
					this.locale.getString('widget.exportTotalWithoutTwitter').toUpperCase(),
					volume - data.total.twitterDocumentCount
				]);
			}
		}
		utils.addEmptyRows();

		return this.userObjectsService.getHiddenAttributesAndModels(widget,
			widget.properties.documentExplorer).then((hiddenObjects) => {
			let exportHelper = new this.ANPreviewExportHelper(angular.copy(widget), data, utils, projectTimezone, allOptions, hiddenObjects);
			let cols = exportHelper.getColumns(widget.properties.documentExplorer);

			utils.joinColumns(cols);
			_.forEach(tmpData, (row, i) => {
				let rowArr = exportHelper.getDataRow(row, i);
				utils.joinColumns(rowArr);
			});

			utils.addEmptyRows();

			if (data.metadata && data.metadata.largeDocumentSizeWarning) {
				utils.addEmptyRows();
				let warningMessage = this.locale.getString('docExplorer.largeDocumentSizeWarning');
				utils.joinColumns(warningMessage);
				utils.addEmptyRows();

				this.globalNotificationService.showWarning(warningMessage, 5);
			}
		});
	};

	private applyFormatting(widget: Widget, row: any, field: string, groupIdField: string): string {
		let attribute = GroupIdentifierHelper.findGroup(widget.properties.selectedAttributes, groupIdField);
		if (!attribute)
			return row[field];

		if (_.isUndefined(row[field])) {
			return undefined;
		}
		if (attribute.type === ReportAssetType.NUMBER) {
			return this.formatAsNumber('' + row[field]);
		}

		let value = this.getValue(widget, row, field, groupIdField);
		return this.capitalization.getWrappedFormatter((attribute as any).capitalization)(value);
	}

	private getValue(widget: Widget, row: any, field: string, groupIdField: string): string {
		let attribute = GroupIdentifierHelper.findGroup(widget.properties.selectedAttributes, groupIdField);
		return attribute ? this.highchartsAnalyticUtils.getCategoryItemName(attribute, row, field) : row[field];
	}

	cb_an_table = (
		utils: ExportBuilder, widget: Widget, options: ExportAssetsOptions, allOptions, data, tmpData, columns: ITableColumn[]
	): void => {
		let dataArray = _.isArray(tmpData) ? tmpData : [tmpData];
		let columnNames = columns.map(column => column.name);
		let columnMap = _.groupBy(columns, 'field');
		let customMetrics = this.getCustomMetricsMap(widget);

		if (!widget.visualProperties.showTotal) {
			dataArray = _.filter(dataArray, e => e.leaf);
		}

		//check if we still have sentiment_breakdown
		utils.joinColumns(columnNames);
		dataArray = this.filterHiddenData(dataArray);
		dataArray.forEach(row => {
			utils.joinColumns(columns.map(col => {
				let groupIdField = col.id;
				let field = col.field;
				if (this.isExcelDateFormating(widget, allOptions, groupIdField)) {
					return this.formatColumnDateValue(row, col);
				}

				let text = this.applyFormatting(widget, row, field, groupIdField);
				if (this.isNewPercentChange(row, field)) {
					text = this.locale.getString('widget.new');
					return text;
				}
				if (text === 'NaN' || text === null || text === undefined) {
					let column = columnMap[field][0];

					if (column && column.historicMetric && column.formatter) {
						// exotic cases for table historical metrics ("---", "New")
						text = (column as any).formatter(0, 0, text, column, row, true);
					} else {

						// everything other
						if (column && column.exportType === 'string') {
							text = '';
						} else {
							text = this.locale.getString('widget.na');
						}
						return text;
					}
				}

				if (OptionsConstant.HIERARCHY_ENRICHMENT_PROPERTY === col.type
					&& (col.id.startsWith(PopDiffPrefix.PERCENT_CHANGE)
						|| col.id.startsWith(PopDiffPrefix.DELTA))) {
					text = this.locale.getString('widget.na');
				}
				//we have string and NaN condition only for case when same metric is grouping and calculation
				if (customMetrics[field] && typeof row[field] === 'string' && row[field] !== 'NaN') {
					let formatter = this.analyticsDataFormatting.getFormatterFromGroup(
						customMetrics[field], [customMetrics[field]]);
					if (formatter)
						return formatter(this.getValue(widget, row, field, col.id));
				}

				if (allOptions && allOptions.statsMode) {
					let calculation: ReportCalculation = this.statsTableService.getReportCalculationByName(widget.properties.selectedMetrics, field);
					return this.statsTableService.formatTableValue(widget.name as WidgetType, calculation, text, options);
				}

				let columnItem = _.findWhere(columns, {id: field});
				if (columnItem && columnItem.exportType) {
					return this.formatValue(text, columnItem.exportType);
				}

				return text;
			}));
		});
		utils.addEmptyRows();
	};

	cb_an_scorecard = (utils: ExportBuilder, widget: Widget, options, allOptions, data, tmpData, columns: ITableColumn[]) => {
		return this.cb_an_table(utils, widget, options, allOptions, data, tmpData, columns);
	};

	private formatValue(value, exportType): string {
		return `$F(${value}|${exportType})`;
	}

	private formatAsNumber(value): string {
		return this.formatterBuilderUtils.formatNumberAsString(value);
	}

	private isNewPercentChange(row, key): boolean {
		if (key.indexOf('percentChange_') > -1) {
			let metric = key.substring(key.indexOf('percentChange_') + 14);
			let historicValue = row['period_2_' + metric];
			if (historicValue === 0 && row[key] > 0) {
				return true;
			}
		}
		return false;
	}

	cb_an_metric = (
		utils: ExportBuilder, widget: Widget, options: ExportAssetsOptions, allOptions, data, tmpData, columns: ITableColumn[]
	): void => {
		this.exportMetric(utils, widget, options, allOptions, tmpData, columns);
	};

	private exportMetric(
		utils: ExportBuilder, widget: Widget, options: ExportAssetsOptions, allOptions, tmpData, columns: ITableColumn[]
	): void {
		const columnNames = columns.map((column) => {
			return column.name;
		});
		utils.joinColumns(columnNames);

		const dataArray = _.isArray(tmpData) ? tmpData : [tmpData];
		let comparisons: MetricWidgetComparison[] = this.getComparisons(widget);

		let metricLabel: string =
				this.periodOverPeriodMetricService.getPeriodLabel(1, widget.visualProperties, widget.properties, widget.containerId);
		const data = MultiMetricDataProcessor.processData(dataArray, widget.properties.selectedMetrics,
			widget.visualProperties, this.metricConstants, comparisons, metricLabel);

		data.forEach((row) => {
			let calculation: ReportCalculation = this.statsTableService.getReportCalculationByName(widget.properties.selectedMetrics, row.name);

			utils.joinColumns(columns.map((column) => {
				if (this.isComparisonPercentChange(row, column)) {
					return this.formatComparisonPercentChange(allOptions.statsMode, row, column);
				}

				let value: any = row[column.field];
				return !allOptions.statsMode
					? this.formatValue(value, column.exportType)
					: this.statsTableService.formatTableValue(widget.name as WidgetType, calculation, value, options);
			}));

		});
		utils.addEmptyRows();
	}

	isComparisonPercentChange(row: any, column: ITableColumn): boolean {
		return row.type === MultiMetricDataType.COMPARISON
			&& column.field === ComparisonValue.DIFF
			&& row.calculation === PeriodOverPeriodMetricType.PERCENT_CHANGE;
	}

	formatComparisonPercentChange(statsMode: boolean, row: any, column: ITableColumn): string {
		return !statsMode
			? this.formatValue(row[column.field], ExportType.PERCENT)
			: this.reportNumberFormatUtils.formatPercentSpaceBetween(row[column.field] * 100);
	}

	private getComparisons(widget: Widget): MetricWidgetComparison[] {
		let comparisons: MetricWidgetComparison[];

		if (widget.properties.widgetType === WidgetType.METRIC) {
			let metricDisplayName: string = widget.properties.selectedMetrics[0].displayName;
			comparisons = (widget.properties as MetricWidgetProperties).comparisons;

			if (comparisons && !_.isEmpty(comparisons)) {
				comparisons.forEach(comparison => {
					let comparisonLabel: string;

					let comparisonType: MetricComparisonType = comparison.type;
					switch (comparisonType) {
						case MetricComparisonType.GOAL:
							comparisonLabel = comparison.label || this.locale.getString('widget.goal');
							break;
						case MetricComparisonType.TIME:
							comparisonLabel = comparison.label || this.DateRange.valueOf(comparison.value.dateFilterMode).displayName;
							break;
					}

					comparison.displayName = `${metricDisplayName} ${comparisonLabel}`;
				});
			}
		}

		return comparisons;
	}

	private sortHeaderKeys(originalKeys): string[] {
		let predefinedKeysSorted = ['id', 'name', 'sent_count', 'doc_count', 'volume', 'percentOfVolume',
			'sentiment', 'metric3', 'metric4', 'fullPath', 'negative', 'neutral', 'positive', 'empty'];
		//Not sure if this is necessary, but better to be safe
		let unexpectedKeys = _.difference(originalKeys, predefinedKeysSorted);
		//Intersection maintains order, just need to remove any keys in master list not present in data
		let originalKeysSorted = _.intersection(predefinedKeysSorted, originalKeys);
		return _.union(originalKeysSorted, unexpectedKeys);
	}

	general = (utils: ExportBuilder, widget: Widget, options: ExportAssetsOptions, allOptions, data, tmpData): void => {
		if (data.countInfo && data.countInfo.total)
			utils.joinColumns([this.locale.getString('widget.exportTotal'), data.countInfo.total]);
		utils.addEmptyRows();

		if (_.isUndefined(tmpData) || tmpData.length === 0) {
			return;
		}
		let dataArray = _.isArray(tmpData) ? tmpData : [tmpData];
		let headerKeys = [];
		let headers = [];
		let customMetrics = this.getCustomMetricsMap(widget);
		let isAnalyticWidget = ReportConstants.isAnalyticWidget(widget.properties.widgetType);

		if (isAnalyticWidget) {
			let dataKeys = Object.keys(this.findDataRowForHeader(dataArray));
			headers = this.getHeader(widget, dataKeys, options);
			headerKeys = _.pluck(headers, 'key');
			utils.joinColumns(_.pluck(headers, 'displayName'));
		} else {
			let headerRow = this.findDataRowForHeader(dataArray);
			headerKeys = this.sortHeaderKeys(Object.keys(headerRow));
			utils.joinColumns(headerKeys);
		}

		dataArray = this.filterHiddenData(dataArray);
		dataArray.forEach(row => {
			utils.joinColumns(headerKeys.map((key) => {
				let header: HeaderItem = _.findWhere(headers, {key});

				if (header?.bandFormatter) {
					return header.bandFormatter(row)?.name;
				}

				if (ReportConstants.isNetworkWidget(widget.properties.widgetType) && key === 'connections') {
					return this.getAmountOfConnections(widget, row, data.networkData);
				}

				if (this.isExcelDateFormating(widget, allOptions, key)) {
					return this.formatGroupingDateValue(row, header.grouping, key);
				}

				let text = this.applyFormatting(widget, row, key, key);
				text = this.appendTextArtifacts(widget, row, key, text, allOptions);

				if (text === 'NaN' || text === null || text === undefined) {
					if (header && header.exportType === 'string') {
						text = '';
					} else {
						text = this.locale.getString('widget.na');
					}
					return text;
				}
				if (key === 'periodDescriptions') {
					text = row[key].currentPeriodLabel;
				}

				if (key === '_pop') {
					if (row[key] === 'period_1_') {
						return this.getPeriodDisplayName(1, widget);
					}
					if (row[key] === 'period_2_') {
						return this.getPeriodDisplayName(2, widget);
					}
				}
				if (customMetrics[key]) {
					let formatter = this.analyticsDataFormatting.getFormatterFromGroup(
						customMetrics[key], [customMetrics[key]]);

					if (formatter)
						return formatter(this.getValue(widget, row, key, key));
				}

				let valueType = typeof text;
				if (valueType === 'object' || valueType === 'function') {
					return '';
				}

				if (allOptions.statsMode) {
					let calculation: ReportCalculation = this.statsTableService.getReportCalculationByName(widget.properties.selectedMetrics, key);
					if (calculation && this.statsTableService.mustInheritFormat(widget, calculation)) {
						calculation = this.statsTableService.getCalculationWithInheritedFormat(
							calculation as unknown as NumberFormatSettings,
							widget.properties.selectedMetrics[0] as unknown as NumberFormatSettings
						);
					}

					return this.statsTableService.formatTableValue(widget.name as WidgetType, calculation, text, options);
				}

				if (header && header.exportType) {
					return this.formatValue(text, header.exportType);
				}

				return text;
			}));

		});

		if (!ReportConstants.isAnalyticWidget(widget.properties.widgetType) && data.metadata && data.metadata.totalCount > 0) {
			utils.joinColumns('n=' + data.metadata.totalCount);
		}
		utils.addEmptyRows();
	};

	private filterHiddenData(dataArray): any[] {
		return dataArray.filter(row => !row.filteredByConfidentiality);
	}

	private getAmountOfConnections(widget: Widget, row, networkData): number {
		const selectedGrouping = widget.properties.selectedAttributes[0];
		const groupingIdentifier: string = GroupIdentifierHelper.getIdentifier(selectedGrouping);

		const pointName = selectedGrouping.model
			? row[groupingIdentifier + ReportConstants.FULL_PATH]
			: row[groupingIdentifier];

		return (_.filter(networkData.links, (linkItem: any) => {
			return linkItem.source === pointName || linkItem.target === pointName;
		}) || []).length;
	}

	private appendTextArtifacts(widget: Widget, row, key, text, allOptions): string {
		if (widget.name === WidgetType.MAP && allOptions.statsMode) {
			// for Map widget, mark rows, that did not match a geographical feature
			let groupingIdentifier = widget.properties.selectedAttributes[0].identifier;
			if (key === groupingIdentifier && !row.__featureId) {
				text = `<i>${text} *</i>`;
			}
		}

		return text;
	}

	private findDataRowForHeader(dataArray): any[] {
		let headerRow = dataArray[0];
		dataArray.forEach((row) => {
			if (!_.isUndefined(row) && Object.keys(row).length > Object.keys(headerRow).length) {
				headerRow = row;
			}
		});
		return headerRow;
	}

	private getCustomMetricsMap(widget): any {
		let map = {};
		this.checkAndAddMetric(widget.properties.selectedAttributes, map);
		return map;
	}

	private checkAndAddMetric(metrics: AttributeGrouping[], map: any): void {
		_.forEach(metrics, (metric) => {
			if (AnalyticMetricTypes.isCustom(metric)
				|| AnalyticMetricTypes.isPredefinedGroup(metric)
				|| AnalyticMetricTypes.isTime(metric)) {
				let field = GroupIdentifierHelper.getIdentifier(metric);
				map[field] = metric;
			}
		});
	}

	private getHeader(widget: Widget, dataKeys, options): HeaderItem[] {
		let header: HeaderItem[] = [
			... this.getGroupingsHeaderItems(widget),
			... this.getHistoricalHeaderItems(widget, dataKeys),
			... this.getCalculationHeaderItems(widget, options)
		];

		if (_.contains(dataKeys, 'volume') && !_.find(header, item => item.key === 'volume')) {
			header.push({
				key: 'volume',
				displayName: this.locale.getString('widget.volume'),
				exportType: 'number'
			});
		}

		if (ReportConstants.isNetworkWidget(widget.properties.widgetType)) {
			const columnDisplayName = widget.properties.documentLevelOnly
				? 'widget.tableInteractionColumn'
				: 'widget.tableSentenceColumn';

			header.push({
				key: 'connections',
				displayName: this.locale.getString(columnDisplayName),
				exportType: 'number'
			});
		}

		return header;
	}

	private getGroupingsHeaderItems(widget: Widget): HeaderItem[] {
		return _.map(widget.properties.selectedAttributes, (item) => {
			let name = GroupIdentifierHelper.getIdentifier(item);
			return {
				key: name,
				displayName: item.displayName,
				exportType: 'string',
				grouping: item
			};
		});
	}

	private getHistoricalHeaderItems(widget: Widget, dataKeys): HeaderItem[] {
		if (widget.properties.useHistoricPeriod && _.contains(dataKeys, '_pop')) {
			return [{
				key: '_pop',
				displayName: this.locale.getString('widget.periodLowercase')
			}];
		}
		return [];
	}

	private getCalculationHeaderItems(widget: Widget, options): HeaderItem[] {
		const colorMetricsFormaters: any[] = this.getColorMetricsFormatters(widget, options.predefinedMetrics);

		return _.chain(widget.properties.selectedMetrics)
			.filter((item) => {
				return item.name !== StandardMetricName.ALPHANUMERIC
						&& item.name !== StandardMetricName.MODEL_ORDER;
			})
			.map((item) => {
				const headerItems: HeaderItem[] = [{
					key: item.name,
					displayName: item.displayName,
					exportType: 'number'
				}];

				const colorMetricData = _.findWhere(colorMetricsFormaters, {name: item.name});
				if (colorMetricData) {
					headerItems.push({
						key: item.name + '_band',
						displayName: this.locale.getString('widget.tableBandColumn', {name: item.displayName}),
						exportType: 'string',
						bandFormatter: colorMetricData.formatter
					});
				}

				return headerItems;
			})
			.flatten()
			.value();
	}

	private getColorMetricsFormatters(widget: Widget, metrics: Metric[]): any[] {
		const primaryColor: string = widget?.visualProperties?.color;
		const secondaryColor: string = widget?.visualProperties?.secondaryColor;
		const primaryPointColor: string = widget?.visualProperties?.pointColor;

		return _.chain([primaryColor, secondaryColor, primaryPointColor])
			.filter(colorName => this.calculationColorService.isCalculationColor(colorName))
			.map((colorName) => {
				const metric: Metric = this.calculationColorService.getMetricByCalculationColor(colorName, metrics);

				if (metric) {
					return [{
						name: metric.name,
						formatter: this.calculationColorService.getCategoryFunction(colorName, metric)
					}];
				}

				return [];
			})
			.flatten()
			.value();
	}

	private getPeriodDisplayName(period, widget): string {
		if (widget.visualProperties.periodLabel && widget.visualProperties.periodLabel[`period_${period}_`]) {
			return widget.visualProperties.periodLabel[`period_${period}_`];
		} else {
			return this.DateRange.valueOf(this.dashboardFiltersService.getActiveDateFilterMode(
				widget.properties, period, widget.containerId)).displayName;
		}
	}

	private formatGroupingDateValue(row, grouping, key): string {
		let value = grouping
			? this.highchartsAnalyticUtils.getCategoryItemName(grouping, row, key)
			: row[key];

		return this.formatDateValue(value);
	}

	private formatColumnDateValue(row, column): string {
		let value = column.objectField
			? row[column.field][column.objectField]
			: row[column.field];

		return this.formatDateValue(value);
	}

	private formatDateValue(value): string {
		let dateFormat = this.dateService.getUserDateFormat(DateTimeFormat.TREND_DATE);
		return `$F(${value}|date|${dateFormat})`;
	}

	private isExcelDateFormating(widget: Widget, allOptions, field): boolean {
		if (allOptions && allOptions.statsMode) {
			return false;
		}
		let timeGrouping = (widget.properties as any).primaryTimeGrouping;
		return timeGrouping && timeGrouping.timeName === 'time.day' && timeGrouping.name === field;
	}

	cb_object_viewer = (utils: ExportBuilder, widget: Widget, options, allOptions, data, tmpData: IObjectViewerRow[]) => {

		if (_.isEmpty(tmpData)) {
			return;
		}
		let headers = [];

		//Category 1	Category 2	Category 3	Category 4	Desired Result	Weight	Weight (N)
		let levelsCount = (_.max(tmpData, row => row.level) as IObjectViewerRow).level + 1;
		headers.pushAll(_.range(0, levelsCount).map(index => this.locale.getString('widget.exportCategoryIndex', {index: index + 1})));
		headers.pushAll([
			this.locale.getString('scorecards.presenceColumnLabel'),
			this.locale.getString('scorecards.weightLabel'),
			this.locale.getString('scorecards.weightNLabel'),
		]);
		let resultIndex = levelsCount;
		let weightIndex = levelsCount + 1;
		let weightNIndex = levelsCount + 2;

		utils.joinColumns(headers);
		tmpData = this.filterHiddenData(tmpData);
		tmpData.forEach(row => {
			let columns = [];
			columns[row.level] = this.formatValue(row.nodeName, 'string');
			columns[resultIndex] = !row.leaf
				? this.locale.getString('scorecards.parentNodePresenceLabel')
				: (row.present
					? this.locale.getString('scorecards.presentLabel')
					: this.locale.getString('scorecards.absentLabel'));
			if (row.autoFail) {
				columns[weightIndex] = this.locale.getString('scorecards.autoFailLabel');
			} else {
				columns[weightIndex] = this.formatValue(row.weight, 'number');
				columns[weightNIndex] = this.formatValue(row.weightN, 'number');
			}
			utils.joinColumns(columns);
		});

		utils.addEmptyRows();
	};


}

app.service('exportDataProcessors', ExportDataProcessors);
