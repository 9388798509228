import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { GridInputFormatterService } from '@app/modules/item-grid/grid-input-formatter.service';
import { BoundaryField } from '@cxstudio/attribute-geography/boundary-field';
import { GeographyOptionsService } from '@cxstudio/attribute-geography/geography-options.service';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { GridFormatter } from '@cxstudio/grids/grid-formatter-service';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { ProjectsManagementController } from '@cxstudio/projects/projects-management-component';
import { Model } from '@cxstudio/reports/entities/model';


@Injectable()
export class ModelsGridDefinitionService implements IGridDefinition<ProjectsManagementController> {

	constructor(
		private locale: CxLocaleService,
		private gridInputFormatterService: GridInputFormatterService<Model>,
		@Inject('gridFormatterService') private readonly gridFormatterService: GridFormatter,
		@Inject('contextMenuTree') private readonly contextMenuTree: ContextMenuTree,
		@Inject('geographyOptionsService') private readonly geographyOptionsService: GeographyOptionsService,
	) {}

	init = (gridMode: GridMode, controller: ProjectsManagementController): Promise<IGridColumn[]> => {
		let columns: IGridColumn[] = [
			{
				id: 'hamburger',
				field: 'name',
				sortable: false,
				minWidth: 40,
				width: 40,
				cssClass: 'cell-hamburger',
				headerCssClass: 'header-hamburger text-center',
				name: '<i class="q-icon-layer"></i>',
				formatter: this.gridFormatterService.getProjectHamburgerFormatter(gridMode),
				onClick: (event, object) => {
					let showHide = {
						name: 'hideToggle',
						text: controller.ui.hideObjects ? this.locale.getString('dashboard.showAllHidden') : this.locale.getString('dashboard.hideAllHidden'),
						func: () => { controller.ui.hideObjects = !controller.ui.hideObjects; },
						classes: [controller.ui.hideObjects ? 'items-hidden' : 'items-visible']
					};

					this.contextMenuTree.show(event, object, [showHide], 'header-menu', 360);
				}
			},
			{
				id: 'name',
				name: this.locale.getString('common.name'),
				field: 'name',
				sortable: false,
				searchable: true,
				minWidth: 280,
				width: 400,
				cssClass: 'cell-title'
			}, {
				id: 'boundaryFields',
				name: this.locale.getString('attributes.geography'),
				field: 'boundaryFields',
				sortable: false,
				optional: true,
				minWidth: 60,
				width: 180,
				cssClass: 'cell-geography cell-dropdown ' + gridMode,
				formatter: this.gridFormatterService.getDropdownValueFormatter(gridMode, this.formatGeographyValue)
			}, {
				id: 'lastClassified',
				name: this.locale.getString('administration.lastClassified'),
				field: 'lastClassified',
				sortable: false,
				optional: true,
				minWidth: 100,
				width: 150,
				cssClass: 'cell-last-classified',
				formatter: this.gridFormatterService.DateFormatter
			}, {
				id: 'localOther',
				name: this.locale.getString('administration.localOther'),
				field: 'includeLocalOther',
				sortable: false,
				optional: true,
				minWidth: 60,
				width: 200,
				cssClass: 'cell-local-other',
				formatter: this.gridFormatterService.YesNoFormatter
			}, {
				id: 'incrementalDataLoads',
				name: this.locale.getString('administration.incrementalDataLoads'),
				field: 'incrementalDataLoads',
				sortable: false,
				optional: true,
				minWidth: 60,
				width: 200,
				cssClass: 'cell-incremental-data-loads',
				formatter: this.gridFormatterService.YesNoFormatter
			}, {
				id: 'emptyRecords',
				name: this.locale.getString('administration.emptyRecords'),
				field: 'includeEmptyRecords',
				sortable: false,
				optional: true,
				minWidth: 60,
				width: 200,
				cssClass: 'cell-empty-records',
				formatter: this.gridFormatterService.YesNoFormatter
			}, {
				id: 'showInDrill',
				name: this.locale.getString('administration.showInDrill'),
				field: 'showInDrill',
				sortable: false,
				searchable: false,
				optional: true,
				minWidth: 60,
				width: 100,
				cssClass: 'cell-show-in-drill text-center',
				formatter: this.gridInputFormatterService.getCheckboxFormatter(gridMode)
			}, {
				id: 'showInDocExp',
				name: this.locale.getString('administration.showInDocExp'),
				field: 'showInDocExp',
				sortable: false,
				searchable: false,
				optional: true,
				minWidth: 60,
				width: 100,
				cssClass: 'cell-show-in-doc-exp text-center',
				formatter: this.gridInputFormatterService.getCheckboxFormatter(gridMode)
			}, {
				id: 'showInReporting',
				name: this.locale.getString('administration.showInReporting'),
				field: 'showInReporting',
				sortable: false,
				searchable: false,
				optional: true,
				minWidth: 60,
				width: 100,
				cssClass: 'cell-show-in-reporting text-center',
				formatter: this.gridInputFormatterService.getCheckboxFormatter(gridMode)
			}];
		return Promise.resolve(columns);
	};

	private formatGeographyValue = (boundaryFields: {[level: number]: BoundaryField}): string => {
		if (boundaryFields && Object.keys(boundaryFields).length > 0) {
			let geographyValue = '';
			for (let level of Object.keys(boundaryFields)) {
				let boundaryField = boundaryFields[level];
				if (!boundaryField) {
					continue;
				}

				if (geographyValue.length > 0) {
					geographyValue += ', ';
				}
				geographyValue += this.geographyOptionsService.getOptionDropdownName(boundaryField);
			}
			return geographyValue.length > 0 ? geographyValue : this.locale.getString('common.none');
		} else {
			return this.locale.getString('common.none');
		}
	};

}

app.service('modelsGridDefinition', downgradeInjectable(ModelsGridDefinitionService));
