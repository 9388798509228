import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';

@Injectable({
	providedIn: 'root'
})
export class ReportAssetUtilsService {
	constructor(
		private readonly betaFeaturesService: BetaFeaturesService,
	) {}

	getWidgetProject(widget: Widget): AccountOrWorkspaceProject {
		return this.getWidgetPropertiesProject(widget.properties);
	}

	getWidgetPropertiesProject(properties: WidgetProperties): AccountOrWorkspaceProject {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE) && properties.workspaceProject  //till we move all widgets to WS
			? properties.workspaceProject
			: ProjectIdentifier.fromWidgetProperties(properties);
	}

	getDashboardProject(dashboard: Dashboard): AccountOrWorkspaceProject {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE) && dashboard.workspaceProject
			? dashboard.workspaceProject
			: ProjectIdentifier.fromDashboardProperties(dashboard.properties);
	}
}

app.service('reportAssetUtilsService', downgradeInjectable(ReportAssetUtilsService));
