import { BookViewTab, DashboardTabMetadata } from '@app/modules/book/book-view-tab';
import DashboardRunType from '@app/modules/dashboard/dashboard-run-type.enum';
import { DashboardRun, DashboardRunService } from '@app/modules/dashboard/dashboard-run.service';
import BulkUpdateLabelsEntity from '@cxstudio/bulk/bulk-update-labels-entity';
import { Book } from '@cxstudio/dashboards/entity/book';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { IRequestShortcutConfig } from 'angular';
import * as moment from 'moment';
import * as uuid from 'uuid';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { DashboardCreationOriginDetails } from '@app/modules/dashboard/dashboard-creation-origin-details';
import { DashboardSnapshot } from '@cxstudio/dashboards/entity/dashboard-snapshot';
import { TreeService } from '../tree-service.service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { EngageJWTRequest } from '@cxstudio/engage/engage-jwt-request';
import { PromiseUtils } from '@app/util/promise-utils';


export class DashboardApiService {
	constructor(
		private $http,
		private treeService: TreeService,
		private httpHandlers: HttpHandlers,
		private dashboardRunService: DashboardRunService,
		private environmentService: EnvironmentService,
	) {}

	getDashboard = (dashboardId: number, logRun: boolean = false, runType?: DashboardRunType,
		tabId?: number, handleLocally?: boolean): ng.IHttpPromise<Dashboard> => {
		let config = { local: handleLocally, params: {}, headers: { runType } } as any;
		if (logRun) {
			let runStartTime: string = moment().format();
			config.params.runStartTime = runStartTime;

			let randomDashboardRunId: string = uuid.v4();
			config.params.dashboardRunId = randomDashboardRunId;

			if (tabId && tabId !== -1) {
				config.params.tabId = tabId;
			}
			let promise = this.requestDashboard(dashboardId, config);
			this.dashboardRunService.setDashboardRun(dashboardId, tabId, randomDashboardRunId, runStartTime, promise);
			return promise;
		} else {
			return this.requestDashboard(dashboardId, config);
		}
	};

	getLatestDashboardSnapshot(dashboardId: number): ng.IPromise<DashboardSnapshot> {
		let options = {
			headers: {} as any,
		};
		if (!!this.environmentService.getPdfToken()) {
			options.headers = { pdf: this.environmentService.getPdfToken() };
		}
		return this.$http.get(`rest/dashboard/snapshot/${dashboardId}`, options).then(this.httpHandlers.success);
	}

	private requestDashboard(dashboardId: number, config: any): ng.IHttpPromise<Dashboard> {
		return this.$http.get(`rest/dashboard/${dashboardId}`, config);
	}

	//userId is optional
	getAllDashboards = (userId?: number): ng.IPromise<Dashboard[]> => {
		return this.$http.get('rest/dashboard', { params: { userId }}).then(this.httpHandlers.success);
	};

	createNewDashboard = (dashboardData: Dashboard): ng.IHttpPromise<Dashboard> =>
		this.$http.post('rest/dashboard', angular.toJson(dashboardData));

	saveDashboardVersion = (dashboardId: number, dashboardData: {name: string; widgets: Widget[]}) =>
		this.$http.post(`rest/dashboard/${dashboardId}/version/`, dashboardData);

	removeDashboardVersion = (versionId: number) =>
		this.$http.delete(`rest/dashboard/version/${versionId}`);

	getMostRecentSavedVersionTime = (dashboardId: number) =>
		this.$http.get(`rest/dashboard/${dashboardId}/mostRecentSavedVersionTime`);

	consumeEngageToken = (request: EngageJWTRequest) => {
		return this.$http.post(`rest/dashboard/token/engage/`, request);
	};

	saveDashboardVersionAs = (dashboardId: number, dashboardData: Dashboard): ng.IPromise<Dashboard> => {
		return this.$http.post(`rest/dashboard/saveAs/${dashboardId}`, angular.toJson(dashboardData));
	};

	// similar to create predefined, but honors the widget positions as they are defined
	createDashboardByPinnedFeedback = (dashboardId: number, dashboardData: Dashboard) =>
		this.$http.post(`rest/dashboard/create/feedback-pinned`, dashboardData, {params: { dashboardId }});

	createPredefinedDashboard = (
		dashboardId: number, dashboardData: Dashboard, originDetails?: DashboardCreationOriginDetails
	): ng.IPromise<Dashboard> => {
		let params: any = this.buildQueryParamsForPredefinedDashboard(originDetails);
		return this.$http.post(`rest/dashboard/create/${(_.isUndefined(dashboardId) ? 'new' : dashboardId)}`, dashboardData, { params });
	};

	private buildQueryParamsForPredefinedDashboard(originDetails?: DashboardCreationOriginDetails): any {
		let params: any = {};

		if (originDetails?.type) {
			params.originType = originDetails.type;
		}

		if (originDetails?.name) {
			params.originName = originDetails.name;
		}

		return params;
	}

	updateDashboard = (dashboardId: number, dashboardData: Dashboard, retainEditPermission: boolean = false) => {
		return this.$http.put(`rest/dashboard/${dashboardId}`, angular.toJson(this.treeService.copyItem(dashboardData)),
			{params: { retainEditPermission }});
	};

	setDashboardRunStatus = (dashboardRun: DashboardRun) => {
		return this.$http.put(`rest/dashboard/run-status`, {requestId: dashboardRun.requestId, runStatus: dashboardRun.runStatus});
	};

	updateLabels = (updateLabelsEntity: BulkUpdateLabelsEntity) => {
		return this.$http.put(`rest/dashboard/labels`, angular.toJson(updateLabelsEntity));
	};

	renameDashboard = (dashboardId: number, nameData) =>
		this.$http.put(`rest/dashboard/${dashboardId}/rename`, angular.toJson(nameData));

	deleteDashboard = (dashboardId: number) =>
		this.$http.delete(`rest/dashboard/${dashboardId}`);

	deleteDashboardsBulk = (dashboardIds: number[]) =>
		this.$http.post('rest/dashboard/bulk/delete', dashboardIds);

	moveDashboard = (dashboardId: number, targetId: number) =>
		this.$http.post(`rest/dashboard/move/${dashboardId}/${targetId}`);

	copyDashboard = (dashboardId: number, name) =>
		this.$http.post(`rest/dashboard/copy/${dashboardId}`, name);

	publishDashboardFromSource = (targetId, sourceId) =>
		this.$http.post(`rest/dashboard/publish/${targetId}/from/${sourceId}`);

	shareDashboard = (dashboardId, shareData) =>
		this.$http.post('rest/dashboard/users/' + dashboardId, angular.toJson(shareData));

	shareDashboardsBulk = (dashboardIds: number[], shareData) => {
		shareData.dashboardIds = dashboardIds;
		return this.$http.post('rest/dashboard/users/bulk/', angular.toJson(shareData));
	};

	rateDashboard = (dashboardId: number, rating) =>
		this.$http.put(`rest/dashboard/rating/${dashboardId}`, rating + '');

	setDashboardFavorite = (dashboardId: number, isFavorite: boolean) =>
		this.$http.post(`rest/dashboard/set_favorite/${dashboardId}`, '' + isFavorite);

	getDashboardUsers = (dashboardId: number) =>
		this.$http.get(`rest/dashboard/users/${dashboardId}`).then(this.httpHandlers.success);

	getDashboardUsersWithRawGroups = (dashboardId: number) =>
		this.$http.get(`rest/dashboard/users/${dashboardId}/withRawGroups`).then(this.httpHandlers.success);

	getDashboardEditors = (dashboardId: number, search: string, limit: number) => {
		let params = {
			search, limit
		};

		return this.$http.get(`rest/dashboard/${dashboardId}/editor`, { params }).then(this.httpHandlers.success);
	};

	isAutosaved = (dashboardId: number): ng.IPromise<boolean> => {
		return this.$http.get(`rest/dashboard/${dashboardId}/auto-saved`).then(this.httpHandlers.success);
	};

	sendTabChangeEvent = (tab: BookViewTab<DashboardTabMetadata>, book: Book, pdf: boolean) => {
		let runStartTime: string = moment().format();
		let randomDashboardRunId: string = uuid.v4();
		let config: IRequestShortcutConfig = { headers: {pdf} };

		let promise = this.$http.post(`rest/dashboard/book/${book.id}/tab-change`, {
			tab,
			clientStartTime: runStartTime,
			dashboardRunId: randomDashboardRunId
		}, config);
		this.dashboardRunService.setDashboardRun(book.id, tab.metadata.dashboardId, randomDashboardRunId, runStartTime, promise);
	};

	updateSharingProperties = (dashboardId: number, preventFromSharing: boolean, allowSharingThroughEmbedding: boolean) => {
		let shareData = {
			preventFromSharing,
			allowSharingThroughEmbedding
		};
		return this.$http.post(`rest/dashboard/${dashboardId}/sharingProperties/update`, angular.toJson(shareData));
	};

	getEmbedWidgetNames = (dashboardIds: number[]): ng.IPromise<Array<{widgetName: string; dashboardName: string}>> =>
		this.$http.get(`rest/dashboard/bulk/embedded-widgets`, {params: {dashboardIds}}).then(this.httpHandlers.success);

	runReportGeneration(dashboardId: number): ng.IHttpPromise<void> {
		return this.$http.post(`rest/dashboard/${dashboardId}/generate-reports`);
	}

	getTranslationTemplate(dashboardId: number): ng.IHttpPromise<void> {
		return this.$http.get(`rest/dashboard-translation/${dashboardId}`);
	}

	getSupportedLocales(dashboardId: number): ng.IHttpPromise<void> {
		return this.$http.get(`rest/dashboard-translation/${dashboardId}/locale`);
	}
}

app.service('dashboardApiService', DashboardApiService);
